/* eslint-disable import/prefer-default-export */
import { TECHNICAL_ERROR_MESSAGE, WEB_CHAT_STATE } from "fixtures/constants";

export function selectHasAppStatus({ appStatus }) {
  return Boolean(appStatus.updatedAt);
}

export function selectTechnicalErrorMessage({ appStatus }) {
  return (
    appStatus.data?.message === TECHNICAL_ERROR_MESSAGE ||
    appStatus.data?.message === "Internal Server Error"
  );
}

export function selectSystemOfflineMessage({ appStatus }) {
  return appStatus.data.SYSTEM;
}

export function selectIsSystemOffline(state) {
  return Boolean(selectSystemOfflineMessage(state));
}

export function selectOfflineMessageForProperty(hotelCode) {
  return ({ appStatus }) => appStatus.data[hotelCode];
}

export function selectIsOfflineForProperty(hotelCode) {
  return (state) => Boolean(selectOfflineMessageForProperty(hotelCode)(state));
}

export function selectChatStatus({ appStatus }) {
  return appStatus?.chatStatus;
}

export function selectChatStatusIsNotClosed({ appStatus }) {
  return (
    appStatus?.chatStatus && appStatus.chatStatus !== WEB_CHAT_STATE.CLOSED
  );
}

export function selectLoadingRedirect({ appStatus }) {
  return appStatus?.loadingRedirect;
}
