import { combineEpics, ofType } from "redux-observable";
import { of, forkJoin, zip } from "rxjs";
import {
  catchError,
  mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
} from "rxjs/operators";

// eslint-disable-next-line import/no-cycle
import getTermsAndConditionsContent$ from "api/content/getTermsAndConditions/getTermsAndConditions";
import { stringifyObject } from "utils/utils";
import {
  fetchTermsAndConditionsContent,
  fetchTermsAndConditionsContentCancel,
  fetchTermsAndConditionsContentFailed,
  fetchTermsAndConditionsContentFulfilled,
} from "./termsAndConditionsContent.slice";

export const fetchTermsAndConditionsContent$ = (
  { locale = "en", hotelCode, optionsList },
  termsAndConditionsData
) =>
  forkJoin(
    optionsList
      .filter(
        (options) =>
          !(
            termsAndConditionsData[hotelCode] &&
            termsAndConditionsData[hotelCode][stringifyObject(options)]
          )
      )
      .map((options) =>
        zip(
          getTermsAndConditionsContent$({
            hotelCode,
            locale,
            options,
          }),
          of({ ...options, locale })
        )
      )
  );

const fetchTermsAndConditionsContentEpic = (action$, state$) =>
  action$.pipe(
    ofType(fetchTermsAndConditionsContent.type),
    withLatestFrom(state$),
    switchMap(
      ([
        { payload },
        {
          termsAndConditions: { data },
        },
      ]) =>
        fetchTermsAndConditionsContent$(payload, data).pipe(
          mergeMap((termsAndConditions) =>
            termsAndConditions.some(([{ error }]) => Boolean(error))
              ? of(
                  fetchTermsAndConditionsContentFailed(
                    "Could not fetch terms and conditions"
                  )
                )
              : of(
                  fetchTermsAndConditionsContentFulfilled({
                    hotelCode: payload.hotelCode,
                    termsAndConditions,
                  })
                )
          ),
          catchError((error) =>
            of(fetchTermsAndConditionsContentFailed(error))
          ),
          takeUntil(
            action$.pipe(ofType(fetchTermsAndConditionsContentCancel.type))
          )
        )
    )
  );

export default combineEpics(fetchTermsAndConditionsContentEpic);
