import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs";
import { map, switchMap, takeUntil, catchError } from "rxjs/operators";

import { getWorkplaces$ } from "api/content";
import {
  fetchWorkplaces,
  fetchWorkplacesFulfilled,
  fetchWorkplacesFailed,
  fetchWorkplacesCancel,
} from "./workplaces.slice";

const fetchWorkplacesEpic = (action$) =>
  action$.pipe(
    ofType(fetchWorkplaces.type),
    switchMap(({ payload: { locale } }) =>
      getWorkplaces$({ locale }).pipe(
        map(fetchWorkplacesFulfilled),
        catchError((error) => of(fetchWorkplacesFailed(error))),
        takeUntil(action$.pipe(ofType(fetchWorkplacesCancel.type)))
      )
    )
  );

export default combineEpics(fetchWorkplacesEpic);
