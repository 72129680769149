import React from "react";

import { useTranslation } from "hooks";

export default function CancellationFailed() {
  const { t } = useTranslation();

  return (
    <div className="modal-content-body-padding">
      <div className="cancel-error">
        {t(
          "We are unable to cancel your reservation online, please contact the Hotel or Worldwide Reservations for assistance."
        )}
      </div>
    </div>
  );
}
