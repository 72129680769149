import { ajax } from "rxjs/ajax";

import env from "config/env";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;
const TRETAIL_API_BOOKINGS_URL = `${TRETAIL_API_BASE_URL}/bookings`;

const addContact$ = ({
  bookingId,
  firstName,
  surname,
  email,
  countryCode,
  phoneNumber,
  locale,
  nationality,
  consentAcceptanceTimestamp,
}) =>
  ajax({
    ...basePostRequest({ locale }),
    url: `${TRETAIL_API_BOOKINGS_URL}/${bookingId}/contact`,
    body: {
      email,
      name: {
        firstName,
        surname,
      },
      address: {
        country: {
          code: countryCode,
        },
      },
      phones: [
        {
          phoneType: "MOBILE",
          number: phoneNumber,
        },
      ],
      ...(nationality && consentAcceptanceTimestamp
        ? { nationality, consentAcceptanceTimestamp }
        : {}),
    },
  });

export default addContact$;
