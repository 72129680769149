import React, { useContext } from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";
import MappingLayerContext from "../../mappingLayerContext";

export default function TermsAndConditions({ register, errors = {} }) {
  const { t } = useTranslation();
  const { termsAndConditions } = useContext(MappingLayerContext);

  const hasError = Object.keys(errors).length > 0;

  return (
    <fieldset
      name="termsAndConditions"
      className={classNames("emp-terms-fieldset has-break", {
        "has-errors": hasError,
      })}
    >
      <legend>{t("Terms & Conditions")}</legend>

      <div
        className={classNames("form-row checkbox terms-agreement-row", {
          "has-errors": hasError,
        })}
      >
        {hasError && (
          <div className="inline-error">
            <span id="ttTermsAndConditions" className="message">
              {t("To proceed, please accept terms & conditions.")}
            </span>
            <span aria-hidden="true" className="icon">
              !
            </span>
          </div>
        )}
        <div id="termsAndConditionsText" className="termsAndConditionsText">
          {termsAndConditions.map(({ text, checkbox }, index) => (
            <React.Fragment key={index}>
              <div>
                <label htmlFor={`termsAgreement-${index}`}>
                  {checkbox && (
                    <input
                      id={`termsAgreement-${index}`}
                      type="checkbox"
                      name={`termsAndConditions.termsAgreement-${index}`}
                      aria-describedby="ttTermsAndConditions"
                      ref={register}
                    />
                  )}
                  <span>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: text,
                      }}
                    />
                  </span>
                </label>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </fieldset>
  );
}
