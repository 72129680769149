import React from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";
import ServicesAndAmenitiesContent from "./ServicesAndAmenitiesContent";

export default function MobileRoomDetails({
  accessibleFeaturesList,
  isBookableOnline,
  roomName,
  servicesAndAmenityGroups,
}) {
  const { t } = useTranslation();

  return (
    <div
      className="room-item-tab-container"
      id="room-item-tab-container-{{title}}"
    >
      <div
        className={classNames("room-details-tab-select", {
          "non-bookable-direct": isBookableOnline,
        })}
        role="tablist"
      >
        <div
          className={classNames("tab-button tab-button--services is-active")}
        >
          <h4 className="span">{t("Services & Amenities")}</h4>
        </div>
      </div>

      <ul className="room-details-tabs">
        <li
          className="tab tab-services"
          id={`tab-services-panel-${roomName}`}
          role="tabpanel"
          aria-label={t("Services & Amenities")}
        >
          <section className="room-item-services room-tab-section">
            <ServicesAndAmenitiesContent
              servicesAndAmenityGroups={servicesAndAmenityGroups}
            />

            {accessibleFeaturesList && (
              <div className="info-block special-access">
                <h4 className="visuallyhidden">
                  {t("Special access requests")}
                </h4>
                <div className="special-access-requests">
                  <h5>{t("Accessibility")}</h5>
                  <ul>
                    {accessibleFeaturesList.map((item) => (
                      <li
                        key={item}
                        dangerouslySetInnerHTML={{ __html: item.trim() }}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </section>
        </li>
      </ul>
    </div>
  );
}
