import compose from "lodash/fp/compose";
import defaultTo from "lodash/fp/defaultTo";
import find from "lodash/fp/find";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import matchesProperty from "lodash/fp/matchesProperty";
import overSome from "lodash/fp/overSome";
import some from "lodash/fp/some";

import formatPrice from "utils/formatPrice";

const matchCode = (valueToMatch) =>
  overSome([
    matchesProperty(["owsCode"], valueToMatch),
    matchesProperty(["orsCode"], valueToMatch),
  ]);

export const getReservationHighlights = ({
  upcomingTrip,
  accommodationContent,
  offersContent,
  employeeRateTitle,
}) => {
  const product = get(["hotelProducts", "0"], upcomingTrip) || {};
  const fsRoomToCharge =
    get(["roomTypes", "0", "fsRoomToCharge"], product) || "";
  const roomTypeId = get(["roomTypes", "0", "roomTypeId"], product) || "";
  const ratePlanCode = get(["roomRate", "ratePlanCode"], product) || "";
  const priceViewable = getOr(true)(["roomRate", "priceViewable"])(product);
  const cash = get(["price", "total", "cash"], upcomingTrip) || {};

  const roomCode = fsRoomToCharge || roomTypeId;

  const roomType = compose(
    defaultTo(""),
    get(["title"]),
    find(matchCode(roomCode)),
    get(["sleepingArrangements"]),
    find(compose(some(matchCode(roomCode)), get(["sleepingArrangements"]))),
    get(["bookableAccommodations"])
  )(accommodationContent);

  const offerNameFallback = get(["roomRate", "ratePlanTitle"], product);

  const offerName = compose(
    defaultTo(employeeRateTitle || offerNameFallback || ""),
    get(["title"]),
    find(matchCode(ratePlanCode)),
    get(["bookableOffers"])
  )(offersContent);

  return new Map(
    [
      [
        "Reservation Number",
        get(["hotelProducts", "0", "reservationId"], upcomingTrip) || "",
      ],
      ["Room Type", roomType],
      ["Offer Name", offerName],
      priceViewable && [
        "Estimated Total",
        cash.currencyCode +
          formatPrice({
            currency: cash.currencyCode,
            value: cash.amount,
            removeCurrency: true,
          }),
      ],
    ].filter(Boolean)
  );
};

export const getReservationHighlightsArray = (a) =>
  Array.from(a).map(([label, value]) => ({
    label,
    value,
  }));
