/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import {
  setRequestStateRequesting,
  setRequestStateFulfilled,
  setRequestStateFailed,
  setRequestStateCancelled,
} from "store/apiRequestStates/setRequestState";

const initialState = {
  data: {},
  error: {},
  requestStates: {},
};

const upsellsSlice = createSlice({
  name: "upsells",
  initialState,
  reducers: {
    fetchUpsellsContent(state, { payload }) {
      const { propertyCode } = payload;
      state.error[propertyCode] = "";
      state.requestStates[propertyCode] = setRequestStateRequesting(
        state.requestStates[propertyCode]
      );
    },

    fetchUpsellsContentFulfilled(
      state,
      { payload: { propertyCode, upsells } }
    ) {
      state.data[propertyCode] = upsells;
      state.error[propertyCode] = "";
      state.requestStates[propertyCode] = setRequestStateFulfilled(
        state.requestStates[propertyCode]
      );
    },

    fetchUpsellsContentFailed(state, { payload }) {
      const { propertyCode, error } = payload;
      state.error[propertyCode] = error;
      state.requestStates[propertyCode] = setRequestStateFailed(
        state.requestStates[propertyCode]
      );
    },

    fetchUpsellsContentCancel(state, { payload }) {
      const { propertyCode } = payload;
      state.requestStates[propertyCode] = setRequestStateCancelled(
        state.requestStates[propertyCode]
      );
    },

    upsellsContentReset() {},
  },
});

export const {
  fetchUpsellsContent,
  fetchUpsellsContentCancel,
  fetchUpsellsContentFailed,
  fetchUpsellsContentFulfilled,
  upsellsContentReset,
} = upsellsSlice.actions;

export default upsellsSlice.reducer;
