/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  booking: undefined,
  comments: {},
  apiErrors: [],
  supplierErrors: [],
  error: "",
  confirmed: false,
};

const webCheckInSlice = createSlice({
  name: "webCheckIn",
  initialState,
  reducers: {
    fetchBooking() {},
    fetchBookingFulfilled(state, { payload: booking }) {
      state.booking = booking;
      state.apiErrors = [];
      state.supplierErrors = [];
    },
    fetchBookingFailed(state, { payload }) {
      state.apiErrors = payload.apiErrors || [];
      state.supplierErrors = payload.supplierErrors || [];
    },
    fetchBookingCancel() {},

    clearFetchBooking(state) {
      state.booking = undefined;
      state.comments = {};
      state.error = "";
      state.apiErrors = [];
      state.supplierErrors = [];
      state.confirmed = false;
    },

    clearErrors(state) {
      state.error = "";
      state.apiErrors = [];
      state.supplierErrors = [];
    },

    confirmCheckIn() {},
    confirmCheckInFulfilled(state) {
      state.error = "";
      state.confirmed = true;
    },
    confirmCheckInFailed(state, { payload }) {
      state.error = payload.error;
    },
    confirmCheckInCancel() {},

    // anlytics only events
    webCheckInStartEvt() {},
    webCheckInErrorEvt() {},
    webCheckInRegistrationEvt() {},
    webCheckInCompleteEvt() {},
  },
});

export const {
  clearFetchBooking,
  clearErrors,
  fetchBooking,
  fetchBookingCancel,
  fetchBookingFailed,
  fetchBookingFulfilled,
  confirmCheckIn,
  confirmCheckInCancel,
  confirmCheckInFailed,
  confirmCheckInFulfilled,
  webCheckInStartEvt,
  webCheckInErrorEvt,
  webCheckInRegistrationEvt,
  webCheckInCompleteEvt,
} = webCheckInSlice.actions;

export default webCheckInSlice.reducer;
