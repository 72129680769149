import get from "lodash/fp/get";

const DEFAULT_CHECK_IN_TIME = "3:00pm";

export default (propertyContent = {}) => {
  const propertyCheckInTime = get(["checkInTime"], propertyContent) || "";

  return propertyCheckInTime
    ? propertyCheckInTime.replace(/\s/g, "").toLowerCase()
    : DEFAULT_CHECK_IN_TIME;
};
