const getBookerNames = ({
  watch,
  isAgentBookingSelected,
  isBookingForSomeoneElseSelected,
}) => {
  if (isAgentBookingSelected) {
    return {
      watchedFirstName: "",
      watchedSurname: "",
    };
  }
  if (isBookingForSomeoneElseSelected) {
    return {
      watchedFirstName: watch("bookerDetails.firstName"),
      watchedSurname: watch("bookerDetails.surname"),
    };
  }

  return {
    watchedFirstName: watch("guestDetails.firstName"),
    watchedSurname: watch("guestDetails.surname"),
  };
};

export default getBookerNames;
