import {
  updateUserPhone,
  updateUserPhoneFulfilled,
} from "store/profile/profile.slice";
import { preparePhones, makeLoginPhone } from "../profile.helpers";
import { updateProfileTemplate } from "./updateProfile";

export default updateProfileTemplate({
  actionType: updateUserPhone.type,
  fulfillAction: updateUserPhoneFulfilled,
  updateFn: ({ phones = [], extension = {} } = {}, newValue) => ({
    phones: preparePhones(phones, newValue),
    extension: makeLoginPhone({ extension, phone: newValue }),
  }),
});
