import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { selectProperty } from "store/properties";
import { getInitialStartAndEndDates } from "../DateRangePicker/calendarHelpers";

export default function useCalendarDefaults({ hotelCode } = {}) {
  const location = useLocation();

  const { checkIn, checkOut } = queryString.parse(location.search || "");

  // This is not ideal but necessary
  // ML should check the validity of dates since it also sets defaults
  return getInitialStartAndEndDates({
    checkIn,
    checkOut,
    minNumberOfNights: 1,
    openingDate: useSelector(selectProperty(hotelCode))?.openingDate,
  });
}
