/* eslint-disable no-unused-vars */
import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";

import env from "config/env";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

export default function submitOTP$({
  sendCodeTo = "",
  sendCodeVia = "",
  otp = "",
  persist = false,
  isUserPersistent = false,
  locale,
}) {
  return ajax({
    ...basePostRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/authentication${
      isUserPersistent ? "/cust/loginPersistent" : ""
    }`,
    body: {
      loginType: sendCodeVia,
      password: otp,
      user: sendCodeTo,
      persistLogin: persist,
    },
  }).pipe(pluck("response"));
}
