import React, { useContext, useEffect, useRef, useReducer } from "react";
import classNames from "classnames";
import FocusLock from "react-focus-lock";
import isFunction from "lodash/isFunction";

import { useTranslation } from "hooks";
import ThemeContextProvider, {
  ThemeContext,
  THEMES,
  flipTheme,
} from "contexts/ThemeContext";

const initialState = (initialIsOpen) => ({
  isOpen: initialIsOpen,
  isOpening: false,
  isClosing: false,
});

function reducer(state, action) {
  switch (action.type) {
    case "open":
      return { isOpen: false, isOpening: true, isClosing: false };
    case "opened":
      return { isOpen: true, isOpening: false, isClosing: false };
    case "close":
      return { isOpen: true, isOpening: false, isClosing: true };
    case "closed":
      return { isOpen: false, isOpening: false, isClosing: false };
    default:
      throw new Error();
  }
}

export default function ToggleBlock({
  type = "edit",
  label,
  showButton = true,
  buttonLabel,
  buttonAriaLabel,
  initialIsOpen = false,
  children,
}) {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const flippedTheme = flipTheme(theme);
  const containerRef = useRef();
  const ref = useRef();

  const [{ isOpen, isOpening, isClosing }, dispatch] = useReducer(
    reducer,
    initialState(initialIsOpen)
  );
  const isTransitioning = isOpening || isClosing;

  const open = () => {
    dispatch({ type: "open" });
  };

  const close = () => {
    dispatch({ type: "close" });
  };

  const toggle = () => {
    if (isOpen || isOpening) {
      close();
    } else {
      open();
    }
  };

  useEffect(() => {
    const handleTransitionend = (e) => {
      if (ref?.current === e.target) {
        if (isOpening) {
          dispatch({ type: "opened" });
        } else {
          dispatch({ type: "closed" });
        }
      }
    };

    const element = ref?.current;
    element.addEventListener("transitionend", handleTransitionend);
    return () => {
      element.removeEventListener("transitionend", handleTransitionend);
    };
  }, [isOpening]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!containerRef?.current.contains(e.target)) {
        close();
      }
    };

    function handleKeypress(e) {
      if (e.key === "Escape") {
        close();
      }
    }

    if (isOpen) {
      window.document.addEventListener("click", handleOutsideClick);
      window.document.addEventListener("keydown", handleKeypress);
    }
    return () => {
      if (isOpen) {
        window.document.removeEventListener("click", handleOutsideClick);
        window.document.removeEventListener("keydown", handleKeypress);
      }
    };
  }, [isOpen]);

  const intentionalEmptyFunction = () => {};

  const renderProps = {
    open,
    close,
    toggle,
    isOpen,
    isOpening,
    isClosing,
    isTransitioning,
  };

  const transitionClassNames = classNames(
    "fs-toggle-block__container accordion-item",
    {
      collapsed: (!isOpen && !isOpening) || (isOpen && isClosing),
      isTransitioning,
    }
  );

  return (
    <div
      className={`fs-toggle-block fs-toggle-block--${type}`}
      ref={containerRef}
    >
      <div className="fs-toggle-block__row">
        {typeof label === "string" && (
          <div className="fs-toggle-block__label">
            <div>
              <h3>{label}</h3>
            </div>

            {showButton && (
              <button
                className={`fs-toggle-button fs-toggle-button--${type}`}
                onClick={toggle}
                type="button"
                aria-label={buttonAriaLabel}
              >
                {buttonLabel}
              </button>
            )}
          </div>
        )}

        {isFunction(label) && label(renderProps)}

        <div className={transitionClassNames} ref={ref}>
          <div className="accordion-item__opacity">
            {(isOpen || isTransitioning) && (
              <FocusLock
                autoFocus={false}
                returnFocus={false}
                onDeactivation={intentionalEmptyFunction}
              >
                <ThemeContextProvider theme={flippedTheme}>
                  <div
                    className={classNames("fs-toggle-block__container__inner", {
                      "fs-toggle-block__container__inner--dark":
                        flippedTheme === THEMES.DARK,
                    })}
                  >
                    {isFunction(children) ? children(renderProps) : children}

                    <button
                      className={classNames("fs-toggle-block__close-button", {
                        "fs-toggle-block__close-button--light":
                          flippedTheme === THEMES.DARK,
                      })}
                      onClick={close}
                      type="button"
                    >
                      <span>{t("Close")}</span>
                    </button>
                  </div>
                </ThemeContextProvider>
              </FocusLock>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
