import React from "react";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import update from "lodash/fp/update";

import * as regexPatterns from "utils/regexPatterns";
import validPhoneNumber from "utils/validPhoneNumber";
import { useTranslation } from "hooks";
import {
  Button,
  Checkbox,
  ControlledCheckbox,
  Dropdown,
  ErrorMessage,
} from "Profile/components";
import { NEW_PHONE_NUMBER_ID } from "fixtures/constants";
import {
  selectCountriesContent,
  selectIsLoadingCountriesContent,
} from "store/countriesContent";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import PhoneNumber from "../../../components/PhoneNumber";
import Tooltip from "../Tooltip";

const TYPES = {
  WORK: "WORK",
  MOBILE: "MOBILE",
  HOME: "HOME",
};

const phoneNumberTypes = [
  { label: "Business", value: TYPES.WORK },
  { label: "Mobile", value: TYPES.MOBILE },
  { label: "Home", value: TYPES.HOME },
];

export default function PhoneNumberForm({
  canBeDeleted = false,
  identifier,
  onSubmit,
  phoneNumber: { phoneType, salesForceId, isLogin, defaultPhone },
  register,
  formState,
  onDelete = () => {},
  errors,
  baseError,
  control,
  watched,
  showLoadingIndicator,
}) {
  const { t } = useTranslation();

  const countries = useSelector(selectCountriesContent);
  const isLoadingCountries = useSelector(selectIsLoadingCountriesContent);

  const isNotNew = salesForceId !== NEW_PHONE_NUMBER_ID;

  return (
    <>
      {showLoadingIndicator && <BookingFlowLoadingIndicator />}

      {baseError && <ErrorMessage>{t(baseError.errorCode)}</ErrorMessage>}

      <form onSubmit={onSubmit} noValidate>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              identifier={identifier}
              name="phoneData.number"
              phoneNumberName="phoneData.number"
              required
              control={control}
              as={<PhoneNumber />}
              countries={countries}
              isLoadingCountries={isLoadingCountries}
              disabled={isLogin}
              rules={{
                required: true,
                pattern: regexPatterns.telephoneNumber,
                validate: { validPhoneNumber },
              }}
              error={
                Boolean(errors?.phoneData?.number) &&
                t("Please enter your phone number.")
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Dropdown
              name="phoneData.phoneType"
              identifier={`${identifier}-type`}
              value={phoneType}
              options={phoneNumberTypes.map(update(["label"], t))}
              register={register({
                required: true,
              })}
              label={t("Type")}
              disabled={isLogin}
              required
              error={
                Boolean(errors?.phoneData?.phoneType) &&
                t("Please select the type of phone number.")
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="fs-profile-phone-number-address-form__checkbox">
              <ControlledCheckbox
                identifier={`${identifier}-isPrimary`}
                checked={defaultPhone}
                disabled
              >
                {t("Primary phone number")}
              </ControlledCheckbox>
            </div>
            <div className="fs-profile-phone-number-address-form__checkbox login-phone__checkbox">
              <Checkbox
                identifier={`${identifier}-login`}
                name="phoneData.isLogin"
                register={register}
                disabled={
                  !isNotNew || isLogin || watched.phoneType !== TYPES.MOBILE
                }
              >
                {t("LOGIN PHONE")}
              </Checkbox>
              {"    "}
              <Tooltip
                message={t(
                  "This is your preferred mobile phone to login to your Four Seasons online profile."
                )}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="fs-toggle-block__actions">
              {canBeDeleted && (
                <Button kind="link" onClick={onDelete}>
                  {t("Delete")}
                </Button>
              )}
              <Button
                type="submit"
                aria-label={t("Update phone number")}
                disabled={!formState.isValid}
              >
                {!isNotNew ? t("Save") : t("Update")}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
