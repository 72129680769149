// Get the domain ignoring the port to work locally
function getDomain(host) {
  if (host) {
    return host.match(/\.(.*?)(:|\/|$)/)[1];
  }
  return host;
}

// Validate the domain of a redirect to
function validateDomain(redirectTo, current) {
  if (redirectTo) {
    try {
      const newUrl = new URL(redirectTo);
      // Remove the subdomain from each host string to get main domain to compare.
      const newDomain = getDomain(newUrl.host);
      const currentDomain = getDomain(current.host);

      if (newDomain === currentDomain) {
        return redirectTo;
      }
    } catch {
      return null;
    }
    return null;
  }

  return redirectTo;
}

export default validateDomain;
