/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  apiErrors: [],
  supplierErrors: [],
};

const globalMailSubscriptionsSlice = createSlice({
  name: "globalMailSubscriptions",
  initialState,
  reducers: {
    fetchGlobalMailSubscriptions() {},
    fetchGlobalMailSubscriptionsFulfilled(state, { payload }) {
      state.data = payload;
    },
    fetchGlobalMailSubscriptionsFailed(state, { payload }) {
      state.data = [];
      state.apiErrors = payload.apiErrors || [];
      state.supplierErrors = payload.supplierErrors || [];
    },
    fetchGlobalMailSubscriptionsCancel() {},
  },
});

export const {
  fetchGlobalMailSubscriptions,
  fetchGlobalMailSubscriptionsCancel,
  fetchGlobalMailSubscriptionsFailed,
  fetchGlobalMailSubscriptionsFulfilled,
} = globalMailSubscriptionsSlice.actions;

export default globalMailSubscriptionsSlice.reducer;
