/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const preferredPartnerDetailsSlice = createSlice({
  name: "preferredPartnerDetails",
  initialState,
  reducers: {
    fetchPreferredPartnerDetails() {},

    fetchPreferredPartnerDetailsFulfilled: (state, { payload }) => {
      state.data = payload;
    },

    fetchPreferredPartnerDetailsFailed: (state) => {
      state.data = {};
    },

    fetchPreferredPartnerDetailsCancel() {},
    clearPreferredPartnerDetails: () => initialState,
  },
});

export const {
  fetchPreferredPartnerDetails,
  fetchPreferredPartnerDetailsFulfilled,
  fetchPreferredPartnerDetailsFailed,
  fetchPreferredPartnerDetailsCancel,
  clearPreferredPartnerDetails,
} = preferredPartnerDetailsSlice.actions;

export default preferredPartnerDetailsSlice.reducer;
