import React from "react";

import { useTranslation } from "hooks";

export default function ProfileBenefits() {
  const { t } = useTranslation();

  return (
    <div className="profile-benefits">
      <ul>
        <li className="ty-c1">{t("Set your arrival time")}</li>
        <li className="ty-c1">{t("Arrange for transportation")}</li>
        <li className="ty-c1">{t("Make dinner plans")}</li>
        <li className="ty-c1">{t("more...")}</li>
      </ul>
    </div>
  );
}
