/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import getMergedPropertyCodes from "store/getMergedPropertyCodes";
import {
  setRequestStateRequesting,
  setRequestStateFulfilled,
  setRequestStateFailed,
  setRequestStateCancelled,
} from "store/apiRequestStates/setRequestState";

const initialState = {
  data: {},
  error: {},
  requestStates: {},
};

const propertyWebCheckinSlice = createSlice({
  name: "propertyWebCheckin",
  initialState,
  reducers: {
    fetchPropertyWebCheckin(state, { payload }) {
      const propertyCodes = getMergedPropertyCodes(payload);
      propertyCodes.forEach((propertyCode) => {
        delete state.error[propertyCode];
        state.requestStates[propertyCode] = setRequestStateRequesting(
          state.requestStates[propertyCode]
        );
      });
    },
    fetchPropertyWebCheckinFulfilled(
      state,
      { payload: { propertyCode, propertyWebCheckin } }
    ) {
      state.data[propertyCode] = propertyWebCheckin;
      state.requestStates[propertyCode] = setRequestStateFulfilled(
        state.requestStates[propertyCode]
      );
    },
    fetchPropertyWebCheckinFailed(state, { payload }) {
      const { propertyCode, error } = payload;
      state.error[propertyCode] = error;
      state.requestStates[propertyCode] = setRequestStateFailed(
        state.requestStates[propertyCode]
      );
    },
    fetchPropertyWebCheckinCancel(state, { payload }) {
      const propertyCodes = getMergedPropertyCodes(payload);
      propertyCodes.forEach((code) => {
        state.requestStates[code] = setRequestStateCancelled(
          state.requestStates[code]
        );
      });
    },
  },
});

export const {
  fetchPropertyWebCheckin,
  fetchPropertyWebCheckinCancel,
  fetchPropertyWebCheckinFailed,
  fetchPropertyWebCheckinFulfilled,
} = propertyWebCheckinSlice.actions;

export default propertyWebCheckinSlice.reducer;
