import { ofType } from "redux-observable";
import { switchMap, takeUntil, catchError } from "rxjs/operators";

import { replace } from "connected-react-router";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import * as bookingFlowRoutes from "BookingFlow/bookingFlowRoutes";

import { getQueryStringFromSearchFormValues } from "BookingFlow/utils";
import catchInternalServerError from "store/catchInternalServerError";
import {
  addPackageFailed,
  constructBookingByResultSet,
  constructBookingByResultSetCancel,
  constructBookingByResultSetFailed,
} from "../bookings.slice";
import { addPackage$ } from "./addPackage";

export default function constructBookingByResultSetEpic(action$) {
  return action$.pipe(
    ofType(constructBookingByResultSet.type),
    switchMap(({ payload: { searchParams: params, locale, actions } }) => {
      const { resultSetId, resultIds, ...searchParams } = params;
      return ajaxWithHealthCheck$({
        locale,
        propertyCode: searchParams.hotelCode,
      }).pipe(
        switchMap(() => {
          return addPackage$({
            resultSetId,
            selectedPackages: resultIds.map((resultId) => ({
              priceId: 1,
              resultId,
            })),
            searchParams,
            locale,
            fromConstructBooking: true,
            actions,
          });
        }),

        catchInternalServerError(),

        catchError((error) => {
          const { response } = error;
          const errorActions = [
            addPackageFailed({
              apiErrors: response?.apiErrors || [],
              supplierErrors: response?.supplierErrors || [],
            }),
            constructBookingByResultSetFailed({
              apiErrors: response?.apiErrors || [],
              supplierErrors: response?.supplierErrors || [],
            }),
            ...actions,
          ];
          if (error?.message === "SYSTEM_OFFLINE") {
            return [
              ...errorActions,
              replace({
                search: getQueryStringFromSearchFormValues(searchParams),
                pathname: bookingFlowRoutes.planYourStay.to({
                  locale,
                }),
              }),
            ];
          }
          return [
            ...errorActions,
            replace({
              search: getQueryStringFromSearchFormValues(searchParams),
              pathname: bookingFlowRoutes.chooseYourRoom.to({
                locale,
              }),
            }),
          ];
        })
      );
    }),
    takeUntil(action$.pipe(ofType(constructBookingByResultSetCancel.type)))
  );
}
