import React from "react";

import { useTranslation } from "hooks";
import useArrivalTime from "BookingFlow/PersonaliseYourStay/hooks/useArrivalTime";

const ArrivalTimeInput = React.forwardRef(
  ({ value = "", onChange = () => {}, disabled }, ref) => {
    const { t } = useTranslation();

    const {
      hour,
      minute,
      meridiem,
      handleChangeHour,
      handleChangeMinute,
      handleChangeMeridiem,
      HOURS,
      MINUTES,
      MERIDIEMS,
    } = useArrivalTime({ value, onChange });

    return (
      <div className="form-row mobile-label">
        <label htmlFor="arrival-time-hour">{`${t("Arrival time")}:`}</label>
        <div className="form-input--arrivalTime">
          <div className="fs-select-mask">
            <select
              id="arrival-time-hour"
              name="arrivaltimeHour"
              value={hour % 12}
              onChange={handleChangeHour}
              ref={ref}
              disabled={disabled}
            >
              {HOURS.map((h) => (
                <option key={h} label={h}>
                  {h}
                </option>
              ))}
            </select>
            <span className="icon icon-arrow-down" />
          </div>

          <div className="fs-select-mask">
            <select
              id="arrival-time-minute"
              name="arrivaltimeHour"
              value={minute}
              onChange={handleChangeMinute}
              disabled={disabled}
            >
              {MINUTES.map((m) => (
                <option key={m} label={m}>
                  {m}
                </option>
              ))}
            </select>
            <span className="icon icon-arrow-down" />
          </div>

          <div className="fs-select-mask">
            <select
              id="arrival-time-period"
              name="arrivaltimeHour"
              value={meridiem}
              onChange={handleChangeMeridiem}
              disabled={disabled}
            >
              {MERIDIEMS.map((a) => (
                <option key={a} label={a}>
                  {a}
                </option>
              ))}
            </select>
            <span className="icon icon-arrow-down" />
          </div>
        </div>
      </div>
    );
  }
);

export default ArrivalTimeInput;
