import React, { useEffect, useRef } from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";
import { OCCUPANCY_ALERTS } from "BookingFlow/utils/checkOccupancy";
import { SystemOfflineMessage } from "BookingFlow/components";
import { SEARCH_FORM_FIELDS } from "fixtures/constants";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import PropertySearch from "../PropertySearch/PropertySearch";
import GuestDropdown from "../GuestDropdown/GuestDropdown";

export default function SearchForm({
  isEmployeeRate = false,
  searchData: {
    caw: {
      cawConfig: {
        name,
        calendar: {
          checkInStart,
          checkInEnd,
          checkOutEnd,
          restrictions: { minStay, maxStay },
          availabilityEnabled,
          availabilityLastUpdated,
          restrictedDates,
          unavailableDates,
        },
        fields: {
          maxAdults,
          maxBodies,
          maxChildAge,
          maxChildren,
          maxRooms,
          minAdults,
          minChildAge,
          minChildren,
          promoHide,
          promoDisabled,
        },
        disableFlexDates,
        flexDates: flexDateOptions,
      },
    },
    areAllNecessarySearchParamsAvailable = false,
    bookingError,
    handleSubmit,
    isOfflineForProperty,
    properties,
    propertyOfflineMessage,

    hotelCode = "",
    checkIn,
    checkOut,
    rooms,
    promoCode,
    flexDatesWindow,

    setHotelCode,
    setCheckIn,
    setCheckOut,
    setRooms,
    setPromoCode,
    setFlexDatesWindow,
  },
  occupancyError,
  hasUserSelectedCheckIn,
  setHasUserSelectedCheckIn,
  initialFocus,
  selectedProperty,
}) {
  const { t } = useTranslation();

  const guestDropdownRef = useRef();
  const promoCodeRef = useRef();
  const isValid = [
    hotelCode,
    checkIn,
    checkOut,
    rooms,
    isEmployeeRate ? promoCode : true,
  ].every(Boolean);

  const hasPropertyError = bookingError?.field === SEARCH_FORM_FIELDS.PROPERTY;

  const hasPromoCodeError =
    bookingError?.field === SEARCH_FORM_FIELDS.PROMOCODE;

  useEffect(() => {
    if (hasPromoCodeError && promoCodeRef?.current) {
      promoCodeRef.current.focus();
    }
  }, [hasPromoCodeError]);

  return (
    <div className="change-search">
      <div className="container" id="search-panel-container">
        <div className="fs-search-panel">
          {isOfflineForProperty && (
            <SystemOfflineMessage
              title={propertyOfflineMessage.title}
              phone={propertyOfflineMessage.phone}
              explanation={propertyOfflineMessage.explanation}
              email={propertyOfflineMessage.email}
              buttonText={propertyOfflineMessage.buttonText}
              buttonLink={propertyOfflineMessage.buttonLink}
              buttonSuppress={propertyOfflineMessage.buttonSuppress}
            />
          )}
          {properties.length > 0 && (
            <form className="emulated-flex-gap" onSubmit={handleSubmit}>
              <PropertySearch
                label={t(name)}
                hasError={hasPropertyError}
                properties={properties}
                value={hotelCode}
                onChange={setHotelCode}
              />

              <DateRangePicker
                availabilityEnabled={availabilityEnabled}
                availabilityLastUpdated={availabilityLastUpdated}
                checkInEnd={checkInEnd}
                checkOutEnd={checkOutEnd}
                maxNumberOfNights={maxStay}
                checkInStart={checkInStart}
                minNumberOfNights={minStay}
                nextControlOutsideWidget={guestDropdownRef}
                restrictedDates={restrictedDates}
                setCheckIn={setCheckIn}
                checkIn={checkIn}
                checkOut={checkOut}
                setCheckOut={setCheckOut}
                unavailableDates={unavailableDates}
                hasUserSelectedCheckIn={hasUserSelectedCheckIn}
                setHasUserSelectedCheckIn={setHasUserSelectedCheckIn}
                hasCheckInError={
                  bookingError?.field === SEARCH_FORM_FIELDS.CHECK_IN
                }
                hasCheckOutError={
                  bookingError?.field === SEARCH_FORM_FIELDS.CHECK_OUT
                }
                initialFocus={initialFocus}
                flexDateOptions={disableFlexDates ? [] : flexDateOptions}
                flexDatesWindow={flexDatesWindow}
                setFlexDatesWindow={setFlexDatesWindow}
              />

              <GuestDropdown
                ref={guestDropdownRef}
                maxAdults={maxAdults}
                maxBodies={maxBodies}
                maxChildAge={maxChildAge}
                maxChildren={maxChildren}
                maxNumberOfRooms={maxRooms}
                minAdults={minAdults}
                minChildAge={minChildAge}
                minChildren={minChildren}
                hasError={
                  occupancyError &&
                  occupancyError?.errorCode !== OCCUPANCY_ALERTS.GROUP
                }
                propertyPhone={selectedProperty?.propertyPhone}
                globalTollFreeLink={selectedProperty?.globalTollFreeLink}
                value={rooms}
                onChange={setRooms}
              />

              {promoHide ? (
                <input
                  id="promocode"
                  type="hidden"
                  name="promoCode"
                  value={promoCode}
                  onChange={(evt) => setPromoCode(evt.target.value)}
                />
              ) : (
                <input
                  id="promocode"
                  className={classNames(
                    "promo-code emulated-flex-gap-component",
                    {
                      "search-panel-error":
                        bookingError?.field === SEARCH_FORM_FIELDS.PROMOCODE,
                    }
                  )}
                  type="text"
                  name="promoCode"
                  placeholder={t("Promo Code")}
                  aria-invalid="false"
                  aria-describedby=""
                  aria-label={t("Promo Code")}
                  value={promoCode}
                  onChange={(evt) => setPromoCode(evt.target.value)}
                  disabled={promoDisabled}
                  ref={promoCodeRef}
                />
              )}

              <button
                className="btn btn-primary find-rooms emulated-flex-gap-component"
                type="submit"
                disabled={!isValid || isOfflineForProperty}
              >
                {!areAllNecessarySearchParamsAvailable || hasPropertyError
                  ? t("Find Rooms")
                  : t("Update Search")}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
