import React from "react";
import { useForm } from "react-hook-form";
import { transducer } from "rxjs-transducer";
import { from } from "rxjs";
import { filter, distinct, map } from "rxjs/operators";
import trim from "lodash/fp/trim";
import { useSelector } from "react-redux";

import { useTranslation, useDispatchWithLocale } from "hooks";
import validateEmailList from "utils/validateEmailList";
import { cancelBooking, selectBookingError } from "store/bookings";
import { selectIsRequestLoading } from "store/apiRequestStates";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import { Button, ErrorMessage } from "Profile/components";
import TextInput from "../components/TextInput";
import Layout from "./Layout";

export default function CancelReservation({
  bookingId,
  goBackHandler,
  isWebChatEnabled,
  openWebChat,
}) {
  const { t } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();

  const { register, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: {
      emailList: "",
    },
  });

  const validateEmails = (value) => !value || validateEmailList(value);

  const getRecipients = (emailList) =>
    transducer(from(emailList.split(",")))(
      filter(Boolean),
      map(trim),
      distinct()
    );

  const onSubmit = ({ emailList = "" }) =>
    dispatchWithLocale(
      cancelBooking({
        bookingId,
        bookingEmailOptions: {
          additionalRecipients: getRecipients(emailList),
        },
      })
    );

  const error = useSelector(selectBookingError);
  const errorCode = error?.errorCode || "";

  const showLoadingIndicator = useSelector(
    selectIsRequestLoading(cancelBooking.type)
  );

  return (
    <Layout
      goBackHandler={goBackHandler}
      className="amenities-request-modal"
      openWebChat={openWebChat}
      isWebChatEnabled={isWebChatEnabled}
    >
      {showLoadingIndicator && <BookingFlowLoadingIndicator />}
      <div className="col-sm-12 col-md-10 offset-md-1">
        <h2>{t("Cancel Reservation")}</h2>
        <p className="view--upcoming-trip__update-trip-dates__intro">
          {t(
            "Would you like to receive an additional email confirmation of your cancellation?"
          )}
          <br />
          {t("Provide additional email addresses (optional)")}
        </p>
        {errorCode && (
          <div>
            <br />
            <ErrorMessage>
              {t(
                "We are unable to cancel your reservation online, please contact the Hotel or Worldwide Reservations for assistance."
              )}
            </ErrorMessage>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            identifier="emails"
            label={t("Email(s)")}
            name="emailList"
            register={register({ validate: validateEmails })}
            placeholder={t("Separate email addresses with commas.")}
          />
          <div className="form-actions">
            <Button type="submit" aria-label="Cancel reservation">
              {t("Cancel Reservation")}
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  );
}
