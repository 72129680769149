import React from "react";
import useCountries from "hooks/useCountries";

export default function PersistentReadOnlyRow({
  name,
  className,
  label,
  ariaDescribedBy,
  value,
  isCountrySelect = false,
}) {
  const id = `input-${name.replace(/\./g, "_")}`;
  const { countries } = useCountries();

  const getCountryName = (countryCode) =>
    (countries.find(({ iso2 }) => iso2 === countryCode) || { shortName: "" })
      .shortName;

  return (
    <div className="form-row string persistent">
      <label htmlFor={id}>{label}</label>
      <p
        id={id}
        name={name}
        aria-describedby={ariaDescribedBy}
        className={className}
      >
        {isCountrySelect ? getCountryName(value) : value}
      </p>
    </div>
  );
}
