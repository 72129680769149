import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import update from "lodash/fp/update";

import * as regexPatterns from "utils/regexPatterns";
import { useDispatchWithLocale, useTranslation } from "hooks";
import {
  Button,
  Checkbox,
  Dropdown,
  ErrorMessage,
  TextInput,
} from "Profile/components";
import { NEW_EMAIL_ID } from "fixtures/constants";
import { createEmailVerifyCode } from "store/profile";
import {
  selectIsRequestFulfilled,
  selectIsRequestLoading,
} from "store/apiRequestStates";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import Tooltip from "../Tooltip";
import EmailVerificationModal from "./EmailVerificationModal";

const emailTypes = [
  { label: "Personal", value: "HO" },
  { label: "Business", value: "BU" },
  { label: "Other", value: "TP" },
];

function EmailForm({
  canBeDeleted = false,
  email: {
    defaultEmail,
    email,
    emailType,
    isLogin,
    salesForceId,
    validatedEmail,
  },
  formState,
  identifier,
  onSubmit,
  register,
  errors = {},
  baseError,
  onDelete = () => {},
  showLoadingIndicator,
}) {
  const { t } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();

  const [isVerificationModalVisible, setIsVerificationModalVisible] =
    useState(false);

  const handleShowVerificationModal = () =>
    dispatchWithLocale(createEmailVerifyCode({ email }));

  const isNotNew = salesForceId !== NEW_EMAIL_ID;

  const wasCreateEmailVerifyCodeFulfilled = useSelector(
    selectIsRequestFulfilled(createEmailVerifyCode.type)
  );
  const createEmailVerifyCodeIsLoading = useSelector(
    selectIsRequestLoading(createEmailVerifyCode.type)
  );

  useEffect(() => {
    if (wasCreateEmailVerifyCodeFulfilled) {
      setIsVerificationModalVisible(true);
    }
  }, [wasCreateEmailVerifyCodeFulfilled]);

  return (
    <>
      {(showLoadingIndicator || createEmailVerifyCodeIsLoading) && (
        <BookingFlowLoadingIndicator />
      )}

      {baseError && <ErrorMessage>{t(baseError.errorCode)}</ErrorMessage>}

      <form
        className="fs-profile-email-address-form"
        onSubmit={onSubmit}
        noValidate
      >
        <div className="row">
          <div className="col-sm-12">
            <TextInput
              identifier={`${identifier}-email`}
              type="email"
              label={t("Email")}
              placeholder={t("Email")}
              name="emailData.email"
              value={email}
              disabled={isLogin || defaultEmail}
              register={register({
                required: true,
                pattern: regexPatterns.email,
              })}
              error={
                errors.emailData?.email && t("Please enter your email address.")
              }
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Dropdown
              label={t("Type")}
              name="emailData.emailType"
              value={emailType}
              identifier={`${identifier}-type`}
              options={emailTypes.map(update(["label"], t))}
              register={register({
                required: true,
              })}
              required
              error={
                errors.emailData?.emailType &&
                t("Please select the type of email address.")
              }
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            {!validatedEmail && isNotNew && (
              <div className="verify-button">
                <Button kind="link" onClick={handleShowVerificationModal}>
                  {t("Send verification")}
                </Button>{" "}
                <Tooltip
                  message={t(
                    "Verify your email address to ensure you can receive all Four Seasons communications, booking confirmations and reminders."
                  )}
                />
              </div>
            )}

            {isNotNew && validatedEmail && (
              <div className="fs-profile-email-address-form__checkboxes">
                <div className="fs-profile-email-address-form__checkbox">
                  <Checkbox
                    identifier={`${identifier}-primary`}
                    name="emailData.defaultEmail"
                    register={register}
                    disabled={defaultEmail}
                  >
                    {t("PRIMARY EMAIL")}
                  </Checkbox>{" "}
                  <Tooltip
                    message={t(
                      "We send all trip related and marketing (if opted in) communications to your primary email."
                    )}
                  />
                </div>
                <div className="fs-profile-email-address-form__checkbox">
                  <Checkbox
                    identifier={`${identifier}-login`}
                    name="emailData.isLogin"
                    register={register}
                    disabled={isLogin}
                  >
                    {t("Login email")}
                  </Checkbox>{" "}
                  <Tooltip
                    message={t(
                      "This is your preferred email to login to your Four Seasons online profile."
                    )}
                  />
                </div>
              </div>
            )}

            <div className="fs-toggle-block__actions">
              {canBeDeleted && (
                <Button kind="link" onClick={onDelete}>
                  {t("Delete")}
                </Button>
              )}

              <Button
                type="submit"
                aria-label={!isNotNew ? t("Save email") : t("Update email")}
                disabled={!formState.isValid}
              >
                {!isNotNew ? t("Save") : t("Update")}
              </Button>
            </div>
          </div>
        </div>
      </form>

      {isVerificationModalVisible && (
        <div
          onClick={(evt) => evt.stopPropagation()}
          onKeyDown={(evt) => evt.stopPropagation()}
          role="button"
          tabIndex={-1}
        >
          <EmailVerificationModal
            email={email}
            close={() => setIsVerificationModalVisible(false)}
          />
        </div>
      )}
    </>
  );
}

export default EmailForm;
