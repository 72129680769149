import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";

import { getPaymentOptions$ } from "api/alipay";
import {
  fetchPaymentOptions,
  fetchPaymentOptionsCancel,
  fetchPaymentOptionsFailed,
  fetchPaymentOptionsFulfilled,
} from "./paymentOptions.slice";

const fetchPaymentOptionsEpic = (action$) =>
  action$.pipe(
    ofType(fetchPaymentOptions.type),

    switchMap(({ payload }) =>
      getPaymentOptions$(payload).pipe(
        map((ajaxResponse) =>
          fetchPaymentOptionsFulfilled(ajaxResponse.response)
        ),
        catchError((error) =>
          of(
            fetchPaymentOptionsFailed({
              error:
                error.response?.alipayChina?.error ||
                error.response?.message ||
                "Unknown error when fetching payment options",
            })
          )
        ),
        takeUntil(action$.pipe(ofType(fetchPaymentOptionsCancel.type)))
      )
    )
  );

export default combineEpics(fetchPaymentOptionsEpic);
