import env from "config/env";

const { EMPLOYEE_OVERFLOW_PATH, PUBLIC_OVERFLOW_PATH, STATIC_SITE_URL } = env;

const getShouldThrottle = ({ globalSettings = {}, state = {} }) => {
  if (
    !globalSettings.throttleEnabled ||
    !PUBLIC_OVERFLOW_PATH ||
    !EMPLOYEE_OVERFLOW_PATH ||
    !STATIC_SITE_URL
  ) {
    return false;
  }

  if (state.router?.location?.pathname?.endsWith("/confirm-your-stay")) {
    if (state.bookings?.bookingInProgressId) {
      return false;
    }
  } else if (
    state.router?.location?.pathname?.endsWith("/personalize-your-stay")
  ) {
    if (state.bookings?.bookingInProgressId) {
      return false;
    }
  }

  return true;
};

export default getShouldThrottle;
