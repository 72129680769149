import React, { useContext } from "react";

import { useTranslation } from "hooks";
import { MediaContext } from "contexts/MediaContext";
import Modal from "BookingFlow/components/Modal";

export default function PreferencesSavedModal({
  returnLink = "/",
  returnLinkLabel = "Return to Homepage",
  closeModal = () => {},
}) {
  const { t } = useTranslation();
  const media = useContext(MediaContext);

  return (
    <Modal
      title={t("Preferences Saved")}
      className="new-modal--preferences-saved"
      closeModal={closeModal}
      renderCloseButton={!media.isLessThan.sm}
    >
      <p>
        {t(
          "Your personal preferences have been saved, we look forward to greeting you upon your arrival."
        )}
      </p>

      <a className="btn btn--primary" href={returnLink}>
        {t(returnLinkLabel)}
      </a>
    </Modal>
  );
}
