import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";

import env from "config/env";

const { PREFERRED_PARTNER_DOMAIN } = env;

export default function getPreferredPartnerDetails$({ searchParams }) {
  const { ppId, pullTAInfo } = searchParams;
  return ajax({
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    withCredentials: true,
    method: "POST",
    url: `${PREFERRED_PARTNER_DOMAIN}/bin/profilestatusservlet`,
    body: `ppId=${ppId ? encodeURIComponent(ppId) : ""}&pullTAInfo=${
      pullTAInfo ? encodeURIComponent(pullTAInfo) : ""
    }`,
  }).pipe(pluck("response"));
}
