import getOr from "lodash/fp/getOr";

import { convert12HourTo24hour } from "utils";

export default (propertyContent) =>
  getOr(convert12HourTo24hour(propertyContent.checkInTime), [
    "hotelProducts",
    0,
    "checkInTime",
  ]);
