import React from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";
import { Select, FormRow } from "BookingFlow/components";

import FormRowCreditCardNumber from "./FormRowCreditCardNumber";
import CreditCardImages from "./CreditCardImages";

export default function CreditCardDetails({
  register,
  control,
  errors,
  showLegend = true,
  showCardList = true,
  readOnlyFields = [],
}) {
  const { t } = useTranslation();

  return (
    <fieldset
      name="creditCardDetails"
      className={classNames({
        "has-errors": Object.keys(errors).length > 0,
      })}
    >
      {showLegend && <legend>{t("Credit Card Details")}</legend>}

      <FormRow
        id="cardName"
        name="creditCard.cardHolderName"
        className="cc-name-row"
        label={`${t("Name On Card")}*`}
        errorMessage={
          errors.cardHolderName && t("Please enter credit card holder name.")
        }
        ariaDescribedBy="ttCardName"
        register={register()}
        readOnly={readOnlyFields.includes("cardName")}
      />

      <FormRowCreditCardNumber
        id="cardNumber"
        name="creditCard.number"
        type="tel"
        className="cc-number-row"
        label={`${t("Credit Card Number")}*`}
        placeholder={`${t("Credit Card Number")}*`}
        errorMessage={
          errors.number && t("The credit card number entered is not valid.")
        }
        ariaDescribedBy="ttCardNumber"
        control={control}
        readOnly={readOnlyFields.includes("number")}
      />

      <div className="form-row credit-card-date">
        <label id="ttCardExpiry" htmlFor="cardExpiry">
          {t("Credit Card Expiry Date")}
        </label>

        <div
          className={classNames("form-row select-box card-month", {
            "has-errors": Boolean(errors.expiryDateMonth),
          })}
        >
          <label
            id="labelCardExpiryMonth"
            className="card-month"
            htmlFor="cardExpiryMonth"
          >
            {t("Month")}
          </label>
          <Select
            id="cardExpiryMonth"
            name="creditCard.expiryDateMonth"
            aria-labelledby="labelCardExpiryMonth"
            aria-describedby="ttCardExpiry"
            aria-invalid={Boolean(errors.expiryDateMonth)}
            required
            register={register()}
            style={{ margin: 0 }}
            defaultValue=""
            includeBlank
            readOnly={readOnlyFields.includes("expiryDateMonth")}
          >
            {[...new Array(12)].map((_, i) => {
              const j = i + 1;
              const value = j < 10 ? `0${j}` : j.toString();
              return (
                <option key={value} value={value}>
                  {value}
                </option>
              );
            })}
          </Select>
          {errors.expiryDateMonth && (
            <div className="inline-error">
              <span aria-hidden="true" className="icon">
                !
              </span>
            </div>
          )}
        </div>

        <div
          className={classNames("form-row select-box card-year", {
            "has-errors": Boolean(errors.expiryDateYear),
          })}
        >
          <label
            id="labelCardExpiryMonth"
            className="card-year"
            htmlFor="cardExpiryYear"
          >
            {t("Year")}
          </label>
          <Select
            id="cardExpiryYear"
            name="creditCard.expiryDateYear"
            aria-labelledby="labelCardExpiryYear"
            aria-describedby="ttCardExpiry"
            aria-invalid={Boolean(errors.expiryDateYear)}
            required="required"
            register={register()}
            style={{ margin: 0 }}
            defaultValue=""
            includeBlank
            readOnly={readOnlyFields.includes("expiryDateYear")}
          >
            {[...new Array(20)].map((_, index) => {
              const value = (new Date().getFullYear() + index).toString();
              return (
                <option key={value} value={value.substr(2)}>
                  {value}
                </option>
              );
            })}
          </Select>

          {errors.expiryDateYear && (
            <div className="inline-error">
              <span aria-hidden="true" className="icon">
                !
              </span>
            </div>
          )}
        </div>
      </div>

      {showCardList && (
        <div className="form-row credit-cards">
          <span>{t("Accepted Cards")}</span>
          <CreditCardImages />
        </div>
      )}
    </fieldset>
  );
}
