import React from "react";
import nl2br from "react-nl2br";

import env from "config/env";
import { useTranslation } from "hooks";
import { getPropertyAddress } from "utils";

const { STATIC_SITE_URL } = env;

function PropertyContactDetails({ propertyContent }) {
  const { t } = useTranslation();

  const { urlWeb, typeName, name, reservationPhone, propertyPhone } =
    propertyContent;

  const reservationOrPropertyPhoneNumber = reservationPhone || propertyPhone;

  return (
    <div className="col-md-5 error-contact-details">
      <h2>{t("Contact Details")}</h2>
      <div className="row">
        <div className="col-xs-6 col-md-6">
          <p>
            <a href={STATIC_SITE_URL + urlWeb}>{`${typeName} ${name}`}</a>
            <br />
            {reservationOrPropertyPhoneNumber && (
              <a href={`tel:${reservationOrPropertyPhoneNumber}`}>
                {reservationOrPropertyPhoneNumber}
              </a>
            )}
          </p>
        </div>
        <div className="col-xs-6">
          <p>{nl2br(getPropertyAddress(propertyContent))}</p>
        </div>
      </div>
    </div>
  );
}

export default PropertyContactDetails;
