import { ajax } from "rxjs/ajax";
import env from "config/env";

const { ALIPAY_API_BASE_URL } = env;

export default function fetchPaymentOptions$(requestBody) {
  return ajax({
    url: `${ALIPAY_API_BASE_URL}/paymentOptions`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: requestBody,
    withCredentials: true,
  });
}

/*
// AJD: TODO - Mock the API call for now
import { Observable } from "rxjs";

export default function fetchPaymentOptions$() {
  return new Observable((subscriber) => {
    setTimeout(() => {
      subscriber.next({
        alipayChina: {
          desktopPaymentUrl:
            "Https://openapi.alipay.com/gateway.do?method=alipay.trade.page.pay&app_id=2018052560275008&charset=utf-8&version=1.0&sign_type=RSA2&timestamp=2020-10-22%2008%3A56%3A04&return_url=https%3A%2F%2Freservations.fourseasons.com%2Fconfirm-your-stay&sign=mMnqOEWR5yBXBQ4bdNUwB4CMh66FLO2QPuZJAZU682uC4eompr9gB5HggjRIChGVzEBkjCY9xiHEyMXcicCLGxplSBx5MPBzi3RgLSsf%2FyxIVuKQVD3ip3SP%2FIc0HuNB%2BXsvLd9lIfLix%2FLSgOckcoq02ztJI1MyP9YykEzie1j%2BzitjmnNuerot91fWviPU0a02PjZ%2FwzVX6hQlt%2Fsjlvy47f7sxBShhrcijZ8gPumennRsu3h5U6t4WmkSjtElGJdFOhvCFBWcRSSzzZQgrQoNLPj%2BtRQM9mtsy4AFfH9ITaybmU6WseO3HcJZHPUGRzLx8upkEmxuK7%2BujPMbJg%3D%3D&alipay_sdk=alipay-sdk-nodejs-3.1.1&biz_content=%7B%22subject%22%3A%22Four%20Seasons%20Hotel%20(Beijing)%22%2C%22body%22%3A%22Check-in%3A%202020-10-22%2C%20Nights%3A%201%22%2C%22total_amount%22%3A%221865.60%22%2C%22product_code%22%3A%22FAST_INSTANT_TRADE_PAY%22%2C%22out_trade_no%22%3A%224afad61d42431532cb21cad9cde98fde%22%2C%22quit_url%22%3A%22https%3A%2F%2Freservations.fourseasons.com%2Fconfirm-your-stay%3FpaymentAction%3Dabort%26paymentProvider%3DalipayChina%22%7D",
          enabled: true,
          mobilePaymentUrl:
            "https://mclient.alipay.com/cashier/mobilepay.htm?alipay_exterface_invoke_assign_target=invoke_3ee82413bf4aee03240673bc98c60533&alipay_exterface_invoke_assign_sign=_u_rn7yq6%2F_sj17bgm_g_v_n2aq_g_x6a_yx_cwul53iv_bk_lcn0_hik_v_yqyz9%2Fa_q_q%3D%3D",
        },
      });
      subscriber.complete();
    }, 5000);
  });
}
*/
