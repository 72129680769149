import React from "react";
import classNames from "classnames";
import ThemeContextProvider, { THEMES } from "contexts/ThemeContext";
import { useTranslation } from "hooks";
import { ChatButton } from "components/WebChat";
import ToastNotification from "components/ToastNotification";

export default function Layout({
  children,
  className,
  goBackHandler,
  isWebChatEnabled,
  openWebChat,
}) {
  const { t, i18n, locale } = useTranslation();

  return (
    <ThemeContextProvider theme={THEMES.LIGHT}>
      <ToastNotification />
      <div className={`layout--profile ${locale} ${i18n.dir(i18n.language)}`}>
        <div
          className={classNames(
            "layout--profile-fullscreen",
            "layout--profile-fullscreen--light",
            "view--upcoming-trip__modal",
            className
          )}
        >
          <div className="layout--profile-fullscreen__top-bar">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  {goBackHandler && (
                    <button
                      className="btn-back"
                      type="button"
                      onClick={goBackHandler}
                    >
                      <span>{t("Back")}</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="layout--profile-fullscreen__body">
            <div className="container">
              <div className="row">{children}</div>
            </div>
          </div>
        </div>
      </div>

      {isWebChatEnabled && <ChatButton onClick={openWebChat} />}
    </ThemeContextProvider>
  );
}
