import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "hooks";
import { MediaContext } from "contexts/MediaContext";
import { QuickLinks } from "components";
import { selectProperty } from "store/properties";
import { leadWithCareLinkClicked } from "store/analyticEvents";
import FullScreenLayout from "../../FullScreenLayout";
import DownloadApp from "./DownloadApp";

function CheckInConfirmation({
  email,
  propertyData: { name, horizontalImage, owsCode: hotelCode },
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const media = useContext(MediaContext);

  const { propertyStatusLink, propertyTravelAdvisoryLink } = useSelector(
    selectProperty(hotelCode)
  );

  const quickLinks = [
    propertyStatusLink && {
      href: propertyStatusLink,
      icon: "/images/profiles_2018/property_info.svg",
      title: t("Property Information"),
      description: t("Check for latest property related updates"),
      onClick: () => {
        dispatch(
          leadWithCareLinkClicked({
            linkName: "Property Information",
          })
        );
      },
    },
    propertyTravelAdvisoryLink && {
      href: propertyTravelAdvisoryLink,
      icon: "/images/profiles_2018/Travel_Advisory.svg",
      title: t("Travel Advisory"),
      description: t(
        "Review travel advisories and necessary documents for your trip"
      ),
      onClick: () => {
        dispatch(
          leadWithCareLinkClicked({
            linkName: "Travel Advisory",
          })
        );
      },
    },
  ].filter(Boolean);

  return (
    <FullScreenLayout backgroundImageSrc={horizontalImage}>
      <div className="step-2 confirmation">
        <div className="step-2__inner">
          <div className="step-2__content">
            <h1 className="ty-h2">{t("CHECK IN COMPLETED")}</h1>
            <p className="ty-c1">
              {t("YOU ARE NOW CHECKED IN TO {0}", [name])}
            </p>
            <p>
              {t(
                "You will receive an email notification at {0} when your room is ready.",
                [email]
              )}
            </p>
          </div>
        </div>

        {quickLinks.length > 0 && (
          <QuickLinks
            links={quickLinks}
            className="quick-links--web-check-in"
          />
        )}
      </div>

      <div className="mobile-app-promotion">
        {!media.isLessThan.lg && (
          <div className="desktop">
            <div className="text">
              <h1 className="ty-h1">
                {t("Your Personalized stay is a tap away")}
              </h1>
              <p>
                {t(
                  "Experience signature Four Seasons service at your finger tips with the Four Seasons App."
                )}
              </p>
              <p>{t("Don't have the app installed? Download it now:")}</p>
              <DownloadApp />
            </div>
            <div className="image">
              <img src="/images/app-mockup-desktop.png" alt={t("App")} />
            </div>
          </div>
        )}

        {media.isLessThan.lg && (
          <div className="mobile">
            <h1 className="ty-h1">
              {t("Your Personalized stay is a tap away")}
            </h1>
            <img
              className="image"
              src="/images/app-mockup-mobile.png"
              alt={t("App")}
            />
            <p>
              {t(
                "Experience signature Four Seasons service at your finger tips with the Four Seasons App."
              )}
            </p>
            <p>{t("Don't have the app installed? Download it now:")}</p>
            <DownloadApp />
          </div>
        )}
        <div className="welcome">
          <h2 className="ty-h1">
            {t("We look forward to welcoming you at Four Seasons")}
          </h2>
        </div>
      </div>
    </FullScreenLayout>
  );
}

export default CheckInConfirmation;
