import React from "react";

import { useTranslation } from "hooks";

export default function DepartureCard() {
  const { t } = useTranslation();

  return (
    <div className="itinerary__item itinerary__item--departure">
      <p className="itinerary__item__title">{t("Departure")}</p>
    </div>
  );
}
