import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

export default function Tab({ id, to, isSelected, children }) {
  return (
    <Link
      aria-controls="tab-panel-booking-type"
      aria-selected={isSelected}
      role="tab"
      className={classNames("btn btn-group__third", {
        "is-active": isSelected,
      })}
      id={id}
      to={to}
    >
      {children}
    </Link>
  );
}
