import parse from "date-fns/parse";
import format from "date-fns/format";

export default function convert12HourTo24Hour(
  value,
  outputFormat = "HH:mm:ss.SSS"
) {
  // Based on: https://git.workbench.fourseasons.com/developers/fourseasons-rbf/-/blob/master/client/src/scripts/booking-flow/step-4--personalize-your-stay/personalize-your-stay-controller.js#L407
  const date = new Date();
  const fallbackTime = parse("4:00 PM", "h:mm a", date);
  const commonFormats = ["h:mm a", "HH:mm", "HH.mm 'Uhr'"];

  const [parsed] = [
    ...commonFormats
      .map((f) => parse(value, f, date))
      .filter((a) => !Number.isNaN(a?.getTime())),
    fallbackTime,
  ];

  return format(parsed, outputFormat);
}
