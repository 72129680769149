import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";

import { MediaContext } from "contexts/MediaContext";
import { useTranslation } from "hooks";
import getFullPageTitle from "utils/getFullPageTitle";
import { FsHeader } from "Profile/components";
import { getIsoCodeForLocale } from "config/languages";
import { getHMTLTagClassNames } from "utils";
import { webCheckInPath } from "./webCheckInRoutes";

function FullScreenLayout({
  backgroundImageSrc,
  children,
  hideLanguageSelector = true,
  languagesFilter,
}) {
  const { i18n, locale, t } = useTranslation();
  const media = useContext(MediaContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <Helmet>
        <html
          lang={getIsoCodeForLocale(i18n.language)}
          className={getHMTLTagClassNames({
            locale,
            dir: i18n.dir(i18n.language),
            kind: "fs-profile-2018",
          })}
        />
        <title>{getFullPageTitle(t, webCheckInPath.getPageTitle())}</title>
        <body className="web-checkin" />
      </Helmet>
      <FsHeader
        hideLanguageSelector={hideLanguageSelector}
        languagesFilter={languagesFilter}
        isMobile={media.isLessThan.sm}
        enableProfileLinkFlyout={false}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        hideProfileFlyout
      />
      <div className="full-screen">
        <div
          className="background-image"
          style={
            backgroundImageSrc && {
              backgroundImage: `url(${backgroundImageSrc})`,
            }
          }
        />
        <div className="background-image-gradient" />
        <main>{children}</main>
      </div>
    </>
  );
}

export default FullScreenLayout;
