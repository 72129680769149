import React from "react";

import { DictionaryKeyContent } from "BookingFlow/components";

export default function CancellationTerms({ dictionaryKeys }) {
  return (
    <div className="cancellation-terms">
      {dictionaryKeys.sameDayHeading && (
        <p className="same-day-heading">
          <DictionaryKeyContent dict={dictionaryKeys.sameDayHeading} />
        </p>
      )}

      {dictionaryKeys.sameDayMessage && (
        <p>
          <DictionaryKeyContent dict={dictionaryKeys.sameDayMessage} />
        </p>
      )}

      {dictionaryKeys.cancelationMessage && (
        <p>
          <DictionaryKeyContent dict={dictionaryKeys.cancelationMessage} />
        </p>
      )}
    </div>
  );
}
