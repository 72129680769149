import React from "react";
import { Controller } from "react-hook-form";
import classNames from "classnames";

import { useTranslation } from "hooks";
import {
  FormRow,
  FormRowPhoneNumber,
  PersistentReadOnlyRow,
} from "BookingFlow/components";
import { selectIsUserPersistent } from "store/profile";
import { useSelector } from "react-redux";

export default function BookerDetails({
  register,
  control,
  errors = {},
  readOnlyFields = [],
}) {
  const { t } = useTranslation();
  const isUserPersistent = useSelector(selectIsUserPersistent);

  return (
    <div>
      <fieldset
        name="bookerDetails"
        className={classNames({
          "has-errors": Object.keys(errors).length > 0,
        })}
      >
        <legend>{t("Booker Details")}</legend>
        {isUserPersistent && readOnlyFields.includes("firstName") ? (
          <PersistentReadOnlyRow
            name="bookerDetails.firstName"
            className="first-name-row"
            label={`${t("First Name")}*`}
            ariaDescribedBy="ttGuestFirstName"
            value={control?.defaultValuesRef?.current?.bookerDetails?.firstName}
          />
        ) : (
          <FormRow
            name="bookerDetails.firstName"
            className="first-name-row"
            label={`${t("First Name")}*`}
            errorMessage={
              errors.firstName && t("Please enter a valid first name.")
            }
            ariaDescribedBy="ttGuestFirstName"
            register={register()}
            readOnly={readOnlyFields.includes("firstName")}
          />
        )}

        {isUserPersistent && readOnlyFields.includes("surname") ? (
          <PersistentReadOnlyRow
            name="bookerDetails.surname"
            className="last-name-row"
            label={`${t("Last Name")}*`}
            ariaDescribedBy="ttGuestLastName"
            value={control?.defaultValuesRef?.current?.bookerDetails?.surname}
          />
        ) : (
          <FormRow
            name="bookerDetails.surname"
            className="last-name-row"
            label={`${t("Last Name")}*`}
            errorMessage={
              errors.surname && t("Please enter a valid last name.")
            }
            ariaDescribedBy="ttGuestLastName"
            register={register()}
            readOnly={readOnlyFields.includes("surname")}
          />
        )}

        {isUserPersistent && readOnlyFields.includes("phoneNumber") ? (
          <PersistentReadOnlyRow
            name="bookerDetails.phoneNumber"
            label={`${t("Mobile Phone Number")}*`}
            ariaDescribedBy="ttGuestPhone2"
            value={
              control?.defaultValuesRef?.current?.bookerDetails?.phoneNumber
            }
          />
        ) : (
          <Controller
            name="bookerDetails.phoneNumber"
            control={control}
            rules={{ required: true }}
            render={({ ref: _ignoredRef, ...props }) => (
              <FormRowPhoneNumber
                errorMessage={
                  errors.phoneNumber && t("Please enter a mobile phone number.")
                }
                readOnly={readOnlyFields.includes("phoneNumber")}
                {...props}
              />
            )}
          />
        )}

        {isUserPersistent && readOnlyFields.includes("email") ? (
          <PersistentReadOnlyRow
            name="bookerDetails.email"
            className="email-row"
            label={`${t("Email Address")}*`}
            ariaDescribedBy="ttGuestEmail1"
            value={control?.defaultValuesRef?.current?.bookerDetails?.email}
          />
        ) : (
          <FormRow
            name="bookerDetails.email"
            className="email-row"
            label={`${t("Email Address")}*`}
            errorMessage={errors.email && t("Please enter a email address.")}
            ariaDescribedBy="ttGuestEmail1"
            type="email"
            register={register()}
            readOnly={readOnlyFields.includes("email")}
          />
        )}

        {isUserPersistent && readOnlyFields.includes("email") ? (
          <PersistentReadOnlyRow
            name="bookerDetails.confirmEmail"
            className="email-confirmation-row"
            label={`${t("Confirm Email Address")}*`}
            ariaDescribedBy="ttGuestEmail2"
            value={control?.defaultValuesRef?.current?.bookerDetails?.email}
          />
        ) : (
          <FormRow
            name="bookerDetails.confirmEmail"
            className="email-confirmation-row"
            label={`${t("Confirm Email Address")}*`}
            errorMessage={
              errors.confirmEmail && t("Please re-enter your email address")
            }
            ariaDescribedBy="ttGuestEmail2"
            type="email"
            register={register()}
            readOnly={readOnlyFields.includes("confirmEmail")}
          />
        )}
      </fieldset>
    </div>
  );
}
