import { ajax } from "rxjs/ajax";

import env from "config/env";
import sortPackages from "./sortPackages";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;
const TRETAIL_API_BOOKINGS_URL = `${TRETAIL_API_BASE_URL}/bookings`;

const addHotelProduct$ = ({
  bookingId,
  resultSetId,
  selectedPackages,
  locale,
}) =>
  ajax({
    ...basePostRequest({ locale }),
    url: `${TRETAIL_API_BOOKINGS_URL}/${bookingId}/products/hotel`,
    body: {
      resultSetId,
      hotels: sortPackages(selectedPackages).map(({ priceId, resultId }) => ({
        priceId,
        resultId,
      })),
    },
  });

export default addHotelProduct$;
