import React from "react";

import { useTranslation } from "hooks";
import Occupancy from "./Occupancy";
import Footer from "./Footer";

function RoomOptions({
  roomsRef,
  roomsOnBlur,
  rooms,
  handleRemoveRoomClick,
  maxBodies,
  minAdults,
  maxAdults,
  minChildren,
  maxChildren,
  minChildAge,
  maxChildAge,
  handleIncrementAdult,
  handleDecrementAdult,
  handleIncrementChild,
  handleDecrementChild,
  maxNumberOfRooms,
  handleAddRoomClick,
  propertyPhone,
  globalTollFreeLink,
}) {
  const { t } = useTranslation();

  return (
    <div className="room-options" ref={roomsRef} onBlur={roomsOnBlur}>
      {rooms.map((item, index) => {
        return (
          <div key={index} className="guests-dropdown-option">
            <fieldset className="room-occupancy-fieldset multi-room">
              <legend className="room-heading">
                {rooms.length > 1 && (
                  <>
                    {t("Room {0}", [index + 1])}
                    <button
                      className="icon icon-error remove-room"
                      type="button"
                      onClick={() => handleRemoveRoomClick(index)}
                      aria-label={`${t("Remove")} ${t("Room {0}", [
                        index + 1,
                      ])}`}
                    />
                  </>
                )}
              </legend>
              <div className="guest-inputs">
                <Occupancy
                  label="Adult guests"
                  value={item.adults}
                  maximum={Math.min(maxBodies - item.children, maxAdults)}
                  minimum={minAdults}
                  roomIndex={index}
                  count={item.adults}
                  isAdult
                  handleIncrement={() => handleIncrementAdult(index)}
                  handleDecrement={() => handleDecrementAdult(index)}
                />
                <Occupancy
                  label="Children guests"
                  value={item.children}
                  maximum={Math.min(maxBodies - item.adults, maxChildren)}
                  minimum={minChildren}
                  roomIndex={index}
                  count={item.children}
                  handleIncrement={() => handleIncrementChild(index)}
                  handleDecrement={() => handleDecrementChild(index)}
                  minChildAge={minChildAge}
                  maxChildAge={maxChildAge}
                />
              </div>
            </fieldset>
          </div>
        );
      })}
      <Footer
        canAddRooms={rooms.length < maxNumberOfRooms}
        maxNumberOfBookableRooms={maxNumberOfRooms}
        handleAddRoomClick={handleAddRoomClick}
        propertyPhone={propertyPhone}
        globalTollFreeLink={globalTollFreeLink}
      />
    </div>
  );
}

export default RoomOptions;
