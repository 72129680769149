/* eslint-disable no-throw-literal */
import { catchError } from "rxjs/operators";
import { of } from "rxjs";

import { TECHNICAL_ERROR_MESSAGE } from "fixtures/constants";
import { fetchAppStatusFulfilled } from "store/appStatus";

export default function catchInternalServerError() {
  return function (source) {
    return source.pipe(
      catchError((error) => {
        if (error.appStatus) {
          throw error;
        }

        if (!error?.response) {
          return of(
            fetchAppStatusFulfilled({ message: TECHNICAL_ERROR_MESSAGE })
          );
        }

        if (error?.response?.message === "Internal Server Error") {
          return of(fetchAppStatusFulfilled(error.response));
        }

        throw error;
      })
    );
  };
}
