import React, { useContext } from "react";

import BookerDetails from "./BookerDetails";
import GuestDetails from "./GuestDetails";
import CreditCardDetails from "./CreditCardDetails";
import TermsAndConditions from "./TermsAndConditions";
import MappingLayerContext from "../../mappingLayerContext";

export default function BookingForSomeoneElse({
  register,
  control,
  errors,
  readOnlyFields,
  persistentValues,
}) {
  const { suppressPaymentOptions } = useContext(MappingLayerContext);

  return (
    <>
      <BookerDetails
        register={register}
        control={control}
        errors={errors.bookerDetails || {}}
        readOnlyFields={readOnlyFields.bookerDetails || []}
      />
      <GuestDetails
        register={register}
        control={control}
        errors={errors.guestDetails || {}}
        includeSendConfirmation
        readOnlyFields={readOnlyFields.guestDetails || []}
        persistentValues={persistentValues}
      />
      {!suppressPaymentOptions && (
        <CreditCardDetails
          register={register}
          control={control}
          errors={errors.creditCard || {}}
          readOnlyFields={readOnlyFields.creditCard || []}
        />
      )}
      <TermsAndConditions
        register={register}
        errors={errors.termsAndConditions || {}}
        readOnlyFields={readOnlyFields.termsAndConditions || []}
      />
    </>
  );
}
