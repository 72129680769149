/* eslint-disable no-throw-literal */
import { ajax } from "rxjs/ajax";
import { EMPTY, of } from "rxjs";
import { map, pluck } from "rxjs/operators";

import env from "config/env";
import { basePostRequest } from "../../tretail/baseRequest";

const { EMAIL_API_BASE_URL, EMAIL_ADD_EXISTING_RESERVATION_ENABLED } = env;

export default function addExistingReservation$({ locale = "en", bookingId }) {
  return EMAIL_ADD_EXISTING_RESERVATION_ENABLED
    ? ajax({
        ...basePostRequest({ locale }),
        url: `${EMAIL_API_BASE_URL}/addExistingReservation`,
        body: {
          bookingId,
        },
      }).pipe(
        pluck("response"),
        map((response) => {
          if (!response.success) {
            throw "Failed to send addExistingReservation email";
          }
          return response;
        })
      )
    : of(EMPTY);
}
