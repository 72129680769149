import { useState } from "react";
import { useSelector } from "react-redux";

const openForms = {
  NONE: "none",
  ADD: "add",
  EDIT: (i) => `edit:${i}`,
};

export default function useBlock({ max, selector }) {
  const items = useSelector(selector);

  const [openForm, setOpenForm] = useState(openForms.NONE);

  return {
    onAddClick: () =>
      setOpenForm(openForm === openForms.ADD ? openForms.NONE : openForms.ADD),
    isNewDetailFormOpen: openForm === openForms.ADD,
    close: () => setOpenForm(openForms.NONE),
    isEditing: (index) => openForm === openForms.EDIT(index),
    toggleEditing: (index) =>
      setOpenForm(
        openForm === openForms.EDIT(index)
          ? openForms.NONE
          : openForms.EDIT(index)
      ),
    items,
    canAdd: items.length < max,
  };
}
