import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { useTranslation } from "hooks";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import ThemeContextProvider, { THEMES } from "contexts/ThemeContext";
import * as profileRoutes from "Profile/profileRoutes";
import { selectProfile } from "store/profile";
import { selectCountriesContent } from "store/countriesContent";
import ToastNotification from "components/ToastNotification";
import UserDetails from "./components/UserDetails";
import Main from "./components/Main";
import Navigations from "./components/Navigations";

function EditProfileView() {
  const { t, locale, i18n } = useTranslation();

  // REQUIRED DATA FOR VIEW
  // profile
  // countries
  const hasProfile = Boolean(useSelector(selectProfile));
  const hasCountries = useSelector(selectCountriesContent).length > 0;

  if (!hasProfile || !hasCountries) {
    return <BookingFlowLoadingIndicator opaque />;
  }

  return (
    <ThemeContextProvider theme={THEMES.DARK}>
      <ToastNotification />
      <div className={`layout--profile ${i18n.dir(i18n.language)}`}>
        <div className="layout--profile-fullscreen view--profile-edit">
          <div className="layout--profile-fullscreen__top-bar">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <Link
                    className="btn-back"
                    to={profileRoutes.profilePath.to({ locale })}
                  >
                    <span>{t("Back")}</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="view--profile-edit__navigation view--profile-edit__navigation--inline">
                <Navigations />
              </div>
            </div>
          </div>
          <div className="layout--profile-fullscreen__body">
            <UserDetails />
            <Main />
          </div>
        </div>
      </div>
    </ThemeContextProvider>
  );
}

export default EditProfileView;
