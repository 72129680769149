import { useEffect, useState } from "react";
import { isInChina } from "utils/geoIP";
import { getRecaptchaLocale } from "config/languages";
import env from "config/env";
import { useIsMounted } from "../../hooks";

const {
  GOOGLE_RECAPTCHA_PUBLIC_KEY,
  CHINESE_RECAPTCHA_SCRIPT_PATH,
  GOOGLE_RECAPTCHA_SCRIPT_PATH,
} = env;

export default function useRecaptcha({ locale }) {
  const recaptchaElementId = "recaptcha";
  const recaptchaOnloadCallbackName = "recaptchaOnload";
  const recaptchaCallbackName = "recaptchaSubmitConfirmation";
  const isMounted = useIsMounted();

  const [isRecaptchaScriptLoaded, setIsRecaptchaScriptLoaded] = useState(
    Boolean(window.grecaptcha && window.grecaptcha.render)
  );
  const [widgetId, setWidgetId] = useState(null);

  useEffect(() => {
    if (isRecaptchaScriptLoaded) {
      setWidgetId(
        window.grecaptcha.render(recaptchaElementId, {
          sitekey: GOOGLE_RECAPTCHA_PUBLIC_KEY,
          theme: "light",
          size: "invisible",
        })
      );
    }
  }, [isRecaptchaScriptLoaded]);

  useEffect(() => {
    let script;

    if (!isRecaptchaScriptLoaded) {
      window[recaptchaOnloadCallbackName] = () => {
        if (isMounted.current) {
          setIsRecaptchaScriptLoaded(true);
          delete window[recaptchaOnloadCallbackName];
        }
      };

      const recaptchaScript = isInChina()
        ? `${CHINESE_RECAPTCHA_SCRIPT_PATH}?render=explicit&onload=${recaptchaOnloadCallbackName}`
        : `${GOOGLE_RECAPTCHA_SCRIPT_PATH}?hl=${getRecaptchaLocale(
            locale
          )}&render=explicit&onload=${recaptchaOnloadCallbackName}`;
      script = document.createElement("script");
      script.setAttribute("async", "");
      script.setAttribute("defer", "");
      script.src = recaptchaScript;
      document.head.appendChild(script);
    }

    return () => {
      setIsRecaptchaScriptLoaded(false);
      if (script) {
        script.parentNode.removeChild(script);
      }
    };
  }, [locale, isMounted]);

  const recaptchaExecute = () => {
    return new Promise((resolve, _reject) => {
      window[recaptchaCallbackName] = (token) => {
        delete window[recaptchaCallbackName];
        resolve(token);
      };
      const token = window.grecaptcha.getResponse(widgetId);
      if (token) {
        resolve(token);
      } else {
        window.grecaptcha.execute();
      }
    });
  };

  return {
    recaptchaElementId,
    recaptchaCallbackName,
    recaptchaKey: GOOGLE_RECAPTCHA_PUBLIC_KEY,
    isReady: widgetId >= 0,
    recaptchaExecute: isRecaptchaScriptLoaded
      ? recaptchaExecute
      : () => Promise.reject(new Error("reCAPTCHA is not loaded")),
  };
}
