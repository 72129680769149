import React from "react";
import { useTranslation } from "hooks";
import PropTypes from "prop-types";

export default function DictionaryKeyContent({
  htmlTag = "span",
  dict,
  ...props
}) {
  const { t } = useTranslation();

  const key = typeof dict === "string" ? dict : dict.key;
  const params = typeof dict === "string" ? [] : dict.params;
  const isHtml = typeof dict === "string" ? false : dict.html;

  const translated = t(key, params);
  if (isHtml) {
    return React.createElement(htmlTag, {
      ...props,
      dangerouslySetInnerHTML: { __html: translated },
    });
  }
  return translated;
}

const Dict = PropTypes.shape({
  html: PropTypes.bool.isRequired,
  key: PropTypes.string.isRequired,
  params: PropTypes.arrayOf(PropTypes.string).isRequired,
});

DictionaryKeyContent.propTypes = {
  htmlTag: PropTypes.string,
  dict: PropTypes.oneOfType([PropTypes.string, Dict]),
};

DictionaryKeyContent.defaultProps = {
  htmlTag: "span",
  dict: "",
};
