import React, { useEffect, useState } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { ApmRoute } from "@elastic/apm-rum-react";

import "config/i18n";
import "config/elasticApm";
import { useTranslation, useDispatchWithLocale } from "hooks";
import BookingFlowLoadingIndicator from "BookingFlow/components/BookingFlowLoadingIndicator";
import getFullPageTitle from "utils/getFullPageTitle";
import { getHMTLTagClassNames } from "utils";
import {
  selectIsSystemOffline,
  selectTechnicalErrorMessage,
} from "store/appStatus";
import {
  appStart,
  appStartCancel,
  changeLanguage,
  setLanguage,
  selectIsAppLoading,
} from "store/app";
import {
  languagesRouteParam,
  getPathnameWithoutLocale,
  getIsoCodeForLocale,
} from "config/languages";
import { PlanYourStayView } from "BookingFlow";
import env from "config/env";
import { interval } from "rxjs";
import { filter, take } from "rxjs/operators";
import AppRoutes from "./AppRoutes";
import SystemOfflineView from "./SystemOffLineView";

const { TRIPTEASE_STATIC_URL, TRIPTEASE_CLIENT_API_KEY, TRIPTEASE_ENABLED } =
  env;

const CHECK_PERIOD = 100;

function App({ useLocale = "en" }) {
  const { ready, t, i18n, locale } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();
  const [isLoadingTranslations, setIsLoadingTranslations] = useState(
    useLocale !== locale
  );
  const isLoading = useSelector(selectIsAppLoading);
  const isSystemOffline = useSelector(selectIsSystemOffline);
  const isTechnicalError = useSelector(selectTechnicalErrorMessage);

  useEffect(() => {
    dispatchWithLocale(setLanguage({ locale: useLocale }));
  });

  useEffect(() => {
    const couldRenderTripTease = TRIPTEASE_ENABLED && locale === "en";
    const addScriptTag = () => {
      /* eslint-disable no-unused-expressions */
      // without the timeout sometimes the if allowed doesn't work?
      setTimeout(() => {
        window.FS?.GDPR?.ifAllowed("functional", () => {
          let script = document.getElementById("triptease-script-tag");
          if (!script && couldRenderTripTease) {
            script = document.createElement("script");
            script.id = "triptease-script-tag";
            script.src = `${TRIPTEASE_STATIC_URL}${TRIPTEASE_CLIENT_API_KEY}.js`;
            script.async = true;
            document.body.appendChild(script);
          }
        });
      }, 50);

      /* eslint-enable no-unused-expressions */
    };
    if (couldRenderTripTease) {
      interval(CHECK_PERIOD)
        .pipe(
          filter(() => !!window.FS?.GDPR?.ifAllowed),
          take(1)
        )
        .subscribe(addScriptTag);
    }
  }, [locale]);

  useEffect(() => {
    if (ready && useLocale !== locale) {
      dispatchWithLocale(changeLanguage({ locale: useLocale }));
    }
  }, [ready, useLocale, locale]);

  useEffect(() => {
    if (ready) {
      setIsLoadingTranslations(true);
      i18n.changeLanguage(useLocale.toLowerCase()).then(() => {
        setIsLoadingTranslations(false);
      });
    }
  }, [ready, useLocale]);

  useEffect(() => {
    dispatchWithLocale(appStart({ locale: useLocale }));
    return () => {
      dispatchWithLocale(appStartCancel({ locale: useLocale }));
    };
  }, []);

  const renderInner = () => {
    if (isSystemOffline) {
      return <SystemOfflineView />;
    }
    if (isTechnicalError) {
      return <PlanYourStayView />;
    }
    if (isLoading || isLoadingTranslations) {
      return <BookingFlowLoadingIndicator />;
    }
    return <AppRoutes />;
  };

  return (
    <>
      <Helmet>
        <html
          lang={getIsoCodeForLocale(i18n.language)}
          dir={i18n.dir(i18n.language)}
          className={getHMTLTagClassNames({
            locale,
            dir: i18n.dir(i18n.language),
          })}
        />
        <title>{getFullPageTitle(t)}</title>
      </Helmet>

      {renderInner()}
    </>
  );
}

function AppWrappedWithLanguageCapture() {
  return (
    <Switch>
      <Route
        path={languagesRouteParam}
        render={({
          location,
          match: {
            params: { locale },
          },
        }) => {
          if (locale === "en") {
            return (
              <Redirect
                to={{
                  ...location,
                  pathname: getPathnameWithoutLocale(location.pathname),
                }}
              />
            );
          }
          return <App useLocale={locale || "en"} />;
        }}
      />
    </Switch>
  );
}

export default function AppWrappedApmRoute() {
  return (
    <Switch>
      <ApmRoute component={AppWrappedWithLanguageCapture} />
    </Switch>
  );
}
