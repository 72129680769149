/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  error: "",
};

const memberDetailsSlice = createSlice({
  name: "memberDetails",
  initialState,
  reducers: {
    fetchMemberDetails() {},
    fetchMemberDetailsFulfilled(state, { payload }) {
      state.data = payload;
      state.error = "";
    },
    fetchMemberDetailsFailed(state, { payload }) {
      state.data = [];
      state.error = payload.error;
    },
    fetchMemberDetailsCancel() {},

    memberDetailsReset() {
      return initialState;
    },
  },
});

export const {
  fetchMemberDetails,
  fetchMemberDetailsFulfilled,
  fetchMemberDetailsFailed,
  fetchMemberDetailsCancel,
  memberDetailsReset,
} = memberDetailsSlice.actions;

export default memberDetailsSlice.reducer;
