import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";

import env from "config/env";
import {
  selectPropertyShortName,
  selectPropertyImage,
} from "store/propertyContent";
import { useTranslation } from "hooks";
import ThemeContextProvider, { THEMES } from "contexts/ThemeContext";
import ChatIcon from "components/ChatIcon";
import { Button, Link } from "Profile/components";
import formatBeverlyWilshire from "Profile/utils/formatBeverlyWilshire";
import { getFormattedDateRange } from "BookingFlow/utils";

const { IMAGE_BASE_URL } = env;

function UpgradeYourRoomView({
  hotelCode,
  reservationHighlights,
  reservationId,
  upcomingTrip,
  phoneNumber,
  isWebChatEnabled,
  openWebChat,
  setWebChatPredefinedMessage,
}) {
  const { t, locale, i18n } = useTranslation();
  const propertyShortName = useSelector(selectPropertyShortName(hotelCode));
  const propertyImage = useSelector(selectPropertyImage(hotelCode));

  const webChatPredefinedMessage = t(
    "Hi, I am interested in learning about available upgrades. Could you kindly provide more details?"
  );

  useEffect(() => {
    if (isWebChatEnabled) {
      setWebChatPredefinedMessage(webChatPredefinedMessage);
    }
  }, [isWebChatEnabled, webChatPredefinedMessage]);

  const stayDates = getFormattedDateRange(
    upcomingTrip.startDate,
    upcomingTrip.endDate,
    locale,
    "short"
  );

  const roomType =
    reservationHighlights.find(({ label }) => label === "Room Type")?.value ||
    "";

  const welcomeMessage = [
    t("Hi {0}! Interested in upgrading your room?", [
      get(upcomingTrip, ["contactDetails", "name", "firstName"]),
    ]),
    isWebChatEnabled
      ? t("Chat with us to learn about available upgrades.")
      : t("Call us to arrange your perfect stay."),
  ].join(" ");

  const backgroundImage = {
    backgroundImage: `url(${IMAGE_BASE_URL + propertyImage})`,
  };

  return (
    <ThemeContextProvider theme={THEMES.LIGHT}>
      {isWebChatEnabled && (
        <button
          onClick={openWebChat}
          type="button"
          className="web-chat-button"
          aria-label={t("Chat With Us")}
        >
          <ChatIcon />
        </button>
      )}

      <div
        className={`layout--profile-fullscreen layout--profile-fullscreen--light view--upgrade-your-room ${
          i18n.language
        } ${i18n.dir(i18n.language)}`}
      >
        <div className="layout--profile-fullscreen__body">
          <div className="container">
            <div className="fs-tree" />

            <h1
              className="property-name"
              dangerouslySetInnerHTML={{
                __html: formatBeverlyWilshire(propertyShortName),
              }}
            />
            <p className="stay-dates">{stayDates}</p>

            <div className="property-image-container">
              <div className="property-image" style={backgroundImage} />
              <div className="welcome-message">
                <p>{welcomeMessage}</p>

                {isWebChatEnabled ? (
                  <Button onClick={openWebChat} kind="primary">
                    {t("Chat to upgrade")}
                  </Button>
                ) : (
                  <Link href={`tel:${phoneNumber}`} kind="primary">
                    {phoneNumber}
                  </Link>
                )}
              </div>
            </div>

            <div className="your-trip-details">
              <h2 className="your-trip-details__title">
                {t("Your Trip Details")}
              </h2>

              <dl className="your-trip-details__list">
                <dt>{t("Stay Dates")}</dt>
                <dd>{stayDates}</dd>
                <dt>{t("Confirmation Number")}</dt>
                <dd>{reservationId}</dd>
                <dt>{t("Room Type")}</dt>
                <dd>{roomType}</dd>
              </dl>
            </div>

            <div className="chat-with-us">
              {isWebChatEnabled ? (
                <p>{t("Chat with us to arrange your perfect stay")}</p>
              ) : (
                <p>{t("Call us to arrange your perfect stay.")}</p>
              )}

              <div className="chat-with-us__actions">
                {phoneNumber && (
                  <Link theme="dark" href={`tel:${phoneNumber}`}>
                    {phoneNumber}
                  </Link>
                )}
                {isWebChatEnabled && (
                  <Button onClick={openWebChat} kind="primary" theme="dark">
                    {t("Chat with us")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeContextProvider>
  );
}

export default UpgradeYourRoomView;
