/* eslint-disable no-param-reassign */
import { createReducer } from "@reduxjs/toolkit";

import {
  activateEmployeeProfile,
  createEmailVerifyCode,
  createProfile,
  deleteUserAddress,
  deleteUserEmail,
  deleteUserPhone,
  fetchProfile,
  logout,
  toggleInterest,
  updateCountryAndLanguage,
  updateEmailSubscriptions,
  updateRoomPreference,
  updateSleepPreferences,
  updateUserAddress,
  updateUserEmail,
  updateUserPhone,
  updateUserTitle,
  verifyEmailCode,
} from "store/profile/profile.slice";
import { generateOtp, submitOtp } from "store/signIn";
import { fetchCountriesContent } from "store/countriesContent/countriesContent.slice";
import {
  updatePostBookingComments,
  updateProfileComments,
} from "store/bookingComments";
import {
  addPackage,
  completeBooking,
  cancelBooking,
  modifyHotelProductCust,
  requestInvoice,
  retrieveBooking,
  submitBookingPreferences,
  upgradeRooms,
} from "store/bookings/bookings.slice";
import { fetchPaymentOptions } from "store/paymentOptions";
import { fetchGlobalMailSubscriptions } from "store/globalMailSubscriptions";
import { fetchGlobalSettings } from "store/globalSettings";
import { fetchExchangeRates } from "store/exchangeRates";
import { fetchProperties } from "store/properties";
import { fetchAccommodationsContent } from "store/accommodationsContent";
import { fetchOffersContent } from "store/offersContent";
import { fetchPropertyContent } from "store/propertyContent";
import { fetchTaxesContent } from "store/taxesContent";
import { fetchUpsellsContent } from "store/upsellsContent";
import { fetchBooking, confirmCheckIn } from "store/webCheckIn";
import { fetchWorkplaces } from "store/workplaces";
import { API_REQUEST_STATES } from "./constants";
import getNextState from "./getNextState";

const ACTIONS_TO_TRACK = [
  activateEmployeeProfile.type,
  createEmailVerifyCode.type,
  createProfile.type,
  deleteUserAddress.type,
  deleteUserEmail.type,
  deleteUserPhone.type,
  fetchProfile.type,
  logout.type,
  toggleInterest.type,
  updateCountryAndLanguage.type,
  updateEmailSubscriptions.type,
  updateRoomPreference.type,
  updateSleepPreferences.type,
  updateUserAddress.type,
  updateUserEmail.type,
  updateUserPhone.type,
  updateUserTitle.type,
  verifyEmailCode.type,

  addPackage.type,
  cancelBooking.type,
  confirmCheckIn.type,
  fetchAccommodationsContent.type,
  fetchBooking.type,
  fetchCountriesContent.type,
  fetchExchangeRates.type,
  fetchGlobalMailSubscriptions.type,
  fetchGlobalSettings.type,
  fetchOffersContent.type,
  fetchPaymentOptions.type,
  fetchProperties.type,
  fetchPropertyContent.type,
  fetchTaxesContent.type,
  fetchUpsellsContent.type,
  fetchWorkplaces.type,
  generateOtp.type,
  completeBooking.type,
  modifyHotelProductCust.type,
  requestInvoice.type,
  retrieveBooking.type,
  submitBookingPreferences.type,
  submitOtp.type,
  updatePostBookingComments.type,
  updateProfileComments.type,
  upgradeRooms.type,
];

const apiRequestStatesReducer = createReducer({}, (builder) => {
  builder
    .addMatcher(
      (action) =>
        action.type.endsWith("Clear") || action.type.endsWith("Reset"),
      (state, { type }) => {
        const [actionPrefix] = type.split("/");
        if (actionPrefix) {
          return Object.keys(state).reduce((acc, key) => {
            if (key.startsWith(actionPrefix)) {
              return {
                ...acc,
                [key]: null,
              };
            }
            return { ...acc };
          }, state);
        }
        return state;
      }
    )
    .addMatcher(
      (action) => action.type.endsWith("Fulfilled"),
      (state, { type: initialType }) => {
        const type = initialType.replace(/Fulfilled$/, "");
        state[type] = getNextState({
          currentState: state[type],
          toState: API_REQUEST_STATES.FULFILLED,
        });
      }
    )
    .addMatcher(
      (action) => action.type.endsWith("Failed"),
      (state, { type: initialType }) => {
        const type = initialType.replace(/Failed$/, "");
        state[type] = getNextState({
          currentState: state[type],
          toState: API_REQUEST_STATES.FAILED,
        });
      }
    )
    .addMatcher(
      (action) => action.type.endsWith("Cancel"),
      (state, { type: initialType }) => {
        const type = initialType.replace(/Cancel$/, "");
        state[type] = getNextState({
          currentState: state[type],
          toState: API_REQUEST_STATES.CANCELLED,
        });
      }
    )
    .addDefaultCase((state, { type }) => {
      if (type.includes("/fetch") || ACTIONS_TO_TRACK.includes(type)) {
        state[type] = getNextState({
          currentState: state[type],
          toState: API_REQUEST_STATES.REQUEST,
        });
      }
    });
});

export default apiRequestStatesReducer;
