import React, { useContext } from "react";
import classNames from "classnames";
import { Controller } from "react-hook-form";
import { endOfDay, isBefore, parse } from "date-fns";

import * as regexPatterns from "utils/regexPatterns";
import { useTranslation } from "hooks";
import { MediaContext } from "contexts/MediaContext";
import { CleaveInput } from "components";

export default function ChildGuestRow({
  roomIndex,
  index,
  register,
  control,
  errors = {},
  ...props
}) {
  const { t, locale, dateFormat } = useTranslation();
  const media = useContext(MediaContext);
  const uniqueId = `guest-name-${roomIndex}-${index}`;
  const uniqueBirthdayId = `guest-birthday-${roomIndex}-${index}`;
  const label = `${t("Guest")} ${index + 1} ${t("Name")} (${t("Child")})`;
  const birthdayLabel = `${t("Guest")} ${index + 1} ${t("Birthday")} (${t(
    "Child"
  )})`;

  return (
    <div>
      <div
        className={classNames("form-row", {
          "has-errors": Boolean(errors.name),
        })}
      >
        <label htmlFor={uniqueId}>{label}</label>
        <input
          id={uniqueId}
          name={`comments[${roomIndex}].roomGuests[${index}].name`}
          type="text"
          placeholder={label}
          ref={register({
            pattern: regexPatterns.noDigits,
          })}
          {...props}
        />

        {Boolean(errors.name) && (
          <div className="inline-error">
            <span className="message">
              {t("Please enter a valid response.")}
            </span>
            <span className="icon">&#33;</span>
          </div>
        )}
      </div>

      <div
        className={classNames("form-row", {
          "has-errors": Boolean(errors.dateOfBirth),
        })}
      >
        <label htmlFor={uniqueBirthdayId}>{birthdayLabel}</label>

        <Controller
          id={uniqueBirthdayId}
          name={`comments[${roomIndex}].roomGuests[${index}].dateOfBirth`}
          control={control}
          placeholder={
            media.isLessThan.sm
              ? `${birthdayLabel} (${dateFormat.label})`
              : dateFormat.label
          }
          className="allow-placeholder"
          cleaveOptions={dateFormat.cleaveOptions}
          as={<CleaveInput />}
          rules={{
            pattern: regexPatterns.getRegexForOptionalDateFormat(locale),
            validate: {
              tooYoung: (value) =>
                value === "" ||
                isBefore(
                  parse(value, dateFormat.dateFnsParse, new Date()),
                  endOfDay(new Date())
                ),
            },
          }}
          defaultValue=""
        />

        {Boolean(errors.dateOfBirth) && (
          <div className="inline-error">
            <span className="message">
              {t("Please enter a valid response.")}
            </span>
            <span className="icon">&#33;</span>
          </div>
        )}
      </div>
    </div>
  );
}
