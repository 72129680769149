import React from "react";
import classNames from "classnames";

import env from "config/env";
import { useTranslation } from "hooks";
import { isMobileApp } from "utils";
import { telephoneNumber } from "utils/regexPatterns";
import { getTollFreeNumbersLink } from "utils/externalLinks";

const { STATIC_SITE_URL } = env;

const BookDirectContent = React.memo(
  ({
    title,
    className,
    hotelReservations,
    telephones,
    propertyUseGlobalNumber,
    tollFreeNumbers,
  }) => {
    const { t } = useTranslation();

    const regex = /\s+\D*\s+/g;

    const telephonesHTML = telephones
      .map(({ number }) => number.trim())
      .filter((number) => Boolean(number))
      .flatMap((number) => {
        const matches = number.trim().match(regex);
        if (!matches) {
          return number;
        }
        return matches.reduce(
          (numberParts, match) => {
            const str = numberParts[numberParts.length - 1];
            if (!str) {
              return numberParts;
            }
            const matchIndex = str.indexOf(match);
            return [
              ...numberParts.slice(0, numberParts.length - 1),
              str.substr(0, matchIndex),
              str.substr(matchIndex, match.length),
              str.substr(matchIndex + match.length),
            ];
          },
          [number]
        );
      })
      .map((n) => {
        if (telephoneNumber.test(n)) {
          return `<strong className="phone"><a href="tel:${n}">${n}</a></strong>`;
        }
        return n.trim();
      })
      .join(" ");

    const tollFreeNumbersLink = tollFreeNumbers
      ? STATIC_SITE_URL + tollFreeNumbers.url
      : getTollFreeNumbersLink();

    return (
      <div
        className={classNames("room-item-actions room-book-direct", className)}
      >
        <div className="book-direct-info">
          {!propertyUseGlobalNumber && (
            <div className="book-direct-phone">
              <span
                dangerouslySetInnerHTML={{
                  __html: t(title),
                }}
              />
              {!isMobileApp() && <br />}

              {hotelReservations && <strong>{t(hotelReservations)}</strong>}

              <span
                dangerouslySetInnerHTML={{
                  __html: telephonesHTML,
                }}
              />
            </div>
          )}
          {propertyUseGlobalNumber && (
            <div className="book-direct-link">
              {t("To Book this Room")}
              <br />
              <a
                href={tollFreeNumbersLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Contact Us")}
              </a>
            </div>
          )}
        </div>
      </div>
    );
  },

  function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  }
);

export default BookDirectContent;
