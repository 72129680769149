import React from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "hooks";
import { selectProfileName } from "store/profile/profile.selectors";
import { ToggleBlock } from "Profile/components";
import UserDetailsForm from "./UserDetailsForm";

function UserDetails() {
  const { t } = useTranslation();

  const profileName = useSelector(selectProfileName(t));

  return (
    <div className="view--profile-edit__user-details">
      <div className="container">
        <div className="user-details">
          <div className="row">
            <div className="col-sm-12">
              <div className="user-details__name-and-edit">
                <ToggleBlock
                  type="edit"
                  label={({ toggle }) => {
                    return (
                      <div className="fs-toggle-block__label">
                        <div>{profileName}</div>
                        <button
                          className="fs-toggle-button fs-toggle-button--edit"
                          onClick={toggle}
                          type="button"
                          aria-label={t("Edit your name")}
                        >
                          {t("Edit")}
                        </button>
                      </div>
                    );
                  }}
                >
                  {({ close }) => <UserDetailsForm closeForm={close} />}
                </ToggleBlock>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
