/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  error: "",
};

const calendarAvailabilitySlice = createSlice({
  name: "calendarAvailability",
  initialState,
  reducers: {
    fetchCalendarAvailability: (state) => {
      state.error = "";
    },

    fetchCalendarAvailabilityFulfilled: (state, { payload }) => {
      state.data = payload;
      state.error = "";
    },

    fetchCalendarAvailabilityFailed: (state, { payload: { error } }) => {
      state.data = [];
      state.error = error;
    },

    fetchCalendarAvailabilityCancel: () => {},
  },
});

export const {
  fetchCalendarAvailability,
  fetchCalendarAvailabilityFulfilled,
  fetchCalendarAvailabilityFailed,
  fetchCalendarAvailabilityCancel,
} = calendarAvailabilitySlice.actions;

export default calendarAvailabilitySlice.reducer;
