const getCreditCardName = (cardCode) => {
  switch (cardCode) {
    case "VI":
      return "Visa";
    case "AE":
      return "American Express";
    case "AX":
      return "American Express";
    case "MC":
      return "MasterCard";
    case "CA":
      return "MasterCard";
    case "DS":
      return "Discover";
    case "CU":
      return "Union Pay";
    case "UP":
      return "Union Pay";
    case "JC":
      return "JCB";
    case "alipay":
      return "alipay";
    case "DN":
      return "Diners Club";
    case "DC":
      return "Diners Club";
    default:
      return "unknown";
  }
};

export default getCreditCardName;
