import { ajax } from "rxjs/ajax";

import env from "config/env";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;
const TRETAIL_API_BOOKINGS_URL = `${TRETAIL_API_BASE_URL}/bookings`;

const createBooking$ = ({ locale }) =>
  ajax({
    ...basePostRequest({ locale }),
    url: TRETAIL_API_BOOKINGS_URL,
  });

export default createBooking$;
