import { init as initApm } from "@elastic/apm-rum";
import { from, interval } from "rxjs";
import { switchMap, take } from "rxjs/operators";

import env from "config/env";

/* eslint-disable no-unused-vars */

const CHECK_PERIOD = 100;

const {
  VERSION,
  ELASTIC_APM_ENABLED,
  ELASTIC_APM_URL,
  STATIC_SITE_URL,
  PREFERRED_PARTNER_DOMAIN,
  ELASTIC_APM_CENTRAL_CONFIG,
  ELASTIC_APM_TRANSACTION_RATE,
} = env;

const apm = initApm({
  apiVersion: 3,
  active: ELASTIC_APM_ENABLED,
  serviceName: "fs-secure-rbf",
  serverUrl: ELASTIC_APM_URL,
  serviceVersion: VERSION,
  distributedTracingOrigins: [STATIC_SITE_URL, PREFERRED_PARTNER_DOMAIN],
  centralConfig: ELASTIC_APM_CENTRAL_CONFIG,
  transactionSampleRate: ELASTIC_APM_TRANSACTION_RATE,
});

let currentCustomTransaction;
export const customTransaction =
  (name, type = "user-interaction") =>
  (action, prevState, nextState) => {
    return () => {
      currentCustomTransaction = apm.startTransaction(name, type);
    };
  };
export const endTransaction = () => (action, prevState, nextState) => {
  return () => currentCustomTransaction?.end();
};

export const apmCaptureError = (eventDef) => (action, prevState, nextState) => {
  const event = eventDef(action, prevState, nextState);
  return () => {
    if (event.errorContext) {
      apm.setCustomContext(event.errorContext);
      apm.captureError(event.error);
      apm.setCustomContext({});
    }
  };
};

export const handleEvent = (event) => event();

export const apmTarget = () => (events) => {
  if (ELASTIC_APM_ENABLED) {
    return interval(CHECK_PERIOD)
      .pipe(
        take(1),
        switchMap(() => from(events))
      )
      .subscribe(handleEvent);
  }
  return null;
};

const origError = console.error;
console.error = (error, ...args) => {
  try {
    if (typeof error === "string" || error instanceof Error) {
      apm.captureError(error);
    }
  } finally {
    origError.apply(console, [error, ...args]);
  }
};

export default apm;
