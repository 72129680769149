import React from "react";
import classNames from "classnames";
import { format } from "date-fns";

import { DictionaryKeyContent } from "BookingFlow/components";
import { useTranslation } from "hooks";
import { pluralize } from "utils/utils";
import CancellationTerms from "../CancellationMessage/CancellationTerms";
import GuestsFields from "../GuestsFields";

export default function YourBookingRoomCard({
  adults,
  children,
  bedType,
  cancelRef,
  control,
  dictionaryKeys,
  errors,
  roomGuests,
  isBookingCancelled,
  isMultiRoomBooking,
  name,
  offer,
  register,
  reservationId,
  roomIndex,
  cancellation,
  openCancellationModal,
  property,
  // isPreferredPartners,
}) {
  const { t, getDateTimeFormat } = useTranslation();

  return (
    <div className="row">
      <div
        className={classNames(
          "app-booking-information",
          `app-booking-information-${roomIndex}`
        )}
      >
        <p className="app-booking-reference">{reservationId}</p>
        <p className="app-booking-ors-code">{property.owsCode}</p>
      </div>

      <div className="col-xs-6">
        <dl>
          <dt>{name}</dt>
          <dd>
            <span>{pluralize(t, adults, "Adult")}</span>
            {children > 0 && <span> {pluralize(t, children, "Child")}</span>}
          </dd>
          <dd>{name}</dd>
          <dd>{offer.title}</dd>
          <dd>{bedType}</dd>
        </dl>
      </div>

      <div className="col-xs-6">
        {cancelRef && (
          <p>
            <span>{t("Cancellation")}</span>
            <br />
            <span className="reservation-number">#{cancelRef}</span>
          </p>
        )}

        <p>
          <span>
            <DictionaryKeyContent dict={dictionaryKeys.yourReservation} />
          </span>
          <br />
          <span className="reservation-number">#{reservationId}</span>
        </p>

        {!isMultiRoomBooking && !isBookingCancelled && cancellation.allowed && (
          <div className="cancel-reservation">
            <button
              type="button"
              className="btn-link cancel"
              onClick={openCancellationModal}
              aria-haspopup="dialog"
            >
              {t("Cancel this reservation")}
            </button>

            {cancellation?.by && (
              <p>
                {t("Cancellation required by")}{" "}
                {format(cancellation.by, getDateTimeFormat())}
              </p>
            )}
          </div>
        )}
      </div>

      {!isMultiRoomBooking && !isBookingCancelled && !cancellation.allowed && (
        <CancellationTerms dictionaryKeys={dictionaryKeys} />
      )}

      <GuestsFields
        roomIndex={roomIndex}
        guests={roomGuests}
        register={register}
        control={control}
        errors={errors}
        isMultiRoomBooking={isMultiRoomBooking}
        isBookingCancelled={isBookingCancelled}
      />
    </div>
  );
}
