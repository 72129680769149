import React, { useContext } from "react";
import { MediaContext } from "contexts/MediaContext";
import { useTranslation } from "hooks";
import env from "config/env";

const { IMAGE_BASE_URL } = env;

function DownloadAppCTA() {
  const { t } = useTranslation();
  const media = useContext(MediaContext);

  function AppButtons() {
    const IMAGES = {
      appleStoreApp: `${IMAGE_BASE_URL}/etc/designs/fourseasons/img/app/app-store-button.png`,
      androidStoreApp: `${IMAGE_BASE_URL}/etc/designs/fourseasons/img/app/google-play-button.png`,
    };

    const URLS = {
      appleStore: "https://itunes.apple.com/app/id980069965",
      androidStore:
        "https://play.google.com/store/apps/details?id=com.fourseasons.mobileapp",
    };

    return (
      <div className="mobile-downloads">
        <div>
          <a
            href={URLS.appleStore}
            target="_blank"
            rel="noopener noreferrer"
            title={t("Download on the App Store")}
          >
            <img
              className="app-button"
              src={IMAGES.appleStoreApp}
              alt={t("Download on the App Store")}
            />
          </a>
        </div>
        <div>
          <a
            href={URLS.androidStore}
            target="_blank"
            rel="noopener noreferrer"
            title={t("Get it on Google play")}
          >
            <img
              className="app-button google-play"
              src={IMAGES.androidStoreApp}
              alt={t("Get it on Google play")}
            />
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="view--profile__mobile-app-promotion">
      {!media.isLessThan.lg && (
        <div className="desktop">
          <div className="text">
            <h1 className="ty-h1">
              {t("Download our app and access your memberships.")}
            </h1>
            <p>
              {t(
                "Experience signature Four Seasons service at your finger tips with the Four Seasons App."
              )}
            </p>
            <p className="ty-h3">
              {t("Four Seasons service at your fingertips")}
            </p>
            <AppButtons />
          </div>
          <div className="image">
            <img src="/images/app-mockup-desktop.png" alt={t("App")} />
          </div>
        </div>
      )}

      {media.isLessThan.lg && (
        <div className="mobile">
          <img
            className="image"
            src="/images/app-mockup-mobile.png"
            alt={t("App")}
          />
          <h1 className="ty-h1">
            {t("Download our app and access your memberships.")}
          </h1>
          <p>
            {t(
              "Experience signature Four Seasons service at your finger tips with the Four Seasons App."
            )}
          </p>
          <p className="ty-h3">
            {t("Four Seasons service at your fingertips")}
          </p>
          <AppButtons />
        </div>
      )}
    </div>
  );
}

export default DownloadAppCTA;
