import React, { useState } from "react";

import { useTranslation } from "hooks";
import Modal from "BookingFlow/components/Modal";

export default function ConfirmYourStayWhatIsIncludedModal({
  cancellationPolicy,
  closeModal,
  depositPolicy,
  feesDisclaimer,
  conditions,
  conditionsTitle,
  inclusions,
  inclusionsTitle,
  longDescription,
  price: {
    currency,
    amount: { withDecimal },
  },
  priceLabel,
  propertyDisclaimer,
  title,
  whatsIncludedTitleDictKey,
  extraInclusions,
}) {
  const { t, formatPrice } = useTranslation();

  const [showTAndC, setShowTAndC] = useState(false);

  return (
    <Modal
      title={t(whatsIncludedTitleDictKey.key, whatsIncludedTitleDictKey.params)}
      className=""
      closeModal={closeModal}
    >
      <div className="offer-details">
        <div className="row">
          <div className="col-sm-12">
            <h2>{t(title)}</h2>
            <div>
              <span className="price">
                {formatPrice({
                  currency,
                  value: withDecimal,
                  decimalPlaces: 0,
                })}
                &nbsp;
                <span className="nights">{t(priceLabel)}</span>
              </span>
              {feesDisclaimer && (
                <div
                  className="resort-fee"
                  dangerouslySetInnerHTML={{
                    __html: t(feesDisclaimer.key, feesDisclaimer.params),
                  }}
                />
              )}
            </div>
          </div>
          {inclusions.length > 0 && (
            <div className="col-sm-12">
              <div className="included">
                <dl className="inclusions">
                  {inclusionsTitle && (
                    <dt>{t(inclusionsTitle.key, inclusionsTitle.params)}</dt>
                  )}
                  {inclusions.map(({ title: inclusionTitle, items }, index) => (
                    <React.Fragment key={index}>
                      {inclusionTitle && (
                        <dt className="inclusion-title">{inclusionTitle}</dt>
                      )}
                      {items.map((html) => (
                        <dd
                          key={html}
                          dangerouslySetInnerHTML={{ __html: html }}
                        />
                      ))}
                    </React.Fragment>
                  ))}
                </dl>
              </div>
            </div>
          )}
          {extraInclusions.length > 0 && (
            <div className="col-sm-12 pp-amenities">
              <div className="included">
                <dl className="inclusions">
                  {extraInclusions.map(
                    ({ title: inclusionTitle, items }, index) => (
                      <React.Fragment key={index}>
                        <dt>{inclusionTitle}</dt>
                        {items.map((html) => (
                          <dd
                            key={html}
                            dangerouslySetInnerHTML={{ __html: html }}
                          />
                        ))}
                      </React.Fragment>
                    )
                  )}
                </dl>
              </div>
            </div>
          )}
          <div className="col-sm-12 long-description">
            <div dangerouslySetInnerHTML={{ __html: longDescription }} />
          </div>
          {conditions.length > 0 && (
            <div className="col-sm-12">
              <div className="included">
                <dl className="inclusions">
                  {conditionsTitle && (
                    <dt>{t(conditionsTitle.key, conditionsTitle.params)}</dt>
                  )}
                  {conditions.map(({ title: conditionTitle, items }, index) => (
                    <React.Fragment key={index}>
                      {conditionTitle && (
                        <dt className="inclusion-title">{conditionTitle}</dt>
                      )}
                      {items.map((html) => (
                        <dd
                          key={html}
                          dangerouslySetInnerHTML={{ __html: html }}
                        />
                      ))}
                    </React.Fragment>
                  ))}
                </dl>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-sm-12">
            <button
              type="button"
              className="terms-button button-as-link"
              aria-expanded={showTAndC}
              aria-controls=".terms"
              onClick={() => setShowTAndC(!showTAndC)}
            >
              {showTAndC
                ? t("Hide Terms & Conditions")
                : t("View Terms & Conditions")}
            </button>
            {showTAndC && (
              <div className="terms">
                <h3>{t("Terms & Conditions")}</h3>
                <div dangerouslySetInnerHTML={{ __html: propertyDisclaimer }} />
                {[
                  { label: "Cancellation Policy", html: cancellationPolicy },
                  { label: "Deposit Policy", html: depositPolicy },
                ]
                  .filter(({ html }) => Boolean(html))
                  .map(({ label, html }) => (
                    <div key={label}>
                      <h3>{t(label)}</h3>
                      <p dangerouslySetInnerHTML={{ __html: html }} />
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
