import React from "react";

import { DictionaryKeyContent } from "BookingFlow/components";
import { useTranslation } from "hooks";
import { pluralize } from "utils/utils";
import env from "config/env";
import GuestsFields from "../GuestsFields";

const { IMAGE_BASE_URL } = env;

export default function RoomCard({
  adults,
  bedType,
  cancelRef,
  dictionaryKeys,
  errors,
  guests,
  image,
  isBookingCancelled,
  isMultiRoomBooking,
  name,
  offer,
  register,
  control,
  reservationId,
  roomIndex,
}) {
  const { t } = useTranslation();

  return (
    <section className="room-item personalise-form">
      <div className="room-item-preview">
        {image?.path && (
          <div>
            <span
              className="room-item-image"
              role="img"
              aria-label="Superior Room"
              style={{
                backgroundImage: `url(${IMAGE_BASE_URL + image?.path})`,
              }}
            />
          </div>
        )}
        <div className="room-item-info">
          <div className="room-item-description">
            <h3 className="room-item-title">{name}</h3>
            <ul className="dash-bullets">
              <li>{pluralize(t, adults, "Adult")}</li>
              <li>{name}</li>
              {offer.title && <li>{offer.title}</li>}
              <li>{bedType}</li>
            </ul>
          </div>

          <div className="room-item-actions">
            {cancelRef && (
              <p>
                <span>{t("Cancellation")}</span>
                <br />
                <span className="reservation-number">#{cancelRef}</span>
              </p>
            )}

            <p>
              <span>
                <DictionaryKeyContent dict={dictionaryKeys.yourReservation} />
              </span>
              <br />
              <span className="reservation-number">#{reservationId}</span>
            </p>
          </div>
        </div>
      </div>

      <GuestsFields
        roomIndex={roomIndex}
        guests={guests}
        register={register}
        control={control}
        errors={errors}
        isMultiRoomBooking={isMultiRoomBooking}
        isBookingCancelled={isBookingCancelled}
      />
    </section>
  );
}
