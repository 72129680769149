/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  byReservationId: {},
};

const transportationAmenitiesContentSlice = createSlice({
  name: "transportationAmenitiesContent",
  initialState,
  reducers: {
    fetchTransportationAmenitiesContent() {},

    fetchTransportationAmenitiesContentFulfilled(
      state,
      { payload: { data, args } }
    ) {
      state.byReservationId[args.reservationId] = data;
    },

    fetchTransportationAmenitiesContentFailed() {},

    fetchTransportationAmenitiesContentCancel() {},
  },
});

export const {
  fetchTransportationAmenitiesContent,
  fetchTransportationAmenitiesContentFailed,
  fetchTransportationAmenitiesContentFulfilled,
  fetchTransportationAmenitiesContentCancel,
} = transportationAmenitiesContentSlice.actions;

export default transportationAmenitiesContentSlice.reducer;
