import React from "react";

import { ROOM } from "fixtures/constants";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import { useTranslation } from "hooks";
import { roomPreferences } from "fixtures/preferences";
import { selectRoomPreference, updateRoomPreference } from "store/profile";
import Button from "../Button";
import Dropdown from "../Dropdown";
import useFormSubmit from "../../hooks/useFormSubmit";

export default function RoomForm({ closeForm }) {
  const { t } = useTranslation();

  const translateOptions = (options) =>
    options.map(({ label, value }) => ({ value, label: t(label) }));

  const {
    onSubmit,
    register,
    showLoadingIndicator,
    value: roomValue,
  } = useFormSubmit({
    closeForm,
    action: updateRoomPreference,
    selector: selectRoomPreference,
    fieldName: ROOM,
  });

  return (
    <>
      {showLoadingIndicator && <BookingFlowLoadingIndicator />}

      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col-md-12">
            <Dropdown
              name={ROOM}
              identifier={`field-preference-${roomPreferences.title}`}
              options={translateOptions(roomPreferences.options)}
              label={t(roomPreferences.title)}
              register={register()}
              value={roomValue}
              forceLabel
            />
          </div>
          <div className="col-sm-12">
            <div className="fs-your-preferences__preference__actions">
              <Button type="submit" aria-label={t("Update")}>
                {t("Update")}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
