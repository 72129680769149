import React from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";
import { pluralize } from "utils/utils";

const RoomsSelectionRoom = React.forwardRef(
  (
    {
      roomNumber,
      adults,
      children,
      roomName,
      packageName,
      isSelected,
      isMultiRoom,
      onEditRoomClick,
      onEditPackageClick,
    },
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <div
        ref={ref}
        className={classNames("rooms-selection__room", {
          "rooms-selection__room--is-selected": isSelected,
        })}
      >
        <div className="rooms-selection__room__inner">
          <div className="rooms-selection__room__title">
            <span className="rooms-selection__room__title__room-number">
              {isMultiRoom ? t("Room {0}", [roomNumber]) : t("Room")}
              {` | `}
            </span>
            <span className="rooms-selection__room__title__guest-count">
              {[
                adults > 0 && pluralize(t, adults, "Adult"),
                children > 0 && pluralize(t, children, "Child", "Children"),
              ]
                .filter(Boolean)
                .join(", ")}
            </span>
          </div>

          <div
            className={classNames("rooms-selection__room__selected-room", {
              "rooms-selection__room__selected-room--is-highlighted":
                isSelected && !roomName,
            })}
          >
            {roomName ? (
              <span>
                {roomName}&nbsp;&nbsp;
                <button
                  type="button"
                  className="rooms-selection__room__edit-button"
                  onClick={onEditRoomClick}
                >
                  {t("Edit")}
                </button>
              </span>
            ) : (
              t("Choose Room")
            )}
          </div>

          <div
            className={classNames("rooms-selection__room__selected-package", {
              "rooms-selection__room__selected-package--is-highlighted":
                isSelected && roomName && !packageName,
            })}
          >
            {packageName ? (
              <span>
                {packageName}&nbsp;&nbsp;
                <button
                  type="button"
                  className="rooms-selection__room__edit-button"
                  onClick={onEditPackageClick}
                >
                  {t("Edit")}
                </button>
              </span>
            ) : (
              t("Choose Package")
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default RoomsSelectionRoom;
