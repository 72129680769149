import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import partition from "lodash/partition";
import set from "lodash/set";

import {
  clearProfileErrors,
  updateUserProfile,
  selectAllProfileErrors,
} from "store/profile";
import { useDispatchWithLocale, usePrevious } from "hooks";
import {
  selectIsRequestFailed,
  selectIsRequestLoading,
} from "store/apiRequestStates";

export default function useFormSubmit({
  closeForm,
  action,
  failedAction = updateUserProfile,
  selector,
  fieldName,
}) {
  const editButtonRef = useRef();
  const dispatchWithLocale = useDispatchWithLocale();

  const profileErrors = useSelector(selectAllProfileErrors);

  const field = useSelector(selector);

  const {
    errors: formErrors,
    formState,
    handleSubmit,
    register,
    setValue,
    watch,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      [fieldName]: field,
    },
  });

  useEffect(() => {
    dispatchWithLocale(clearProfileErrors());
  }, []);

  const errorFieldNameMap = {
    emails: "emailData",
    phones: "phoneData",
    address: "addressData",
  };

  const getFieldNameForError = (errorField) => {
    const [first, ...rest] = errorField.split(".");
    const mappedFieldName = errorFieldNameMap[first.replace(/\[\d]$/, "")];
    if (mappedFieldName) {
      return [mappedFieldName, ...rest].join(".");
    }
    return mappedFieldName;
  };

  const [profileFormErrors, baseErrors] = partition(
    profileErrors.map(({ field: errorField, ...profileError }) => {
      const mappedField = errorField && getFieldNameForError(errorField);
      if (mappedField) {
        return { ...profileError, field: mappedField };
      }
      return {
        ...profileError,
      };
    }),
    "field"
  );

  const errors = profileFormErrors.reduce((acc, profileError) => {
    return set({ ...acc }, profileError.field, profileError.errorCode);
  }, formErrors || {});

  const onSubmit = handleSubmit((value) => {
    if (value[fieldName] !== field) {
      dispatchWithLocale(
        action({
          newValue:
            typeof field === "object"
              ? { ...field, ...value[fieldName] }
              : value[fieldName],
        })
      );
    }
  });

  const error = useSelector(selectIsRequestFailed(failedAction.type));

  const showLoadingIndicator = useSelector(selectIsRequestLoading(action.type));
  const prevShowLoadingIndicator = usePrevious(showLoadingIndicator);

  useEffect(() => {
    if (prevShowLoadingIndicator && !showLoadingIndicator && closeForm) {
      closeForm();
      setValue(fieldName, field);
    }
  }, [showLoadingIndicator, prevShowLoadingIndicator]);

  return {
    editButtonRef,
    errors,
    baseError: baseErrors.map((baseError) => ({
      ...baseError,
      errorCode: "error.E0821",
    }))[0],
    formState,
    onSubmit,
    register,
    setValue,
    showLoadingIndicator: showLoadingIndicator && !error,
    value: field,
    watched: watch(fieldName),
    control,
  };
}
