import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

function ProfileNavigationItem({ to, children, isSelected, ...props }) {
  return (
    <li className="Secondary-Navigation-item">
      <Link
        to={to}
        className={classNames("CTA CTA--text--dark", {
          "is-selected": isSelected,
        })}
        {...props}
      >
        {children}
      </Link>
    </li>
  );
}

export default ProfileNavigationItem;
