import { isEmployeeRate } from ".";

function isBookingEmployeeRate(booking) {
  return (
    booking?.hotelProducts?.length &&
    booking.hotelProducts.some((hotelProduct) =>
      isEmployeeRate({ promoCode: hotelProduct.roomRate?.ratePlanCode })
    )
  );
}

export default isBookingEmployeeRate;
