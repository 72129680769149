import React from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";
import { isMobileApp, isIOS } from "utils";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import useRecaptcha from "../hooks/useRecaptcha";

export default function FormWithRecaptcha({
  handleSubmit, // from react-hook-form
  onSubmit,
  children,
  ...props
}) {
  const { locale } = useTranslation();

  const {
    recaptchaElementId,
    recaptchaCallbackName,
    isReady,
    recaptchaExecute,
  } = useRecaptcha({ locale });

  const onSubmitWithRecaptcha = (formValues, evt) => {
    recaptchaExecute().then((gRecaptchaResponse) => {
      onSubmit({ ...formValues, gRecaptchaResponse }, evt);
    });
  };

  if (!isReady) {
    return <BookingFlowLoadingIndicator />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmitWithRecaptcha)} {...props}>
      {children}

      <div
        id={recaptchaElementId}
        className={classNames("g-recaptcha", {
          iosBottom: isIOS() || isMobileApp(),
        })}
        data-callback={recaptchaCallbackName}
      />
    </form>
  );
}
