import { getSearchFormValuesFromQueryString } from "BookingFlow/utils";
import { convertBookingCodesToArrays } from "utils/utils";
import queryString from "query-string";

export default function canDeepLinkToConfirmYourStay(search) {
  const {
    hotelCode,
    dates: { checkIn, checkOut },
    roomCodes,
    offerCodes,
  } = convertBookingCodesToArrays(
    getSearchFormValuesFromQueryString(search, new Date())
  );

  return (
    [hotelCode, checkIn, checkOut, roomCodes, offerCodes].every(Boolean) &&
    roomCodes.length > 0 &&
    roomCodes.length === offerCodes.length &&
    !queryString.parse(search).focusOn
  );
}
