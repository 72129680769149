import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import { MediaContext } from "contexts/MediaContext";
import { useTranslation } from "hooks";
import { viewRoomDetailsEvt } from "store/searchResults";

export default function ViewMoreDetailsLink({
  isExpanded,
  toggleExpand,
  roomName,
  uniqueName,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const media = useContext(MediaContext);

  return (
    <button
      type="button"
      className={classNames("show-more-info-link", {
        view: !media.isLessThan.xs,
        "info-icon": media.isLessThan.xs,
        btn: media.isLessThan.xs,
        "btn--secondary": media.isLessThan.xs,
      })}
      onClick={() => {
        toggleExpand();
        dispatch(viewRoomDetailsEvt({ roomName }));
      }}
      aria-label={
        isExpanded
          ? `${t("Hide details")} (${roomName})`
          : `${t("View more details")} (${roomName})`
      }
      aria-controls={`room-item-tab-container-${uniqueName}`}
      aria-expanded={isExpanded}
    >
      {media.isGreaterThan.xs
        ? isExpanded
          ? t("Hide details")
          : t("View more details")
        : null}
    </button>
  );
}
