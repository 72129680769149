import React from "react";

import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import { useTranslation } from "hooks";
import { sleepPreferences } from "fixtures/preferences";
import { updateSleepPreferences, selectSleepPreferences } from "store/profile";
import Button from "../Button";
import Dropdown from "../Dropdown";
import useFormSubmit from "../../hooks/useFormSubmit";

export default function SleepForm({ closeForm }) {
  const { t } = useTranslation();

  const translateOptions = (options) =>
    options.map(({ label, value }) => ({ value, label: t(label) }));

  const {
    onSubmit,
    register,
    showLoadingIndicator,
    value: sleepPreferencesValues,
  } = useFormSubmit({
    closeForm,
    action: updateSleepPreferences,
    selector: selectSleepPreferences,
    fieldName: "sleepPreferences",
  });

  return (
    <>
      {showLoadingIndicator && <BookingFlowLoadingIndicator />}

      <form onSubmit={onSubmit}>
        <div className="row">
          {sleepPreferences.map(
            ({ title, identifier, options, description }) => (
              <div key={title} className="col-md-6">
                <Dropdown
                  name={`sleepPreferences.${title}`}
                  identifier={identifier}
                  options={translateOptions(options)}
                  label={t(title)}
                  register={register()}
                  value={sleepPreferencesValues[title]}
                  forceLabel
                />
                <p className="fs-your-preferences__preference__details">
                  {t(description)}
                </p>
              </div>
            )
          )}
          <div className="col-sm-12">
            <div className="fs-your-preferences__preference__actions">
              <Button type="submit" aria-label={t("Update")}>
                {t("Update")}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
