import React from "react";

import ItineraryDay from "./ItineraryDay";

export default function Itinerary({
  hotelCode,
  itineraryItemsByDay = {},
  isWebChatEnabled,
  openWebChat,
  itineraryQuickLinks,
}) {
  return (
    <div className="itinerary">
      <ul>
        {Object.keys(itineraryItemsByDay).map((date) => (
          <ItineraryDay
            key={date}
            hotelCode={hotelCode}
            date={date}
            itineraryItems={itineraryItemsByDay[date]}
            isWebChatEnabled={isWebChatEnabled}
            openWebChat={openWebChat}
            itineraryQuickLinks={itineraryQuickLinks}
          />
        ))}
      </ul>
    </div>
  );
}
