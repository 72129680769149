/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const addReservationModalSlice = createSlice({
  name: "addReservationModal",
  initialState: {
    isOpen: false,
    isSubmitting: false,
    isSuccess: false,
    error: null,
  },
  reducers: {
    setIsOpen(state, { payload }) {
      if (!payload) {
        state.isSubmitting = false;
        state.isSuccess = false;
        state.error = null;
      }
      state.isOpen = payload;
    },

    setIsSubmitting(state, { payload }) {
      if (payload) {
        state.error = null;
      }
      state.isSubmitting = payload;
    },

    setIsSuccess(state, { payload }) {
      if (payload) {
        state.isSubmitting = false;
      }
      state.isSuccess = payload;
    },

    setAddReservationError(state, { payload }) {
      state.error = payload;
    },
  },
});

export const {
  setIsOpen,
  setIsSubmitting,
  setIsSuccess,
  setAddReservationError,
} = addReservationModalSlice.actions;

export default addReservationModalSlice.reducer;
