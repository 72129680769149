import { ofType } from "redux-observable";
import { of, defer } from "rxjs";
import { switchMap, takeUntil, withLatestFrom } from "rxjs/operators";
import { push } from "connected-react-router";
import compose from "lodash/fp/compose";
import pick from "lodash/fp/pick";

import * as bookingFlowRoutes from "BookingFlow/bookingFlowRoutes";
import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import {
  fetchPropertiesFulfilled,
  selectProperty,
  fetchProperties$,
} from "store/properties";
import {
  fetchSearchResultsFulfilled,
  fetchSearchResults$,
} from "store/searchResults";
import { convertBookingCodesToArrays } from "utils/utils";
import { constructBooking, constructBookingCancel } from "../bookings.slice";
import { canConstructBooking, selectRoomsWithOffers } from "../packageHelpers";
import { addPackage$ } from "./addPackage";

export default function constructBookingEpic(action$, state$) {
  return action$.pipe(
    ofType(constructBooking.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { searchParams, locale, actions, location },
        },
        state,
      ]) =>
        defer(() =>
          Array.isArray(state.properties.data) &&
          state.properties.data.length > 0
            ? of({ payload: state.properties.data })
            : fetchProperties$({ locale }).pipe(
                ofType(fetchPropertiesFulfilled.type)
              )
        ).pipe(
          switchMap(({ payload }) => {
            const codes = compose(
              pick(["offerCodes", "roomCodes"]),
              convertBookingCodesToArrays
            )(searchParams);

            return ajaxWithHealthCheck$({
              locale,
            }).pipe(
              switchMap(() => {
                return fetchSearchResults$({
                  searchParams,
                  property: selectProperty(searchParams.hotelCode)({
                    properties: { data: payload },
                  }),
                  locale,
                  state,
                }).pipe(
                  ofType(fetchSearchResultsFulfilled.type),
                  switchMap(({ payload: { data } }) => {
                    return defer(() =>
                      canConstructBooking({
                        ...codes,
                        searchResults: data,
                      })
                        ? addPackage$({
                            resultSetId: data.id,
                            selectedPackages: selectRoomsWithOffers({
                              ...codes,
                              searchResults: data,
                            }),
                            searchParams,
                            locale,
                            actions,
                          })
                        : [
                            ...actions,
                            push({
                              ...location,
                              pathname: bookingFlowRoutes.chooseYourRoom.to({
                                locale,
                              }),
                            }),
                          ]
                    );
                  })
                );
              })
            );
          })
        )
    ),
    takeUntil(action$.pipe(ofType(constructBookingCancel.type)))
  );
}
