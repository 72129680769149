import { differenceInMilliseconds, isBefore, parse } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { selectPropertyContent } from "store/propertyContent";
import { WEB_CHECK_IN } from "utils/dateFormats";
import { selectPropertyWebCheckin } from "store/propertyWebCheckin";

const CHECK_IN_VALID_PERIOD_MILLISECONDS = 36 * 60 * 60 * 1000; // 36 Hours

export const selectBooking = ({ webCheckIn }) => webCheckIn?.booking;

export const selectFetchBookingErrors = ({
  webCheckIn: { apiErrors, supplierErrors } = {},
}) => {
  return {
    apiErrors: apiErrors || [],
    supplierErrors: supplierErrors || [],
  };
};

export const selectHasFetchBookingErrors = ({
  webCheckIn: { apiErrors, supplierErrors } = {},
}) => [apiErrors, supplierErrors].some((a) => a.length > 0);

export const selectConfirmCheckInError = ({ webCheckIn: { error } = {} }) =>
  error;

export const selectConfirmedCheckIn = ({ webCheckIn: { confirmed } = {} }) =>
  confirmed;

export const selectPropertyCheckInTimeUTC = (state) => {
  const hotelCode = state.webCheckIn?.booking?.hotelProducts[0]?.hotelCode;
  const checkInDate = state.webCheckIn?.booking?.startDate;
  if (!hotelCode || !checkInDate) {
    return undefined;
  }

  const property = selectPropertyContent(hotelCode)(state);
  const propertyWebCheckin = selectPropertyWebCheckin({
    propertyCode: hotelCode,
  })(state);
  if (!propertyWebCheckin || !property) {
    return undefined;
  }

  const propertyCheckInTime = propertyWebCheckin.checkInTime;
  const propertyTimeZone = property.timeZone;
  if (!propertyCheckInTime || !propertyTimeZone) {
    return undefined;
  }

  return zonedTimeToUtc(
    parse(
      `${checkInDate} ${propertyCheckInTime}`,
      WEB_CHECK_IN.PROPERTY_CHECK_IN_DATE_TIME_FORMAT,
      new Date()
    ),
    propertyTimeZone
  );
};

export const selectValidTimeToCheckIn = (state) => {
  const propertyCheckInTime = selectPropertyCheckInTimeUTC(state);

  return propertyCheckInTime
    ? differenceInMilliseconds(propertyCheckInTime, new Date()) <
        CHECK_IN_VALID_PERIOD_MILLISECONDS
    : false;
};

export const selectReservationIsPast = (state) => {
  const hotelCode = state.webCheckIn?.booking?.hotelProducts[0]?.hotelCode;
  const checkInDate = state.webCheckIn?.booking?.startDate;
  if (!hotelCode || !checkInDate) {
    return false;
  }

  const property = selectPropertyContent(hotelCode)(state);
  if (!property) {
    return false;
  }

  const propertyTimeZone = property.timeZone;
  if (!propertyTimeZone) {
    return false;
  }

  return isBefore(
    zonedTimeToUtc(
      parse(
        `${checkInDate} 23:59:59`,
        WEB_CHECK_IN.BOOKING_CHECK_IN_DATE_TIME,
        new Date()
      ),
      propertyTimeZone
    ),
    new Date()
  );
};
