import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "hooks";
import {
  setShouldOpen,
  selectShouldOpen,
  selectNotificationText,
} from "store/toastNotification";
import { ThemeContext, THEMES } from "contexts/ThemeContext";
import { MediaContext } from "contexts/MediaContext";
import classNames from "classnames";

const animationDuration = 500; // ms
const toastDuration = 4000; // ms

export default function ToastNotification() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const shouldOpen = useSelector(selectShouldOpen);
  const notificationText = useSelector(selectNotificationText);
  const { theme } = useContext(ThemeContext);
  const media = useContext(MediaContext);
  const isMobile = media.isLessThan.sm;
  const initialRender = useRef(true);
  const [isShowing, setIsShowing] = useState(false);

  const handleClose = () => {
    setIsShowing(false);
    window.setTimeout(() => {
      dispatch(setShouldOpen({ shouldOpen: false }));
    }, animationDuration);
  };

  useLayoutEffect(() => {
    if (
      (initialRender.current && shouldOpen === "onNextView") ||
      shouldOpen === "onSameView"
    ) {
      setIsShowing(true);
      const toastNotificationTimeout = window.setTimeout(
        handleClose,
        animationDuration + toastDuration
      );
      return () => {
        window.clearTimeout(toastNotificationTimeout);
        setIsShowing(false);
        dispatch(setShouldOpen({ shouldOpen: false }));
      };
    }
    initialRender.current = false;
    return () => {};
  }, [shouldOpen]);

  return (initialRender.current && shouldOpen === "onNextView") ||
    shouldOpen === "onSameView" ? (
    <div
      style={{
        animation: `${animationDuration / 1000}s slide${
          isShowing ? "In" : "Out"
        }${isMobile ? "Mobile" : ""}`,
      }}
      className={classNames("toast-notification", {
        "toast-notification--isOpen": isShowing,
        "toast-notification--isClosed": !isShowing,
        "toast-notification--dark": theme === THEMES.DARK,
        "toast-notification--light": theme !== THEMES.DARK,
      })}
    >
      <span
        className={classNames({
          "ty-d2": !isMobile,
          "ty-d4": isMobile,
        })}
      >
        {t(notificationText || "You have signed in successfully.")}
      </span>
      <button
        className={classNames("toast-notification__close-button", {
          "toast-notification__close-button--dark": theme === THEMES.DARK,
          "toast-notification__close-button--light": theme !== THEMES.DARK,
        })}
        onClick={handleClose}
        type="button"
      >
        <span>{t("close")}</span>
      </button>
    </div>
  ) : null;
}
