import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import queryString from "query-string";

import contentAjax from "api/content/contentAjax";

export default function getOffersTermsAndConditionsContent$({
  locale = "en",
  hotelCode,
  checkinDate,
  checkoutDate,
  roomTypeIds = [],
  promoCode,
  retryInEN,
}) {
  return forkJoin(
    roomTypeIds.map(({ roomTypeId, offerCodes }) => {
      const query = queryString.stringify({
        checkinDate,
        checkoutDate,
        promoCode,
        offerCodes: offerCodes.join(","),
      });

      return contentAjax({
        locale,
        url: `/properties/${hotelCode}/beds/${roomTypeId}/terms-and-conditions-by-offer?${query}`,
        retryInEN,
      }).pipe(
        map((offersTermsAndConditionsContent) => {
          return {
            offersTermsAndConditionsContent,
            hotelCode,
            roomTypeId,
            checkinDate,
            checkoutDate,
            promoCode,
          };
        })
      );
    })
  );
}
