import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";

import { useTranslation } from "hooks";
import { MediaContext } from "contexts/MediaContext";
import { DictionaryKeyContent } from "BookingFlow/components";
import { selectIsUserLoggedIn } from "store/profile";
import env from "config/env";
import { isMobileApp } from "utils";
import { QuickLinks } from "components";
import { leadWithCareLinkClicked } from "store/analyticEvents";
import { selectGlobalSettings } from "store/globalSettings";
import YourArrival from "./YourArrival";
import { RoomCard } from "./RoomCard";
import MultiRoomOptions from "./MultiRoomOptions";
import ProfileBenefits from "./ProfileBenefits";
import SignInOrRegisterBlock from "./SignInOrRegisterBlock";
import { SurveyCard } from ".";

const { IMAGE_BASE_URL } = env;

export default function MainSection({
  completedBooking,
  contactName,
  dictionaryKeys,
  email,
  goToRegisterWithReservation,
  isBookingCancelled,
  isPreferredPartners,
  phoneNumber,
  postBookingComments,
  rooms,
  register,
  control,
  errors,
  formState,
  showAppDownload,
  downloadMobileAppUrl,
  propertyStatusLink,
  propertyTravelAdvisoryLink,
  showBookingSurvey,
  setShowBookingSurvey,
  createSurveyLink,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const media = useContext(MediaContext);
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);

  const { globalHealthAndSafetyLink } = useSelector(selectGlobalSettings);

  const quickLinks = [
    globalHealthAndSafetyLink && {
      href: globalHealthAndSafetyLink,
      icon: "/images/profiles_2018/Health_&_Safety.svg",
      title: t("Enhanced Health & Safety"),
      description: t(
        "Learn more about Lead with Care, our global health & safety program"
      ),
      onClick: () => {
        dispatch(
          leadWithCareLinkClicked({
            linkName: "Enhanced Health & Safety",
          })
        );
      },
    },
    propertyStatusLink && {
      href: propertyStatusLink,
      icon: "/images/profiles_2018/Available_Amenities.svg",
      title: t("What's Open at the Property?"),
      description: t("Check available Amenities & Services"),
      onClick: () => {
        dispatch(
          leadWithCareLinkClicked({
            linkName: "What's Open at the Property?",
          })
        );
      },
    },
    propertyTravelAdvisoryLink && {
      href: propertyTravelAdvisoryLink,
      icon: "/images/profiles_2018/Travel_Advisory.svg",
      title: t("Preparing for your Travel"),
      description: t(
        "Review travel advisories, and necessary documents for your trip"
      ),
      onClick: () => {
        dispatch(
          leadWithCareLinkClicked({
            linkName: "Preparing for your Travel",
          })
        );
      },
    },
  ].filter(Boolean);

  const appLogoSrc = `${IMAGE_BASE_URL}/content/dam/fourseasons/images/web/artwork/logos/FINAL_MobileAppLogo_964.png/_jcr_content/renditions/cq5dam.thumbnail.319.319.png`;
  const appTextSrc = `${IMAGE_BASE_URL}/content/dam/fourseasons/images/web/artwork/logos/FS_APP_TEXT_SM.png/jcr:content/renditions/cq5dam.web.637.358.png`;

  return (
    <div className="col-sm-9 col-xs-12">
      {media.isGreaterThan.sm && !isUserLoggedIn && !isPreferredPartners && (
        <div className="row">
          <div className="col-sm-6">
            <SignInOrRegisterBlock
              contactName={contactName}
              email={email}
              phoneNumber={phoneNumber}
              goToRegisterWithReservation={goToRegisterWithReservation}
            />
          </div>
          <div className="col-sm-6">
            <ProfileBenefits />
          </div>
        </div>
      )}

      {showBookingSurvey && (
        <SurveyCard
          createSurveyLink={createSurveyLink}
          setShowBookingSurvey={setShowBookingSurvey}
        />
      )}

      {media.isGreaterThan.sm && (
        <h2 className="page-heading">
          <DictionaryKeyContent dict={dictionaryKeys.personalizeYourStay} />
        </h2>
      )}

      <YourArrival
        dictionaryKeys={dictionaryKeys}
        register={register}
        control={control}
        errors={errors}
        isBookingCancelled={isBookingCancelled}
        isPreferredPartners={isPreferredPartners}
      />
      {media.isGreaterThan.sm &&
        completedBooking.hotelProducts.map((hotelProduct, roomIndex) => (
          <RoomCard
            key={roomIndex}
            roomIndex={roomIndex}
            reservationId={hotelProduct.reservationId}
            cancelRef={hotelProduct.cancelRef}
            dictionaryKeys={dictionaryKeys}
            register={register}
            control={control}
            guests={postBookingComments.comments[roomIndex].roomGuests}
            errors={get(errors, ["comments", roomIndex, "roomGuests"], [])}
            isMultiRoomBooking={rooms.length > 1}
            isBookingCancelled={isBookingCancelled}
            {...rooms[roomIndex]}
          />
        ))}
      {rooms.length > 1 && <MultiRoomOptions register={register} />}
      <div className="row">
        <div className="col-sm-6 col-sm-offset-6 col-md-4 col-md-offset-8">
          <button
            id="submit-personalisation-btn"
            type="submit"
            className="btn btn-primary"
            disabled={isBookingCancelled || !formState.isDirty}
          >
            {t("Submit preferences")}
          </button>
        </div>
      </div>

      {quickLinks.length > 0 && <QuickLinks links={quickLinks} />}

      {showAppDownload && !isMobileApp() && (
        <div className="row visible-xs-block">
          <div className="container app-cta">
            <div className="col-xs-4 col-xs-offset-4">
              <img className="app-logo" src={appLogoSrc} alt="" />
            </div>
            <div className="col-xs-10 col-xs-offset-1">
              <img className="app-text-img" src={appTextSrc} alt="" />
            </div>

            <div className="download-text col-xs-12">
              {t("Service in the palm of your hand")}
            </div>

            <div className="col-xs-12 app-btn">
              <a
                className="btn btn-secondary"
                href={downloadMobileAppUrl}
                title="Download the Four Seasons App"
              >
                {t("Download the App")}
              </a>
            </div>
          </div>
        </div>
      )}

      {!showAppDownload && (
        <div className="row visible-xs-block">
          <div className="backup-padding col-xs-12">&nbsp;</div>
        </div>
      )}
    </div>
  );
}
