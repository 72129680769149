import React, { useEffect, useRef } from "react";

import RoomsSelectionRoom from "./RoomsSelectionRoom";

function RoomsSelection({ rooms, isMultiRoom, onEditClick }) {
  const rowRef = useRef();
  const selectedRef = useRef();

  const selectedIndex = rooms.findIndex(({ isSelected }) => isSelected);
  const selectedRoom = rooms[selectedIndex];

  useEffect(() => {
    if (rowRef?.current && selectedRef?.current) {
      const { width } = selectedRef.current.getBoundingClientRect();
      rowRef.current.scrollTo({
        top: 0,
        left: selectedIndex * width,
        behavior: "smooth",
      });
    }
  }, [selectedIndex]);

  return (
    <div className="rooms-selection">
      <div className="rooms-selection__row-container">
        <div className="rooms-selection__row" ref={rowRef}>
          {rooms.map(
            ({ index, onEditRoomClick, onEditPackageClick, ...room }) => {
              const showChangeDatesModalForEditRoom = Boolean(
                index < selectedIndex - 1 ||
                  (index === selectedIndex - 1 && room.roomName)
              );

              const showChangeDatesModalForEditPackage = Boolean(
                index < selectedIndex - 1 ||
                  (index === selectedIndex - 1 && selectedRoom.roomName)
              );

              return (
                <RoomsSelectionRoom
                  key={index}
                  {...room}
                  isMultiRoom={isMultiRoom}
                  onEditRoomClick={
                    showChangeDatesModalForEditRoom
                      ? onEditClick
                      : onEditRoomClick
                  }
                  onEditPackageClick={
                    showChangeDatesModalForEditPackage
                      ? onEditClick
                      : onEditPackageClick
                  }
                  ref={selectedIndex === index ? selectedRef : undefined}
                />
              );
            }
          )}
        </div>
      </div>
    </div>
  );
}

export default RoomsSelection;
