import React from "react";
import { useTranslation } from "hooks";
import { Button, Link } from "Profile/components";
import Modal from "Profile/ProfileView/Modal";
import { subHours, addMinutes, format } from "date-fns";
import { WEB_CHECK_IN } from "utils/dateFormats";
import downloadAsFile from "downloadjs";
import querystring from "query-string";
import env from "config/env";

const { RESERVATIONS_SITE_URL } = env;

function AddToCalendarModal({
  confirmation,
  hotelCode,
  checkInTimeUTC,
  close,
}) {
  const { t } = useTranslation();

  const calendarStartDate = subHours(checkInTimeUTC, 36);

  const calendarEndDate = addMinutes(calendarStartDate, 15);

  const formatCalendarDate = (calendarDate) =>
    `${format(
      new Date(
        calendarDate.valueOf() + calendarDate.getTimezoneOffset() * 60 * 1000
      ),
      WEB_CHECK_IN.CALENDARS
    )}Z`;

  const calendarStartString = formatCalendarDate(calendarStartDate);
  const calendarEndString = formatCalendarDate(calendarEndDate);
  const checkInUrl = `${RESERVATIONS_SITE_URL}/check-in?${querystring.stringify(
    {
      confirmationNumber: confirmation,
      hotelCode,
    }
  )}`;

  const downloadIsc = () => {
    const icsData = `BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nCLASS:PUBLIC\nDESCRIPTION:${checkInUrl}\nDTSTART:${calendarStartString}\nDTEND:${calendarEndString}\nLOCATION:Four Seasons\nSUMMARY:Four Seasons Check-In\nTRANSP:TRANSPARENT\nEND:VEVENT\nEND:VCALENDAR`;

    downloadAsFile(icsData, "Four Seasons CheckIn.ics", "text/plain");
  };

  const calendarOptions = [
    { label: "iCalendar", download: true },
    {
      label: "Yahoo! Calendar",
      link: `http://calendar.yahoo.com/?${querystring.stringify({
        v: "60",
        view: "d",
        type: "20",
        title: "Four Seasons Check-In",
        st: calendarStartString,
        et: calendarEndString,
        desc: checkInUrl,
        in_loc: "Four Seasons",
      })}`,
    },
    {
      label: "Google Calendar",
      link: `https://www.google.com/calendar/render?${querystring.stringify({
        action: "TEMPLATE",
        text: "Four Seasons Check-In",
        dates: `${calendarStartString}/${calendarEndString}`,
        details: checkInUrl,
        location: "Four Seasons",
      })}`,
    },
    {
      label: "Microsoft Calendar",
      link: `http://calendar.live.com/calendar/calendar.aspx?${querystring.stringify(
        {
          rru: "addevent",
          summary: "Four Seasons Check-In",
          dtstart: calendarStartString,
          dtend: calendarEndString,
          description: checkInUrl,
          location: "Four Seasons",
        }
      )}`,
    },
    { label: "Outlook", download: true },
    { label: "Lotus Notes", download: true },
  ];

  return (
    <Modal close={close} className="employ-portal-modal-container">
      <div className="modal--friends-and-family view--profile-folio modal--add-to-calendar">
        <div className="container">
          <h3 className="ty-h3">{t("Add To Calendar")}</h3>
          <div className="row links">
            {calendarOptions.map(({ label, link = "#", download }) => (
              <p key={label} className="col-sm-12 col-md-6">
                <Link
                  href={link}
                  onClick={(evt) => {
                    if (download) {
                      evt.preventDefault();
                      downloadIsc();
                    }
                  }}
                  target="_blank"
                >
                  {t(label)}
                </Link>
              </p>
            ))}
          </div>
          <Button kind="secondary" theme="light" onClick={close}>
            {t("Close")}
          </Button>
        </div>

        <button className="btn-close" type="button" onClick={close}>
          <span>{t("Close")}</span>
        </button>
      </div>
    </Modal>
  );
}

export default AddToCalendarModal;
