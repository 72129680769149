import React, { useRef, useContext, useEffect } from "react";
import { useDay } from "@datepicker-react/hooks";
import classNames from "classnames";
import { add, addDays, isWithinInterval, format, isAfter } from "date-fns";

import DatepickerContext from "./datepickerContext";

const today = new Date();

function Day({ day }) {
  const dayRef = useRef(null);
  const {
    focusedDate,
    handleNavigatingAwayFromCalendar,
    handleNavigatingAwayFromNextMonth,
    isDateAvailable,
    isDateBlocked,
    isDateFocused,
    isDateHovered,
    isDateSelected,
    isEndDate,
    isFirstOrLastSelectedDate,
    isRestricted,
    isStartDate,
    isUnselectable,
    isOutsideCheckInRange,
    onDateFocus,
    onDateSelect,
    startDate,
    validInterval = {
      start: today,
      end: add(today, { years: 1 }),
    },
  } = useContext(DatepickerContext);
  const { isSelected, disabledDate, onClick, tabIndex } = useDay({
    date: day.date,
    focusedDate,
    isDateBlocked,
    isDateFocused,
    isDateHovered,
    isDateSelected,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
  });

  const handleOnClick = () => {
    if (!disabledDate) {
      onDateFocus(day.date);
      onClick();
    }
  };

  const canNavigateToDate = (nextDate) =>
    [
      isWithinInterval(nextDate, validInterval),
      isDateAvailable(nextDate),
      isAfter(nextDate, addDays(startDate, -1)),
    ].every(Boolean);

  const onKeyDown = (e) => {
    switch (e.key) {
      case "ArrowRight":
        if (canNavigateToDate(addDays(day.date, 1)))
          onDateFocus(addDays(day.date, 1));
        break;
      case "ArrowLeft":
        if (canNavigateToDate(addDays(day.date, -1)))
          onDateFocus(addDays(day.date, -1));
        break;
      case "ArrowUp":
        if (canNavigateToDate(addDays(day.date, -7)))
          onDateFocus(addDays(day.date, -7));
        break;
      case "ArrowDown":
        if (canNavigateToDate(addDays(day.date, 7)))
          onDateFocus(addDays(day.date, 7));
        break;
      case " ":
        handleNavigatingAwayFromNextMonth(e);
        break;
      case "Tab":
        handleNavigatingAwayFromCalendar(e);
        break;
      case "Enter":
        handleOnClick();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (dayRef && dayRef.current && isDateFocused(day.date)) {
      dayRef.current.focus();
    }
  }, [dayRef, day.date, isDateFocused]);

  return (
    <td
      className={classNames("date-field", {
        "date-range-selected": isSelected,
        "ui-datepicker-unselectable ui-state-disabled": disabledDate,
        "is-restricted": isRestricted(day.date),
        "is-unavailable": !isDateAvailable(day.date),
        "date-range-selected-first": isStartDate(day.date),
        "date-range-selected-last": isEndDate(day.date),
        "ui-datepicker-unselectable": isUnselectable(day.date),
        "outside-checkin-range": isOutsideCheckInRange(day.date),
      })}
      aria-disabled={disabledDate}
      id={`day-${format(day.date, "yyyyMMdd")}`}
    >
      <span
        className="ui-state-default"
        role="button"
        onClick={handleOnClick}
        onKeyDown={onKeyDown}
        tabIndex={tabIndex}
        ref={dayRef}
      >
        {day.dayLabel}
      </span>
    </td>
  );
}

export default Day;
