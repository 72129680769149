import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { parse, format, isValid as dateFnsIsValid } from "date-fns";

import { errorCodes, getErrorCodeForTranslations } from "utils/errorCodes";
import { getFindAHotelLink } from "utils/externalLinks";
import * as regexPatterns from "utils/regexPatterns";
import { isNotBlank } from "utils/isBlank";
import trimStringValues from "utils/trimStringValues";
import { useTranslation } from "hooks";
import useProperties from "hooks/useProperties";
import {
  selectBookingSupplierError,
  selectBookingsApiError,
} from "store/bookings";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import {
  Button,
  Checkbox,
  ErrorMessage,
  ExpiryDateInput,
  TextInput,
} from "Profile/components";
import Typeahead from "Profile/ProfileView/Typeahead";

function EnterReservationDetails({ onSubmit = () => {}, onCancel = () => {} }) {
  const { t, dateFormat, locale } = useTranslation();

  const { properties, isLoading: isLoadingProperties } = useProperties();
  const supplierError = useSelector(selectBookingSupplierError);
  const apiError = useSelector(selectBookingsApiError);
  let errorParams = [];

  if (supplierError?.errorCode === errorCodes.COULD_NOT_RETRIEVE) {
    errorParams = [getFindAHotelLink(locale)];
  }

  const validHotelCodes = properties.map((p) => p.owsCode);

  const {
    control,
    register,
    handleSubmit,
    formState,
    errors: formErrors,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      surname: "",
      hotelCode: "",
      reservationId: "",
      creditCardNumber: "",
      creditCardExpiryDate: "",
      reservationStartDate: "",
      confirmation: false,
    },
  });

  const errors = {
    ...(apiError?.field
      ? {
          [apiError.field]: {
            message: t(apiError.userMessage || apiError.errorCode),
          },
        }
      : {}),
    ...formErrors,
  };

  const [findByCreditCard, setFindByCreditCard] = useState(false);
  const toggleFindByCreditCard = () => setFindByCreditCard(!findByCreditCard);

  const parseDate = (str) => {
    return parse(str, dateFormat.dateFnsFormat, new Date());
  };

  const isValidDate = (str) => {
    return dateFnsIsValid(parseDate(str));
  };

  const getShortDateLabel = (label) => {
    if (["jp", "zh", "zh_hant"].includes(locale)) {
      return "年/月/日";
    }
    if (locale === "kr") {
      return "년/월/일";
    }
    return label;
  };

  const onSubmitTrimValues = ({ reservationStartDate, ...formValues }) => {
    onSubmit(
      trimStringValues({
        ...formValues,
        reservationStartDate: reservationStartDate
          ? format(parseDate(reservationStartDate), "yyyy-MM-dd")
          : reservationStartDate,
      })
    );
  };

  useEffect(() => {
    reset();
  }, [findByCreditCard]);

  if (isLoadingProperties) {
    return <BookingFlowLoadingIndicator />;
  }

  return (
    <div className="view view--register-reservation-details">
      <h1>{t("Add Your Reservation")}</h1>

      <p className="intro">
        {t(
          "Please provide your booking details and we'll add this trip to your profile."
        )}
      </p>

      <form
        onSubmit={handleSubmit(onSubmitTrimValues)}
        noValidate
        autoComplete="off"
      >
        {(supplierError || apiError) && (
          <ErrorMessage className="not-found">
            {t(
              getErrorCodeForTranslations({
                errorCode: supplierError?.errorCode || apiError?.errorCode,
              }),
              errorParams || []
            )}
          </ErrorMessage>
        )}

        {!findByCreditCard && (
          <div>
            <TextInput
              name="surname"
              label={t("Last name")}
              register={register({
                required: true,
                validate: {
                  isNotBlank,
                },
              })}
              error={errors.surname && t("Please enter your last name")}
              required
            />

            <Controller
              control={control}
              name="hotelCode"
              rules={{
                required: true,
                validate: {
                  isNotBlank,
                  isValidHotelCode: (value) => validHotelCodes.includes(value),
                },
              }}
              render={({ ref: _ignoredRef, ...renderProps }) => {
                return (
                  <Typeahead
                    options={properties}
                    {...renderProps}
                    error={errors.hotelCode && t("Please select a hotel")}
                    required
                  />
                );
              }}
            />

            <TextInput
              name="reservationId"
              label={t("Confirmation number")}
              register={register({
                required: true,
                validate: {
                  isNotBlank,
                },
              })}
              error={
                errors.reservationId &&
                t("Please enter your reservation number")
              }
              required
            />

            <p className="find-reservation-using-credit-card">
              <span className="or">{t("Or")}</span>
              <Button kind="link" onClick={toggleFindByCreditCard}>
                {t("Find reservation using credit card")}
              </Button>
            </p>
          </div>
        )}

        {findByCreditCard && (
          <div>
            <div className="row">
              <div className="col-md-6">
                <TextInput
                  name="firstName"
                  label={t("First name")}
                  register={register({
                    required: true,
                    validate: {
                      isNotBlank,
                    },
                  })}
                  error={errors.firstName && t("Please enter your first name")}
                  required
                />
              </div>
              <div className="col-md-6">
                <TextInput
                  name="surname"
                  label={t("Last name")}
                  register={register({
                    required: true,
                    validate: {
                      isNotBlank,
                    },
                  })}
                  error={errors.surname && t("Please enter your last name")}
                  required
                />
              </div>
            </div>

            <Controller
              control={control}
              name="hotelCode"
              rules={{
                required: true,
                validate: {
                  isNotBlank,
                  isValidHotelCode: (value) => validHotelCodes.includes(value),
                },
              }}
              render={({ ref: _ignoredRef, ...renderProps }) => {
                return (
                  <Typeahead
                    options={properties}
                    {...renderProps}
                    error={errors.hotelCode && t("Please select a hotel")}
                    required
                  />
                );
              }}
            />

            <TextInput
              name="creditCardNumber"
              label={t("Last four digits of Credit Card")}
              register={register({
                required: true,
                maxLength: 4,
                minLength: 4,
                pattern: regexPatterns.onlyDigits,
                validate: { isNotBlank },
              })}
              error={
                errors.creditCardNumber &&
                t("Please enter the last four digits of your credit card")
              }
              required
              maxLength="4"
            />

            <Controller
              name="creditCardExpiryDate"
              control={control}
              rules={{
                required: true,
                validate: { isNotBlank },
              }}
              render={({ ref: _ignoredRef, ...renderProps }) => (
                <ExpiryDateInput
                  label={t("Expiry Date")}
                  error={
                    errors.creditCardExpiryDate &&
                    t("Please enter your credit card expiry date")
                  }
                  required
                  {...renderProps}
                />
              )}
            />

            <TextInput
              name="reservationStartDate"
              label={t("Date of arrival")}
              placeholder={getShortDateLabel(t(dateFormat.label))}
              register={register({
                required: true,
                validate: { isNotBlank, isValidDate },
              })}
              error={
                errors.reservationStartDate &&
                t("Please enter your date of arrival")
              }
              required
            />

            <p className="find-reservation-using-credit-card">
              <span className="or">{t("Or")}</span>
              <Button kind="link" onClick={toggleFindByCreditCard}>
                {t("Find reservation using confirmation number")}
              </Button>
            </p>
          </div>
        )}

        <Checkbox name="confirmation" register={register({ required: true })}>
          {t(
            "I confirm that I am the guest, another individual authorized and responsible for the requested reservation and payment details, or I have otherwise been authorized to update this reservation."
          )}
        </Checkbox>

        <div className="view__actions">
          <div className="view__action">
            <Button kind="secondary" onClick={onCancel}>
              {t("Back")}
            </Button>
          </div>

          <div className="view__action">
            <Button type="submit" disabled={!formState.isValid}>
              {t("Next")}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EnterReservationDetails;
