import React from "react";

import { useTranslation } from "hooks";
import { getTollFreeNumbersLink } from "utils/externalLinks";
import env from "config/env";

const { YACHTS_CONTACT_US_URL } = env;

export default function RequestOtpErrorFooter({ isYachtsMode }) {
  const { t, locale } = useTranslation();
  if (isYachtsMode) {
    return (
      <div className="problem-please-call">
        <p
          dangerouslySetInnerHTML={{
            __html: t(
              'If you are having problems signing in, please <a href="{0}">contact us</a>.',
              [YACHTS_CONTACT_US_URL]
            ),
          }}
        />
      </div>
    );
  }
  return (
    <div className="problem-please-call">
      <p>{t("If you are having problems signing in, please call us.")}</p>
      <p>
        <a href="tel:+18008195053">+1 (800) 819-5053</a>
        <a className="other-toll-free" href={getTollFreeNumbersLink(locale)}>
          {t("Other Toll-Free Numbers")}
        </a>
      </p>
    </div>
  );
}
