import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import classNames from "classnames";

import "../stylesheets/sass/booking-flow.scss";
import { getIsoCodeForLocale } from "config/languages";
import { useTranslation } from "hooks";
import { MediaContext } from "contexts/MediaContext";
import getFullPageTitle from "utils/getFullPageTitle";
import { isMobileApp, getHMTLTagClassNames } from "utils";
import { FsHeader } from "Profile/components";
import {
  BookingFlowFooter,
  BookingFlowSteps,
  ElementToCatchFocusThatHappensAfterUserAcceptCookies,
} from "BookingFlow/components";

export default function BookingFlowLayout({
  children,
  contactUs,
  employeeMode = false,
  htmlBodyClassName = "",
  isPreferredPartners,
  pageTitle,
  ppReturnUrl,
}) {
  const { t, i18n, locale } = useTranslation();
  const media = useContext(MediaContext);
  const showBestRateGuaranteed =
    locale === "en" && !isPreferredPartners && !employeeMode;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <Helmet>
        <html
          lang={getIsoCodeForLocale(i18n.language)}
          className={getHMTLTagClassNames({
            locale,
            dir: i18n.dir(i18n.language),
            kind: "fs-booking-flow",
          })}
        />
        <title>{getFullPageTitle(t, pageTitle)}</title>
        <body
          className={classNames(htmlBodyClassName, {
            "no-y-scroll": isMenuOpen,
          })}
        />
      </Helmet>

      <ElementToCatchFocusThatHappensAfterUserAcceptCookies />
      <a href="#main" className="skipLink">
        {t("Skip to Main content")}
      </a>

      {!isMobileApp() && (
        <FsHeader
          pageTitle={pageTitle}
          showBestRateGuaranteed={showBestRateGuaranteed}
          hideLanguageSelector
          isPreferredPartners={isPreferredPartners}
          ppReturnUrl={ppReturnUrl}
          contactUs={contactUs}
          enableProfileLinkFlyout={false}
          isMobile={media.isLessThan.sm}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        >
          {!media.isLessThan.sm && (
            <BookingFlowSteps isPreferredPartners={isPreferredPartners} />
          )}
        </FsHeader>
      )}

      <main id="main">{children}</main>

      {!isMobileApp() && (
        <BookingFlowFooter showBestRateGuaranteed={showBestRateGuaranteed} />
      )}
    </>
  );
}
