import { ajax } from "rxjs/ajax";

import env from "config/env";
import { basePostRequest } from "../tretail/baseRequest";

const { API_BASE_URL } = env;

export default function proceedCheckIn$(requestBody) {
  return ajax({
    ...basePostRequest({ locale: requestBody.locale }),
    url: `${API_BASE_URL}/CheckInRequests/proceedCheckin`,
    body: requestBody,
  });
}
