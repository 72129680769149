import { ofType } from "redux-observable";
import { of } from "rxjs";
import { switchMap, takeUntil, map, catchError } from "rxjs/operators";

import getCalendarAvailability$ from "api/content/getCalendarAvailability/getCalendarAvailability";
import {
  fetchCalendarAvailability,
  fetchCalendarAvailabilityFulfilled,
  fetchCalendarAvailabilityCancel,
  fetchCalendarAvailabilityFailed,
} from "./calendarAvailability.slice";

const fetchCalendarAvailabilityEpic = (action$) =>
  action$.pipe(
    ofType(fetchCalendarAvailability.type),
    switchMap(({ payload }) =>
      getCalendarAvailability$(payload).pipe(
        map(fetchCalendarAvailabilityFulfilled),
        catchError((error) => of(fetchCalendarAvailabilityFailed(error))),
        takeUntil(action$.pipe(ofType(fetchCalendarAvailabilityCancel.type)))
      )
    )
  );

export default fetchCalendarAvailabilityEpic;
