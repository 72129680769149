import React from "react";

import { useTranslation } from "hooks";
import { Button } from "Profile/components";

export default function ReservationHighlights({ onClick, highlights }) {
  const { t } = useTranslation();

  return (
    <div className="col-lg-2">
      <div className="fs-trip__highlights">
        <dl>
          {[
            highlights.map(({ label, value }) => (
              <React.Fragment key={label}>
                <dt>{t(label)}</dt>
                <dd>{value}</dd>
              </React.Fragment>
            )),
          ]}
        </dl>
        {onClick && (
          <Button kind="link" onClick={onClick}>
            {t("View Details")}
          </Button>
        )}
      </div>
    </div>
  );
}
