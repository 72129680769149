export const tribeErrorCodes = {
  NO_ACCOUNT_FOUND_WITH_EMAIL_ADDRESS: "E0218",
  NO_ACCOUNT_FOUND_WITH_PHONE_NUMBER: "E0219",
  INVALID_LOGIN_OTP_CODE: "E0201",
  INVALID_LOGIN_OTP_EXPIRED: "E0202",
  COULD_NOT_RETRIEVE: "E0318",
  EMAIL_PHONE_ALREADY_USED: "E0203",
};

export const getErrorCodeForTranslations = ({ errorCode }) => {
  if (!errorCode.startsWith("E")) {
    return errorCode;
  }

  return `error.${errorCode}`;
};

/*
# Error categories are:
#  01 Category (OJ_01_XXXX) - end user or validation errors
#  02 Category (OJ_02_XXXX) - back end errors e.g. timeout or connection errors
#  03 Category (OJ_03_XXXX) - system errors e.g. infrastructure, environment, bugs
#  04 Category (OJ_04_XXXX) - application errors e.g. no availability, booking, reprice
#  05 Category (OJ_05_XXXX) - Validation errors returned from xDistributor
*/

export const errorCodes = {
  ...tribeErrorCodes,

  // CUSTOM
  VERIFY_EMAIL_CODE_EXPIRED: "OJC-01-0010",
  VERIFY_EMAIL_CACHE_ENTRY_EMPTY: "OJC-01-0011",
  VERIFY_EMAIL_INCORRECT: "OJC-01-0012",
  VERIFY_EMAIL_NOT_IN_PROFILE: "OJC-01-0013",

  CHECK_SUBMITTED_DATE_CODE: "OJ-01-0001",
  RETURN_DATE_BEFORE: "OJ-01-0011",
  PROPERTY_TYPE_MISMATCH: "OJ-01-0012",
  INVALID_USER_TYPE: "OJ-01-0013",
  LOGIN_FAILURE: "OJ-01-0014",
  ARRIVAL_DATE_PAST_CODE: "OJ-01-0015",
  DEPART_DATE_BEFORE_PREVIOUS_BOUND_DEPART_DATE: "OJ-01-0016",
  DEPART_DATE_PAST_CODE: "OJ-01-0017",
  CHECK_SUBMITTED_RESULT_ID: "OJ-01-0018",
  CHECK_BOOKING_ID_CODE: "OJ-01-0020",
  CHECK_SUBMITTED_SEARCH_ID: "OJ-01-0021",
  NOT_NULL: "OJ-01-0023",
  INVALID_JWT: "OJ-01-0024",
  JWT_MALFORMED: "OJ-01-0025",
  JWT_EXPIRED: "OJ-01-0026",
  MISSING_REQUEST_HEADER: "OJ-01-0027",
  USER_NOT_LOGGED_IN: "OJ-01-0028",
  CHECK_SUBMITTED_TRAVEL_DOC_ID: "OJ-01-0029",
  CHECK_SUBMITTED_FIRST_NAME: "OJ-01-0030",
  CHECK_SUBMITTED_SURNAME: "OJ-01-0031",
  CHECK_SUBMITTED_PAXTYPE: "OJ-01-0033",
  CHECK_SUBMITTED_EMAIL: "OJ-01-0034",
  CHECK_SUBMITTED_PRODUCT_ID: "OJ-01-0035",
  CHECK_SUBMITTED_PRICE_ID: "OJ-01-0038",
  CHECK_SUBMITTED_PAX_GENDER: "OJ-01-0039",
  CHECK_SUBMITTED_PAX_NATIONALITY: "OJ-01-0040",
  CHECK_SUBMITTED_PAX_DOB: "OJ-01-0041",
  CHECK_SUBMITTED_TRAVEL_DOC_TYPE: "OJ-01-0042",
  CHECK_SUBMITTED_TRAVEL_DOC_EXPIRY_DATE: "OJ-01-0043",
  CHECK_SUBMITTED_TRAVEL_DOC_COUNTRY: "OJ-01-0044",
  CHECK_SUBMITTED_PHONE: "OJ-01-0045",
  CHECK_SUBMITTED_PAX_TYPE: "OJ-01-0046",
  NATIONAL_IDENTITY_DOCUMENT_NEEDED: "OJ-01-0047",
  PAX_MEAL_NOT_ALLOWED: "OJ-01-0049",
  CHINESE_NAME_WRONG_INPUT: "OJ-01-0050",
  CHECK_SUBMITTED_NAME_ILLEGAL_CHARACTER: "OJ-01-0051",
  NO_CHINESE_INTERNATIONAL: "OJ-01-0052",
  DOMESTIC_ENGLISH_NAME_MISSING: "OJ-01-0054",
  CHECK_SUBMITTED_TRAVEL_DOC: "OJ-01-0055",
  BAD_LOCATION_ID: "OJ-01-0056",
  UNSUPPORTED_ENUM_VALUE: "OJ-01-0057",
  CHECK_SUBMITTED_INFANT_TRAVEL_DOC: "OJ-01-0058",
  INFANT_INVENTORY_NOT_ENOUGH: "OJ-01-0059",
  MALFORMED_REQUEST: "OJ-01-0060",
  RETURN_LOCATION_SAME_AS_DEPART_LOCATION: "OJ-01-0061",
  INFANT_INVENTORY_NOT_AVAIL: "OJ-01-0062",
  INFANT_ADT_RELATIONSHIP_INVALID: "OJ-01-0064",
  INFANT_ADT_RELATIONSHIP_IS_EMPTY: "OJ-01-0065",
  CHECK_SUBMITTED_AREACODE: "OJ-01-0108",
  CHECK_SUBMITTED_COUNTRY_CODE: "OJ-01-0109",
  CHECK_SUBMITTED_TITLE: "OJ-01-0110",
  CHECK_SUBMITTED_CUSTOMER_FLIGHT_DETAILS: "OJ-01-0111",
  CHECK_SUBMITTED_VEHICLE_PAXTYPE: "OJ-01-0115",
  CHECK_SUBMITTED_CUSTOMER_DOB: "OJ-01-0116",
  CHECK_SUBMITTED_MIDDLE_NAME: "OJ-01-0118",
  CHECK_SUBMITTED_NAME: "OJ-01-0119",
  CHECK_SUBMITTED_AGE: "OJ-01-0120",
  CHECK_SUBMITTED_TITLE_NOT_EXISTS: "OJ-01-0122",
  CHECK_SUBMITTED_GENDER_NOT_EXISTS: "OJ-01-0123",
  CHECK_SUBMITTED_MIDDLENAME_NOT_EXISTS: "OJ-01-0124",
  WRONG_PRODUCT_TYPE: "OJ-01-0125",
  CHECK_SUBMITTED_CASH_AMOUNT: "OJ-01-0127",
  CHECK_SUBMITTED_POINTS_QUANTITY: "OJ-01-0128",
  CHECK_SUBMITTED_CONTACT: "OJ-01-0129",
  CHECK_SUBMITTED_CREDIT_CARD_NUMBER: "OJ-01-0130",
  CHECK_SUBMITTED_EXPIRY_DATE: "OJ-01-0131",
  CHECK_SUBMITTED_SERIES_CODE: "OJ-01-0132",
  CHECK_SUBMITTED_COUNTRY_NAME: "OJ-01-0134",
  CHECK_SUBMITTED_EXPIRY_DATE_FORMAT: "OJ-01-0137",
  CHECK_SUBMITTED_REDIRECT_TOKEN: "OJ-01-0142",
  CHECK_SUBMITTED_CHEQUE_SORT_CODE: "OJ-01-0143",
  CHECK_SUBMITTED_CHEQUE_ACCOUNT_CODE: "OJ-01-0144",
  CHECK_SUBMITTED_CHEQUE_CODE: "OJ-01-0145",
  CHECK_SUBMITTED_CASH_COMMENT: "OJ-01-0146",
  CHECK_SUBMITTED_OFFLINE_STATUS: "OJ-01-0147",
  CHECK_SUBMITTED_PAYMENT_TYPE: "OJ-01-0148",
  CHECK_SUBMITTED_POSTCODE: "OJ-01-0150",
  CHECK_SUBMITTED_FLIGHT_ID: "OJ-01-0151",
  CHECK_SUBMITTED_CARD_HOLDER_NAME: "OJ-01-0156",
  CHILD_AGE_MUST_MATCH_INITIAL_SEARCH: "OJ-01-0157",
  CHECK_NULL_EMPTY: "OJ-01-0158",
  CHECK_NAME_INVALID_LENGTH: "OJ-01-0159",
  CHECK_NAME_PATTERN_MATCH: "OJ-01-0160",
  CHECK_NAME_DISALLOWED_MIDDLE_NAME: "OJ-01-0161",
  CHECK_RAW_WORDS: "OJ-01-0162",
  CHECK_NAME_INCLUDE_CHD: "OJ-01-0163",
  CHECK_NAME_INCLUDE_INF: "OJ-01-0164",
  INVALID_AGENT_JWT: "OJ-01-0165",
  AGENT_JWT_MALFORMED: "OJ-01-0166",
  AGENT_JWT_EXPIRED: "OJ-01-0167",
  INVALID_PAX_MIX_HOTEL_ROOM: "OJ-01-0169",
  DUPLICATE_CUSTOMER_SUBMITTED: "OJ-01-0170",
  DUPLICATE_DOCUMENT_ID_SUBMITTED: "OJ-01-0171",
  DUPLICATE_DOCUMENT_TYPE_SUBMITTED: "OJ-01-0172",
  PASSENGER_TYPE_NOT_VALID_ON_DATE_OF_TRAVEL: "OJ-01-0174",
  FLIGHT_PRODUCT_IDS_MISSING: "OJ-01-0175",
  FLIGHT_PRODUCT_IDS_LENGTH: "OJ-01-0176",
  UNSUPPORTED_PRODUCT_TYPE: "OJ-01-0177",
  CHECK_SUBMITTED_BOOKING_REFERENCE: "OJ-01-0178",
  CHECK_SUBMITTED_USER_TOKEN: "OJ-01-0179",
  INVALID_CABIN_CLASS: "OJ-01-0180",
  BOOKING_NOT_PENDING_PAYMENT: "OJ-01-0181",
  FAILED_TO_RETRIEVE_PNR_TO_VALIDATE_ANCILLARY_RELATED_BOOKING: "OJ-01-0183",
  FLIGHT_SEGMENT_IS_NULL: "OJ-01-0184",
  CURRENT_SEGMENT_DOES_NOT_SUPPORT_ANCILLARY_PRODUCT: "OJ-01-0185",
  CURRENT_PASSENGER_DOES_NOT_SUPPORT_ANCILLARY_PRODUCT: "OJ-01-0186",
  PASSENGER_ALREADY_HAS_BOOKED_THE_ANCILLARY_PRODUCT_THROUGH_EXTERNAL:
    "OJ-01-0187",
  CHECK_LIVE_PRODUCT_NOT_FOUND: "OJ-01-0182",
  PROMOTION_CODE_NOT_ALLOWED: "OJ-01-0189",
  CHECK_SUBMITTED_REDRESS_INFO: "OJ-01-0190",
  CHECK_SUBMITTED_RESIDENT_ADDRESS: "OJ-01-0220",
  CHECK_SUBMITTED_DESTINATION_ADDRESS: "OJ-01-0221",
  INVALID_DESTINATION_REDRESS_ADDRESS_COUNTRY_CODE: "OJ-01-0222",
  CHECK_SUBMITTED_FIELD_LENGTH: "OJ-01-0223",
  CHECK_SUBMITTED_RESIDENT_DESTINATION_ADDRESSES: "OJ-01-0224",
  INVALID_GENDER_SUBMITTED: "OJ-01-0226",
  MISSING_PASSENGER_COUNT: "OJ-01-0227",
  PACK_HOTEL_NUM_ADULT: "OJ-01-0228",
  PACK_HOTEL_NUM_CHILD: "OJ-01-0229",
  PACK_HOTEL_NUM_INFANTS: "OJ-01-0230",
  CALENDAR_MULTI_CITY_NOT_SUPPORTED: "OJ-01-0231",
  CHECK_SUBMITTED_FREQUENT_FLYER_CIPHER_CODE: "OJ-01-0101",
  CHECK_SUBMITTED_EXTERNAL_PAYMENT_REFERENCE: "OJ-01-0232",
  CHECK_SUBMITTED_BANK_CODE_NOT_EXISTS: "OJ-01-0233",
  CHECK_SUBMITTED_SIGNATURE_NOT_EXISTS: "OJ-01-0234",
  CHECK_SUBMITTED_CURRENCY_CODE_NOT_EXISTS: "OJ-01-0235",
  CHECK_SUBMITTED_AMOUNT_NOT_EXISTS: "OJ-01-0236",
  CHECK_SUBMITTED_PAYMENT_REFERENCE_NOT_EXISTS: "OJ-01-0237",
  TWO_STEP_EXTERNAL_PAYMENT_SIGNATURE_INVALID: "OJ-01-0238",
  TWO_STEP_EXTERNAL_PAYMENT_REFERENCE_INVALID: "OJ-01-0239",
  TWO_STEP_EXTERNAL_PAYMENT_REF_PARAM_NOT_SAME: "OJ-01-0240",
  TWO_STEP_EXTERNAL_PAYMENT_AMOUNT_INVALID: "OJ-01-0241",
  TWO_STEP_EXTERNAL_PAYMENT_NOT_ENABLED: "OJ-01-0242",
  TWO_STEP_EXTERNAL_PAYMENT_PRODUCT_CANCELED: "OJ-01-0243",
  CHECK_SUBMITTED_SORTBY_INVALID: "OJ-01-0244",
  REDEEM_POINTS_GREATER_THAN_LOYALTY_ACCOUNT_POINTS: "OJ-01-0245",
  CHECK_SUBMITTED_BOOKING_REDEEM_POINTS: "OJ-01-0246",
  CHECK_SURNAME_INVALID_LENGTH: "OJ-01-0247",
  CHECK_FIRST_NAME_INVALID_LENGTH: "OJ-01-0248",
  DEPART_DATE_RANGE_NOT_EXIST: "OJ-01-0249",
  UNSUPPORTED_PRODUCT_TYPE_FOR_VOUCHER: "OJ-01-0250",
  JWT_BLACKLISTED: "OJ-01-0251",
  AGENT_JWT_BLACKLISTED: "OJ-01-0252",
  INVALID_PAX_MAX_HOTEL_GUESTS_ROOM: "OJ-01-0253",
  INVALID_PAX_MAX_HOTEL_GUESTS: "OJ-01-0254",
  INVALID_HOTEL_GUESTS_PER_ROOM: "OJ-01-0255",
  INVALID_HOTEL_TOTAL_GUESTS: "OJ-01-0256",

  // Hotel Error Codes
  MISSING_USER_PASSWORD: "OJ-01-0063",
  CHECK_SUBMITTED_DELIVERY_OPTION: "OJ-01-0066",
  CHECK_SUBMITTED_CITY: "OJ-01-0068",
  CHECK_SUBMITTED_ZIP: "OJ-01-0069",
  CHECK_SUBMITTED_PROVINCE: "OJ-01-0070",
  CHECK_SUBMITTED_GENDER: "OJ-01-0071",
  CHECK_SUBMITTED_ADDRESS: "OJ-01-0072",
  CHECK_SUBMITTED_BANK_ID: "OJ-01-0073",
  PAYMENT_OPTION_RESPONSE_NULL: "OJ-01-0075",
  SPRING_VALIDATION_MIN: "OJ-01-0076",
  SPRING_VALIDATION_MAX: "OJ-01-0077",
  SPRING_VALIDATION_SIZE: "OJ-01-0078",
  SPRING_VALIDATION_NOT_NULL: "OJ-01-0079",
  CHECK_SUBMITTED_PAY_AMOUNT: "OJ-01-0080",
  SPRING_VALIDATION_PATTERN: "OJ-01-0081",
  CHECK_SUBMITTED_BILL_NO: "OJ-01-0082",
  CHECK_SUBMITTED_ORDER_NO: "OJ-01-0083",
  CHECK_SUBMITTED_PAYMENT_DATE: "OJ-01-0084",
  CHECK_SUBMITTED_PAYMENT_TIME: "OJ-01-0085",
  CHECK_SUBMITTED_ORDER_CUR_TYPE: "OJ-01-0086",
  CHECK_SUBMITTED_ORDER_TYPE: "OJ-01-0087",
  CHECK_SUBMITTED_LAN: "OJ-01-0088",
  CHECK_SUBMITTED_MESSAGE: "OJ-01-0092",
  CHECK_SUBMITTED_SIGNATURE: "OJ-01-0094",
  CHECK_SUBMITTED_ORG_ID: "OJ-01-0095",
  CHECK_SUBMITTED_PASSWORD: "OJ-01-0098",
  CHECK_SUBMITTED_USER_ID: "OJ-01-0100",
  PLEASE_SPECIFY_AGE: "OJ-01-0102",
  CHECK_SUBMITTED_PRODUCTS_LIST: "OJ-01-0104",
  CHECK_SUBMITTED_CUSTOMERS: "OJ-01-0105",
  CHECK_SUBMITTED_SEARCH_TYPE: "OJ-01-0106",

  // FlightPassenger Error Codes
  CHECK_USER_ID: "OJ-01-0191",
  CHECK_SUBMITTED_FLIGHT_PASSENGER_ID: "OJ-01-0192",
  CHECK_FREQUENT_FLYER_CARD: "OJ-01-0194",
  CHECK_TRAVEL_DOCUMENT_ALREADY_EXIST: "OJ-01-0195",
  CHECK_PAX_PROFILE_TYPE: "OJ-01-0196",
  CHECK_TRAVEL_DOCUMENT_PASSPORT_ONLY: "OJ-01-0199",
  CHECK_TRAVEL_DOCUMENT_DOC_ID: "OJ-01-0197",
  CHECK_FLIGHT_ADDED_REPEATEDLY: "OJ-01-0974",

  // Car Error Codes
  DROPOFF_DATE_CANNOT_BE_BEFORE_PICKUP_DATE: "OJ-01-0200",
  CHECK_SUBMITTED_REFERENCE: "OJ-01-0203",
  CHECK_SUBMITTED_CAR_EXTRA_CODE: "OJ-01-0204",
  CHECK_RADIUS_SEARCH_LONGITUDE: "OJ-01-0206",
  CHECK_RADIUS_SEARCH_LATITUDE: "OJ-01-0207",
  CHECK_SUBMITTED_DRIVER_AGE: "OJ-01-0208",
  CAR_CHECK_MINIMUM_DRIVER_AGE: "OJ-01-0210",
  PICKUP_DATE_CANNOT_BE_IN_THE_PAST: "OJ-01-0211",
  DROPOFF_DATE_CANNOT_BE_IN_THE_PAST: "OJ-01-0212",
  TIME_CANNOT_BE_IN_THE_PAST: "OJ-01-0213",
  DROPOFF_TIME_CANNOT_BE_BEFORE_OR_EQUAL_PICKUP_TIME: "OJ-01-0214",
  DRIVER_AGE_CANNOT_CHANGE_FROM_INITIAL_SEARCH: "OJ-01-0215",
  CHECK_SUBMITTED_PICKUP_LOCATION: "OJ-01-0216",
  CHECK_SUBMITTED_DROPOFF_LOCATION: "OJ-01-0217",
  DUPLICATE_SPECIAL_EQUIPMENT: "OJ-01-0218",

  // Multi-factor authentication
  MFA_CAPTCHA_FAIL_CREATE_BASE64_IMAGE_CONTENT: "OJ-01-0230",

  // Chinese Edit Profile Error Codes
  TRAVELER_ID_CAN_NOT_BE_NULL: "OJ-01-0302",
  FLIGHT_SEGMENT_STATUS_IS_INCORRECT: "OJ-01-0303",
  TICKETING_STATUS_IS_INCORRECT: "OJ-01-0304",
  SEAT_REQUEST_FOR_TRAVELER_ITINERARY_RESPONSE_CAN_NOT_BE_NULL: "OJ-01-0305",
  BAGGAGE_REQUEST_FOR_TRAVELER_ITINERARY_RESPONSE_CAN_NOT_BE_NULL: "OJ-01-0306",
  CHECK_SUBMITED_PRCID: "OJ-01-0307",
  ERROR_TO_DISABLE_USER: "OJ-01-0308",
  GET_LOGIN_ERROR_COUNT_ERROR: "OJ-01-0309",
  SAVE_LOGIN_ERROR_COUNT_ERROR: "OJ-01-0310",
  ERROR_TO_SEARCH_BOOKING_COUNT: "OJ-01-0311",
  CHECK_CANCELLED_BOOKING_SEND_ERROR: "OJ-01-0312",
  DISABLE_USER_ERROR: "OJ-01-0313",
  FLIGHT_SEGMENT_IS_INCORRECT: "OJ-01-0315",
  ANCILLARY_REQUEST_FOR_TRAVELER_ITINERARY_RESPONSE_CAN_NOT_BE_NULL:
    "OJ-01-0316",
  ERROR_RESET_PASSWORD: "OJ-01-0320",
  EMAIL_NOT_AUTHENTICATED: "OJ-01-0321",
  MOBILE_NOT_AUTHENTICATED: "OJ-01-0322",
  INVALID_INPUT_AUTHCODE: "OJ-01-0331",
  COUPON_PASSWORD_IS_INCORRECT: "OJ-01-0332",
  AUTHENTICATE_COUPON_ERROR: "OJ-01-0333",
  CHECK_COUPON_STATUS: "OJ-01-0340",
  CHECK_COUPON_SCOPE: "OJ-01-0341",
  CHECK_COUPON_TYPE: "OJ-01-0342",
  CHECK_REDEEM_DATE_BEFORE: "OJ-01-0343",
  CHECK_EXPIRY_DATE_BEFORE: "OJ-01-0344",
  CHECK_ACTIVATE_DATE_BEFORE: "OJ-01-0345",
  CHECK_SUBMIT_COUPON_NUMBER: "OJ-01-0346",
  CHECK_SUBMIT_COUPON_ACTIVATE_DATE: "OJ-01-0347",
  CHECK_SUBMIT_COUPON_EXPIRY_DATE: "OJ-01-0348",
  CHECK_SUBMIT_COUPON_REDEEM_DATE: "OJ-01-0349",
  EMAIL_AUTHCODE_SEND_ERROR: "OJ-01-0350",
  EMAIL_AUTHENTICATE_CONFIRM_ERROR: "OJ-01-0351",
  EMAIL_INVALID_AUTHCODE: "OJ-01-0352",
  MOBILE_PHONE_UPDATE_AND_AUTHENTICATION_ERROR: "OJ-01-0353",
  CHECK_SECURITY_QUESTION: "OJ-01-0354",
  GET_AUTH_STATUS_ERROR: "OJ-01-0355",
  PROFILE_RETRIEVE_ERROR: "OJ-01-0356",
  ORIGINAL_MOBILE_PHONE_INCORRECT: "OJ-01-0357",
  NEW_MOBILE_PHONE_SAME_AS_ORIGINAL_MOBILE_PHONE: "OJ-01-0358",
  EMAIL_UPDATE_ERROR: "OJ-01-0359",
  ERROR_SAVING_EMAIL_AUTH_CODE: "OJ-01-0360",
  CAPTCHA_CODES_ERROR: "OJ-01-0361",
  NEW_EMAIL_NOT_CHANGED: "OJ-01-0362",
  CHECK_SUBMIT_NEW_OLD_PASSWORD: "OJ-01-0364",
  ERROR_GET_CACHED_AUTH_CODE: "OJ-01-0365",
  EMAIL_ALREADY_AUTHENTICATED: "OJ-01-0366",
  USERNAME_ALREADY_REGISTER: "OJ-01-0367",
  ERROR_INPUT_DATE: "OJ-01-0368",
  ERROR_DATE_RANGE: "OJ-01-0379",
  ORIGIN_AND_DESTINATION_NOT_MATCH: "OJ-01-0370",
  CODE_AND_CONTEXT_NOT_MATCH: "OJ-01-0371",
  LOCATION_EMPTY: "OJ-01-0372",
  EMAIL_SEND_ERROR: "OJ-01-0373",
  SMS_SEND_ERROR: "OJ-01-0374",
  SMS_VERIFICATION_ERROR: "OJ-01-0375",
  SEND_SMS_FAILED: "OJ-01-0376",
  INVALID_SMS_ERROR: "OJ-01-0377",
  CHECK_SUBMITTED_PHONE_SIZE_AND_TYPE: "OJ-01-0378",
  MOBILE_ALREADY_AUTHENTICATED: "OJ-01-0380",
  UPDATE_PROFILE_ERROR: "OJ-01-0381",
  CHECK_SUBMITTED_PREFERRED_LANGUAGE: "OJ-01-0382",
  PHONE_TYPE_NOT_MOBILE: "OJ-01-0383",
  IDENTITY_CARD_ERROR_MESSAGE: "OJ-01-0384",
  ADD_BENEFICIARY_ERROR: "OJ-01-0385",
  ANSWER_PATTERN_IS_INCORRECT: "OJ-01-0386",
  DELETE_BENEFICIARY_ERROR: "OJ-01-0387",
  BENEFICIARY_ID_INCORRECT: "OJ-01-0388",
  ERROR_GETTING_BENEFICIARIES: "OJ-01-0389",
  INVALID_START_DATE: "OJ-01-0390",
  INVALID_END_DATE: "OJ-01-0391",
  INVALID_START_END_DATE: "OJ-01-0392",
  INVALID_DATE_DURATION: "OJ-01-0393",
  INVALID_PAGINATION_PARAMETERS: "OJ-01-0394",
  INVALID_TRANSACTION_TYPE: "OJ-01-0395",
  TRANSACTION_SEARCH_FAILURE: "OJ-01-0396",
  INVALID_LOGIN_USER_NAME: "OJ-01-0397",
  ADD_IP_TO_BLACK_LIST_ERROR: "OJ-01-0398",
  NON_BLANK_PASSWORD: "OJ-01-0399",
  MFA_REQUEST_HEADER_META_FORMAT_ERROR: "OJ-01-0401",

  // Hotel Error Codes
  HOTEL_CHECK_MAX_ROOMS: "OJ-01-0400",
  HOTEL_CHECK_PROPERTY_NOT_AVAILABLE: "OJ-01-0404",
  HOTEL_CHECK_MINIMUM_CHILD_AGE: "OJ-01-0405",
  CHECK_SUBMITTED_HOTEL_EXTRA_CODE: "OJ-01-0408",
  HOTEL_CHECK_CHECKOUT_TIME: "OJ-01-0415",
  HOTEL_CHECK_MAXIMUM_CHILD_AGE: "OJ-01-0416",
  CHECKIN_AND_CHECKOUT_DATES_ARE_SAME: "OJ-01-0418",
  CHECK_SUBMITTED_HOTEL_SPECIAL_REQUEST_BED_TYPE: "OJ-01-0419",
  CHECK_SUBMITTED_HOTEL_SPECIAL_REQUEST_SMOKING_PREFERENCE: "OJ-01-0420",
  RADIUS_SEARCH_LONGITUDE_AND_LATITUDE_CANNOT_BE_ZERO: "OJ-01-0421",

  // Event Error Codes
  CHECK_SUBMITTED_TICKETS: "OJ-01-0500",
  CHECK_SUBMITTED_TICKET_SEARCH_DATE: "OJ-01-0501",
  CHECK_SUBMITTED_TICKET_TYPES: "OJ-01-0502",
  CHECK_NUMBER_SUBMITTED_TICKET: "OJ-01-0503",
  CHECK_SUBMITTED_EVENT_REFERENCE: "OJ-01-0504",
  CHECK_CUSTOMER_TICKET_TYPE: "OJ-01-0505",
  CHECK_CUSTOMER_TICKET_TYPE_WITH_EVENT_PRODUCT: "OJ-01-0506",
  CHECK_LEAD_TRAVELER_EVENT_PRODUCT: "OJ-04-0507",
  CHECK_HOTEL_PICKUP_AVAILABLE: "OJ-04-0508",
  CHECK_BOOKING_QUESTIONS_EVENT_PRODUCT: "OJ-04-0509",
  EVENT_PRICE_CHANGE: "OJ-04-0510",
  EVENT_REPRICE_RESPONSE: "OJ-04-0511",

  // Flight Error Codes
  SEAT_MAP_CUSTOMER_ID_MISSING: "OJ-01-0601",
  SEAT_MAP_ID_MISSING: "OJ-01-0602",
  SEAT_MAP_ROW_NUM_MISSING: "OJ-01-0603",
  SEAT_MAP_SEAT_NUM_MISSING: "OJ-01-0604",
  SEAT_MAP_NOT_AVAILABLE: "OJ-01-0605",
  CHARGEABLE_SEAT_TAKEN: "OJ-01-0606",
  CUSTOMER_ALREADY_HAS_CHARGEABLE_SEAT: "OJ-01-0607",
  FREE_SEAT_TAKEN: "OJ-01-0608",
  CUSTOMER_ALREADY_HAS_FREE_SEAT: "OJ-01-0609",
  SEAT_MAP_FLIGHT_ALREADY_MODIFIED: "OJ-01-0610",
  SEAT_MAP_PRODUCT_ALREADY_ADDED: "OJ-01-0611",
  DUPLICATE_REQUEST_SEAT_NUMBER: "OJ-01-0612",
  DUPLICATE_REQUEST_SEAT_CUSTOMER: "OJ-01-0613",
  UNAVAILABLE_SEAT_ANCILLARY: "OJ-01-0614",
  ERROR_RETIEVING_SEAT_MAP: "OJ-01-0615",
  SEAT_MAP_ASSIGNMENT_UNAVAILABLE: "OJ-01-0616",
  CHECK_SUBMITTED_DEPART_DATE: "OJ-01-0617",
  CHECK_SUBMITTED_RETURN_DATE: "OJ-01-0618",
  CHECK_SUBMITTED_DEPARTURE_LOCATION: "OJ-01-0619",
  CUSTOMER_ALREADY_HAS_SEAT: "OJ-01-0620",
  CHECK_SUBMITTED_TICKETING_REQUEST: "OJ-01-0622",
  FLIGHT_REQUEST_TICKETING_FAILED: "OJ-01-0623",
  MAX_BOUNDS_LIMIT_EXCEEDED: "OJ-01-0624",
  INVALID_PASSENGER_TYPE_SUBMITTED: "OJ-01-0625",
  DUPLICATE_PASSENGER_TYPE_SUBMITTED: "OJ-01-0626",
  INVALID_NUMBER_OF_INF_PASSENGERS: "OJ-01-0627",
  MINIMUM_PASSENGER_REQUIREMENT_FAILED: "OJ-01-0628",
  MAXIMUM_PASSENGER_REQUIREMENT_FAILED: "OJ-01-0629",
  INVALID_ADDITONAL_DOCUMENT_TYPE: "OJ-01-0633",
  ADDITIONAL_DOCUMENT_PATTERN_DOES_NOT_MATCH: "OJ-01-0634",
  ADDITIONAL_DOCUMENT_REQUIRED: "OJ-01-0635",
  ADDITIONAL_DOCUMENT_NOT_ALLOWED: "OJ-01-0636",
  FLEXIBLE_ROWS_ARE_NOT_ALLOWED: "OJ-01-0637",
  INVALID_SEAT_OF_PRODUCT: "OJ-01-0638",
  FAILED_TO_CANCEL_FREE_SEATS: "OJ-01-0639",
  NO_PRICE_FOR_CHARGEABLE_SEAT: "OJ-01-0640",
  INVALID_AIR_TICKETING_STATUS: "OJ-01-0641",
  ANCILLARY_PRODUCTS_HAVE_TICKETING_SUCCESSFULLY: "OJ-01-0642",
  ANCILLARY_PRODUCTS_REQUEST_TICKETING_FAILED: "OJ-01-0643",
  AIR_PRODUCT_SHOULD_TICKETING_FIRST: "OJ-01-0644",
  SELECTED_OPTION_ID_INVALID: "OJ-01-0645",
  SELECTED_OPTION_ID_DUPLICATE_BOUND: "OJ-01-0646",
  SELECTED_OPTION_ID_NOT_FOUND: "OJ-01-0647",
  SELECTED_OPTION_IDS_EXCEED_MAX: "OJ-01-0648",
  SELECTED_OPTION_IDS_NOT_COMBINABLE: "OJ-01-0649",
  BOUND_IDS_NOT_EXIST: "OJ-01-0650",
  BOUND_IDS_DUPLICATE: "OJ-01-0651",
  MAX_CONNECTIONS_LIMIT_EXCEEDED: "OJ-01-0652",
  SUBMITTED_FLIGHT_RESULT_ID_NOT_PERMITTED: "OJ-01-0653",
  ERROR_SAVING_SEAT_MAP_RESULT: "OJ-01-0654",
  ERROR_GETTING_SEAT_MAP_RESULT: "OJ-01-0655",
  SEAT_ASSIGNMENTS_NOT_FOUND: "OJ-01-0951",
  SEAT_ASSIGNMENT_ID_NOT_FOUND: "OJ-01-0952",
  EXISTING_PAYMENT_DUE_RELATED_PRODUCTS: "OJ-01-0953",
  SUBMIT_SELECTED_OPTION_IDS_ALL_BOUNDS: "OJ-01-0954",
  SUBMIT_BOUND_FLIGHT_OPTIONS_ID_ONLY: "OJ-01-0955",
  MIN_TIME_RANGE_DUPLICATE_BOUND: "OJ-01-0956",
  MAX_TIME_RANGE_FORMAT_INCORRECT: "OJ-01-0957",
  MAX_TIME_RANGE_DUPLICATE_BOUND: "OJ-01-0958",
  SUBMITTED_BOUND_INCORRECT: "OJ-01-0959",
  MIN_TIME_RANGE_FORMAT_INCORRECT: "OJ-01-0960",
  INVALID_SEAT_BOOKING_CHANNEL: "OJ-01-0961",
  FAILED_TO_CANCEL_SPECIAL_SERVICE_REQUESTS: "OJ-01-0962",
  SPECIAL_SERVICE_REQUESTS_INVALID_TICKET_STATUS: "OJ-01-0963",
  SPECIAL_SERVICE_REQUESTS_NOT_SAME_TYPE: "OJ-01-0964",
  SPECIAL_SERVICE_REQUESTS_NOT_SAME_FLIGHT: "OJ-01-0965",
  SPECIAL_SERVICE_REQUESTS_NOT_SAME_SEGMENT: "OJ-01-0966",
  DURATION_FORMAT_INCORRECT: "OJ-01-0967",
  DURATION_DUPLICATE_BOUND: "OJ-01-0968",
  CONNECTIONS_PER_BOUND_FORMAT_INCORRECT: "OJ-01-0969",
  CONNECTIONS_PER_BOUND_DUPLICATE_BOUND: "OJ-01-0970",
  AIRLINES_FORMAT_INCORRECT: "OJ-01-0971",
  AIRLINES_DUPLICATE_CODES: "OJ-01-0972",
  ERROR_BOOKING_DUE_TO_PENDING_PAYMENT: "OJ-01-0973",
  BRITISH_FLIGHT_ONLY_CHILD_OR_INFANT_NON_ADULTS: "OJ-01-0975",
  BRITISH_FLIGHT_INVALID_YOUTH_SEARCH: "OJ-01-0976",
  BRITISH_FLIGHT_NO_YOUTH_AND_ADULT: "OJ-01-0977",
  BRITISH_FLIGHT_INVALID_CABIN_FOR_YOUTH: "OJ-01-0978",
  CHECK_INFANT_PAY_LUGGAGE_CODE: "OJ-01-0979",
  CHECK_INFANT_FREE_MEALS_CODE: "OJ-01-0980",
  CHECK_INFANT_SEAT_ASSIGNMENTS: "OJ-01-0981",
  CHECK_INFANT_ANCILLARY_PRODUCTS: "OJ-01-0982",
  CHECK_ADULT_CARRYING_INFANT: "OJ-01-0983",
  HAS_PENDING_PAYMENT_PRODUCT: "OJ-01-0984",
  HAS_NO_COST_FREE_SEAT: "OJ-01-0985",
  FAILED_TO_SUBMIT_COST_FREE_SEAT_MODIFICATIONS: "OJ-01-0986",
  INVALID_PAYMENT_COST_FREE_SEAT: "OJ-01-0987",

  // Package Error Codes
  CHECK_PACK_SUBMITTED_SEARCH_TYPE: "OJ-01-0800",
  PACK_HOTEL_MIN_ADULT: "OJ-01-0801",
  PACK_HOTEL_MAX_ADULT: "OJ-01-0802",
  PACK_HOTEL_MAX_CHILD: "OJ-01-0803",
  PACK_HOTEL_MAX_GUESTS: "OJ-01-0805",
  PACK_PACKAGE_ID_DOES_NOT_EXIST: "OJ-01-0806",
  PACKAGE_ROOM_OPTION_NOT_FOUND: "OJ-01-0807",
  PACK_PACKAGE_INVALID_FLIGHT_BOUND_IDX: "OJ-01-0808",
  CHECK_SUBMITTED_PACKAGE_ID: "OJ-01-0810",
  PACKAGE_ALREADY_EXISTS: "OJ-01-0811",
  PACK_PACKAGE_INVALID_FLIGHT_OPTION_ID: "OJ-01-0812",
  CHECK_SUBMITTED_PACKAGE_FOR_STATUS: "OJ-01-0813",
  HOTEL_CHECKIN_DATE_NOT_WITHIN_PACK_DATES: "OJ-01-0814",
  HOTEL_CHECKOUT_DATE_NOT_WITHIN_PACK_DATES: "OJ-01-0815",
  PACKAGE_DOES_NOT_EXIST_IN_BOOKING: "OJ-01-0816",
  PACKAGE_HOTEL_CHECK_HOTEL_NAME: "OJ-01-0817",
  PACKAGE_HOTEL_CHECK_STAR_RATING: "OJ-01-0818",
  PACKAGE_MIN_DATE_RANGE: "OJ-01-0819",
  PACKAGE_MAX_DATE_RANGE: "OJ-01-0820",
  PACKAGE_MIN_LEAD_TIME: "OJ-01-0821",
  PACKAGE_MAX_LEAD_TIME: "OJ-01-0822",
  LOCATION_WITH_COORDINATES_NOT_ALLOWED: "OJ-01-0823",
  MISSING_DESTINATION_LOCATION_TYPE: "OJ-01-0824",
  CHECK_PACKAGE_HOTEL_DESTINATION_FILTER: "OJ-01-0825",
  TYPE_OF_PASSENGER_NOT_SUPPORTED: "OJ-01-0826",
  INTERVAL_BETWEEN_FLIGHTS: "OJ-01-0827",
  NOT_SUPPORT_SIMULTANEOUS_SELECTION: "OJ-01-0828",
  TYPE_OF_AIR_TRIP_NOT_SUPPORTED: "OJ-01-0829",
  CANCELLATION_COST_RETRIEVE_ERROR: "OJ-01-0830",
  PACKAGE_CAR_OPTION_NOT_FOUND: "OJ-01-0831",
  PACKAGE_PRODUCT_STATUS_INCORRECT: "OJ-01-0832",
  CANNOT_FILTER_OUTBOUND_OPTIONS_BY_ID: "OJ-01-0833",
  AIRLINE_DOES_NOT_EXIST: "OJ-01-0834",
  PACK_PACKAGE_INVALID_FLIGHT_OPTION_ID_FOR_AIRLINE: "OJ-01-0835",
  PACKAGE_FLIGHT_ARRIVAL_TIME_IS_OUTSIDE_OF_DEPOT_OPERATING_TIMES: "OJ-01-0836",
  PACKAGE_ALT_FLIGHT_AIRLINE_ARRIVAL_TIME_IS_OUTSIDE_OF_DEPOT_OPERATING_TIMES:
    "OJ-01-0837",

  // Flight refund error codes
  CHECK_FLIGHTS_CAN_REFUND: "OJ-01-0900",
  CHECK_TICKET_TIME_IS_OVER_ONE_YEAR: "OJ-01-0901",
  CHECK_TICKET_CHECKED_IN_BOARDED: "OJ-01-0902",
  CHECK_CHILD_CAN_NOT_TRAVEL_ALONE: "OJ-01-0903",
  CHECK_SAME_FLIGHT_SEGMENT_SELECTED: "OJ-01-0904",
  CHECK_SUBMITTED_CUSTOMER_TO_REFUND: "OJ-01-0905",
  CHECK_SUBMITTED_FLIGHT_SEGMENT_TO_REFUND: "OJ-01-0906",
  CHECK_TICKETS_NOT_USED_IN_ORDER: "OJ-01-0907",
  CHECK_TICKETS_NOT_USED_IN_ORDER_SHOW_SEGMENTS_ALLOWED: "OJ-01-0908",
  CHECK_NOT_ALL_UNUSED_SEGMENTS_SELECTED: "OJ-01-0909",
  CHECK_REFUND_REMARKS_NOT_FOUND: "OJ-01-0910",
  CHECK_TICKET_INFO_SYNC_FAILED: "OJ-01-0911",
  CHECK_HISTORY_ORDER: "OJ-01-0912",
  CHECK_ALL_CHANNEL_EXTERNAL: "OJ-01-0913",
  CHECK_TICKETING_STATUS: "OJ-01-0914",
  CHECK_SUBMITTED_CUSTOMER_ID_DUPLICATE: "OJ-01-0915",
  CHECK_SUBMITTED_FLIGHT_SEGMENT_TO_DUPLICATE: "OJ-01-0916",
  CHECK_QUEUE_PROCESS: "OJ-01-0917",
  CHECK_SUBMITTED_REFUND_REMARKS_ID: "OJ-01-0918",
  ERROR_COMMIT_REFUND_APPLICATION: "OJ-01-0919",
  UNSUPPORTED_FLIGHT_REFUND_BY_SEGMENTS: "OJ-01-0920",
  UNSUPPORTED_VOLUNTARY_REFUND_ADDITIONAL_SERVICES: "OJ-01-0921",
  CHECK_TICKETING_FOR_RESHOP_AIR_PRODUCT: "OJ-01-0922",
  ERROR_NO_DOMESTIC_FLIGHT_SEGMENTS_CANCEL_COST: "OJ-01-0923",
  ERROR_COMMIT_SUPERPNR_SPLIT: "OJ-01-0924",
  CHECK_TICKET_STATUS: "OJ-01-0925",
  CHECK_ACE_REFUND_INSURANCE_WITH_AIR_TICKET: "OJ-01-0926",
  INSURANCE_IS_NOT_BOOKED: "OJ-01-0928",
  INSURANCE_IS_BEING_SYNCHRONIZED: "OJ-01-0929",
  INSURANCE_NOT_BOOKED: "OJ-01-0930",
  INSURANCE_NOT_CONFORM_REFUND_RULE: "OJ-01-0931",
  INSURANCE_BEYOND_TIME_TO_REFUND: "OJ-01-0932",
  INSURANCE_EXISTS_UNPAID_PAYMENT: "OJ-01-0933",
  INSURANCE_FLIGHT_HAS_FLOWN: "OJ-01-0934",
  CHECK_REFUND_REASON_TYPE: "OJ-01-0935",
  CANCEL_BY_SEGMENTS_NOT_ALLOWED: "OJ-01-0936",
  CHECK_INFANT_CAN_NOT_TRAVEL_ALONE: "OJ-01-0937",
  FLIGHT_SEGMENT_IDS_MISSING: "OJ-01-0938",
  ALL_SEGMENTS_IN_BOUND_NOT_SELECTED: "OJ-01-0939",
  ERROR_BOOKING_IS_ARCHIVED: "OJ-01-0940",
  ERROR_MAX_PRODUCTS_ALLOWED: "OJ-01-0941",
  ERROR_MAX_TOTAL_PRODUCTS_ALLOWED: "OJ-01-0942",
  ERROR_MAX_UNIQUE_HOTEL_MODULAR_PRODUCTS_ALLOWED: "OJ-01-0943",
  ERROR_BOOKING_REQUEST_RESTRICTED: "OJ-01-0944",
  ERROR_BOOKING_REQUEST_RESTRICTED_NO_TRANSACT: "OJ-01-0945",
  ERROR_BOOKING_REQUEST_RESTRICTED_TRANSACT_MADE: "OJ-01-0946",
  /**
   * system errors e.g. infrastructure, environment, bugs OJ-03-*
   */
  UNKNOWN_ERROR_CODE: "OJ-03-0001",
  CACHE_BEAN_NULL: "OJ-03-0005",
  ERROR_CONTROLLER_UNKNOWN_ERROR: "OJ-03-0007",
  INVALID_PATH: "OJ-03-0008",
  METHOD_NOT_ALLOWED: "OJ-03-0009",
  EASYPAY_CERT_NOT_FOUND: "OJ-03-0010",
  UNSUPPORTED_MEDIA_TYPE: "OJ-03-0011",
  CUSTOMER_RPH_EXISTS: "OJ-03-0013",
  FAILED_TO_SAVE_CACHE: "OJ-03-0014",
  FAILED_TO_GET_CACHE: "OJ-03-0015",
  ERROR_CACHE_ACCESS: "OJ-03-0016",
  UNKNOWN_HTTP_ERROR_CODE: "OJ-03-0017",

  /**
   * application errors e.g. no availability, booking, reprice OJ-04-*
   */
  ERROR_OCCURRED: "OJ-04-0001",
  NO_RESULTS_CODE: "OJ-04-0002",
  ERROR_BOOKING_RESERVATION: "OJ-04-0003",
  UNSUPPORTED_LOCALE: "OJ-04-0004",
  UNSUPPORTED_CURRENCY: "OJ-04-0005",
  CONTACT_DETAILS_EXIST: "OJ-04-0006",
  AIR_PRICE_ERROR: "OJ-04-0007",
  ERROR_CODE: "OJ-04-0008",
  AIR_PRICE_CHANGE: "OJ-04-0009",
  CONTACT_DETAILS_MISSING: "OJ-04-0010",
  INVALID_PASSENGERS_FOR_PRODUCT: "OJ-04-0011",
  NO_SUPERPNR_PRODUCT: "OJ-04-0012",
  INVALID_SUPERPNR_STATE_FOR_PAYMENT: "OJ-04-0013",
  NO_PAYMENT_DUE: "OJ-04-0014",
  OUT_OF_TICKET_TIME_LIMIT: "OJ-04-0015",
  UNSUCCESSFUL_PAYMENT: "OJ-04-0016",
  AUTHORISE_PAYMENT: "OJ-04-0017",
  NO_SUPERPNR_ID: "OJ-04-0018",
  CONFIRM_PAYMENT: "OJ-04-0019",
  CONFIRM_PAYMENT_INVALID_SIGNATURE: "OJ-04-0020",
  CONFIRM_PAYMENT_INVALID_SPNR_ID: "OJ-04-0022",
  CONFIRM_PAYMENT_UNPREDICTABLE_PAYMENT_STATUS_PAIR: "OJ-04-0023",
  CONFIRM_PAYMENT_UPDATE_PAYMENT_STATUS_FAILED: "OJ-04-0024",
  CONFIRM_PAYMENT_INPUT_DECODE: "OJ-04-0025",
  CUSTOMER_DETAILS_MISSING: "OJ-04-0026",
  UNKNOWN_PRODUCT: "OJ-04-0027",
  INVALID_CURRENCY: "OJ-04-0028",
  NO_PENDING_ITEMS: "OJ-04-0030",
  GET_PAYMENT_EMPTY: "OJ-04-0031",
  NO_PAYMENT_DATA_IN_SPNR: "OJ-04-0033",
  SEAT_MAP_NOT_FOUND: "OJ-04-0035",
  RESULTSET_ID_NOT_EXIST_OR_EXPIRED: "OJ-04-0036",
  SEATS_FLIGHT_PRODUCT_NOT_FOUND: "OJ-04-0037",
  ERROR_SAVING_PROFILE: "OJ-04-0038",
  ERROR_GETTING_PROFILE: "OJ-04-0039",
  ERROR_SAVING_SEARCH_CRITERIA: "OJ-04-0040",
  ERROR_GETTING_SEARCH_CRITERIA: "OJ-04-0041",
  PACKAGE_PRICE_FAIL: "OJ-04-0042",
  PACKAGE_HOTEL_PRICE_FAIL: "OJ-04-0043",
  PACKAGE_DELETE_ERROR: "OJ-04-0044",
  PRODUCT_DELETE_ERROR: "OJ-04-0045",
  ERROR_SAVING_LOYALTY_TRANSACTIONS: "OJ-04-0046",
  ERROR_GETTING_LOYALTY_TRANSACTIONS: "OJ-04-0047",
  ERROR_CANCELLATION_COST_NOT_FOUND: "OJ-04-0048",
  NO_ENABLED_AIR_ANCILLARY: "OJ-04-0049",
  ANCILLARY_SEARCH_FAILED: "OJ-04-0050",
  ANCILLARY_CHECK_FAILED: "OJ-04-0051",
  ERROR_SAVING_BAGGAGE_SEARCH: "OJ-04-0052",
  INVALID_BAGGAGE_PRICING_TYPE_IN_BOOKING: "OJ-04-0053",
  INVALID_BAGGAGE_DATA_IN_BOOKING: "OJ-04-0054",
  SEAT_ASSIGNMENT_NOT_ALLOW_WHEN_PENDING_PAYMENT: "OJ-04-0055",
  PAYMENT_CANCEL_ERROR: "OJ-04-0056",
  HOTEL_DETAILS_FAILED: "OJ-04-0057",
  INCORRECT_PAYMENT_BALANCE: "OJ-04-0058",
  SPECIAL_SERVICE_REQUEST_PRODUCT_NOT_FOUND: "OJ-04-0059",
  PAYMENT_TYPE_IS_NOT_SUPPORTED: "OJ-04-0060",
  FFP_BOOKING_INVALID_SIGNATURE: "OJ-04-0062",
  INVALID_PAYMENT_OPTION: "OJ-04-0076",
  HOTEL_PRICE_CHECK_ERROR: "OJ-04-0077",
  FLIGHT_REPRICE_CURRENCY_ERROR: "OJ-04-0086",
  CABIN_NOT_AVAILABLE: "OJ-04-0087",
  PAYMENT_CANCEL_REASON_TYPE_IS_NOT_OTHER: "OJ-04-0088",
  CONFIRM_PAYMENT_HOTEL_NO_INVENTORY: "OJ-04-0089",
  BAGGAGE_DELETE_ERROR: "OJ-04-0090",
  ERROR_SAVING_BLACKLIST_TOKEN: "OJ-04-0091",
  NOT_ENOUGH_AVAILABILITY_ERROR: "OJ-04-0092",

  // Coupon Error Codes
  SAVING_COUPON_ERROR: "OJ-04-0061",
  CHECK_COUPON_BOOKING_NOT_SUPPORT: "OJ-04-0067",
  CHECK_BOOKING_USED_COUPONS: "OJ-04-0068",
  INVALID_COUPON_NUMBER: "OJ-04-0070",
  CHECK_COUPON_OUT_DATE: "OJ-04-0071",
  ERROR_GET_COUPON_RESULT_CACHE: "OJ-04-0072",
  CHECK_USE_COUPON_STATUS_NOT_AVAILABLE: "OJ-04-0073",
  ERROR_USE_COUPON_STATUS_FAILED: "OJ-04-0074",
  ERROR_USE_COUPON_FAILED: "OJ-04-0075",

  // Bank offer error codes
  ERROR_GET_BANK_OFFER_RESULT: "OJ-04-0081",
  CHECK_BANK_OFFER_BOOKING_NOT_SUPPORT: "OJ-04-0082",
  CHECK_BANK_OFFER_BOOKING_NOT_SUPPORT_MORE: "OJ-04-0083",
  CHECK_BANK_OFFER_BOOKING_NOT_SUPPORT_USED: "OJ-04-0084",
  CHECK_BANK_OFFER_BOOKING_NOT_AVAILABLE: "OJ-04-0085",
  ERROR_USE_BANK_OFFER_FAILED: "OJ-04-0086",

  // Chinese profile flight passenger Error Codes
  ERROR_CREATING_FLIGHT_PASSENGER: "OJ-04-0100",
  ERROR_DELETING_FLIGHT_PASSENGER: "OJ-04-0101",
  LOCAL_DATE_CONVERT_ERROR: "OJ-04-0103",
  ERROR_GETTING_FLIGHT_PASSENGER: "OJ-04-0104",

  /**
   * Receipt delivery.
   */
  RECEIPT_DELIVERY_MAILING_ITINERARY_ADDRESS_ALREADY_EXISTS: "OJ-04-0207",
  RECEIPT_DELIVERY_EXTERNAL_FLIGHT_BOOKING_NOT_SUPPORTED: "OJ-04-0208",
  RECEIPT_DELIVERY_SAVE_ERROR: "OJ-04-0209",

  // Chinese profile Profile Error Codes
  ERROR_RETRIEVE_USER_INFO: "OJ-04-0308",
  ERROR_CREATE_NEW_USER: "OJ-04-0309",
  ERROR_UPDATING_PASSWORD: "OJ-04-0310",
  DUPLICATE_FFP_NUMBER: "OJ-04-0311",
  ERROR_AUTHENTICATE_FFP: "OJ-04-0312",
  ERROR_CHANGE_SECURITY_QUESTION: "OJ-04-0313",
  ERROR_GET_COUPON_LIST: "OJ-04-0314",
  ERROR_PAYMENT_STATE_CANCEL: "OJ-04-0315",

  // Chinese profile Booking Management Error Codes
  CHECK_NULL_END_DATE: "OJ-04-0316",
  END_DATE_BEFORE_START_DATE: "OJ-04-0317",
  BOOKING_DATE_RANGE: "OJ-04-0318",
  CHECK_SUBMITTED_TICKET_NUMBER: "OJ-04-0320",
  ERROR_MODIFY_DOC_ID: "OJ-04-0321",
  UNSUPPORTED_MODIFY_DOC_ID: "OJ-04-0322",
  ERROR_ANCILLARIES_NOT_ALLOWED_REFUND: "OJ-04-0350",
  ERROR_ANCILLARIES_NOT_SAME_TYPE: "OJ-04-0351",
  ANCILLARIES_REFUND_COMMIT_ERROR: "OJ-04-0352",
  MULTIPLE_PRODUCTS_CANCEL_UNSUPPORTED: "OJ-04-0353",
  ERROR_RETRIEVE_CANCELLATION_COST: "OJ-04-0354",
  INSURANCES_REFUND_COMMIT_ERROR: "OJ-04-0355",
  ERROR_INSURANCES_AND_ANCILLARIES_CANNOT_REFUND_TOGETHER: "OJ-04-0356",
  ERROR_CANCELLATION_COST_ID_IS_EMPTY: "OJ-04-0357",
  ERROR_REFUND_REMARKS_ID_IS_EMPTY: "OJ-04-0358",
  ERROR_PRODUCTS_CANCELLATION_REFERENCE_IS_EMPTY: "OJ-04-0359",
  ERROR_FOR_MORE_BOOKINGS: "OJ-04-0360",
  ERROR_SEARCH_FOR_UNREGISTERED_USER: "OJ-04-0361",
  ERROR_ANOTHER_AGENT_MODIFIED_BOOKING: "OJ-04-0362",

  // Reshop Error Codes
  ERROR_DOMESTIC_BOOKING: "OJ-04-0400",
  ERROR_INVALID_PRODUCT: "OJ-04-0401",
  ERROR_DATE_CHANGE_IN_PROGRESS: "OJ-04-0402",
  ERROR_ONLY_ONE_DATE_CHANGE_ALLOWED_PER_PAX: "OJ-04-0403",
  ERROR_MULTICITY: "OJ-04-0405",
  ERROR_INVALID_PAX_ID: "OJ-04-0406",
  ERROR_TICKET_STATUS: "OJ-04-0407",
  ERROR_ANCILLARY_RELATED_PRODUCT: "OJ-04-0410",
  ERROR_INVALID_FLIGHT_SEGMENT_UPGRADABLE: "OJ-04-0411",
  ERROR_INVALID_FLIGHT_SEGMENT: "OJ-04-0412",
  ERROR_INVALID_DATECHANGE_LIMIT: "OJ-04-0434",
  ERROR_INSURANCES_WITH_REFUND_REMARKS_ID: "OJ-04-0435",
  ERROR_INSURANCES_STATUS: "OJ-04-0436",
  ERROR_INSURANCES_WITH_CANCELLATION_COST_ID: "OJ-04-0437",
  ERROR_CANCELLATION_FAILED: "OJ-04-0439",

  // External flight booking search Error Codes
  EXTERNAL_FLIGHT_BOOKING_SEARCH_NOT_ALLOWED: "OJ-04-0600",
  EXTERNAL_FLIGHT_BOOKING_SEARCH_EXTRACT_INFORMATION_FAILED: "OJ-04-0601",
  EXTERNAL_FLIGHT_BOOKING_SEARCH_PASSENGER_NAME_NOT_MATCH: "OJ-04-0602",
  EXTERNAL_FLIGHT_BOOKING_SEARCH_CERTAIN_FLIGHT_NOT_ALLOWED: "OJ-04-0603",
  EXTERNAL_FLIGHT_BOOKING_SEARCH_NOT_ELIGIBLE_FOR_PREPAID: "OJ-04-0604",
  EXTERNAL_FLIGHT_BOOKING_SEARCH_TICKET_NOT_FOUND: "OJ-04-0605",
  EXTERNAL_FLIGHT_BOOKING_SEARCH_NOT_ALLOWED_FOR_SEAT_SELECTION: "OJ-04-0606",
  EXTERNAL_FLIGHT_BOOKING_SEARCH_LOAD_FAILURE: "OJ-04-0607",
  EXTERNAL_FLIGHT_BOOKING_IMPORT_FAILURE: "OJ-04-0608",
  EXTERNAL_FLIGHT_BOOKING_IMPORT_ORDER_EMPTY: "OJ-04-0609",
  EXTERNAL_FLIGHT_BOOKING_IMPORT_LOAD_FAILURE: "OJ-04-0610",
  EXTERNAL_FLIGHT_BOOKING_IMPORT_BOOKING_NOT_FOUND: "OJ-04-0611",
  EXTERNAL_FLIGHT_BOOKING_IMPORT_ORIGINAL_ORDER_EMPTY: "OJ-04-0612",
  EXTERNAL_FLIGHT_BOOKING_IMPORT_PNR_NOT_FOUND: "OJ-04-0613",
  EXTERNAL_SUPPLIER_CODE_SPECIFIED: "OJ-04-0614",
  EXTERNAL_SUPPLIER_CODE_BLANK: "OJ-04-0615",
  NON_EXTERNAL_CURRENCY_CODE_SPECIFIED: "OJ-04-0616",
  EXTERNAL_CURRENCY_CODE_BLANK: "OJ-04-0617",

  // Open Ticket Codes
  ERROR_PRODUCT_INVALID: "OJ-04-0413",
  ERROR_INVALID_OPEN_TICKET_APPLICATION_REQUEST_DATA: "OJ-04-0414",
  ERROR_INVALID_SELECTED_CUSTOMERS: "OJ-04-0415",
  ERROR_CLASS_NOT_ELIGIBLE: "OJ-04-0416",
  ERROR_ALREADY_MARKED_OPEN_TICKET: "OJ-04-0417",
  ERROR_TICKET_STATUS_UNAVAILABLE: "OJ-04-0418",
  ERROR_CANNOT_DO_OPEN_TICKET_IN_CURRENT_STATE: "OJ-04-0419",
  ERROR_NO_ADULT_SELECTED: "OJ-04-0420",
  ERROR_INFANT_UNACCOMPANIED: "OJ-04-0421",
  ERROR_CHILD_WITHOUT_ADULT: "OJ-04-0422",
  ERROR_ALREADY_MODIFIED_WITH_RESHOP: "OJ-04-0423",
  ERROR_SELECTED_PAX_HAVE_NOT_BEEN_TICKETED: "OJ-04-0424",
  ERROR_SPLIT_PNR_PROCESS_FAILED: "OJ-04-0425",
  ERROR_OPEN_TICKET_PROCESS_FAILED: "OJ-04-0426",
  ERROR_UNABLE_TO_SET_SEGMENT_STATUS: "OJ-04-0427",
  ERROR_DEMAND_TICKET_FAILURE: "OJ-04-0430",
  ERROR_INVALID_BOOKING_FOR_CONFIRMING_UPGRADE: "OJ-04-0431",
  ERROR_INVALID_INSURANCE_STATUS: "OJ-04-0432",
  ERROR_COST_FREE_SEAT_DEMAND_TICKET_FAILURE: "OJ-04-0438",

  /**
   * Baggage Booking error codes start
   */
  ERROR_REPEAT_BUY: "OJ-04-0501",
  ERROR_RESULT_NOT_EXISTS: "OJ-04-0505",
  ERROR_OPEN_TICKET_PROCESS_BAGGAGE: "OJ-04-0506",
  ERROR_INVALID_PRODUCT_NUMBER: "OJ-04-0512",
  ERROR_INVALID_FLIGHT_STATUS: "OJ-04-0513",
  ERROR_INVALID_TICKETING_STATUS: "OJ-04-0514",
  ERROR_PRICE_OPTION_NOT_IN_RESULTS: "OJ-04-0515",
  ERROR_PRICE_OPTION_NOT_AVAILABLE_FOR_CUSTOMER: "OJ-04-0516",
  /**
   * Baggage Booking error codes end
   */

  /**
   * Ancillary error codes start
   */
  ERROR_INVALID_RESULT_ID: "OJ-04-0520",
  ERROR_DUPLICATE_REQUEST: "OJ-04-0521",
  ERROR_INVALID_CUSTOMER_ID: "OJ-04-0522",
  ERROR_REPEAT_BUY_SSR: "OJ-04-0524",
  INVALID_FLIGHT_PRODUCT_ID: "OJ-04-0525",
  INVALID_SPECIAL_REQUEST_ID: "OJ-04-0526",
  INVALID_BOOKING_STATUS: "OJ-04-0527",
  ERROR_NO_VALID_SEGMENT_FOR_MEAL_SEARCH: "OJ-04-0530",
  ERROR_BEYOND_MAXIMUM_NUMBER: "OJ-04-0532",
  ERROR_BEYOND_QUANTITY_AVAILABLE: "OJ-04-0533",
  ERROR_MORE_THEN_ONE_SAME_ANCILLARY: "OJ-04-0534",
  INVALID_ANCILLARY_RESERVATION_TICKETING_STATUS: "OJ-04-0535",
  PASSENGER_HAS_ALREADY_BOOKED_FREE_MEAL: "OJ-04-0536",
  INVALID_PRODUCT_BOOKING_STATUS: "OJ-04-0537",
  FREE_AND_CHARGEABLE_MEAL_BOOKED_TOGETHER: "OJ-04-0538",
  ANCILLARY_PRODUCT_IS_NOT_BELONG_TO_CURRENT_AIR_PRODUCT: "OJ-04-0539",

  /**
   * Ancillary error codes end
   */

  // Insurance Booking
  INVALID_INSURANCE_PASSENGER_TYPE: "OJ-04-0801",
  CHECK_INSURANCE_NOT_FOUND: "OJ-04-0802",
  NO_AVAILABLE_INSURANCE: "OJ-04-0803",
  ERROR_EXCEEDING_THE_LIMIT_OF_PURCHASE: "OJ-04-0804",
  ERROR_INVALID_INSURANCE_EXISTED: "OJ-04-0805",
  INSURANCE_INPUT_DUPLICATE: "OJ-04-0806",
  NO_VALID_INSURANCE: "OJ-04-0807",
  INVALID_INSURED_AGE: "OJ-04-0808",
  WRONG_CALCULATED_INSURANCE_AMOUNT: "OJ-04-0809",
  DUPLICATE_INSURANCE_ERROR: "OJ-04-0810",
  NULL_INSURANCE_END_DATE: "OJ-04-0811",
  INVALID_INSURANCE_PURCHASE_DAYS: "OJ-04-0812",
  EMPTY_INSURANCE_AMOUNT: "OJ-04-0813",
  EMPTY_PASSENGER_EMAIL: "OJ-04-0814",
  CHECK_INSURANCE_EACH_PASSENGER_AGE: "OJ-04-0815",
  INVALID_INSURANCE_IN_SHOPPING_CART: "OJ-04-0816",
  INFANT_CANNOT_BUY_INSURANCE: "OJ-04-0817",
  ERROR_INSURANCE_NOT_PURCHASED: "OJ-04-0818",

  /**
   * Ancillary common validation error codes start.
   */
  INVALID_AIR_FOR_ANCILLARY_SEARCHING_BOOKING: "OJ-04-0650",
  INVALID_ALL_FLIGHT_SEGMENT_OPEN_OR_NO_TICKET: "OJ-04-0651",
  INVALID_NO_TICKET_OPEN_FOR_USE: "OJ-04-0652",
  ERROR_BOOKING_IN_PAYMENT: "OJ-04-0653",
  ALL_AIR_TICKETING_SHOULD_ISSUE: "OJ-04-0654",
  ERROR_ANCILLARY_HAS_PENDING_TICKETING: "OJ-04-0655",
  RELATIVE_FLIGHT_SEGMENT_OPEN_FOR_USE: "OJ-04-0656",
  RELATIVE_FLIGHT_SEGMENT_OPEN_OR_NO_TICKET: "OJ-04-0657",
  INVALID_EXTERNAL_SUPERPNR_MMB_SEARCHING_BOOKING: "OJ-04-0659",
  VALID_ONLY_ONE_AIR_PRODUCT_FOR_ANCILLARY_BOOKING: "OJ-04-0660",
  INVALID_ADD_SEAT_OTHER_ANCILLARY_TOGETHER: "OJ-04-0661",

  /**
   * Ancillary common validation error codes end.
   */

  /**
   * XSS defender interceptor error codes start.
   */
  XSS_DEFENDER_INTERCEPTOR_ERROR: "OJ-04-0662",
  /**
   * XSS defender interceptor error codes end.
   */

  /**
   * external flight tickets import common validation error codes begin.
   */
  EXTERNAL_FLIGHT_TICKETS_FAILED_TO_SEARCH_ORDER: "OJ-04-0700",
  EXTERNAL_FLIGHT_TICKETS_FAILED_TO_RETRIEVE_TICKET: "OJ-04-0701",
  EXTERNAL_FLIGHT_TICKETS_PASSENGER_NAME_NOT_MATCH: "OJ-04-0702",
  EXTERNAL_FLIGHT_TICKETS_FAILED_TO_RETRIEVE_PNR_FROM_TICKET: "OJ-04-0704",
  EXTERNAL_FLIGHT_TICKETS_NO_AVAILABLE_SEGMENTS: "OJ-04-0705",
  EXTERNAL_FLIGHT_TICKETS_FAILED_TO_RETRIEVE_PNR: "OJ-04-0706",
  EXTERNAL_FLIGHT_TICKETS_NO_AVAILABLE_SEGMENTS_IN_PNR: "OJ-04-0707",
  EXTERNAL_FLIGHT_TICKETS_FAILED_TO_RETRIEVE_HISTORY_TICKET: "OJ-04-0708",
  EXTERNAL_FLIGHT_TICKETS_GROUP_TICKETS_NOT_SUPPORT: "OJ-04-0709",
  EXTERNAL_FLIGHT_TICKETS_UNSUPPORTED_PASSENGER_TYPE: "OJ-04-0710",
  EXTERNAL_FLIGHT_TICKETS_CERTIFICATE_NUMBER_IS_NULL: "OJ-04-0711",
  EXTERNAL_FLIGHT_TICKETS_UNSUPPORTED_TEMP_CHANGE: "OJ-04-0712",
  EXTERNAL_FLIGHT_TICKETS_NO_IRR_REMARK_IN_TICKET: "OJ-04-0713",
  EXTERNAL_FLIGHT_TICKETS_CERTIFICATE_PNR_NUMBER_NOT_MATCH: "OJ-04-0714",
  EXTERNAL_FLIGHT_TICKETS_UNSUPPORTED_RESCHEDULING: "OJ-04-0717",
  EXTERNAL_FLIGHT_TICKETS_PASSENGER_NAME_FORMAT_ERROR: "OJ-04-0719",
  EXTERNAL_FLIGHT_TICKETS_TICKET_NUMBER_FORMAT_ERROR: "OJ-04-0720",
  EXTERNAL_FLIGHT_TICKETS_CERTIFICATE_NUMBER_NOT_MATCH: "OJ-04-0721",
  EXTERNAL_FLIGHT_TICKETS_UNSUPPORTED_CREATE_TEMP_ORDER: "OJ-04-0722",
  EXTERNAL_FLIGHT_TICKETS_CUSTOMER_PARAM_VALIDATE_ERROR: "OJ-04-0723",
  EXTERNAL_FLIGHT_TICKETS_NULL_REQUEST_FOR_EXTERNAL_IMPORT: "OJ-04-0725",
  EXTERNAL_FLIGHT_TICKETS_BAD_REQUEST_FOR_EXTERNAL_IMPORT: "OJ-04-0726",
  /**
   * external flight tickets import common validation error codes end.
   */
};

export const errorMessages = {
  "OJ-01-0001": "Please check date submitted. The date cannot be before today.",
  "OJ-01-0011": "The return date cannot be a date before the departure date.",
  "OJ-01-0012": "Invalid input parameter, it is not the required type",
  "OJ-01-0013": "Access is restricted due to invalid user type.",
  "OJ-01-0014": "Invalid username or password.",
  "OJ-01-0015": "The arrival date cannot be in the past.",
  "OJ-01-0016":
    "Check submitted departure date. Each bound must have a departure date after the previous bound depart date.",
  "OJ-01-0017": "The departure date cannot be in the past.",
  "OJ-01-0018": "Check submitted result id. This cannot be blank.",
  "OJ-01-0020": "Check submitted booking id. No booking was found.",
  "OJ-01-0021": "Check submitted resultSetId.",
  "OJ-01-0023": "NotNull.",
  "OJ-01-0024": "Invalid User-Token Signature.",
  "OJ-01-0025": "Malformed User-Token.",
  "OJ-01-0026": "Expired User-Token.",
  "OJ-01-0027":
    "Missing request header ''{0}'' for method parameter of type ''{1}''",
  "OJ-01-0028": "No such logged in user found.",
  "OJ-01-0029": "Check travel document id.",
  "OJ-01-0030": "Check submitted first name.",
  "OJ-01-0031": "Check submitted surname.",
  "OJ-01-0033": "Check submitted pax type.",
  "OJ-01-0034": "Check submitted email.",
  "OJ-01-0035": "Check submitted product number.",
  "OJ-01-0038": "Check submitted flight price id. This cannot be blank.",
  "OJ-01-0039": "Check submitted passengers gender.",
  "OJ-01-0040":
    "Check submitted passengers nationality. It should be a two letter country code.",
  "OJ-01-0041": "Check submitted passengers date of birth.",
  "OJ-01-0042": "Check submitted travel document type.",
  "OJ-01-0043": "Check submitted travel document expiry date",
  "OJ-01-0044": "Check submitted travel document issue country.",
  "OJ-01-0045":
    "Check submitted phone number. Telephone cannot be blank and must be in the correct format.",
  "OJ-01-0046":
    "Check Pax Type. Reached max amount of pax type for this product.",
  "OJ-01-0047":
    "A travel document of type NATIONAL_IDENTITY_DOCUMENT also needs to be submitted.",
  "OJ-01-0049": "Meal preferences are not allowed for this flight.",
  "OJ-01-0050": "If name is in Chinese, please use the surname input only.",
  "OJ-01-0051": "The persons name contained illegal characters.",
  "OJ-01-0052":
    "International flights may not use chinese characters for names.",
  "OJ-01-0054":
    "For domestic passengers with English spelling you must submit a first and last name.",
  "OJ-01-0055": "Check submitted travel document.",
  "OJ-01-0056": "This location does not exist.",
  "OJ-01-0057":
    "The enum value ''{0}'' is not acceptable. The valid enum values are ''{1}''.",
  "OJ-01-0058": "Infant id Numbers cannot be added to domestic flights.",
  "OJ-01-0059": "Infant inventory is not enough.",
  "OJ-01-0060": "The request is malformed.",
  "OJ-01-0061": "Origin location is same as destination location.",
  "OJ-01-0062": "Infant inventory is not available.",
  "OJ-01-0063": "The password cannot be blank.",
  "OJ-01-0064": "The relationship between infants and adults is faulty.",
  "OJ-01-0065": "The association between infant and adult cannot be empty.",
  "OJ-01-0066": "DeliveryOption cannot be blank.",
  "OJ-01-0067":
    "An exception occurred when the infant inventory interface was called.",
  "OJ-01-0068": "Check submitted city.",
  "OJ-01-0069": "Zip cannot be blank and must be in the correct format.",
  "OJ-01-0070": "Check submitted province.",
  "OJ-01-0071": "Gender cannot be blank.",
  "OJ-01-0072":
    "Check submitted address. Address lines cannot be empty or contain an empty string.",
  "OJ-01-0073": "BankId cannot be blank.",
  "OJ-01-0075": "Payment Options Response is null.",
  "OJ-01-0076": "The value exceeds a minimum value.",
  "OJ-01-0077": "The value exceeds a maximum value.",
  "OJ-01-0078": "The value has the incorrect size.",
  "OJ-01-0079": "The value cannot be null.",
  "OJ-01-0080": "Payment amount cannot be blank",
  "OJ-01-0081": "The value does not conform to the pattern.",
  "OJ-01-0082": "Bill No cannot be blank.",
  "OJ-01-0083": "Order No cannot be blank.",
  "OJ-01-0084": "Payment date cannot be blank.",
  "OJ-01-0085": "Payment time cannot be blank.",
  "OJ-01-0086": "Order cur type cannot be blank.",
  "OJ-01-0087": "Order type cannot be blank.",
  "OJ-01-0088": "Order cannot be blank.",
  "OJ-01-0092": "Message cannot be blank.",
  "OJ-01-0094": "Signature cannot be blank.",
  "OJ-01-0095": "Org Id cannot be blank.",
  "OJ-01-0098": "Check Submitted password.",
  "OJ-01-0100": "You have entered an incorrect username.",
  "OJ-01-0102": "Please specify age.",
  "OJ-01-0104": "Please check the submitted products list.",
  "OJ-01-0105": "Please check the submitted customer(s).",
  "OJ-01-0106": "Cannot search by location and radius simultaneously.",
  "OJ-01-0108": "Check submitted phone area code.",
  "OJ-01-0109": "Check submitted country code.",
  "OJ-01-0110": "Check submitted title.",
  "OJ-01-0111": "Flight Details should be submitted.",
  "OJ-01-0115":
    "Only pax type of ADT is allowed for assigning to a vehicle product.",
  "OJ-01-0116":
    "Date of birth is younger or older than specified age at the time of vehicle rental.",
  "OJ-01-0118": "Check submitted middle name.",
  "OJ-01-0119": "Please check the submitted name.",
  "OJ-01-0120":
    "Child age must be >: ''{1}'' and <: ''{2}''. Infant age must be >: 0 and <: ''{0}''.",
  "OJ-01-0122": "Domestic flights may not use title.",
  "OJ-01-0123": "Domestic flights may not use gender.",
  "OJ-01-0124": "Domestic flights may not use middle name.",
  "OJ-01-0125": "Please check the product type. It should be flight product.",
  "OJ-01-0127": "Check submitted Cash Amount.",
  "OJ-01-0128": "Check submitted Points Quantity.",
  "OJ-01-0129": "Please check the submitted contact.",
  "OJ-01-0130": "Please check the submitted credit card number.",
  "OJ-01-0131": "Please check the submitted credit card expiry date.",
  "OJ-01-0132": "Please check the submitted credit card series code.",
  "OJ-01-0134": "Please check the submitted country name.",
  "OJ-01-0137": "Expiry dates must be in the format MMYY or MMYYYY.",
  "OJ-01-0142": "Please check submitted redirect token.",
  "OJ-01-0143": "Please check submitted cheque sort code.",
  "OJ-01-0144": "Please check submitted cheque account code.",
  "OJ-01-0145": "Please check submitted cheque code.",
  "OJ-01-0146": "Please check submitted cash comment.",
  "OJ-01-0147": "Please check submitted offline status.",
  "OJ-01-0148":
    "Amount and quantity can not both be populated or else payment type is not supported.",
  "OJ-01-0150": "Please check the submitted post code.",
  "OJ-01-0151": "Check submitted flight id. No flight with provided id found.",
  "OJ-01-0156": "Please check the submitted credit card holder name.",
  "OJ-01-0157": "Child age must match age entered in initial search.",
  "OJ-01-0158": "Field cannot be empty/null.",
  "OJ-01-0159": "The length of name should be between {0} and {1}.",
  "OJ-01-0160": "Invalid name.",
  "OJ-01-0161": "Middle name is not allowed in Chinese.",
  "OJ-01-0162":
    "The name or surname contains chinese raw words, please replace the words to phonetic letters start from the first raw word.",
  "OJ-01-0163": "name should not include characters 'CHD'.",
  "OJ-01-0164": "name should not include characters 'INF'.",
  "OJ-01-0165": "Invalid Agent-Token Signature.",
  "OJ-01-0166": "Malformed Agent-Token.",
  "OJ-01-0167": "Expired Agent-Token.",
  "OJ-01-0169":
    "Invalid number of ''{0}'' submitted for room ''{1}''. Only ''{2}'' are permitted per room.",
  "OJ-01-0170":
    "Customers with duplicate name ''{0}'' cannot be added to product.",
  "OJ-01-0171":
    "Duplicate document id ''{0}'' found when adding customers to product.",
  "OJ-01-0172":
    "Duplicate document type ''{0}'' found when adding customers to product.",
  "OJ-01-0174":
    "Passenger type ''{0}'' for customer with date of birth ''{1}'' will not be valid on travel date ''{2}'', passenger age will be ''{3}''.",
  "OJ-01-0175": "Flight product ids are missing from the request",
  "OJ-01-0176": "Only one flight product id may be passed in per request.",
  "OJ-01-0177": "Unsupported product type. Check submitted product id.",
  "OJ-01-0178": "Blank is not allowed for booking reference.",
  "OJ-01-0179": "Blank is not allowed for user token.",
  "OJ-01-0180": "Invalid cabin class submitted. Only ''{0}'' permitted.",
  "OJ-01-0181": "The booking is not pending payment.",
  "OJ-01-0183": "Failed to retrieve PRN to validate seat related booking.",
  "OJ-01-0184": "Flight segment can not be null.",
  "OJ-01-0185": "Current segment does not support ancillary selection.",
  "OJ-01-0186": "Current passenger is not allowed to select ancillary.",
  "OJ-01-0187":
    "Current passenger travelers have a choice of seats required to cancel the corresponding leg seat or contact customer service.",
  "OJ-01-0182": "The live product is not found.",
  "OJ-01-0189": "Promotion code is not allowed for submitted flight type.",
  "OJ-01-0190": "Redress info is required.",
  "OJ-01-0220":
    "Resident address information is required for passengers travelling to this destination.",
  "OJ-01-0221":
    "Destination address information is required for passengers travelling to this destination.",
  "OJ-01-0222": "Invalid destination redress info address country code.",
  "OJ-01-0223": "Length of field must be between {0} and {1}.",
  "OJ-01-0224":
    "Check the submitted resident and destination addresses have both been submitted correctly.",
  "OJ-01-0226":
    "Invalid 'gender' is submitted. Permitted gender values are: ''{0}''.",
  "OJ-01-0227":
    "Passenger counts is missing. Passenger counts are required for package searches.",
  "OJ-01-0228":
    "Passenger counts does not match adult guest count in hotel rooms.",
  "OJ-01-0229":
    "Passenger counts does not match child guest count in hotel rooms.",
  "OJ-01-0230":
    "Passenger counts does not match infant guest count in hotel rooms.",
  "OJ-01-0231":
    "Multi city calendar searches are not supported. Please search for a one way or return flight.",
  "OJ-01-0232": "External payment reference is required.",
  "OJ-01-0233": "Bank code is required.",
  "OJ-01-0234": "Signature is required.",
  "OJ-01-0235": "Currency code is required.",
  "OJ-01-0236": "Amount is required.",
  "OJ-01-0237": "Payment reference is required.",
  "OJ-01-0238": "Signature is invalid.",
  "OJ-01-0239": "The payment reference is invalid.",
  "OJ-01-0240": "The payment information is invalid.",
  "OJ-01-0241": "The payment amount is invalid.",
  "OJ-01-0242": "The two step external payment is not enabled.",
  "OJ-01-0243": "The superPNR product is canceled.",
  "OJ-01-0244": "Sorting by ''{0}'' is not applicable.",
  "OJ-01-0245":
    "The redeem points: loyaltyRedeemPoints is greater than the loyalty account points: loyaltyAccountPoints.",
  "OJ-01-0246": "Please check the redeem quantity in the booking.",
  "OJ-01-0247": "The length of surname should be between {0} and {1}.",
  "OJ-01-0248": "The length of first name should be between {0} and {1}.",
  "OJ-01-0249": "The range of departure date does not exist.",
  "OJ-01-0250":
    "Unsupported product type. Vouchers can only be generated for hotels, vehicles and event products.",
  "OJ-01-0251": "Blacklisted User-Token.",
  "OJ-01-0252": "Blacklisted Agent-Token.",
  "OJ-01-0253":
    "Invalid number of ''{0}'' or ''{1}'' submitted for room ''{2}''. Only ''{3}'' guests are permitted per room.",
  "OJ-01-0254":
    "Invalid number of ''{0}'' or ''{1}'' submitted. The total number of guests for all hotel products cannot exceed ''{2}''.",
  "OJ-01-0255":
    "Invalid number of guests for room ''{0}''. Maximum of ''{1}'' guests are permitted per room.",
  "OJ-01-0256":
    "Too many hotel guests are being added to the booking. Maximum of ''{0}'' guests per booking are permitted.",

  // Flight Passenger
  "OJ-01-0191": "No passengers are found for the user.",
  "OJ-01-0192": "The passenger does not exist.",
  "OJ-01-0194": "Please check the frequent flyer card number.",
  "OJ-01-0195": "Document information of this passenger already exists.",
  "OJ-01-0196": "Please check the profile type.",
  "OJ-01-0199":
    "Please check the international passenger document type, it must be PASSPORT.",

  // Car Errors
  "OJ-01-0200": "Drop off date cannot be before pick up date.",
  "OJ-01-0203": "Please check the submitted priceId & resultId.",
  "OJ-01-0204": "Please check the submitted car extra code.",
  "OJ-01-0206": "Please check the submitted longitude.",
  "OJ-01-0207": "Please check the submitted latitude.",
  "OJ-01-0208": "Please check the submitted  driver age.",
  "OJ-01-0210":
    "Driver Age ''{0}'' is less than the minimum driver age allowed ''{1}''.",
  "OJ-01-0211": "Pick up date cannot be before the current date.",
  "OJ-01-0212": "Drop off date cannot be before the current date.",
  "OJ-01-0213": "Pick up or drop off time cannot be before current time.",
  "OJ-01-0214": "Drop off time cannot be before or equal to pick up time.",
  "OJ-01-0215":
    "Customer driver age (''{0}'') must match driver age in initial search (''{1}'').",
  "OJ-01-0216": "Please check the submitted pick up location.",
  "OJ-01-0217": "Please check the submitted drop off location.",
  "OJ-01-0218": "This special equipment has been requested multiple times.",

  // Profile information management
  "OJ-01-0302": "Traveler ID has not to be null.",
  "OJ-01-0303": "Flight segment status is incorrect.",
  "OJ-01-0304": "Ticketing status is incorrect.",
  "OJ-01-0305": "Traveler itinerary response for seat has not to be null.",
  "OJ-01-0306": "Traveler itinerary response for baggage has not to be null.",
  "OJ-01-0307": "Check submitted prcId.",
  "OJ-01-0308": "Failed to disable account.",
  "OJ-01-0309": "Error when getting login error count from cache.",
  "OJ-01-0310": "Error when saving login error count into cache.",
  "OJ-01-0311": "Error when getting the number of ticketed booking.",
  "OJ-01-0312": "Error when getting the number of cancelled booking.",
  "OJ-01-0313": "Automatically disabled by the system.",
  "OJ-01-0315": "Flight segment is incorrect.",
  "OJ-01-0316": "Traveler itinerary response for ancillary has not to be null.",
  "OJ-01-0320": "Failed to reset password.",
  "OJ-01-0321": "Email is not authenticated.",
  "OJ-01-0322": "Mobile is not authenticated.",
  "OJ-01-0331": "Please check submitted authentication code.",
  "OJ-01-0332": "Coupon password is incorrect.",
  "OJ-01-0333": "Failed to authenticate coupon.",
  "OJ-01-0340": "Invalid coupon status.",
  "OJ-01-0341": "Invalid coupon scope.",
  "OJ-01-0342": "Invalid coupon type.",
  "OJ-01-0343":
    "The coupon search redeem end date cannot be a date before the start date.",
  "OJ-01-0344":
    "The coupon search expiry end date cannot be a date before the start date.",
  "OJ-01-0345":
    "The coupon search activation end date cannot be a date before the start date.",
  "OJ-01-0346": "Please check the coupon number.",
  "OJ-01-0347": "Please check the coupon activation start and end date.",
  "OJ-01-0348": "Please check the coupon expiry start and end date.",
  "OJ-01-0349": "Please check the coupon redeem start and end date.",
  "OJ-01-0350": "Failed to send authentication email.",
  "OJ-01-0351": "Failed to authenticate email.",
  "OJ-01-0352":
    "Failed to authenticate email due to the invalid authentication code.",
  "OJ-01-0353": "Mobile phone update and authentication error.",
  "OJ-01-0354": "Invalid security question or answer.",
  "OJ-01-0355": "Failed to get authentication status for user.",
  "OJ-01-0356": "Failed to retrieve user profile.",
  "OJ-01-0357": "The original phone number is incorrect.",
  "OJ-01-0358":
    "The new phone number can't be same as the original phone number.",
  "OJ-01-0359": "Failed to update email.",
  "OJ-01-0360": "Failed to save email authentication code.",
  "OJ-01-0361": "Please check submitted captcha code.",
  "OJ-01-0362": "New email address is the same as original one.",
  "OJ-01-0364": "New password should not equal to old password.",
  "OJ-01-0365": "Error when getting authentication code from cache.",
  "OJ-01-0366": "The email already authenticated.",
  "OJ-01-0367": "Username has been registered.",
  "OJ-01-0368": "The current selection time range is incorrect.",
  "OJ-01-0370": "Origin does not match destination code.",
  "OJ-01-0371": "Code does not match context.",
  "OJ-01-0372": "Code can not be empty.",
  "OJ-01-0373":
    "Failed to send email, server can not process your request now.",
  "OJ-01-0374": "Failed to send SMS, server can not process your request now.",
  "OJ-01-0375":
    "SMS verification failed, server can not process your request now.",
  "OJ-01-0376": "Failed to send verification SMS.",
  "OJ-01-0377": "Invalid SMS auth code.",
  "OJ-01-0378":
    "There can only be one phone number and the phone type cannot be a mobile.",
  "OJ-01-0379":
    "The days between start and end dates can not be greater than {0} days.",
  "OJ-01-0380": "Mobile already authenticated.",
  "OJ-01-0381": "Failed to update profile info.",
  "OJ-01-0382": "Please check submitted preferred language.",
  "OJ-01-0383": "Phone type is not mobile.",
  "OJ-01-0384":
    "At least one of the following: ID card number or passport number or other certificate number.",
  "OJ-01-0385": "Failed to add beneficiary.",
  "OJ-01-0386":
    "The answer is only allowed to input Chinese and English characters, half-width numbers 0-9, symbols -, _ or /.",
  "OJ-01-0387": "Failed tp delete beneficiary.",
  "OJ-01-0388": "Beneficiary ID is null.",
  "OJ-01-0389": "Failed to get beneficiaries.",
  "OJ-01-0390": "Start date can not be empty.",
  "OJ-01-0391": "End date can not be empty.",
  "OJ-01-0392": "The start date can not be later than end date.",
  "OJ-01-0393": "The query date interval can not be more than one year.",
  "OJ-01-0394": "Invalid pagination parameters.",
  "OJ-01-0395": "Invalid transaction type.",
  "OJ-01-0396": "Failed to search profile transactions.",
  "OJ-01-0397": "Invalid user name for login.",
  "OJ-01-0398": "Failed to add ip blacklist.",
  "OJ-01-0399": "Blank can't be included in password.",
  "OJ-01-0401": "MFA request header meta format error.",

  // Hotel Errors
  "OJ-01-0400":
    "Please check the number of rooms requested. Must not exceed ''{1}''.",
  "OJ-01-0404":
    "Configuration property value is not defined for property ''{0}''.",
  "OJ-01-0405":
    "Child Age ''{0}'' is less than the minimum child age allowed ''{1}''.",
  "OJ-01-0408": "Please check the submitted hotel extra code.",
  "OJ-01-0415":
    "The hotel checkout date cannot be before the hotel checkin date.",

  "OJ-01-0416":
    "Child Age ''{0}'' is greater than the maximum child age allowed ''{1}''.",
  "OJ-01-0418":
    "Checkin date ''{0}'' cannot be the same as checkout date ''{1}''.",
  "OJ-01-0419":
    "Please check the submitted hotel special request bed type as it is not valid for the hotel.",
  "OJ-01-0420":
    "Please check the submitted hotel special request smoking preference as it is not valid for the hotel.",
  "OJ-01-0421":
    "Please check the submitted radius search, longitude amd latitude cannot be zero.",
  "OJ-04-0048": "Please retrieve cancellation cost.",

  // Event Errors
  "OJ-01-0500":
    "Please check the number of tickets submitted as it exceeds the maximum of ''{0}'' allowed.",
  "OJ-01-0501":
    "Please check the submitted ticket search date as it's not in the date range of event search results.",
  "OJ-01-0502":
    "Please check the submitted ticket types searched as they are not valid for this event.",
  "OJ-01-0503":
    "Please check the number of tickets submitted as it exceeds the maximum available, Tickets Available: ''{0}''.",
  "OJ-01-0504": "Please check submitted event result ID and price ID.",
  "OJ-01-0505":
    "Please submit a ticket type for customer assignment to an event product.",
  "OJ-01-0506":
    "Please check the submitted ticket type for this customer as it is not valid for the event.",
  "OJ-04-0507": "A lead traveler has not been selected for product {0}.",
  "OJ-04-0508": "Hotel Pickups are not available for this event.",
  "OJ-04-0509":
    "Please check event booking questions for product {0} as not all have been answered.",
  "OJ-04-0510":
    "Event can't be added to cart as the cost of the event has changed.",
  "OJ-04-0511": "Event reprice response can't be retrieved.",

  // Flight Errors
  "OJ-01-0601": "Customer not present in the seat map response.",
  "OJ-01-0602": "Seat map id not present in the seat map response.",
  "OJ-01-0603": "Row number not present in the seat map response.",
  "OJ-01-0604": "Seat number not present in the seat map response.",
  "OJ-01-0605": "This seat is not available.",
  "OJ-01-0606": "This chargeable seat is already taken.",
  "OJ-01-0607": "This customer has already a selected chargeable seat.",
  "OJ-01-0608": "This free seat is already taken.",
  "OJ-01-0609": "This customer has already a free seat.",
  "OJ-01-0610":
    "This flight product has already been modified. Commit the changes and try again.",
  "OJ-01-0611":
    "This spnr has pending seat products. Commit them and try again.",
  "OJ-01-0612": "This seat has been requested multiple times in this request.",
  "OJ-01-0613":
    "This customer has more than one seat selected in this request.",
  "OJ-01-0614": "The seat ancillary is unavailable.",
  "OJ-01-0615": "Error retrieving seat map from xdistributor.",
  "OJ-01-0616": "There are no seat assignments available.",
  "OJ-01-0617": "Please check the submitted depart date.",
  "OJ-01-0618": "Please check the submitted return date.",
  "OJ-01-0619": "Check submitted departure location.",
  "OJ-01-0620": "This customer already has seat.",
  "OJ-01-0622": "Ticketing has been completed,no need to request ticketing.",
  "OJ-01-0623": "Failed to request for Flight Ticketing.",
  "OJ-01-0624": "Invalid 'bounds' is submitted. Permitted maximum: ''{0}''.",
  "OJ-01-0625":
    "Invalid 'passengerCounts' is submitted. Permitted passenger types are ''{0}''.",
  "OJ-01-0626":
    "Invalid 'passengerCounts' is submitted. Several entries with the same passenger type are not allowed.",
  "OJ-01-0627":
    "Invalid 'passengerCounts' is submitted. Number of INF passengers cannot exceed number of passengers with types ''{0}''.",
  "OJ-01-0628":
    "Invalid 'passengerCounts' is submitted. Permitted minimum is 1 passenger of any type from ''{0}''.",
  "OJ-01-0629":
    "Invalid 'passengerCounts' is submitted. Permitted maximum is ''{0}'' passengers of any type from ''{1}''.",
  "OJ-01-0633":
    "Invalid additional document type. For passenger type ''{0}'' id type must be ''{1}''.",
  "OJ-01-0634":
    "Additional document id pattern is incorrect. Expected pattern is ''{0}''.",
  "OJ-01-0635": "Additional document required for this passenger type.",
  "OJ-01-0636": "Additional document not allowed for this passenger type.",
  "OJ-01-0637":
    "Invalid 'bounds' is submitted. Flexible rows search is not permitted.",
  "OJ-01-0638": "The seat info can't be found in booking.",
  "OJ-01-0639": "Failed to cancel the free seats.",
  "OJ-01-0640": "The seat has no corresponding product price.",
  "OJ-01-0641": "The air product ticketing status is checked in.",
  "OJ-01-0642":
    "Ancillary products have already ticketed successfully, no need to request ticketing.",
  "OJ-01-0643": "Failed to request ticketing for ancillary products.",
  "OJ-01-0644": "Please request ticketing for air product first.",
  "OJ-01-0645": "Submitted 'selectedOptionIds' is in incorrect format.",
  "OJ-01-0646": "Only one 'selectedOptionIds' per bound is allowed.",
  "OJ-01-0647":
    "Submitted 'selectedOptionIds' is not found in the search results set.",
  "OJ-01-0648":
    "Number of 'selectedOptionIds' should be less or equal number of bounds in the search.",
  "OJ-01-0649": "Only combinable options are allowed in 'selectedOptionIds'.",
  "OJ-01-0650": "Submitted 'boundIds' is not found in search results set.",
  "OJ-01-0651":
    "Invalid 'boundIds' is submitted. Several entries with the same id are not allowed.",
  "OJ-01-0652":
    "Invalid 'maxConnections' is submitted. Permitted maximum: ''{0}''.",
  "OJ-01-0653": "'resultId' is not permitted with submitted 'resultSetId'.",
  "OJ-01-0654": "Failed to save seat map result.",
  "OJ-01-0655": "Failed to get seat map result.",
  "OJ-01-0951": "No seat selections.",
  "OJ-01-0952": "Seat selection is incorrect.",
  "OJ-01-0953": "Existing pending payment booking.",
  "OJ-01-0954": "Please, specify 'selectedOptionIds' for all bounds.",
  "OJ-01-0955":
    "Submitted 'selectedOptionIds' should represent a boundFlightOptionId ''{0}''",
  "OJ-01-0956":
    "Only one 'departureTimePerBoundRangeMin' per bound is allowed.",
  "OJ-01-0957":
    "Submitted 'departureTimePerBoundRangeMax' is in incorrect format.",
  "OJ-01-0958":
    "Only one 'departureTimePerBoundRangeMax' per bound is allowed.",
  "OJ-01-0959": "Submitted bound is not found in search results.",
  "OJ-01-0960":
    "Submitted 'departureTimePerBoundRangeMin' is in incorrect format.",
  "OJ-01-0961":
    "The seats you choose include free booking forms under the line, and no support for website withdrawal.",
  "OJ-01-0962": "Failed to cancel the special service requests.",
  "OJ-01-0963": "All the tickets status must be open for use.",
  "OJ-01-0964": "The special service requests must be the same type.",
  "OJ-01-0965":
    "The special service requests must be in the same flight product.",
  "OJ-01-0966":
    "The special service requests must be in the same flight segment.",
  "OJ-01-0967": "Submitted duration range is in incorrect format.",
  "OJ-01-0968": "Only one duration range per bound is allowed.",
  "OJ-01-0969": "Submitted 'connectionsPerBound' is in incorrect format.",
  "OJ-01-0970": "Duplicated filter by number of connections on the same bound.",
  "OJ-01-0971": "Submitted airlines list is in incorrect format.",
  "OJ-01-0972": "Duplicated filter by airlines on the same bound.",
  "OJ-01-0973": "Pay or cancel the pending booking.",
  "OJ-01-0974":
    "The flight product is not allowed to be added repeatedly to the shopping cart.",
  "OJ-01-0975":
    "The number of children and infants must be 0 When the number of adults is 0.",
  "OJ-01-0976":
    "For non-UK departure countries, the number of youth must be zero.",
  "OJ-01-0977": "The number of adults and youths cannot be 0.",
  "OJ-01-0978": "Youth passenger types are only available for economy class.",
  "OJ-01-0979": "Infant cannot allowed to buy paid baggage.",
  "OJ-01-0980": "Infant cannot allowed to choose free meals.",
  "OJ-01-0981": "Infant cannot add seats.",
  "OJ-01-0982": "Infant cannot add additional services.",
  "OJ-01-0983": "The adult carries a infant and cannot be deleted.",
  "OJ-01-0984": "Has pending payment product.",
  "OJ-01-0985": "Has not cost-free seat.",
  "OJ-01-0986": "Failed to submit cost-free seat ancillaries modifications.",
  "OJ-01-0987":
    "Initiate payment is not allowed since only has cost free seat.",

  // Package Errors
  "OJ-01-0800": "The package search type issued is not allowed.",
  "OJ-01-0801":
    "The number of adults ''{0}'' is less than the minimum number of adults allowed ''{1}''.",
  "OJ-01-0802":
    "The number of adults ''{0}'' is greater than the maximum number of adults allowed ''{1}''.",
  "OJ-01-0803":
    "The number of children ''{0}'' is greater than the maximum number of children allowed ''{1}''.",
  "OJ-01-0805":
    "The total number of guests ''{0}'' is greater than the maximum number of guests allowed ''{1}''.",
  "OJ-01-0806": "Package with ID ''{0}'' does not exist.",
  "OJ-01-0807":
    "Room option with id ''{0}'' and index ''{1}'' not found in package ''{2}''.",
  "OJ-01-0808": "Invalid package flight bound.",
  "OJ-01-0810": "The package id cannot be blank.",
  "OJ-01-0811": "The booking already contains a package.",
  "OJ-01-0812": "Package flight option ''{0}'' does not exist.",
  "OJ-01-0813": "Check submitted package id.",
  "OJ-01-0814":
    "Hotel checkin date must be within the package departure and arrival dates.",
  "OJ-01-0815":
    "Hotel checkout date must be within the package departure and arrival dates.",
  "OJ-01-0816": "Package with id ''{0}'' not found in booking ''{1}''.",
  "OJ-01-0817": "The hotel name ''{0}'' is invalid.",
  "OJ-01-0818": "Invalid star rating. The valid star ratings are ''{0}''.",
  "OJ-01-0819":
    "Package duration is below the minimum package duration required.",
  "OJ-01-0820":
    "Package duration is over the maximum package duration required.",
  "OJ-01-0821":
    "Package minimum lead time below the minimum package lead time allowed.",
  "OJ-01-0822": "Package lead time over maximum package lead time allowed.",
  "OJ-01-0823":
    "Location search by coordinates is not allowed for this product type.",
  "OJ-01-0824": "Destination location is missing a location type.",
  "OJ-01-0825": "Package hotel filter by coordinates is not allowed.",
  "OJ-01-0826": "Type of passenger not supported.",
  "OJ-01-0827":
    "The check-in date can not be more than 15 days. Please re-select the hotel arrival date.",
  "OJ-01-0828":
    "Adults and children and infants do not support simultaneous selection.",
  "OJ-01-0829": "Type of trip not supported.",
  "OJ-01-0830": "Cancellation cost cannot be retrieve.",
  "OJ-01-0831": "Car option with id ''{0}'' not found in package ''{1}''.",
  "OJ-01-0832": "Package product status is incorrect.",
  "OJ-01-0833":
    "When retrieving flight options for inbound flights you cannot filter by outbound id.",
  "OJ-01-0834":
    "Submitted airline ''{0}'' does not exist in package with id ''{1}''.",
  "OJ-01-0835":
    "Outbound flight option ''{0}'' is not compatible with airline ''{1}''.",
  "OJ-01-0836":
    "The flight option ''{0}'' arrival time is outside of the selected car depot operating times.",
  "OJ-01-0837":
    "Airline ''{0}'' flight option arrival time is outside of the selected car depot operating times.",

  // Flight refund error codes
  "OJ-01-0900":
    "The state does not meet the refund conditions, please contact customer service for processing.",
  "OJ-01-0901":
    "The ticket time is over one year or cannot synchronise ticket time from GDS.",
  "OJ-01-0902":
    "Please cancel the checked-in flight segment in other channels, and then refund and upgrade.",
  "OJ-01-0903": "Children can not travel alone.",
  "OJ-01-0904":
    "Please ensure the same flights are selected for each passenger.",
  "OJ-01-0905":
    "Please check submitted customer. No customer with id {0} found.",
  "OJ-01-0906":
    "Please check submitted flight segment. No flight segment with id {0} found.",
  "OJ-01-0907":
    "Tickets are not used in order, they are considered as automatic waiver and cannot be refunded.",
  "OJ-01-0908":
    "Tickets are not used in order, only segments {0} to {1} can be refunded.",
  "OJ-01-0909": "Please select all remaining unused segments to refund.",
  "OJ-01-0910": "Refund remarks not found.",
  "OJ-01-0911":
    "Ticket information has changed, no other operations are allowed, please contact airline call center to process.",
  "OJ-01-0912": "History order can not be refunded.",
  "OJ-01-0913": "All channel or external change order can not be refunded.",
  "OJ-01-0914": "The ticketing status of the order is not applied to refund.",
  "OJ-01-0915": "Duplicate customer id.",
  "OJ-01-0916": "Duplicate flight segments id.",
  "OJ-01-0917": "The process of the queue is failed.",
  "OJ-01-0918": "The refund remark ID should not be empty.",
  "OJ-01-0919": "Something wrong with refund while committing.",
  "OJ-01-0920": "Currently only support the flight refund by segments.",
  "OJ-01-0921":
    "Your product does not meet the voluntary application of additional services.",
  "OJ-01-0922": "There is no ticketing for reshopped air product.",
  "OJ-01-0923":
    "Sorry, can not get your domestic flight segments cancel cost, please contact your airline.",
  "OJ-01-0924":
    "Sorry, your order is unable to complete the operation, please contact your airline to complete your transaction.",
  "OJ-01-0925":
    "The information of the order has already been changed,please go back to order details.",
  "OJ-01-0926":
    "ACE insurance reversion rules maintenance error,please contact the administrator to deal with.",
  "OJ-01-0928":
    "Insurance is not yet booked, please later to refund, thank you.",
  "OJ-01-0929":
    "Purchase information is being synchronized, the most we will take {0} minutes to complete the synchronization, please try again later operation of this order, this has caused you distress, very sorry, hope you understand.",
  "OJ-01-0930": "The insurance product is not booked.",
  "OJ-01-0931":
    "Some of the insurance products you choose may not conform to the separate surrender rules.Please re select insurance products.",
  "OJ-01-0932":
    "The {0} refund time limit is {1}. The time limit is now extended and no refund is allowed.",
  "OJ-01-0933":
    "Sorry,can not refund the insurance because there is an unpaid payment order.",
  "OJ-01-0934":
    "Sorry,can not refund the insurance because The flight has taken off.",
  "OJ-01-0935": "Refund reason type is invalid.",
  "OJ-01-0936": "Partial cancel by flight segment not supported.",
  "OJ-01-0937": "Infant can not travel alone.",
  "OJ-01-0938": "Please ensure the flights segments Ids are not empty.",
  "OJ-01-0939": "All segments in a bound must be selected for cancellation.",
  "OJ-01-0940": "The booking is archived.",
  "OJ-01-0941":
    "The number of products of this type in the cart cannot exceed {0}.",
  "OJ-01-0942": "The total number of products in the cart cannot exceed {0}.",
  "OJ-01-0943":
    "The number of unique hotel modular products in the cart cannot exceed {0}.",
  "OJ-01-0944":
    "Access is restricted. Only reservation and payment operations are allowed when the first transaction of a 2 phase transaction has been made.",
  "OJ-01-0945":
    "Access is restricted. The first transaction in the booking process has not been made.",
  "OJ-01-0946":
    "Access is restricted. The first transaction in the booking process has already been made.",

  // System Errors - Infrastructure, environment, bugs
  "OJ-03-0001": "An unknown error occurred.",
  "OJ-03-0005": "500 Internal Server Error.",
  "OJ-03-0007": "An unknown error has occurred.",
  "OJ-03-0008": "The endpoint url is not valid.",
  "OJ-03-0009": "The endpoint method is not valid.",
  "OJ-03-0010": "The easypay public key cannot be found on the server.",
  "OJ-03-0011": "The provided media type is not supported for this endpoint.",
  "OJ-03-0013": "Failed to add a customer that already exists.",
  "OJ-03-0014": "Failed to save data to cache.",
  "OJ-03-0015": "Failed to get data from cache.",
  "OJ-03-0016": "An error occurred during cache access. Please retry.",
  "OJ-03-0017": "Internal connection error.",

  // Applications Errors - booking, product repricing errors
  "OJ-04-0001": "Error occurred in application.",
  "OJ-04-0002": "No results for the criteria entered.",
  "OJ-04-0003": "Failed to make a reservation.",
  "OJ-04-0004": "Unsupported Locale.",
  "OJ-04-0005": "Unsupported currency.",
  "OJ-04-0006": "Contact details already exist.",
  "OJ-04-0007": "Air price message did not return successfully.",
  "OJ-04-0008": "Failed to generate the payment id.",
  "OJ-04-0009": "The cost of your flight has changed.",
  "OJ-04-0010": "No contact details exist for this booking.",
  "OJ-04-0011":
    "Invalid number of {0} passengers added to {1} product. Required: {2}, found: {3}.",
  "OJ-04-0012": "SuperPNR does not contain any products.",
  "OJ-04-0013": "The superPNR is not in a valid state for payment.",
  "OJ-04-0014": "Cannot authorise payment, there is no payment due.",
  "OJ-04-0015":
    "Cannot authorise payment, the booking is out of the ticket time limit.",
  "OJ-04-0016": "Unsuccessful payment.",
  "OJ-04-0017": "PaymentRQ Error.",
  "OJ-04-0018":
    "SuperPNRID has not been created. Ensure the cart has been booked.",
  "OJ-04-0019":
    "Cannot confirm payment, the payment provider confirm stage has errors",
  "OJ-04-0020": "The signature returned by EasyPay is not valid.",
  "OJ-04-0022":
    "Failed to retrieve SPNR from ID received in EasyPay response msg parameter.",
  "OJ-04-0023":
    "Unpredictable payment status pair between EasyPay and the SuperPNR.",
  "OJ-04-0024": "Payment status update failed.",
  "OJ-04-0025": "Failed to decode the confirm payment input.",
  "OJ-04-0026": "Customer Details are missing from booking.",
  "OJ-04-0027": "Unknown product type trying to add to booking.",
  "OJ-04-0028":
    "Currency code of product being added must match currency of existing products in SuperPNR.",
  "OJ-04-0030": "SuperPNR has no pending items.",
  "OJ-04-0031": "Get payment is empty.",
  "OJ-04-0033": "No payments found in SPNR.",
  "OJ-04-0035": "Seat map not found.",
  "OJ-04-0036":
    "Result set ID does not exist or has expired (Result set ID : {0}).",
  "OJ-04-0037": "Flight product associated with seat not found.",
  "OJ-04-0038": "Failed to save profile.",
  "OJ-04-0039": "Failed to get profile.",
  "OJ-04-0040": "Failed to save search criteria.",
  "OJ-04-0041": "Failed to get search criteria.",
  "OJ-04-0042": "There has been an error checking the price of the package.",
  "OJ-04-0043":
    "There has been an error checking the price of the hotel component of the package.",
  "OJ-04-0044": "Error deleting package ''{0}'' from the SuperPNR ''{1}''.",
  "OJ-04-0045": "Error deleting product ''{0}'' from the SuperPNR ''{1}''.",
  "OJ-04-0049": "No ancillaries have been enabled.",
  "OJ-04-0050": "Failed to search flight ancillary list.",
  "OJ-04-0051": "Failed to check flight ancillary availability.",
  "OJ-04-0052": "Failed to save baggage search result.",
  "OJ-04-0053": "Failed to recognize baggage pricing type in SuperPNR.",
  "OJ-04-0054": "Invalid baggage data in SuperPNR.",
  "OJ-04-0055": "Booking shouldn't contains PENDING_PAYMENT status.",
  "OJ-04-0056": "The associated passenger must also be marked for cancel",
  "OJ-04-0057": "Failed to retrieve the hotel details from the supplier.",
  "OJ-04-0058": "Incorrect payment balance.",
  "OJ-04-0059": "The air product can not be found.",
  "OJ-04-0060": "Payment type is not supported.",
  "OJ-04-0062": "Insufficient balance of points",
  "OJ-04-0061": "Failed to save coupon.",
  "OJ-04-0067": "It is not allowed to use a coupon for this booking.",
  "OJ-04-0068": "The booking has used coupon.",
  "OJ-04-0070": "The submitted coupon number is invalid.",
  "OJ-04-0071": "The submitted coupon date is invalid.",
  "OJ-04-0072": "Failed to get coupon search result.",
  "OJ-04-0073": "Coupon status is not available.",
  "OJ-04-0074": "Failed to query coupon status.",
  "OJ-04-0075": "Failed to use coupons.",
  "OJ-04-0076": "Invalid payment option.",
  "OJ-04-0077": "Hotel price check error.",
  "OJ-04-0081": "Get bank offer result error.",
  "OJ-04-0082": "It is not allowed to use a bank offer for this booking.",
  "OJ-04-0083": "It is allow use once bank offer for a booking.",
  "OJ-04-0084": "This booking has been used for bank offer.",
  "OJ-04-0085": "Selected bank offer without stock.",
  "OJ-04-0086": "Use bank offer failed.",
  "OJ-04-0087": "The cabin is sold out.",
  "OJ-04-0089": "The hotel is sold out.",
  "OJ-04-0090": "Wrong order of removing baggage.",
  "OJ-04-0091": "Failed to save blacklist token.",
  "OJ-04-0092":
    "We're sorry, there are no rooms available for the dates you have requested.",

  "OJ-04-0100": "Failed to create flight passenger.",
  "OJ-04-0101": "Failed to delete flight passenger.",
  "OJ-04-0103": "Failed to convert string type of date to LocalDate type.",
  "OJ-04-0104": "Failed to get flight passenger.",

  // receipt Itinerary error
  "OJ-04-0207": "The receipt itinerary address already exists.",
  "OJ-04-0208": "External flight booking is not supported.",
  "OJ-04-0209": "Check the receipt delivery information.",

  "OJ-04-0308": "Failed to retrieve user information by username.",
  "OJ-04-0309": "Failed to create new user account.",
  "OJ-04-0310": "Failed to update password.",
  "OJ-04-0311": "Customer has already authenticated FFP.",
  "OJ-04-0312": "Failed to authenticate FFP.",
  "OJ-04-0313": "Failed to change security question.",
  "OJ-04-0314": "Failed to search coupon result.",
  "OJ-04-0315": "Failed to cancel the order, the order status is error.",
  "OJ-04-0316": "Booking end date cannot be empty or in the future.",
  "OJ-04-0317":
    "The booking end date cannot be a date before the booking start date.",
  "OJ-04-0318": "The booking date range cannot exceed {0} days.",
  "OJ-04-0320": "Invalid ticket number.",
  "OJ-04-0321": "Failed to modify document id.",
  "OJ-04-0322": "Unsupported modify document id.",

  "OJ-04-0350": "These ancillaries are not allowed to refund.",
  "OJ-04-0351":
    "Currently ancillaries cancellation with different product type are not allowed.",
  "OJ-04-0352": "Failed to refund the ancillary products.",
  "OJ-04-0353":
    "Multiple products cancellation does not supported except ancillary products.",
  "OJ-04-0354": "Failed to retrieve cancellation cost of ancillary products.",
  "OJ-04-0355": "Failed to refund the insurance products.",
  "OJ-04-0356":
    "Ancillary products and insurance products cannot be refund together.",
  "OJ-04-0357":
    "The unique identifier of the cancellation cost cannot be empty for refund ancillary products.",
  "OJ-04-0358":
    "The unique identifier of the flight refund comments cannot be empty for refund ancillary products.",
  "OJ-04-0359": "The refund application information cannot be empty.",
  "OJ-04-0360":
    "I'm sorry, but there are a lot of bookings on flights at present,Please try again later or contact XXXXX customer service for tickets.",
  "OJ-04-0361": "Non-registered users cannot search historical booking.",
  "OJ-04-0362":
    "Another agent ( at ) has modified this booking since you retrieved it. Ignore, check itinerary and start changes again.",
  "OJ-04-0435":
    "Do not fill in the refund information when you return the insurance.",
  "OJ-04-0436": "There is an insurance with incorrect status in the order.",
  "OJ-04-0437":
    "Do not fill in the cancellation cost when you return the insurance.",

  "OJ-04-0400":
    "We apologise that date change is only allowed for domestic booking.",
  "OJ-04-0401": "Invalid product.",
  "OJ-04-0402":
    "Date Change in progress, please do not submit the request again.",
  "OJ-04-0403":
    "Only one Date Change allowed per passenger, the passenger has already been Date Changed.",
  "OJ-04-0405": "Your order is multiCity, please contact airline.",
  "OJ-04-0406": "Passenger mix selected is not valid.",
  "OJ-04-0407":
    "The state does not meet the date change conditions, please contact customer service for processing.",
  "OJ-04-0410":
    "When you apply date change or full cancel, please cancel or apply refund for the related AAM products first and then continue the operation.",
  "OJ-04-0411": "The cabin cannot be date changed.",
  "OJ-04-0412": "Invalid Flight Segment Submitted.",
  "OJ-04-0088":
    "Failed to application error refund, reasonType field should be 'OTHER'.",

  "OJ-04-0413": "Invalid Product Selected.",
  "OJ-04-0414": "Invalid Open Ticket Application request data.",
  "OJ-04-0415": "Invalid selected customer(s).",
  "OJ-04-0416": "Class not eligible for Open Ticket.",
  "OJ-04-0417": "Already marked as Open Ticket.",
  "OJ-04-0418": "The ticket status is unavailable.",
  "OJ-04-0419": "Cannot do Open Ticket in this state.",
  "OJ-04-0420": "No adult selected.",
  "OJ-04-0421": "Infant must be accompanied by adult.",
  "OJ-04-0422": "Child must travel with adult.",
  "OJ-04-0423": "Sorry, the order has already been modified with Reshop.",
  "OJ-04-0424": "Selected passenger(s) have not been ticketed.",
  "OJ-04-0425": "The Split PNR Process failed.",
  "OJ-04-0426": "The Open Ticket process failed.",
  "OJ-04-0427": "Unable to change flight segment status to Open Ticket.",
  "OJ-04-0430": "Free date change failed initial ticketing.",
  "OJ-04-0431": "Invalid booking for paid upgrade confirmation.",
  "OJ-04-0432":
    "Insurance has not purchased successfully, please try again later to give up travel.",
  "OJ-04-0434":
    "Sorry, the route you selected does not meet the conditions for re-shop. Please try another one.",
  "OJ-04-0438": "Cost free seat failed initial ticketing.",
  "OJ-04-0439": "Cancellation has failed",

  // Baggage Error Codes
  "OJ-04-0501": "Failed to repeat buy baggage.",
  "OJ-04-0505": "Check submitted result id,not found it from avail results.",
  "OJ-04-0506": "Add baggage for an open ticket flight is not allowed.",
  "OJ-04-0512": "Invalid product number.",
  "OJ-04-0513": "Invalid flight status to buy baggage product.",
  "OJ-04-0514": "Invalid ticketing status for the product booking rules.",
  "OJ-04-0515":
    "Price option with submitted price id not found in the result set.",
  "OJ-04-0516":
    "Price option with submitted price id not available for customer.",

  // Ancillary error codes
  "OJ-04-0520": "Invalid result id.",
  "OJ-04-0521": "Repeated the selection is not allowed.",
  "OJ-04-0522": "Some customer ID is not found in the air product.",
  "OJ-04-0524": "The same type of special service request is already booked.",
  "OJ-04-0525": "Invalid product id.",
  "OJ-04-0526": "Invalid special request id.",
  "OJ-04-0527": "Invalid booking status.",
  "OJ-04-0530": "There is no valid flight segment for meal searching.",
  "OJ-04-0532":
    "Beyond the maximum number of ancillaries of this type allowed in the booking for one passenger.",
  "OJ-04-0533": "The requested quantity more than inventory.",
  "OJ-04-0534":
    "More than one same ancillary is found in the booking for one passenger.",
  "OJ-04-0535": "Invalid ticketing status for ancillary reservation.",
  "OJ-04-0536": "Current passenger has already booked free meal.",
  "OJ-04-0537": "The booking status of air product is invalid.",
  "OJ-04-0538": "Chargeable meal and free meal can't be booked together.",
  "OJ-04-0539":
    "There are multiple air products that add pending booking ancillary products at the same time.",

  // External Flight booking
  "OJ-04-0600": "Not open all channel selection function.",
  "OJ-04-0601":
    "Failed to extract face information. For domestic tickets please click on the id card and international ticket please click the passport number. Please check if you have completed correctly.",
  "OJ-04-0602": "Enter the name does not accord with coupon information.",
  "OJ-04-0603":
    "The ticket for the determination of travel dates and flights, can not choose seat.",
  "OJ-04-0604": "Not found eligible for prepaid purchase schedule.",
  "OJ-04-0605": "Airline ticket order not found.",
  "OJ-04-0606": "The ticket is not suitable for seat selection.",
  "OJ-04-0607":
    "Failed to load the order, try it later! For domestic tickets please click on the id card and international ticket please click the passport number. Please check if you have completed correctly.",
  "OJ-04-0608": "Failed to import the order.",
  "OJ-04-0609": "The impotted order information is empty.",
  "OJ-04-0610": "Failed to load the order information.",
  "OJ-04-0611": "No order information is found.",
  "OJ-04-0612": "Order information is empty.",
  "OJ-04-0613": "This PNR not exist in the order.",
  "OJ-04-0614": "Supplier code should be specified only for external PNRs.",
  "OJ-04-0615": "Supplier code should be null or not empty for external PNRs.",
  "OJ-04-0616": "Currency code should be specified only for external PNRs.",
  "OJ-04-0617": "Currency code should be null or not empty.",

  // Insurance
  "OJ-04-0801": "The passenger:{0} is not allowed to book this insurance.",
  "OJ-04-0802": "Insurance not found.",
  "OJ-04-0803": "No available insurance found.",
  "OJ-04-0804":
    "The insurance quantity selected is not allowed for passenger:{0} and result ID:{1}.",
  "OJ-04-0805": "Invalid insurance id:{0} is existed.",
  "OJ-04-0806": "Duplicate customer ID:{0} and result ID:{1}.",
  "OJ-04-0807":
    "The search result of insurance has expired, please search again.",
  "OJ-04-0808":
    "Sorry, the passenger is not old enough to purchase travel insurance.",
  "OJ-04-0809":
    "The input insurance amount is not equal to the calculated insurance amount.",
  "OJ-04-0810": "There's already been insurance of the same type.",
  "OJ-04-0811": "The end date of this insurance cannot be blank.",
  "OJ-04-0812": "End date of the insurance is invalid.",
  "OJ-04-0813": "The insurance amount cannot be empty.",
  "OJ-04-0814": "The insurance mailbox cannot be empty.",
  "OJ-04-0815":
    "Sorry, you do not meet the conditions of purchasing insurance. International insurance requires at least one passenger to be at least 18 years old.",
  "OJ-04-0816": "Please remove insurance: {0} in shopping car.",
  "OJ-04-0817": "The infant cannot buy insurance.",
  "OJ-04-0818":
    "Insurance has not purchased successfully, please try again later to give up travel.",

  // ancillary common validation
  "OJ-04-0650": "There are no valid air products to process.",
  "OJ-04-0651":
    "It is invalid that all flight segments are open ticket or status are no.",
  "OJ-04-0652":
    "There should be at least one ticket whose status is OPEN FOR USE.",
  "OJ-04-0653": "There are some pending payment booking.",
  "OJ-04-0654": "All air ticketing should issued.",
  "OJ-04-0655": "Pending ticketing ancillary product exists in the booking.",
  "OJ-04-0656": "Ticket status of relative air segment should be OPEN FOR USE.",
  "OJ-04-0657":
    "The status of relative air segment should not be 'Open' or 'No'.",
  "OJ-04-0659":
    "You have to import this external booking again for ancillary products booking.",
  "OJ-04-0660":
    "It is no valid to book ancillary products for more than one air product simultaneously.",
  "OJ-04-0661":
    "It is invalid to add seats and other ancillary products into shopping cart together.",

  // xDistributor error mapping
  "OJ-05-0001": "Invalid tickets associated with this product.",
  "OJ-05-0002": "An error occurred retrieving the ticket information.",
  "OJ-05-0003": "A ticket in this product is not eligible for refund.",
  "OJ-05-0004": "You can't cancel flight because of unacommpanied minor.",
  "OJ-05-0005":
    "You can't cancel flight because the number of Infants is greater than number of Adults.",
  "OJ-05-0006": "Flight cannot be cancelled this close to departure time.",
  "OJ-05-0007": "Ticket is not eligible for cancellation.",

  // XSS defender interceptor error codes
  "OJ-04-0662": "Request illegal, intercepted.",

  // external import error Codes
  "OJ-04-0700": "Failed to search your order,please try again later.",
  "OJ-04-0701": "Failed to retrieve your ticket.",
  "OJ-04-0702":
    "The passenger name in the ticket does not match with the input one.",
  "OJ-04-0704": "Failed to retrieve PNR by ticket.",
  "OJ-04-0705": "There are no available legs(OPEN ticket).",
  "OJ-04-0706": "Failed to retrieve PNR.",
  "OJ-04-0707": "There are no available legs in the PNR of the ticket.",
  "OJ-04-0708": "Failed to retrieve history information of the ticket.",
  "OJ-04-0709": "Group tickets are not supported.",
  "OJ-04-0710": "Unsupported passenger type(unaccompanied children,infants).",
  "OJ-04-0711": "The certificate number is null.",
  "OJ-04-0712":
    "International tickets are not supported temporarily for abnormal flight change.",
  "OJ-04-0713": "There is no IRR remark in the ticket.",
  "OJ-04-0714":
    "The certificate number in the PNR does not match with the input one.",
  "OJ-04-0717":
    "The International sites do not support refund and upgrade services.",
  "OJ-04-0719": "Passenger Surname Format Error.",
  "OJ-04-0720": "Ticket Number Format Error.",
  "OJ-04-0721":
    "The input certificate number does not match with the input passenger name.",
  "OJ-04-0722":
    "It does not support create a temp order for the external booking by platform.",
  "OJ-04-0723":
    "To extract an order by ticket number, you need to provide the name and certificate number. To extract an order by certificate number, you can not provide these two information.",
  "OJ-04-0725": "External flight import parameter is null.",
  "OJ-04-0726": "Invalid parameter for external flight import.",
};

export default errorCodes;
