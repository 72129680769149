import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from "connected-react-router";

import MediaContextProvider from "contexts/MediaContext";
import ThemeContextProvider from "contexts/ThemeContext";
import BookingFlowLoadingIndicator from "BookingFlow/components/BookingFlowLoadingIndicator";
import store, { persistor, history } from "store/store";

function Providers({ children }) {
  return (
    <ReduxProvider store={store}>
      <PersistGate
        loading={<BookingFlowLoadingIndicator />}
        persistor={persistor}
      >
        <MediaContextProvider>
          <ThemeContextProvider>
            <HelmetProvider>
              <ConnectedRouter history={history}>{children}</ConnectedRouter>
            </HelmetProvider>
          </ThemeContextProvider>
        </MediaContextProvider>
      </PersistGate>
    </ReduxProvider>
  );
}

export default Providers;
