import React from "react";
import flow from "lodash/fp/flow";

import { useTranslation } from "hooks";
import { HorizontalScrollView } from "Profile/components";
import { SignInModal } from "Authentication";
import AmenitiesIcon from "./AmenitiesIcon";
import TransportationIcon from "./TransportationIcon";

function ItineraryAction({
  href,
  children,
  enableSignInModal = false,
  ...props
}) {
  if (href) {
    return (
      <a href={href} className="itinerary-action ty-c4" {...props}>
        {children}
      </a>
    );
  }
  if (enableSignInModal) {
    return (
      <SignInModal
        id={props?.id}
        onClick={props?.onClick}
        className="itinerary-action ty-c4"
        type="CustomButton"
        ariaLabel={props?.["aria-label"]}
      >
        {children}
      </SignInModal>
    );
  }
  return (
    <button type="button" className="itinerary-action ty-c4" {...props}>
      {children}
    </button>
  );
}

export default function BuildYourItinerary({
  goToArrivalTransportationView,
  goToArrivalAmenitiesView,
  itineraryQuickLinks,
  dispatchAnalyticsEvent,
  isUnauthenticated,
}) {
  const { t } = useTranslation();

  return (
    <div className="build-your-itinerary">
      <div className="build-your-itinerary__container">
        <h4 className="ty-h4">{t("Build your Itinerary")}</h4>

        <div className="build-your-itinerary__actions-bar">
          <HorizontalScrollView>
            {goToArrivalTransportationView && (
              <ItineraryAction
                onClick={flow(
                  dispatchAnalyticsEvent("arrival transportation"),
                  goToArrivalTransportationView
                )}
                enableSignInModal={!isUnauthenticated}
                id="arrivalTransportation"
                aria-label={t("Arrival Transportation")}
              >
                <TransportationIcon />
                {t("Arrival Transportation")}
              </ItineraryAction>
            )}

            {goToArrivalAmenitiesView && (
              <ItineraryAction
                onClick={flow(
                  dispatchAnalyticsEvent("arrival amenities"),
                  goToArrivalAmenitiesView
                )}
                enableSignInModal={!isUnauthenticated}
                id="arrivalAmenities"
                aria-label={t("Arrival Amenities")}
              >
                <AmenitiesIcon />
                {t("Arrival Amenities")}
              </ItineraryAction>
            )}

            {itineraryQuickLinks.map(({ title, icon, href, onClick }) => {
              return (
                <ItineraryAction
                  href={href}
                  key={title}
                  onClick={onClick}
                  aria-label={title?.html || title}
                >
                  {icon}
                  {title?.html ? (
                    <span dangerouslySetInnerHTML={{ __html: title.html }} />
                  ) : (
                    title
                  )}
                </ItineraryAction>
              );
            })}
          </HorizontalScrollView>
        </div>
      </div>
    </div>
  );
}
