import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { useTranslation, usePrevious } from "hooks";
import { Button, TextInput } from "Profile/components";

export default function GuestForm({
  name,
  index,
  roomGuests,
  onSubmit,
  showSavingSpinner,
  close,
}) {
  const { t } = useTranslation();
  const prevShowSavingSpinner = usePrevious(showSavingSpinner);

  const { register, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: { roomGuests },
  });

  useEffect(() => {
    if (prevShowSavingSpinner && !showSavingSpinner) {
      close();
    }
  }, [prevShowSavingSpinner, showSavingSpinner]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        identifier={`${name}:${index}`}
        name={`roomGuests[${index}].name`}
        label={t("Name")}
        register={register}
      />
      <div className="fs-trip-details__checkin-action">
        <Button type="submit">{t("Update")}</Button>
      </div>
    </form>
  );
}
