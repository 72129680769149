import getOr from "lodash/fp/getOr";
import flow from "lodash/fp/flow";
import get from "lodash/fp/get";
import map from "lodash/fp/map";
import min from "lodash/fp/min";
import { parseISO, format, isSameMonth } from "date-fns";
import * as dateFnLocales from "date-fns/locale";

import { getDateFnsLocale } from "config/languages";
import { EMPLOYEE_SEARCH_ERRORS } from "./checkEmployeeSearchParams";
import { LANGUAGE_SUPPORT_ERROR } from "./checkLanguageSupport";

const RESULT_NOT_AVAILABLE = "N/A";

export const selectSearchResults = (state) => {
  return state.searchResults.data[state.searchResults.resultSetId];
};

export const selectIsAllInPricingResults = ({ searchResults }) =>
  searchResults.isAllInPricingResults;

export const selectHasSearchResults = (state) =>
  (selectSearchResults(state) || {})?.totalResults > 0;

export const selectSearchResultsApiErrors = (state) =>
  state.searchResults.apiErrors;

export const selectSearchResultsSupplierErrors = (state) =>
  state.searchResults.supplierErrors;

export const isEmployeeSearchError = (error) =>
  Object.values(EMPLOYEE_SEARCH_ERRORS).includes(error.errorCode);

export const isNotEmployeeSearchError = (error) =>
  !isEmployeeSearchError(error);

export const isLanguageSupportError = (error) =>
  error.errorCode === LANGUAGE_SUPPORT_ERROR;

export const selectSearchResultErrors = (state) =>
  state.searchResults.errors.filter(isNotEmployeeSearchError);

export const selectSearchResultError = (state) =>
  selectSearchResultErrors(state)[0];

export const selectLanguageSupportErrors = (state) =>
  state.searchResults.errors.filter(isLanguageSupportError);

export const selectLanguageSupportError = (state) =>
  selectLanguageSupportErrors(state)[0];

export const selectEmployeeSearchErrors = (state) =>
  state.searchResults.errors.filter(isEmployeeSearchError);

export const selectSearchResultsErrors = (state) => state.searchResults.errors;

export const selectSearchResultsError = (state) =>
  selectSearchResultsErrors(state)[0];

export const selectEmployeeSearchError = (state) =>
  selectEmployeeSearchErrors(state)[0];

export const selectSearchResultTRetailErrors = (state) => {
  return {
    apiErrors: state.searchResults.apiErrors,
    supplierErrors: state.searchResults.supplierErrors,
  };
};

export const selectHasAnyTRetailErrors = ({
  searchResults: { apiErrors, supplierErrors, errors } = {},
}) => [apiErrors, supplierErrors, errors].some((a) => a.length > 0);

export const selectMissingLanguageError = ({ searchResults }) =>
  searchResults.missingLanguageError;

export const selectOccupancyError = ({ searchResults }) =>
  searchResults.occupancyError;

export const selectPlainSearchParams = getOr("", [
  "searchResults",
  "plainSearchParams",
]);

export const selectResultSetId = (state) => {
  return state.searchResults.resultSetId;
};

function getDateRange(checkinDate, checkoutDate, locale = "en") {
  return [checkinDate, checkoutDate]
    .map(parseISO)
    .reduce((acc, date) => {
      return [
        ...acc,
        {
          date,
          formatStr: isSameMonth(acc[acc.length - 1]?.date, date)
            ? "d"
            : "MMM d",
        },
      ];
    }, [])
    .map(({ date, formatStr }) =>
      format(date, formatStr, {
        locale: dateFnLocales[getDateFnsLocale(locale)],
      })
    )
    .join(" - ");
}

export const selectFlexDates =
  ({
    currencyCode,
    isAccessible = false,
    enCountry,
    optInUsAccessibleRooms = false,
  }) =>
  (state) => {
    const exchangeRatesByCurrencyCode = state.exchangeRates.data.reduce(
      (acc, { code, rate }) => ({ ...acc, [code]: parseFloat(rate) }),
      {}
    );

    const { hotelSearchResultSets = [], flexDatesCalendarPrices = [] } =
      state?.searchResults?.createHotelSearchResponse;

    const resultsCurrencyCode =
      get([0, "bestAvailableRateCurrencyCode"])(flexDatesCalendarPrices) ||
      "USD";

    const convertToUSDExchangeRate =
      exchangeRatesByCurrencyCode[resultsCurrencyCode] || 1;

    const exchangeRate = exchangeRatesByCurrencyCode[currencyCode] || 1;

    const priceConvertedFlexDatesCalendarPrices = flexDatesCalendarPrices.map(
      (flexDatesCalendarPrice) => {
        const bestAvailableRatePerNightInclAccessible = parseFloat(
          flexDatesCalendarPrice.bestAvailableRatePerNightInclAccessible
        );

        const bestAvailableRatePerNightExclAccessible = parseFloat(
          flexDatesCalendarPrice.bestAvailableRatePerNightExclAccessible
        );

        // Use Best Rate Excluding Accessible Rooms when:
        // Accessible Rooms filter unchecked
        // and
        // Best Rate Including Accessible Rooms is less than Best Rate Excluding Accessible Rooms
        // and
        // USA Property or Property opted in to USA Accessible Room logic  (property.enCountry === 'U.S.A.' || property.optInUsAccessibleRooms === true)
        // ELSE
        // Use Best Rate Including Accessible Rooms in all other cases
        const bestAvailableRatePerNight =
          !isAccessible &&
          bestAvailableRatePerNightInclAccessible <
            bestAvailableRatePerNightExclAccessible &&
          (enCountry === "U.S.A." || optInUsAccessibleRooms)
            ? bestAvailableRatePerNightExclAccessible
            : bestAvailableRatePerNightInclAccessible;

        return {
          ...flexDatesCalendarPrice,
          bestAvailableRatePerNight: Math.round(
            (bestAvailableRatePerNight / convertToUSDExchangeRate) *
              exchangeRate
          ),
          bestAvailableRateCurrencyCode: currencyCode,
        };
      }
    );

    const flexDatesCalendarPricesByCheckInDate =
      priceConvertedFlexDatesCalendarPrices.reduce(
        (acc, curr) => ({ ...acc, [curr.checkinDate]: curr }),
        {}
      );

    const lowestRate = flow(
      map(get("bestAvailableRatePerNight")),
      min
    )(priceConvertedFlexDatesCalendarPrices);

    return hotelSearchResultSets.map((hotelSearchResult) => {
      const flexDatesCalendarPrice =
        flexDatesCalendarPricesByCheckInDate[hotelSearchResult.checkinDate] ||
        {};

      return {
        ...hotelSearchResult,
        bestAvailableRatePerNight:
          hotelSearchResult.resultSetId !== RESULT_NOT_AVAILABLE
            ? flexDatesCalendarPrice.bestAvailableRatePerNight
            : undefined,
        bestAvailableRateCurrencyCode:
          flexDatesCalendarPrice.bestAvailableRateCurrencyCode,
        isBestRate:
          flexDatesCalendarPrice?.bestAvailableRatePerNight === lowestRate,
        dateRange: getDateRange(
          hotelSearchResult.checkinDate,
          hotelSearchResult.checkoutDate,
          state.app.locale
        ),
        isDisabled: hotelSearchResult.resultSetId === RESULT_NOT_AVAILABLE,
      };
    });
  };
