export const selectPreBookingComments =
  (bookingId) =>
  ({ bookingComments }) =>
    bookingComments.preBookingComments[bookingId];

export const selectPostBookingComments =
  ({ reservationId }) =>
  ({ bookingComments }) => {
    return bookingComments.byReservationId[reservationId] || {};
  };

export const selectComments =
  ({ reservationId, productId }) =>
  ({ bookingComments }) => {
    return (
      bookingComments.byReservationId[reservationId]?.comments?.find(
        (comment) => comment.productId === productId
      ) || {}
    );
  };

export const selectTransportation =
  ({ reservationId, productId }) =>
  (state) => {
    const transportationRequests =
      selectComments({ reservationId, productId })(state)
        ?.transportationRequests || {};

    return {
      ...transportationRequests,
      ...(transportationRequests?.vehicleRequest
        ? {
            vehicleRequest: {
              ...transportationRequests.vehicleRequest,
              optionLabel: [
                transportationRequests?.vehicleRequest?.type,
                transportationRequests?.vehicleRequest?.description,
              ]
                .filter(Boolean)
                .join(" - "),
            },
          }
        : {}),
    };
  };

export const selectRoomGuests =
  ({ reservationId, productId }) =>
  (state) => {
    return (
      selectComments({ reservationId, productId })(state)?.roomGuests || []
    );
  };

export const selectBookingCommentsApiErrors = (state) =>
  state.bookingComments.apiErrors;

export const selectBookingCommentsSupplierErrors = (state) =>
  state.bookingComments.supplierErrors;

export const selectBookingCommentsError = (state) => {
  return (
    selectBookingCommentsApiErrors(state)[0] ||
    selectBookingCommentsSupplierErrors(state)[0]
  );
};
