import { getCookieValue } from "./cookies";

export function getGeoIPCode() {
  return (getCookieValue("GeoIPCookie") || "").split("=")[1];
}

export function isInGB() {
  return getGeoIPCode() === "GB";
}

export function isInChina() {
  return getGeoIPCode() === "CN";
}

export function isInRussia() {
  return getGeoIPCode() === "RU";
}
