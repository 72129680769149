import React, { useContext, useState, useEffect } from "react";
// import PropTypes from "prop-types";
import classNames from "classnames";

import { ThemeContext } from "contexts/ThemeContext";
import { generateNumbersList } from "utils/utils";
import Dropdown from "../Dropdown";

export default function ExpiryDateInput({
  identifier,
  label,
  name,
  value = "",
  error = "",
  disabled = false,
  readOnly = false,
  required = false,
  requiredIndicator = "*",
  onChange = () => {},
}) {
  const [initialYear, initialMonth] = value.split("-");
  const { theme } = useContext(ThemeContext);
  const [month, setMonth] = useState(initialMonth || "");
  const [year, setYear] = useState(initialYear || "");

  const id = identifier || `input-${name}`;
  const hasError = typeof error === "boolean" || error.length > 0;

  const months = generateNumbersList(12).map((a) =>
    a < 10 ? `0${a}` : `${a}`
  );
  const years = generateNumbersList(21, 2020).map(String);

  useEffect(() => {
    if (month && year) {
      onChange(`${year}-${month}`);
    } else {
      onChange("");
    }
  }, [month, year]);

  return (
    <div
      className={classNames("fsp-input fsp-input--expiry-date", {
        "fsp-input--dark": theme === "dark",
        "formElement-field--invalid": hasError,
      })}
    >
      {label && (
        <label
          htmlFor={`${id}-month`}
          className="formElement-label formElement--focusAlways ty-c4"
        >
          {label}
          {required && requiredIndicator}
        </label>
      )}

      <div className="row">
        <div className="col-md-6">
          <Dropdown
            identifier={`${id}-month`}
            name={`${id}-month`}
            options={months}
            disabled={disabled}
            readOnly={readOnly}
            required={required}
            value={month}
            onChange={({ target }) => setMonth(target.value)}
          />
        </div>
        <div className="col-md-6">
          <Dropdown
            identifier={`${id}-year`}
            name={`${id}-year`}
            options={years}
            disabled={disabled}
            readOnly={readOnly}
            required={required}
            value={year}
            onChange={({ target }) => setYear(target.value)}
          />
        </div>
      </div>

      {hasError && typeof error === "string" && (
        <span className="formElement-message" role="alert">
          {error}
        </span>
      )}
    </div>
  );
}
