import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";

import * as regexPatterns from "utils/regexPatterns";
import { useDispatchWithLocale, useTranslation } from "hooks";
import Button from "Profile/components/Button";
import Modal from "Profile/ProfileView/Modal";
import {
  createEmailVerifyCode,
  verifyEmailCode,
  verifyEmailCodeClear,
  selectVerifyYourEmailError,
} from "store/profile";
import {
  selectIsRequestFulfilled,
  selectIsRequestLoading,
} from "store/apiRequestStates";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import IntroContent from "Authentication/components/OtpForm/IntroContent";
import OtpInput from "Authentication/components/OtpInput";
import OtpError from "Authentication/components/OtpForm/OtpError";

export default function EmailVerificationModal({ email, close }) {
  const { t } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();

  const [isSuccessModalVisble, setIsSuccessModalVisble] = useState(false);

  const closeSuccessModal = () => {
    dispatchWithLocale(verifyEmailCodeClear());
    close();
  };

  const reset = () => {
    dispatchWithLocale(createEmailVerifyCode({ email }));
  };

  const onSubmit = (formValue) =>
    dispatchWithLocale(verifyEmailCode({ email, ...formValue }));

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      password: "",
    },
  });

  const wasVerifyEmailCodeFulfilled = useSelector(
    selectIsRequestFulfilled(verifyEmailCode)
  );

  const showLoadingIndicator = useSelector(
    selectIsRequestLoading(verifyEmailCode.type)
  );

  const verifyYourEmailError = useSelector(selectVerifyYourEmailError);

  useEffect(() => {
    if (wasVerifyEmailCodeFulfilled) {
      setIsSuccessModalVisble(true);
    }
  }, [wasVerifyEmailCodeFulfilled]);

  return (
    <Modal
      close={close}
      allowOutsideClickToClose={false}
      allowEscapeKeyToClose={false}
    >
      {showLoadingIndicator && <BookingFlowLoadingIndicator />}

      <div className="fs-profile-modal">
        {verifyYourEmailError && (
          <OtpError errorCode={verifyYourEmailError.errorCode} reset={reset} />
        )}

        {!verifyYourEmailError && isSuccessModalVisble && (
          <>
            <div className="tick-animation tick-animation--start">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 130.2 130.2"
              >
                <circle
                  className="path circle"
                  fill="none"
                  stroke="#73AF55"
                  strokeWidth="6"
                  strokeMiterlimit="10"
                  cx="65.1"
                  cy="65.1"
                  r="62.1"
                />
                <polyline
                  className="path check"
                  fill="none"
                  stroke="#73AF55"
                  strokeWidth="6"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  points="29.8,67.5 51.5,88.8 100.2,40.2"
                />
              </svg>
            </div>
            <p className="ty-h3">{t("Email verified")}</p>
            <button
              type="button"
              className="fs-profile-modal__close"
              aria-label={t("Close")}
              onClick={closeSuccessModal}
            >
              &times;
            </button>
          </>
        )}

        {!verifyYourEmailError && !isSuccessModalVisble && (
          <>
            <h1>{t("Verify Your Email")}</h1>
            <p>
              <IntroContent messageType="short" sendCodeTo={email} />
            </p>
            <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              autoComplete="off"
            >
              <Controller
                control={control}
                name="password"
                rules={{
                  required: true,
                  minLength: 6,
                  maxLength: 6,
                }}
                render={({ ref: _ignoredRef, ...renderProps }) => (
                  <OtpInput
                    pattern={regexPatterns.onlyDigits}
                    {...renderProps}
                  />
                )}
              />

              <div className="fs-profile-modal__form-actions">
                <Button kind="link" onClick={close}>
                  {t("Cancel")}
                </Button>
                <Button type="submit" disabled={!isValid}>
                  {t("Verify")}
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
}
