import React, { useContext } from "react";
import classNames from "classnames";

import { MediaContext } from "contexts/MediaContext";
import { useTranslation } from "hooks";
import Month from "BookingFlow/PlanYourStay/DateRangePicker/Month";
import NavButton from "BookingFlow/PlanYourStay/DateRangePicker/NavButton";
import DatepickerContext from "BookingFlow/PlanYourStay/DateRangePicker/datepickerContext";
import SmallScreenSelectDate from "BookingFlow/PlanYourStay/DateRangePicker/SmallScreenSelectDate";
import useCalendar from "BookingFlow/PlanYourStay/hooks/useCalendar";
import {
  getCalendarMonthLabel,
  getWeekdayLabelFormatter,
} from "BookingFlow/PlanYourStay/DateRangePicker/calendarHelpers";

export default function DateRangePicker({
  checkInWrapperClassName = "",
  checkOutWrapperClassName = "",
  checkInLabel,
  checkOutLabel,
  availabilityEnabled,
  availabilityLastUpdated,
  checkIn,
  checkInEnd,
  checkInStart,
  checkOut,
  checkOutEnd,
  hasCheckInError,
  hasCheckOutError,
  hasUserSelectedCheckIn,
  maxNumberOfNights,
  minNumberOfNights,
  nextControlOutsideWidget,
  onChange,
  restrictedDates,
  setCheckIn,
  setCheckOut,
  setHasUserSelectedCheckIn,
  unavailableDates,
}) {
  const { t, locale } = useTranslation();

  const media = useContext(MediaContext);

  const {
    activeMonths,
    availabilityLastUpdatedDescription,
    canNavigateToNextMonth,
    canNavigateToPreviousMonth,
    checkType,
    componentOnBlur,
    componentRef,
    endDate,
    endDateInputRef,
    firstMonth,
    focusedDate,
    formatedEndDate,
    formatedStartDate,
    goToNextMonths,
    goToPreviousMonths,
    handleEndDateOnClick,
    handleEndDateOnFocus,
    handleNavigatingAwayFromCalendar,
    handleNavigatingAwayFromEndDateInput,
    handleNavigatingAwayFromNextMonth,
    handleNavigatingAwayFromPrevMonth,
    handleNavigatingAwayFromStartDateInput,
    handleOnMouseDown,
    handleStartDateOnClick,
    handleStartDateOnFocus,
    firstDayOfWeek,
    isCalendarVisible,
    isDateAvailable,
    isDateBlocked,
    isDateFocused,
    isDateHovered,
    isDateSelected,
    isEndDate,
    isFirstOrLastSelectedDate,
    isRestricted,
    isStartDate,
    isUnselectable,
    isOutsideCheckInRange,
    nextMonthRef,
    numberOfSelectedDays,
    onDateFocus,
    onDateSelect,
    prevMonthRef,
    startDate,
    startDateInputRef,
    validInterval,
    includeDaysFromOtherMonths,
    weekdayLabelFormatter,
  } = useCalendar({
    availabilityEnabled,
    availabilityLastUpdated,
    checkIn,
    checkInEnd,
    checkInStart,
    checkOut,
    checkOutEnd,
    hasCheckInError,
    hasCheckOutError,
    hasUserSelectedCheckIn,
    locale,
    maxNumberOfNights,
    minNumberOfNights,
    nextControlOutsideWidget,
    onChange,
    restrictedDates,
    setCheckIn,
    setCheckOut,
    setHasUserSelectedCheckIn,
    unavailableDates,
    weekdayLabelFormatter: getWeekdayLabelFormatter(locale),
  });

  return (
    <div ref={componentRef} onBlur={componentOnBlur}>
      <div
        className={classNames(`datepicker checking-which-${checkType}`, {
          "calendar-open": isCalendarVisible,
        })}
      >
        {media.isLessThan.sm ? (
          <>
            <SmallScreenSelectDate
              date={startDate}
              locale={locale}
              onClick={handleStartDateOnClick}
              isCheckIn
              label={checkInLabel || t("Check In:")}
            />
            <SmallScreenSelectDate
              date={endDate}
              locale={locale}
              onClick={handleEndDateOnClick}
              label={checkOutLabel || t("Check Out:")}
            />
          </>
        ) : (
          <>
            <div className={checkInWrapperClassName}>
              {checkInLabel && (
                <label className="ty-c3" htmlFor="checkin">
                  {checkInLabel}
                </label>
              )}
              <input
                id="checkin"
                type="text"
                className={classNames(
                  "fs-select-date emulated-flex-gap-component",
                  {
                    "search-panel-error": hasCheckInError,
                  }
                )}
                value={formatedStartDate}
                onClick={handleStartDateOnClick}
                onFocus={handleStartDateOnFocus}
                onKeyDown={handleNavigatingAwayFromStartDateInput}
                aria-label={t("Check In")}
                readOnly
                ref={startDateInputRef}
              />
            </div>
            <div className={checkOutWrapperClassName}>
              {checkOutLabel && (
                <label className="ty-c3" htmlFor="checkout">
                  {checkOutLabel}
                </label>
              )}
              <input
                id="checkout"
                type="text"
                className={classNames(
                  "fs-select-date emulated-flex-gap-component",
                  {
                    "search-panel-error": hasCheckOutError,
                  }
                )}
                value={formatedEndDate}
                onClick={handleEndDateOnClick}
                onFocus={handleEndDateOnFocus}
                aria-label={t("Check Out")}
                readOnly
                onKeyDown={handleNavigatingAwayFromEndDateInput}
                ref={endDateInputRef}
              />
            </div>
          </>
        )}
      </div>

      {isCalendarVisible && (
        <DatepickerContext.Provider
          value={{
            focusedDate,
            handleNavigatingAwayFromCalendar,
            handleNavigatingAwayFromNextMonth,
            isDateAvailable,
            isDateBlocked,
            isDateFocused,
            isDateHovered,
            isDateSelected,
            isEndDate,
            isFirstOrLastSelectedDate,
            isRestricted,
            isStartDate,
            isUnselectable,
            isOutsideCheckInRange,
            onDateFocus,
            startDate,
            onDateSelect: (a) => {
              setHasUserSelectedCheckIn(true);
              onDateSelect(a);
            },
            validInterval,
          }}
        >
          <div
            className={`fs-calendar-wrapper ${checkType}`}
            onMouseDown={handleOnMouseDown}
            role="presentation"
          >
            <div className="fs-calendar" role="application">
              <div>
                <div className="ui-datepicker-header">
                  <NavButton
                    onClick={goToPreviousMonths}
                    isEnabled={canNavigateToPreviousMonth}
                    ref={prevMonthRef}
                    handleOnKeyDown={handleNavigatingAwayFromPrevMonth}
                  />
                  <div className="ui-datepicker-title">
                    {getCalendarMonthLabel(firstMonth, locale)}
                  </div>
                  <NavButton
                    direction="right"
                    onClick={goToNextMonths}
                    isEnabled={canNavigateToNextMonth}
                    ref={nextMonthRef}
                    handleOnKeyDown={handleNavigatingAwayFromNextMonth}
                  />
                </div>
                <Month
                  firstDayOfWeek={firstDayOfWeek}
                  month={activeMonths[0]}
                  includeDaysFromOtherMonths={includeDaysFromOtherMonths}
                  weekdayLabelFormatter={weekdayLabelFormatter}
                />
              </div>

              <footer className="datepicker-footer">
                <div className="nights-selected">
                  <span>{numberOfSelectedDays}</span>
                  {t("Nights Selected")}
                </div>
                {availabilityEnabled && (
                  <ul className="legend">
                    <li className="availability" title="No Availability">
                      {t("noavailability")}
                      <span className="is-unavailable">&nbsp;</span>
                    </li>
                    <li
                      className="restrictions"
                      title={t("Restrictions may apply")}
                    >
                      {t("Restrictions may apply")}
                      <span className="is-restricted">&nbsp;</span>
                    </li>
                    <li
                      className="updated-at"
                      title={availabilityLastUpdatedDescription}
                    >
                      {availabilityLastUpdatedDescription}
                    </li>
                  </ul>
                )}
              </footer>
            </div>
          </div>
        </DatepickerContext.Provider>
      )}
    </div>
  );
}
