const HOURS = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
const MINUTES = ["00", "15", "30", "45"];
const MERIDIEMS = ["AM", "PM"];

export default function useArrivalTime({ value, onChange }) {
  const [hour = "", minute = ""] = value.split(":");
  const meridiem = MERIDIEMS[Math.floor(hour / 12) % 2];

  const getHour = (v, isPM) => {
    const newHour = parseInt(v, 10) % 12;
    if (isPM) {
      return `${newHour + 12}`;
    }
    if (newHour < 10) {
      return `0${newHour}`;
    }
    return `${newHour}`;
  };

  const handleChangeHour = ({ target }) => {
    const isPM = meridiem === "PM";
    onChange(`${getHour(target.value, isPM)}:${minute}:00.000`);
  };

  const handleChangeMinute = ({ target }) => {
    onChange(`${hour}:${target.value}:00.000`);
  };

  const handleChangeMeridiem = ({ target }) => {
    const isPM = target.value === "PM";
    onChange(`${getHour(hour, isPM)}:${minute}:00.000`);
  };

  return {
    hour,
    minute,
    meridiem,
    handleChangeHour,
    handleChangeMinute,
    handleChangeMeridiem,
    HOURS,
    MINUTES,
    MERIDIEMS,
  };
}
