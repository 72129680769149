import React from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";
import { ToggleBlock } from "Profile/components";
import { SignInModal } from "Authentication";
import SpecialRequestsForm from "./SpecialRequestsForm";

function SpecialRequest({
  label,
  className = "",
  defaultValues,
  onSubmit,
  fieldName,
  showSavingSpinner,
  isUnauthenticated,
}) {
  const { t } = useTranslation();

  return (
    <div className={classNames("fs-trip__special-requests", className)}>
      <div className="fs-your-preferences__preference fs-your-preferences__preference--special-requests">
        <ToggleBlock
          type="edit"
          label={({ toggle, isOpen, isOpening }) => (
            <div className="fs-toggle-block__label">
              <div className="fs-your-preferences__preference__title">
                <h3>{t(label)}</h3>
              </div>
              {isUnauthenticated ? (
                <button
                  className={classNames(
                    "fs-toggle-button--light fs-toggle-button-light--add",
                    {
                      "fs-toggle-button--light-active": isOpen || isOpening,
                    }
                  )}
                  onClick={toggle}
                  type="button"
                  aria-label={t("add special request")}
                >
                  {defaultValues[fieldName] ? t("Edit") : t("Add")}
                </button>
              ) : (
                <SignInModal
                  id="editSpecialRequests"
                  onClick={toggle}
                  className={classNames(
                    "fs-toggle-button--light fs-toggle-button-light--edit",
                    {
                      "fs-toggle-button--light-active": isOpen || isOpening,
                    }
                  )}
                  type="CustomButton"
                  buttonLabel={defaultValues[fieldName] ? t("Edit") : t("Add")}
                  ariaLabel={t("add special request")}
                />
              )}
            </div>
          )}
        >
          {({ close }) => (
            <SpecialRequestsForm
              fieldName={fieldName}
              defaultValues={defaultValues}
              onSubmit={onSubmit}
              close={close}
              showSavingSpinner={showSavingSpinner}
            />
          )}
        </ToggleBlock>
      </div>

      {defaultValues[fieldName] && (
        <ul className="fs-your-preferences__preference__selected-preferences">
          <li>{defaultValues[fieldName]}</li>
        </ul>
      )}
    </div>
  );
}

export default SpecialRequest;
