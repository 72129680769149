/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { API_REQUEST_STATES } from "store/apiRequestStates/constants";
import {
  setRequestStateRequesting,
  setRequestStateFulfilled,
  setRequestStateFailed,
  setRequestStateCancelled,
} from "store/apiRequestStates/setRequestState";

const initialState = {
  locale: "en",
  isLoading: API_REQUEST_STATES.REQUEST,
  isWebChatEnabled: false,
  availCheckTemplate: "",
  roomFindingMethod: "",
  lastView: "",
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    appStart(state) {
      state.isLoading = setRequestStateRequesting(state.isLoading);
    },
    appStartFulfilled(state) {
      state.isLoading = setRequestStateFulfilled(state.isLoading);
    },
    appStartFailed(state) {
      state.isLoading = setRequestStateFailed(state.isLoading);
    },
    appStartCancel(state) {
      state.isLoading = setRequestStateCancelled(state.isLoading);
    },
    setView(state, { payload }) {
      state.lastView = payload;
    },
    setLanguage(state, { payload: { locale } }) {
      state.locale = locale;
    },

    changeLanguage(state, { payload: { locale } }) {
      state.locale = locale;
    },
    setAvailCheckTemplate(state, { payload: { availCheckTemplate } }) {
      state.availCheckTemplate = availCheckTemplate || state.availCheckTemplate;
    },
    setRoomFindingMethod(state, { payload: { roomFindingMethod } }) {
      state.roomFindingMethod = roomFindingMethod || state.roomFindingMethod;
    },
  },
});

export const {
  appStart,
  appStartCancel,
  appStartFailed,
  appStartFulfilled,
  setView,
  setLanguage,
  changeLanguage,
  setAvailCheckTemplate,
  setRoomFindingMethod,
} = appSlice.actions;

export default appSlice.reducer;
