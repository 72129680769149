import React, { useEffect } from "react";
import { useTranslation } from "hooks";
import { Button } from "Profile/components";
import { differenceInCalendarDays, parse } from "date-fns";
import { useFieldArray } from "react-hook-form";
import { DATA } from "utils/dateFormats";
import RoomDetail from "./RoomDetail";

function RoomDetails({ searchData = {}, formControls, smokeFree }) {
  const { t } = useTranslation();

  const {
    caw: {
      cawConfig: {
        fields: { defaultAdults = 2, defaultChildren = 0, maxRooms = 3 } = {},
      } = {},
    } = {},
    checkIn,
    checkOut,
  } = searchData;
  const { control, register } = formControls;

  const numberOfNights = differenceInCalendarDays(
    parse(checkOut, DATA, new Date()),
    parse(checkIn, DATA, new Date())
  );

  const {
    fields: rooms,
    append,
    remove: deleteRoom,
  } = useFieldArray({
    control,
    name: "rooms",
  });

  const addRoom = (shouldFocus = true) =>
    append(
      {
        totalAdults: defaultAdults,
        totalChildren: defaultChildren,
        smoking: smokeFree ? undefined : false,
      },
      shouldFocus
    );

  useEffect(() => addRoom(false), []);

  const totalNumberOfNights = rooms.length * numberOfNights;

  return (
    <div className="room-details">
      <h3 className="ty-c1 section-header">{t("Room Details")}</h3>
      <h4 className="ty-c4 section-header total-nights">
        {t("Total number of nights: {0}", [totalNumberOfNights])}
      </h4>
      <input
        type="hidden"
        name="totalNights"
        value={totalNumberOfNights}
        ref={register()}
      />

      {rooms.map(({ id, ...formValues }, index) => (
        <RoomDetail
          key={id}
          index={index}
          formNamePrefix={`rooms[${index}]`}
          formControls={formControls}
          formValues={formValues}
          searchData={searchData}
          onDelete={() => deleteRoom(index)}
          includeHeader={rooms.length > 1}
          smokeFree={smokeFree}
        />
      ))}
      <hr />

      <div className="add-room">
        {rooms.length < maxRooms && (
          <Button type="button" kind="link" onClick={() => addRoom()}>
            {t("Add Another Room")}
          </Button>
        )}
      </div>
    </div>
  );
}

export default RoomDetails;
