import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";
import queryString from "query-string";

import env from "config/env";
import { baseGetRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

export default function retrieveMailSubscriptionTemplates$({
  locale,
  subscriptionTypeSfApiName,
  propertyCode,
}) {
  const qsStringified = queryString.stringify({
    subscriptionTypeSfApiName,
    propertyCode,
  });

  return ajax({
    ...baseGetRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/mailSubscriptions/templates?${qsStringified}`,
  }).pipe(pluck("response"));
}
