import { push } from "connected-react-router";
import {
  catchError,
  filter,
  mergeMap,
  map,
  toArray,
  distinct,
} from "rxjs/operators";
import { identity, from, forkJoin, EMPTY } from "rxjs";
import get from "lodash/fp/get";

import * as authenticationRoutes from "Authentication/authenticationRoutes";
import { fetchProfileWithBookingHistory$ } from "store/profile/epics/fetchProfile";
import { fetchMemberDetailsFulfilled } from "store/memberDetails";
import { fetchBookingHistoryFulfilled } from "store/bookingHistory";
import { fetchGlobalMailSubscriptions$ } from "store/globalMailSubscriptions";
import { logoutFulfilled, fetchProfileFulfilled } from "store/profile";
import { fetchProperties$ } from "store/properties";
import { fetchPropertyContent } from "store/propertyContent";
import { fetchAccommodationsContent } from "store/accommodationsContent";
import { fetchOffersContent } from "store/offersContent";
import { fetchTaxesContent } from "store/taxesContent";
import { retrieveBooking } from "store/bookings/bookings.slice";
import { getInfoToRetrieveSoonestBooking } from "utils/datesHelpers";
import sortUpcomingTrips from "utils/sortUpcomingTrips";

const buildRedirectToSignIn = ({ locale, matchedPath }) => {
  return push({
    pathname: authenticationRoutes.signIn.to({
      locale,
    }),
    state: {
      afterSignInRedirectTo: matchedPath?.url,
    },
  });
};

export const profileOnlyActions = ({
  locale,
  bookingHistory: { bookingSummaries = [] } = {},
  profile,
}) =>
  from(bookingSummaries).pipe(
    map(get(["hotelProducts", "0", "hotelCode"])),
    distinct(),
    toArray(),
    mergeMap((hotelCodes) => [
      ...(hotelCodes.length > 0
        ? [
            retrieveBooking({
              ...getInfoToRetrieveSoonestBooking(
                sortUpcomingTrips(bookingSummaries)
              ),
              surname: profile?.name?.surname,
              locale,
            }),
          ]
        : []),
      fetchPropertyContent({ locale, propertyCodes: hotelCodes }),
      fetchAccommodationsContent({ locale, propertyCodes: hotelCodes }),
      fetchTaxesContent({ propertyCodes: hotelCodes, locale }),
      fetchOffersContent({ propertyCodes: hotelCodes, locale }),
    ])
  );

export default ({ matchedPath, locale }) =>
  (additionalActions = () => EMPTY) =>
    forkJoin([
      fetchProfileWithBookingHistory$({ locale }),
      fetchProperties$({ locale }),
      fetchGlobalMailSubscriptions$({ locale }),
    ]).pipe(
      mergeMap(
        ([
          { profile, bookingHistory, memberDetails },
          propertiesAction,
          subscriptionsAction,
        ]) => [
          from([
            propertiesAction,
            subscriptionsAction,
            memberDetails && fetchMemberDetailsFulfilled(memberDetails),
            bookingHistory && fetchBookingHistoryFulfilled(bookingHistory),
            profile && fetchProfileFulfilled(profile),
          ]).pipe(filter(Boolean)),
          ...(profile?.id
            ? [
                additionalActions({
                  profile,
                  bookingHistory,
                  locale,
                  ...matchedPath,
                }),
              ]
            : [buildRedirectToSignIn({ locale, matchedPath })]),
        ]
      ),
      mergeMap(identity),
      catchError(() => [
        logoutFulfilled(),
        buildRedirectToSignIn({ locale, matchedPath }),
      ])
    );
