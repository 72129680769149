import flow from "lodash/fp/flow";
import flatMap from "lodash/fp/flatMap";
import get from "lodash/fp/get";
import map from "lodash/fp/map";
import update from "lodash/fp/update";

import { selectOfferTermsAndConditions } from "store/offersTermsAndConditionsContent";
import { selectSearchResults } from "store/searchResults";

const createResultIdToOfferCodeMap = (arr) =>
  arr.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: curr.roomRateId.split(".")[1] || "",
    }),
    {}
  );

const updateOffersWithOfferTermsAndConditions = ({
  hotelCode,
  checkinDate,
  checkoutDate,
  promoCode,
  state,
}) => {
  const results = selectSearchResults(state);

  const resultIdToOfferCodeMap = flow(
    get(["hotelOptions", 0, "roomOptions", 0, "roomTypeIds"]),
    flatMap(get("roomRateIds")),
    createResultIdToOfferCodeMap
  )(results);

  return (searchResults = []) =>
    flow(
      map(
        update(
          ["rooms"],
          map(
            update(
              ["bedTypes"],
              map((bedType) => {
                const roomTypeId = bedType.owsCode;

                return update(
                  ["offers"],
                  map((offer) => {
                    const offerCode =
                      offer.owsCode ||
                      resultIdToOfferCodeMap[offer.resultId] ||
                      "";

                    return update(["offerTermsAndConditionsContent"], () =>
                      selectOfferTermsAndConditions({
                        hotelCode,
                        checkinDate,
                        checkoutDate,
                        promoCode,
                        roomTypeId,
                        offerCode,
                      })(state)
                    )(offer);
                  })
                )(bedType);
              })
            )
          )
        )
      )
    )(searchResults);
};

export default updateOffersWithOfferTermsAndConditions;
