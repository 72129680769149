import flow from "lodash/fp/flow";
import filter from "lodash/fp/filter";
import find from "lodash/fp/find";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import includes from "lodash/fp/includes";
import map from "lodash/fp/map";
import set from "lodash/fp/set";

export const REQUEST_SUBTYPES = {
  ARRIVAL_TRANSPORTATION: "Arrival Transportation",
};

export const REQUEST_STATUSES = {
  OPEN: "Open",
  REMINDER: "Reminder",
  ENQUIRY: "Enquiry",
  NOTE: "Note",
  PENDING: "Pending",
  WAITLIST: "Waitlist",
  ON: "On Hold",
  CLOSED: "Closed",
  RECONFIRMED: "Reconfirmed",
  CANCELLED: "Cancelled",
  REQUESTED: "Requested",
};

export const REQUEST_STATUSES_TO_HIDE = [
  REQUEST_STATUSES.REMINDER,
  REQUEST_STATUSES.ENQUIRY,
  REQUEST_STATUSES.NOTE,
  REQUEST_STATUSES.CANCELLED,
];

export const REQUEST_STATUSES_TO_SHOW = [
  REQUEST_STATUSES.OPEN,
  REQUEST_STATUSES.PENDING,
  REQUEST_STATUSES.WAITLIST,
  REQUEST_STATUSES.ON,
  REQUEST_STATUSES.CLOSED,
  REQUEST_STATUSES.RECONFIRMED,
  REQUEST_STATUSES.REQUESTED,
];

export const ITINERARY_STATUS_MAP = {
  [REQUEST_STATUSES.OPEN]: "Pending",
  [REQUEST_STATUSES.PENDING]: "Pending",
  [REQUEST_STATUSES.WAITLIST]: "Pending",
  [REQUEST_STATUSES.ON]: "Pending",
  [REQUEST_STATUSES.CLOSED]: "Confirmed",
  [REQUEST_STATUSES.RECONFIRMED]: "Confirmed",
  [REQUEST_STATUSES.REQUESTED]: "Pending",
};

export function selectHasGuestRequests({ reservationId }) {
  return ({ guestRequests }) => Boolean(guestRequests.data[reservationId]);
}

export function selectGuestRequests({ reservationId }) {
  return flow(
    getOr([], ["guestRequests", "data", reservationId]),
    filter(
      flow(get("status"), (status) =>
        includes(status)(REQUEST_STATUSES_TO_SHOW)
      )
    ),
    map((r) => set("itineraryStatus", ITINERARY_STATUS_MAP[r.status])(r))
  );
}

export function selectGuestRequestArrivalTransportation({ reservationId }) {
  return flow(
    selectGuestRequests({ reservationId }),
    find({ requestSubtype: REQUEST_SUBTYPES.ARRIVAL_TRANSPORTATION })
  );
}
