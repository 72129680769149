import React from "react";

import AncillaryBookingCard from "./AncillaryBookingCard";

export default function AncillaryBookingCards({
  title,
  subtitle,
  ancillaryCardsData,
}) {
  return (
    <div className="ancillary-booking-cards">
      <div className="ancillary-booking-cards__header">
        <div className="ty-h3">{title}</div>
        <div className="ty-b2">{subtitle}</div>
      </div>
      <div className="ancillary-booking-cards__grid">
        {ancillaryCardsData.map(({ id, ...rest }) => (
          <AncillaryBookingCard key={id} id={id} {...rest} />
        ))}
      </div>
    </div>
  );
}
