import React from "react";

export const LockIconHTMLString = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25 12.0069H21.5V10.5539C21.5286 9.82117 21.4064 9.09032 21.141 8.40671C20.8755 7.72311 20.4724 7.10133 19.9568 6.57993C19.4411 6.05853 18.8238 5.64863 18.1431 5.37564C17.4625 5.10265 16.7331 4.97239 16 4.99295C15.2669 4.97239 14.5375 5.10265 13.8569 5.37564C13.1762 5.64863 12.5589 6.05853 12.0432 6.57993C11.5276 7.10133 11.1245 7.72311 10.859 8.40671C10.5936 9.09032 10.4714 9.82117 10.5 10.5539V12.0069H7C6.86739 12.0069 6.74021 12.0596 6.64645 12.1534C6.55268 12.2472 6.5 12.3743 6.5 12.5069V26.5069C6.5 26.6396 6.55268 26.7667 6.64645 26.8605C6.74021 26.9543 6.86739 27.0069 7 27.0069H25C25.1326 27.0069 25.2598 26.9543 25.3536 26.8605C25.4473 26.7667 25.5 26.6396 25.5 26.5069V12.5069C25.5 12.3743 25.4473 12.2472 25.3536 12.1534C25.2598 12.0596 25.1326 12.0069 25 12.0069ZM11.5 10.5539C11.4689 9.95197 11.5635 9.35011 11.7778 8.78672C11.9921 8.22333 12.3214 7.71076 12.7448 7.28167C13.1681 6.85258 13.6762 6.51638 14.2367 6.2945C14.7971 6.07262 15.3977 5.96992 16 5.99295C17.1383 5.97629 18.2424 6.38127 19.1 7.12995C19.5652 7.56693 19.931 8.09885 20.1725 8.68962C20.4141 9.2804 20.5258 9.91622 20.5 10.5539V12.0069H11.5V10.5539ZM24.5 26.0069H7.5V13.0069H24.5V26.0069ZM16 22.0069C15.7348 22.0069 15.4804 21.9016 15.2929 21.7141C15.1054 21.5265 15 21.2722 15 21.0069V18.0069C15 17.7417 15.1054 17.4874 15.2929 17.2998C15.4804 17.1123 15.7348 17.0069 16 17.0069C16.2652 17.0069 16.5196 17.1123 16.7071 17.2998C16.8946 17.4874 17 17.7417 17 18.0069V21.0069C17 21.2722 16.8946 21.5265 16.7071 21.7141C16.5196 21.9016 16.2652 22.0069 16 22.0069Z" fill="black"></path></svg>`;

export default function LockIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 12.0069H21.5V10.5539C21.5286 9.82117 21.4064 9.09032 21.141 8.40671C20.8755 7.72311 20.4724 7.10133 19.9568 6.57993C19.4411 6.05853 18.8238 5.64863 18.1431 5.37564C17.4625 5.10265 16.7331 4.97239 16 4.99295C15.2669 4.97239 14.5375 5.10265 13.8569 5.37564C13.1762 5.64863 12.5589 6.05853 12.0432 6.57993C11.5276 7.10133 11.1245 7.72311 10.859 8.40671C10.5936 9.09032 10.4714 9.82117 10.5 10.5539V12.0069H7C6.86739 12.0069 6.74021 12.0596 6.64645 12.1534C6.55268 12.2472 6.5 12.3743 6.5 12.5069V26.5069C6.5 26.6396 6.55268 26.7667 6.64645 26.8605C6.74021 26.9543 6.86739 27.0069 7 27.0069H25C25.1326 27.0069 25.2598 26.9543 25.3536 26.8605C25.4473 26.7667 25.5 26.6396 25.5 26.5069V12.5069C25.5 12.3743 25.4473 12.2472 25.3536 12.1534C25.2598 12.0596 25.1326 12.0069 25 12.0069ZM11.5 10.5539C11.4689 9.95197 11.5635 9.35011 11.7778 8.78672C11.9921 8.22333 12.3214 7.71076 12.7448 7.28167C13.1681 6.85258 13.6762 6.51638 14.2367 6.2945C14.7971 6.07262 15.3977 5.96992 16 5.99295C17.1383 5.97629 18.2424 6.38127 19.1 7.12995C19.5652 7.56693 19.931 8.09885 20.1725 8.68962C20.4141 9.2804 20.5258 9.91622 20.5 10.5539V12.0069H11.5V10.5539ZM24.5 26.0069H7.5V13.0069H24.5V26.0069ZM16 22.0069C15.7348 22.0069 15.4804 21.9016 15.2929 21.7141C15.1054 21.5265 15 21.2722 15 21.0069V18.0069C15 17.7417 15.1054 17.4874 15.2929 17.2998C15.4804 17.1123 15.7348 17.0069 16 17.0069C16.2652 17.0069 16.5196 17.1123 16.7071 17.2998C16.8946 17.4874 17 17.7417 17 18.0069V21.0069C17 21.2722 16.8946 21.5265 16.7071 21.7141C16.5196 21.9016 16.2652 22.0069 16 22.0069Z"
        fill="black"
      />
    </svg>
  );
}
