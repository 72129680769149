import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useDispatchWithLocale } from "hooks";
import {
  selectWorkplaces,
  selectIsLoadingWorkplaces,
  selectHasRequestedWorkplaces,
  fetchWorkplaces,
  fetchWorkplacesCancel,
} from "store/workplaces";

export default function useWorkplaces({ employeeMode = false } = {}) {
  const dispatchWithLocale = useDispatchWithLocale();

  const workplaces = useSelector(selectWorkplaces);
  const isLoading = useSelector(selectIsLoadingWorkplaces);
  const hasRequested = useSelector(selectHasRequestedWorkplaces);

  useEffect(() => {
    if (
      employeeMode &&
      !hasRequested &&
      !isLoading &&
      workplaces.length === 0
    ) {
      dispatchWithLocale(fetchWorkplaces());
    }
    return () => {
      if (isLoading) {
        dispatchWithLocale(fetchWorkplacesCancel());
      }
    };
  }, [isLoading, hasRequested, workplaces.length]);

  return {
    workplaces,
    isLoading,
  };
}
