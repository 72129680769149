import { ajax } from "rxjs/ajax";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { handleEvent } from "config/elasticApm";

import env from "config/env";

const { CONTENT_API_BASE_URL } = env;

export default function getAppStatus$({ locale = "en" }) {
  return ajax.getJSON(`${CONTENT_API_BASE_URL}/${locale}/status`).pipe(
    catchError((error) => {
      handleEvent({
        error: "getAppStatusFailed",
        errorContext: {
          failure: error,
          locale,
        },
      });
      return of({});
    })
  );
}
