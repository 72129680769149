import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";

import env from "config/env";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

export const createHistoricBookingSummaries$ = ({ locale, startDate }) =>
  ajax({
    ...basePostRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/bookingHistory/resultSets`,
    body: {
      futureBookingsOnly: true,
      ...(startDate ? { startDate } : {}),
    },
  }).pipe(pluck("response"));

export default createHistoricBookingSummaries$;
