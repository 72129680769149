import React, { useState } from "react";
import classNames from "classnames";

export default function FormRow({
  id: initialId,
  name,
  className,
  label,
  errorMessage,
  type = "text",
  ariaDescribedBy,
  register,
  inputClassName,
  popupMessage,
  ...inputProps
}) {
  const hasError = Boolean(errorMessage);
  const id = initialId || `input-${name.replace(/\./g, "_")}`;

  const [showPopop, setShowPopup] = useState(false);

  const handleMouseEnter = () => {
    setShowPopup(true);
  };

  const handleMouseLeave = () => {
    setShowPopup(false);
  };

  return (
    <div
      className={classNames("form-row string", className, {
        "has-errors": hasError,
      })}
    >
      <label htmlFor={id}>{label}</label>
      <input
        type={type}
        id={id}
        name={name}
        placeholder={label}
        aria-describedby={ariaDescribedBy}
        aria-invalid={hasError}
        required
        ref={register}
        className={classNames(inputClassName)}
        {...inputProps}
      />
      {popupMessage && (
        <div
          className="inline-message"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {showPopop && (
            <span
              className="message"
              dangerouslySetInnerHTML={{
                __html: popupMessage,
              }}
            />
          )}
          <span aria-hidden="true" className="icon">
            i
          </span>
        </div>
      )}
      {hasError && (
        <div className="inline-error">
          <span id={ariaDescribedBy} className="message" role="tooltip">
            {errorMessage}
          </span>
          <span aria-hidden="true" className="icon">
            !
          </span>
        </div>
      )}
    </div>
  );
}
