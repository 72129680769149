import React from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";

function InterestOption({ isSelected, label, onToggle }) {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      className={classNames("fs-your-interests__interest__option", {
        "fs-your-interests__interest__option--selected": isSelected,
      })}
      onClick={onToggle}
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 130.2 130.2"
      >
        <circle
          className="path circle"
          fill="none"
          stroke="#73AF55"
          strokeWidth="6"
          strokeMiterlimit="10"
          cx="65.1"
          cy="65.1"
          r="62.1"
        />
        <polyline
          className="path check"
          fill="none"
          stroke="#000"
          strokeWidth="6"
          strokeLinecap="round"
          strokeMiterlimit="10"
          points="29.8,67.5 51.5,88.8 100.2,40.2"
        />
      </svg>
      <span>{t(label)}</span>
    </button>
  );
}

export default InterestOption;
