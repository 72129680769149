import { getIsoCodeForLocale } from "config/languages";

export default ({
  currency,
  value,
  locale,
  useGrouping = true,
  decimalPlaces = 2,
  removeCurrency = false,
} = {}) => {
  // Check the format of the value provided - number preferred, but will accept and parse a string
  let price;
  if (typeof value === "string") {
    price = Number.parseFloat(value);
  } else if (typeof value === "number") {
    price = value;
  } else {
    return Error("Value is unsupported type");
  }

  const getCustomIsoCode = (isoCode) =>
    ["ar", "ru"].some((toIgnoreCode) => isoCode?.includes(toIgnoreCode))
      ? "en"
      : isoCode;

  const formattedCurrency = Intl.NumberFormat(
    getCustomIsoCode(getIsoCodeForLocale(locale)),
    {
      style: "currency",
      currencyDisplay: "code",
      currency,
      useGrouping,
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    }
  ).format(price);

  return removeCurrency
    ? formattedCurrency.replace(/[^0123456789.,]/g, "")
    : formattedCurrency;
};
