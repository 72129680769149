import { useLocation } from "react-router-dom";
import queryString from "query-string";

export default function useSystemOffline() {
  const location = useLocation();

  const searchParams = queryString.parse(location.search || "");
  const { ppMode: isPreferredPartners = false, ppUrl: ppReturnUrl } =
    searchParams;

  return {
    isPreferredPartners,
    ppReturnUrl,
  };
}
