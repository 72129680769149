import React, { useContext } from "react";
import { Controller } from "react-hook-form";
import classNames from "classnames";

import { useTranslation } from "hooks";
import {
  FormRow,
  FormRowPhoneNumber,
  Select,
  PersistentReadOnlyRow,
} from "BookingFlow/components";
import { selectIsUserPersistent } from "store/profile";
import { useSelector } from "react-redux";
import FormRowCountrySelect from "./FormRowCountrySelect";
import MappingLayerContext from "../../mappingLayerContext";

export default function GuestDetails({
  register,
  control,
  errors = {},
  includeSendConfirmation = false,
  excludeConfirmEmail = false,
  readOnlyFields = [],
  isPreferredPartners,
  persistentValues = {},
}) {
  const { t } = useTranslation();
  const {
    labels: { emailAddress },
  } = useContext(MappingLayerContext);
  const isUserPersistent = useSelector(selectIsUserPersistent);

  return (
    <div>
      <fieldset
        name="guestDetails"
        className={classNames({
          "has-errors": Object.keys(errors).length > 0,
        })}
      >
        <legend>{t("Guest Details")}</legend>
        {isUserPersistent && readOnlyFields.includes("firstName") ? (
          <PersistentReadOnlyRow
            name="guestDetails.firstName"
            className="first-name-row"
            label={`${t("First Name")}*`}
            ariaDescribedBy="ttGuestFirstName"
            value={persistentValues.firstName}
          />
        ) : (
          <FormRow
            name="guestDetails.firstName"
            className="first-name-row"
            label={`${t("First Name")}*`}
            errorMessage={
              errors.firstName && t("Please enter a valid first name.")
            }
            ariaDescribedBy="ttGuestFirstName"
            register={register()}
            readOnly={readOnlyFields.includes("firstName")}
          />
        )}
        {isUserPersistent && readOnlyFields.includes("surname") ? (
          <PersistentReadOnlyRow
            name="guestDetails.surname"
            className="last-name-row"
            label={`${t("Last Name")}*`}
            ariaDescribedBy="ttGuestLastName"
            value={persistentValues.surname}
          />
        ) : (
          <FormRow
            name="guestDetails.surname"
            className="last-name-row"
            label={`${t("Last Name")}*`}
            errorMessage={
              errors.surname && t("Please enter a valid last name.")
            }
            ariaDescribedBy="ttGuestLastName"
            register={register()}
            readOnly={readOnlyFields.includes("surname")}
          />
        )}
        {isUserPersistent && readOnlyFields.includes("phoneNumber") ? (
          <PersistentReadOnlyRow
            name="guestDetails.phoneNumber"
            label={`${t("Mobile Phone Number")}*`}
            ariaDescribedBy="ttGuestPhone2"
            value={persistentValues.phoneNumber}
          />
        ) : (
          <Controller
            name="guestDetails.phoneNumber"
            control={control}
            render={({ ref: _ignoredRef, ...props }) => (
              <FormRowPhoneNumber
                errorMessage={
                  errors.phoneNumber && t("Please enter a mobile phone number.")
                }
                readOnly={readOnlyFields.includes("phoneNumber")}
                {...props}
              />
            )}
          />
        )}

        {isUserPersistent && readOnlyFields.includes("email") ? (
          <PersistentReadOnlyRow
            name="guestDetails.email"
            className="email-row"
            label={`${t("Email Address")}*`}
            ariaDescribedBy="ttGuestEmail1"
            value={persistentValues.email}
          />
        ) : (
          <FormRow
            name="guestDetails.email"
            className="email-row"
            label={`${t(emailAddress.key, emailAddress.params)}*`}
            errorMessage={errors.email && t("Please enter a email address.")}
            ariaDescribedBy="ttGuestEmail1"
            type="email"
            register={register()}
            readOnly={readOnlyFields.includes("email")}
            popupMessage={
              isPreferredPartners
                ? `${t(
                    "This required field is used to match a reservation with a guest's Four Seasons profile. This ensures guest preferences are consistently managed at all Four Seasons."
                  )}<br /><br />${t(
                    "The guest will not be sent a confirmation."
                  )}`
                : undefined
            }
          />
        )}

        {!excludeConfirmEmail && (
          <>
            {isUserPersistent && readOnlyFields.includes("email") ? (
              <PersistentReadOnlyRow
                name="guestDetails.confirmEmail"
                className="email-confirmation-row"
                label={`${t("Confirm Email Address")}*`}
                ariaDescribedBy="ttGuestEmail2"
                value={persistentValues.email}
              />
            ) : (
              <FormRow
                name="guestDetails.confirmEmail"
                className="email-confirmation-row"
                label={`${t("Confirm Email Address")}*`}
                errorMessage={
                  errors.confirmEmail && t("Please re-enter your email address")
                }
                ariaDescribedBy="ttGuestEmail2"
                type="email"
                register={register()}
                readOnly={readOnlyFields.includes("confirmEmail")}
              />
            )}
          </>
        )}

        {includeSendConfirmation && (
          <div
            className={classNames("form-row send-guest-email-row", {
              "in-focus": false,
            })}
          >
            <label htmlFor="sendGuestEmail">
              {t("Send guest a confirmation?")}*
            </label>
            <Select
              id="sendGuestEmail"
              name="guestDetails.sendGuestConfirmation"
              required="required"
              register={register}
            >
              <option value="true">{t("Yes")}</option>
              <option value="false">{t("No")}</option>
            </Select>
          </div>
        )}

        {isUserPersistent && readOnlyFields.includes("countryCode") ? (
          <PersistentReadOnlyRow
            name="guestDetails.countryCode"
            label={`${t("Country/Region")}*`}
            ariaDescribedBy="ttGuestCountry"
            value={persistentValues.countryCode}
            isCountrySelect
          />
        ) : (
          <FormRowCountrySelect
            name="guestDetails.countryCode"
            label={`${t("Country/Region")}*`}
            errorMessage={
              errors.countryCode && t("Please enter a valid country.")
            }
            aria-describedby="ttGuestCountry"
            register={register()}
            readOnly={readOnlyFields.includes("countryCode")}
          />
        )}
      </fieldset>
    </div>
  );
}
