import {
  differenceInCalendarDays,
  format,
  isAfter,
  isDate,
  isSameYear,
  isValid,
  parse,
} from "date-fns";
import { format as formatZ, zonedTimeToUtc } from "date-fns-tz";
import * as dateFnLocales from "date-fns/locale";
import compact from "lodash/fp/compact";
import compose from "lodash/fp/compose";
import cond from "lodash/fp/cond";
import constant from "lodash/fp/constant";
import get from "lodash/fp/get";
import isEmpty from "lodash/fp/isEmpty";
import mapKeys from "lodash/fp/mapKeys";
import negate from "lodash/fp/negate";
import omit from "lodash/fp/omit";
import overEvery from "lodash/fp/overEvery";
import pick from "lodash/fp/pick";
import reverse from "lodash/fp/reverse";
import join from "lodash/fp/join";
import flow from "lodash/fp/flow";
import filter from "lodash/fp/filter";
import { stringify as queryStringStringify } from "query-string";

import env from "config/env";
import { getDateFnsLocale } from "config/languages";
import { onlyDigits } from "utils/regexPatterns";
import { DATA, PROFILE as PROFILE_DATE_FORMATS } from "./dateFormats";

const { STATIC_SITE_URL, RESIDENTIAL_APP_URL } = env;

export const pluralize = (t, count, singular, plural = `${singular}s`) =>
  `${count} ${count === 1 ? t(singular) : t(plural)}`;

const add = (a) => (b) => a + b;
export const uncurriedAdd = (a, b) => add(a)(b);
const subtract = (a) => (b) => b - a;
export const addOne = (a) => add(1)(a);
export const subtractOne = (a) => subtract(1)(a);

export const generateNumbersList = (n, start = 1) =>
  [...Array(n).keys()].map(add(start));

export const formatDate = (date, dateFormat, locale) =>
  format(date, dateFormat, {
    locale: dateFnLocales[getDateFnsLocale(locale)],
  });

export const formatDateRange = (checkInDate, checkOutDate, locale) => {
  const ERROR_MESSAGE = "Invalid dates";
  const dateFormat = "yyyy-MM-dd";

  try {
    const checkIn = parse(checkInDate, dateFormat, new Date());
    const checkOut = parse(checkOutDate, dateFormat, new Date());

    if (!isAfter(checkOut, checkIn)) return ERROR_MESSAGE;

    const localeConfig = {
      locale: dateFnLocales[getDateFnsLocale(locale)],
    };

    return isSameYear(checkIn, checkOut)
      ? `${format(checkIn, "MMMM dd", localeConfig)} - ${format(
          checkOut,
          "MMMM dd, yyyy",
          localeConfig
        )}`
      : `${format(checkIn, "MMMM dd, yyyy", localeConfig)} - ${format(
          checkOut,
          "MMMM dd, yyyy",
          localeConfig
        )}`;
  } catch (e) {
    return ERROR_MESSAGE;
  }
};

export const stringToInt = (n) => parseInt(n, 10);

export const buildOccupancy = (t, { adults = 1, children }) =>
  children && children > 0
    ? `${pluralize(t, adults, "Adult")}, ${pluralize(
        t,
        children,
        "Child",
        "Children"
      )}`
    : `${pluralize(t, adults, "Adult")}`;

const buildOccupancyText = (buildChildrenLabel) => (t, array) =>
  array.reduce(
    (acc, a) => {
      const adults = stringToInt(acc.numberOfAdults) + stringToInt(a.adults);
      const children =
        stringToInt(acc.numberOfChildren) + stringToInt(a.children);
      return {
        numberOfRooms: acc.numberOfRooms + 1,
        numberOfAdults: adults,
        numberOfChildren: children,
        value: `${pluralize(t, acc.numberOfRooms + 1, "Room")} - ${pluralize(
          t,
          adults,
          "Adult"
        )}${buildChildrenLabel(t, children)}`,
      };
    },
    {
      numberOfRooms: 0,
      numberOfAdults: 0,
      numberOfChildren: 0,
      value: "",
    }
  );

export const buildSelectedOptionsLabel = buildOccupancyText(
  (t, children = 0) => `, ${pluralize(t, children, "Child", "Children")}`
);

export const buildSelectedOptionsLabelForStep3 = buildOccupancyText(
  (t, children = 0) =>
    children > 0 ? `, ${pluralize(t, children, "Child", "Children")}` : ""
);

export const calculateAverage = (array) =>
  array.reduce(uncurriedAdd, 0) / array.length;

const twelveHoursTimeFormat = "p";

export const formatToTwelveHoursTime = (date, locale) =>
  isDate(date)
    ? format(date, twelveHoursTimeFormat, {
        locale: dateFnLocales[getDateFnsLocale(locale)],
      })
        .toLowerCase()
        .replace(/\s/g, "")
    : "";

const standardDateFormat = "MMMM d, yyyy";

export const formatToStandardDate = (date, locale) =>
  isDate(date)
    ? format(date, standardDateFormat, {
        locale: dateFnLocales[getDateFnsLocale(locale)],
      })
    : "";

export const getClientTimezoneAbbreviation = (date = new Date()) =>
  date
    .toTimeString()
    .split(/[()]/)[1]
    .replace(/[a-z|\s]/g, "");

export const objectToOrderedMap = (object) =>
  new Map(Object.entries(object).sort());

export const stringifyObject = (object) =>
  JSON.stringify(Array.from(objectToOrderedMap(object).entries()));

export const getConsentAcceptanceTimestamp = () =>
  `${format(new Date(), "yyyy-MM-dd hh:mm:ss").replace(" ", "T")}.000z`;

export const isWebChatEnabled = ({
  webChatConfig = {},
  disableWebChat = true,
} = {}) => {
  const integrationId = get(["default", "integrationId"])(webChatConfig);
  const enabled = get(["default", "enabled"])(webChatConfig);
  return Boolean(integrationId) && Boolean(enabled) && !disableWebChat;
};

export const getQueryString = flow(queryStringStringify, (qs) =>
  qs.length > 0 ? `?${qs}` : qs
);

export const getYouCanBookLinksText = ({
  propertyContent,
  internalCampaignTrackingCode,
}) => {
  const qs = getQueryString({ _s_icmp: internalCampaignTrackingCode });
  return flow(
    ({ diningPath, spaPath, urlName }) => [
      diningPath &&
        `<a target="_blank" data-interaction-detail="dining" href="${STATIC_SITE_URL}${diningPath}${qs}">restaurant reservations</a>`,
      spaPath &&
        `<a target="_blank" data-interaction-detail="book a spa" href="${STATIC_SITE_URL}${spaPath}${qs}">spa treatments</a>`,
      urlName &&
        `<a target="_blank" data-interaction-detail="experiences" href="${STATIC_SITE_URL}/${urlName}/experiences/${qs}">activities</a>`,
    ],
    filter(Boolean),
    reverse,
    ([and, ...all]) => [all.reverse().join(", "), and],
    filter(Boolean),
    join(" and ")
  )(propertyContent);
};

export const getYouCanBookMessage = ({
  propertyContent,
  internalCampaignTrackingCode,
}) =>
  !isWebChatEnabled(propertyContent)
    ? ""
    : `You can book ${getYouCanBookLinksText({
        propertyContent,
        internalCampaignTrackingCode,
      })} for your stay. We are here to help you.`;

export const isPastCheckIn = ({ propertyTimezone, checkInDate }) =>
  checkInDate &&
  differenceInCalendarDays(
    zonedTimeToUtc(parse(checkInDate, DATA, new Date()), propertyTimezone),
    new Date()
  ) > 0;

export const earliestCheckInDate = ({ propertyTimezone }) =>
  zonedTimeToUtc(new Date(), propertyTimezone);

export const twelveToTwentyFourTime = (twelveTime) =>
  twelveTime
    ? format(parse(twelveTime, "h:mm a", new Date()), "HH:mm")
    : twelveTime;

export const twentyFourToTwelveTime = (twentyTime, outputFormat = "h:00 a") =>
  twentyTime
    ? format(parse(twentyTime, "HH:mm", new Date()), outputFormat)
    : twentyTime;

export const formatArrivalTime = (checkInTime = "") =>
  twentyFourToTwelveTime(checkInTime.substring(0, 5), "h:mma").toLowerCase();

export const cleanPhoneNumber = (phoneNumber) =>
  phoneNumber.replace(/^\+/, "").replace(/\s+/g, "");

export const validateInvoiceDate = ({ date, timeZone }) => {
  const error = "Please enter your arrival date in MM/DD/YYYY format";
  try {
    const pastDate = zonedTimeToUtc(
      parse(date, PROFILE_DATE_FORMATS.INVOICE, new Date()),
      timeZone
    );
    if (isValid(pastDate)) {
      return !isAfter(new Date(), pastDate) ? "Please enter a past date" : true;
    }
    return error;
  } catch (_e) {
    return error;
  }
};

const testRegex = (regex) => (value) => regex.test(value);

export const validateLast4CC = compose(
  cond([
    [
      negate(Boolean),
      constant("Please enter the last 4 digits of your credit card"),
    ],
  ]),
  overEvery([negate(isEmpty), testRegex(onlyDigits)])
);

export const formatFolioRequestTimeStamp = ({ timeZone }) =>
  formatZ(new Date(), PROFILE_DATE_FORMATS.FOLIO_TIME_STAMP, {
    timeZone,
  });

export const scrollToTop = (selector) => {
  if (selector) {
    const domElement = window.document.querySelector(selector);
    if (domElement) {
      domElement.scrollTop = 0;
    }
  } else {
    window.scrollTo(0, 0);
  }
};

export const convertBookingCodesToArrays = ({
  resultId,
  roomOfferCode,
  roomOwsCode,
  ...rest
}) => {
  const resultIds = Array.isArray(resultId) ? resultId : [resultId];
  const roomCodes = Array.isArray(roomOwsCode) ? roomOwsCode : [roomOwsCode];
  const offerCodes = Array.isArray(roomOfferCode)
    ? roomOfferCode
    : [roomOfferCode];

  return {
    resultIds: compact(resultIds),
    roomCodes: compact(roomCodes),
    offerCodes: compact(offerCodes),
    ...rest,
  };
};

const guestInformationMap = {
  email: "guestEmail",
  phoneNumber: "guestPhone",
};
const constructGuestName = (formValues) => ({
  ...omit(["firstName", "surname"], formValues),
  guestName: `${formValues.firstName} ${formValues.surname}`,
});
export const formValuesToPaymentOptionsParams = compose(
  constructGuestName,
  mapKeys((key) => (guestInformationMap[key] ? guestInformationMap[key] : key)),
  pick(["firstName", "surname", "phoneNumber", "email"]),
  get(["guestDetails"])
);

export const webCheckinMapOptions = (options = []) =>
  options.map(({ title, value }) => ({ value, label: title }));

export const isResidentialAppUrl = (url) =>
  !!url?.includes(RESIDENTIAL_APP_URL);

export const getNearestFifteenMinuteTimestamp = () => {
  const curMin = Math.floor(Date.now() / 60000);
  return curMin - (curMin % 15);
};
