import React from "react";
import { useWatch } from "react-hook-form";
import LocationAndDates from "./LocationAndDates";
import Occupancy from "./Occupancy";

function Caw({ searchData, formControls, searchBy }) {
  const { control } = formControls;
  const { rooms } = searchData;
  const formValues = rooms && rooms.length ? rooms[0] : {};

  const { adults, children } = useWatch({
    control,
    defaultValue: formValues,
    name: "rooms[0]",
  });

  return (
    <div>
      <LocationAndDates
        searchData={searchData}
        formControls={formControls}
        searchBy={searchBy}
      />

      <Occupancy
        searchData={searchData}
        formValues={{
          adults,
          children,
        }}
        formControls={formControls}
        formNames={{ adults: "rooms[0].adults", children: "rooms[0].children" }}
      />
    </div>
  );
}

export default Caw;
