import React, { useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import { useTranslation } from "hooks";
import { viewFloorplanEvt } from "store/searchResults";
import ViewMoreDetailsLink from "./ViewMoreDetailsLink";
import SelectRoomButton from "./SelectRoomButton";
import BookDirectContent from "./BookDirectContent";
import RoomImages from "./RoomImages";
import DesktopRoomDetails from "../../components/Room/DesktopRoomDetails";
import FloorplanModal from "../../components/Room/FloorplanModal";
import Price from "./Price";

export default function RoomDesktop({
  accessibleFeaturesList,
  bathroomDescription,
  bedOptions,
  bedTypes,
  isBookableOnline,
  bookDirectData = { telephones: [] },
  propertyUseGlobalNumber,
  tollFreeNumbers,
  extraBed,
  feesDisclaimer, // from property content
  floorPlanImagePath,
  images,
  location,
  lowestRate: { amount, currency } = {},
  occupancy,
  onSelectRoom,
  priceLabel,
  roomName,
  servicesAndAmenityGroups,
  size: roomSize,
  unitLongDescription,
  view,
  isBestRate = false,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showFloorplanModal, setShowFloorplanModal] = useState(false);

  const openFloorplanModal = () => {
    setShowFloorplanModal(true);
    dispatch(viewFloorplanEvt({ roomName }));
  };

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const roomsRemaining = Math.max(...bedTypes.map((bedType) => bedType.units));

  return (
    <section
      className={classNames("room-item", {
        "room-item-expanded": isExpanded,
        "room-item--is-best-rate": isBestRate,
      })}
    >
      {isBestRate && (
        <div className="room-item__best-rate">{t("Best Rate")}</div>
      )}

      <div className="room-item-preview">
        <div className="room-item-image">
          {images.length > 0 && (
            <RoomImages roomName={roomName} images={images} />
          )}
        </div>

        <div
          className={classNames("room-item-info", {
            "has-book-direct": !isBookableOnline,
          })}
        >
          <div className="room-item__info-info">
            <h3 className="room-item-title">{roomName}</h3>

            {showFloorplanModal && (
              <FloorplanModal
                floorPlanImagePath={floorPlanImagePath}
                closeModal={() => setShowFloorplanModal(false)}
              />
            )}

            <div
              className={classNames("room-item-features", {
                "no-price": !isBookableOnline,
              })}
            >
              <div className="room-feature-list">
                <div className="col">
                  <ul>
                    {view && (
                      <li>
                        <span className="label">{t("View")}</span>
                        <div className="shrink">{view}</div>
                      </li>
                    )}

                    {bedOptions && (
                      <li>
                        <span className="label">{t("Bed Options")}</span>
                        <div className="shrink">{bedOptions}</div>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="col">
                  <ul>
                    {roomSize && (
                      <li>
                        <span className="label">{t("Room Size")}</span>
                        <div className="shrink">{roomSize}</div>
                        {floorPlanImagePath && (
                          <button
                            type="button"
                            className="terms-button"
                            onClick={openFloorplanModal}
                            aria-label={`${t("View floorplan")} (${roomName})`}
                            aria-haspopup="dialog"
                          >
                            {t("View floorplan")}
                          </button>
                        )}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>

            <ViewMoreDetailsLink
              isExpanded={isExpanded}
              toggleExpand={toggleExpand}
              roomName={roomName}
              uniqueName={roomName}
            />
          </div>

          <div className="room-item__price-and-select-room">
            {!isBookableOnline && (
              <BookDirectContent
                hotelReservations={bookDirectData.hotelReservations}
                title="To Book this Room<br/>Please Call"
                telephones={bookDirectData.telephones}
                propertyUseGlobalNumber={propertyUseGlobalNumber}
                tollFreeNumbers={tollFreeNumbers}
              />
            )}

            {isBookableOnline && (
              <Price
                currency={currency}
                amount={amount}
                feesDisclaimer={feesDisclaimer}
                priceLabel={priceLabel}
              >
                {roomsRemaining < 6 && (
                  <div className="rooms-remaining">
                    {`${roomsRemaining} ${t(
                      roomsRemaining === 1 ? "room" : "rooms"
                    )} ${t("remaining")}`}
                  </div>
                )}
              </Price>
            )}

            {isBookableOnline && (
              <SelectRoomButton
                bedTypes={bedTypes}
                roomName={roomName}
                onSelectRoom={onSelectRoom}
              />
            )}
          </div>
        </div>
      </div>
      {isExpanded && (
        <DesktopRoomDetails
          accessibleFeaturesList={accessibleFeaturesList}
          bathroomDescription={bathroomDescription}
          isBookableOnline={isBookableOnline}
          extraBed={extraBed}
          location={location}
          longDescription={unitLongDescription}
          occupancy={occupancy}
          roomName={roomName}
          servicesAndAmenityGroups={servicesAndAmenityGroups}
        />
      )}
    </section>
  );
}
