/* eslint-disable no-param-reassign */
import { ajax } from "rxjs/ajax";

import env from "config/env";
import { basePutRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

export default function updateUserProfile$({ body, locale }) {
  const persistent = body.dataRedacted;
  if (typeof body.dataRedacted === "boolean") {
    delete body.dataRedacted;
  }
  return ajax({
    ...basePutRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/cust/profile${
      persistent ? "Persistent" : ""
    }`,
    body,
  });
}
