import get from "lodash/get";

export default ({
  upcomingTrip,
  termsAndConditions: { cancellationBy = "" } = {},
  checkInDate,
}) => {
  const roomTypes = get(
    upcomingTrip,
    ["hotelProducts", "0", "roomTypes", "0"],
    {}
  );
  const cardHolderName = get(
    upcomingTrip,
    ["hotelProducts", "0", "guarantees", "0", "cardholderName"],
    ""
  );
  const today = new Date();
  return (
    roomTypes.fsRoomToCharge === roomTypes.roomTypeId &&
    !/alipay/i.test(cardHolderName) &&
    checkInDate &&
    cancellationBy &&
    today < new Date(cancellationBy)
  );
};
