import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs";
import { switchMap, takeUntil, mergeMap, catchError } from "rxjs/operators";

import { TECHNICAL_ERROR_MESSAGE } from "fixtures/constants";
import { getOffersContent$ } from "api/content";
import {
  fetchOffersContent,
  fetchOffersContentCancel,
  fetchOffersContentFailed,
  fetchOffersContentFulfilled,
} from "store/offersContent/offersContent.slice";

const fetchOffersContentEpic = (action$) =>
  action$.pipe(
    ofType(fetchOffersContent.type),
    switchMap(({ payload: { locale = "en", propertyCode, propertyCodes } }) =>
      getOffersContent$({ locale, propertyCode, propertyCodes }).pipe(
        mergeMap((responses) => {
          return responses.map(({ ajaxResponse, hotelCode }) => {
            if (ajaxResponse.error) {
              return fetchOffersContentFailed({
                error: ajaxResponse.error,
                propertyCode: hotelCode,
              });
            }
            return fetchOffersContentFulfilled({
              offersContent: ajaxResponse,
              propertyCode: hotelCode,
            });
          });
        }),
        catchError(() =>
          of(
            fetchOffersContentFailed({
              propertyCode,
              propertyCodes,
              error: TECHNICAL_ERROR_MESSAGE,
            })
          )
        ),
        takeUntil(action$.pipe(ofType(fetchOffersContentCancel.type)))
      )
    )
  );

export default combineEpics(fetchOffersContentEpic);
