import React from "react";

import { useTranslation } from "hooks";
import env from "config/env";

const { IMAGE_BASE_URL } = env;

export default function CommonSingleRoomUpsells({
  roomIndex,
  roomName,
  image,
  price,
  description,
  children,
}) {
  const { t, formatPrice } = useTranslation();

  return (
    <li key={roomIndex} className="upgrade-option-section">
      <div className="upgrade-option-info">
        <div>
          {image && (
            <span
              className="col-xs-12 col-sm-5 div-image upgrade-option-image"
              style={{
                backgroundImage: `url(${IMAGE_BASE_URL}${image.path})`,
              }}
              aria-label={roomName}
              role="img"
            />
          )}
        </div>
        <div className="col-xs-12 col-sm-7">
          <div className="upgrade-option-price">
            <h2>
              {t("Just {0} More/Night", [
                formatPrice({
                  currency: price.currency,
                  value: price.amount.withDecimal,
                  decimalPlaces: 2,
                }),
              ])}
            </h2>
          </div>
          <h2 className="upgrade-room">
            {t("Upgrade to the {0}", [roomName])}
          </h2>
          <p dangerouslySetInnerHTML={{ __html: description }} />
          {children}
        </div>
      </div>
    </li>
  );
}
