import React, { useContext } from "react";

import GuestDetails from "./GuestDetails";
import CreditCardDetails from "./CreditCardDetails";
import TermsAndConditions from "./TermsAndConditions";
import TravelAgentDetails from "./TravelAgentDetails";
import MappingLayerContext from "../../mappingLayerContext";

export default function TravelAgentBooking({
  register,
  control,
  errors,
  readOnlyFields,
  isPreferredPartners,
}) {
  const {
    suppressPaymentOptions,
    labels: { travelAgentDetails, travelAgentId },
  } = useContext(MappingLayerContext);

  return (
    <>
      <TravelAgentDetails
        register={register}
        control={control}
        errors={errors.travelAgentDetails || {}}
        travelAgentDetails={travelAgentDetails}
        travelAgentId={travelAgentId}
        readOnlyFields={readOnlyFields.travelAgentDetails || []}
        isPreferredPartners={isPreferredPartners}
      />
      <GuestDetails
        register={register}
        control={control}
        errors={errors.guestDetails || {}}
        includeSendConfirmation={!isPreferredPartners}
        excludeConfirmEmail
        readOnlyFields={readOnlyFields.guestDetails || []}
        isPreferredPartners={isPreferredPartners}
      />
      {!suppressPaymentOptions && (
        <CreditCardDetails
          register={register}
          control={control}
          errors={errors.creditCard || {}}
          readOnlyFields={readOnlyFields.creditCard || []}
        />
      )}
      <TermsAndConditions
        register={register}
        errors={errors.termsAndConditions || {}}
        readOnlyFields={readOnlyFields.termsAndConditions || []}
      />
    </>
  );
}
