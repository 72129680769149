import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { useTranslation, usePrevious } from "hooks";
import { Button } from "Profile/components";

export default function SpecialRequestsForm({
  defaultValues,
  onSubmit,
  fieldName,
  close,
  showSavingSpinner,
}) {
  const { t } = useTranslation();
  const prevShowSavingSpinner = usePrevious(showSavingSpinner);

  const { register, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    if (prevShowSavingSpinner && !showSavingSpinner) {
      close();
    }
  }, [prevShowSavingSpinner, showSavingSpinner]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-md-12">
          <textarea
            className="fs-trip__text_area"
            name={fieldName}
            placeholder={t(
              "Let us know how to make your stay even more special."
            )}
            ref={register()}
          />
          <div className="fs-trip__submit">
            <Button type="submit">{t("Submit")}</Button>
          </div>
        </div>
      </div>
    </form>
  );
}
