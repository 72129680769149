import { ofType } from "redux-observable";
import { mergeMap, withLatestFrom } from "rxjs/operators";
import { push, replace } from "connected-react-router";

import { bookingFlowRoutes } from "BookingFlow";
import {
  searchResultsClear,
  missingLanguage,
  submitSearch,
} from "store/searchResults/searchResults.slice";
import { clearPreferredPartnerDetails } from "store/preferredPartnerDetails";
import {
  selectProperty,
  selectIsPropertyBookableInLocale,
} from "store/properties";
import { clearDeepLinkErrors } from "store/bookings";
import { getQueryStringFromSearchFormValues } from "BookingFlow/utils";

export default function submitSearchEpic(action$, state$) {
  return action$.pipe(
    ofType(submitSearch.type),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { locale, ...formValues },
        },
        state,
      ]) => {
        const {
          router: { location },
        } = state;
        const propertyCode = formValues.hotelCode;
        const property = selectProperty(propertyCode)(state);
        const isPropertyBookableInLocale = selectIsPropertyBookableInLocale({
          propertyCode,
          locale,
        })(state);
        const search = getQueryStringFromSearchFormValues({
          ...formValues,
        });

        if (!isPropertyBookableInLocale) {
          return [
            missingLanguage({
              property,
              continuePath: {
                pathname: bookingFlowRoutes.chooseYourRoom.to({ locale: "en" }),
                search,
              },
            }),
          ];
        }

        return [
          searchResultsClear({ locale }),
          clearDeepLinkErrors(),
          clearPreferredPartnerDetails(),

          // will save form values in url for when navigating back
          replace({ ...location, search }),

          push({
            pathname: bookingFlowRoutes.chooseYourRoom.to({ locale }),
            search,
            analyticsData: {
              from: window.location.href,
            },
          }),
        ];
      }
    )
  );
}
