import { combineEpics, ofType } from "redux-observable";
import { mergeMap } from "rxjs/operators";

import {
  fetchPropertyContent,
  fetchPropertyContentCancel,
} from "store/propertyContent";
import {
  fetchAccommodationsContent,
  fetchAccommodationsContentCancel,
} from "store/accommodationsContent";
import {
  fetchOffersContent,
  fetchOffersContentCancel,
} from "store/offersContent";
import {
  fetchUpsellsContent,
  fetchUpsellsContentCancel,
} from "store/upsellsContent";
import { fetchTaxesContent, fetchTaxesContentCancel } from "store/taxesContent";
import {
  fetchAllPropertyContent,
  fetchAllPropertyContentFulfilled,
  fetchAllPropertyContentCancel,
} from "./allPropertyContent.actions";

const fetchAllPropertyContentEpic = (action$) =>
  action$.pipe(
    ofType(fetchAllPropertyContent.type),
    mergeMap(({ payload: { locale, propertyCode } }) => [
      fetchAccommodationsContent({ locale, propertyCode }),
      fetchOffersContent({ locale, propertyCode }),
      fetchPropertyContent({ locale, propertyCode }),
      fetchTaxesContent({ locale, propertyCode }),
      fetchUpsellsContent({ locale, propertyCode }),
      fetchAllPropertyContentFulfilled(),
    ])
  );

const fetchAllPropertyContentCancelEpic = (action$) =>
  action$.pipe(
    ofType(fetchAllPropertyContentCancel.type),
    mergeMap(({ payload: { propertyCode } }) => [
      fetchAccommodationsContentCancel({ propertyCode }),
      fetchOffersContentCancel({ propertyCode }),
      fetchPropertyContentCancel({ propertyCode }),
      fetchTaxesContentCancel({ propertyCode }),
      fetchUpsellsContentCancel({ propertyCode }),
    ])
  );

export default combineEpics(
  fetchAllPropertyContentEpic,
  fetchAllPropertyContentCancelEpic
);
