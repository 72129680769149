export default [
  { value: "AR", label: "Arabic" },
  { value: "AZ", label: "Azerbaijani" },
  { value: "B", label: "Indonesia (Bahasa)" },
  { value: "C", label: "Cantonese" },
  { value: "CR", label: "Creole" },
  { value: "D", label: "German" },
  { value: "E", label: "English" },
  { value: "EL", label: "Greek" },
  { value: "F", label: "French" },
  { value: "FA", label: "Persian" },
  { value: "H", label: "Dutch" },
  { value: "I", label: "Italian" },
  { value: "J", label: "Japanese" },
  { value: "KO", label: "Korean" },
  { value: "M", label: "Mandarin" },
  { value: "P", label: "Portuguese" },
  { value: "R", label: "Russian" },
  { value: "RO", label: "Romanian" },
  { value: "S", label: "Spanish" },
  { value: "TR", label: "Turkish" },
  { value: "VN", label: "Vietnamese" },
  { value: "W", label: "Swedish" },
  { value: "Y", label: "Hungarian" },
  { value: "Z", label: "Thai" },
];
