/* eslint-disable no-unused-vars */
import { createMiddleware } from "redux-beacon";
import combineEvents from "@redux-beacon/combine-events";

import {
  addPackage,
  addPackageCancel,
  addPackageFailed,
  addPackageFulfilled,
  cancelBooking,
  cancelBookingCancel,
  cancelBookingFailed,
  cancelBookingFulfilled,
  completeBooking,
  completeBookingCancel,
  completeBookingFailed,
  completeBookingFulfilled,
  submitBookingPreferences,
  submitBookingPreferencesCancel,
  submitBookingPreferencesFailed,
  submitBookingPreferencesFulfilled,
} from "store/bookings";

import {
  apmTarget,
  apmCaptureError,
  customTransaction,
  endTransaction,
} from "config/elasticApm";
import {
  fetchSearchResults,
  fetchSearchResultsCancel,
  fetchSearchResultsFailed,
  fetchSearchResultsForResultSetIdFulfilled,
} from "./searchResults";
import {
  generateOtp,
  generateOtpCancel,
  generateOtpFailed,
  generateOtpFulfilled,
  submitOtp,
  submitOtpCancel,
  submitOtpFailed,
  submitOtpFulfilled,
} from "./signIn";

const eventsMap = {
  [generateOtp.type]: customTransaction("Generate OTP"),
  [generateOtpCancel.type]: endTransaction(),
  [generateOtpFailed.type]: endTransaction(),
  [generateOtpFulfilled.type]: endTransaction(),

  [submitOtp.type]: customTransaction("Submit OTP"),
  [submitOtpCancel.type]: endTransaction(),
  [submitOtpFailed.type]: endTransaction(),
  [submitOtpFulfilled.type]: endTransaction(),

  [fetchSearchResults.type]: customTransaction("Fetch Availability"),
  [fetchSearchResultsCancel.type]: endTransaction(),
  [fetchSearchResultsFailed.type]: endTransaction(),
  [fetchSearchResultsForResultSetIdFulfilled.type]: endTransaction(),

  [addPackage.type]: customTransaction("Choose Your Room"),
  [addPackageCancel.type]: endTransaction(),
  [addPackageFailed.type]: endTransaction(),
  [addPackageFulfilled.type]: endTransaction(),

  [submitBookingPreferences.type]: customTransaction("Personalize Booking"),
  [submitBookingPreferencesCancel.type]: endTransaction(),
  [submitBookingPreferencesFailed.type]: endTransaction(),
  [submitBookingPreferencesFulfilled.type]: endTransaction(),

  [cancelBooking.type]: customTransaction("Cancel Booking"),
  [cancelBookingCancel.type]: endTransaction(),
  [cancelBookingFailed.type]: endTransaction(),
  [cancelBookingFulfilled.type]: endTransaction(),

  [completeBooking.type]: customTransaction("Checkout"),
  [completeBookingCancel.type]: endTransaction(),
  [completeBookingFulfilled.type]: endTransaction(),
  [completeBookingFailed.type]: combineEvents(
    apmCaptureError(({ payload: { errorContext } }) => ({
      error: "makeReservationFailed",
      errorContext,
    })),
    endTransaction()
  ),
};

export default createMiddleware(eventsMap, apmTarget());
