import env from "config/env";

const { EMPLOYEE_RATES } = env;

export const getEmployeeRate = ({
  upcomingTripDetails: {
    hotelProducts: [{ roomRate: { ratePlanCode } = {} } = {}] = [],
  } = {},
} = {}) => {
  if (!ratePlanCode || !EMPLOYEE_RATES) {
    return false;
  }

  return EMPLOYEE_RATES.rateDetails.find((rateCodeDetails) =>
    rateCodeDetails.rateCodes.includes(ratePlanCode)
  );
};

export const getEmployeeRateByCode = (ratePlanCode) => {
  if (!ratePlanCode || !EMPLOYEE_RATES) {
    return false;
  }

  return EMPLOYEE_RATES.rateDetails.find((rateCodeDetails) =>
    rateCodeDetails.rateCodes.includes(ratePlanCode)
  );
};
