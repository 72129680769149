function isIpad() {
  const userAgent = window.navigator.userAgent || window.navigator.vendor;
  if (userAgent.indexOf("iPad") > -1) {
    return true;
  }
  if (userAgent.match(/Mac/) && navigator?.maxTouchPoints > 2) {
    return true;
  }
  return false;
}

export default isIpad;
