import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { useTranslation } from "hooks";
import { MediaContext } from "contexts/MediaContext";
import { selectExchangeRates } from "store/exchangeRates";
import { sortResultsEvt, accessibleRoomsToggleEvt } from "store/searchResults";
import { SORT_BY_RATE } from "fixtures/constants";

function IsAccessibleCheckbox({ label, checked, onChange }) {
  return (
    <div>
      <div className="checkboxes">
        <label
          className={classNames("checkbox", {
            "is-checked": checked,
          })}
        >
          <input type="checkbox" onChange={onChange} checked={checked} />
          <div className="checkbox__custom-checkbox" />
          {label}
        </label>
      </div>
    </div>
  );
}

function AllInPricingCheckbox({
  label,
  checked,
  onChange,
  isDisabled = false,
}) {
  return (
    <div className="checkboxes all-in-pricing">
      <label
        className={classNames("switch", {
          "switch--is-disabled": isDisabled,
        })}
      >
        <div className="slider-wrapper">
          <input
            type="checkbox"
            onChange={onChange}
            checked={checked}
            disabled={isDisabled}
          />
          <span className="slider round" />
        </div>
        {label}
      </label>
    </div>
  );
}

function Select({ className = "select", ...props }) {
  return (
    <div className={[className, "wrapper"].join("__")}>
      <select {...props} className={className} />
      <div className={[className, "arrow"].join("__")} />
    </div>
  );
}

export function SearchFilterSelect({
  label,
  children,
  name,
  value,
  options,
  onChange,
  className = "",
}) {
  const { t } = useTranslation();
  const [inFocus, setInFocus] = useState(false);
  const id = `field-${name};`;

  return (
    <div className={className}>
      <div className="AccessibilityLabelDiv">
        <label className="AccessibilityLabel" htmlFor={id}>
          {t(label)}
        </label>
      </div>
      {children}
      <div
        className={classNames("fs-select-mask", {
          "in-focus": inFocus,
        })}
      >
        <div>
          <select
            name={name}
            id={id}
            value={value}
            onFocus={() => setInFocus(true)}
            onBlur={() => setInFocus(false)}
            onChange={onChange}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {t(option.label)}
              </option>
            ))}
          </select>
          <span
            aria-hidden="true"
            className="icon-button icon icon-arrow-down"
          />
        </div>
      </div>
    </div>
  );
}

function SearchFilters({
  isAllInPricing,
  onIsAllInPricingChange,
  isAccessible,
  onIsAccessibleChange,
  isAllInPricingDisabled,
  sortByHighestToLowestRate,
  onSortByHighestToLowestRateChange,
  willRenderAllInPricing,
  currencyCode,
  setCurrencyCode,
}) {
  const { t, i18n } = useTranslation();
  const media = useContext(MediaContext);
  const isMobile = media.isLessThan.sm;
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => setIsOpen(!isOpen);
  const dispatch = useDispatch();
  const toggleAllInPricing = () => onIsAllInPricingChange(!isAllInPricing);
  const toggleIsAccessible = () => {
    const toggledIsAccessible = !isAccessible;
    onIsAccessibleChange(toggledIsAccessible);
    dispatch(accessibleRoomsToggleEvt({ isAccessible: toggledIsAccessible }));
  };
  const exchangeRates = useSelector(selectExchangeRates);

  const handleCurrencyCodeChange = ({ target: { value } }) =>
    setCurrencyCode(value);

  return (
    <fieldset className="search-filters">
      <legend className="visuallyhidden">{t("Filter room options")}</legend>

      {isMobile && (
        <div className="search-filters__actions">
          <button
            type="button"
            onClick={toggleIsOpen}
            className={classNames("ty-c4 search-filters__actions__toggle", {
              "search-filters__actions__toggle--is-open": isOpen,
            })}
          >
            {t("sort & filters")}
          </button>
        </div>
      )}

      {(isOpen || !isMobile) && (
        <div className={classNames("search-filters__options", i18n.language)}>
          <div className="search-filters__options__row">
            <div className="search-filters__options__col">
              <label
                className="search-filters__options__label"
                htmlFor="field-rate"
              >
                {t("Sort by rate")}
              </label>
              <Select
                className="search-filters__options__select"
                id="field-rate"
                value={sortByHighestToLowestRate}
                onChange={(evt) => {
                  onSortByHighestToLowestRateChange(evt.target.value);
                  dispatch(sortResultsEvt({ sortValue: evt.target.value }));
                }}
              >
                <option value={SORT_BY_RATE.BY_RATE}>
                  {t("Sort by rate")}
                </option>
                <option value={SORT_BY_RATE.LOW_TO_HIGH}>
                  {t("Low to High")}
                </option>
                <option value={SORT_BY_RATE.HIGH_TO_LOW}>
                  {t("High to Low")}
                </option>
              </Select>
            </div>
            <div className="search-filters__options__col">
              <label
                className="search-filters__options__label"
                htmlFor="field-currency"
              >
                {t("Convert Currency")}
              </label>
              <Select
                className="search-filters__options__select"
                id="field-currency"
                value={currencyCode}
                onChange={handleCurrencyCodeChange}
              >
                {exchangeRates
                  .map(({ code, title }) => ({
                    value: code,
                    label: title,
                  }))
                  .map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
              </Select>
            </div>
          </div>
          <div className="search-filters__options__row">
            <div className="search-filters__options__col">
              <IsAccessibleCheckbox
                label={t("Accessible rooms")}
                onChange={toggleIsAccessible}
                checked={isAccessible}
              />
            </div>
            {willRenderAllInPricing && (
              <div className="search-filters__options__col">
                <AllInPricingCheckbox
                  label={t("All-in price")}
                  onChange={toggleAllInPricing}
                  checked={isAllInPricing}
                  isDisabled={isAllInPricingDisabled}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </fieldset>
  );
}

export default SearchFilters;
