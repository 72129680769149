const smoochSignOut = () => {
  Object.keys(sessionStorage)
    .filter((key) => {
      return (
        // smooch things
        key.indexOf(".appUserId") > 0 ||
        key.indexOf(".sessionToken") > 0 ||
        key.indexOf(".clientId") > 0
      );
    })
    .forEach((key) => {
      sessionStorage.removeItem(key);
    });
};

export default smoochSignOut;
