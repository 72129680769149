/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import useTextInput from "../../hooks/useTextInput";

const TextInput = React.forwardRef((props, ref) => {
  const {
    inputProps,
    fieldClassNames,
    labelClassNames,
    label,
    error,
    errorClassNames,
  } = useTextInput(props, ref);

  return (
    <div className={fieldClassNames}>
      <input {...inputProps} />

      {label && (
        <label htmlFor={inputProps.id} className={labelClassNames}>
          {label}
        </label>
      )}

      {error && (
        <span className={errorClassNames} role="alert">
          {error}
        </span>
      )}
    </div>
  );
});

TextInput.propTypes = {
  identifier: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "date",
    "datetime-local",
    "email",
    "number",
    "password",
    "range",
    "search",
    "tel",
    "text",
    "time",
    "url",
  ]),
  value: PropTypes.string,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  requiredIndicator: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  register: PropTypes.func,
  onChange: PropTypes.func,
};

TextInput.defaultProps = {
  type: "text",
  value: "",
  disabled: false,
  readOnly: false,
  required: false,
  requiredIndicator: "*",
  error: "",
  onChange: () => {},
};

TextInput.displayName = "TextInput";

export default TextInput;
