/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const toastNotificationSlice = createSlice({
  name: "toastNotification",
  initialState: {
    shouldOpen: false,
    notificationText: undefined,
  },
  reducers: {
    setShouldOpen(state, { payload }) {
      state.shouldOpen = payload.shouldOpen || false;
      state.notificationText = payload.notificationText;
    },
  },
});

export const { setShouldOpen } = toastNotificationSlice.actions;

export default toastNotificationSlice.reducer;
