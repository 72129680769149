import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";

import env from "config/env";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

export const LOGIN_TYPES = {
  PHONE_NUMBER: "PHONE_NUMBER",
  EMAIL_ADDRESS: "EMAIL_ADDRESS",
  CARD_NUMBER: "CARD_NUMBER",
  USERNAME: "USERNAME",
  UNREGISTERED_MOBILE: "UNREGISTERED_MOBILE",
  MOBILE_APP_TRANSFER_TOKEN: "MOBILE_APP_TRANSFER_TOKEN",
};

export default function generateOTP$({
  sendCodeTo = "",
  sendCodeVia = "",
  isUserPersistent = false,
  locale,
  gRecaptchaResponse,
}) {
  return ajax({
    ...basePostRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/authentication/cust/otp${
      isUserPersistent ? "Persistent" : ""
    }`,
    body: {
      loginType: sendCodeVia,
      // transferToken: "string",
      user: isUserPersistent ? undefined : sendCodeTo,
      gRecaptchaResponse,
    },
  }).pipe(pluck("response"));
}
