export function getCookies(cookieString = document.cookie) {
  return cookieString
    .split(";")
    .map((s) => s.trim())
    .reduce((cookies, curr) => {
      const [key, ...valueParts] = curr.split("=");
      return { ...cookies, [key]: valueParts.join("=") };
    }, {});
}

export function getCookieValue(cookieName, cookieString = document.cookie) {
  return getCookies(cookieString)[cookieName];
}
