export const selectPropertyOffersContent =
  (propertyCode) =>
  ({ offersContent }) =>
    offersContent?.data[propertyCode];

export const selectAllPropertyOffersContent = ({ offersContent }) =>
  offersContent?.data;

export const selectAllPropertyOffersContentErrors = ({ offersContent }) =>
  offersContent?.error;

export const selectOffer = (ratePlanCode) => (hotelCode) => (state) =>
  selectPropertyOffersContent(hotelCode)(state)?.bookableOffers?.find(
    ({ owsCode }) => owsCode === ratePlanCode
  ) || {};

export const selectOffersByOrsCodeMap = (hotelCode) => (state) =>
  selectPropertyOffersContent(hotelCode)(state)?.bookableOffers?.reduce(
    (acc, offer) => ({ ...acc, [offer.orsCode]: offer }),
    {}
  );
