import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";

import env from "config/env";
import { basePutRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

export default function updateHotelProductComments$({
  bookingId,
  comments,
  locale,
}) {
  return ajax({
    ...basePutRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/bookings/${bookingId}/products/hotel/comments`,
    body: {
      comments,
    },
  }).pipe(pluck("response"));
}
