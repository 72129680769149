import { ajax } from "rxjs/ajax";

import env from "config/env";
import { baseDeleteRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

export default function removeHotelProductComment$({
  bookingId,
  productId,
  locale,
}) {
  return ajax({
    ...baseDeleteRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/bookings/${bookingId}/products/hotel/comments/${productId}`,
  });
}
