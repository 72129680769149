import React from "react";

import SingleRoomUpsells from "./SingleRoomUpsells";
import MultiRoomUpsells from "./MultiRoomUpsells";

export default function Upsells({ upsells }) {
  return upsells.length > 1 ? (
    <MultiRoomUpsells upsells={upsells} />
  ) : (
    <SingleRoomUpsells upsells={upsells} />
  );
}
