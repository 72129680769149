/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const REGISTER_VIEW_STATES = {
  DO_YOU_HAVE_A_RESERVATION_WITH_US: "DO_YOU_HAVE_A_RESERVATION_WITH_US",
  ENTER_RESERVATION_DETAILS: "ENTER_RESERVATION_DETAILS",
  ENTER_REGISTRATION_DETAILS: "ENTER_REGISTRATION_DETAILS",
  WE_FOUND_YOUR_RESERVATION: "WE_FOUND_YOUR_RESERVATION",
  SEND_CODE: "SEND_CODE",
};

const initialState = {
  viewState: REGISTER_VIEW_STATES.DO_YOU_HAVE_A_RESERVATION_WITH_US,
  foundReservationId: null,
  registrationFormValues: null,
};

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setViewState(state, { payload }) {
      state.viewState = payload;
    },

    setFoundReservationId(state, { payload }) {
      state.foundReservationId = payload;
    },

    setRegistrationFormValues(state, { payload }) {
      state.registrationFormValues = payload;
    },

    resetRegistration() {
      return initialState;
    },
  },
});

export const {
  setViewState,
  setFoundReservationId,
  setRegistrationFormValues,
  resetRegistration,
} = registrationSlice.actions;

export default registrationSlice.reducer;
