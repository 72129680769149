import { ofType } from "redux-observable";
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
  concatMap,
} from "rxjs/operators";
import { of, EMPTY } from "rxjs";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { updateUserProfile$ } from "api/tretail/profile";
import {
  fetchProfileFulfilled,
  updateUserProfile,
  updateUserProfileCancel,
  updateUserProfileFailed,
  updateUserProfileFulfilled,
} from "store/profile/profile.slice";
import { fetchProfileWithBookingHistory$ } from "store/profile/epics/fetchProfile";
import catchInternalServerError from "store/catchInternalServerError";
import { fetchBookingHistoryFulfilled } from "store/bookingHistory";
import { fetchMemberDetailsFulfilled } from "store/memberDetails";

export default (action$) =>
  action$.pipe(
    ofType(updateUserProfile.type),

    switchMap(({ payload }) => {
      return ajaxWithHealthCheck$({
        locale: payload.locale,
      }).pipe(
        switchMap(() => {
          return updateUserProfile$(payload).pipe(
            map(updateUserProfileFulfilled),

            catchError(({ response = {} }) =>
              of(
                updateUserProfileFailed({
                  apiErrors: response?.apiErrors || [],
                  supplierErrors: response?.supplierErrors || [],
                })
              )
            ),

            takeUntil(action$.pipe(ofType(updateUserProfileCancel.type)))
          );
        })
      );
    })
  );

export const updateProfileTemplate =
  ({ actionType, fulfillAction = () => EMPTY, updateFn }) =>
  (action$, state$) =>
    action$.pipe(
      ofType(actionType),
      withLatestFrom(state$),
      concatMap(
        ([
          {
            payload: { locale, newValue },
          },
          state,
        ]) => {
          const {
            profile: { data },
          } = state;

          return ajaxWithHealthCheck$({
            locale,
          }).pipe(
            concatMap(() => {
              return updateUserProfile$({
                body: {
                  ...data, // some fields needed here
                  ...updateFn(data, newValue, state),
                },
                locale,
              }).pipe(
                switchMap(() => {
                  return fetchProfileWithBookingHistory$({ locale }).pipe(
                    mergeMap(({ profile, bookingHistory, memberDetails }) => {
                      return [
                        fetchMemberDetailsFulfilled(memberDetails),
                        fetchBookingHistoryFulfilled(bookingHistory),
                        fetchProfileFulfilled(profile),
                        fulfillAction(),
                      ];
                    })
                  );
                }),

                catchInternalServerError(),

                catchError(({ response = {} }) =>
                  of(
                    updateUserProfileFailed({
                      apiErrors: response?.apiErrors || [],
                      supplierErrors: response?.supplierErrors || [],
                    })
                  )
                ),
                takeUntil(action$.pipe(ofType(updateUserProfileCancel.type)))
              );
            })
          );
        }
      )
    );
