import React from "react";

import { useTranslation } from "hooks";

import { Button } from "Profile/components";
import Modal from "Profile/ProfileView/Modal";

function ExitTransportationFormModal({ onCancel, onContinue }) {
  const { t } = useTranslation();

  return (
    <Modal close={onContinue} className="transportation-modal">
      <div className="transportation-modal__container">
        <button
          type="button"
          onClick={onContinue}
          aria-label={t("Close")}
          className="transportation-modal__container__close-btn"
        >
          <span className="icon icon-close" />
        </button>

        <div className="transportation-modal__container__content">
          <h3>{t("Confirmation")}</h3>
          <p>
            {t("Any changes you made on this form will not be saved.")}
            &nbsp;
            {t("Click Continue to return to the form, or Cancel to exit.")}
          </p>
          <div>
            <Button kind="secondary" onClick={onCancel}>
              {t("Cancel")}
            </Button>

            <Button kind="primary" onClick={onContinue}>
              {t("Continue")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ExitTransportationFormModal;
