import { getSearchFormValuesFromQueryString } from "BookingFlow/utils";
import { convertBookingCodesToArrays } from "utils/utils";

export default function canDeepLinkToConfirmYourStayByBookingId(search) {
  const {
    hotelCode,
    dates: { checkIn, checkOut },
    rooms,
    bookingId,
  } = convertBookingCodesToArrays(
    getSearchFormValuesFromQueryString(search, new Date())
  );
  return [hotelCode, checkIn, checkOut, rooms, bookingId].every(Boolean);
}
