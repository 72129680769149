/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  error: "",
};

const exchangeRatesSlice = createSlice({
  name: "exchangeRates",
  initialState,
  reducers: {
    fetchExchangeRates(state) {
      state.error = "";
    },

    fetchExchangeRatesFulfilled(state, { payload: { exchangeRates } }) {
      state.data = exchangeRates;
      state.error = "";
    },

    fetchExchangeRatesFailed(state, { payload }) {
      state.data = [];
      state.error = payload.error;
    },

    fetchExchangeRatesCancel() {},

    setCurrencyCode() {},
  },
});

export const {
  fetchExchangeRates,
  fetchExchangeRatesFulfilled,
  fetchExchangeRatesFailed,
  fetchExchangeRatesCancel,
  setCurrencyCode,
} = exchangeRatesSlice.actions;

export default exchangeRatesSlice.reducer;
