import { ofType } from "redux-observable";
import get from "lodash/fp/get";
import { switchMap, withLatestFrom } from "rxjs/operators";
import { iif, of, EMPTY } from "rxjs";
import { push } from "connected-react-router";
import { matchPath } from "react-router";

import { cancelBookingFulfilled } from "store/bookings";
import { profilePath, profileBookingPath } from "Profile/profileRoutes";
import { selectLocale } from "store/app";

export default (action$, state$) =>
  action$.pipe(
    ofType(cancelBookingFulfilled.type),
    withLatestFrom(state$),
    switchMap(([_, state]) =>
      iif(
        () =>
          matchPath(
            get(["router", "location", "pathname"])(state),
            profileBookingPath.path
          )?.isExact,
        of(
          push({
            pathname: profilePath.to({ locale: selectLocale(state) }),
          })
        ),
        EMPTY
      )
    )
  );
