import { ajax } from "rxjs/ajax";

import env from "config/env";
import isMobileApp from "utils/isMobileApp";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;
const TRETAIL_API_BOOKINGS_URL = `${TRETAIL_API_BASE_URL}/bookings`;

function getSalesChannelHeader(salesChannel) {
  if (salesChannel) {
    return { "Sales-Channel": salesChannel.toUpperCase() };
  }
  if (isMobileApp()) {
    return { "Sales-Channel": "APP" };
  }
  return {};
}

export const makeReservation$ = ({
  bookingId,
  gRecaptchaResponse,
  locale,
  alipayToken,
  salesChannel,
  bookingEmailOptions,
}) => {
  let requestBody;
  if (alipayToken) {
    requestBody = {
      alipayToken,
    };
  } else {
    requestBody = {
      gRecaptchaResponse,
      bookingEmailOptions,
    };
  }

  return ajax({
    ...basePostRequest({
      locale,
      headers: getSalesChannelHeader(salesChannel),
    }),
    url: `${TRETAIL_API_BOOKINGS_URL}/${bookingId}/reservation`,
    body: requestBody,
  });
};

export default makeReservation$;
