/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import smoochSignOut from "utils/smoochSignOut";

const initialState = {
  data: {},
  errors: [],
  apiErrors: [],
  supplierErrors: [],
  verifyYourEmailErrors: {
    apiErrors: [],
    supplierErrors: [],
  },
  sendFFEmailStatus: "",
};

const profileActions = createSlice({
  name: "profile",
  initialState,
  reducers: {
    fetchProfile() {},
    fetchProfileFulfilled(state, { payload: profile }) {
      state.data = profile;
      state.errors = [];
      state.apiErrors = [];
      state.supplierErrors = [];
    },
    fetchProfileFailed(state, { payload }) {
      state.data = {};
      state.errors = payload.errors || [];
      state.apiErrors = payload.apiErrors || [];
      state.supplierErrors = payload.supplierErrors || [];
    },
    fetchProfileCancel() {},

    checkUpdateEmailValidated() {},

    createProfile(state) {
      state.errors = [];
    },
    createProfileFulfilled(state) {
      state.errors = [];
      state.apiErrors = [];
      state.supplierErrors = [];
    },
    createProfileFailed(state, { payload }) {
      state.errors = payload.errors || [];
      state.apiErrors = payload.apiErrors || [];
      state.supplierErrors = payload.supplierErrors || [];
    },
    createProfileCancel() {},

    clearProfileErrors(state) {
      state.errors = [];
      state.apiErrors = [];
      state.supplierErrors = [];
      state.verifyYourEmailErrors.apiErrors = [];
      state.verifyYourEmailErrors.supplierErrors = [];
      state.sendFFEmailStatus = "";
    },

    toggleInterest() {},
    toggleInterestFulfilled() {},

    updateUserTitle() {},
    updateUserTitleFulfilled() {},

    updateUserEmail() {},
    updateUserEmailFulfilled() {},

    deleteUserEmail() {},
    deleteUserEmailFulfilled() {},

    createEmailVerifyCode() {},
    createEmailVerifyCodeFulfilled(state) {
      state.verifyYourEmailErrors.apiErrors = [];
      state.verifyYourEmailErrors.supplierErrors = [];
    },
    createEmailVerifyCodeFailed() {},
    createEmailVerifyCodeCancel() {},

    verifyEmailCode() {},
    verifyEmailCodeFulfilled() {},
    verifyEmailCodeFailed(state, { payload }) {
      state.verifyYourEmailErrors.apiErrors = payload.apiErrors || [];
      state.verifyYourEmailErrors.supplierErrors = payload.supplierErrors || [];
    },
    verifyEmailCodeCancel() {},
    verifyEmailCodeClear(state) {
      state.verifyYourEmailErrors.apiErrors = [];
      state.verifyYourEmailErrors.supplierErrors = [];
    },

    employeeSpecialRateRequest() {},
    employeeSpecialRateRequestFulfilled(state) {
      state.sendFFEmailStatus = "success";
    },
    employeeSpecialRateRequestFailed(state) {
      state.sendFFEmailStatus = "failure";
    },
    employeeSpecialRateRequestCancel() {},

    updateUserPhone() {},
    updateUserPhoneFulfilled() {},
    deleteUserPhone() {},
    deleteUserPhoneFulfilled() {},

    updateUserAddress() {},
    updateUserAddressFulfilled() {},
    deleteUserAddress() {},
    deleteUserAddressFulfilled() {},

    updateCountryAndLanguage() {},
    updateCountryAndLanguageFulfilled() {},

    updateEmailSubscriptions() {},
    updateEmailSubscriptionsFulfilled() {},

    updateUserProfile() {},
    updateSleepPreferences() {},
    updateSleepPreferencesFulfilled() {},
    updateRoomPreference() {},
    updateRoomPreferenceFulfilled() {},
    updateUserProfileFulfilled() {},
    updateUserProfileFailed: (state, { payload }) => {
      state.errors = payload.errors || [];
      state.apiErrors = payload.apiErrors || [];
      state.supplierErrors = payload.supplierErrors || [];
    },
    updateUserProfileCancel() {},

    activateEmployeeProfile() {},
    activateEmployeeProfileFulfilled() {},
    activateEmployeeProfileFailed: (state, { payload }) => {
      state.errors = payload.errors || [];
      state.apiErrors = payload.apiErrors || [];
      state.supplierErrors = payload.supplierErrors || [];
    },
    activateEmployeeProfileCancel() {},

    logout() {},
    logoutFulfilled: (state) => {
      state.data = {};
      state.errors = [];
      state.apiErrors = [];
      state.supplierErrors = [];
      smoochSignOut();
    },
    logoutFailed: (state, { payload }) => {
      state.errors = payload.errors || [];
      state.apiErrors = payload.apiErrors || [];
      state.supplierErrors = payload.supplierErrors || [];
    },

    logoutCancel() {},
  },
});

export const {
  activateEmployeeProfile,
  activateEmployeeProfileCancel,
  activateEmployeeProfileFailed,
  activateEmployeeProfileFulfilled,
  checkUpdateEmailValidated,
  clearProfileErrors,
  createEmailVerifyCode,
  createEmailVerifyCodeCancel,
  createEmailVerifyCodeFailed,
  createEmailVerifyCodeFulfilled,
  createProfile,
  createProfileCancel,
  createProfileFailed,
  createProfileFulfilled,
  deleteUserAddress,
  deleteUserAddressFulfilled,
  deleteUserEmail,
  deleteUserEmailFulfilled,
  deleteUserPhone,
  deleteUserPhoneFulfilled,
  employeeSpecialRateRequest,
  employeeSpecialRateRequestCancel,
  employeeSpecialRateRequestFailed,
  employeeSpecialRateRequestFulfilled,
  fetchProfile,
  fetchProfileCancel,
  fetchProfileFailed,
  fetchProfileFulfilled,
  logout,
  logoutCancel,
  logoutFailed,
  logoutFulfilled,
  toggleInterest,
  toggleInterestFulfilled,
  updateCountryAndLanguage,
  updateCountryAndLanguageFulfilled,
  updateEmailSubscriptions,
  updateEmailSubscriptionsFulfilled,
  updateRoomPreference,
  updateRoomPreferenceFulfilled,
  updateSleepPreferences,
  updateSleepPreferencesFulfilled,
  updateUserAddress,
  updateUserAddressFulfilled,
  updateUserEmail,
  updateUserEmailFulfilled,
  updateUserPhone,
  updateUserPhoneFulfilled,
  updateUserProfile,
  updateUserProfileCancel,
  updateUserProfileFailed,
  updateUserProfileFulfilled,
  updateUserTitle,
  updateUserTitleFulfilled,
  verifyEmailCode,
  verifyEmailCodeCancel,
  verifyEmailCodeClear,
  verifyEmailCodeFailed,
  verifyEmailCodeFulfilled,
} = profileActions.actions;

export default profileActions.reducer;
