import React from "react";

import SearchForm from "../SearchForm";
import usePlanYourStay from "../hooks/usePlanYourStay";

export default function CheckAvailabilityWidget() {
  const searchData = usePlanYourStay({ isEmbedded: true });

  return <SearchForm isEmployeeRate={false} searchData={searchData} />;
}
