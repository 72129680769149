import React from "react";

import { useTranslation } from "hooks";

export default function ServicesAndAmenitiesContent({
  servicesAndAmenityGroups,
}) {
  const { t } = useTranslation();

  return (
    <div className="info-block amenities">
      <h4 className="visuallyhidden">{t("Services & Amenities")}</h4>
      {servicesAndAmenityGroups.map(({ servicesAndAmenities }, index) => (
        <div className="columns" key={index}>
          {servicesAndAmenities.map(({ title, amenities }) => (
            <div className="amenities-list" key={title}>
              <h5>{title}</h5>
              <ul className="amenities-item-list">
                {amenities.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
