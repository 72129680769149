import React from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "hooks";
import { Button } from "Profile/components";
import { getFormattedDateRange } from "BookingFlow/utils";
import {
  selectFoundBooking,
  selectFoundBookingCustomer,
} from "store/registration";

export default function WeFoundYourReservation({
  onSubmit = () => {},
  onCancel = () => {},
}) {
  const { t, locale } = useTranslation();
  const booking = useSelector(selectFoundBooking);
  const bookingCustomer = useSelector(selectFoundBookingCustomer);

  const reservationIds = booking.hotelProducts
    .map(({ reservationId }) => `#${reservationId}`)
    .join(", ");

  const { surname } = bookingCustomer.name;

  const stayDates = getFormattedDateRange(
    booking.startDate,
    booking.endDate,
    locale,
    "long"
  );

  return (
    <div className="view view--register-reservation-found">
      <h1>{t("We found your reservation")}</h1>
      <p className="intro">
        {t("Please review your reservation details below.")}
      </p>

      <div className="panels">
        <div className="panel">
          <div className="reservation-details">
            <div
              className="reservation-details__row"
              ng-if="vm.reservationSummary.lastName.length > 0"
            >
              <div className="reservation-details__row__label">
                {t("Last name")}
              </div>
              <div className="reservation-details__row__value">{surname}</div>
            </div>
            <div className="reservation-details__row">
              <div className="reservation-details__row__label">
                {t("Hotel")}
              </div>
              <div className="reservation-details__row__value">
                {reservationIds}
              </div>
            </div>
            <div className="reservation-details__row">
              <div className="reservation-details__row__label">
                {t("Stay dates")}
              </div>
              <div className="reservation-details__row__value">{stayDates}</div>
            </div>
          </div>
        </div>

        <div className="panel">
          <p>{t("Continue to create your account.")}</p>

          <div className="view--register-reservation-found__actions">
            <Button onClick={onSubmit}>{t("Use email or phone")}</Button>
          </div>
        </div>
      </div>

      <div className="view__actions">
        <div className="view__action">
          <Button kind="secondary" onClick={onCancel}>
            {t("Back")}
          </Button>
        </div>
      </div>
    </div>
  );
}
