import { ajax } from "rxjs/ajax";

import env from "config/env";
import { baseDeleteRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

const removeItem$ = ({ bookingId, productId, locale }) =>
  ajax({
    ...baseDeleteRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/bookings/${bookingId}/products/${productId}`,
  });

export default removeItem$;
