import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import get from "lodash/fp/get";

import { useTranslation, useDispatchWithLocale } from "hooks";
import { selectPropertyContent } from "store/propertyContent";
import getInitialArrivalTime from "utils/getInitialArrivalTime";
import {
  selectBookingByReservationId,
  modifyHotelProductCust,
} from "store/bookings";
import { Button } from "Profile/components";
import { formatArrivalTime } from "utils/utils";
import { getDefaultCheckInTime } from "utils";
import ArrivalTimeSelects from "../components/ArrivalTimeSelects";

export default function EditArrivalTimeForm({ soonestBooking }) {
  const { t, locale } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();
  const {
    name,
    hotelCode,
    reservationId,
    checkInTime: unformattedCheckInTime,
  } = get(["hotelProducts", "0"], soonestBooking) || {};
  const { bookingId } = useSelector(
    selectBookingByReservationId({ reservationId })
  );
  const propertyContent = useSelector(selectPropertyContent(hotelCode)) || {};

  const { handleSubmit, control } = useForm({
    mode: "onChange",
    defaultValues: {
      arrivalTime: getInitialArrivalTime(propertyContent)(soonestBooking),
    },
  });
  const checkInTime =
    formatArrivalTime(unformattedCheckInTime) ||
    getDefaultCheckInTime(propertyContent);

  const onSubmit = ({ arrivalTime }) =>
    dispatchWithLocale(
      modifyHotelProductCust({
        bookingId,
        arrivalTime,
        locale,
      })
    );
  return (
    <>
      <label className="fs-profile-edit-arrival-time-msg">
        {t(
          "For a perfectly-timed welcome at {0}, let us know when to expect you.",
          [name]
        )}
      </label>
      <form
        className="fs-profile-edit-arrival-time-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="arrivalTime"
          control={control}
          as={ArrivalTimeSelects}
          disabled={false}
          rules={{ required: true }}
        />
        <Button
          type="submit"
          aria-label={t("Set {0} arrival time", [checkInTime])}
        >
          {t("Set arrival")}
        </Button>
      </form>
    </>
  );
}
