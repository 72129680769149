import {
  updateSleepPreferences,
  updateSleepPreferencesFulfilled,
} from "store/profile/profile.slice";
import { prepareGuestPreferenceListWithSleepPreferences } from "../profile.helpers";
import { updateProfileTemplate } from "./updateProfile";

export default updateProfileTemplate({
  actionType: updateSleepPreferences.type,
  fulfillAction: updateSleepPreferencesFulfilled,
  updateFn: ({ extension = {} } = {}, newValue) => ({
    extension: {
      ...extension,
      guestPreferenceList: prepareGuestPreferenceListWithSleepPreferences(
        extension.guestPreferenceList,
        newValue
      ),
    },
  }),
});
