import React from "react";

export default function CloseIcon() {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m20 .8-.8-.8L10 9.2.8 0 0 .8 9.2 10 0 19.2l.8.8 9.2-9.2 9.2 9.2.8-.8-9.199-9.2z"
        fillRule="evenodd"
      />
    </svg>
  );
}
