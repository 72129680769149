import React from "react";

import { useTranslation } from "hooks";
import { getPropertyContactUsLink } from "utils/externalLinks";
import { OCCUPANCY_ALERTS } from "BookingFlow/utils/checkOccupancy";

/*
OCCUPANCY_ALERT_SPLIT  - Able to distribute occupancy across max rooms(4)
OCCUPANCY_ALERT_ADULTS - Too many children for number of adults.
OCCUPANCY_ALERT_GROUP  - Exceeds occupancy for max rooms
EMPLOYEE_SEARCH        - "We are sorry, there are no longer available rooms on the dates you have requested, as they have sold out since the last calendar update.  Please do not reach out directly to the hotel to inquire about availability."
*/

const OCCUPANCY_MESSAGES = {
  [OCCUPANCY_ALERTS.SPLIT]: (phone, email) => {
    if (phone && email) {
      return "Due to its size, your group is going to need more than one room. Please review our suggestions and adjust to suit your needs. For assistance, please contact the {0} directly at {1} or {2}.";
    }
    if (phone) {
      return "Due to its size, your group is going to need more than one room. Please review our suggestions and adjust to suit your needs. For assistance, please contact the {0} directly at {1}.";
    }
    return "Due to its size, your group is going to need more than one room. Please review our suggestions and adjust to suit your needs. For assistance, please {0} the {1}.";
  },
  [OCCUPANCY_ALERTS.GROUP]: (phone, email) => {
    if (phone && email) {
      return "Based on the occupancy you have selected, we would like to help you make the best arrangements for your group. Please contact the {0} at {1} or {2} to discuss preferred group rates and availability.";
    }
    if (phone) {
      return "Based on the occupancy you have selected, we would like to help you make the best arrangements for your group. Please contact the {0} at {1} to discuss preferred group rates and availability.";
    }
    return "Based on the occupancy you have selected, we would like to help you make the best arrangements for your group. Please {0} the {1} to discuss preferred group rates and availability.";
  },
  [OCCUPANCY_ALERTS.ADULTS]: (phone, email) => {
    if (phone && email) {
      return "Based on the occupancy you have selected, we would like to help you make the best arrangements for your group. Please contact the {0} at {1} or {2} for assistance with this reservation.";
    }
    if (phone) {
      return "Based on the occupancy you have selected, we would like to help you make the best arrangements for your group. Please contact the {0} at {1} for assistance with this reservation.";
    }
    return "Based on the occupancy you have selected, we would like to help you make the best arrangements for your group. Please {0} the {1} for assistance with this reservation.";
  },
  [OCCUPANCY_ALERTS.EMPLOYEE_SEARCH]: () =>
    "We are sorry, there are no longer available rooms on the dates you have requested, as they have sold out since the last calendar update.  Please do not reach out directly to the hotel to inquire about availability.",
};

function getOccupancyMessage({ key, phone, email }) {
  return OCCUPANCY_MESSAGES[key](phone, email);
}

export default function OccupancyAlert({
  title,
  messageKey, // "OCCUPANCY_ALERT_SPLIT" | "OCCUPANCY_ALERT_GROUP" | "OCCUPANCY_ALERT_ADULTS"
  propertyType, // "Hotel" | "Resort"
  phone,
  email,
  propertyUrlName,
  close,
}) {
  const { t, locale } = useTranslation();

  const occupancyMessage = getOccupancyMessage({
    key: messageKey,
    phone,
    email,
  });

  const contactLink = getPropertyContactUsLink({
    locale,
    propertyUrlName,
  });

  const tParams = phone
    ? [
        locale !== "fr" ? propertyType : undefined,
        `<a href="tel:${phone}">${phone}</a>`,
        email && `<a href="mailto:${email}">${email}</a>`,
      ]
    : [
        `<a href="${contactLink}" target="_blank">${t("contact")}</a>`,
        propertyType,
      ];

  return (
    <div className="occupancy-alert-inline">
      <div className="occupancy-alert-message">
        <h3>{t(title)}</h3>
        <div
          dangerouslySetInnerHTML={{ __html: t(occupancyMessage, tParams) }}
        />
        <button type="button" className="icon icon-close" onClick={close}>
          <span className="visuallyhidden">{t("close")}</span>
        </button>
      </div>
    </div>
  );
}
