import React from "react";
import Cleave from "cleave.js/react";

const CleaveInput = React.forwardRef(
  (
    {
      value,
      onChange = () => {},
      cleaveOptions = {},
      asRawValue = false,
      ...props
    },
    ref
  ) => {
    const handleChange = ({ target: { rawValue, value: prettyValue } }) => {
      onChange(asRawValue ? rawValue : prettyValue);
    };
    return (
      <Cleave
        options={cleaveOptions}
        value={value}
        onChange={handleChange}
        ref={ref}
        {...props}
      />
    );
  }
);

export default CleaveInput;
