import React, { useContext } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import FocusLock from "react-focus-lock";
import { RemoveScroll } from "react-remove-scroll";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { useLanguageSupport } from "BookingFlow/hooks";
import { useTranslation } from "hooks";
import { MediaContext } from "contexts/MediaContext";
import { changeLanguage } from "store/app";

export default function LanguageSupportModal({
  useLocale,
  continuePath,
  continueMsg,
  closeModal = () => {},
}) {
  const { t } = useTranslation();
  const media = useContext(MediaContext);
  const dispatch = useDispatch();

  const { alreadyOnContinuePath, heading, continueMessage, phoneContacts } =
    useLanguageSupport({
      useLocale,
      continuePath,
      continueMsg,
      closeModal,
    });

  function handleKeyDown(evt) {
    if (evt.key === "Escape") {
      closeModal();
    }
  }

  const handleOverlayClicked = (evt) => {
    if (evt.target === evt.currentTarget) {
      closeModal();
    }
  };
  return (
    <RemoveScroll>
      <FocusLock returnFocus>
        <div
          className="modal modal--language-support"
          onClick={handleOverlayClicked}
          role="button"
          onKeyDown={handleKeyDown}
          tabIndex="0"
        >
          <div className="modal-dialog" role="dialog">
            <button
              type="button"
              aria-label={t("Close")}
              className="icon-button modal-icon icon icon-close"
              onClick={closeModal}
            />

            <div className="profile-modal-body">
              <p>{heading}</p>

              {phoneContacts.map(({ text, number }) => (
                <p key={number}>
                  <a
                    href={`tel:${number}`}
                    className={classNames({
                      "btn btn-primary": media.isLessThan.xs,
                    })}
                  >
                    {text} : {number}
                  </a>
                </p>
              ))}

              {alreadyOnContinuePath && (
                <p>
                  <button
                    type="button"
                    onClick={closeModal}
                    className="btn-submit"
                  >
                    {continueMessage}
                  </button>
                </p>
              )}

              {continuePath && !alreadyOnContinuePath && (
                <p>
                  <Link
                    to={continuePath}
                    className="btn-submit"
                    onClick={(evt) => {
                      evt.preventDefault();
                      dispatch(changeLanguage({ locale: "en" }));
                      closeModal();
                      dispatch(push(continuePath));
                    }}
                  >
                    {continueMessage}
                  </Link>
                </p>
              )}
            </div>
          </div>
        </div>
      </FocusLock>
    </RemoveScroll>
  );
}
