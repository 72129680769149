import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import FocusLock from "react-focus-lock";
import { RemoveScroll } from "react-remove-scroll";
import classNames from "classnames";
import ThemeContextProvider, { THEMES } from "contexts/ThemeContext";

export default function Modal({
  theme = THEMES.LIGHT,
  close,
  className,
  allowOutsideClickToClose = true,
  allowEscapeKeyToClose = true,
  children,
  ...props
}) {
  const modalContainerRef = useRef();

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.key === "Escape") {
        close();
      }
    }
    if (allowEscapeKeyToClose) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      if (allowEscapeKeyToClose) {
        document.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);

  useEffect(() => {
    const handleOutsideModalClick = (e) => {
      if (modalContainerRef.current === e.target) {
        close();
      }
    };

    if (allowOutsideClickToClose) {
      document.addEventListener("mousedown", handleOutsideModalClick);
    }
    return () => {
      if (allowOutsideClickToClose) {
        document.removeEventListener("mousedown", handleOutsideModalClick);
      }
    };
  }, [allowOutsideClickToClose]);

  return ReactDOM.createPortal(
    <ThemeContextProvider theme={theme}>
      <RemoveScroll>
        <FocusLock returnFocus noFocusGuards>
          <div
            className={classNames("fs-modal-container", className)}
            ref={modalContainerRef}
            {...props}
          >
            {children}
          </div>
        </FocusLock>
      </RemoveScroll>
    </ThemeContextProvider>,
    document.body
  );
}
