import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "hooks";
import "../stylesheets/sass/profile_2018.scss";
import { MediaContext } from "contexts/MediaContext";
import { FsHeader } from "Profile/components";
import { selectIsUserLoggedIn } from "store/profile";
import BookingFlowLoadingIndicator from "BookingFlow/components/BookingFlowLoadingIndicator";
import { isMobileApp } from "utils";
import ToastNotification from "components/ToastNotification";
import ProfileNavigation from "./ProfileNavigation";

function ProfileLayout({ children }) {
  const { i18n } = useTranslation();
  const media = useContext(MediaContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);

  if (!isUserLoggedIn) {
    return <BookingFlowLoadingIndicator opaque />;
  }

  return (
    <div className={`layout--profile ${i18n.dir(i18n.language)}`}>
      {!isMobileApp() && (
        <FsHeader
          isUserLoggedIn={isUserLoggedIn}
          isMobile={media.isLessThan.sm}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        >
          {!media.isLessThan.sm && <ProfileNavigation showBookingsLink />}
        </FsHeader>
      )}

      {!isMobileApp() && <ToastNotification />}

      {children}
    </div>
  );
}

export default ProfileLayout;
