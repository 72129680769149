/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  errors: [],
  activate: false,
};

const employeeProfileSlice = createSlice({
  name: "employeeProfile",
  initialState,
  reducers: {
    fetchEmployeeProfile() {},
    fetchEmployeeProfileFulfilled(state, { payload }) {
      state.data = payload;
      state.errors = [];
      state.activate = false;
    },
    fetchEmployeeProfileFailed(state, { payload }) {
      state.errors = payload.errors || [];
      state.activate = false;
    },
    fetchEmployeeProfileCancel() {},

    setEmployeeActivateProfile(state) {
      state.data = {};
      state.errors = [];
      state.activate = true;
    },

    clearEmployeeProfile(state) {
      state.data = {};
      state.errors = [];
      state.activate = false;
    },
  },
});

export const {
  clearEmployeeProfile,
  fetchEmployeeProfile,
  fetchEmployeeProfileCancel,
  fetchEmployeeProfileFailed,
  fetchEmployeeProfileFulfilled,
  setEmployeeActivateProfile,
} = employeeProfileSlice.actions;

export default employeeProfileSlice.reducer;
