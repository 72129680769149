import { ajax } from "rxjs/ajax";
import omitBy from "lodash/fp/omitBy";
import isEmpty from "lodash/fp/isEmpty";

import env from "config/env";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;
const TRETAIL_API_BOOKINGS_URL = `${TRETAIL_API_BASE_URL}/bookings`;

const addCustomers$ = ({
  bookingId,
  productIds,
  contactType = "guest",
  firstName,
  surname,
  email,
  phoneNumber,
  fsAdvisorId,
  travelAgentId,
  locale,
}) => {
  const customer = {
    name: omitBy(isEmpty, {
      firstName,
      surname,
    }),
  };

  if (contactType === "guest") {
    customer.products = productIds.map((productId) => ({
      productId,
      age: null,
      passengerType: "ADT",
    }));
  }

  if (contactType === "booker" || contactType === "agent") {
    customer.emails = [{ email }];
    customer.phones = [{ number: phoneNumber, phoneType: "MOBILE" }];
    customer.contactType = "BOOK_FOR";
  }

  if (contactType === "agent") {
    customer.fsAdvisorId = fsAdvisorId;
    customer.travelAgentId = travelAgentId;
    customer.contactType = "TRAVEL_AGENT";
  }

  return ajax({
    ...basePostRequest({ locale }),
    url: `${TRETAIL_API_BOOKINGS_URL}/${bookingId}/customers`,
    body: {
      customers: [customer],
    },
  });
};

export default addCustomers$;
