import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";

import { subscribeToAllEmails$ } from "api/emailSubscriptions";
import {
  subscribeToAllCancel,
  subscribeToAllEmails,
  subscribeToAllFailed,
  subscribeToAllFulfilled,
} from "../bookings.slice";

export default function subscribeToAllEmailsEpic(action$) {
  return action$.pipe(
    ofType(subscribeToAllEmails.type),
    switchMap(({ payload }) =>
      subscribeToAllEmails$(payload).pipe(
        map(() => subscribeToAllFulfilled()),

        catchError((response) => {
          console.log("Error Subscribing To All Emails", {
            response,
            bookingId: payload.bookingId,
          });
          return of(subscribeToAllFailed());
        }),

        takeUntil(action$.pipe(ofType(subscribeToAllCancel.type)))
      )
    )
  );
}
