import React from "react";
import classNames from "classnames";
import "./RadioButtons.scss";

export default function RadioButtons({
  id: initialId,
  name,
  errorMessage = false,
  options = [],
  value,
  flexDirection = "column",
  onChange,
  onBlur,
  disabled,
  label,
  isRequired,
  labelFn = (lbl) => lbl,
  radioIsButton = false,
}) {
  const id = initialId || `field--${name}`;

  const radiosClassName = classNames(`radios`, {
    "radios--row": flexDirection === "row",
    "radios-cta": radioIsButton,
  });

  const labelToDisplay = isRequired ? `${label}*` : label;
  const optionValue = options?.find((option) => option.value === value);

  const hasError =
    (typeof errorMessage === "boolean" && errorMessage === true) ||
    errorMessage.length > 0;

  return (
    <>
      {labelToDisplay ? (
        <label
          className={classNames("ty-c4", {
            "formElement-label--invalid fs-error-copy": hasError,
          })}
        >
          {labelFn(labelToDisplay, optionValue?.label || value || "")}
        </label>
      ) : undefined}

      <div id={id} role="radiogroup" className={radiosClassName}>
        {options.map((option) => {
          return (
            <label
              className={classNames("radio ty-b2", {
                "CTA radio-cta": radioIsButton,
                "CTA--secondary--light":
                  radioIsButton && option.value !== value,
                "CTA--primary--light": radioIsButton && option.value === value,
              })}
              key={option.value}
              disabled={option.disabled || disabled}
            >
              <input
                type="radio"
                name={name}
                value={option.value}
                checked={option.value === value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={option.disabled || disabled}
              />
              <span className="radio__checkmark" />
              {option.label}
            </label>
          );
        })}
      </div>

      {errorMessage && (
        <span
          className="ButtonOptions__errorMessage formElement-message fs-error-copy"
          role="alert"
        >
          {errorMessage}
        </span>
      )}
    </>
  );
}
