import { getRoomsData } from "rbf-content-mapping";
import flow from "lodash/fp/flow";

import { selectPropertyAccommodationsContent } from "store/accommodationsContent";
import { selectPropertyBookingMessagesContent } from "store/bookingMessages";
import {
  selectSearchResults,
  selectSearchResultsApiErrors,
  selectSearchResultsSupplierErrors,
} from "store/searchResults";
import { selectPropertyOffersContent } from "store/offersContent";
import {
  selectPropertyContent,
  selectPropertyCurrency,
} from "store/propertyContent";
import { selectExchangeRates } from "store/exchangeRates";
import { selectGlobalSettings } from "store/globalSettings";
import { selectTaxesContent } from "store/taxesContent";
import { getRoomsWithPackages } from "BookingFlow/ChooseYourRoom/utils";
import { SORT_BY_RATE } from "fixtures/constants";
import { isEmployeeRate } from "BookingFlow/utils";
import updateOffersWithOfferTermsAndConditions from "./updateOffersWithOfferTermsAndConditions";
import updateRoomWithIsBestRate from "./updateRoomWithIsBestRate";

const selectRoomsData =
  ({
    currencyCode,
    geoIpCountryCode,
    hasInteractedWithAccessiblityOption = false,
    hotelCode,
    isAccessible = false,
    ppMode: isPreferredPartners = false,
    locale,
    promoCode,
    ratePlanCode,
    roomOwsCodes,
    roomOfferCode,
    roomOwsCode,
    roomSelections = [],
    selectedPackages = [],
    sortByHighestToLowestRate = SORT_BY_RATE.BY_RATE,
    dates,
  }) =>
  (state) => {
    const roomSelectionsWithPackages = getRoomsWithPackages({
      rooms: roomSelections,
      packages: selectedPackages,
    });

    const employeeMode = isEmployeeRate({ promoCode });

    const results = selectSearchResults(state);

    const { searchResults, ...result } = getRoomsData({
      content: {
        accommodations: selectPropertyAccommodationsContent(hotelCode)(state),
        availabilityBookingMessages: selectPropertyBookingMessagesContent({
          hotelCode,
          bookingStep: "availability",
        })(state),
        offers: selectPropertyOffersContent(hotelCode)(state),
        property: selectPropertyContent(hotelCode)(state),
        exchangeRates: { exchangeRates: selectExchangeRates(state) },
        globalSettings: selectGlobalSettings(state) || {},
        taxes: { taxes: selectTaxesContent(hotelCode)(state) || [] },
      },
      employee: employeeMode,
      geoIpCountryCode,
      language: locale,
      oj: {
        results,
        apiErrors: selectSearchResultsApiErrors(state),
        supplierErrors: selectSearchResultsSupplierErrors(state),
      },
      pp: isPreferredPartners,
      promoCode,
      ratePlanCode: ratePlanCode || roomOfferCode,
      roomOwsCodes: roomOwsCodes || roomOwsCode,
      roomsState: {
        accessible: isAccessible,
        currency: currencyCode || selectPropertyCurrency(hotelCode)(state),
        roomSelections: roomSelectionsWithPackages,
        sort: sortByHighestToLowestRate,
        hasInteractedWithAccessiblityOption,
      },
    });

    return {
      ...result,
      searchResults: flow(
        updateOffersWithOfferTermsAndConditions({
          hotelCode,
          checkinDate: dates.checkIn,
          checkoutDate: dates.checkOut,
          promoCode,
          state,
        }),
        updateRoomWithIsBestRate
      )(searchResults || []),
    };
  };

export default selectRoomsData;
