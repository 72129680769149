import React, { useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { setView } from "store/app";
import { useDispatchWithLocale, useTranslation } from "hooks";
import { selectIsLoadingRequests } from "store/apiRequestStates";
import {
  createProfile,
  createProfileCancel,
  selectIsUserLoggedIn,
} from "store/profile";
import {
  generateOtp,
  generateOtpCancel,
  reset as resetSignIn,
} from "store/signIn";
import {
  fetchHistoricBookingForRegistration,
  fetchHistoricBookingForRegistrationCancel,
} from "store/bookings";
import {
  setViewState,
  selectRegistrationFormValues,
  selectViewState,
  selectFoundBookingCustomerForRegistration,
} from "store/registration";
import { REGISTER_VIEW_STATES } from "store/registration/registration.slice";
import { profileRoutes } from "Profile";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import doesMeetRussianCriteria from "utils/doesMeetRussianCriteria";
import { getGeoIPCode } from "utils/geoIP";
import { RUSSIA } from "fixtures/constants";
import { getConsentAcceptanceTimestamp } from "utils/utils";
import RussiaConfirmationModal from "components/RussiaConfirmationModal";
import DoYouHaveAReservationWithUs from "./DoYouHaveAReservationWithUs";
import EnterReservationDetails from "./EnterReservationDetails";
import EnterRegistrationDetails from "./EnterRegistrationDetails";
import WeFoundYourReservation from "./WeFoundYourReservation";
import SendCode from "./SendCode";

export default function RegisterView({
  employeeMode = false,
  afterSignInRedirectTo,
}) {
  const history = useHistory();
  const { locale } = useTranslation();
  const dispatch = useDispatch();
  const dispatchWithLocale = useDispatchWithLocale();

  const queryParams = queryString.parse(useLocation()?.search || "");

  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);

  useEffect(() => {
    if (isUserLoggedIn) {
      history.replace(profileRoutes.profilePath.to({ locale }));
    }
  }, [isUserLoggedIn]);

  useEffect(() => {
    dispatch(setView("RegisterView"));
    dispatch(resetSignIn());
    return () => {
      dispatch(setView(""));
      dispatchWithLocale(generateOtpCancel());
      dispatchWithLocale(createProfileCancel());
      dispatchWithLocale(fetchHistoricBookingForRegistrationCancel());
    };
  }, []);

  const viewState = useSelector(selectViewState);
  const isLoading = useSelector(
    selectIsLoadingRequests([
      createProfile.type,
      generateOtp.type,
      fetchHistoricBookingForRegistration.type,
    ])
  );
  const bookingCustomer = useSelector(
    selectFoundBookingCustomerForRegistration
  );

  const previousRegistrationValues = useSelector(selectRegistrationFormValues);
  const initialRegistrationValues =
    previousRegistrationValues || bookingCustomer || {};

  const {
    DO_YOU_HAVE_A_RESERVATION_WITH_US,
    ENTER_RESERVATION_DETAILS,
    ENTER_REGISTRATION_DETAILS,
    WE_FOUND_YOUR_RESERVATION,
    SEND_CODE,
  } = REGISTER_VIEW_STATES;

  const [russianCitizenHandler, setRussianCitizenHandler] = useState(undefined);
  const closeRussianCitizenModal = () => setRussianCitizenHandler(undefined);

  const dispatchCreateProfile = (formValues) =>
    dispatchWithLocale(
      createProfile({
        ...formValues,
        employeeMode,
      })
    );

  const onRussianCitizenSubmit =
    (formValues) =>
    () =>
    ({ isRussianCitizen }) => {
      closeRussianCitizenModal();
      dispatchCreateProfile({
        ...formValues,
        ...(isRussianCitizen
          ? {
              nationality: RUSSIA.CODE,
              consentAcceptanceTimestamp: getConsentAcceptanceTimestamp(),
            }
          : {}),
      });
    };

  const handleSubmitRegistrationDetails = (formValues) => {
    if (
      doesMeetRussianCriteria({
        geoIPCode: getGeoIPCode(),
        countryAddress: formValues.countryCode,
      })
    ) {
      setRussianCitizenHandler(onRussianCitizenSubmit(formValues));
    } else {
      dispatchCreateProfile(formValues);
    }
  };

  const handleSubmitReservationDetails = ({
    reservationId,
    hotelCode,
    surname,
    creditCardExpiryDate,
    creditCardNumber,
    firstName,
    reservationStartDate,
  }) => {
    dispatchWithLocale(
      fetchHistoricBookingForRegistration({
        reservationId,
        hotelCode,
        surname,
        creditCardExpiryDate,
        creditCardNumber,
        firstName,
        reservationStartDate,
      })
    );
  };

  const handleSubmitFoundReservation = () => {
    dispatch(setViewState(REGISTER_VIEW_STATES.ENTER_REGISTRATION_DETAILS));
  };

  const handleSubmitSendCode = (formValues) => {
    dispatchWithLocale(
      generateOtp({
        ...formValues,
        shouldRedirectToSignIn: true,
        employeeMode,
        queryParams,
        afterSignInRedirectTo,
      })
    );
  };

  useLayoutEffect(() => {
    if (employeeMode) {
      dispatch(setViewState(ENTER_REGISTRATION_DETAILS));
    }
  }, [employeeMode]);

  function renderView() {
    switch (viewState) {
      case DO_YOU_HAVE_A_RESERVATION_WITH_US:
        return (
          <DoYouHaveAReservationWithUs
            onSubmit={({ hasReservation }) => {
              dispatch(
                setViewState(
                  hasReservation
                    ? ENTER_RESERVATION_DETAILS
                    : ENTER_REGISTRATION_DETAILS
                )
              );
            }}
          />
        );

      case ENTER_RESERVATION_DETAILS:
        return (
          <EnterReservationDetails
            onSubmit={handleSubmitReservationDetails}
            onCancel={() =>
              dispatch(setViewState(DO_YOU_HAVE_A_RESERVATION_WITH_US))
            }
          />
        );

      case WE_FOUND_YOUR_RESERVATION:
        return (
          <WeFoundYourReservation
            onSubmit={handleSubmitFoundReservation}
            onCancel={() => dispatch(setViewState(ENTER_RESERVATION_DETAILS))}
          />
        );

      case SEND_CODE:
        return (
          <SendCode
            onSubmit={handleSubmitSendCode}
            onCancel={() => dispatch(setViewState(ENTER_REGISTRATION_DETAILS))}
          />
        );

      default:
        return (
          <>
            <EnterRegistrationDetails
              defaultValues={initialRegistrationValues}
              employeeMode={employeeMode}
              onSubmit={handleSubmitRegistrationDetails}
              onCancel={() =>
                dispatch(setViewState(DO_YOU_HAVE_A_RESERVATION_WITH_US))
              }
            />
            {Boolean(russianCitizenHandler) && (
              <RussiaConfirmationModal
                onSubmit={russianCitizenHandler}
                onClose={closeRussianCitizenModal}
              />
            )}
          </>
        );
    }
  }

  return (
    <>
      {renderView()}
      {isLoading && <BookingFlowLoadingIndicator />}
    </>
  );
}
