import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { format } from "date-fns";

import { useDispatchWithLocale, useTranslation } from "hooks";
import {
  selectTechnicalErrorMessage,
  selectIsOfflineForProperty,
  selectOfflineMessageForProperty,
  selectChatStatus,
} from "store/appStatus";
import { clearBookingInProgress } from "store/bookings";
import { useCheckOccupancy } from "BookingFlow/hooks";
import {
  hasSearchQueryParams,
  getSearchFormValuesFromQueryString,
} from "BookingFlow/utils";
import { selectProperty } from "store/properties";
import {
  fetchCalendarAvailability,
  fetchCalendarAvailabilityCancel,
} from "store/calendarAvailability";
import {
  fetchEmployeeCalendarAvailability,
  fetchEmployeeCalendarAvailabilityCancel,
  selectAllEmployeeCalendarAvailability,
} from "store/employeeCalendarAvailability";
import { selectIsEmployeeProfile } from "store/employeeProfile";
import { selectPropertyContent } from "store/propertyContent";
import {
  clearErrors,
  dismissMissingLanguage,
  selectEmployeeSearchError,
  selectMissingLanguageError,
  submitSearch,
  selectSearchResultsError,
} from "store/searchResults";
import getWebChatStatus from "utils/getWebChatStatus";
import { STEPS } from "fixtures/constants";
import { MediaContext } from "contexts/MediaContext";
import { selectProfile } from "store/profile";
import { setParams } from "store/webChat";
import { dateFormat } from "../DateRangePicker/calendarHelpers";
import useCalendarDefaults from "./useCalendarDefaults";
import { getData } from "../planYourStay.selectors";

const getPropertiesAsList = (propertiesData = []) => {
  return propertiesData.reduce((acc, { properties, title }) => {
    return [
      ...acc,
      ...properties
        .filter(({ code }) => code)
        .map((a) => ({ ...a, regionTitle: title })),
    ];
  }, []);
};

export default function usePlanYourStay({ isEmbedded = false, employee } = {}) {
  const location = useLocation();
  const media = useContext(MediaContext);
  const { t, locale } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();

  const searchParams = queryString.parse(location.search || "");
  const {
    defaultAdults,
    defaultChildren,
    ppMode: isPreferredPartners = false,
    ppUrl: ppReturnUrl,
  } = searchParams;

  const isTechnicalError = useSelector(selectTechnicalErrorMessage);

  const {
    hotelCode: initialHotelCode,
    promoCode: initialPromoCode,
    rooms: initialRooms,
    flexDatesWindow: initialFlexDatesWindow,
  } = getSearchFormValuesFromQueryString(location.search, new Date());

  const { occupancyError, dismissOccupancyError } = useCheckOccupancy();

  const { checkIn: checkInParam, focusOn: initialFocus } = queryString.parse(
    location.search || ""
  );

  const areAllNecessarySearchParamsAvailable = hasSearchQueryParams(
    location.search
  );

  const [hotelCode, setHotelCode_] = useState(initialHotelCode);
  const { startDate, endDate } = useCalendarDefaults({ hotelCode });
  const [hasUserSelectedCheckIn, setHasUserSelectedCheckIn] = useState(
    Boolean(checkInParam)
  );
  const [checkIn, setCheckIn] = useState(format(startDate, dateFormat));
  const [checkOut, setCheckOut] = useState(format(endDate, dateFormat));
  const [rooms, setRooms] = useState(
    initialRooms || [
      {
        adults: defaultAdults,
        children: defaultChildren,
      },
    ]
  );
  const [promoCode, setPromoCode] = useState(initialPromoCode);
  const [flexDatesWindow, setFlexDatesWindow] = useState(
    initialFlexDatesWindow || ""
  );

  useEffect(() => {
    if (!hotelCode) {
      setFlexDatesWindow("");
    }
  }, [hotelCode]);

  const selectedProperty = useSelector(selectProperty(hotelCode));
  const propertyContent = useSelector(selectPropertyContent(hotelCode));
  const isOfflineForProperty = useSelector(
    selectIsOfflineForProperty(hotelCode)
  );
  const propertyOfflineMessage = useSelector(
    selectOfflineMessageForProperty(hotelCode)
  );
  const employeeMode = useSelector(selectIsEmployeeProfile);
  const employeeSearchError = useSelector(selectEmployeeSearchError);
  const missingLanguageError = useSelector(selectMissingLanguageError);

  const isLoadingSearchResults = useSelector(
    (state) => state.searchResults.isLoading
  );

  const setHotelCode = (nextHotelCode) => {
    setHotelCode_(nextHotelCode);
    if (hotelCode && nextHotelCode !== hotelCode) {
      dispatchWithLocale(clearErrors());
      dispatchWithLocale(dismissMissingLanguage());
    }
  };

  const locationState = location?.state;

  const searchData = useSelector(
    getData({
      hotelCode,
      checkIn,
      promoCode,
      flexDatesWindow,
      searchParams,
      locale,
      isEmbedded,
      employee,
    })
  );

  const profile = useSelector(selectProfile);

  const {
    bookingMessages,
    caw,
    contactUs,
    sisterProperties,
    webChat,
    error: mlError,
    bookingError: planYourStayBookingError,
    suppressBookingError,
  } = searchData;

  const error = useSelector(selectSearchResultsError) || mlError;

  const roomsWithLimitedOccupancies = rooms.map((room) => {
    const maxAdultsForRoom = caw?.cawConfig?.fields?.maxAdults || 10;
    const maxChildrenForRoom = caw?.cawConfig?.fields?.maxChildren || 10;
    const adultsForRoom = Math.min(room.adults, maxAdultsForRoom);
    const childrenForRoom = Math.min(room.children, maxChildrenForRoom);
    return {
      ...room,
      adults: adultsForRoom,
      children: childrenForRoom,
    };
  });

  const bookingError = planYourStayBookingError || locationState?.bookingError;

  const webChatStatus = getWebChatStatus({
    isEnabled: webChat?.enabled,
    currentState: useSelector(selectChatStatus),
    step: STEPS.ONE,
    isUserLoggedIn: !!profile.id,
    isChangingSearch: areAllNecessarySearchParamsAvailable,
    ...media,
  });

  const webChatParams = {
    ...webChat,
    bookingError: bookingError?.messageDictKey?.key
      ? bookingError?.messageDictKey?.key === "error.E0301"
        ? "NO_AVAILABILITY"
        : `${t(bookingError?.messageDictKey?.key)} (${
            bookingError?.messageDictKey?.key
          })`
      : undefined,
  };

  useEffect(() => {
    dispatchWithLocale(setParams(webChatParams));
  }, [JSON.stringify(webChatParams)]);

  const dispatchSubmitSearch = (formValues = {}) => {
    dispatchWithLocale(
      submitSearch({
        ...searchParams,
        ...formValues,
        dates: { checkIn, checkOut },
        promoCode: formValues.promoCode.toUpperCase(),
        focusOn: undefined,
      })
    );
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    dispatchWithLocale(
      submitSearch({
        ...searchParams,
        hotelCode,
        rooms,
        dates: { checkIn, checkOut },
        promoCode: promoCode.toUpperCase(),
        flexDatesWindow: !caw?.cawConfig?.disableFlexDates && flexDatesWindow,
        focusOn: undefined,
      })
    );
  };

  useEffect(() => {
    dispatchWithLocale(clearBookingInProgress);
    dispatchWithLocale(
      employee
        ? fetchEmployeeCalendarAvailability()
        : fetchCalendarAvailability()
    );
    return () => {
      dispatchWithLocale(
        employee
          ? fetchEmployeeCalendarAvailabilityCancel()
          : fetchCalendarAvailabilityCancel()
      );
    };
  }, []);

  const properties = getPropertiesAsList(caw.regions);

  const allPropertyEmployeeAvailability =
    useSelector(selectAllEmployeeCalendarAvailability) || [];

  return {
    areAllNecessarySearchParamsAvailable,
    bookingError: !suppressBookingError && (bookingError || isTechnicalError),
    bookingMessages,
    caw,
    checkIn,
    checkOut,
    contactUs,
    error,
    employeeMode,
    employeeSearchError,
    handleSubmit,
    dispatchSubmitSearch,
    hasUserSelectedCheckIn,
    hotelCode,
    isOfflineForProperty,
    isPreferredPartners,
    isLoadingSearchResults,
    language: locale,
    missingLanguageError,
    ppReturnUrl,
    promoCode,
    properties,
    propertyContent,
    allPropertyEmployeeAvailability,
    propertyOfflineMessage,
    rooms: roomsWithLimitedOccupancies,
    selectedProperty,
    setCheckIn,
    setCheckOut,
    setHasUserSelectedCheckIn,
    setHotelCode,
    setRooms,
    setPromoCode,
    sisterProperties,
    dismissMissingLanguageError: () =>
      dispatchWithLocale(dismissMissingLanguage),
    searchFormKey: JSON.stringify(searchParams),
    occupancyError,
    dismissOccupancyError,
    webChatStatus,
    additionalWebChatInfo: webChat,
    initialFocus,
    flexDatesWindow,
    setFlexDatesWindow,
  };
}
