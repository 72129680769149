import React, { useContext } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { useTranslation } from "hooks";
import { ThemeContext } from "contexts/ThemeContext";
import {
  selectRoomPreference,
  selectPillowPreferences,
  selectMattressPreferences,
} from "store/profile";
import { roomPreferences } from "fixtures/preferences";
import { SignInModal } from "Authentication";
import ToggleBlock from "../ToggleBlock";
import SleepForm from "./SleepForm";
import RoomForm from "./RoomForm";

export default function Preferences({
  canEdit = true,
  enableSignInModal = false,
}) {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);

  const isDark = theme === "dark";

  const roomPreference = useSelector(selectRoomPreference);
  const pillowPreference = useSelector(selectPillowPreferences);
  const mattressPreference = useSelector(selectMattressPreferences);

  const pillowLabel = pillowPreference?.label;
  const mattressLabel = mattressPreference?.label;
  const { label: roomLabel } = roomPreferences.options.find(
    ({ value }) => value === roomPreference
  );

  return (
    <div className="fs-your-preferences">
      <div className="fs-your-preferences__preference fs-your-preferences__preference--sleep">
        <ToggleBlock
          type="edit"
          label={({ toggle, isOpen, isOpening }) => (
            <div className="fs-toggle-block__label">
              <div className="fs-trip__guests__guest__label">
                <div className="fs-your-preferences__preference__title">
                  <div
                    className={classNames("icon", {
                      "icon--dark": !isDark,
                      "icon--light": isDark,
                    })}
                  />
                  <h3>{t("Sleep")}</h3>
                </div>
              </div>
              {canEdit && (
                <>
                  {enableSignInModal ? (
                    <SignInModal
                      id="sleepPreferences"
                      onClick={toggle}
                      className={classNames({
                        "fs-toggle-button fs-toggle-button--edit": isDark,
                        "fs-toggle-button--light fs-toggle-button-light--edit":
                          !isDark,
                        "fs-toggle-button--light-active":
                          (isOpen || isOpening) && !isDark,
                        "fs-toggle-button--active":
                          (isOpen || isOpening) && isDark,
                      })}
                      type="CustomButton"
                      buttonLabel={t("Edit")}
                      ariaLabel={t("Edit your sleep preferences")}
                    />
                  ) : (
                    <button
                      className={classNames({
                        "fs-toggle-button fs-toggle-button--edit": isDark,
                        "fs-toggle-button--light fs-toggle-button-light--edit":
                          !isDark,
                        "fs-toggle-button--light-active":
                          (isOpen || isOpening) && !isDark,
                        "fs-toggle-button--active":
                          (isOpen || isOpening) && isDark,
                      })}
                      onClick={toggle}
                      type="button"
                      aria-label={t("Edit your sleep preferences")}
                    >
                      {t("Edit")}
                    </button>
                  )}
                </>
              )}
            </div>
          )}
        >
          {({ close }) => <SleepForm closeForm={close} />}
        </ToggleBlock>

        <ul className="fs-your-preferences__preference__selected-preferences">
          <li>{t(mattressLabel)}</li>
          <li>{t(pillowLabel)}</li>
        </ul>
      </div>

      <div className="fs-your-preferences__preference fs-your-preferences__preference--room">
        <ToggleBlock
          type="edit"
          label={({ toggle, isOpen, isOpening }) => (
            <div className="fs-toggle-block__label">
              <div className="fs-trip__guests__guest__label">
                <div className="fs-your-preferences__preference__title">
                  <div
                    className={classNames("icon", {
                      "icon--dark": !isDark,
                      "icon--light": isDark,
                    })}
                  />
                  <h3>{t("Room")}</h3>
                </div>
              </div>
              {canEdit && (
                <>
                  {enableSignInModal ? (
                    <SignInModal
                      id="roomPreferences"
                      onClick={toggle}
                      className={classNames({
                        "fs-toggle-button fs-toggle-button--edit": isDark,
                        "fs-toggle-button--light fs-toggle-button-light--edit":
                          !isDark,
                        "fs-toggle-button--light-active":
                          (isOpening || isOpen) && !isDark,
                        "fs-toggle-button--active":
                          (isOpening || isOpen) && isDark,
                      })}
                      type="CustomButton"
                      buttonLabel={t("Edit")}
                      ariaLabel={t("Edit your room preferences")}
                    />
                  ) : (
                    <button
                      className={classNames({
                        "fs-toggle-button fs-toggle-button--edit": isDark,
                        "fs-toggle-button--light fs-toggle-button-light--edit":
                          !isDark,
                        "fs-toggle-button--light-active":
                          (isOpening || isOpen) && !isDark,
                        "fs-toggle-button--active":
                          (isOpening || isOpen) && isDark,
                      })}
                      onClick={toggle}
                      type="button"
                      aria-label={t("Edit your room preferences")}
                    >
                      {t("Edit")}
                    </button>
                  )}
                </>
              )}
            </div>
          )}
        >
          {({ close }) => <RoomForm closeForm={close} />}
        </ToggleBlock>

        <ul className="fs-your-preferences__preference__selected-preferences">
          <li>{t(roomLabel)}</li>
        </ul>
      </div>
    </div>
  );
}
