import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";

import { CONSENT_SOURCE, OPTING_OPTIONS } from "fixtures/constants";
import env from "config/env";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

export default function createUserProfile$({
  formValues,
  orsNameId,
  salesForceId,
  employeeMode = false,
  locale,
}) {
  const body = {
    orsNameId,
    salesForceId,
    address: [formValues.address],
    emails: [formValues.email],
    extension: {
      authenticationStatusList: [
        {
          authenticationType: "EMAIL",
          id: formValues.email.email,
          isAuthenticated: false,
        },
        {
          authenticationType: "MOBILE",
          id: `+${formValues.phoneNumber.number}`,
          isAuthenticated: false,
        },
      ],
      gRecaptchaResponse: formValues.gRecaptchaResponse,
      ...(formValues.nationality && formValues.consentAcceptanceTimestamp
        ? {
            nationality: formValues.nationality,
            consentAcceptanceTimestamp: formValues.consentAcceptanceTimestamp,
          }
        : {}),
      ...(employeeMode ? { employeeId: formValues.workdayId } : {}),
    },
    name: {
      firstName: formValues.firstName,
      surname: formValues.surname,
      title: formValues.title,
    },
    phones: [formValues.phoneNumber],
  };

  if (formValues.consent) {
    body.extension.mailSubscriptionList = [
      {
        consentSource: CONSENT_SOURCE,
        mailingList: "SIGNUPLIST",
        optInOption: OPTING_OPTIONS.IN,
      },
    ];
  }

  return ajax({
    ...basePostRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/profile`,
    body,
  }).pipe(map(() => formValues));
}
