import React from "react";
import { useTranslation } from "hooks";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectName, selectUserState } from "store/profile";
import { SIGN_IN_STATES } from "fixtures/constants";
import { profileRoutes } from "Profile";
import { authenticationRoutes } from "Authentication";
import PersonIcon from "./PersonIcon";
import LockIcon from "./LockIcon";

export default function ProfileStatus({
  locale,
  enableProfileLinkFlyout,
  onClick,
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const userState = useSelector(selectUserState);
  const name = useSelector(selectName);
  const firstName =
    name?.firstName?.length > 8
      ? `${name.firstName.slice(0, 8)}...`
      : name?.firstName;

  const afterSignInRedirectTo = location?.state?.afterFindReservationRedirectTo;

  return (
    <div className="profile-status-container">
      <a
        href={
          userState > SIGN_IN_STATES.ANONYMOUS
            ? profileRoutes.profilePath.to({ locale })
            : authenticationRoutes.signIn.to({
                locale,
                queryParams: { afterSignInRedirectTo },
              })
        }
        data-s-object-id={
          userState !== SIGN_IN_STATES.ANONYMOUS ? "Hello, Name" : undefined
        }
        onClick={enableProfileLinkFlyout ? onClick : undefined}
      >
        <span className="profile-status-icon">
          {userState === SIGN_IN_STATES.PERSISTENT ? (
            <LockIcon />
          ) : (
            <PersonIcon />
          )}
        </span>
        <span className="profile-status-name">
          {userState === SIGN_IN_STATES.ANONYMOUS
            ? t("Sign In")
            : t("Hello, {0}", [firstName])}
        </span>
      </a>
    </div>
  );
}
