import { format, parse } from "date-fns";
import get from "lodash/get";

function convertDate(dateString, inputFormat, outputFormat) {
  return format(parse(dateString, inputFormat, new Date()), outputFormat);
}

function getRoomGuestDefaultValues({
  inputDateFnsFormat,
  outputFormat,
  defaultName,
}) {
  return ({ dateOfBirth, ...roomGuest }) => {
    return {
      ...roomGuest,
      name: roomGuest.name || defaultName || undefined,
      dateOfBirth: dateOfBirth
        ? convertDate(dateOfBirth, inputDateFnsFormat, outputFormat)
        : undefined,
    };
  };
}

export default function addDefaultRoomGuestValuesToComments(
  comments,
  inputDateFnsFormat,
  outputFormat = "yyyy-MM-dd"
) {
  const firstRoomGuestName = get(comments, [0, "roomGuests", 0, "name"]);
  return comments.map(({ roomGuests, ...comment }) => {
    return {
      ...comment,
      roomGuests: roomGuests.map((roomGuest, index) => {
        return getRoomGuestDefaultValues({
          inputDateFnsFormat,
          outputFormat,
          defaultName: index === 0 && firstRoomGuestName,
        })(roomGuest);
      }),
    };
  });
}
