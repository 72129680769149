import React from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "hooks";
import { selectCancelRefs } from "store/bookings";

export default function CancellationSuccess({
  bookingId,
  returnLinkUrl,
  returnLinkText,
}) {
  const { t } = useTranslation();

  const cancelRefs = useSelector(selectCancelRefs(bookingId));
  const cancelRefNumbers = cancelRefs
    .map((cancelRef) => `#${cancelRef.reference}`)
    .join(", ");

  return (
    <div className="modal-content-body-padding">
      <p>
        {t("Your reservation has been cancelled.  Cancellation {0}", [
          cancelRefNumbers,
        ])}
      </p>
      {returnLinkUrl && returnLinkText && (
        <a className="btn btn--primary" href={returnLinkUrl}>
          {t(returnLinkText)}
        </a>
      )}
    </div>
  );
}
