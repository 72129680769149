/* eslint-disable no-unused-vars */
import { createMiddleware } from "redux-beacon";
import {
  activateEmployeeProfileFulfilled,
  createEmailVerifyCodeFulfilled,
  createProfileFulfilled,
  fetchProfile,
  fetchProfileFulfilled,
  selectIsUserFullySignedIn,
  updateUserProfileFulfilled,
  verifyEmailCodeFulfilled,
} from "store/profile";
import { from } from "rxjs";
import store from "store/store";
import { getPersistAt } from "utils/FSSecurityMX";
import { differenceInMilliseconds } from "date-fns";
import { throttle } from "lodash";
import {
  addPackageFulfilled,
  addReservationFulfilled,
  cancelBookingFulfilled,
  completeBookingFulfilled,
  constructBookingByBookingIdFulfilled,
  fetchBookingInProgressFulfilled,
  fetchHistoricBookingForRegistrationFulfilled,
  fetchHistoricBookingFulfilled,
  getRatesForDateChangeFulfilled,
  getUpcomingTripDataFulfilled,
  modifyHotelProductCustFulfilled,
  removeContactCustomerPaymentFulfilled,
  submitBookingPreferencesFulfilled,
  upgradeRoomsFulfilled,
} from "./bookings";
import {
  addRequestEventFulfilled,
  fetchGuestRequestsFulfilled,
} from "./guestRequests";
import {
  fetchBookingCommentsFulfilled,
  updatePostBookingCommentsFulfilled,
  updateProfileCommentsFulfilled,
} from "./bookingComments";
import { fetchBookingFulfilled } from "./webCheckIn";
import { fetchBookingHistoryFulfilled } from "./bookingHistory";
import { fetchGlobalMailSubscriptionsFulfilled } from "./globalMailSubscriptions";
import {
  fetchSearchResultsForResultSetIdFulfilled,
  fetchSearchResultsFulfilled,
} from "./searchResults";
import { fetchStayHistoryFulfilled } from "./stayHistory";
import { submitOtpFulfilled } from "./signIn";

const profileTimeout = 20; // minutes
let profileRefreshID;

const handleEvent = throttle(
  ({ type, payload, profile, locale }) => {
    if (profileRefreshID) {
      window.clearTimeout(profileRefreshID);
    }

    const persistAtDate = getPersistAt();
    const msTimeoutPeriod = persistAtDate
      ? differenceInMilliseconds(new Date(persistAtDate), new Date())
      : profileTimeout * 60 * 1000;
    if (
      (type === fetchProfileFulfilled.type && !payload?.dataRedacted) ||
      selectIsUserFullySignedIn({ profile })
    ) {
      profileRefreshID = window.setTimeout(
        () => {
          store.dispatch(fetchProfile({ locale }));
        },
        msTimeoutPeriod > 0 ? msTimeoutPeriod : profileTimeout * 60 * 1000
      );
    } else {
      profileRefreshID = undefined;
    }
  },
  300,
  { leading: false, trailing: true }
);

const profileRefreshTarget = (events) => {
  from(events).subscribe(handleEvent);
};

const getProfileData = (event, prevState, nextState) => {
  return {
    ...event,
    profile: nextState?.profile,
    locale: nextState?.app?.locale,
  };
};

const ojFulfilledTypes = [
  activateEmployeeProfileFulfilled.type,
  addPackageFulfilled.type,
  addRequestEventFulfilled.type,
  addReservationFulfilled.type,
  cancelBookingFulfilled.type,
  completeBookingFulfilled.type,
  constructBookingByBookingIdFulfilled.type,
  createEmailVerifyCodeFulfilled.type,
  createProfileFulfilled.type,
  fetchBookingCommentsFulfilled.type,
  fetchBookingFulfilled.type,
  fetchBookingHistoryFulfilled.type,
  fetchBookingInProgressFulfilled.type,
  fetchGlobalMailSubscriptionsFulfilled.type,
  fetchGuestRequestsFulfilled.type,
  fetchHistoricBookingForRegistrationFulfilled.type,
  fetchHistoricBookingFulfilled.type,
  fetchProfileFulfilled.type,
  fetchSearchResultsForResultSetIdFulfilled.type,
  fetchSearchResultsFulfilled.type,
  fetchStayHistoryFulfilled.type,
  getRatesForDateChangeFulfilled.type,
  getUpcomingTripDataFulfilled.type,
  modifyHotelProductCustFulfilled.type,
  removeContactCustomerPaymentFulfilled.type,
  submitBookingPreferencesFulfilled.type,
  submitOtpFulfilled.type,
  updatePostBookingCommentsFulfilled.type,
  updateProfileCommentsFulfilled.type,
  updateUserProfileFulfilled.type,
  upgradeRoomsFulfilled.type,
  verifyEmailCodeFulfilled.type,
];

const eventsMap = Object.fromEntries(
  ojFulfilledTypes.map((typeString) => [typeString, getProfileData])
);

export default createMiddleware(eventsMap, profileRefreshTarget);
