/* eslint-disable react/button-has-type */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { ThemeContext } from "contexts/ThemeContext";

export function getButtonClassName({
  className,
  theme = "light",
  kind = "primary",
  rounded = false,
}) {
  return classNames(
    {
      "CTA--secondary--light": kind === "secondary" && theme === "light",
      "CTA--secondary--dark": kind === "primary" && theme === "light",
      "CTA--secondary--light--on-dark":
        kind === "secondary" && theme === "dark",
      "CTA--secondary--dark--on-dark": kind === "primary" && theme === "dark",
      "CTA--secondary--rounded": rounded,
      "CTA--underlined--light": kind === "link" && theme === "light",
      "CTA--underlined--dark": kind === "link" && theme === "dark",
    },
    className
  );
}

const Button = React.forwardRef(
  (
    {
      type = "button",
      kind = "primary",
      rounded = false,
      children,
      className,
      ...props
    },
    ref
  ) => {
    const { theme } = useContext(ThemeContext);
    return (
      <button
        type={type}
        className={getButtonClassName({ theme, kind, rounded, className })}
        ref={ref}
        {...props}
      >
        {children}
      </button>
    );
  }
);

Button.propTypes = {
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  kind: PropTypes.oneOf(["primary", "secondary", "link"]),
  rounded: PropTypes.bool,
};

Button.defaultProps = {
  type: "button",
  kind: "primary",
  rounded: false,
};

export default Button;
