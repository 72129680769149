import React from "react";

import { Link } from "Profile/components";
import ThemeContextProvider, { THEMES } from "contexts/ThemeContext";

export default function YourStayPerfected({
  title,
  content,
  ctaPath,
  ctaLabel,
  image,
}) {
  return (
    <ThemeContextProvider theme={THEMES.DARK}>
      <div className="your-stay-perfected">
        <div className="your-stay-perfected__container">
          <div className="your-stay-perfected__content">
            {title && <h3>{title}</h3>}
            {content && <p>{content}</p>}
          </div>

          {image && (
            <div
              className="your-stay-perfected__image"
              style={{
                backgroundImage: `url("${image}")`,
              }}
            />
          )}

          {ctaPath && ctaLabel && (
            <Link to={ctaPath} kind="secondary">
              {ctaLabel}
            </Link>
          )}
        </div>
      </div>
    </ThemeContextProvider>
  );
}
