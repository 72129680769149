import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs";
import { switchMap, takeUntil, mergeMap, catchError } from "rxjs/operators";

// eslint-disable-next-line import/no-cycle
import getUpsells$ from "api/content/getUpsells/getUpsells";
import {
  fetchUpsellsContent,
  fetchUpsellsContentCancel,
  fetchUpsellsContentFailed,
  fetchUpsellsContentFulfilled,
} from "./upsellsContent.slice";

const fetchUpsellsContentEpic = (action$) =>
  action$.pipe(
    ofType(fetchUpsellsContent.type),
    switchMap(({ payload: { locale, propertyCode } }) =>
      getUpsells$({ locale, propertyCode }).pipe(
        mergeMap(({ upsells = [], error }) =>
          error
            ? of(fetchUpsellsContentFailed({ propertyCode, error }))
            : of(fetchUpsellsContentFulfilled({ propertyCode, upsells }))
        ),
        catchError((error) => of(fetchUpsellsContentFailed(error))),
        takeUntil(action$.pipe(ofType(fetchUpsellsContentCancel.type)))
      )
    )
  );

export default combineEpics(fetchUpsellsContentEpic);
