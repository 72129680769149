import React, { useState, useEffect } from "react";
import classNames from "classnames";

import { useDispatchWithLocale, useTranslation } from "hooks";
import Modal from "BookingFlow/components/Modal";
import env from "config/env";
import { multiRoomUpsellEvt, upgradeRooms } from "store/bookings";

const { IMAGE_BASE_URL } = env;

export default function MultiRoomUpsells({ upsells }) {
  const { t, formatPrice } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();

  const [isUpsellsModalOpen, setIsUpsellsModalOpen] = useState(true);

  const closeModal = () => setIsUpsellsModalOpen(false);

  const [selectedRooms, setSelectedRooms] = useState([]);
  const toggleRoomSelection = (roomIndex) =>
    selectedRooms.includes(roomIndex)
      ? selectedRooms.filter((a) => a !== roomIndex)
      : [...selectedRooms, roomIndex];

  useEffect(() => {
    dispatchWithLocale(multiRoomUpsellEvt());
  }, [isUpsellsModalOpen]);

  if (!isUpsellsModalOpen) return null;

  return (
    <Modal
      title="Would you like to Upgrade your Room?"
      className="new-modal--upsells"
      closeModal={closeModal}
    >
      <ul className="block-list upgrade-option-block">
        {upsells.map(({ roomIndex, roomName, description, image, price }) => (
          <li
            key={roomIndex}
            className="upgrade-option-section multiple-upgrade"
          >
            <div className="upgrade-option-info">
              <div className="col-xs-12 col-sm-8 visible-xs-block">
                <h2>
                  {t("Room {0}:", [roomIndex + 1])}&nbsp;
                  {t("Upgrade to the {0}", [roomName])}
                </h2>
              </div>
              <div>
                {image && (
                  <span
                    className="col-xs-12 col-sm-4 div-image upgrade-option-image"
                    style={{
                      backgroundImage: `url(${IMAGE_BASE_URL}${image.path})`,
                    }}
                    aria-label={roomName}
                    role="img"
                  />
                )}
              </div>
              <div className="col-xs-12 col-sm-7">
                <div className="upgrade-option-price">
                  <h2>
                    {t("Just {0} More/Night", [
                      formatPrice({
                        currency: price.currency,
                        value: price.amount.withDecimal,
                        decimalPlaces: 2,
                      }),
                    ])}
                  </h2>
                </div>
                <h2 className="upgrade-room hidden-xs">
                  {t("Room {0}:", [roomIndex + 1])}&nbsp;
                  {t("Upgrade to the {0}", [roomName])}
                </h2>
                <p dangerouslySetInnerHTML={{ __html: description }} />
                <div className="upgrade-toggle-select">
                  <button
                    className={classNames("btn-plain", {
                      "upgrade-accepted": selectedRooms.includes(roomIndex + 1),
                    })}
                    type="button"
                    onClick={() =>
                      setSelectedRooms(toggleRoomSelection(roomIndex + 1))
                    }
                  >
                    <span>{t("Select")}</span>
                    <i aria-hidden="true" className="icon icon-tick" />
                  </button>
                </div>
              </div>
            </div>
          </li>
        ))}
        <div className="upgrade-multi-select-section">
          <div className="upgrade-multi-select">
            <button
              type="button"
              className="btn btn-primary btn-upgrade-option"
              onClick={() => {
                dispatchWithLocale(
                  upgradeRooms({ roomIndices: selectedRooms })
                );
                closeModal();
              }}
              disabled={selectedRooms.length <= 0}
            >
              {t("Upgrade")}
            </button>
            <button
              type="button"
              className="upgrade-option-decline button-as-link"
              onClick={closeModal}
            >
              {t("No thanks, continue")}
            </button>
          </div>
        </div>
      </ul>
    </Modal>
  );
}
