import React, { useContext } from "react";

import env from "config/env";
import onlyUnique from "utils/onlyUnique";
import { useDispatchWithLocale, useTranslation } from "hooks";
import { MediaContext } from "contexts/MediaContext";
import Modal from "BookingFlow/components/Modal";
import { cancelBooking } from "store/bookings";
import CancellationSuccess from "./CancellationSuccess";
import CancellationFailed from "./CancellationFailed";
import CancellationForm from "./CancellationForm";

const { STATIC_SITE_URL, PREFERRED_PARTNER_DOMAIN, MOBILE_APP_URL_SCHEME } =
  env;

export default function CancellationModal({
  completedBooking,
  isBookingCancelled,
  isBookingCancelledFailed = false,
  isPreferredPartners,
  sendGuestEmail,
  closeModal = () => {},
}) {
  const { t } = useTranslation();
  const { isMobileApp } = useContext(MediaContext);
  const dispatchWithLocale = useDispatchWithLocale();

  const onSubmit = ({ emailList = "" }) => {
    const recipients = emailList
      .split(",")
      .filter(Boolean)
      .map((s) => s.trim())
      .filter(onlyUnique);

    const bookingEmailOptions = {
      additionalRecipients: recipients,
      inBookingFlow: true,
      sendGuestEmail,
    };

    dispatchWithLocale(
      cancelBooking({
        bookingId: completedBooking.bookingId,
        bookingEmailOptions,
      })
    );
  };

  const returnLinkUrl = isMobileApp
    ? MOBILE_APP_URL_SCHEME.returnHome
    : isPreferredPartners
    ? PREFERRED_PARTNER_DOMAIN
    : STATIC_SITE_URL;
  const returnLinkText = isMobileApp ? "HOME" : "Return to Homepage";

  return (
    <Modal
      title={t("Cancel Reservation")}
      className="new-modal--cancellation"
      closeModal={closeModal}
    >
      {isBookingCancelled && (
        <CancellationSuccess
          bookingId={completedBooking.bookingId}
          returnLinkUrl={returnLinkUrl}
          returnLinkText={returnLinkText}
        />
      )}
      {isBookingCancelledFailed && <CancellationFailed />}
      {!isBookingCancelled && !isBookingCancelledFailed && (
        <CancellationForm onSubmit={onSubmit} />
      )}
    </Modal>
  );
}
