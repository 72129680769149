import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import merge from "lodash/merge";

import { useDispatchWithLocale, usePrevious, useTranslation } from "hooks";
import { selectIsRequestLoading } from "store/apiRequestStates";
import {
  submitBookingPreferences,
  submitBookingPreferencesCancel,
} from "store/bookings";
import { addDefaultRoomGuestValuesToComments } from "../utils";

export default function usePreferencesForm({
  bookingId,
  bookingType,
  hasBookingError,
  initialArrivalTime,
  isPreferredPartners,
  postBookingComments,
  reservationId,
  sendGuestEmail,
}) {
  const { dateFormat } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();

  const { comments: initialComments } = postBookingComments;

  const initialAdditionalRequests =
    initialComments[0]?.additionalRequests || "";

  const commentsWithCorrectedDateOfBirths = addDefaultRoomGuestValuesToComments(
    initialComments,
    "yyyy-MM-dd",
    dateFormat.dateFnsParse
  );

  const { control, register, handleSubmit, errors, formState } = useForm({
    mode: "onChange",
    defaultValues: {
      comments: commentsWithCorrectedDateOfBirths,
      arrivalTime: initialArrivalTime,
      tripPurpose: initialComments[0]?.tripPurpose || "",
      additionalRequests: initialComments[0]?.additionalRequests || "",
      adjoiningRooms: initialComments[0]?.adjoiningRooms || false,
      keepRoomsClose: initialComments[0]?.keepRoomsClose || false,
    },
  });

  const isSavingComments = useSelector(
    selectIsRequestLoading(submitBookingPreferences.type)
  );
  const prevIsSavingComments = usePrevious(isSavingComments);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const onSubmit = ({
    tripPurpose,
    additionalRequests,
    adjoiningRooms,
    keepRoomsClose,
    ...formValues
  }) => {
    const cleanedAdditionalRequests = additionalRequests.replace(/[\n\r]/g, "");
    const cleanedTripPurpose = tripPurpose.replace(/[\n\r]/g, "");
    const comments = addDefaultRoomGuestValuesToComments(
      formValues.comments.map((comment, index) => {
        return merge(
          {},
          {
            productId: postBookingComments.comments[index].productId,
            roomGuests: postBookingComments.comments[index].roomGuests,
          },
          {
            tripPurpose: cleanedTripPurpose,
            additionalRequests: cleanedAdditionalRequests,
            adjoiningRooms,
            keepRoomsClose,
          },
          comment
        );
      }),
      dateFormat.dateFnsParse
    );

    dispatchWithLocale(
      submitBookingPreferences({
        bookingId,
        ...formValues,
        bookingType,
        comments,
        ppMode: isPreferredPartners,
        specialRequestChanged: additionalRequests !== initialAdditionalRequests,
        reservationId,
        sendGuestEmail,
      })
    );
  };

  useEffect(() => {
    if (!hasBookingError && prevIsSavingComments && !isSavingComments) {
      setShowSuccessModal(true);
    }
  }, [hasBookingError, isSavingComments, prevIsSavingComments]);

  useEffect(() => {
    return () => dispatchWithLocale(submitBookingPreferencesCancel());
  }, []);

  return {
    register,
    control,
    errors,
    formState,
    isSavingComments,
    showSuccessModal,
    closeSuccessModal: () => setShowSuccessModal(false),
    onSubmit: handleSubmit(onSubmit),
  };
}
