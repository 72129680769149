import React from "react";
import classNames from "classnames";

export default function StarIconFilled({ className }) {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("icon-star--filled", className)}
    >
      <path
        d="M7.54706 6.34586L0.857065 7.39586H0.789565C0.720296 7.41849 0.658107 7.45875 0.609109 7.51269C0.560112 7.56664 0.525993 7.6324 0.510104 7.70352C0.494216 7.77464 0.497105 7.84867 0.518489 7.91833C0.539872 7.988 0.579013 8.0509 0.632065 8.10086L5.43957 12.6759L4.38957 19.2009V19.2759C4.38432 19.3499 4.39876 19.4239 4.43143 19.4905C4.4641 19.5571 4.51382 19.6139 4.57554 19.655C4.63726 19.6962 4.70878 19.7202 4.78282 19.7248C4.85686 19.7293 4.93078 19.7142 4.99707 19.6809L10.9971 16.6809L16.9971 19.6809H17.0646C17.1329 19.7009 17.2051 19.7033 17.2746 19.6877C17.344 19.6722 17.4084 19.6393 17.4616 19.592C17.5148 19.5448 17.5552 19.4848 17.5789 19.4177C17.6026 19.3506 17.6088 19.2785 17.5971 19.2084L16.5471 12.6834L21.3471 8.10836L21.3996 8.05586C21.4411 7.99685 21.467 7.92829 21.4749 7.85656C21.4828 7.78483 21.4725 7.71227 21.4448 7.64563C21.4171 7.579 21.3729 7.52046 21.3165 7.47548C21.2601 7.43049 21.1932 7.40052 21.1221 7.38836L14.4321 6.33836L11.3721 0.480857C11.3371 0.411438 11.2835 0.3531 11.2172 0.312341C11.151 0.271581 11.0748 0.25 10.9971 0.25C10.9193 0.25 10.8431 0.271581 10.7769 0.312341C10.7107 0.3531 10.6571 0.411438 10.6221 0.480857L7.54706 6.34586Z"
        fill="black"
      />
    </svg>
  );
}
