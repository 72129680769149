/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  error: "",
};

const workplacesSlice = createSlice({
  name: "workplaces",
  initialState,
  reducers: {
    fetchWorkplaces: (state) => {
      state.error = "";
    },

    fetchWorkplacesFulfilled: (state, { payload: { workPlaces } }) => {
      state.data = workPlaces;
      state.error = "";
    },

    fetchWorkplacesFailed: (state, { payload }) => {
      state.data = [];
      state.error = payload.error;
    },

    fetchWorkplacesCancel: () => {},
  },
});

export const {
  fetchWorkplaces,
  fetchWorkplacesFulfilled,
  fetchWorkplacesFailed,
  fetchWorkplacesCancel,
} = workplacesSlice.actions;

export default workplacesSlice.reducer;
