import React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { useTranslation } from "hooks";
import { Link } from "Profile/components";
import Modal from "Profile/ProfileView/Modal";
import { useLanguageSupport } from "BookingFlow/hooks";
import { changeLanguage } from "store/app";

function LanguageSupportModal({ continuePath, closeModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { heading, continueMessage, phoneContacts } = useLanguageSupport({
    continuePath,
  });

  return (
    <Modal close={closeModal} className="employ-portal-modal-container">
      <div className="modal--profile-language-support view--profile-folio">
        <div className="container">
          <h3 className="ty-h3">{heading}</h3>
          {phoneContacts.map(({ text, number }) => (
            <p key={number}>
              <Link href={`tel:${number}`}>
                {text} : {number}
              </Link>
            </p>
          ))}
          <Link
            kind="secondary"
            to={continuePath}
            className="btn-submit"
            onClick={(evt) => {
              evt.preventDefault();
              dispatch(changeLanguage({ locale: "en" }));
              closeModal();
              dispatch(push(continuePath));
            }}
          >
            {continueMessage}
          </Link>
        </div>

        <button className="btn-close" type="button" onClick={closeModal}>
          <span>{t("Back")}</span>
        </button>
      </div>
    </Modal>
  );
}

export default LanguageSupportModal;
