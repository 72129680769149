import React from "react";
import { useTranslation } from "hooks";
import { parse, format } from "date-fns";
import { Link } from "react-router-dom";
import * as profileRoutes from "Profile/profileRoutes";
import { getCardExpiredStatus } from "Profile/utils/membershipCardStatus";

function MemberCard({ membership, cardExpired, memberDetail }) {
  const { t } = useTranslation();
  let status = "";
  if (membership?.endDate) {
    const expDate = parse(
      membership?.endDate,
      "yyyy-MM-dd HH:mm:ss",
      new Date()
    );
    if (cardExpired) {
      status = t("Expired - on {0}", [format(expDate, "MM/yy")]);
    } else {
      status = t("Active - valid until {0}", [format(expDate, "MM/yy")]);
    }
  }

  return (
    <div
      className="fs-member-detail-card"
      alt={membership?.Content.name}
      style={
        membership?.Content.cardImage.url && {
          backgroundImage: `url(${membership?.Content.cardImage.url})`,
        }
      }
    >
      <p className="membership-property ty-c2">
        {membership?.Content?.property?.officialName}
      </p>
      <p className="membership-tier ty-c3">{membership?.Content.name}</p>
      {!memberDetail && (
        <p className="membership-account ty-c2">{membership?.cardNo}</p>
      )}
      {!memberDetail && <p className="membership-status ty-c2">{status}</p>}
      {!memberDetail && cardExpired && (
        <div className="expired-container">
          <div className="warning-icon" />
          <p className="membership-expired ty-c2">{t("Expired")}</p>
        </div>
      )}
    </div>
  );
}

function MembershipCard({ membership, memberDetail }) {
  const { locale } = useTranslation();
  const cardExpired = getCardExpiredStatus(membership);

  return (
    <>
      {!cardExpired && !memberDetail ? (
        <Link
          key={`${membership?.membershipId}/${membership?.membershipTierId}`}
          to={profileRoutes.profileMembershipPath.to({
            hotelCode: membership?.hotelCode,
            membershipId: membership?.membershipId,
            tierId: membership?.membershipTierId,
            locale,
          })}
        >
          <MemberCard membership={membership} cardExpired={cardExpired} />
        </Link>
      ) : (
        <MemberCard
          membership={membership}
          cardExpired={cardExpired}
          memberDetail={memberDetail}
        />
      )}
    </>
  );
}

export default MembershipCard;
