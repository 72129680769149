import { format } from "date-fns";
import * as dateFnLocales from "date-fns/locale";

import { PROFILE } from "utils/dateFormats";
import { getDateFnsLocale } from "config/languages";

export default function ({ date, locale }) {
  const dnsLocale = dateFnLocales[getDateFnsLocale(locale)];

  switch (locale) {
    case "zh":
      return format(date, "yyyy年MM月dd日");
    case "kr":
      return format(date, "MM월 dd일(E)", {
        locale: dateFnLocales[getDateFnsLocale(locale)],
      });
    case "tr":
      return format(date, "d MMMM EEEE", {
        locale: dnsLocale,
      });
    default:
      return format(date, PROFILE.ESTIMATED_TOTAL_RATES, { locale: dnsLocale });
  }
}
