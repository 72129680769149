/*
OCCUPANCY_ALERT.SPLIT  - Able to distribute occupancy across max rooms(4)
OCCUPANCY_ALERT.ADULTS - Too many children for number of adults.
OCCUPANCY_ALERT.GROUP  - Exceeds occupancy for max rooms
EMPLOYEE_SEARCH        - "We are sorry, there are no longer available rooms on the dates you have requested, as they have sold out since the last calendar update.  Please do not reach out directly to the hotel to inquire about availability."
*/
import { stringToInt } from "utils/utils";

export const OCCUPANCY_ALERTS = {
  SPLIT: "OCCUPANCY_ALERT_SPLIT",
  ADULTS: "OCCUPANCY_ALERT_ADULTS",
  GROUP: "OCCUPANCY_ALERT_GROUP",
  EMPLOYEE_SEARCH: "EMPLOYEE_SEARCH",
};

export const getMaxRooms = ({
  maxRooms,
  maxRoomsPP,
  isPreferredPartners = false,
}) =>
  isPreferredPartners
    ? stringToInt(maxRoomsPP || "0")
    : stringToInt(maxRooms || "4");

function splitOccupancyIntoMultipleRooms({
  adults,
  children,
  property,
  isPreferredPartners = false,
}) {
  const {
    maxAdultOccupancy,
    maxChildOccupancy,
    maxNumberOfBodies,
    maxRooms: initialMaxRooms,
    maxRoomsPP,
  } = property;

  const maxRooms = getMaxRooms({
    maxRooms: initialMaxRooms,
    maxRoomsPP,
    isPreferredPartners,
  });

  if (maxChildOccupancy < 1 && children > 0) {
    return OCCUPANCY_ALERTS.ADULTS;
  }

  const roomsForAdults = Math.ceil(adults / maxAdultOccupancy);

  const roomsForChildren =
    maxChildOccupancy > 0 ? Math.ceil(children / maxChildOccupancy) : 0;

  const roomsForTotalOccupancy = Math.ceil(
    (adults + children) / maxNumberOfBodies
  );

  const roomsNeeded = Math.max(
    roomsForTotalOccupancy,
    roomsForAdults,
    roomsForChildren
  );

  if (roomsNeeded > maxRooms) {
    return OCCUPANCY_ALERTS.GROUP;
  }

  if (roomsNeeded > adults) {
    return OCCUPANCY_ALERTS.ADULTS;
  }

  return OCCUPANCY_ALERTS.SPLIT;
}

export default function checkOccupancy({
  rooms,
  property,
  isPreferredPartners = false,
  employeeMode = false,
}) {
  const {
    maxAdultOccupancy,
    maxChildOccupancy,
    maxNumberOfBodies,
    maxRooms: initialMaxRooms,
    maxRoomsPP,
  } = property;

  const maxRooms = getMaxRooms({
    maxRooms: initialMaxRooms,
    maxRoomsPP,
    isPreferredPartners,
  });

  const occupancyExceedsLimit = rooms.some(
    ({ adults, children }) =>
      adults > maxAdultOccupancy ||
      children > maxChildOccupancy ||
      adults + children > maxNumberOfBodies
  );

  if (occupancyExceedsLimit && employeeMode) {
    return OCCUPANCY_ALERTS.EMPLOYEE_SEARCH;
  }

  if (
    rooms.length > parseInt(maxRooms, 10) ||
    (occupancyExceedsLimit && rooms.length > 1)
  ) {
    return OCCUPANCY_ALERTS.GROUP;
  }

  if (occupancyExceedsLimit) {
    return splitOccupancyIntoMultipleRooms({
      adults: rooms[0].adults,
      children: rooms[0].children,
      property,
      isPreferredPartners,
    });
  }

  return false;
}
