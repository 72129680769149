/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  error: "",
};

const employeeCalendarAvailabilitySlice = createSlice({
  name: "employeeCalendarAvailability",
  initialState,
  reducers: {
    fetchEmployeeCalendarAvailability: (state) => {
      state.error = "";
    },

    fetchEmployeeCalendarAvailabilityFulfilled: (state, { payload }) => {
      state.data = payload;
      state.error = "";
    },

    fetchEmployeeCalendarAvailabilityFailed: (
      state,
      { payload: { error } }
    ) => {
      state.data = [];
      state.error = error;
    },

    fetchEmployeeCalendarAvailabilityCancel: () => {},
  },
});

export const {
  fetchEmployeeCalendarAvailability,
  fetchEmployeeCalendarAvailabilityFulfilled,
  fetchEmployeeCalendarAvailabilityFailed,
  fetchEmployeeCalendarAvailabilityCancel,
} = employeeCalendarAvailabilitySlice.actions;

export default employeeCalendarAvailabilitySlice.reducer;
