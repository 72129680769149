import { ajax } from "rxjs/ajax";

import env from "config/env";

const { CONTENT_API_BASE_URL } = env;

const getCalendarAvailability$ = ({ locale = "en" }) =>
  ajax.getJSON(`${CONTENT_API_BASE_URL}/${locale}/calendarAvailability`);

export default getCalendarAvailability$;
