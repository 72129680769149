import { ajax } from "rxjs/ajax";

import env from "config/env";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

export default function createEmailVerifyCode$({ body, locale }) {
  return ajax({
    ...basePostRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/cust/profile/emailCode`,
    body,
  });
}
