import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import queryString from "query-string";

import { getHMTLTagClassNames, validateDomain } from "utils";
import { getIsoCodeForLocale } from "config/languages";
import { useGdprBanner, useTranslation, useLoadQualtrics } from "hooks";
import {
  Profile,
  profileRoutes,
  EmployeePortalView,
  EmployeeStayHistoryView,
} from "Profile";
import { webCheckInRoutes, WebCheckIn } from "WebCheckIn";
import BookingFlowLoadingIndicator from "BookingFlow/components/BookingFlowLoadingIndicator";
import { selectIsRequestLoading } from "store/apiRequestStates";
import { logout, selectIsUserLoggedIn } from "store/profile";
import { selectIsEmployeeProfile } from "store/employeeProfile";
import {
  AuthenticationLayout,
  RegisterView,
  SignInView,
  UnauthenticatedUpcomingTripView,
} from "Authentication";
import * as authenticationRoutes from "Authentication/authenticationRoutes";
import BookingFlow from "./BookingFlow/BookingFlow";

function AppRoutes() {
  const { i18n, locale } = useTranslation();
  useGdprBanner();
  useLoadQualtrics();

  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const isEmployeeProfile = useSelector(selectIsEmployeeProfile);
  const isLoggingOut = useSelector(selectIsRequestLoading(logout.type));

  return (
    <>
      <Switch>
        <Route
          path={authenticationRoutes.signIn.path}
          render={({ location }) => {
            const { afterSignInRedirectTo, ...queryParams } = queryString.parse(
              location.search
            );

            const newRedirect = validateDomain(
              afterSignInRedirectTo,
              window.location
            );
            // If an afterSignInRedirectTo was passed in but was invalid, still
            // doing the redirect so that we remove the value from the queryParams.
            if (afterSignInRedirectTo) {
              return (
                <Redirect
                  replace
                  to={{
                    ...location,
                    search: queryString.stringify(queryParams),
                    state: { afterSignInRedirectTo: newRedirect },
                  }}
                />
              );
            }

            return (
              <AuthenticationLayout
                pageTitle={authenticationRoutes.signIn.getPageTitle()}
                htmlBodyClassName={
                  authenticationRoutes.signIn.htmlBodyClassName
                }
              >
                <SignInView
                  afterSignInRedirectTo={location?.state?.afterSignInRedirectTo}
                />
              </AuthenticationLayout>
            );
          }}
        />

        <Route
          path={authenticationRoutes.employeeSignIn.path}
          render={({ location: { state: { afterSignInRedirectTo } = {} } }) => {
            return (
              <AuthenticationLayout
                pageTitle={authenticationRoutes.employeeSignIn.getPageTitle()}
                htmlBodyClassName={
                  authenticationRoutes.employeeSignIn.htmlBodyClassName
                }
              >
                <SignInView
                  afterSignInRedirectTo={afterSignInRedirectTo}
                  employeeMode
                />
              </AuthenticationLayout>
            );
          }}
        />

        <Route
          path={authenticationRoutes.register.path}
          render={({ location: { state: { afterSignInRedirectTo } = {} } }) => {
            return (
              <AuthenticationLayout
                pageTitle={authenticationRoutes.register.getPageTitle()}
                htmlBodyClassName={
                  authenticationRoutes.register.htmlBodyClassName
                }
              >
                <RegisterView afterSignInRedirectTo={afterSignInRedirectTo} />
              </AuthenticationLayout>
            );
          }}
        />

        <Route
          path={authenticationRoutes.employeeRegister.path}
          render={() => {
            return (
              <AuthenticationLayout
                pageTitle={authenticationRoutes.employeeRegister.getPageTitle()}
                htmlBodyClassName={
                  authenticationRoutes.employeeRegister.htmlBodyClassName
                }
              >
                <RegisterView employeeMode />
              </AuthenticationLayout>
            );
          }}
        />

        <Route
          path={authenticationRoutes.employeeTravel.path}
          render={() => {
            if (isUserLoggedIn) {
              return <Redirect to={profileRoutes.profilePath.to({ locale })} />;
            }
            return (
              <Redirect
                to={authenticationRoutes.employeeSignIn.to({
                  locale,
                })}
              />
            );
          }}
        />

        <Route
          path={profileRoutes.employeeProfilePath.path}
          render={() => {
            if (!isUserLoggedIn) {
              return (
                <Redirect
                  to={{
                    pathname: authenticationRoutes.employeeSignIn.to({
                      locale,
                    }),
                    state: {
                      afterSignInRedirectTo:
                        profileRoutes.employeeProfilePath.to({ locale }),
                    },
                  }}
                />
              );
            }

            if (!isEmployeeProfile) {
              return <Redirect to={profileRoutes.profilePath.to({ locale })} />;
            }

            return <EmployeePortalView />;
          }}
        />

        <Route
          path={profileRoutes.employeeStayHistoryPath.path}
          render={() => {
            if (!isUserLoggedIn) {
              return (
                <Redirect
                  to={{
                    pathname: authenticationRoutes.employeeSignIn.to({
                      locale,
                    }),
                    state: {
                      afterSignInRedirectTo:
                        profileRoutes.employeeStayHistoryPath.to({ locale }),
                    },
                  }}
                />
              );
            }

            if (!isEmployeeProfile) {
              return <Redirect to={profileRoutes.profilePath.to({ locale })} />;
            }

            return <EmployeeStayHistoryView />;
          }}
        />

        <Route
          path={authenticationRoutes.unauthenticatedBookingPath.path}
          render={({
            location,
            match: {
              params: { hotelCode, reservationId },
            },
          }) => {
            const { afterFindReservationRedirectTo, ...queryParams } =
              queryString.parse(location.search);

            const newRedirect = validateDomain(
              decodeURIComponent(afterFindReservationRedirectTo),
              window.location
            );

            if (afterFindReservationRedirectTo) {
              return (
                <Redirect
                  replace
                  to={{
                    ...location,
                    search: queryString.stringify(queryParams),
                    state: { afterFindReservationRedirectTo: newRedirect },
                  }}
                />
              );
            }

            return (
              <>
                <Helmet>
                  <html
                    lang={getIsoCodeForLocale(i18n.language)}
                    className={getHMTLTagClassNames({
                      locale,
                      dir: i18n.dir(i18n.language),
                      kind: "fs-profile-2018",
                    })}
                  />
                </Helmet>

                <UnauthenticatedUpcomingTripView
                  hotelCode={hotelCode}
                  reservationId={reservationId}
                  pageTitle={authenticationRoutes.unauthenticatedBookingPath.getPageTitle()}
                  afterFindReservationRedirectTo={
                    location?.state?.afterFindReservationRedirectTo
                  }
                />
              </>
            );
          }}
        />

        <Route
          path={authenticationRoutes.findReservationsPath.path}
          render={({
            match: {
              params: { hotelCode, reservationId },
            },
          }) => {
            return (
              <>
                <Helmet>
                  <html
                    lang={getIsoCodeForLocale(i18n.language)}
                    className={getHMTLTagClassNames({
                      locale,
                      dir: i18n.dir(i18n.language),
                      kind: "fs-profile-2018",
                    })}
                  />
                </Helmet>
                <UnauthenticatedUpcomingTripView
                  hotelCode={hotelCode}
                  reservationId={reservationId}
                  pageTitle={authenticationRoutes.findReservationsPath.getPageTitle()}
                  isFindReservations
                />
              </>
            );
          }}
        />

        <Route
          path={authenticationRoutes.unauthenticatedBookingItineraryPath.from}
          render={({ location }) => {
            const { confirmationNumber, hotelCode } = queryString.parse(
              location.search
            );
            return (
              <Redirect
                to={authenticationRoutes.unauthenticatedBookingItineraryPath.to(
                  {
                    hotelCode,
                    reservationId: confirmationNumber,
                    locale,
                  }
                )}
              />
            );
          }}
        />

        <Route
          path={authenticationRoutes.arrivalTransportationMobile.path}
          render={({ location }) => {
            const { confirmationNumber, hotelCode } = queryString.parse(
              location.search
            );
            return (
              <Redirect
                to={authenticationRoutes.unauthenticatedBookingTransportationPath.to(
                  {
                    hotelCode,
                    reservationId: confirmationNumber,
                    locale,
                  }
                )}
              />
            );
          }}
        />

        <Route path={profileRoutes.profilePath.path} component={Profile} />

        <Route
          path={webCheckInRoutes.webCheckInPath.path}
          component={WebCheckIn}
        />

        <Route component={BookingFlow} />
      </Switch>

      {isLoggingOut && <BookingFlowLoadingIndicator />}
    </>
  );
}

export default AppRoutes;
