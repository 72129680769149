import React from "react";

import { useTranslation } from "hooks";
import { upgradeRooms } from "store/bookings";
import CommonSingleRoomUpsells from "./CommonSingleRoomUpsells";

export default function DesktopSingleRoomUpsells({
  roomIndex,
  roomName,
  image,
  price,
  description,
  hideUpgrade,
  dispatchWithLocale,
}) {
  const { t } = useTranslation();

  return (
    <div className="upgrade-option-inline">
      <ul className="block-list upgrade-option-block">
        <CommonSingleRoomUpsells
          roomIndex={roomIndex}
          roomName={roomName}
          image={image}
          price={price}
          description={description}
        >
          <div className="upgrade-select">
            <button
              className="btn btn-primary btn-upgrade-option"
              type="button"
              onClick={() =>
                dispatchWithLocale(
                  upgradeRooms({ roomIndices: [roomIndex + 1] })
                )
              }
            >
              {t("Upgrade")}
            </button>
          </div>
        </CommonSingleRoomUpsells>
        <button
          type="button"
          aria-label={t("Close")}
          className="icon-button icon icon-close"
          onClick={hideUpgrade}
        />
      </ul>
    </div>
  );
}
