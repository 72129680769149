import get from "lodash/fp/get";
import { ofType } from "redux-observable";
import { map, switchMap, takeUntil, catchError } from "rxjs/operators";
import { of, merge } from "rxjs";
import head from "lodash/fp/head";
import compose from "lodash/fp/compose";

import {
  getRatesForDateChange,
  getRatesForDateChangeCancel,
  getRatesForDateChangeFailed,
  getRatesForDateChangeFulfilled,
} from "store/bookings";
import buildSearchResultsFromBooking from "utils/buildSearchResultsFromBooking";
import { errorMessages, errorCodes } from "utils/errorCodes";
import { getSearchResults$ } from "api/tretail/searchResults";
import {
  addHotelProduct$,
  createBooking$,
  getBookingById$,
} from "api/tretail/booking";
import catchInternalServerError from "store/catchInternalServerError";
import { selectRoomsWithOffers } from "../packageHelpers";

const getBookingCodes = (booking) => ({
  roomCodes: [
    get(["hotelProducts", "0", "roomTypes", "0", "roomTypeId"], booking) || "",
  ],
  offerCodes: [
    get(["hotelProducts", "0", "roomRate", "ratePlanCode"], booking) || "",
  ],
});

const getApiError = compose(get(["errorCode"]), head);
const getSupplierError = compose(get(["errorCode"]), head);

export default function getRatesForDateChangeEpic(action$) {
  return action$.pipe(
    ofType(getRatesForDateChange.type),
    switchMap(({ payload: { locale, booking, startDate, endDate } }) =>
      getSearchResults$({
        isAllInPricing: false,
        locale,
        ...buildSearchResultsFromBooking({ booking, startDate, endDate }),
      }).pipe(
        switchMap(({ getHotelSearchResultsResponse: searchResults }) => {
          const resultSetId = searchResults.id;
          return createBooking$({ locale }).pipe(
            switchMap(({ response: { bookingId } }) => {
              const [selectedPackage] = selectRoomsWithOffers({
                ...getBookingCodes(booking),
                searchResults,
              });

              return selectedPackage.resultId && selectedPackage.priceId
                ? addHotelProduct$({
                    bookingId,
                    resultSetId,
                    selectedPackages: [selectedPackage],
                    locale,
                  }).pipe(
                    switchMap(() =>
                      getBookingById$({ bookingId, locale }).pipe(
                        map(getRatesForDateChangeFulfilled)
                      )
                    )
                  )
                : of(
                    getRatesForDateChangeFailed(
                      errorMessages[errorCodes.NOT_ENOUGH_AVAILABILITY_ERROR]
                    )
                  );
            })
          );
        }),
        takeUntil(action$.pipe(ofType(getRatesForDateChangeCancel.type)))
      )
    ),

    catchInternalServerError(),

    catchError((error, caught) => {
      const response = error?.response || {};
      if (error.appStatus) {
        throw error;
      }
      return merge(
        of(
          getRatesForDateChangeFailed({
            apiErrorCode: response?.apiErrors
              ? getApiError(response.apiErrors)
              : undefined,
            supplierErrorCode: response?.supplierErrors
              ? getSupplierError(response.supplierErrors)
              : undefined,
          })
        ),
        caught
      );
    })
  );
}
