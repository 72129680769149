import { ofType } from "redux-observable";
import { of } from "rxjs";
import { map, switchMap, catchError, takeUntil } from "rxjs/operators";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { getBookingById$ } from "api/tretail/booking";
import { retrieveBooking$ } from "api/tretail/bookingHistory";
import {
  fetchHistoricBooking,
  fetchHistoricBookingCancel,
  fetchHistoricBookingFailed,
  fetchHistoricBookingFulfilled,
} from "store/bookings";
import catchInternalServerError from "store/catchInternalServerError";

export function retrieveBooking({
  reservationId,
  hotelCode,
  surname,
  creditCardExpiryDate,
  creditCardNumber,
  firstName,
  reservationStartDate,
  locale,
}) {
  return retrieveBooking$({
    reservationId,
    hotelCode,
    surname,
    creditCardExpiryDate,
    creditCardNumber,
    firstName,
    reservationStartDate,
    locale,
  }).pipe(
    switchMap(({ bookingId }) => {
      return getBookingById$({
        bookingId,
        locale,
      });
    })
  );
}

export const handleHistoricBookingError = ({ reservationId, response = {} }) =>
  of(
    fetchHistoricBookingFailed({
      reservationId,
      apiErrors: response?.apiErrors || [],
      supplierErrors: response?.supplierErrors || [],
    })
  );

export default function fetchHistoricBookingEpic(action$) {
  return action$.pipe(
    ofType(fetchHistoricBooking.type),
    switchMap(
      ({ payload: { reservationId, propertyCode, surname, locale } }) => {
        return ajaxWithHealthCheck$({
          locale,
        }).pipe(
          switchMap(() => {
            return retrieveBooking({
              reservationId,
              hotelCode: propertyCode,
              surname,
              locale,
            }).pipe(
              map(fetchHistoricBookingFulfilled),

              catchInternalServerError(),

              catchError(({ response }) =>
                handleHistoricBookingError({ reservationId, response })
              ),

              takeUntil(action$.pipe(ofType(fetchHistoricBookingCancel.type)))
            );
          })
        );
      }
    )
  );
}
