const pkg = require("../../package.json");

let envVars = process.env || {};

envVars = Object.keys(envVars).reduce((acc, key) => {
  let value = envVars[key];

  // convert boolean strings to boolean types
  value = value === "true" ? true : value;
  value = value === "false" ? false : value;

  // convert JSON values
  if (
    typeof value === "string" &&
    value.startsWith("{") &&
    value.endsWith("}")
  ) {
    try {
      value = JSON.parse(value);
    } catch (e) {
      // console.log(e);
    }
  }

  return {
    ...acc,
    [key.replace(/^REACT_APP_/, "")]: value,
  };
}, {});

const configuration = {
  VERSION: pkg.version,
  ...envVars,
  ...(window.config || {}),
};

export default configuration;
