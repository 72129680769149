import React, { useContext } from "react";
import env from "config/env";
import MappingLayerContext from "../../mappingLayerContext";

const { STATIC_SITE_URL } = env;

export default function CreditCardImages() {
  const { supportedCreditCards } = useContext(MappingLayerContext);

  return supportedCreditCards.map(({ path, alt }) => (
    <img
      key={path}
      className="card"
      alt={alt}
      title={alt}
      src={`${STATIC_SITE_URL}${path}`}
    />
  ));
}
