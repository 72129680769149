import React, { useContext } from "react";

import { MediaContext } from "contexts/MediaContext";
import Modal from "BookingFlow/components/Modal";
import DesktopRoomDetails from "./DesktopRoomDetails";
import MobileRoomDetails from "./MobileRoomDetails";
import RoomItemFeatures from "./RoomItemFeatures";

export default function RoomDetailsModal({
  accessibleFeaturesList,
  bathroomDescription,
  bedOptions,
  bookableOnline,
  closeModal,
  extraBed,
  floorPlanImagePath,
  location,
  longDescription,
  occupancy,
  openFloorplanModal,
  roomName,
  servicesAndAmenityGroups,
  size,
  view,
}) {
  const media = useContext(MediaContext);

  return (
    <Modal
      title={roomName}
      className="new-modal--room-details"
      closeModal={closeModal}
    >
      {media.isLessThan.sm ? (
        <>
          <RoomItemFeatures
            bedOptions={bedOptions}
            bookableOnline
            floorPlanImagePath={floorPlanImagePath}
            openFloorplanModal={openFloorplanModal}
            occupancy={occupancy}
            size={size}
            roomName={roomName}
            view={view}
          />
          <MobileRoomDetails
            accessibleFeaturesList={accessibleFeaturesList}
            bookableOnline={bookableOnline}
            roomName={roomName}
            servicesAndAmenityGroups={servicesAndAmenityGroups}
            floorPlanImagePath={floorPlanImagePath}
          />
        </>
      ) : (
        <DesktopRoomDetails
          accessibleFeaturesList={accessibleFeaturesList}
          bathroomDescription={bathroomDescription}
          bookableOnline={bookableOnline}
          extraBed={extraBed}
          location={location}
          longDescription={longDescription}
          occupancy={occupancy}
          roomName={roomName}
          servicesAndAmenityGroups={servicesAndAmenityGroups}
          view={view}
          size={size}
          floorPlanImagePath={floorPlanImagePath}
        />
      )}
    </Modal>
  );
}
