import React, { useContext } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "hooks";
import Link from "Profile/components/Link";
import * as authenticationRoutes from "Authentication/authenticationRoutes";
import LanguageSelect from "Profile/components/LanguageSelect";
import { selectUserState } from "store/profile";
import { SIGN_IN_STATES } from "fixtures/constants";
import { MediaContext } from "contexts/MediaContext";
import { NavLink } from "react-router-dom";
import { profileRoutes } from "Profile";
import ProfileLinks from "./ProfileLinks";
import ProfileStatus from "../ProfileStatus/ProfileStatus";

export default function ProfileFlyout({
  isSignInView,
  isRegisterView,
  isProfileView,
  isPreferredPartners,
  hideLanguageSelector,
  languagesFilter,
  isMenuOpen,
  setIsMenuOpen,
}) {
  const userState = useSelector(selectUserState);
  const { t, locale } = useTranslation();
  const media = useContext(MediaContext);
  const isMobile = media.isLessThan.sm;

  const closeMenu = () => setIsMenuOpen(false);

  if (isSignInView) {
    return null;
  }

  return (
    <div
      className={classNames("fs-header__overlay", {
        "fs-header__overlay--is-open": isMenuOpen,
        "fs-header__overlay--is-loggedin": userState > SIGN_IN_STATES.ANONYMOUS,
      })}
    >
      {(isMenuOpen || isMobile) && (
        <div
          className="fs-header__overlay__content"
          data-testid="fs-header__profile-flyout"
        >
          {isMobile && (
            <div className="fs-header__top-bar fs-header__top-bar--menu-is-open">
              <div className="fs-header__top-bar__nav">
                {!isPreferredPartners && (
                  <div className="fs-header__top-bar__nav__inner">
                    {!isRegisterView && !isSignInView && (
                      <NavLink
                        className="profile-status"
                        to={profileRoutes.profilePath.to({ locale })}
                        activeClassName="is-active"
                      >
                        <ProfileStatus />
                      </NavLink>
                    )}
                    {!hideLanguageSelector && (
                      <LanguageSelect filter={languagesFilter} />
                    )}
                  </div>
                )}

                {!isSignInView &&
                  !isRegisterView &&
                  !isPreferredPartners &&
                  isMobile && (
                    <div className="fs-header__menu-button-container">
                      <button
                        type="button"
                        className="fs-header__menu-button fs-header__menu-button--is-open"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        data-testid="menu-trigger"
                      >
                        <span className="visuallyhidden">{t("Menu")}</span>
                      </button>
                    </div>
                  )}
              </div>
            </div>
          )}

          {userState > SIGN_IN_STATES.ANONYMOUS && (
            <ProfileLinks closeMenu={closeMenu} isProfileView={isProfileView} />
          )}

          {userState === SIGN_IN_STATES.ANONYMOUS && (
            <div className="fs-header__profile-flyout__columns">
              <div className="fs-header__profile-flyout__column">
                <div>
                  <h2>{t("Create a profile")}</h2>
                  <p>
                    {t(
                      "Welcome to Four Seasons. By creating a profile, you’ll be able to seamlessly manage your trips, set your stay preferences and get access to exclusive offers."
                    )}
                  </p>
                </div>

                <Link
                  to={authenticationRoutes.register.to({ locale })}
                  kind="primary"
                  onClick={closeMenu}
                >
                  {t("Create")}
                </Link>
              </div>
              <div className="fs-header__profile-flyout__column">
                <div>
                  <h2>{t("Sign in")}</h2>
                  <p>
                    {t("Welcome back. Sign in now to access your profile.")}
                  </p>
                </div>

                <Link
                  to={authenticationRoutes.signIn.to({ locale })}
                  kind="primary"
                  onClick={closeMenu}
                >
                  {t("Continue")}
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className="fs-header__overlay__background"
        role="presentation"
        onClick={closeMenu}
      />
    </div>
  );
}
