import React, { useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import env from "config/env";
import { useTranslation } from "hooks";
import { viewServicesAmenitiesEvt } from "store/searchResults";
import ServicesAndAmenitiesContent from "./ServicesAndAmenitiesContent";

const { IMAGE_BASE_URL } = env;
const SECTIONS = {
  DETAILS: "details",
  AMENITIES: "amenities",
};

export default function DesktopRoomDetails({
  accessibleFeaturesList,
  bathroomDescription,
  isBookableOnline,
  extraBed,
  location,
  longDescription,
  occupancy,
  roomName,
  servicesAndAmenityGroups,
  size,
  view,
  floorPlanImagePath,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(SECTIONS.DETAILS);
  const [showFloorPlan, setShowFloorPlan] = useState(false);

  const toggleFloorPlanImage = () => setShowFloorPlan(!showFloorPlan);

  return (
    <div
      className="room-item-tab-container"
      id={`room-item-tab-container-${roomName}`}
    >
      <div
        className={classNames("room-details-tab-select", {
          "non-bookable-direct": !isBookableOnline,
        })}
        role="tablist"
      >
        <button
          type="button"
          id={`tab-details-button-${roomName}`}
          className={classNames("tab-button tab-button--details", {
            "is-active": activeTab === "details",
          })}
          aria-label={`${t("Room Details")} (${roomName})`}
          aria-controls={`tab-details-panel-${roomName}`}
          aria-selected={activeTab === "details"}
          role="tab"
          onClick={() => setActiveTab("details")}
        >
          <h4 className="span">{t("Room Details")}</h4>
        </button>

        <button
          type="button"
          id={`tab-services-button-${roomName}`}
          className={classNames("tab-button tab-button--services", {
            "is-active": activeTab === "services",
          })}
          aria-label={`${t("Services & Amenities")} (${roomName})`}
          aria-controls={`tab-services-panel-${roomName}`}
          aria-selected={activeTab === "services"}
          role="tab"
          onClick={() => {
            setActiveTab("services");
            dispatch(viewServicesAmenitiesEvt({ roomName }));
          }}
        >
          <h4 className="span">{t("Services & Amenities")}</h4>
        </button>
      </div>

      <ul className="room-details-tabs">
        <li
          className={classNames("tab tab-details", {
            "is-active": activeTab === "details",
          })}
          id={`tab-details-panel-${roomName}`}
          role="tabpanel"
          aria-label={`${t("Room Details")} (${roomName})`}
        >
          <section className="room-item-details room-tab-section">
            <h4 className="visuallyhidden">{t("Room Details")}</h4>

            <div
              className="description-text"
              dangerouslySetInnerHTML={{ __html: longDescription }}
            />

            <div
              className={classNames("room-feature-list", {
                col3: view && size,
              })}
            >
              {view && size && (
                <div className="col">
                  <ul>
                    <li>
                      <h5 className="label">{t("View")}</h5>
                      {view}
                    </li>
                    <li>
                      <h5 className="label">{t("Room Size")}</h5>
                      <div className="shrink">{size}</div>
                    </li>
                  </ul>
                </div>
              )}
              <div className="col">
                <ul>
                  <li>
                    <h5 className="label">{t("Occupancy")}</h5>
                    {occupancy}
                  </li>
                  <li>
                    <h5 className="label">{t("Extra Beds")}</h5>
                    {extraBed}
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul>
                  <li>
                    <h5 className="label">{t("Location")}</h5>
                    {location}
                  </li>
                  <li>
                    <h5 className="label">{t("Bathroom")}</h5>
                    {bathroomDescription}
                  </li>
                </ul>
              </div>
            </div>
            {floorPlanImagePath && (
              <>
                <div className="room-details-floorplan">
                  <button
                    type="button"
                    className="button-as-link"
                    onClick={toggleFloorPlanImage}
                  >
                    <span className="button-as-link__label">
                      {t("View floorplan")}
                    </span>
                    <span
                      className={`icon ${
                        showFloorPlan ? "icon-arrow-up" : "icon-arrow-down"
                      }`}
                    />
                  </button>
                </div>
                {showFloorPlan && (
                  <div className="floorplan">
                    <img
                      src={IMAGE_BASE_URL + floorPlanImagePath}
                      alt={t("Floor Plan")}
                    />
                  </div>
                )}
              </>
            )}
          </section>
        </li>

        <li
          className={classNames("tab tab-services", {
            "is-active": activeTab === "services",
          })}
          id={`tab-services-panel-${roomName}`}
          role="tabpanel"
          aria-label={t("Services & Amenities")}
        >
          <section className="room-item-services room-tab-section">
            <ServicesAndAmenitiesContent
              servicesAndAmenityGroups={servicesAndAmenityGroups}
            />

            {accessibleFeaturesList && (
              <div className="info-block special-access">
                <h4 className="visuallyhidden">
                  {t("Special access requests")}
                </h4>
                <div className="special-access-requests">
                  <h5>{t("Accessibility")}</h5>
                  <ul>
                    {accessibleFeaturesList.map((item) => (
                      <li
                        key={item}
                        dangerouslySetInnerHTML={{ __html: item.trim() }}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </section>
        </li>
      </ul>
    </div>
  );
}
