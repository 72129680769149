import { useTranslation as useReactI18nTranslation } from "react-i18next";
import { parse, format } from "date-fns";
import * as dateFnLocales from "date-fns/locale";

import { getIsoCodeForLocale, getDateFnsLocale } from "config/languages";
import formatPrice from "utils/formatPrice";

function useTranslation(ns, options) {
  const { ready, i18n, ...props } = useReactI18nTranslation(ns, options);
  const locale = i18n.language;
  const localeIsoCode = getIsoCodeForLocale(locale);
  const dateFnsLocale = getDateFnsLocale(locale);

  const formatDateInLocale = (
    dateStr,
    formatString,
    parseString = "yyyy-MM-dd",
    useLocale = locale
  ) => {
    return format(parse(dateStr, parseString, new Date()), formatString, {
      locale: dateFnLocales[getDateFnsLocale(useLocale)],
    });
  };

  const getDateFormatByLocale = (useLocale = locale) => {
    switch (useLocale) {
      case "en":
        return {
          label: "MM/DD/YYYY",
          dateFnsFormat: "MM/dd/yyyy",
          dateFnsParse: "MM/dd/yyyy",
          cleaveOptions: {
            date: true,
            delimiter: "/",
            datePattern: ["m", "d", "Y"],
          },
        };

      case "kr":
        return {
          label: "YYYY년MM월DD일",
          dateFnsFormat: "yyyy/MM/dd",
          dateFnsParse: "yyyy년MM월dd일",
          cleaveOptions: {
            delimiters: ["년", "월", "일"],
            blocks: [4, 2, 2, 0],
          },
        };

      case "jp":
      case "zh_hant":
      case "zh":
        return {
          label: "YYYY年MM月DD日",
          dateFnsFormat: "yyyy/MM/dd",
          dateFnsParse: "yyyy年MM月dd日",
          cleaveOptions: {
            delimiters: ["年", "月", "日"],
            blocks: [4, 2, 2, 0],
          },
        };

      case "en-GB":
      case "fr":
      case "ar":
      case "es":
      case "es-es":
      case "gr":
      case "ru":
      default:
        return {
          label: "DD/MM/YYYY",
          dateFnsFormat: "dd/MM/yyyy",
          dateFnsParse: "dd/MM/yyyy",
          v: "dd/MM/yyyy",
          cleaveOptions: {
            date: true,
            delimiter: "/",
            datePattern: ["d", "m", "Y"],
          },
        };
    }
  };

  const getDateFormat = ({ useLocale = locale, responsive = false } = {}) => {
    switch (useLocale) {
      case "jp":
      case "zh_hant":
      case "zh":
        return responsive ? "d '日'" : "d'日'";

      case "kr":
        return responsive ? "d '일'" : "d'일'";

      default:
        return "d";
    }
  };

  const getMonthFormat = ({ useLocale = locale, responsive = false } = {}) => {
    switch (useLocale) {
      case "jp":
      case "zh_hant":
      case "zh":
        return responsive ? "M '月'" : "M'月'";

      case "kr":
        return responsive ? "M '월'" : "M'월'";

      default:
        return responsive ? "MMM" : "MMMM";
    }
  };

  const getYearFormat = ({ useLocale = locale, responsive = false } = {}) => {
    switch (useLocale) {
      case "jp":
      case "zh_hant":
      case "zh":
        return responsive ? "yyyy '年'" : "yyyy'年'";

      case "kr":
        return responsive ? "yyyy '년'" : "yyyy'년'";

      default:
        return responsive ? "yyyy" : "yy";
    }
  };

  const getTimeFormat = ({ useLocale = locale, longFormat = false } = {}) => {
    if (longFormat) {
      switch (useLocale) {
        case "kr":
          return "a h:mm";
        default:
          return "h:mma";
      }
    }
    switch (useLocale) {
      case "zh":
        return "h:mm";
      default:
        return "h";
    }
  };

  const getMeridiemFormat = (useLocale = locale) => {
    switch (useLocale) {
      // case "zh":
      //   moment.locale("zh", {
      //     meridiem(hour, minute, isLowercase) {
      //       if (hour < 9) {
      //         return "早上";
      //       }
      //       if (hour < 11 && minute < 30) {
      //         return "上午";
      //       }
      //       if (hour < 13 && minute < 30) {
      //         return "中午";
      //       }
      //       if (hour < 18) {
      //         return "下午";
      //       }
      //       return "晚上";
      //     },
      //   });
      //   return moment
      //     .localeData("zh")
      //     .meridiem(momentDate.format("H"), momentDate.format("mm"));

      // case "kr":
      //   moment.locale("kr", {
      //     meridiem(hour, minute, isLowercase) {
      //       if (hour < 12) {
      //         return "전";
      //       }
      //       return "후";
      //     },
      //   });
      //   return moment
      //     .localeData("kr")
      //     .meridiem(momentDate.format("H"), momentDate.format("mm"));

      default:
        return "a";
    }
  };

  const getDateTimeFormat = ({
    useLocale = locale,
    responsive = false,
  } = {}) => {
    const yearFormat = getYearFormat({ useLocale, responsive });
    const monthFormat = getMonthFormat({ useLocale, responsive });
    const dayFormat = getDateFormat({ useLocale, responsive });
    const timeFormat = getTimeFormat({ useLocale });
    const meridiemFormat = getMeridiemFormat(useLocale);

    switch (useLocale) {
      case "zh":
        return `${yearFormat}${monthFormat}${dayFormat}, ${meridiemFormat}${timeFormat}`;

      case "kr":
        return `${yearFormat}${monthFormat}${dayFormat}, ${timeFormat}${meridiemFormat}`;

      case "de":
        return `${dayFormat}. ${monthFormat} ${yearFormat}, ${timeFormat} ${meridiemFormat}`;

      default:
        return `${timeFormat}${meridiemFormat}, ${dayFormat} ${monthFormat} ${yearFormat}`;
    }
  };

  return {
    ...props,
    ready: ready || false,
    i18n,
    locale,
    localeIsoCode,
    dateFnsLocale,
    dateFormat: getDateFormatByLocale(),
    formatDateInLocale,
    getDateFormatByLocale,
    getDateFormat,
    getMonthFormat,
    getYearFormat,
    getDateTimeFormat,
    formatPrice: ({ ...args }) => formatPrice({ locale, ...args }),
  };
}

export default useTranslation;
