import React from "react";

import { useTranslation } from "hooks";
import ThemeContextProvider, { THEMES } from "contexts/ThemeContext";
import { Preferences } from "Profile/components";

function YourPreferences() {
  const { t } = useTranslation();

  return (
    <ThemeContextProvider theme={THEMES.DARK}>
      <div id="your-preferences" className="view--profile__your-preferences">
        <div className="container">
          <h2>{t("Your Preferences")}</h2>
          <p>{t("You may change these at any time.")}</p>
          <Preferences />
        </div>
      </div>
    </ThemeContextProvider>
  );
}

export default YourPreferences;
