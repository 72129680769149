import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "hooks";
import {
  buildSelectedOptionsLabelForStep3,
  formatDateRange,
} from "utils/utils";
import { selectExchangeRates } from "store/exchangeRates";
import DictionaryKeyContent from "BookingFlow/components/DictionaryKeyContent";
import { SearchFilterSelect } from "BookingFlow/ChooseYourRoom/SearchFilters";
import { selectUpgradedRooms, selectBookingInProgress } from "store/bookings";
import { selectTermsAndConditionsForCurrentBooking } from "store/termsAndConditionsContent";
import Accordion from "./Accordion";
import MappingLayerContext from "../../mappingLayerContext";
import WhatIsIncludedModal from "./WhatIsIncludedModal";
import RoomDetailsModal from "../../components/Room/RoomDetailsModal";
import CancelBy from "./CancelBy";

const openModal = {
  NONE: "none",
  SHOW_ROOM: (i) => `open-room:${i}`,
  SHOW_OFFER: (i) => `open-offer:${i}`,
};

const removeCurrency = (formattedCurrency) =>
  formattedCurrency.replace(/^\D+\s/, "");

export default function ReservationSummary({
  bookingInProgress,
  setCurrencyCode,
  displayCancelByMessage,
}) {
  const { t, locale, formatPrice } = useTranslation();

  const {
    labels: { yourReservation },
    rooms,
    propertyName,
    cancelBy,
    totalRoomRate,
    estimatedTotal: {
      amount: { withDecimal },
      currency,
    },
    taxes,
    promoCode,
  } = useContext(MappingLayerContext);

  const [isModalOpen, setIsModalOpen] = useState(openModal.NONE);

  const closeModal = () => setIsModalOpen(openModal.NONE);

  const exchangeRates = useSelector(selectExchangeRates);
  const upgradedRooms = useSelector(selectUpgradedRooms);

  const costs = [
    { description: "Total Room Rates", price: totalRoomRate },
    ...taxes,
  ];

  const termsAndConditions = useSelector(
    selectTermsAndConditionsForCurrentBooking({
      locale,
      currentBooking: useSelector(selectBookingInProgress),
    })
  );

  return (
    <div>
      <h2 className="page-heading hidden-xs">
        <DictionaryKeyContent dict={yourReservation} />
      </h2>
      <div className="your-trip">
        <h3>{t("Trip Details")}</h3>
        <div className="summary">
          <p>
            <span
              className="destination-name"
              dangerouslySetInnerHTML={{
                __html: propertyName,
              }}
            />
            <br />
            <span className="booking-dates">
              {bookingInProgress &&
                formatDateRange(
                  bookingInProgress.startDate,
                  bookingInProgress.endDate,
                  locale
                )}
            </span>
            <br />
            <span className="rooms-guests">
              {buildSelectedOptionsLabelForStep3(t, rooms).value}
            </span>
          </p>
        </div>
        {rooms.map(
          (
            {
              averageRate,
              dailyRates,
              offer,
              room,
              numberOfNights,
              feesDisclaimerCheckout,
              stayResortFees,
            },
            index
          ) => (
            <React.Fragment key={index}>
              <div key={index} className="package">
                <button
                  type="button"
                  className="room-name text-left"
                  onClick={() => setIsModalOpen(openModal.SHOW_ROOM(index))}
                >
                  {room.name}
                </button>
                {upgradedRooms.includes(index + 1) && (
                  <span className="upgraded">
                    <strong>{t("Upgraded")}</strong>
                  </span>
                )}
                {promoCode ? (
                  <span className="text-left">{t(offer.title)}</span>
                ) : (
                  <button
                    type="button"
                    className="promo-title text-left"
                    onClick={() => setIsModalOpen(openModal.SHOW_OFFER(index))}
                  >
                    {t(offer.title)}
                  </button>
                )}
                <p>{t(room.bedType)}</p>
                <ul className="costs">
                  <li>
                    <span className="label">{t("Average Nightly Rate")}</span>
                    <strong className="amount">
                      {formatPrice({
                        currency: averageRate.currency,
                        value: averageRate.amount.withDecimal,
                        decimalPlaces: 2,
                      })}
                    </strong>
                  </li>
                  {feesDisclaimerCheckout && (
                    <li className="feesDisclaimer">{feesDisclaimerCheckout}</li>
                  )}
                </ul>
                <Accordion
                  dailyRates={dailyRates}
                  stayResortFees={stayResortFees}
                  numberOfNights={numberOfNights}
                  index={index}
                />
              </div>

              {isModalOpen === openModal.SHOW_ROOM(index) && (
                <RoomDetailsModal
                  closeModal={closeModal}
                  accessibleFeaturesList={room.accessibleFeaturesList}
                  bathroomDescription={room.bathroom}
                  bookableOnline={room.bookableOnline}
                  extraBed={room.extraBed}
                  location={room.location}
                  longDescription={room.description}
                  occupancy={room.occupancy}
                  roomName={room.name}
                  servicesAndAmenityGroups={room.servicesAndAmenityGroups}
                  bedOptions={room.bedOptions}
                  size={room.size}
                  view={room.view}
                  floorPlanImagePath={room.floorPlanImage?.path}
                />
              )}

              {isModalOpen === openModal.SHOW_OFFER(index) && (
                <WhatIsIncludedModal
                  bookDirectPhone={offer.bookDirectPhone}
                  cancellationPolicy={
                    termsAndConditions[index].cancellationPolicy
                  }
                  closeModal={closeModal}
                  feesDisclaimer={offer.feesDisclaimer}
                  conditions={offer.conditions}
                  conditionsTitle={offer.conditionsTitle}
                  inclusions={offer.inclusions}
                  inclusionsTitle={offer.inclusionsTitle}
                  longDescription={offer.longDescription}
                  price={offer.price}
                  priceLabel={offer.priceLabel}
                  propertyDisclaimer={offer.disclaimer}
                  title={offer.title}
                  whatsIncludedTitleDictKey={offer.whatsIncludedTitleDictKey}
                  extraInclusions={offer.extraInclusions}
                />
              )}
            </React.Fragment>
          )
        )}
      </div>
      <div className="reservation-total">
        <div className="row">
          <ul className="costs">
            {costs.map(({ price, description }) => (
              <li key={description}>
                <span className="label">{t(description)}</span>
                <strong className="amount">
                  {formatPrice({
                    currency: price.currency,
                    value: price.amount.withDecimal,
                    decimalPlaces: 2,
                  })}
                </strong>
              </li>
            ))}
          </ul>
          <h3>{t("Estimated Total")}</h3>
          <p className="hidden-xs" aria-label={t("Estimated Total")}>
            {t("Estimated Total")}*
          </p>
          <SearchFilterSelect
            className="fs-currency-select"
            label={t("Currency Selected")}
            name="currency"
            value={currency}
            options={exchangeRates.map(({ code }) => ({
              value: code,
              label: code,
            }))}
            onChange={({ target: { value } }) => setCurrencyCode(value)}
          >
            <div className="fs-currency-select-inner">
              <span name="total" className="fs-currency-select-total">
                {removeCurrency(
                  formatPrice({
                    currency,
                    value: withDecimal,
                    decimalPlaces: 2,
                    removeCurrency: true,
                  })
                )}
              </span>
            </div>
          </SearchFilterSelect>
        </div>

        {displayCancelByMessage && <CancelBy cancelBy={cancelBy} />}
      </div>
    </div>
  );
}
