import React from "react";

import { useTranslation } from "hooks";
import CreditCardDetails from "./CreditCardDetails";
import AlipayDetails from "./AlipayDetails";
import CreditCardImages from "./CreditCardImages";
import { PAYMENT_METHODS } from "../hooks/constants";
import alipayIcon from "./images/alipay_wap.png";

export default function PaymentMethod({
  register,
  control,
  errors,
  allowAlipay,
  paymentMethod,
  readOnlyFields,
}) {
  const { t } = useTranslation();

  // If Alipay is not allowed, we can just show the credit card form
  if (!allowAlipay) {
    return (
      <CreditCardDetails
        register={register}
        control={control}
        errors={errors.creditCard || {}}
        readOnlyFields={readOnlyFields.creditCard || []}
      />
    );
  }

  // Alipay is enabled, so we need to allow a payment method choice
  return (
    <fieldset name="paymentOptions">
      <legend>{t("Select A Payment Method")}</legend>
      <ul className="choice-items">
        <li className="choice-item">
          <label className="choice-item__control">
            <input
              id="creditCard"
              type="radio"
              name="paymentMethod"
              value={PAYMENT_METHODS.CREDITCARD}
              ref={register}
            />
            <span className="choice-item__control__dot" />
          </label>
          <label htmlFor="creditCard">{t("Credit Card")}</label>
          <div className="available-cards">
            <CreditCardImages />
          </div>
        </li>
        {paymentMethod === PAYMENT_METHODS.CREDITCARD && (
          <li>
            <CreditCardDetails
              register={register}
              control={control}
              errors={errors.creditCard || {}}
              showLegend={false}
              showCardList={false}
              readOnlyFields={readOnlyFields.creditCard || []}
            />
          </li>
        )}
        <li className="choice-item">
          <label className="choice-item__control">
            <input
              id="alipay"
              type="radio"
              name="paymentMethod"
              value={PAYMENT_METHODS.ALIPAY}
              ref={register}
            />
            <span className="choice-item__control__dot" />
          </label>
          <label htmlFor="alipay">{t("Alipay")}</label>
          <div className="available-cards">
            <img
              className="card"
              alt="Alipay"
              title="Alipay"
              width={40}
              src={alipayIcon}
            />
          </div>
        </li>
        {paymentMethod === PAYMENT_METHODS.ALIPAY && <AlipayDetails />}
      </ul>
    </fieldset>
  );
}
