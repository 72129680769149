import React from "react";
import { useSelector } from "react-redux";

import { selectMailSubscriptionList } from "store/profile";
import SideBar from "./SideBar";
import Emails from "./Emails/Emails";
import PhoneNumbers from "./PhoneNumbers/PhoneNumbers";
import Addresses from "./Addresses/Addresses";
import CountryAndLanguage from "./CountryAndLanguage/CountryAndLanguage";
import EmailSubscriptions from "./EmailSubscriptions/EmailSubscriptions";

function Main() {
  const forceEmailSubscriptionsToReRender = JSON.stringify(
    useSelector(selectMailSubscriptionList)
  );

  return (
    <div className="view--profile-edit__wrapper">
      <div className="container">
        <div className="row">
          <SideBar />
          <div className="col-sm-12 col-lg-9 view--profile-edit__content">
            <Emails />
            <PhoneNumbers />
            <Addresses />
            <CountryAndLanguage />
            <EmailSubscriptions key={forceEmailSubscriptionsToReRender} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
