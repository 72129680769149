import { createAction } from "@reduxjs/toolkit";

export const leadWithCareLinkClicked = createAction(
  "analyticEvents/leadWithCareLinkClicked"
);

export const leadWithCareTravelAdvisoryButtonClicked = createAction(
  "analyticEvents/leadWithCareTravelAdvisoryButtonClicked"
);

export const leadWithCareCheckHotelStatusLinkClicked = createAction(
  "analyticEvents/leadWithCareCheckHotelStatusLinkClicked"
);

export const locationChanged = createAction("analyticEvents/locationChanged");

export const itineraryActivityDetailsModal = createAction(
  "analyticEvents/itineraryActivityDetailsModal"
);

export const openTransportationRequestForm = createAction(
  "analyticEvents/openTransportationRequestForm"
);

export const buildYourItineraryAction = createAction(
  "analyticEvents/buildYourItineraryAction"
);

export const requestCtaClicked = createAction(
  "analyticEvents/requestCtaClicked"
);

export const welcomeAmentitiesSubmitted = createAction(
  "analyticEvents/welcomeAmentitiesSubmitted"
);

export const welcomeAmentitiesAdded = createAction(
  "analyticEvents/welcomeAmentitiesAdded"
);

export const welcomeAmentitiesRemoved = createAction(
  "analyticEvents/welcomeAmentitiesRemoved"
);

export const contextualLinkClick = createAction(
  "analyticEvents/contextualLinkClick"
);
