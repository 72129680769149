const matchSortProperties = (inputValue, properties) => {
  const alphaSortedProperties = properties.sort((a, b) =>
    b.normalizedName - a.normalizedName ? -1 : 1
  );

  const propertiesWithInputValueInName = alphaSortedProperties.filter(
    (property) => property.normalizedName.indexOf(inputValue.toLowerCase()) >= 0
  );

  const sortedMatchedProperties = [];
  for (
    let strIndex = 0;
    sortedMatchedProperties.length < propertiesWithInputValueInName.length;
    strIndex += 1
  ) {
    sortedMatchedProperties.push(
      ...propertiesWithInputValueInName.filter(
        (property) =>
          property.normalizedName
            .toLowerCase()
            .indexOf(inputValue.toLowerCase()) === strIndex
      )
    );
  }

  return sortedMatchedProperties;
};

export default matchSortProperties;
