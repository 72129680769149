import isArray from "lodash/isArray";

export default function validateSearchParamAdults(searchParams = {}) {
  const { adults, children } = searchParams;

  const adultsArray = isArray(adults) ? adults : [adults];
  const childrenArray = isArray(children) ? children : [children];

  const roomErrors = [...adultsArray, ...childrenArray].map((a) => {
    if (!/^\d+$/.test(a)) {
      return {
        errorCode:
          "Reservation request cannot be processed. Please correct expected room occupancy information to specify at least one adult",
      };
    }
    return false;
  });

  if (roomErrors.some(Boolean)) {
    return roomErrors[0];
  }

  return false;
}
