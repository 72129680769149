import filter from "lodash/fp/filter";
import flow from "lodash/fp/flow";
import flatMap from "lodash/fp/flatMap";
import get from "lodash/fp/get";
import map from "lodash/fp/map";
import min from "lodash/fp/min";
import update from "lodash/fp/update";
import isEqual from "lodash/fp/isEqual";

const updateRoomWithIsBestRate = (searchResults = []) => {
  const lowestRate = flow(
    // get flat array of all rooms
    flatMap(get("rooms")),

    // get array of lowest rates
    map(get(["lowestRate", "amount", "withDecimal"])),

    // filter out any falsey values
    filter(Boolean),

    // convert each to float
    map(parseFloat),

    // get the lowest
    min
  )(searchResults);

  return flow(
    map(
      update(
        ["rooms"],
        map((room) => ({
          ...room,
          isBestRate: flow(
            get(["lowestRate", "amount", "withDecimal"]),
            parseFloat,
            isEqual(lowestRate)
          )(room),
        }))
      )
    )
  )(searchResults);
};

export default updateRoomWithIsBestRate;
