const trimStringValues = (obj) =>
  Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    return {
      ...acc,
      [key]: typeof value === "string" ? value.trim() : value,
    };
  }, obj);

export default trimStringValues;
