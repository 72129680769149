import { API_REQUEST_STATES } from "./constants";

export default function getNextState({ currentState, toState }) {
  if (
    !currentState ||
    currentState === API_REQUEST_STATES.REQUEST ||
    toState === API_REQUEST_STATES.REQUEST
  ) {
    return toState;
  }
  return currentState;
}
