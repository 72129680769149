import React from "react";

import { useTranslation } from "hooks";

export default function Price({
  currency,
  amount,
  feesDisclaimer,
  priceLabel,
  children,
}) {
  const { t, formatPrice, locale } = useTranslation();

  return (
    <div className="price">
      <div className="nightly-rate">
        <span className="from">{t("From")}</span>{" "}
        <span className="fullprice">
          {formatPrice({
            currency,
            value: amount.withoutDecimal,
            decimalPlaces: 0,
            locale,
          })}
        </span>
        <div className="night">{t(priceLabel)}</div>
        {feesDisclaimer && (
          <div className="resort-fee">
            {t(feesDisclaimer.key, feesDisclaimer.params)}
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
