import React from "react";

import { useTranslation } from "hooks";
import ButtonSelect from "../ButtonSelect";

export default function SelectRoomButton({ roomName, bedTypes, onSelectRoom }) {
  const { t } = useTranslation();

  return (
    <div className="select-room">
      <ButtonSelect
        roomName={roomName}
        btnLabel={t("Select Room")}
        label={t("Choose Bed Option")}
        options={bedTypes.map(({ owsCode, description, offers }) => ({
          value: owsCode,
          label: t(description),
          offers,
        }))}
        ariaName={roomName}
        onSelectOption={({ value: owsCode, offers }) =>
          onSelectRoom({ owsCode, offers })
        }
      />
    </div>
  );
}
