import React from "react";

import InterestOption from "./InterestOption";

function Interest({ title, classTitle, options, onToggle }) {
  return (
    <div
      className={`fs-your-interests__interest fs-your-interests__interest--${classTitle
        .replace(/\s/g, "")
        .replace("&", "And")}`}
    >
      <div className="fs-your-interests__interest__title">
        <div className="icon" />
        <h3>{title}</h3>
      </div>
      <div className="fs-your-interests__interest__options">
        {options.map(({ value, label, isSelected }, i) => (
          <InterestOption
            key={`${value}:${i}`}
            onToggle={() => onToggle(value)}
            label={label}
            isSelected={isSelected}
          />
        ))}
      </div>
    </div>
  );
}

export default Interest;
