/* eslint-disable no-unused-vars */
import { RUSSIA } from "fixtures/constants";
import { SECTIONS } from "../BookingFlow/ConfirmYourStay/hooks/constants";

export default function doesMeetRussianCriteria({
  bookingType = SECTIONS.MYSELF,
  countryAddress,
  geoIPCode,
  isUserLoggedIn = false,
  propertyRegion = RUSSIA.NAME,
}) {
  /* return (
    !isUserLoggedIn &&
    bookingType === SECTIONS.MYSELF &&
    propertyRegion === RUSSIA.NAME &&
    [countryAddress, geoIPCode].includes(RUSSIA.CODE)
  ); */
  return false;
}
