/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  apiErrors: [],
  supplierErrors: [],
};

const bookingHistorySlice = createSlice({
  name: "bookingHistory",
  initialState,
  reducers: {
    fetchBookingHistory() {},
    fetchBookingHistoryFulfilled(state, { payload }) {
      state.data = payload;
    },
    fetchBookingHistoryFailed(state, { payload }) {
      state.apiErrors = payload.apiErrors || [];
      state.supplierErrors = payload.supplierErrors || [];
    },
    fetchBookingHistoryCancel() {},

    clearBookingHistory(state) {
      state.data = {};
      state.apiErrors = [];
      state.supplierErrors = [];
    },
  },
});

export const {
  clearBookingHistory,
  fetchBookingHistory,
  fetchBookingHistoryCancel,
  fetchBookingHistoryFailed,
  fetchBookingHistoryFulfilled,
} = bookingHistorySlice.actions;

export default bookingHistorySlice.reducer;
