const DOMINIC_REPUBLIC_CODES = ["809", "829", "849"];
const CANADA_AREA_CODES = [
  "204",
  "226",
  "236",
  "249",
  "250",
  "289",
  "306",
  "343",
  "365",
  "367",
  "403",
  "416",
  "418",
  "431",
  "437",
  "438",
  "450",
  "506",
  "514",
  "519",
  "548",
  "579",
  "581",
  "587",
  "604",
  "613",
  "639",
  "647",
  "705",
  "709",
  "778",
  "780",
  "782",
  "807",
  "819",
  "825",
  "867",
  "873",
  "902",
  "905",
];
const JAMAICA_COUNTRY_CODES = ["876", "658"];
const PUERTO_RICO_COUNTRY_CODES = ["787", "939"];

export default (number = "") => {
  const areaCode = number.slice(1, 4);

  if (number[0] !== "1") return false;

  return CANADA_AREA_CODES.includes(areaCode)
    ? "CA"
    : DOMINIC_REPUBLIC_CODES.includes(areaCode)
    ? "DO"
    : JAMAICA_COUNTRY_CODES.includes(areaCode)
    ? "JM"
    : PUERTO_RICO_COUNTRY_CODES.includes(areaCode)
    ? "PR"
    : false;
};
