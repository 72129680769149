import { ofType } from "redux-observable";
import { of } from "rxjs";
import {
  switchMap,
  takeUntil,
  map,
  mergeMap,
  catchError,
  withLatestFrom,
} from "rxjs/operators";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import {
  refreshOnRequestEventList$,
  modifyCustAncillary$,
} from "api/tretail/products";
import { getGuestRequests$ } from "api/messenger";

import {
  addRequestEvent,
  addRequestEventFulfilled,
  addRequestEventFailed,
  addRequestEventCancel,
  fetchGuestRequestsFulfilled,
} from "../guestRequests.slice";

const addRequestEventEpic = (action$, state$) =>
  action$.pipe(
    ofType(addRequestEvent.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: {
            requestId,
            bookingId,
            surname,
            propertyCode,
            reservationId,
            formData,
            locale,
            isOnItineraryPage,
          },
        },
      ]) => {
        return ajaxWithHealthCheck$({
          locale,
        }).pipe(
          switchMap(() => {
            return refreshOnRequestEventList$({
              bookingId,
              formData,
              locale,
            });
          }),

          switchMap((booking) => {
            return modifyCustAncillary$({
              bookingId,
              locale,
            }).pipe(map(() => booking));
          }),

          switchMap((booking) =>
            getGuestRequests$({ propertyCode, reservationId, surname }).pipe(
              map((guestRequests) => {
                return {
                  booking,
                  guestRequests,
                };
              })
            )
          ),

          mergeMap(({ booking, guestRequests }) => [
            fetchGuestRequestsFulfilled({
              reservationId,
              guestRequests,
            }),
            addRequestEventFulfilled({
              requestId,
              hotelCode: propertyCode,
              booking,
              isOnItineraryPage,
            }),
          ]),

          catchError((error) => {
            return of(addRequestEventFailed({ requestId, error }));
          }),

          takeUntil(action$.pipe(ofType(addRequestEventCancel.type)))
        );
      }
    )
  );

export default addRequestEventEpic;
