import React, { useEffect, useState } from "react";

import { travelDatesPreppers } from "Profile/utils/updateUpcomingTripMappers";
import { getReservationHighlightsArray } from "Profile/utils/getReservationHighlights";
import getRates from "Profile/utils/getRates";
import { useSelector } from "react-redux";
import { selectIsUserFullySignedIn } from "store/profile";
import CancelReservation from "./CancelReservation";
import ChangeDate from "./ChangeDate";
import EstimatedTotal from "./EstimatedTotal";
import UpcomingTrip from "./UpcomingTrip";

const LOCAL_VIEWS = {
  MAIN: "main",
  ESTIMATED_TOTAL: "estimated-total",
  CHANGE_TRAVEL_DATES: "change-travel-dates",
  CANCEL_RESERVATION: "cancel-reservation",
};

export default function UpcomingTripView({
  phoneNumberPrimary,
  bookingConfig,
  hotelCode,
  reservationId,
  allowCancellation,
  bookingId,
  bookingMessages,
  cancelByDate,
  canChangeBooking,
  changeArrivalTime,
  comment,
  employeeRate,
  hasErrors,
  highlightsMap,
  phoneNumber,
  priceViewable,
  propertyContent,
  showSavingSpinner,
  taxes,
  upcomingTrip,
  updateAdditionalRequests,
  updateMostImportantThing,
  updateRoomGuests,
  updateTravelDates,
  updateTripPurpose,
  youCanBookMessage,

  // WebChat
  isWebChatEnabled,
  openWebChat,

  // Welcome Amentities
  canRequestWelcomeAmenities,
  goToAmenityRequests,

  // Arrival Transportation
  goToArrivalTransportationView,
  hasTransportation,
  arrivalTransportation,
  canRequestTransportation,
}) {
  const [localView, setLocalView] = useState(LOCAL_VIEWS.MAIN);
  const isFullySignedIn = useSelector(selectIsUserFullySignedIn);

  const goToTripCancellation = () => {
    setLocalView(LOCAL_VIEWS.CANCEL_RESERVATION);
  };

  const goToEstimatedTotal = () => {
    setLocalView(LOCAL_VIEWS.ESTIMATED_TOTAL);
  };

  const goToChangeTravelDates = () => {
    setLocalView(LOCAL_VIEWS.CHANGE_TRAVEL_DATES);
  };

  const reservationHighlights =
    upcomingTrip && getReservationHighlightsArray(highlightsMap);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [localView]);

  useEffect(() => {
    if (hasErrors) {
      window.scrollTo(0, 0);
    }
  }, [hasErrors]);

  useEffect(() => {
    if (
      !isFullySignedIn &&
      (localView === LOCAL_VIEWS.CANCEL_RESERVATION ||
        localView === LOCAL_VIEWS.CHANGE_TRAVEL_DATES)
    ) {
      setLocalView(LOCAL_VIEWS.MAIN);
    }
  }, [isFullySignedIn]);

  const rates = getRates({ upcomingTrip, taxes });

  const stayResortFees =
    upcomingTrip?.hotelProducts[0]?.price?.prepay?.stayResortFees;

  switch (true) {
    case localView === LOCAL_VIEWS.ESTIMATED_TOTAL:
      return (
        <EstimatedTotal
          goBackHandler={() => setLocalView(LOCAL_VIEWS.MAIN)}
          highlights={highlightsMap}
          rates={rates}
          stayResortFees={stayResortFees}
          estimatedTotalDisclaimer={propertyContent?.estimatedTotalDisclaimer}
          bookingMessages={bookingMessages}
          openWebChat={openWebChat}
          isWebChatEnabled={isWebChatEnabled}
        />
      );

    case isFullySignedIn && localView === LOCAL_VIEWS.CHANGE_TRAVEL_DATES:
      return (
        <ChangeDate
          dates={travelDatesPreppers.receive(upcomingTrip)}
          goBackHandler={() => setLocalView(LOCAL_VIEWS.MAIN)}
          handleUpdateTravelDates={updateTravelDates}
          highlights={highlightsMap}
          hotelCode={hotelCode}
          propertyContent={propertyContent}
          openWebChat={openWebChat}
          isWebChatEnabled={isWebChatEnabled}
          showSavingSpinner={showSavingSpinner}
          taxes={taxes}
          upcomingTrip={upcomingTrip}
        />
      );

    case isFullySignedIn && localView === LOCAL_VIEWS.CANCEL_RESERVATION:
      return (
        <CancelReservation
          goBackHandler={() => setLocalView(LOCAL_VIEWS.MAIN)}
          bookingId={bookingId}
          openWebChat={openWebChat}
          isWebChatEnabled={isWebChatEnabled}
        />
      );

    default:
      return (
        <UpcomingTrip
          phoneNumberPrimary={phoneNumberPrimary}
          bookingConfig={bookingConfig}
          allowCancellation={allowCancellation}
          cancelByDate={cancelByDate}
          canChangeBooking={canChangeBooking}
          changeArrivalTime={changeArrivalTime}
          comment={comment}
          employeeRate={employeeRate}
          goToChangeTravelDates={goToChangeTravelDates}
          goToEstimatedTotal={priceViewable && goToEstimatedTotal}
          goToTripCancellation={goToTripCancellation}
          hasErrors={hasErrors}
          hotelCode={hotelCode}
          phoneNumber={phoneNumber}
          propertyContent={propertyContent}
          reservationHighlights={reservationHighlights}
          reservationId={reservationId}
          showSavingSpinner={showSavingSpinner}
          upcomingTrip={upcomingTrip}
          updateAdditionalRequests={updateAdditionalRequests}
          updateMostImportantThing={updateMostImportantThing}
          updateRoomGuests={updateRoomGuests}
          updateTripPurpose={updateTripPurpose}
          youCanBookMessage={youCanBookMessage}
          //
          // WebChat
          isWebChatEnabled={isWebChatEnabled}
          openWebChat={openWebChat}
          //
          // Welcome Amentities
          canRequestWelcomeAmenities={canRequestWelcomeAmenities}
          goToAmenityRequests={goToAmenityRequests}
          //
          // Arrival Transportation
          canRequestTransportation={canRequestTransportation}
          goToArrivalTransportationView={goToArrivalTransportationView}
          arrivalTransportation={arrivalTransportation}
          hasTransportation={hasTransportation}
        />
      );
  }
}
