import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { employeeSpecialRateRequest } from "store/profile";
import { useForm } from "react-hook-form";
import { useDispatchWithLocale, useTranslation } from "hooks";
import { Button, Link, Dropdown } from "Profile/components";
import { useSelector } from "react-redux";
import { selectPropertyIsSmokeFree } from "store/properties";
import * as profileRoutes from "Profile/profileRoutes";
import FriendsAndFamilyForm from "./FriendsAndFamily/FriendsAndFamilyForm";
import Caw from "./BookingFields/Caw";
import Messages from "./Messages";
import FriendsAndFamilyConfirmationModal from "./FriendsAndFamily/FriendsAndFamilyConfirmationModal";
import { SEARCH_BY_OPTIONS, FORM_OPTIONS } from "./BookingFields/constants";

function BookingOptionsForm({ searchData = {} }) {
  const {
    caw: {
      message: { title: messagesTitle = "", message: messages = [] } = {},
    },
    hotelCode: initialHotelCode = "",
    promoCode: initialPromoCode,
    dispatchSubmitSearch: planYourStaySubmit,
    setPromoCode,
    checkIn = "",
    checkOut,
  } = searchData;

  const { locale, t } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();
  const smokeFree = useSelector(selectPropertyIsSmokeFree(initialHotelCode));

  const [showFriendsAndFamilyModal, setShowFriendsAndFamilyModal] =
    useState(false);

  const [searchByOption, setSearchByOption] = useState(
    SEARCH_BY_OPTIONS.DESTINATION
  );

  const {
    register,
    handleSubmit,
    watch,
    reset: resetForm,
    control,
    errors,
    formState,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      hotelCode: initialHotelCode,
      formOption:
        initialPromoCode &&
        (initialPromoCode.startsWith(FORM_OPTIONS.DAILY_DISCOVERY) ||
          initialPromoCode.startsWith(FORM_OPTIONS.EXPERIENCE_RATE))
          ? initialPromoCode.substring(0, 6)
          : FORM_OPTIONS.DAILY_DISCOVERY,
    },
  });

  const watchFormOption = watch("formOption");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => setPromoCode(watchFormOption), [watchFormOption]);

  const handleSearchByChange = ({ target }) => {
    setSearchByOption(target.value);
  };

  const onSubmit = (formValues) => {
    if (watchFormOption !== FORM_OPTIONS.FRIENDS_FAMILY) {
      const { hotelCode, rooms } = formValues;
      planYourStaySubmit({
        hotelCode,
        // promoCode is the booking option plus the year of the checkin date
        promoCode: watchFormOption + checkIn.substring(2, 4),
        rooms,
      });
    } else {
      const { rooms } = formValues;

      dispatchWithLocale(
        employeeSpecialRateRequest({
          ...formValues,
          // the api expects an empty chidlren and / or adults array, so need to fill those in if undefined
          rooms: rooms.map(({ adults = [], children = [], smoking }) => ({
            adults,
            children,
            smoking,
          })),
          checkInDate: checkIn,
          checkOutDate: checkOut,
        })
      );
      setShowFriendsAndFamilyModal(true);
      resetForm();
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className="booking-options-form">
      {showFriendsAndFamilyModal && (
        <FriendsAndFamilyConfirmationModal
          close={() => setShowFriendsAndFamilyModal(false)}
        />
      )}
      <div className="stay-history-link">
        <Link to={profileRoutes.employeeStayHistoryPath.to({ locale })}>
          {t("Stay History")}
        </Link>
      </div>
      <form className="form-gray" noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="fs-radio-inputs row">
          <p>{t("Please select your rate")}</p>

          <ul className="radio-buttons">
            <li>
              <label className="fs-radio">
                <input
                  type="radio"
                  name="formOption"
                  value={FORM_OPTIONS.DAILY_DISCOVERY}
                  ref={register({ required: true })}
                />
                <span>{t("Discovery Nights")}</span>
              </label>
            </li>

            <li>
              <label className="fs-radio">
                <input
                  type="radio"
                  name="formOption"
                  value={FORM_OPTIONS.EXPERIENCE_RATE}
                  ref={register({ required: true })}
                />
                <span>{t("Employee Experience Rate")}</span>
              </label>
            </li>

            <li>
              <label className="fs-radio">
                <input
                  type="radio"
                  name="formOption"
                  value={FORM_OPTIONS.FRIENDS_FAMILY}
                  ref={register({ required: true })}
                />
                <span>{t("Friends and Family Rate Request")}</span>
              </label>
            </li>
          </ul>
        </div>

        <hr
          className={classNames("employee-booking-options", {
            "sub-divider": watchFormOption !== FORM_OPTIONS.FRIENDS_FAMILY,
          })}
        />

        {watchFormOption !== FORM_OPTIONS.FRIENDS_FAMILY && (
          <div className="rate-options">
            <div className="Search-By-Navigation-bar row">
              <div className="Search-By-Navigation-tab">
                <button
                  type="button"
                  className={classNames("Search-By-Navigation-item ty-c2", {
                    "is-selected":
                      searchByOption === SEARCH_BY_OPTIONS.DESTINATION,
                  })}
                  onClick={() => {
                    setSearchByOption(SEARCH_BY_OPTIONS.DESTINATION);
                  }}
                >
                  {t("Search By Destination")}
                </button>
              </div>
              <div className="Search-By-Navigation-tab">
                <button
                  type="button"
                  className={classNames("Search-By-Navigation-item ty-c2", {
                    "is-selected": searchByOption === SEARCH_BY_OPTIONS.DATES,
                  })}
                  onClick={() => {
                    setSearchByOption(SEARCH_BY_OPTIONS.DATES);
                  }}
                >
                  {t("Search By Dates")}
                </button>
              </div>
            </div>

            <div className="Search-By-Dropdown row">
              <Dropdown
                label={t("Search By")}
                name="searchBy"
                options={[
                  {
                    value: SEARCH_BY_OPTIONS.DESTINATION,
                    label: t("Destination"),
                  },
                  {
                    value: SEARCH_BY_OPTIONS.DATES,
                    label: t("Dates"),
                  },
                ]}
                formElementFieldFont="ty-c3"
                value={searchByOption}
                onChange={handleSearchByChange}
              />
            </div>

            <hr className="employee-booking-options" />

            <Caw
              searchData={searchData}
              formControls={{ control, watch, register, errors, setValue }}
              searchBy={searchByOption}
            />
          </div>
        )}

        {watchFormOption === FORM_OPTIONS.FRIENDS_FAMILY && (
          <FriendsAndFamilyForm
            searchData={searchData}
            formControls={{ control, watch, register, errors, setValue }}
            smokeFree={smokeFree}
          />
        )}

        <hr />

        {messagesTitle && messages.length && (
          <Messages title={messagesTitle} messages={messages} />
        )}

        <div className="view__actions">
          <Button type="submit" disabled={!formState.isValid}>
            {t(
              watchFormOption !== FORM_OPTIONS.FRIENDS_FAMILY
                ? "Find Rooms"
                : "Submit Request"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default BookingOptionsForm;
