import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "hooks";
import isMobileApp from "utils/isMobileApp";

import classNames from "classnames";
import queryString from "query-string";

import { selectIsEmployeeProfile } from "store/employeeProfile";

function BookingFlowLoadingIndicator({ opaque = false }) {
  const { t } = useTranslation();

  const employeeMode = useSelector(selectIsEmployeeProfile);
  const mobileAppSpinner = isMobileApp();
  const isYachtsMode =
    queryString.parse(window.location.search || "")?.yachts === "true";
  const showBestRateGuarantee =
    !employeeMode && !mobileAppSpinner && !isYachtsMode;

  return (
    <div
      className={classNames("loading-screen", {
        "loading-screen-opaque": opaque,
        "mobile-app-spinner": mobileAppSpinner,
      })}
    >
      <span
        className={classNames("loading-screen-spinner", {
          "mobile-app-spinner": mobileAppSpinner,
        })}
      >
        <div>
          {showBestRateGuarantee && (
            <div className="best_rate_guaranteed_wrapper">
              <p className="best_rate_guaranteed_title">
                {t("BEST RATE GUARANTEED")}
              </p>
            </div>
          )}
        </div>
      </span>
    </div>
  );
}

export default BookingFlowLoadingIndicator;
