import { ofType } from "redux-observable";
import { of } from "rxjs";
import { switchMap, takeUntil, map, catchError } from "rxjs/operators";

import getEmployeeCalendarAvailability$ from "api/content/getEmployeeCalendarAvailability/getEmployeeCalendarAvailability";
import {
  fetchEmployeeCalendarAvailability,
  fetchEmployeeCalendarAvailabilityFulfilled,
  fetchEmployeeCalendarAvailabilityCancel,
  fetchEmployeeCalendarAvailabilityFailed,
} from "./employeeCalendarAvailability.slice";

const fetchEmployeeCalendarAvailabilityEpic = (action$) =>
  action$.pipe(
    ofType(fetchEmployeeCalendarAvailability.type),
    switchMap((args) =>
      getEmployeeCalendarAvailability$(args).pipe(
        map(fetchEmployeeCalendarAvailabilityFulfilled),
        catchError((error) =>
          of(fetchEmployeeCalendarAvailabilityFailed(error))
        ),
        takeUntil(
          action$.pipe(ofType(fetchEmployeeCalendarAvailabilityCancel.type))
        )
      )
    )
  );

export default fetchEmployeeCalendarAvailabilityEpic;
