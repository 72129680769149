/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";

const DisplayOnlyInput = React.forwardRef(
  (
    {
      type,
      id,
      value,
      displayValue,
      name,
      classNames = {},
      label,
      register,
      includeHiddenInput,
      phoneCountryDisplayValue,
      phoneCountryValue,
      showAsInput,
    },
    ref
  ) => {
    const identifier = id || `displayInput-${name}`;
    return (
      <div className="DisplayOnlyInput">
        {(type === "text" || (type === "phone" && !showAsInput)) && (
          <div className={classNames.textClassNames || "DisplayOnlyInput-text"}>
            <div className={classNames.fieldClassNames || "TextInput"}>
              <div
                id={identifier}
                className={
                  classNames.displayClassNames ||
                  `DisplayOnlyInput-field${
                    showAsInput ? " formElement-field" : ""
                  }`
                }
              >
                <span>{displayValue || value}</span>
              </div>

              {label && (
                <label
                  htmlFor={identifier}
                  className={
                    classNames.labelClassNames ||
                    "DisplayOnlyInput-label formElement-label ty-c4 formElement--focusAlways"
                  }
                >
                  {label}
                </label>
              )}
            </div>
          </div>
        )}

        {type === "phone" && showAsInput && (
          <div className="row">
            <div
              className={
                classNames.phoneCountryCodeClassNames ||
                "col-sm-2 DisplayOnlyInput-phone-countryCode"
              }
            >
              <div
                className={classNames.fieldClassNames || "TextInput Dropdown"}
              >
                <div
                  id={identifier}
                  className={
                    classNames.displayClassNames ||
                    "DisplayOnlyInput-field formElement-field"
                  }
                >
                  <span>{phoneCountryDisplayValue || phoneCountryValue}</span>
                </div>
                {label && (
                  <label
                    htmlFor={identifier}
                    className={
                      classNames.labelClassNames ||
                      "DisplayOnlyInput-label formElement-label ty-c4 formElement--focusAlways"
                    }
                  >
                    {label}
                  </label>
                )}
              </div>
            </div>
            <div
              className={
                classNames.phoneTextClassNames ||
                "col-sm-10 TextInput DisplayOnlyInput-phone-phoneNumber"
              }
            >
              <div
                id={identifier}
                className={
                  classNames.phoneTextDisplayClassNames ||
                  "DisplayOnlyInput-field formElement-field"
                }
              >
                <span>{displayValue || value}</span>
              </div>
            </div>
          </div>
        )}

        {includeHiddenInput && (
          <input
            type="hidden"
            name={name}
            ref={register?.() || ref}
            value={value}
          />
        )}
      </div>
    );
  }
);

DisplayOnlyInput.propTypes = {
  type: PropTypes.oneOf(["text", "phone"]),
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  displayValue: PropTypes.string,
  register: PropTypes.func,
  includeHiddenInput: PropTypes.bool,
  phoneCountryDisplayValue: PropTypes.string,
  phoneCountryValue: PropTypes.string,
  classNames: PropTypes.shape({
    fieldClassNames: PropTypes.string,
    textClassNames: PropTypes.string,
    displayClassNames: PropTypes.string,
    labelClassNames: PropTypes.string,
    phoneCountryCodeClassNames: PropTypes.string,
    phoneTextClassNames: PropTypes.string,
    phoneTextDisplayClassNames: PropTypes.string,
  }),
  showAsInput: PropTypes.bool,
};

DisplayOnlyInput.defaultProps = {
  type: "text",
  value: "",
  includeHiddenInput: false,
  showAsInput: false,
};

DisplayOnlyInput.displayName = "DisplayOnlyInput";

export default DisplayOnlyInput;
