import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs";
import { switchMap, takeUntil, mergeMap, catchError } from "rxjs/operators";

import { TECHNICAL_ERROR_MESSAGE } from "fixtures/constants";
import { getAccommodationsContent$ } from "api/content";
import {
  fetchAccommodationsContent,
  fetchAccommodationsContentCancel,
  fetchAccommodationsContentFailed,
  fetchAccommodationsContentFulfilled,
} from "./accommodationsContent.slice";

const fetchAccommodationsContentEpic = (action$) =>
  action$.pipe(
    ofType(fetchAccommodationsContent.type),
    switchMap(({ payload: { locale = "en", propertyCode, propertyCodes } }) =>
      getAccommodationsContent$({ locale, propertyCode, propertyCodes }).pipe(
        mergeMap((responses) => {
          return responses.map(({ ajaxResponse, hotelCode }) => {
            if (ajaxResponse.error) {
              return fetchAccommodationsContentFailed({
                error: ajaxResponse.error,
                propertyCode: hotelCode,
              });
            }
            return fetchAccommodationsContentFulfilled({
              accommodationsContent: ajaxResponse,
              propertyCode: hotelCode,
            });
          });
        }),
        catchError(() =>
          of(
            fetchAccommodationsContentFailed({
              propertyCode,
              propertyCodes,
              error: TECHNICAL_ERROR_MESSAGE,
            })
          )
        ),
        takeUntil(action$.pipe(ofType(fetchAccommodationsContentCancel.type)))
      )
    )
  );

export default combineEpics(fetchAccommodationsContentEpic);
