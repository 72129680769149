import { API_REQUEST_STATES } from "store/apiRequestStates/constants";
import { fetchCountriesContent } from "./countriesContent.slice";

export const selectCountriesContent = (state) => state.countries?.data || [];

export const selectIsLoadingCountriesContent = ({ apiRequestStates }) => {
  const apiRequestState = apiRequestStates[fetchCountriesContent.type];
  return (
    (apiRequestState && apiRequestState === API_REQUEST_STATES.REQUEST) || false
  );
};

export const selectHasRequestedCountriesContent = ({ apiRequestStates }) => {
  const apiRequestState = apiRequestStates[fetchCountriesContent.type];
  return (
    (apiRequestState && apiRequestState === API_REQUEST_STATES.FULFILLED) ||
    false
  );
};
