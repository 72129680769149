import React, { useContext } from "react";
import classNames from "classnames";

import { ThemeContext } from "contexts/ThemeContext";

export default function ControlledCheckbox({
  name,
  identifier,
  disabled = false,
  checked,
  children,
  ...props
}) {
  const { theme } = useContext(ThemeContext);
  const id = identifier || `input-${name}`;

  return (
    <div
      className={classNames("fsp-input fsp-input--checkbox", {
        "fsp-input--dark": theme === "dark",
      })}
    >
      <label className="fsp-input__checkbox" htmlFor={id} disabled={disabled}>
        <input
          id={id}
          className="checkbox"
          type="checkbox"
          name={name}
          disabled={disabled}
          checked={checked}
          {...props}
        />

        {children && <div>{children}</div>}
      </label>
    </div>
  );
}
