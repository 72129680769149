import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";

import env from "config/env";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

export default function cancelBookingCust$({
  bookingId,
  locale,
  bookingEmailOptions,
}) {
  return ajax({
    ...basePostRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/bookings/${bookingId}/cust/cancellation`,
    body: {
      bookingEmailOptions,
    },
  }).pipe(pluck("response"));
}
