import { ofType } from "redux-observable";
import { of, merge } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import { requestInvoice$ } from "api/webCheckIn";
import { TECHNICAL_ERROR_MESSAGE } from "fixtures/constants";
import {
  requestInvoice,
  requestInvoiceFailed,
  requestInvoiceFulfilled,
} from "../bookings.slice";

export default function requestInvoiceEpic(action$) {
  return action$.pipe(
    ofType(requestInvoice.type),
    switchMap(
      ({
        payload: {
          checkInDate,
          confirmation,
          email,
          firstName,
          hotelCode,
          last4CC,
          lastName,
          locale,
          number,
          reservationId,
          timeStamp,
        },
      }) => {
        return requestInvoice$({
          checkInDate,
          confirmation,
          email,
          firstName,
          hotelCode,
          last4CC,
          lastName,
          locale,
          number,
          reservationId,
          timeStamp,
        }).pipe(
          map(({ response } = {}) =>
            response?.errorCode
              ? requestInvoiceFailed(
                  response?.message || TECHNICAL_ERROR_MESSAGE
                )
              : requestInvoiceFulfilled()
          )
        );
      }
    ),
    catchError((_, caught) =>
      merge(of(requestInvoiceFailed(TECHNICAL_ERROR_MESSAGE)), caught)
    )
  );
}
