/* eslint-disable react/destructuring-assignment */
import React, { useContext } from "react";

import { MediaContext } from "contexts/MediaContext";
import RoomDesktop from "./RoomDesktop";
import RoomMobile from "./RoomMobile";

export default function Room(props) {
  const media = useContext(MediaContext);

  return media.isGreaterThan.sm ? (
    <RoomDesktop {...props} />
  ) : (
    <RoomMobile {...props} />
  );
}
