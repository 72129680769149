import {
  toggleInterest,
  toggleInterestFulfilled,
} from "store/profile/profile.slice";
import { prepareGuestPreferenceListWithInterests } from "../profile.helpers";
import { updateProfileTemplate } from "./updateProfile";

export default updateProfileTemplate({
  actionType: toggleInterest.type,
  fulfillAction: toggleInterestFulfilled,
  updateFn: ({ extension = {} } = {}, newValue) => ({
    extension: {
      ...extension,
      guestPreferenceList: prepareGuestPreferenceListWithInterests(
        extension.guestPreferenceList,
        newValue
      ),
    },
  }),
});
