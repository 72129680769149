import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs";
import { switchMap, takeUntil, mergeMap, catchError } from "rxjs/operators";

// eslint-disable-next-line import/no-cycle
import { getPropertyWebCheckin$ } from "api/content";
import {
  fetchPropertyWebCheckin,
  fetchPropertyWebCheckinCancel,
  fetchPropertyWebCheckinFailed,
  fetchPropertyWebCheckinFulfilled,
} from "./propertyWebCheckin.slice";

const fetchPropertyWebCheckinEpic = (action$) =>
  action$.pipe(
    ofType(fetchPropertyWebCheckin.type),
    switchMap(({ payload: { locale, propertyCode } }) =>
      getPropertyWebCheckin$({ locale, propertyCode }).pipe(
        mergeMap(({ error, ...propertyWebCheckin }) =>
          error
            ? of(fetchPropertyWebCheckinFailed(error))
            : of(
                fetchPropertyWebCheckinFulfilled({
                  propertyCode,
                  propertyWebCheckin,
                })
              )
        ),
        catchError((error) => of(fetchPropertyWebCheckinFailed(error))),
        takeUntil(action$.pipe(ofType(fetchPropertyWebCheckinCancel.type)))
      )
    )
  );

export default combineEpics(fetchPropertyWebCheckinEpic);
