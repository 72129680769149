export const STEP_2 = {
  OFFER_CANCELLATION: "LLL do yyyy",
};

export const STEP_3 = {
  FREE_ONLINE_CANCELLATION: "LLL-dd-yyyy",
};

export const DATE_WITH_TIME = "yyyy-MM-dd HH:mm:ss";

export const PROFILE = {
  ESTIMATED_TOTAL_RATES: "EEEE, dd MMMM",
  EMP_CHILD_BIRTHDAY: "MM/dd/yyyy",
  INVOICE: "MM/dd/yyyy",
  FOLIO_TIME_STAMP: "EEEE, MMMM do yyyy, h:mm:ss aaaa z",
};

export const TWELVE_HOURS = "h:mm a";
export const TWENTY_FOUR_HOURS = "HH:mm";

export const PROPERTY_CHECK_IN_DATE_TIME_FORMAT = "yyyy-MM-dd h:mm a";
export const DATE_AND_TIME = "yyyy-MM-dd HH:mm";

export const WEB_CHECK_IN = {
  PROPERTY_CHECK_IN_DATE_TIME_FORMAT,
  CALENDARS: "yyyyMMdd'T'HHmmss",
  BOOKING_CHECK_IN_DATE_TIME: DATE_WITH_TIME,
};

export const DATA = "yyyy-MM-dd";

export const ETA = "HH:mm:ss.SSS";
