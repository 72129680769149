import { getRoomsData } from "rbf-content-mapping";
import flow from "lodash/fp/flow";
import get from "lodash/fp/get";
import map from "lodash/fp/map";
import flatMap from "lodash/fp/flatMap";
import update from "lodash/fp/update";
import min from "lodash/fp/min";
import filter from "lodash/fp/filter";
import isEqual from "lodash/fp/isEqual";

import { selectPropertyAccommodationsContent } from "store/accommodationsContent";
import { selectPropertyBookingMessagesContent } from "store/bookingMessages";
import {
  selectSearchResults,
  selectSearchResultsApiErrors,
  selectSearchResultsSupplierErrors,
} from "store/searchResults";
import { selectPropertyOffersContent } from "store/offersContent";
import {
  selectPropertyContent,
  selectPropertyCurrency,
} from "store/propertyContent";
import { selectExchangeRates } from "store/exchangeRates";
import { selectGlobalSettings } from "store/globalSettings";
import { selectTaxesContent } from "store/taxesContent";
import { getRoomsWithPackages } from "BookingFlow/ChooseYourRoom/utils";
import { SORT_BY_RATE } from "fixtures/constants";
import { isEmployeeRate } from "BookingFlow/utils";
import { selectOfferTermsAndConditions } from "store/offersTermsAndConditionsContent";

const selectRoomsData =
  ({
    currencyCode,
    geoIpCountryCode,
    hasInteractedWithAccessiblityOption = false,
    hotelCode,
    isAccessible = false,
    ppMode: isPreferredPartners = false,
    locale,
    promoCode,
    ratePlanCode,
    roomOwsCodes,
    roomOfferCode,
    roomOwsCode,
    roomSelections = [],
    selectedPackages = [],
    sortByHighestToLowestRate = SORT_BY_RATE.BY_RATE,
    dates,
  }) =>
  (state) => {
    const roomSelectionsWithPackages = getRoomsWithPackages({
      rooms: roomSelections,
      packages: selectedPackages,
    });

    const employeeMode = isEmployeeRate({ promoCode });

    const results = selectSearchResults(state);

    const createResultIdToOfferCodeMap = (arr) =>
      arr.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id]: curr.roomRateId.split(".")[1] || "",
        }),
        {}
      );

    const resultIdToOfferCodeMap = flow(
      get(["hotelOptions"]),
      flatMap(get("roomOptions")),
      flatMap(get("roomTypeIds")),
      flatMap(get("roomRateIds")),
      createResultIdToOfferCodeMap
    )(results);

    const { searchResults, ...result } = getRoomsData({
      content: {
        accommodations: selectPropertyAccommodationsContent(hotelCode)(state),
        availabilityBookingMessages: selectPropertyBookingMessagesContent({
          hotelCode,
          bookingStep: "availability",
        })(state),
        offers: selectPropertyOffersContent(hotelCode)(state),
        property: selectPropertyContent(hotelCode)(state),
        exchangeRates: { exchangeRates: selectExchangeRates(state) },
        globalSettings: selectGlobalSettings(state) || {},
        taxes: { taxes: selectTaxesContent(hotelCode)(state) || [] },
      },
      employee: employeeMode,
      geoIpCountryCode,
      language: locale,
      oj: {
        results,
        apiErrors: selectSearchResultsApiErrors(state),
        supplierErrors: selectSearchResultsSupplierErrors(state),
      },
      pp: isPreferredPartners,
      promoCode,
      ratePlanCode: ratePlanCode || roomOfferCode,
      roomOwsCodes: roomOwsCodes || roomOwsCode,
      roomsState: {
        accessible: isAccessible,
        currency: currencyCode || selectPropertyCurrency(hotelCode)(state),
        roomSelections: roomSelectionsWithPackages,
        sort: sortByHighestToLowestRate,
        hasInteractedWithAccessiblityOption,
      },
    });

    const updateOffersWithOfferTermsAndConditions = flow(
      map(
        update(
          ["rooms"],
          map(
            update(
              ["bedTypes"],
              map((bedType) => {
                const roomTypeId = bedType.owsCode;

                return update(
                  ["offers"],
                  map((offer) => {
                    const offerCode =
                      offer.owsCode ||
                      resultIdToOfferCodeMap[offer.resultId] ||
                      "";

                    return update(["offerTermsAndConditionsContent"], () =>
                      selectOfferTermsAndConditions({
                        hotelCode,
                        checkinDate: dates.checkIn,
                        checkoutDate: dates.checkOut,
                        promoCode,
                        roomTypeId,
                        offerCode,
                      })(state)
                    )(offer);
                  })
                )(bedType);
              })
            )
          )
        )
      )
    );

    const lowestRate = flow(
      // get flat array of all rooms
      flatMap(get("rooms")),

      // get array of lowest rates
      map(get(["lowestRate", "amount", "withDecimal"])),

      // filter out any falsey values
      filter(Boolean),

      // convert each to float
      map(parseFloat),

      // get the lowest
      min
    )(searchResults || []);

    const updateRoomWithIsBestRate = flow(
      map(
        update(
          ["rooms"],
          map((room) => ({
            ...room,
            isBestRate: flow(
              get(["lowestRate", "amount", "withDecimal"]),
              parseFloat,
              isEqual(lowestRate)
            )(room),
          }))
        )
      )
    );

    return {
      ...result,
      searchResults: flow(
        updateOffersWithOfferTermsAndConditions,
        updateRoomWithIsBestRate
      )(searchResults || []),
    };
  };

export default selectRoomsData;
