import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";

import env from "config/env";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

export const retrieveBooking$ = ({
  reservationId,
  hotelCode,
  surname,
  creditCardExpiryDate,
  creditCardNumber,
  firstName,
  reservationStartDate,
  locale,
}) =>
  ajax({
    ...basePostRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/bookingHistory/retrieval`,
    body: {
      bookingReference: reservationId || undefined,
      creditCardExpiryDate: creditCardExpiryDate || undefined,
      creditCardNumber: creditCardNumber || undefined,
      firstName: firstName || undefined,
      hotelCode: hotelCode || undefined,
      reservationStartDate: reservationStartDate || undefined,
      supplierCode: "IBE",
      surname: surname || undefined,
    },
  }).pipe(pluck("response"));

export default retrieveBooking$;
