import { createTransform } from "redux-persist";

const expireTransform = (expireIn, expireKey, defaultValue = {}) => {
  const storedExpiration = localStorage.getItem(expireKey);
  let expired = false;

  if (storedExpiration) {
    const expiring = parseInt(storedExpiration, 10);
    const now = new Date().getTime();
    expired = !Number.isNaN(expiring) && now > expiring;
  }

  return createTransform(
    (inboundState) => {
      setTimeout(() => {
        const expireValue = (new Date().getTime() + expireIn).toString();
        localStorage.setItem(expireKey, expireValue);
      }, 0);
      return inboundState;
    },

    (outboundState, key) => {
      if (!expired) {
        return outboundState;
      }

      if (defaultValue[key]) {
        return defaultValue[key];
      }

      return defaultValue;
    }
  );
};

export default expireTransform;
