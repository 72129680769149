/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import getMergedPropertyCodes from "store/getMergedPropertyCodes";
import {
  setRequestStateRequesting,
  setRequestStateFulfilled,
  setRequestStateFailed,
  setRequestStateCancelled,
} from "store/apiRequestStates/setRequestState";

const initialState = {
  data: {},
  error: {},
  requestStates: {},
};

const offersContentSlice = createSlice({
  name: "offersContent",
  initialState,
  reducers: {
    fetchOffersContent(state, { payload }) {
      const propertyCodes = getMergedPropertyCodes(payload);
      propertyCodes.forEach((propertyCode) => {
        delete state.error[propertyCode];
        state.requestStates[propertyCode] = setRequestStateRequesting(
          state.requestStates[propertyCode]
        );
      });
    },

    fetchOffersContentFulfilled(state, { payload }) {
      const { propertyCode, offersContent } = payload;
      state.data[propertyCode] = offersContent;
      state.requestStates[propertyCode] = setRequestStateFulfilled(
        state.requestStates[propertyCode]
      );
    },

    fetchOffersContentFailed(state, { payload }) {
      const { propertyCode, error } = payload;
      state.error[propertyCode] = error;
      state.requestStates[propertyCode] = setRequestStateFailed(
        state.requestStates[propertyCode]
      );
    },

    fetchOffersContentCancel(state, { payload }) {
      const propertyCodes = getMergedPropertyCodes(payload);
      propertyCodes.forEach((code) => {
        state.requestStates[code] = setRequestStateCancelled(
          state.requestStates[code]
        );
      });
    },

    offersContentReset() {
      return initialState;
    },
  },
});

export const {
  fetchOffersContent,
  fetchOffersContentFulfilled,
  fetchOffersContentFailed,
  fetchOffersContentCancel,
  offersContentReset,
} = offersContentSlice.actions;

export default offersContentSlice.reducer;
