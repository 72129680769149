/* eslint-disable camelcase */
import { find, flow, get, map, update, round } from "lodash/fp";
import { differenceInDays, parseISO } from "date-fns";

const divideBy = (b) => (a) => a / b;

const getFlexDatesAnalyticsData = ({
  flexDatesCalendarPrices,
  selectedCheckInDate,
  searchedCheckInDate,
  isAccessible = false,
  exchangeRates,
}) => {
  if (!flexDatesCalendarPrices) {
    return {
      flex_price_differential: "no flex dates",
      flex_percent_differential: "no flex dates",
      flex_date_differential: "no flex dates",
    };
  }

  if (selectedCheckInDate === searchedCheckInDate) {
    return {
      flex_price_differential: "original search",
      flex_percent_differential: "original search",
      flex_date_differential: "original search",
    };
  }

  const UNAVAILABLE = "unavailable";

  const exchangeRate =
    exchangeRates.find(
      ({ code }) =>
        code === flexDatesCalendarPrices[0].bestAvailableRateCurrencyCode
    )?.rate || 1;

  const divideByExchangeRate = divideBy(exchangeRate);

  const whichRate = isAccessible
    ? "bestAvailableRatePerNightInclAccessible"
    : "bestAvailableRatePerNightExclAccessible";

  const [selected, searched] = flow(
    map(
      (checkinDate) =>
        find((a) => a.checkinDate === checkinDate)(flexDatesCalendarPrices) || {
          checkinDate,
        }
    ),
    map(update([whichRate], flow(parseFloat, divideByExchangeRate, round)))
  )([selectedCheckInDate, searchedCheckInDate]);

  const priceDifference = selected[whichRate] - searched[whichRate];

  const flex_date_differential = differenceInDays
    .call(
      undefined,
      ...flow(map(get("checkinDate")), map(parseISO))([selected, searched])
    )
    .toString();

  return {
    flex_price_differential: !Number.isNaN(priceDifference)
      ? priceDifference.toFixed(2)
      : UNAVAILABLE,
    flex_percent_differential: !Number.isNaN(priceDifference)
      ? Math.round((priceDifference / searched[whichRate]) * 100).toString()
      : UNAVAILABLE,
    flex_date_differential,
  };
};

export default getFlexDatesAnalyticsData;
