/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  resultSetId: null,
  createHotelSearchResponse: {},
  data: {},
  isAllInPricingResults: false,
  isLoading: false,
  errors: [],
  apiErrors: [],
  supplierErrors: [],
  occupancyError: null,
  missingLanguageError: null,
  plainSearchParams: "",
};

function handleFetchSearchResultsFulfilled(state, { payload }) {
  state.resultSetId = payload.data.id;
  if (payload.createHotelSearchResponse) {
    state.createHotelSearchResponse = payload.createHotelSearchResponse;
  }
  if (Object.prototype.hasOwnProperty.call(payload, "isAllInPricingResults")) {
    state.isAllInPricingResults = payload.isAllInPricingResults;
  }
  state.data[payload.data.id] = payload.data;
  state.isLoading = false;
  state.errors = [];
  state.apiErrors = [];
  state.supplierErrors = [];
}

const searchResultsSlice = createSlice({
  name: "searchResults",
  initialState,
  reducers: {
    submitSearch(state) {
      state.resultSetId = null;
      state.createHotelSearchResponse = {};
      state.data = {};
      state.isLoading = true;
      state.errors = [];
      state.apiErrors = [];
      state.supplierErrors = [];
      state.missingLanguageError = null;
    },

    changeSearch(state) {
      state.resultSetId = null;
      state.createHotelSearchResponse = {};
      state.data = {};
      state.errors = [];
      state.apiErrors = [];
      state.supplierErrors = [];
      state.missingLanguageError = null;
    },

    fetchSearchResults(state, { payload: { refetch, plainSearchParams } }) {
      state.isLoading = true;
      state.errors = [];
      state.apiErrors = [];
      state.supplierErrors = [];
      state.plainSearchParams = plainSearchParams;
      if (!refetch) {
        state.resultSetId = null;
        state.data = {};
      }
    },
    fetchSearchResultsForResultSetId(
      state,
      { payload: { plainSearchParams } }
    ) {
      state.isLoading = true;
      state.errors = [];
      state.apiErrors = [];
      state.supplierErrors = [];
      state.plainSearchParams = plainSearchParams;
    },
    fetchSearchResultsFulfilled: handleFetchSearchResultsFulfilled,
    fetchSearchResultsForResultSetIdFulfilled:
      handleFetchSearchResultsFulfilled,
    fetchSearchResultsFailed(state, { payload = {} }) {
      state.resultSetId = payload.resultSetId;
      state.isLoading = false;
      state.errors = payload.errors || [];
      state.apiErrors = payload.apiErrors || [];
      state.supplierErrors = payload.supplierErrors || [];
      state.missingLanguageError = null;
    },
    fetchSearchResultsCancel(state) {
      state.isLoading = false;
    },

    missingLanguage(state, { payload }) {
      state.isLoading = false;
      state.missingLanguageError = payload;
    },
    dismissMissingLanguage(state) {
      state.missingLanguageError = null;
    },

    occupancyError(state, { payload }) {
      state.isLoading = false;
      state.occupancyError = payload;
    },
    dismissOccupancyError(state) {
      state.occupancyError = null;
    },

    searchResultsClear(state) {
      state.resultSetId = null;
      state.createHotelSearchResponse = {};
      state.data = {};
      state.errors = [];
      state.apiErrors = [];
      state.supplierErrors = [];
      state.plainSearchParams = "";
    },

    clearErrors(state) {
      state.errors = [];
      state.apiErrors = [];
      state.supplierErrors = [];
    },

    sortResultsEvt() {},

    accessibleRoomsToggleEvt() {},

    viewRoomDetailsEvt() {},

    viewFloorplanEvt() {},

    viewServicesAmenitiesEvt() {},
  },
});

export const {
  accessibleRoomsToggleEvt,
  changeSearch,
  clearErrors,
  dismissMissingLanguage,
  dismissOccupancyError,
  fetchSearchResults,
  fetchSearchResultsForResultSetId,
  fetchSearchResultsCancel,
  fetchSearchResultsFailed,
  fetchSearchResultsFulfilled,
  fetchSearchResultsForResultSetIdFulfilled,
  missingLanguage,
  occupancyError,
  searchResultsClear,
  sortResultsEvt,
  submitSearch,
  viewFloorplanEvt,
  viewRoomDetailsEvt,
  viewServicesAmenitiesEvt,
} = searchResultsSlice.actions;

export default searchResultsSlice.reducer;
