/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const bookingCommentsSlice = createSlice({
  name: "bookingComments",
  initialState: {
    preBookingComments: {},
    byReservationId: {},
    apiErrors: [],
    supplierErrors: [],
  },
  reducers: {
    fetchBookingComments(state) {
      state.apiErrors = [];
      state.supplierErrors = [];
    },

    fetchBookingCommentsFulfilled(
      state,
      {
        payload: {
          bookingId,
          reservationId,
          preBookingComments,
          postBookingComments,
        },
      }
    ) {
      state.apiErrors = [];
      state.supplierErrors = [];
      if (preBookingComments) {
        state.preBookingComments[bookingId] = preBookingComments;
      }
      if (postBookingComments && reservationId) {
        state.byReservationId[reservationId] = postBookingComments;
      }
    },

    fetchBookingCommentsFailed(state, { payload }) {
      state.apiErrors = payload.apiErrors || [];
      state.supplierErrors = payload.supplierErrors || [];
    },

    fetchBookingCommentsCancel() {},

    clearBookingCommentsErrors(state) {
      state.apiErrors = [];
      state.supplierErrors = [];
    },

    updatePostBookingComments(state) {
      state.apiErrors = [];
      state.supplierErrors = [];
    },
    updatePostBookingCommentsFulfilled(
      state,
      { payload: { reservationId, postBookingComments } }
    ) {
      state.apiErrors = [];
      state.supplierErrors = [];
      state.byReservationId[reservationId] = postBookingComments;
    },
    updatePostBookingCommentsFailed(state, { payload }) {
      state.apiErrors = payload.apiErrors || [];
      state.supplierErrors = payload.supplierErrors || [];
    },
    updatePostBookingCommentsCancel() {},

    updateProfileComments() {},
    updateProfileCommentsFulfilled() {},
    updateProfileCommentsFailed(state, { payload }) {
      state.apiErrors = payload.apiErrors || [];
      state.supplierErrors = payload.supplierErrors || [];
    },
    updateProfileCommentsCancel() {},
  },
});

export const {
  fetchBookingComments,
  fetchBookingCommentsFulfilled,
  fetchBookingCommentsFailed,
  fetchBookingCommentsCancel,
  clearBookingCommentsErrors,

  updatePostBookingComments,
  updatePostBookingCommentsFulfilled,
  updatePostBookingCommentsFailed,
  updatePostBookingCommentsCancel,

  updateProfileComments,
  updateProfileCommentsFulfilled,
  updateProfileCommentsFailed,
  updateProfileCommentsCancel,
} = bookingCommentsSlice.actions;

export default bookingCommentsSlice.reducer;
