import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";

import env from "config/env";
import { baseGetRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

export default function retrieveHotelProductComment$({
  bookingId,
  productId,
  locale,
}) {
  return ajax({
    ...baseGetRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/bookings/${bookingId}/products/hotel/comments/${productId}`,
  }).pipe(pluck("response"));
}
