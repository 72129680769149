import { ofType } from "redux-observable";
import {
  catchError,
  mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
} from "rxjs/operators";
import { of, iif } from "rxjs";
import { push } from "connected-react-router";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import * as profileRoutes from "Profile/profileRoutes";
import { submitOTP$, logout$ } from "api/tretail/authentication";
import {
  fetchProfileFulfilled,
  checkUpdateEmailValidated,
} from "store/profile/profile.slice";
import {
  submitOtp,
  submitOtpFulfilled,
  submitOtpFailed,
  submitOtpCancel,
  redirectTo,
} from "store/signIn/signIn.slice";
import { resetRegistration } from "store/registration/registration.slice";
import { loadingRedirect } from "store/appStatus/appStatus.slice";
import { fetchBookingHistoryFulfilled } from "store/bookingHistory";
import { getProfileDetails$ } from "api/tretail/profile";
import { fetchBookingHistory$ } from "store/bookingHistory/epics/fetchBookingHistory";
import { fetchEmployeeProfile$ } from "store/employeeProfile/epics/fetchEmployeeProfile";
import {
  clearEmployeeProfile,
  fetchEmployeeProfileFulfilled,
  setEmployeeActivateProfile,
} from "store/employeeProfile";
import { setShouldOpen as setToastNotificationShouldOpen } from "store/toastNotification";

import { TECHNICAL_ERROR_MESSAGE } from "fixtures/constants";
import catchInternalServerError from "store/catchInternalServerError";
import { LOGIN_TYPES } from "api/tretail/authentication/generateOTP";
import { cleanPhoneNumber, isResidentialAppUrl } from "utils/utils";
import { constructBookingFailed, setBookingInProgressId } from "store/bookings";
import { errorCodes } from "utils";

export default function submitOtpEpic(action$, state$) {
  return action$.pipe(
    ofType(submitOtp.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: {
            formValues: { otp, persist },
            afterSignInRedirectTo,
            locale,
            employeeMode,
            isUserPersistent,
            showToastOnNextView,
          },
        },
        {
          signIn: {
            submitOTP: { sendCodeTo, sendCodeVia },
          },
          router: {
            location: { pathname: currentLocation },
          },
        },
      ]) => {
        return ajaxWithHealthCheck$({
          locale,
        }).pipe(
          switchMap(() => {
            return submitOTP$({
              sendCodeTo:
                sendCodeVia === LOGIN_TYPES.PHONE_NUMBER
                  ? `+${cleanPhoneNumber(sendCodeTo[sendCodeVia])}`
                  : sendCodeTo[sendCodeVia],
              sendCodeVia,
              otp,
              persist: !!persist,
              isUserPersistent,
              locale,
            }).pipe(
              mergeMap(({ bookingId }) => {
                if (
                  typeof afterSignInRedirectTo === "string" &&
                  afterSignInRedirectTo.startsWith("http")
                ) {
                  const redirectToResiApp =
                    showToastOnNextView &&
                    isResidentialAppUrl(afterSignInRedirectTo)
                      ? `${afterSignInRedirectTo}${
                          afterSignInRedirectTo.includes("?") ? "&" : "?"
                        }openToast=true`
                      : null;
                  return [
                    setBookingInProgressId({ bookingId }),
                    submitOtpFulfilled(),
                    resetRegistration(),
                    loadingRedirect(),
                    redirectTo({
                      afterSignInRedirectTo:
                        redirectToResiApp || afterSignInRedirectTo,
                    }),
                  ];
                }

                return getProfileDetails$({ locale }).pipe(
                  switchMap((profile) => {
                    const hasEmployeeId =
                      !!profile?.extension?.employeeId &&
                      profile.extension.employeeId !== "0";

                    return iif(
                      () => hasEmployeeId && employeeMode,
                      fetchEmployeeProfile$({
                        locale,
                        workdayId: profile.extension.employeeId,
                      }),
                      of(undefined)
                    ).pipe(
                      mergeMap((employee = {}) => {
                        if (
                          hasEmployeeId &&
                          employeeMode &&
                          !employee.workPlace
                        ) {
                          // Invalid Employee Profile - Log Out and show an error
                          return logout$({ locale }).pipe(
                            mergeMap(() => {
                              return [
                                submitOtpFailed({
                                  errors: [
                                    {
                                      errorCode:
                                        employee.error ||
                                        TECHNICAL_ERROR_MESSAGE,
                                    },
                                  ],
                                }),
                              ];
                            }),
                            catchError(() => {
                              return of(
                                submitOtpFailed({
                                  errors: [
                                    {
                                      errorCode:
                                        employee.error ||
                                        TECHNICAL_ERROR_MESSAGE,
                                    },
                                  ],
                                })
                              );
                            })
                          );
                        }

                        const employeeAction =
                          employeeMode && !hasEmployeeId
                            ? [setEmployeeActivateProfile()]
                            : employeeMode
                            ? [
                                fetchEmployeeProfileFulfilled(
                                  employee.workPlace ? employee : {}
                                ),
                              ]
                            : [clearEmployeeProfile()];

                        const redirectToResiApp =
                          typeof afterSignInRedirectTo === "string" &&
                          isResidentialAppUrl(afterSignInRedirectTo)
                            ? `${afterSignInRedirectTo}${
                                afterSignInRedirectTo.includes("?") ? "&" : "?"
                              }openToast=true`
                            : null;
                        const signInModalAction = isUserPersistent
                          ? [
                              ...(!afterSignInRedirectTo
                                ? [
                                    setToastNotificationShouldOpen({
                                      shouldOpen: showToastOnNextView
                                        ? "onNextView"
                                        : "onSameView",
                                    }),
                                    push(currentLocation),
                                  ]
                                : [
                                    ...(redirectToResiApp
                                      ? [
                                          redirectTo({
                                            afterSignInRedirectTo:
                                              redirectToResiApp,
                                          }),
                                        ]
                                      : [
                                          setToastNotificationShouldOpen({
                                            shouldOpen: "onNextView",
                                          }),
                                          push(afterSignInRedirectTo),
                                        ]),
                                  ]),
                            ]
                          : [
                              push(
                                afterSignInRedirectTo ||
                                  profileRoutes.profilePath.to({ locale })
                              ),
                            ];

                        // Order of actions is critical here with submitOtpFulfilled being last
                        // A combination of fetchProfileFulfilled & submitOtpFulfilled will trigger a redirect
                        // to the profile page and stop all following actions from firing
                        // checkUpdateEmailValidated must be preceded by fetchProfileFulfilled
                        return fetchBookingHistory$({ locale }).pipe(
                          mergeMap((bookingHistory) => {
                            return [
                              ...employeeAction,
                              resetRegistration(),
                              fetchProfileFulfilled(profile),
                              fetchBookingHistoryFulfilled(bookingHistory),
                              ...(sendCodeVia === LOGIN_TYPES.EMAIL_ADDRESS
                                ? [
                                    checkUpdateEmailValidated({
                                      profile,
                                      signInEmail: sendCodeTo[sendCodeVia],
                                    }),
                                  ]
                                : []),
                              setBookingInProgressId({ bookingId }),
                              submitOtpFulfilled(),
                              ...signInModalAction,
                            ];
                          })
                        );
                      })
                    );
                  })
                );
              }),

              catchInternalServerError(),

              catchError(({ response = {} }) => {
                const errors = {
                  apiErrors: response?.apiErrors || [],
                  supplierErrors: response?.supplierErrors || [],
                };
                if (
                  afterSignInRedirectTo &&
                  response?.apiErrors?.[0]?.errorCode ===
                    errorCodes.CHECK_BOOKING_ID_CODE
                ) {
                  return [
                    constructBookingFailed(errors),
                    push(afterSignInRedirectTo),
                  ];
                }
                return of(submitOtpFailed(errors));
              }),
              takeUntil(action$.pipe(ofType(submitOtpCancel.type)))
            );
          })
        );
      }
    )
  );
}
