import React from "react";
import { useSelector } from "react-redux";

import { BookingFlowLayout, bookingFlowRoutes } from "BookingFlow";
import { selectSystemOfflineMessage } from "store/appStatus";
import SystemOfflineMessage from "BookingFlow/components/SystemOfflineMessage";
import useSystemOffline from "hooks/useSystemOffline";
import env from "config/env";

const { STATIC_SITE_URL, PREFERRED_PARTNER_DOMAIN } = env;

export default function SystemOfflineView() {
  const systemOfflineMessage = useSelector(selectSystemOfflineMessage);

  const searchData = useSystemOffline();
  const { isPreferredPartners, ppReturnUrl = PREFERRED_PARTNER_DOMAIN } =
    searchData;

  return (
    <BookingFlowLayout
      pageTitle={systemOfflineMessage.title}
      htmlBodyClassName={bookingFlowRoutes.planYourStay.htmlBodyClassName}
      isPreferredPartners={isPreferredPartners}
      ppReturnUrl={ppReturnUrl}
    >
      <div className="main-inner">
        <div className="change-search">
          <div className="container" id="search-panel-container">
            <div className="fs-search-panel">
              <SystemOfflineMessage
                title={
                  systemOfflineMessage.title ||
                  "Online reservation system is offline."
                }
                phone={systemOfflineMessage.phone}
                explanation={
                  systemOfflineMessage.explanation ||
                  "The Four Seasons online reservation system is temporarily offline for scheduled maintenance."
                }
                email={systemOfflineMessage.email}
                buttonText={systemOfflineMessage.buttonText || "Find a Hotel"}
                buttonLink={
                  systemOfflineMessage.buttonLink ||
                  `${STATIC_SITE_URL}/find_a_hotel_or_resort.html`
                }
                buttonSuppress={systemOfflineMessage.buttonSuppress}
              />
            </div>
          </div>
        </div>
      </div>
    </BookingFlowLayout>
  );
}
