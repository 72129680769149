import queryString from "query-string";

import contentAjax from "api/content/contentAjax";

export default function getTermsAndConditionsContent$({
  hotelCode,
  locale = "en",
  options = {},
  retryInEN,
}) {
  return contentAjax({
    locale,
    url: `/properties/${hotelCode}/termsandconditions?${queryString.stringify(
      options
    )}`,
    retryInEN,
  });
}
