export const selectIsOpen = ({ addReservationModal }) =>
  addReservationModal.isOpen;

export const selectIsSubmitting = ({ addReservationModal }) =>
  addReservationModal.isSubmitting;

export const selectIsSuccess = ({ addReservationModal }) =>
  addReservationModal.isSuccess;

export const selectAddReservationError = ({ addReservationModal }) =>
  addReservationModal.error;
