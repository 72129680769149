import { ofType } from "redux-observable";
import { mergeMap, withLatestFrom } from "rxjs/operators";
import { push } from "connected-react-router";

import { bookingFlowRoutes } from "BookingFlow";
import * as profileRoutes from "Profile/profileRoutes";
import {
  searchResultsClear,
  changeSearch,
} from "store/searchResults/searchResults.slice";

export default function changeSearchEpic(action$, state$) {
  return action$.pipe(
    ofType(changeSearch.type),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { locale, employeeMode = false },
        },
        state,
      ]) => {
        const {
          router: { location },
        } = state;

        return [
          searchResultsClear({ locale }),
          push({
            ...location,
            pathname: employeeMode
              ? profileRoutes.employeeProfilePath.to({ locale })
              : bookingFlowRoutes.planYourStay.to({ locale }),
            state: {
              isChangingSearch: true,
            },
          }),
        ];
      }
    )
  );
}
