import React from "react";
import { DictionaryKeyContent } from "BookingFlow/components";

function Messages({ title, messages }) {
  return (
    <div className="employee-notes">
      <h2 className="ty-c1">
        <DictionaryKeyContent dict={title} />
      </h2>
      <ul>
        {messages.map((message) => (
          <li key={message}>{message}</li>
        ))}
      </ul>
    </div>
  );
}

export default Messages;
