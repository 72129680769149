import React from "react";
import { useLocation, useHistory } from "react-router-dom";

import languages, { languagesRegex } from "config/languages";
import { useTranslation } from "hooks";

export default function LanguageSelect({ filter = () => true }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const handleLanguageChange = ({ target: { value } }) => {
    const pathname = location.pathname.replace(languagesRegex, "/");
    history.replace({
      ...location,
      pathname: value !== "en" ? `/${value}${pathname}` : pathname,
    });
  };

  return (
    <div className="LanguageToggle ty-c1">
      <select
        title={t("Select language")}
        aria-label={t("Select language")}
        className="LanguageToggle-select ty-c1"
        value={i18n.language}
        onChange={handleLanguageChange}
        onBlur={handleLanguageChange}
      >
        {languages.filter(filter).map(({ name, locale }) => (
          <option key={locale} value={locale}>
            {t(name)}
          </option>
        ))}
      </select>
    </div>
  );
}
