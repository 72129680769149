import { twelveToTwentyFourTime, twentyFourToTwelveTime } from "utils/utils";
import omit from "lodash/fp/omit";
import update from "lodash/fp/update";
import get from "lodash/fp/get";
import pick from "lodash/fp/pick";
import merge from "lodash/fp/merge";
import set from "lodash/fp/set";
import compose from "lodash/fp/compose";

import {
  buildUniqueAmenity,
  prepareChangedAmenities,
} from "utils/amenitiesHelpers";

export const updateComments =
  ({ reservationId, bookingId, productId, recipients, comments }) =>
  (mapper) => ({
    reservationId,
    bookingId,
    recipients,
    comments: comments.map((comment) =>
      comment.productId === productId ? mapper(comment) : comment
    ),
  });

const getDefaultValues = () => ({
  airRequest: {
    airportName: "",
    airlineName: "",
    airlineFlightNumber: "",
    flightArrivalTime: "",
    cityOfOrigin: "",
    numberOfPassengers: "",
    numberOfLuggage: "",
    countryCode: "gb",
    contactPhoneNumber: "",
  },
  vehicleRequest: {
    optionLabel: "",
  },
});

export const getTransferOptions = ({ airportTransfer, airportName }) =>
  (
    get(
      ["transferOptions"],
      airportTransfer.find(({ airportTitle }) => airportTitle === airportName)
    ) || []
  )
    .map(get(["optionLabel"]))
    .filter(Boolean);

export const getTransferOption = ({
  airportTransfer,
  airportName,
  vehicleOptionLabel,
}) =>
  (
    get(
      ["transferOptions"],
      airportTransfer.find(({ airportTitle }) => airportTitle === airportName)
    ) || []
  ).find(({ optionLabel }) => optionLabel === vehicleOptionLabel);

export const transportationPreppers = {
  receive: ({
    initialValues = {},
    defaultContactNumber = "",
    airports = [],
    airportTransfer = [],
  } = {}) =>
    compose(
      update(["airRequest", "airportName"], (n) =>
        !n && airports.length === 1 ? get(["0", "airportTitle"], airports) : n
      ),
      update(["airRequest", "contactPhoneNumber"], (n) =>
        n && !/^\++/i.test(n) ? `+${n}` : defaultContactNumber
      ),
      update(["airRequest", "flightArrivalTime"], twentyFourToTwelveTime),
      update(["vehicleRequest"], (n = {}) => {
        const [transferOption] =
          !n && airports.length === 1
            ? getTransferOptions({
                airportTransfer,
                airportName: get(["0", "airportTitle"], airports),
              })
            : [n];
        return transferOption;
      })
    )(merge(getDefaultValues(), initialValues)),
  send: ({ formValues, airportTransfer }) =>
    update(
      ["vehicleRequest"],
      (vehicleRequest) =>
        omit(
          ["vehicleRequest", "optionLabel"],
          omit(
            ["vehicleRequest", "maxOccupancy"],
            airportTransfer
              .find(
                ({ airportTitle }) =>
                  airportTitle ===
                  get(["airRequest", "airportName"], formValues)
              )
              ?.transferOptions.find(
                (transferOption) =>
                  transferOption.optionLabel === vehicleRequest?.optionLabel
              )
          )
        ),

      update(
        ["airRequest", "flightArrivalTime"],
        twelveToTwentyFourTime,
        omit(["confirmation"], formValues)
      )
    ),
};

export const updateTransportation = {
  remove: (comment) => omit(["transportationRequests"], comment),
  update:
    ({ formValues, airportTransfer }) =>
    (comment) => ({
      ...comment,
      transportationRequests: {
        ...comment.transportationRequests,
        ...transportationPreppers.send({
          formValues,
          airportTransfer,
        }),
      },
    }),
};

export const updateWelcomeAmenities = {
  receive: ({ contentWelcomeAmenities, rawSelectedWelcomeAmenities }) =>
    contentWelcomeAmenities.reduce((acc, contentAmenity) => {
      const key = `${buildUniqueAmenity("")(contentAmenity)}`;

      return {
        ...acc,
        [key]: get(key, rawSelectedWelcomeAmenities) || false,
      };
    }, {}),
  remove: omit(["welcomeAmenities"]),
  update: (formValues) => (comment) =>
    merge(comment, { welcomeAmenities: prepareChangedAmenities(formValues) }),
  sanitise: ({ contentWelcomeAmenities, rawSelectedWelcomeAmenities }) =>
    contentWelcomeAmenities.filter((contentAmenity) =>
      get(buildUniqueAmenity("")(contentAmenity), rawSelectedWelcomeAmenities)
    ),
};

const createPreppers = (fieldName) => ({
  receive: pick([fieldName]),
  update: compose(set([fieldName]), get([fieldName])),
});

export const additionalRequestsPreppers = createPreppers("additionalRequests");
export const mostImportantPreppers = createPreppers("mostImportant");
export const tripPurposePreppers = createPreppers("tripPurpose");

export const roomGuestsPreppers = {
  receive: ({ comment, booking }) => {
    const [_ignoredFirstGuest, ...others] = comment?.roomGuests || [];
    const bookingHolder = {
      name: [
        get(["contactDetails", "name", "firstName"], booking),
        get(["contactDetails", "name", "surname"], booking),
      ]
        .filter(Boolean)
        .join(" "),
      email: get(["contactDetails", "email"], booking),
    };
    return { bookingHolder, others };
  },
  update: (formValues) =>
    update(["roomGuests"], (roomGuests) => {
      const [bookingHolder, ...originalOthers] = roomGuests;

      return [
        bookingHolder,
        ...originalOthers.map((guest, index) =>
          merge(guest, formValues.roomGuests[index])
        ),
      ];
    }),
};

export const travelDatesPreppers = {
  receive: pick(["startDate", "endDate"]),
  update: () => {},
};
