import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useDispatch } from "react-redux";

import env from "config/env";
import { SignInModal } from "Authentication";
import { useTranslation } from "hooks";
import {
  BookingFlowLoadingIndicator,
  DictionaryKeyContent,
  ErrorMessage,
} from "BookingFlow/components";
import { TECHNICAL_ERROR_MESSAGE } from "fixtures/constants";
import BookingFlowLayout from "BookingFlow/BookingFlowLayout";
import * as bookingFlowRoutes from "BookingFlow/bookingFlowRoutes";
import RussiaConfirmationModal from "components/RussiaConfirmationModal";
import MappingLayerContext from "../mappingLayerContext";
import { useConfirmYourStay } from "./hooks";
import Upsells from "./Upsells";
import { Form, Tab, ReservationSummary, AlipayModal } from "./components";
import { PAYMENT_METHODS, SECTIONS } from "./hooks/constants";
import { confirmYourStayReady } from "../../store/bookingFlow";

const { STATIC_SITE_URL, PREFERRED_PARTNER_DOMAIN } = env;

function ConfirmYourStayView() {
  const { t, locale } = useTranslation();
  const dispatch = useDispatch();

  const {
    alipayInfo,
    alipaySubmitHandler,
    allowAlipay,
    arePaymentOptionsLoading,
    bookingInProgress,
    bookingType,
    cancelBy,
    closeRussianCitizenModal,
    confirmYourStayData,
    employeeMode,
    hasExplicitOptIn,
    isAgentBookingSelected,
    isAlipayModalOpen,
    isBookingForMyselfSelected,
    isBookingForSomeoneElseSelected,
    isPreferredPartners,
    isUserLoggedIn,
    onSubmit,
    ppReturnUrl = PREFERRED_PARTNER_DOMAIN,
    queryParams,
    russianCitizenHandler,
    setCurrencyCode,
    setIsAlipayModalOpen,
    showLoadingIndicator,
    showSignInBlock,
    alipayError,
    propertyContent,
  } = useConfirmYourStay();

  const {
    bookingError,
    contactUs,
    labels: { confirmYourStay } = {},
    disclaimer,
    tollFreeNumbers,
    upsells,
    bookingTypesAllowed,
  } = confirmYourStayData;

  const haveAllTheStuffFromMappingFunction =
    upsells && bookingTypesAllowed && propertyContent;

  const hasBookingError = Boolean(bookingError);

  useEffect(() => {
    if (hasBookingError) {
      window.scrollTo(0, 0);
    }
  }, [hasBookingError]);

  const forceFormReRenderKey = isUserLoggedIn + bookingType;

  const [paymentMethod, setPaymentMethod] = useState(
    allowAlipay ? "" : PAYMENT_METHODS.CREDITCARD
  );

  const displayCancelByMessage = allowAlipay
    ? cancelBy && paymentMethod === PAYMENT_METHODS.CREDITCARD
    : !arePaymentOptionsLoading && cancelBy;

  const [readyDispatched, setReadyDispatched] = useState(false);

  useEffect(() => {
    if (
      !readyDispatched &&
      !showLoadingIndicator &&
      haveAllTheStuffFromMappingFunction &&
      confirmYourStayData?.totalRoomRate?.amount?.withoutDecimal
    ) {
      setReadyDispatched(true);
      dispatch(confirmYourStayReady());
    }
  }, [
    confirmYourStayData?.totalRoomRate?.amount?.withoutDecimal,
    dispatch,
    haveAllTheStuffFromMappingFunction,
    showLoadingIndicator,
    readyDispatched,
  ]);

  if (showLoadingIndicator || !haveAllTheStuffFromMappingFunction) {
    return <BookingFlowLoadingIndicator />;
  }

  return (
    <BookingFlowLayout
      pageTitle={bookingFlowRoutes.confirmYourStay.getPageTitle({
        isPreferredPartners,
      })}
      htmlBodyClassName={bookingFlowRoutes.confirmYourStay.htmlBodyClassName}
      isPreferredPartners={isPreferredPartners}
      ppReturnUrl={ppReturnUrl}
      contactUs={contactUs}
      employeeMode={employeeMode}
    >
      {isAlipayModalOpen && (
        <AlipayModal
          close={() => setIsAlipayModalOpen(false)}
          alipayInfo={alipayInfo}
          alipaySubmitHandler={alipaySubmitHandler}
        />
      )}

      <div className="main-inner">
        <MappingLayerContext.Provider value={confirmYourStayData}>
          {!alipayError && bookingError && (
            <ErrorMessage
              contactReservations={bookingError.contactReservations}
              descriptionDictKey={bookingError.descriptionDictKey}
              field={bookingError.field}
              labelDictKey={bookingError.labelDictKey}
              messageDictKey={bookingError.messageDictKey}
              propertyInfo={bookingError.propertyInfo}
            />
          )}

          {(alipayError || (!bookingError && confirmYourStayData.error)) && (
            <ErrorMessage
              messageDictKey={alipayError || TECHNICAL_ERROR_MESSAGE}
              labelDictKey="Error"
            />
          )}

          <div>
            <div className="container view-confirm-stay">
              <div className="row">
                <div className="col-sm-9">
                  <div className="visible-xs">
                    <ReservationSummary setCurrencyCode={setCurrencyCode} />
                  </div>

                  <div className="confirm-stay-form">
                    <div className="heading-row">
                      <h2 className="page-heading hidden-xs">
                        <DictionaryKeyContent dict={confirmYourStay} />
                      </h2>
                    </div>

                    {showSignInBlock && (
                      <div className="otp-sign-in-launch-block">
                        <p className="ty-c1">{t("Sign in to book faster")}</p>
                        <SignInModal
                          id="signInBlock"
                          buttonLabel="Use email or phone"
                          type="CTA"
                          afterSignInRedirectTo={bookingFlowRoutes.confirmYourStay.to(
                            { locale }
                          )}
                          fromAnonymous={!isUserLoggedIn}
                        />
                      </div>
                    )}

                    {upsells.length > 0 && <Upsells upsells={upsells} />}

                    {bookingTypesAllowed.length > 1 && (
                      <div
                        id="booking-type-tab-container"
                        className="btn-group booking-form-context"
                        role="tablist"
                      >
                        <Tab
                          id="booking-for-myself-button"
                          to={{
                            path: bookingFlowRoutes.confirmYourStay.to({
                              locale,
                            }),
                            search: queryString.stringify({
                              ...queryParams,
                              bookingType: SECTIONS.MYSELF,
                            }),
                          }}
                          isSelected={isBookingForMyselfSelected}
                        >
                          {t("Booking for myself")}
                        </Tab>
                        <Tab
                          id="booking-for-someone-else-button"
                          to={{
                            path: bookingFlowRoutes.confirmYourStay.to({
                              locale,
                            }),
                            search: queryString.stringify({
                              ...queryParams,
                              bookingType: SECTIONS.SOMEONE_ELSE,
                            }),
                          }}
                          isSelected={isBookingForSomeoneElseSelected}
                        >
                          {t("Booking for someone else")}
                        </Tab>
                        <Tab
                          id="booking-travel-agent-button"
                          to={{
                            path: bookingFlowRoutes.confirmYourStay.to({
                              locale,
                            }),
                            search: queryString.stringify({
                              ...queryParams,
                              bookingType: SECTIONS.AGENT,
                            }),
                          }}
                          isSelected={isAgentBookingSelected}
                        >
                          {t("Travel agent booking")}
                        </Tab>
                      </div>
                    )}

                    <Form
                      key={forceFormReRenderKey}
                      onSubmit={onSubmit}
                      bookingType={bookingType}
                      isAgentBookingSelected={isAgentBookingSelected}
                      isBookingForMyselfSelected={isBookingForMyselfSelected}
                      isBookingForSomeoneElseSelected={
                        isBookingForSomeoneElseSelected
                      }
                      allowAlipay={allowAlipay}
                      arePaymentOptionsLoading={arePaymentOptionsLoading}
                      isPreferredPartners={isPreferredPartners}
                      employeeMode={employeeMode}
                      hasExplicitOptIn={hasExplicitOptIn}
                      cancelBy={cancelBy}
                      displayCancelByMessage={displayCancelByMessage}
                      setPaymentMethod={setPaymentMethod}
                    />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="hidden-xs">
                    <ReservationSummary
                      bookingInProgress={bookingInProgress}
                      setCurrencyCode={setCurrencyCode}
                      displayCancelByMessage={displayCancelByMessage}
                    />
                  </div>
                  <div
                    className="disclaimer"
                    dangerouslySetInnerHTML={{ __html: disclaimer }}
                  />
                  <span>
                    {/* eslint-disable jsx-a11y/control-has-associated-label */}
                    {tollFreeNumbers && (
                      <a
                        href={STATIC_SITE_URL + tollFreeNumbers.url}
                        dangerouslySetInnerHTML={{
                          __html: t(
                            tollFreeNumbers.textDictKey.key,
                            tollFreeNumbers.textDictKey.params
                          ),
                        }}
                      />
                    )}
                    <br />
                    {contactUs && (
                      <a
                        href={
                          (isPreferredPartners
                            ? PREFERRED_PARTNER_DOMAIN
                            : STATIC_SITE_URL) + contactUs.url
                        }
                        dangerouslySetInnerHTML={{
                          __html: t(
                            contactUs.textDictKey.key,
                            contactUs.textDictKey.params
                          ),
                        }}
                      />
                    )}
                    <br />
                  </span>
                </div>
              </div>
            </div>
            {Boolean(russianCitizenHandler) && (
              <RussiaConfirmationModal
                onSubmit={russianCitizenHandler}
                onClose={closeRussianCitizenModal}
              />
            )}
          </div>
        </MappingLayerContext.Provider>
      </div>
    </BookingFlowLayout>
  );
}

export default ConfirmYourStayView;
