import { ofType } from "redux-observable";
import {
  catchError,
  switchMap,
  takeUntil,
  mergeMap,
  withLatestFrom,
} from "rxjs/operators";
import { of } from "rxjs";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { validateEmployeeDetails$ } from "api/workday";
import {
  activateEmployeeProfile,
  activateEmployeeProfileFailed,
  activateEmployeeProfileFulfilled,
  activateEmployeeProfileCancel,
  fetchProfile,
  updateUserProfileFulfilled,
} from "store/profile/profile.slice";
import { fetchEmployeeProfileFulfilled } from "store/employeeProfile";
import { updateUserProfile$ } from "api/tretail/profile";
import { TECHNICAL_ERROR_MESSAGE } from "fixtures/constants";
import catchInternalServerError from "store/catchInternalServerError";

export default function activateEmployeeProfileEpic(action$, state$) {
  return action$.pipe(
    ofType(activateEmployeeProfile.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { locale, ...formValues },
        },
        { profile: { data = {} } = {} },
      ]) => {
        return ajaxWithHealthCheck$({
          locale,
        }).pipe(
          switchMap(() => {
            return validateEmployeeDetails$(formValues).pipe(
              mergeMap((employeeResponse = {}) => {
                const updateProfilePayload = {
                  body: {
                    uniqueId: data.uniqueId,
                    orsNameId: data.orsNameId,
                    salesForceId: data.salesForceId,
                    extension: {
                      employeeId: formValues.workdayId,
                    },
                  },
                  locale,
                };
                return updateUserProfile$(updateProfilePayload).pipe(
                  mergeMap(() => [
                    fetchProfile({ locale }),
                    updateUserProfileFulfilled(),
                    fetchEmployeeProfileFulfilled(employeeResponse),
                    activateEmployeeProfileFulfilled(),
                  ])
                );
              }),

              catchInternalServerError(),

              catchError(({ response = {} }) =>
                of(
                  activateEmployeeProfileFailed(
                    response.apiErrors || response.supplierErrors
                      ? {
                          apiErrors: response.apiErrors || [],
                          supplierErrors: response.supplierErrors || [],
                        }
                      : {
                          errors: [
                            {
                              errorCode:
                                response.error || TECHNICAL_ERROR_MESSAGE,
                            },
                          ],
                        }
                  )
                )
              ),
              takeUntil(
                action$.pipe(ofType(activateEmployeeProfileCancel.type))
              )
            );
          })
        );
      }
    )
  );
}
