import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation, useView } from "hooks";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { selectLoadingRedirect } from "store/appStatus";
import {
  selectIsUserLoggedIn,
  selectIsUserPersistent,
  selectLoginEmail,
  selectLoginPhoneNumber,
} from "store/profile";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import * as authenticationRoutes from "Authentication/authenticationRoutes";
import { profileRoutes } from "Profile";
import env from "config/env";
import { LOGIN_TYPES } from "api/tretail/authentication/generateOTP";
import OtpForm from "../components/OtpForm";

const { YACHTS_AUTH_URL, YACHTS_REDIRECT_PARAMETER } = env;

export default function SignInView({
  afterSignInRedirectTo: initialAfterSignInRedirectTo,
  employeeMode = false,
}) {
  useView("SignInView");
  const queryParams = queryString.parse(useLocation()?.search || "");
  const isYachtsMode = queryParams?.yachts === "true";
  const isRequestDeletion = queryParams?.requestDeletion === "true";
  const history = useHistory();
  const { t, locale } = useTranslation();
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const isUserPersistent = useSelector(selectIsUserPersistent);
  const loginEmail = useSelector(selectLoginEmail);
  const loginPhoneNumber = useSelector(selectLoginPhoneNumber);

  const isLoadingRedirect = useSelector(selectLoadingRedirect);

  let afterSignInRedirectTo = initialAfterSignInRedirectTo;
  if (isYachtsMode && YACHTS_AUTH_URL) {
    afterSignInRedirectTo = `${YACHTS_AUTH_URL}${
      YACHTS_REDIRECT_PARAMETER && queryParams[YACHTS_REDIRECT_PARAMETER]
        ? `?${YACHTS_REDIRECT_PARAMETER}=${encodeURIComponent(
            queryParams[YACHTS_REDIRECT_PARAMETER]
          )}`
        : ""
    }`;
  }

  useEffect(() => {
    if (isUserLoggedIn && !(isUserPersistent && isRequestDeletion)) {
      history.replace(profileRoutes.profilePath.to({ locale }));
    }
  }, [isUserLoggedIn, locale, history]);

  if (isLoadingRedirect) {
    return <BookingFlowLoadingIndicator />;
  }

  const introContent = isYachtsMode
    ? t("Access your Four Seasons Yachts account.")
    : isUserPersistent
    ? t("Please sign in and verify your identity.")
    : t(
        "View and edit your Four Seasons profile and customise your preferences."
      );
  let registerLinkTo;
  if (!isYachtsMode) {
    registerLinkTo = employeeMode
      ? authenticationRoutes.employeeRegister.to({ locale })
      : afterSignInRedirectTo
      ? {
          pathname: authenticationRoutes.register.to({
            locale,
          }),
          search: queryString.stringify(queryParams),
          state: { afterSignInRedirectTo },
        }
      : authenticationRoutes.register.to({
          locale,
          queryParams,
        });
  }

  return (
    <div className="view view--sign-in">
      <div className="fs-otp-sign-in">
        <OtpForm
          signInTitle={
            isUserPersistent ? t("Sign In To Continue") : t("Sign In")
          }
          introContent={introContent}
          afterSignInRedirectTo={afterSignInRedirectTo}
          employeeMode={employeeMode}
          isYachtsMode={isYachtsMode}
          isUserPersistent={isUserPersistent}
          registerLinkTo={registerLinkTo}
          requestOtpInitialValues={{
            sendCodeTo: {
              [LOGIN_TYPES.PHONE_NUMBER]: loginPhoneNumber || "",
              [LOGIN_TYPES.EMAIL_ADDRESS]: loginEmail || "",
            },
          }}
        />
      </div>
    </div>
  );
}
