import { parse, format as formatDate, isSameYear, isSameMonth } from "date-fns";
import * as dateFnLocales from "date-fns/locale";

import { getDateFnsLocale } from "config/languages";

function getFormattedDateRange(
  dateFromString,
  dateToString,
  locale = "en",
  format = "long"
) {
  const dateFrom = parse(dateFromString, "yyyy-MM-dd", new Date());
  const dateTo = parse(dateToString, "yyyy-MM-dd", new Date());
  const sameYear = isSameYear(dateFrom, dateTo);
  const sameMonth = isSameMonth(dateFrom, dateTo);
  let dateFromFormat = "MMMM d, yyyy";
  let dateToFormat = "MMMM d, yyyy";

  const formatDateInLocale = (date, formatString) => {
    return formatDate(date, formatString, {
      locale: dateFnLocales[getDateFnsLocale(locale)],
    });
  };

  switch (locale) {
    case "jp":
    case "zh_hant":
    case "zh":
      dateFromFormat = "yyyy'年'MM'月'dd'日'";
      dateToFormat = sameYear ? "MM'月'dd'日'" : "yyyy'年'MM'月'dd'日'";
      break;

    case "kr":
      dateFromFormat = "yyyy'년'MM'월'dd'일'";
      dateToFormat = "dd'일'";
      if (!sameYear) {
        dateToFormat = "yyyy'년'MM'월'dd'일'";
      } else if (!sameMonth) {
        dateToFormat = "MM'월'dd'일'";
      }
      break;

    case "fr":
    case "tr":
      switch (format) {
        case "short":
          dateFromFormat = sameYear ? "dd MMM" : "dd MMM yyyy";
          dateToFormat = "dd MMM yyyy";
          break;

        case "long":
        default:
          dateFromFormat = sameYear ? "d MMMM" : "d MMMM yyyy";
          dateToFormat = "d MMMM yyyy";
          break;
      }
      break;

    case "de":
      switch (format) {
        case "short":
          dateFromFormat = sameYear ? "dd'.' MMM'.'" : "dd'.' MMM'.' yyyy";
          dateToFormat = "dd'.' MMM'.' yyyy";
          break;

        case "long":
        default:
          dateFromFormat = sameYear ? "d'.' MMMM" : "d'.' MMMM yyyy";
          dateToFormat = "d'.' MMMM yyyy";
          break;
      }
      break;

    default:
      switch (format) {
        case "short":
          dateFromFormat = sameYear ? "MMM dd" : "MMM dd yyyy";
          dateToFormat = "MMM dd yyyy";
          break;

        case "long":
        default:
          dateFromFormat = sameYear ? "MMMM d" : "MMMM d, yyyy";
          dateToFormat = "MMMM d, yyyy";
          break;
      }
  }

  return `${formatDateInLocale(
    dateFrom,
    dateFromFormat
  )} - ${formatDateInLocale(dateTo, dateToFormat)}`;
}

export default getFormattedDateRange;
