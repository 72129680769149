import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import concat from "lodash/fp/concat";
import flow from "lodash/fp/flow";
import filter from "lodash/fp/filter";
import flatMap from "lodash/fp/flatMap";
import getOr from "lodash/fp/getOr";
import groupBy from "lodash/fp/groupBy";
import map from "lodash/fp/map";
import negate from "lodash/fp/negate";
import reduce from "lodash/fp/reduce";
import get from "lodash/fp/get";
import set from "lodash/fp/set";
import toNumber from "lodash/fp/toNumber";
import uniqBy from "lodash/fp/uniqBy";
import split from "lodash/fp/split";
import first from "lodash/fp/first";
import last from "lodash/fp/last";

import { MediaContext } from "contexts/MediaContext";
import { useTranslation } from "hooks";
import FieldSelector, {
  INPUT_TYPES,
} from "Profile/components/TransportationAmenities/FieldSelector";
import { Button } from "Profile/components";
import formatPrice from "utils/formatPrice";
import CheckmarkIcon from "./CheckmarkIcon";
import SupportingInfo from "./SupportingInfo";
import env from "../../../config/env";

const { IMAGE_BASE_URL } = env;

export default function AmenityItem({
  name: productName,
  description,
  portraitImage,
  price,
  cancellationPolicy,
  fieldSets,
  requestedAmenity,
  addRequestedAmenities,
}) {
  const { t } = useTranslation();

  const initialValues = flow(
    flatMap(get("fields")),
    filter(({ type }) => type !== "HIDDEN"),
    // remove any duplicate field names, ie. "details.fields[1].Request_Date" & "details.fields[9].Request_Date"
    uniqBy(flow(get("name"), split("."), last)),
    reduce((acc, { name, type, options = [] }) => {
      const hasValue = get(name)(requestedAmenity);
      if (name.endsWith("Request_Date") && !hasValue) {
        return set(
          name,
          flow(filter(negate(get("disabled"))), first, get("value"))(options)
        )(acc);
      }

      return set(
        name,
        getOr(
          type === INPUT_TYPES.NUMBER || type === INPUT_TYPES.QUANTITY
            ? "0"
            : "",
          name
        )(requestedAmenity)
      )(acc);
    }, {})
  )(fieldSets);

  const media = useContext(MediaContext);

  function getInputType(type) {
    if (type === INPUT_TYPES.RADIO && media.isLessThan.md) {
      return INPUT_TYPES.DROPDOWN;
    }
    return type;
  }

  const { register, control, handleSubmit, watch } = useForm({
    defaultValues: initialValues,
  });

  const watchedQuantity = parseInt(
    watch("details.fields[0].Quantity") || "0",
    10
  );

  const isUpdatingForm = Boolean(requestedAmenity);

  const initialQuantity = flow(
    getOr(0, "numQuantity"),
    toNumber
  )(requestedAmenity);

  const [hasSubmitted, setHasSubmitted] = useState(initialQuantity > 0);

  const onSubmit = (formValues) => {
    flow(
      flatMap(get("fields")),
      map((field) => {
        return {
          ...field,
          value: getOr(field.value, field.name)(formValues),
        };
      }),
      reduce((acc, field) => {
        return [
          ...acc,
          ...flow(
            getOr([], "additionalNames"),
            flatMap((name) => ({ ...field, name })),
            concat(field)
          )(field),
        ];
      }, []),
      reduce(
        (acc, { name, value }) => {
          return set(name, value)(acc);
        },
        {
          price,
          description,
        }
      ),
      addRequestedAmenities,
      () => setHasSubmitted(true)
    )(fieldSets);
  };

  const { quantityFields, additionalFields } = flow(
    filter(negate(get("hidden"))),
    flatMap(getOr([], "fields")),
    groupBy(({ name }) =>
      name.endsWith("Quantity") ? "quantityFields" : "additionalFields"
    )
  )(fieldSets);

  return (
    <div data-cy="arrival-amenities__item" className="arrival-amenities__item">
      <div className="arrival-amenities__item__image-container">
        {portraitImage && (
          <img
            className="arrival-amenities__item__image"
            src={IMAGE_BASE_URL + portraitImage}
            alt={productName}
          />
        )}
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className="arrival-amenities__item__content"
      >
        <div className="arrival-amenities__item__description">
          <p className="ty-c1">{productName}</p>

          {cancellationPolicy?.text && (
            <SupportingInfo icon={cancellationPolicy?.icon}>
              {cancellationPolicy?.text}
            </SupportingInfo>
          )}

          <p className="ty-b2">{description}</p>
        </div>

        <div className="arrival-amenities__item__price-and-quantity-toggles">
          <div className="arrival-amenities__item__price">
            <div className="ty-c1">
              {formatPrice({
                value: price.amount,
                currency: price.currencyCode,
              })}
            </div>
          </div>

          {!hasSubmitted &&
            quantityFields.map((field) => (
              <FieldSelector
                key={field.name}
                {...field}
                register={register}
                control={control}
              />
            ))}

          {!hasSubmitted &&
            watchedQuantity > 0 &&
            additionalFields.length === 0 && (
              <div className="arrival-amenities__item__form-actions">
                <Button type="submit">
                  {isUpdatingForm ? t("Update") : t("Add")}
                </Button>
              </div>
            )}

          {hasSubmitted && (
            <>
              {initialQuantity > 0 && (
                <div className="arrival-amenities__item__confirmed-quantity ty-b2">
                  <CheckmarkIcon />
                  {initialQuantity} {t("Added")}
                </div>
              )}

              <div className="arrival-amenities__item__form-actions">
                <Button
                  onClick={() => {
                    setHasSubmitted(false);
                  }}
                  kind="secondary"
                >
                  {t("Edit")}
                </Button>
              </div>
            </>
          )}
        </div>

        {!hasSubmitted &&
          watchedQuantity > 0 &&
          additionalFields.length > 0 && (
            <div className="arrival-amenities__item__additional-fields">
              {additionalFields.map((fieldProps) => {
                const type = getInputType(fieldProps.type);
                const labelProps =
                  type !== INPUT_TYPES.TEXTAREA
                    ? {
                        label: fieldProps.label,
                        labelFn: (label, value) => (
                          <>
                            <span className="ty-c3">{`${label}: `}</span>
                            <span className="ty-c4">{value}</span>
                          </>
                        ),
                      }
                    : {};
                const dropdownProps =
                  type === INPUT_TYPES.DROPDOWN
                    ? {
                        includeBlank: false,
                        value: get(fieldProps.name)(initialValues),
                      }
                    : {};
                return (
                  <div key={fieldProps.name}>
                    <FieldSelector
                      {...fieldProps}
                      {...labelProps}
                      {...dropdownProps}
                      type={type}
                      register={register}
                      control={control}
                    />
                  </div>
                );
              })}
            </div>
          )}

        {!hasSubmitted && watchedQuantity === 0 && isUpdatingForm && (
          <div className="arrival-amenities__item__form-actions">
            <Button type="submit">{t("Remove")}</Button>
          </div>
        )}

        {!hasSubmitted &&
          watchedQuantity > 0 &&
          additionalFields.length > 0 && (
            <div className="arrival-amenities__item__form-actions">
              <Button type="submit">
                {isUpdatingForm ? t("Update") : t("Add")}
              </Button>
            </div>
          )}
      </form>
    </div>
  );
}
