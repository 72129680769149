import React, { useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { hasSearchQueryParams } from "BookingFlow/utils";
import {
  BookingFlowLoadingIndicator,
  WebChatButton,
} from "BookingFlow/components";
import { useTranslation, useWebChat } from "hooks";
import * as authenticationRoutes from "Authentication/authenticationRoutes";
import { isMobileApp } from "utils";
import { selectWebChatParams, reset as resetWebChat } from "store/webChat";
import { selectBookingInProgressSearchParams } from "store/bookings/bookings.selectors";
import * as bookingFlowRoutes from "./bookingFlowRoutes";
import { PlanYourStayView, CheckAvailabilityWidget } from "./PlanYourStay";
import { ChooseYourRoomView } from "./ChooseYourRoom";
import { ConfirmYourStayView } from "./ConfirmYourStay";
import { PersonaliseYourStayView } from "./PersonaliseYourStay";
import useFetchRequiredData from "./useFetchRequiredData";
import ErrorBoundary from "./components/ErrorBoundary";

export default function BookingFlow() {
  const { locale } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    hasBookingInProgress,
    hasCompletedBooking,
    showLoadingIndicator,
    employeeMode,
    isUserLoggedIn,
  } = useFetchRequiredData();

  const webChatParams = useSelector(selectWebChatParams) || {};

  const bookingInProgressSearchParams = useSelector(
    selectBookingInProgressSearchParams
  );

  const { isInitialised: webChatIsInitialised, openWebChat } = useWebChat({
    hotelCode: webChatParams?.hotelCode,
    searchParams: bookingInProgressSearchParams,
    enabled: false,
    ...webChatParams,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.key]);

  useEffect(() => {
    return () => {
      dispatch(resetWebChat());
    };
  }, [dispatch]);

  return (
    <ErrorBoundary
      fallbackUI={(hasReactError) => (
        <PlanYourStayView hasReactError={hasReactError} />
      )}
    >
      {!isMobileApp() && webChatIsInitialised && (
        <WebChatButton onClick={openWebChat} />
      )}

      {showLoadingIndicator && <BookingFlowLoadingIndicator />}

      {!showLoadingIndicator && (
        <Switch>
          <Route
            path={bookingFlowRoutes.personalizeYourStay.path}
            render={() => {
              if (!hasCompletedBooking) {
                return (
                  <Redirect
                    to={bookingFlowRoutes.confirmYourStay.to({
                      locale,
                    })}
                  />
                );
              }
              return <PersonaliseYourStayView />;
            }}
          />

          <Route
            path={bookingFlowRoutes.confirmYourStay.path}
            render={() => {
              if (employeeMode && !isUserLoggedIn) {
                return (
                  <Redirect
                    to={authenticationRoutes.employeeSignIn.to({ locale })}
                  />
                );
              }
              if (!hasBookingInProgress) {
                return (
                  <Redirect
                    to={bookingFlowRoutes.planYourStay.to({ locale })}
                  />
                );
              }
              if (hasCompletedBooking) {
                return (
                  <Redirect
                    to={bookingFlowRoutes.personalizeYourStay.to({
                      locale,
                    })}
                  />
                );
              }
              return <ConfirmYourStayView />;
            }}
          />

          <Route
            path={bookingFlowRoutes.chooseYourRoom.path}
            render={(routeProps) => {
              if (!hasSearchQueryParams(routeProps.location.search)) {
                return (
                  <Redirect
                    to={{
                      ...routeProps.location,
                      pathname: bookingFlowRoutes.planYourStay.to({
                        locale,
                      }),
                    }}
                  />
                );
              }
              return <ChooseYourRoomView />;
            }}
          />

          <Route
            path={bookingFlowRoutes.planYourStay.path}
            render={(routeProps) => {
              return (
                <PlanYourStayView
                  isChangingSearch={
                    routeProps?.location?.state?.isChangingSearch
                  }
                  webChatIsInitialised={webChatIsInitialised}
                  openWebChat={openWebChat}
                />
              );
            }}
          />

          <Route
            path={bookingFlowRoutes.embeddedCheckAvailabilityWidget.path}
            component={CheckAvailabilityWidget}
          />

          <Route
            render={(routeProps) => {
              return (
                <Redirect
                  to={{
                    ...routeProps.location,
                    pathname: bookingFlowRoutes.chooseYourRoom.to({
                      locale,
                    }),
                  }}
                />
              );
            }}
          />
        </Switch>
      )}
    </ErrorBoundary>
  );
}
