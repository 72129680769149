import React from "react";

export default function AncillaryCard({
  img,
  title,
  subtitle,
  imgDescription = `${subtitle} ${title}`,
  as: Component = "a",
  ...rest
}) {
  return (
    <Component className="ancillary-booking-card" {...rest}>
      <div className="ancillary-booking-card__text">
        <div className="ancillary-booking-card__text__title ty-c3">{title}</div>
        <div className="ancillary-booking-card__text__subtitle ty-c4">
          {subtitle}
        </div>
      </div>
      <div className="ancillary-booking-card__img-container">
        <img src={img} alt={imgDescription} />
      </div>
    </Component>
  );
}
