import React from "react";

import env from "config/env";
import Link from "../Link";

const { IMAGE_BASE_URL } = env;

export default function FeaturedAncillaryCard({
  title,
  image,
  description,
  cta,
}) {
  return (
    <div className="featured-ancillary-card">
      <img
        className="featured-ancillary-card__img"
        src={`${IMAGE_BASE_URL + image.src}`}
        alt={image.alt}
      />

      <div className="featured-ancillary-card__text">
        <h3 className="ty-c1 featured-ancillary-card__text__title">{title}</h3>
        <div className="ty-b3 featured-ancillary-card__text__description">
          {description}
        </div>
        <Link href={cta.href} kind="primary">
          {cta.label}
        </Link>
      </div>
    </div>
  );
}
