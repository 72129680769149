import { ajax } from "rxjs/ajax";
import creditCardType from "credit-card-type";

import env from "config/env";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;
const TRETAIL_API_BOOKINGS_URL = `${TRETAIL_API_BASE_URL}/bookings`;

const CARD_TYPE_MAPPINGS = {
  visa: "VISA",
  mastercard: "MASTERCARD",
  jcb: "JAPAN_CREDIT_BUREAU",
  "diners-club": "DINERS_CLUB",
  "american-express": "AMERICAN_EXPRESS",
  discovery: "DISCOVERY",
  discover: "DISCOVERY",
  "china-union-pay": "CHINA_UNION_PAY",
  unionpay: "CHINA_UNION_PAY",
  alipay: "ALIPAY",
};

export const addAllPayments$ = ({
  bookingId,
  amount,
  creditCard: { expiryDateMonth, expiryDateYear, ...creditCard },
  countryCode,
  isAlipay,
  locale,
}) => {
  const cardType = isAlipay
    ? "ALIPAY"
    : CARD_TYPE_MAPPINGS[(creditCardType(creditCard.number)[0] || {}).type] ||
      "";
  return ajax({
    ...basePostRequest({ locale }),
    url: `${TRETAIL_API_BOOKINGS_URL}/${bookingId}/payments`,
    body: {
      payments: [
        {
          amount,
          creditCard: {
            ...creditCard,
            expiryDate: expiryDateMonth + expiryDateYear,
            cardType,
            seriesCode: "000",
            address: {
              addressLines: ["Somewhere"],
              country: {
                code: countryCode,
              },
            },
          },
        },
      ],
    },
  });
};

export default addAllPayments$;
