import React, { useState } from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";
import { ErrorContactDetails } from "BookingFlow/components/MomentaryViews";

export default function BookingMessages({
  bookingMessages,
  showPropertyInfo = false,
}) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="booking-messages">
      {isOpen &&
        bookingMessages.map(({ title, body }, index) => (
          <div key={index}>
            <div
              className={classNames("booking-message", {
                "col-md-7": showPropertyInfo,
              })}
            >
              <h3>{title}</h3>
              <div dangerouslySetInnerHTML={{ __html: body }} />
            </div>

            {showPropertyInfo && (
              <>
                <ErrorContactDetails />

                <button type="button" onClick={() => setIsOpen(false)}>
                  {t("Close")}
                </button>
              </>
            )}
          </div>
        ))}
    </div>
  );
}
