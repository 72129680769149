import React from "react";

export default function ArrivalTransportationTerms({ title, children }) {
  return (
    <div className="arrival-transportation__terms">
      {title && (
        <h3 className="arrival-transportation__terms__header ty-c1">{title}</h3>
      )}
      <div
        dangerouslySetInnerHTML={{ __html: children }}
        className="arrival-transportation__terms__body ty-b2"
      />
    </div>
  );
}
