import get from "lodash/get";
import { add, format, isAfter, isBefore, parse } from "date-fns";

import { EMPLOYEE_SEARCH_ERRORS } from "store/searchResults/checkEmployeeSearchParams";
import isEmployeeRate from "./isEmployeeRate";

function checkBookingDatesSameHotel(
  sameHotelOverlappingDates,
  searchCheckIn,
  searchCheckOut,
  bookingCheckIn,
  bookingCheckOut
) {
  const overlappingDates = { ...sameHotelOverlappingDates };
  let stayDate = new Date(searchCheckIn.getTime());

  while (isAfter(searchCheckOut, stayDate)) {
    if (
      !isAfter(bookingCheckIn, stayDate) &&
      isBefore(stayDate, bookingCheckOut)
    ) {
      const dateKey = format(stayDate, "yyyyMMdd");
      if (overlappingDates[dateKey]) {
        overlappingDates[dateKey] += 1;
      } else {
        overlappingDates[dateKey] = 1;
      }
    }
    stayDate = add(stayDate, { days: 1 });
  }

  return overlappingDates;
}

function hasEmployeeSameDayBooking({ searchParams = {}, bookings = [] } = {}) {
  let foundBooking = false;
  let sameHotelOverlappingDates = {};

  if (
    !get(searchParams, ["dates", "checkIn"]) ||
    !get(searchParams, ["dates", "checkOut"]) ||
    !Array.isArray(bookings) ||
    !bookings.length
  ) {
    return false;
  }

  const hotelCode = searchParams.hotelCode || "";
  const searchCheckIn = parse(
    searchParams.dates?.checkIn,
    "yyyy-MM-dd",
    new Date()
  );
  const searchCheckOut = parse(
    searchParams.dates?.checkOut,
    "yyyy-MM-dd",
    new Date()
  );

  bookings.forEach((booking) => {
    if (
      !foundBooking &&
      !!booking.startDate &&
      !!booking.endDate &&
      !get(booking, ["hotelProducts", 0, "fsCachedExistingReservation"]) &&
      isEmployeeRate({
        promoCode: get(booking, [
          "hotelProducts",
          0,
          "rooms",
          "room",
          0,
          "ratePlanCode",
        ]),
      })
    ) {
      const bookingCheckIn = parse(booking.startDate, "yyyy-MM-dd", new Date());
      const bookingCheckOut = parse(booking.endDate, "yyyy-MM-dd", new Date());

      if (
        isBefore(searchCheckIn, bookingCheckOut) &&
        isAfter(searchCheckOut, bookingCheckIn)
      ) {
        if (hotelCode === get(booking, ["hotelProducts", 0, "hotelCode"])) {
          sameHotelOverlappingDates = checkBookingDatesSameHotel(
            sameHotelOverlappingDates,
            searchCheckIn,
            searchCheckOut,
            bookingCheckIn,
            bookingCheckOut
          );
        } else {
          foundBooking = true;
        }
      }
    }
  });

  if (foundBooking) {
    return EMPLOYEE_SEARCH_ERRORS.BOOKING_SAME_DATES;
  }

  if (
    Object.keys(sameHotelOverlappingDates).reduce((found, overlappingDate) => {
      if (sameHotelOverlappingDates[overlappingDate] > 1) {
        return true;
      }
      return found;
    }, false)
  ) {
    return EMPLOYEE_SEARCH_ERRORS.BOOKINGS_SAME_HOTEL;
  }

  return false;
}

export default hasEmployeeSameDayBooking;
