import {
  deleteUserPhone,
  deleteUserPhoneFulfilled,
} from "store/profile/profile.slice";
import { deletePhone } from "../profile.helpers";
import { updateProfileTemplate } from "./updateProfile";

export default updateProfileTemplate({
  actionType: deleteUserPhone.type,
  fulfillAction: deleteUserPhoneFulfilled,
  updateFn: ({ phones = [] } = {}, newValue) => ({
    phones: deletePhone(phones, newValue),
  }),
});
