/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import getMergedPropertyCodes from "store/getMergedPropertyCodes";
import {
  setRequestStateRequesting,
  setRequestStateFulfilled,
  setRequestStateFailed,
  setRequestStateCancelled,
} from "store/apiRequestStates/setRequestState";

const initialState = {
  data: {},
  error: {},
  requestStates: {},
};

const taxesSlice = createSlice({
  name: "taxes",
  initialState,
  reducers: {
    fetchTaxesContent(state, { payload }) {
      const propertyCodes = getMergedPropertyCodes(payload);
      propertyCodes.forEach((propertyCode) => {
        delete state.error[propertyCode];
        state.requestStates[propertyCode] = setRequestStateRequesting(
          state.requestStates[propertyCode]
        );
      });
    },

    fetchTaxesContentFulfilled(state, { payload: { propertyCode, taxes } }) {
      state.data[propertyCode] = taxes;
      delete state.error[propertyCode];
      state.requestStates[propertyCode] = setRequestStateFulfilled(
        state.requestStates[propertyCode]
      );
    },

    fetchTaxesContentFailed(state, { payload }) {
      const { propertyCode, error } = payload;
      state.error[propertyCode] = error;
      state.requestStates[propertyCode] = setRequestStateFailed(
        state.requestStates[propertyCode]
      );
    },

    fetchTaxesContentCancel(state, { payload }) {
      const propertyCodes = getMergedPropertyCodes(payload);
      propertyCodes.forEach((code) => {
        state.requestStates[code] = setRequestStateCancelled(
          state.requestStates[code]
        );
      });
    },

    taxesContentReset() {
      return initialState;
    },
  },
});

export const {
  fetchTaxesContent,
  fetchTaxesContentCancel,
  fetchTaxesContentFailed,
  fetchTaxesContentFulfilled,
  taxesContentReset,
} = taxesSlice.actions;

export default taxesSlice.reducer;
