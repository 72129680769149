import { useState, useEffect, useRef } from "react";
import {
  add,
  areIntervalsOverlapping,
  differenceInDays,
  format,
  isAfter,
} from "date-fns";
import { isSameDay } from "date-fns/fp";
import { useDatepicker, START_DATE, END_DATE } from "@datepicker-react/hooks";

import { useTranslation } from "hooks";
import {
  getClientTimezoneAbbreviation,
  formatToStandardDate,
  formatToTwelveHoursTime,
} from "utils/utils";
import {
  formatDate,
  getCorrectCheckOutDate,
  dateFormat,
  parseDate,
  formatDateWithLocale,
} from "../DateRangePicker/calendarHelpers";

const today = new Date();

const section = {
  START_DATE_INPUT: "start:date:input",
  END_DATE_INPUT: "end:date:input",
  PREV_MONTH: "prev:month",
  NEXT_MONTH: "next:month",
  CALENDAR: "calendar",
};

export default function useCalendar({
  areInputsFocusable = true,
  availabilityEnabled = true,
  availabilityLastUpdated,
  checkIn,
  checkInEnd = add(today, { years: 1 }),
  checkInStart = today,
  checkOut,
  checkOutEnd = add(today, { years: 1, days: 1 }),
  hasCheckInError,
  hasCheckOutError,
  hasUserSelectedCheckIn,
  includeDaysFromOtherMonths = true,
  locale,
  maxNumberOfNights = 28,
  minNumberOfNights = 1,
  nextControlOutsideWidget,
  numberOfMonths = 1,
  restrictedDates = [],
  setCheckIn,
  setCheckOut,
  setHasUserSelectedCheckIn = () => true,
  unavailableDates = [],
  weekdayLabelFormatter = formatDateWithLocale("eee", locale),
  disableEffectfullyUpdatingCheckInDate = false,
  initialFocus,
  hideCalendarOnEndDateSelection = true,
}) {
  const { t } = useTranslation();
  const [focusedInput, setFocusedInput] = useState(START_DATE);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [focusedSection, setFocusedSection] = useState(section.NEXT_MONTH);

  const noAvailabilityDates = !availabilityEnabled ? [] : unavailableDates;

  const nextMonthRef = useRef();
  const prevMonthRef = useRef();
  const endDateInputRef = useRef();
  const startDateInputRef = useRef();
  const componentRef = useRef();

  const validInterval = {
    start: checkInStart,
    end: focusedInput === START_DATE ? checkInEnd : checkOutEnd,
  };

  const startDate = parseDate(checkIn);
  const endDate = parseDate(checkOut);

  const formatedStartDate = formatDate(startDate, locale);
  const formatedEndDate = formatDate(endDate, locale);

  const showCalendar = () => setIsCalendarVisible(true);
  const hideCalendar = () => setIsCalendarVisible(false);

  const focusOnStartDate = () => setFocusedInput(START_DATE);
  const focusOnEndDate = () => setFocusedInput(END_DATE);

  const correctCheckInDate = (checkInDate) =>
    isAfter(checkInDate, checkInEnd) ? checkInEnd : checkInDate;

  const correctCheckOutDate = getCorrectCheckOutDate({
    minNumberOfNights,
    maxNumberOfNights,
  });

  const handleDateChange = (newState) => {
    const correctedCheckInDate = correctCheckInDate(newState.startDate);
    const correctedCheckOutDate = correctCheckOutDate({
      correctedCheckInDate,
      checkOutDate: newState.endDate,
    });

    if (!newState.focusedInput) {
      focusOnStartDate();
      setCheckIn(format(correctedCheckInDate, dateFormat));
      setCheckOut(format(correctedCheckOutDate, dateFormat));
      if (hideCalendarOnEndDateSelection) {
        hideCalendar();
      }
    } else if (!correctedCheckOutDate) {
      setFocusedInput(newState.focusedInput);
      setCheckIn(format(correctedCheckInDate, dateFormat));
      setCheckOut(
        format(
          add(correctedCheckInDate, { days: minNumberOfNights }),
          dateFormat
        )
      );
    } else {
      setFocusedInput(newState.focusedInput);
      setCheckIn(format(correctedCheckInDate, dateFormat));
      setCheckOut(format(correctedCheckOutDate, dateFormat));
    }

    setFocusedSection(section.CALENDAR);
  };

  const {
    activeMonths,
    firstDayOfWeek,
    focusedDate,
    goToDate,
    goToNextMonths,
    goToPreviousMonths,
    isDateBlocked,
    isDateFocused,
    isDateHovered,
    isDateSelected,
    isEndDate,
    isFirstOrLastSelectedDate,
    isStartDate,
    onDateFocus,
    onDateSelect,
  } = useDatepicker({
    startDate,
    endDate,
    focusedInput,
    onDatesChange: handleDateChange,
    numberOfMonths,
    firstDayOfWeek: 0,
    minBookingDate: focusedInput === END_DATE ? startDate : checkInStart,
    maxBookingDate: validInterval.end,
  });

  const isRestricted = (day) => restrictedDates.some(isSameDay(day));
  const isDateAvailable = (day) => !noAvailabilityDates.some(isSameDay(day));
  const isUnselectable = (day) => focusedInput === END_DATE && isStartDate(day);
  const isOutsideCheckInRange = (day) =>
    differenceInDays(day, checkInStart) < 0 || isAfter(day, checkInEnd);

  const [month1, month2] = activeMonths;
  const firstMonth = new Date(month1.year, month1.month);
  const numberOfSelectedDays = differenceInDays(endDate, startDate);

  // --- HANDLERS
  const canNavigateToPreviousMonth = areIntervalsOverlapping(validInterval, {
    start: new Date(month1.year, month1.month - 1),
    end: new Date(month1.year, month1.month - 1, 31),
  });
  const theSecondMonth = month2 || month1;
  const canNavigateToNextMonth = areIntervalsOverlapping(validInterval, {
    start: new Date(theSecondMonth.year, theSecondMonth.month + 1),
    end: new Date(theSecondMonth.year, theSecondMonth.month + 1, 31),
  });

  const availabilityLastUpdatedDescription = `${t("Last updated {0} at {1}", [
    formatToStandardDate(availabilityLastUpdated, locale),
    `${formatToTwelveHoursTime(
      availabilityLastUpdated,
      locale
    )} ${getClientTimezoneAbbreviation()}`,
  ])}`;

  const handleOnMouseDown = (e) => e.preventDefault();

  const componentOnBlur = ({ relatedTarget }) => {
    if (!componentRef.current.contains(relatedTarget)) {
      hideCalendar();
    }
  };

  const handleStartDateOnFocus = () => {
    setFocusedSection(section.START_DATE_INPUT);
    showCalendar();
  };

  const handleStartDateOnClick = () => {
    focusOnStartDate();
    showCalendar();
  };

  const handleEndDateOnFocus = () => {
    setFocusedSection(section.END_DATE_INPUT);
    focusOnEndDate();
    showCalendar();
  };

  const handleEndDateOnClick = () => {
    focusOnEndDate();
    showCalendar();
  };

  const handleNavigatingAwayFromPrevMonth = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      setFocusedSection(section.CALENDAR);
    }
  };

  const focusOnNextWidgetOutsideCalendar = () => {
    if (nextControlOutsideWidget && nextControlOutsideWidget.current) {
      nextControlOutsideWidget.current.focus();
    }
  };

  const handleNavigatingAwayFromCalendar = (e) => {
    e.preventDefault();
    if (canNavigateToNextMonth) {
      setFocusedSection(section.NEXT_MONTH);
    } else if (focusedInput === END_DATE) {
      hideCalendar();
      focusOnStartDate();
      focusOnNextWidgetOutsideCalendar();
    } else if (areInputsFocusable) {
      setFocusedSection(section.END_DATE_INPUT);
    }
  };

  const handleNavigatingAwayFromNextMonth = (e) => {
    if (["Tab", " "].includes(e.key)) {
      e.preventDefault();
      if (focusedInput === START_DATE && areInputsFocusable) {
        setFocusedSection(section.END_DATE_INPUT);
      } else {
        hideCalendar();
        focusOnStartDate();
        focusOnNextWidgetOutsideCalendar();
      }
    }
  };

  const handleNavigatingAwayFromStartDateInput = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      focusOnStartDate();
      if (canNavigateToPreviousMonth) {
        setFocusedSection(section.PREV_MONTH);
      } else {
        setFocusedSection(section.CALENDAR);
      }
    }
  };

  const handleNavigatingAwayFromEndDateInput = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      focusOnEndDate();
      if (canNavigateToPreviousMonth) {
        setFocusedSection(section.PREV_MONTH);
      } else {
        setFocusedSection(section.CALENDAR);
      }
    }
  };

  useEffect(() => {
    if (!disableEffectfullyUpdatingCheckInDate) {
      if (
        !isSameDay(checkInStart, startDate) &&
        (!hasUserSelectedCheckIn || isAfter(checkInStart, startDate))
      ) {
        setCheckOut(
          format(add(checkInStart, { days: minNumberOfNights }), dateFormat)
        );
        setCheckIn(format(checkInStart, dateFormat));
        goToDate(checkInStart);
        setHasUserSelectedCheckIn(false);
      } else if (isAfter(startDate, checkInEnd)) {
        setCheckOut(
          format(
            add(checkInEnd, {
              days: Math.min(
                differenceInDays(endDate, startDate),
                maxNumberOfNights
              ),
            }),
            dateFormat
          )
        );
        setCheckIn(format(checkInEnd, dateFormat));
        goToDate(checkInEnd);
      } else if (isAfter(endDate, checkOutEnd)) {
        setCheckOut(format(checkOutEnd, dateFormat));
      }
    }
  }, [checkInStart, checkInEnd, checkOutEnd, hasUserSelectedCheckIn]);

  useEffect(() => {
    if (focusedSection === section.PREV_MONTH && prevMonthRef.current) {
      prevMonthRef.current.focus();
    } else if (focusedSection === section.NEXT_MONTH && nextMonthRef.current) {
      nextMonthRef.current.focus();
    } else if (
      focusedSection === section.END_DATE_INPUT &&
      endDateInputRef.current &&
      areInputsFocusable
    ) {
      endDateInputRef.current.focus();
    } else if (
      focusedSection === section.START_DATE_INPUT &&
      startDateInputRef.current &&
      areInputsFocusable
    ) {
      startDateInputRef.current.focus();
    }
  }, [focusedSection, areInputsFocusable]);

  useEffect(() => {
    if (initialFocus === "checkIn") {
      setFocusedSection(section.START_DATE_INPUT);
      setIsCalendarVisible(true);
    } else if (initialFocus === "checkOut") {
      setFocusedSection(section.END_DATE_INPUT);
      setIsCalendarVisible(true);
    }
  }, [initialFocus]);

  useEffect(() => {
    if (hasCheckInError && startDateInputRef?.current) {
      startDateInputRef.current.focus();
    }
  }, [hasCheckInError]);

  useEffect(() => {
    if (hasCheckOutError && endDateInputRef?.current) {
      endDateInputRef.current.focus();
    }
  }, [hasCheckOutError]);

  return {
    activeMonths,
    availabilityLastUpdatedDescription,
    canNavigateToNextMonth,
    canNavigateToPreviousMonth,
    checkType: focusedInput === START_DATE ? "checkIn" : "checkOut",
    componentOnBlur,
    componentRef,
    endDate,
    endDateInputRef,
    firstDayOfWeek,
    firstMonth,
    focusedDate,
    formatedEndDate,
    formatedStartDate,
    goToNextMonths,
    goToPreviousMonths,
    handleEndDateOnClick,
    handleEndDateOnFocus,
    handleNavigatingAwayFromCalendar,
    handleNavigatingAwayFromEndDateInput,
    handleNavigatingAwayFromNextMonth,
    handleNavigatingAwayFromPrevMonth,
    handleNavigatingAwayFromStartDateInput,
    handleOnMouseDown,
    handleStartDateOnClick,
    handleStartDateOnFocus,
    hideCalendar,
    includeDaysFromOtherMonths,
    isCalendarVisible,
    isDateAvailable,
    isDateBlocked,
    isDateFocused: (date) =>
      focusedSection === section.CALENDAR && isDateFocused(date),
    isDateHovered,
    isDateSelected,
    isEndDate,
    isFirstOrLastSelectedDate,
    isRestricted,
    isStartDate,
    isUnselectable,
    isOutsideCheckInRange,
    nextMonthRef,
    numberOfSelectedDays,
    onDateFocus,
    onDateSelect,
    prevMonthRef,
    startDate,
    startDateInputRef,
    validInterval,
    weekdayLabelFormatter,
  };
}
