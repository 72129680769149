import { getIsoCodeForLocale } from "config/languages";
import env from "config/env";

const { NODE_ENV } = env;

const baseRequest = ({ locale, headers = {} }) => {
  if (NODE_ENV === "development" && !locale) {
    throw new Error("missing locale");
  }
  return {
    headers: {
      "Accept-Language": getIsoCodeForLocale(locale || "en"),
      "Content-Type": "application/json",
      ...headers,
    },
    withCredentials: NODE_ENV === "development" ? true : undefined,
  };
};

export const baseGetRequest = ({ locale = "en", headers = {} } = {}) => ({
  ...baseRequest({ locale, headers }),
  method: "GET",
});

export const basePostRequest = ({ locale = "en", headers = {} } = {}) => ({
  ...baseRequest({ locale, headers }),
  method: "POST",
});

export const basePutRequest = ({ locale = "en", headers = {} } = {}) => ({
  ...baseRequest({ locale, headers }),
  method: "PUT",
});

export const baseDeleteRequest = ({ locale = "en", headers = {} } = {}) => ({
  ...baseRequest({ locale, headers }),
  method: "DELETE",
});
