import { ajax } from "rxjs/ajax";

import env from "config/env";
import { basePostRequest } from "../tretail/baseRequest";

const { FOLIO_API_BASE_URL } = env;

export default function requestInvoice$({
  checkInDate,
  confirmation,
  email,
  firstName,
  hotelCode,
  last4CC,
  lastName,
  locale = "en",
  number,
  reservationId,
  timeStamp,
}) {
  return ajax({
    ...basePostRequest({ locale }),
    url: `${FOLIO_API_BASE_URL}/process`,
    body: {
      language: locale,
      checkInDate,
      confirmation,
      email,
      firstName,
      hotelCode,
      last4CC,
      lastName,
      number,
      reservationId,
      timeStamp,
    },
  });
}
