import env from "config/env";

const { IMAGE_BASE_URL } = env;

const defaultCardImages = {
  transportation: {
    landscape: `${IMAGE_BASE_URL}/alt/img-opt/~60..0,0000-156,2500-3000,0000-1687,5000/author/content/dam/fourseasons/images/web/TFY/TFY_1135_original.jpg`,
    portrait: `${IMAGE_BASE_URL}/alt/img-opt/~60..1050,0000-0,000-1600,0000-2000,0000/author/content/dam/fourseasons/images/web/TFY/TFY_1135_original.jpg`,
  },
  amenities: {
    landscape: `${IMAGE_BASE_URL}/content/dam/fourseasons/images/web/AUS/AUS_1214_aspect16x9.jpg`,
    portrait: `${IMAGE_BASE_URL}/alt/img-opt/~60..830,0000-0,000-1350,0000-1687,5000/author/content/dam/fourseasons/images/web/AUS/AUS_1214_aspect16x9.jpg`,
  },
  spa: {
    landscape: `${IMAGE_BASE_URL}/alt/img-opt/~60..0,0000-250,0000-3000,0000-1687,5000/author/content/dam/fourseasons/images/web/MAR/MAR_1869_original.jpg`,
    portrait: `${IMAGE_BASE_URL}/alt/img-opt/~60..800,0000-0,000-1600,0000-2000,0000/author/content/dam/fourseasons/images/web/MAR/MAR_1869_original.jpg`,
  },
  dining: {
    landscape: `${IMAGE_BASE_URL}/alt/img-opt/~60..0,0000-80,0000-2500,0000-1406,2500/author/content/dam/fourseasons/images/web/NAV/NAV_373_original.jpg`,
    portrait: `${IMAGE_BASE_URL}/alt/img-opt/~60..610,0000-0,0000-1280,0000-1600,0000/author/content/dam/fourseasons/images/web/NAV/NAV_373_original.jpg`,
  },
  experiences: {
    landscape: `${IMAGE_BASE_URL}/alt/img-opt/~60..0,0000-156,2500-3000,0000-1687,5000/author/content/dam/fourseasons/images/web/CFS/CFS_2059_original.jpg`,
    portrait: `${IMAGE_BASE_URL}/alt/img-opt/~60..700,0000-0,000-1600,0000-2000,0000/author/content/dam/fourseasons/images/web/CFS/CFS_2059_original.jpg`,
  },
};

export default defaultCardImages;
