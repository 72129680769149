import { API_REQUEST_STATES } from "store/apiRequestStates/constants";
import { isEmployeeRate } from "BookingFlow/utils";

import { parse } from "date-fns";
import { fetchStayHistory } from "./stayHistory.slice";

const checkedOutStatus = "CHECKEDOUT";

export const selectStayHistory = (state) =>
  state?.stayHistory?.data?.bookingSummaries || [];

export const selectEmployeeStayHistory = (state) =>
  (state?.stayHistory?.data?.bookingSummaries || [])
    .filter(
      ({
        hotelProducts: [
          {
            fsReservationStatus,
            rooms: { room: [{ ratePlanCode } = {}] = [] } = {},
          } = {},
        ] = [],
      } = {}) => {
        return (
          fsReservationStatus === checkedOutStatus &&
          isEmployeeRate({ promoCode: ratePlanCode })
        );
      }
    )
    .sort((a, b) => {
      if ((a.startDate || "") < (b.startDate || "")) {
        return 1;
      }
      if ((a.startDate || "") > (b.startDate || "")) {
        return -1;
      }
      if ((a.endDate || "") < (b.endDate || "")) {
        return 1;
      }
      return -1;
    });

export const selectStayHistoryByYear = (state) =>
  selectEmployeeStayHistory(state).reduce((stayHistory, stay) => {
    const stayDetails = {
      reservationId: stay.hotelProducts?.[0]?.reservationId,
      ratePlanCode: stay.hotelProducts?.[0]?.rooms?.room?.[0]?.ratePlanCode,
      hotelCode: stay.hotelProducts?.[0]?.hotelCode,
      checkIn: stay.startDate,
      checkOut: stay.endDate,
    };
    const checkInYear = parse(
      stay.startDate,
      "yyyy-MM-dd",
      new Date()
    ).getFullYear();
    const yearStayHistory = stayHistory.find(
      ({ year }) => year === checkInYear
    );
    if (yearStayHistory) {
      yearStayHistory.stays.push(stayDetails);
      return stayHistory;
    }
    return [
      ...stayHistory,
      {
        year: checkInYear,
        stays: [stayDetails],
      },
    ];
  }, []);

export const selectIsLoadingStayHistory = ({ apiRequestStates }) => {
  const apiRequestState = apiRequestStates[fetchStayHistory.type];
  return (
    (apiRequestState && apiRequestState === API_REQUEST_STATES.REQUEST) || false
  );
};

export const selectHasRequestedStayHistory = ({ apiRequestStates }) => {
  const apiRequestState = apiRequestStates[fetchStayHistory.type];
  return (
    (apiRequestState && apiRequestState === API_REQUEST_STATES.FULFILLED) ||
    false
  );
};
