import { parse, addDays, isAfter } from "date-fns";

export const getCardDisplayStatus = (membershipCard) => {
  let showCard = false;
  switch (membershipCard.sta) {
    case "R": // Card Status = Initial - Do not display the card
    case "X": // Card Status = Cancel - Do not display the card
    case "G": // Card Status = Transfer - Do not display the card
    case "T": // Card Status = Refund - Do not display the card
      showCard = false;
      break;

    case "I": // Card Status = Valid - Display card
    case "L": // Card Status = Loss - Display card with Expired Tag
    case "S": // Card Status = Sleep - Display card with Expired Tag
      showCard = true;
      break;

    case "O": // Card Status = Suspend/Stop - IF today > 30 days from expiry date, Do not display the card. IF today < 30 days from expiry date, Display card with Expired Tag
      if (membershipCard?.endDate) {
        const expDate = parse(
          membershipCard?.endDate,
          "yyyy-MM-dd HH:mm:ss",
          new Date()
        );
        const thirtyDaysFrom = addDays(expDate, 30);
        const today = new Date();
        const moreThan30 = isAfter(today, thirtyDaysFrom);
        if (moreThan30) {
          showCard = false;
        } else {
          showCard = true;
        }
      } else {
        showCard = false;
      }
      break;

    default:
      showCard = false;
  }

  return showCard;
};

export const getCardExpiredStatus = (membershipCard) => {
  let cardExpired = false;
  switch (membershipCard.sta) {
    case "R": // Card Status = Initial - Do not display the card
    case "X": // Card Status = Cancel - Do not display the card
    case "G": // Card Status = Transfer - Do not display the card
    case "T": // Card Status = Refund - Do not display the card
    case "I": // Card Status = Valid - Display card
      cardExpired = false;
      break;

    case "O": // Card Status = If card Displays, display card with Expired Tag
    case "L": // Card Status = Loss - Display card with Expired Tag
    case "S": // Card Status = Sleep - Display card with Expired Tag
      cardExpired = true;
      break;

    default:
      cardExpired = false;
  }

  return cardExpired;
};
