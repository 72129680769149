import React from "react";
import { useSelector } from "react-redux";
import update from "lodash/fp/update";

import { useTranslation } from "hooks";
import {
  updateCountryAndLanguage,
  selectCountryAndLanguage,
} from "store/profile";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import { Button, Dropdown } from "Profile/components";
import { selectCountriesContent } from "store/countriesContent";
import languages from "../../tretailProfileLanguages";
import useFormSubmit from "../../../hooks/useFormSubmit";

function CountryAndLanguage() {
  const { t } = useTranslation();

  const countries = useSelector(selectCountriesContent);

  const countriesOptions = countries.map(({ shortName, iso2 }) => ({
    value: iso2,
    label: shortName,
  }));

  const {
    onSubmit,
    register,
    showLoadingIndicator,
    value: { countryOfResidence, preferredLanguage },
    formState,
  } = useFormSubmit({
    action: updateCountryAndLanguage,
    selector: selectCountryAndLanguage,
    fieldName: "countryAndLanguage",
  });

  return (
    <div id="country-and-language" className="view--profile-edit__block">
      {showLoadingIndicator && <BookingFlowLoadingIndicator />}
      <div className="fs-profile-country-and-language">
        <h3 className="fs-profile-country-and-language__title">
          {t("Country & Language")}
        </h3>
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="col-sm-12 col-md-9">
              <Dropdown
                name="countryAndLanguage.countryOfResidence"
                identifier="input-country-region"
                label={t("Country / Region")}
                value={countryOfResidence}
                options={countriesOptions.map(update(["label"], t))}
                register={register()}
              />
              <Dropdown
                name="countryAndLanguage.preferredLanguage"
                identifier="input-preferred-language"
                label={t("Preferred Language")}
                value={preferredLanguage}
                options={languages.map(update(["label"], t))}
                register={register()}
              />
            </div>
          </div>
          <div className="fs-profile-country-and-language__actions">
            <Button
              type="submit"
              aria-label={t("Add a new email address")}
              kind="secondary"
              disabled={!formState.isDirty}
              rounded
            >
              {t("Update")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CountryAndLanguage;
