export const selectCartRecommendations = (state) =>
  state.cartRecommendations.data;

export const selectCartExperiencesRecommendations = (state) =>
  state.cartRecommendations.data.productCategories.experiences;

export const selectCartDiningRecommendations = (state) =>
  state.cartRecommendations.data.productCategories.dining;

export const selectCartSpaRecommendations = (state) =>
  state.cartRecommendations.data.productCategories.spa;
