import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import queryString from "query-string";

import env from "config/env";
import processResponse from "./processResponse";

const { MESSENGER_API_BASE_URL } = env;

const defaultFields = [
  "Product_Name__c",
  "Property__c",
  "status",
  "isDeleted",
  "Description",
  "Request_Date__c",
  "Time__c",
  "Request_Subtype__c",
  "Master_Request_Type__c",
  "Total_Cost__C",
  "Currency__c",
  "CurrencyCode__c",
  "Duration__c",
  "Number_of_Guests__c",
  "Number_of_Adults__c",
  "Number_of_Children__c",
  "Pickup_location__c",
  "Dropoff_location__c",
  "Vendor__r.Name",
  "Appointment_With__c",
  "Confirmation_Number__c",
  "Driver_Instruction__c",
  "Activity_Tour_Start_Time__c",
  "Pick_up_time__c",
  "Vendor__c",
  "Vendor_Name__c",
  "Vendor_Name_on_Itinerary__c",
  "Show_on_Itinerary__c",
  "Preferred_Time_of_Day__c",
  "Guest_Visible_Notes__c",
  "Cancellation_Policy__c",
  "Departure_location__c",
  "Departure_Time__c",
  "Vehicle_Selected__c",
  "Number_of_Items__c",
  "Gender_Preference__c",
  "Quantity__c",
];

export default function getGuestRequests$({
  surname,
  propertyCode,
  reservationId,
  fields = defaultFields,
}) {
  return ajax
    .getJSON(
      `${MESSENGER_API_BASE_URL}/api/v1/concierge_key?${queryString.stringify({
        last_name: surname,
        property_ows_code: propertyCode,
        confirmation: reservationId,
        fields: fields.join(","),
      })}`
    )
    .pipe(
      map((response) => response.map(processResponse)),

      catchError((error) => {
        console.log("getGuestRequests ~ error:", error);
        return of([]);
      })
    );
}
