import React from "react";
import classNames from "classnames";

import env from "config/env";

const { IMAGE_BASE_URL } = env;

export default function AncillaryCard({
  title,
  subTitle,
  image = { src: null, alt: null },
  description,
  cta,
  isWide = false,
}) {
  return (
    <a
      href={cta.href}
      className={classNames("ancillary-card", {
        "ancillary-card--wide": isWide,
      })}
    >
      <div className="ancillary-card__background">
        <img src={`${IMAGE_BASE_URL + image.src}`} alt={image.alt} />
      </div>

      <div className="ancillary-card__content">
        <div className="ancillary-card__content__title-container">
          <h3 className="ty-c3 ancillary-card__content__title">{title}</h3>
          {subTitle && (
            <p className="ty-c4 ancillary-card__content__sub-title">
              {subTitle}
            </p>
          )}
        </div>
        <div className="ancillary-card__content__description-container">
          <p className="ty-b3 ancillary-card__content__description">
            {description}
          </p>
          <p className="ty-c3 ancillary-card__content__cta">{cta.label}</p>
        </div>
      </div>
    </a>
  );
}
