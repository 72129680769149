import { ofType } from "redux-observable";
import { mergeMap, switchMap, catchError } from "rxjs/operators";
import { of } from "rxjs";

import { modifyHotelProductCust$ } from "api/tretail/bookingManagementCust";
import { getBookingById$ } from "api/tretail/booking";
import catchInternalServerError from "store/catchInternalServerError";
import {
  modifyHotelProductCust,
  modifyHotelProductCustFulfilled,
  fetchHistoricBookingFailed,
  fetchHistoricBookingFulfilled,
  modifyHotelProductCustFailed,
} from "../bookings.slice";

export default function modifyHotelProductCustEpic(action$) {
  return action$.pipe(
    ofType(modifyHotelProductCust.type),
    switchMap(({ payload }) => {
      return modifyHotelProductCust$(payload).pipe(
        switchMap((data) =>
          getBookingById$({
            bookingId: payload.bookingId,
            locale: payload.locale,
          }).pipe(
            mergeMap((response = {}) => [
              modifyHotelProductCustFulfilled(data),
              ...(payload.actions ? payload.actions : []),
              response?.errorCode
                ? fetchHistoricBookingFailed(response.message)
                : fetchHistoricBookingFulfilled(response),
            ])
          )
        ),
        catchInternalServerError(),
        catchError(() => of(modifyHotelProductCustFailed()))
      );
    })
  );
}
