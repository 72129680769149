import React from "react";

import { useTranslation } from "hooks";
import { formatDate } from "utils/utils";
import { STEP_3 as DATE_FORMATS } from "utils/dateFormats";

export default function CancelBy({ cancelBy }) {
  const { t, locale } = useTranslation();

  return (
    <div className="free-cancellation">
      <p className="cancellation-description">
        {t(
          "Free cancellation online until {0} {1}, {2}",
          formatDate(
            cancelBy,
            DATE_FORMATS.FREE_ONLINE_CANCELLATION,
            locale
          ).split("-")
        )}
      </p>
    </div>
  );
}
