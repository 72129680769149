import React, { useState } from "react";
import { useTranslation } from "hooks";
import { Button } from "Profile/components";
import AddToCalendarModal from "./AddToCalendarModal";

function EarlyCheckInBanner({
  onClose,
  confirmation,
  hotelCode,
  checkInTimeUTC,
}) {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <AddToCalendarModal
          confirmation={confirmation}
          hotelCode={hotelCode}
          checkInTimeUTC={checkInTimeUTC}
          close={() => setShowModal(false)}
        />
      )}
      <div
        className="banner early-check-in-banner"
        aria-label={t("Early Check In Banner")}
        role="dialog"
      >
        <p>
          {t(
            "Check-in is only allowed 36 hours in advance of the property local check-in time."
          )}
          &nbsp;
        </p>

        <Button className="add-to-calendar" onClick={() => setShowModal(true)}>
          {t("Add To Calendar")}
        </Button>

        <Button kind="secondary" onClick={onClose}>
          {t("Close")}
        </Button>
      </div>
    </>
  );
}

export default EarlyCheckInBanner;
