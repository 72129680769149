import React from "react";

export default function DiningIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.57307 1.5C6.25657 1.5 6 1.75569 6 2.07109V8.76428C6 10.9994 7.67326 12.845 9.83954 13.1249V29.7576C9.83954 30.073 10.0961 30.3287 10.4126 30.3287C10.7291 30.3287 10.9857 30.073 10.9857 29.7576V13.1249C13.152 12.845 14.8252 10.9994 14.8252 8.76428V2.07109C14.8252 1.75569 14.5686 1.5 14.2521 1.5C13.9357 1.5 13.6791 1.75569 13.6791 2.07109V7.10811H10.9857V2.07109C10.9857 1.75569 10.7291 1.5 10.4126 1.5C10.0961 1.5 9.83954 1.75569 9.83954 2.07109V7.10811H7.14613V2.07109C7.14613 1.75569 6.88956 1.5 6.57307 1.5ZM7.14613 8.25029V8.76428C7.14613 10.5621 8.60858 12.0195 10.4126 12.0195C12.2166 12.0195 13.6791 10.5621 13.6791 8.76428V8.25029H7.14613Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8768 18.1187H24.8539V29.9289C24.8539 30.2443 25.1104 30.5 25.4269 30.5C25.7434 30.5 26 30.2443 26 29.9289V2.18531C26 1.8699 25.7434 1.61422 25.4269 1.61422H25.0716C21.954 1.61422 20.3037 3.32591 20.3037 6.01162V17.5477C20.3037 17.8631 20.5603 18.1187 20.8768 18.1187ZM21.4499 6.01162C21.4499 4.0083 22.5301 2.82816 24.8539 2.75957V16.9766H21.4499V6.01162Z"
        fill="black"
      />
    </svg>
  );
}
