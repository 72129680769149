import React from "react";

import { useTranslation } from "hooks";
import { selectPhoneNumbers } from "store/profile";
import { PERSONAL_INFO_TYPE } from "fixtures/constants";
import { ToggleBlock } from "Profile/components";
import AddPhoneNumber from "./AddPhoneNumber";
import EditPhoneNumber from "./EditPhoneNumber";
import useBlock from "../../hooks/useBlock";
import ToggleBlockLabel from "../ToggleBlockLabel";

const MAX_NUMBER_OF_PHONE_NUMBERS = 3;

function PhoneNumbers() {
  const { t } = useTranslation();

  const { canAdd, items: phoneNumbers } = useBlock({
    selector: selectPhoneNumbers,
    max: MAX_NUMBER_OF_PHONE_NUMBERS,
  });

  return (
    <div id="phone-numbers" className="view--profile-edit__block">
      <div className="fs-profile-phone-numbers">
        <div className="view--profile-edit__block__title-row">
          <ToggleBlock
            type="add"
            label={t("Phone Numbers")}
            buttonLabel={t("Add")}
            buttonAriaLabel={t("Add a new phone number")}
            showButton={canAdd}
          >
            {({ close }) => <AddPhoneNumber closeForm={close} />}
          </ToggleBlock>
        </div>

        {phoneNumbers.map((phoneNumber, index) => (
          <ToggleBlock
            key={phoneNumber.salesForceId}
            type="profile-value-edit"
            buttonLabel={t("Add")}
            buttonAriaLabel={t("Add a new email address")}
            label={({ toggle, isOpen }) => {
              return (
                <ToggleBlockLabel
                  label={PERSONAL_INFO_TYPE()[phoneNumber.phoneType]}
                  ariaLabel={t("Edit your phone number")}
                  secondaryLabel={phoneNumber.defaultPhone && t("primary")}
                  selectedValue={`+${phoneNumber.number}`}
                  toggleForm={toggle}
                  isActive={isOpen}
                />
              );
            }}
          >
            {({ close }) => (
              <EditPhoneNumber
                identifier={`edit-phone-number-${index}`}
                phoneNumber={phoneNumber}
                closeForm={close}
                canBeDeleted={
                  phoneNumbers.length > 1 &&
                  !phoneNumber.isLogin &&
                  !phoneNumber.defaultPhone
                }
              />
            )}
          </ToggleBlock>
        ))}
      </div>
    </div>
  );
}

export default PhoneNumbers;
