import { SECTIONS } from "../../BookingFlow/ConfirmYourStay/hooks/constants";

export const getBookingType = (contactType) => {
  switch (contactType) {
    case SECTIONS.AGENT:
    case "TRAVEL_AGENT":
      return "t";

    case SECTIONS.SOMEONE_ELSE:
    case "BOOK_FOR":
      return "b";

    default:
      return "s";
  }
};

export const getBookingTypeAndEmailOptions = (formValues) => {
  const bookingType = getBookingType(formValues.contactType);
  if (
    ["t", "b"].includes(bookingType) &&
    formValues.guestDetails.sendGuestConfirmation
  ) {
    return {
      bookingType,
      bookingEmailOptions: {
        sendGuestEmail: true,
        guestEmail: formValues.guestDetails.email,
      },
    };
  }
  return { bookingType };
};
