import React from "react";
import classNames from "classnames";
import uniq from "lodash/uniq";

import { useTranslation } from "hooks";

function FlexDatesInput({ flexDateOptions, value, onChange = () => {} }) {
  const { t } = useTranslation();

  const handleClick = (flexDateValue) => () => {
    onChange(flexDateValue === value ? "" : flexDateValue);
  };

  return (
    <div className="flex-date-window">
      {uniq(flexDateOptions).map((flexDateOption) => (
        <button
          key={flexDateOption}
          type="button"
          onClick={handleClick(flexDateOption.toString())}
          className={classNames("flex-date-window__btn", {
            "flex-date-window__btn--is-selected":
              value === flexDateOption.toString(),
          })}
        >
          {["±", flexDateOption, t("Days")].join(" ")}
        </button>
      ))}
    </div>
  );
}

export default FlexDatesInput;
