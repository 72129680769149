import React, { useState } from "react";
import find from "lodash/fp/find";
import classNames from "classnames";

import "./ButtonOptions.scss";
import { useTranslation } from "hooks";
import Button from "../Button";

function CollapseIcon({ className = "" }) {
  return <span className={classNames("icon icon-caret", className)} />;
}

export default function ButtonOptions({
  id: initialId,
  name,
  errorMessage = false,
  isRequired,
  label,
  selectedLabel,
  options: initialOptions = [],
  disabled,

  // uncontrolled
  _defaultValue,

  // controlled
  value,
  onChange,

  // list or grid
  layout = "list",
  showAllButtonText,
}) {
  const { t } = useTranslation();

  const [optionsHidden, setOptionsHidden] = useState(true);

  const id = initialId || `field--${name}`;

  const hasError =
    (typeof errorMessage === "boolean" && errorMessage === true) ||
    errorMessage.length > 0;

  const options = initialOptions.map((option) => {
    if (typeof option === "string") {
      return { label: option, value: option };
    }
    return option;
  });

  // list: show first 3 initially, grid: show all always
  const optionsToRender = options.slice(
    0,
    layout === "list" && optionsHidden ? 3 : Infinity
  );

  const labelToDisplay = isRequired ? `${label}*` : label;

  if (options.length === 0) {
    return null;
  }

  if (value) {
    const { title, subtitle, price } = find({ value })(options);
    return (
      <div className="ButtonOptions ButtonOptions--selected">
        <div id={id} className="ButtonOption ButtonOption--selected">
          <div>
            <div className="ty-b2">{title}</div>
            <div className="ty-c4">{subtitle}</div>
            <div className="ButtonOption__cost ty-c3">{price}</div>
          </div>
          <button
            name={name}
            value={undefined}
            type="button"
            onClick={onChange}
            className="ty-c3 CTA--underlined--light"
            disabled={disabled}
          >
            {t("EDIT")}
          </button>
        </div>
        <label
          className={classNames("ButtonOptions__label ty-c4", {
            "formElement-label--invalid fs-error-copy": hasError,
          })}
          htmlFor={id}
        >
          {selectedLabel}
        </label>
      </div>
    );
  }

  return (
    <div
      className={classNames("ButtonOptions", {
        "ButtonOptions--grid": layout === "grid",
        "ButtonOptions--list": layout === "list",
      })}
    >
      {optionsToRender.map(({ title, subtitle, price, value: optionValue }) => {
        return (
          <button
            id={id}
            key={optionValue}
            className="ButtonOption"
            type="button"
            name={name}
            value={optionValue}
            onClick={onChange}
            disabled={disabled}
          >
            <div className="ty-b2">{title}</div>
            <div className="ty-c4">{subtitle}</div>
            <div className="ButtonOption__cost ty-c3">{price}</div>
          </button>
        );
      })}
      {layout === "list" && options.length > 3 && (
        <Button
          onClick={() =>
            setOptionsHidden((prevOptionsHidden) => !prevOptionsHidden)
          }
          type="secondary"
          theme="light"
          className={classNames("ButtonOptions__showHideBtn ty-c3", {
            "ButtonOptions__showHideBtn--visible": !optionsHidden,
          })}
        >
          {optionsHidden
            ? `${t(`Show All`)} ${showAllButtonText || t("options")}`
            : `${t(`Show Less`)} ${showAllButtonText || t("options")}`}

          <CollapseIcon />
        </Button>
      )}
      <label
        className={classNames("ButtonOptions__label ty-c4", {
          "formElement-label--invalid fs-error-copy": hasError,
        })}
        htmlFor={id}
      >
        {labelToDisplay}
      </label>
      {errorMessage && (
        <span
          className="ButtonOptions__errorMessage formElement-message fs-error-copy"
          role="alert"
        >
          {errorMessage}
        </span>
      )}
    </div>
  );
}
