import React from "react";
import { useTranslation } from "hooks";
import { Button } from "Profile/components";
import Modal from "Profile/ProfileView/Modal";

function PastCheckInTimeModal({ close }) {
  const { t } = useTranslation();
  const noteElements = [];
  let noteParts = t(
    "Please note your requested time is before our official Check-In time. We will do our best to accommodate your request."
  ).split(".");
  noteParts = noteParts.filter((notePart) => notePart && notePart.trim());
  noteParts.forEach((notePart, i) => {
    if (notePart) {
      noteElements.push(
        <span>
          {`${notePart.trim()}.`}
          {i < noteParts.length - 1 && <br />}
        </span>
      );
    }
  });
  return (
    <Modal close={close} className="employ-portal-modal-container">
      <div className="modal--friends-and-family view--profile-folio">
        <div className="container">
          <h3 className="ty-h3">{t("Early Check In")}</h3>
          <p>{noteElements}</p>
          <Button kind="secondary" theme="light" onClick={close}>
            {t("Close")}
          </Button>
        </div>

        <button className="btn-close" type="button" onClick={close}>
          <span>{t("Close")}</span>
        </button>
      </div>
    </Modal>
  );
}

export default PastCheckInTimeModal;
