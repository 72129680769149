import React from "react";

import { useTranslation } from "hooks";
import env from "config/env";

const { IMAGE_BASE_URL } = env;

const IMAGES = {
  appLogo: `${IMAGE_BASE_URL}/content/dam/fourseasons/images/web/artwork/logos/FINAL_MobileAppLogo_964.png/_jcr_content/renditions/cq5dam.thumbnail.67.67.margin.png`,
  appText: `${IMAGE_BASE_URL}/content/dam/fourseasons/images/web/artwork/logos/FS_APP_TEXT_SM.png/jcr:content/renditions/cq5dam.web.637.358.png`,
  appleStoreApp: `${IMAGE_BASE_URL}/etc/designs/fourseasons/img/app/app-store-button.png`,
  androidStoreApp: `${IMAGE_BASE_URL}/etc/designs/fourseasons/img/app/google-play-button.png`,
};

const URLS = {
  appleStore: "https://itunes.apple.com/app/id980069965",
  androidStore:
    "https://play.google.com/store/apps/details?id=com.fourseasons.mobileapp",
};

export default function DownloadApp() {
  const { t } = useTranslation();

  return (
    <div className="app-cta">
      <img className="app-logo" src={IMAGES.appLogo} alt="Four Seasons Logo" />
      <img
        className="app-text-img"
        src={IMAGES.appText}
        alt="The Four Seasons App"
      />
      <div className="download-text col-12">
        {t(
          "Download the Four Seasons App to your phone so you can check in before you arrive."
        )}
      </div>
      <div className="col-lg-6 col-lg-offset-0 col-md-offset-1 col-md-10 col-sm-12">
        <a
          href={URLS.appleStore}
          target="_blank"
          rel="noopener noreferrer"
          title={t("Download on the App Store")}
        >
          <img
            className="app-btn"
            src={IMAGES.appleStoreApp}
            alt={t("Download on the App Store")}
          />
        </a>
      </div>
      <div className="col-lg-6 col-lg-offset-0 col-md-offset-1 col-md-10 col-sm-12">
        <a
          href={URLS.androidStore}
          target="_blank"
          rel="noopener noreferrer"
          title={t("Get it on Google play")}
        >
          <img
            className="app-btn"
            src={IMAGES.androidStoreApp}
            alt={t("Get it on Google play")}
          />
        </a>
      </div>
    </div>
  );
}
