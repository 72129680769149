import React from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";
import AddIcon from "./AddIcon";
import RemoveIcon from "./RemoveIcon";

export default function Quantity({
  id,
  name,
  disabled,
  value = 0,
  onChange,
  min = 0,
  max = Infinity,
  step = 1,
  circularIcons = true,
}) {
  const { t } = useTranslation();

  return (
    <div className="quantity-input" id={id}>
      <button
        type="button"
        aria-label={t("remove one")}
        className="quantity-input__toggle-button"
        onClick={() =>
          onChange({ target: { name, value: Math.max(value - step, min) } })
        }
        disabled={disabled || value === min}
      >
        <div className="quantity-input__toggle-icon">
          <RemoveIcon />
        </div>
      </button>

      <div
        className={classNames({
          "ty-h3": circularIcons,
          "ty-b2": !circularIcons,
        })}
      >
        {value}
      </div>

      <button
        type="button"
        aria-label={t("add one")}
        className="quantity-input__toggle-button"
        onClick={() =>
          onChange({ target: { name, value: Math.min(max, value + step) } })
        }
        disabled={disabled || value === max}
      >
        <div className="quantity-input__toggle-icon">
          <AddIcon />
        </div>
      </button>
    </div>
  );
}
