import { ofType } from "redux-observable";
import { of } from "rxjs";
import { switchMap, takeUntil, map, catchError } from "rxjs/operators";

import getCartRecommendations$ from "api/cartRecommendations/getCartRecommendations";
import {
  fetchCartRecommendations,
  fetchCartRecommendationsFulfilled,
  fetchCartRecommendationsCancel,
  fetchCartRecommendationsFailed,
} from "./cartRecommendations.slice";

const fetchCartRecommendationsEpic = (action$) =>
  action$.pipe(
    ofType(fetchCartRecommendations.type),
    switchMap(({ payload }) =>
      getCartRecommendations$(payload).pipe(
        map(fetchCartRecommendationsFulfilled),
        catchError((error) => of(fetchCartRecommendationsFailed(error))),
        takeUntil(action$.pipe(ofType(fetchCartRecommendationsCancel.type)))
      )
    )
  );

export default fetchCartRecommendationsEpic;
