/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { WEB_CHAT_STATE } from "fixtures/constants";

const initialState = {
  data: {},
  updatedAt: undefined,
  chatStatus: localStorage.getItem("webChatStatus") || WEB_CHAT_STATE.HIDDEN,
};

const appStatusSlice = createSlice({
  name: "appStatus",
  initialState,
  reducers: {
    fetchAppStatus() {},

    fetchAppStatusFulfilled(state, { payload }) {
      state.data = payload;
      state.updatedAt = Date.now();
    },

    fetchAppStatusCancel() {},

    updateChatStatus(state, { payload }) {
      state.chatStatus = payload.status;
      localStorage.setItem("webChatStatus", payload);
    },

    webChatAccepted() {
      let webChatAccepted = localStorage?.webChatAccepted;
      if (!webChatAccepted) {
        webChatAccepted = true;
        localStorage.setItem("webChatAccepted", webChatAccepted);
      }
    },

    loadingRedirect(state) {
      state.loadingRedirect = true;
    },
  },
});

export const {
  fetchAppStatus,
  fetchAppStatusFulfilled,
  fetchAppStatusCancel,
  updateChatStatus,
  webChatAccepted,
  loadingRedirect,
} = appStatusSlice.actions;

export default appStatusSlice.reducer;
