import React from "react";

import Navigations from "./Navigations";

function SideBar() {
  return (
    <div className="col-sm-12 col-lg-3 view--profile-edit__sidebar">
      <div className="view--profile-edit__navigation">
        <Navigations />
      </div>
    </div>
  );
}

export default SideBar;
