import { ajax } from "rxjs/ajax";

import env from "config/env";

const { HAS_MEMBERSHIP_URL } = env;

export default function hasMembership$() {
  return ajax.getJSON(`${HAS_MEMBERSHIP_URL}`);
}
/*
// DMG: Mock the API call
import { Observable } from "rxjs";

export default function hasMembership$() {
  return new Observable((subscriber) => {
    setTimeout(() => {
      subscriber.next({
        HasMembership: "True",
      });
      subscriber.complete();
    }, 5000);
  });
}
*/
