import React from "react";

import { useTranslation } from "hooks";
import { authenticationRoutes } from "Authentication";
import { Link } from "react-router-dom";

export default function OrSignInRegisterBlock() {
  const { t, locale } = useTranslation();

  const afterSignInRedirectTo = window.location.href;

  return (
    <div>
      <div className="sign-in-container">
        <span className="or_text">{t("Or")}</span>
        <Link
          to={{
            pathname: authenticationRoutes.signIn.to({
              locale,
            }),
            state: { afterSignInRedirectTo },
          }}
          className="CTA--secondary--light signin-button"
          aria-label="Sign In"
        >
          {t("Sign In")}
        </Link>
        <Link
          to={{
            pathname: authenticationRoutes.register.to({
              locale,
            }),
            state: { afterSignInRedirectTo },
          }}
          className="CTA--secondary--light register-button"
          aria-label="Register"
        >
          {t("Register")}
        </Link>
      </div>
    </div>
  );
}
