import React, { useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import getOr from "lodash/fp/getOr";

import { useTranslation, useWebChat } from "hooks";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import { getReservationHighlightsArray } from "Profile/utils/getReservationHighlights";
import getRates from "Profile/utils/getRates";
import useUpcomingTrip from "Profile/UpcomingTripView/useUpcomingTrip";
import ArrivalTransportation from "Profile/components/TransportationAmenities/ArrivalTransportation";
import ArrivalAmenities from "Profile/components/TransportationAmenities/ArrivalAmenities";
import EstimatedTotal from "Profile/UpcomingTripView/EstimatedTotal";
import UpcomingTrip from "Profile/UpcomingTripView/UpcomingTrip";
import UpgradeYourRoomView from "Profile/UpcomingTripView/UpgradeYourRoomView";
import {
  unauthenticatedBookingPath,
  unauthenticatedBookingItineraryPath,
  unauthenticatedBookingTransportationPath,
  unauthenticatedBookingWelcomeAmenitiesPath,
  unauthenticatedBookingEstimatedTotalPath,
  unauthenticatedBookingUpgradeYourRoomPath,
  findReservationsPath,
  findReservationsTransportationPath,
  findReservationsWelcomeAmenitiesPath,
  findReservationsEstimatedTotalPath,
} from "../authenticationRoutes";
import { ItineraryView } from "../../Profile/ItineraryView";

export default function UnauthenticatedUpcomingTrip({
  hotelCode,
  reservationId,
  surname,
  isFindReservations = false,
}) {
  const history = useHistory();
  const location = useLocation();
  const { t, locale } = useTranslation();

  const {
    phoneNumberPrimary,
    bookingConfig,
    allowCancellation,
    bookingMessages,
    canChangeBooking,
    changeArrivalTime,
    comment,
    employeeRate,
    hasErrors,
    highlightsMap,
    phoneNumber,
    priceViewable,
    propertyContent,
    showLoadingSpinner,
    showSavingSpinner,
    taxes,
    upcomingTrip,
    updateAdditionalRequests,
    updateMostImportantThing,
    updateRoomGuests,
    updateTripPurpose,
    youCanBookMessage,

    // WebChat
    webChatEnabled,
    searchParams,

    // Welcome Amentities
    canRequestWelcomeAmenities,
    availableWelcomeAmenities,

    // Arrival Transportation
    hasTransportation,
    availableArrivalTransportations,
    arrivalTransportation,
    canRequestTransportation,
  } = useUpcomingTrip({
    reservationId,
    hotelCode,
    surname,
  });

  const {
    enabled,
    isInitialised,
    openWebChat,
    setPredefinedMessage: setWebChatPredefinedMessage,
  } = useWebChat({
    hotelCode,
    booking: upcomingTrip,
    searchParams,
  });

  const isWebChatEnabled = enabled && isInitialised && webChatEnabled;

  const goBackHandler = () => {
    history.push(
      isFindReservations
        ? findReservationsPath.to({ locale, hotelCode, reservationId })
        : unauthenticatedBookingPath.to({ locale, hotelCode, reservationId })
    );
  };

  const goToEstimatedTotal = () => {
    history.push(
      isFindReservations
        ? findReservationsEstimatedTotalPath.to({
            locale,
            hotelCode,
            reservationId,
          })
        : unauthenticatedBookingEstimatedTotalPath.to({
            locale,
            hotelCode,
            reservationId,
          })
    );
  };

  const goToArrivalTransportationView = () => {
    const params = {
      locale,
      hotelCode,
      reservationId,
    };
    const pathname = isFindReservations
      ? findReservationsTransportationPath.to(params)
      : unauthenticatedBookingTransportationPath.to(params);
    history.push({
      pathname,
      state: {
        isOnItineraryPage: window.location.pathname.includes("/itinerary"),
      },
    });
  };

  const goToAmenityRequests = () => {
    const params = {
      locale,
      hotelCode,
      reservationId,
    };
    const pathname = isFindReservations
      ? findReservationsWelcomeAmenitiesPath.to(params)
      : unauthenticatedBookingWelcomeAmenitiesPath.to(params);
    history.push({
      pathname,
      state: {
        isOnItineraryPage: window.location.pathname.includes("/itinerary"),
      },
    });
  };

  const itineraryPath = unauthenticatedBookingItineraryPath.to({
    hotelCode,
    reservationId,
    locale,
  });

  const goToItinerary = () => {
    history.push(itineraryPath);
  };

  const reservationHighlights =
    highlightsMap && getReservationHighlightsArray(highlightsMap);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.key]);

  useEffect(() => {
    if (hasErrors) {
      window.scrollTo(0, 0);
    }
  }, [hasErrors]);

  if (showLoadingSpinner) {
    return <BookingFlowLoadingIndicator opaque />;
  }

  const rates = getRates({ upcomingTrip, taxes });

  const stayResortFees =
    upcomingTrip?.hotelProducts[0]?.price?.prepay?.stayResortFees;

  return (
    <Switch>
      <Route
        path={unauthenticatedBookingItineraryPath.path}
        exact
        render={(props) => (
          <ItineraryView
            {...props.match.params}
            highlightsMap={highlightsMap}
            bookingMessages={bookingMessages}
            taxes={taxes}
            goToArrivalTransportationView={
              canRequestTransportation &&
              !hasTransportation &&
              goToArrivalTransportationView
            }
            goToArrivalAmenitiesView={
              canRequestWelcomeAmenities && goToAmenityRequests
            }
            isWebChatEnabled={isWebChatEnabled}
            openWebChat={openWebChat}
            isUnauthenticated
          />
        )}
      />

      <Route
        path={unauthenticatedBookingUpgradeYourRoomPath.path}
        exact
        render={() => (
          <UpgradeYourRoomView
            hotelCode={hotelCode}
            reservationHighlights={reservationHighlights}
            reservationId={reservationId}
            upcomingTrip={upcomingTrip}
            phoneNumber={phoneNumber}
            isWebChatEnabled={isWebChatEnabled}
            openWebChat={openWebChat}
            setWebChatPredefinedMessage={setWebChatPredefinedMessage}
          />
        )}
      />

      <Route
        path={[
          unauthenticatedBookingTransportationPath.path,
          findReservationsTransportationPath.path,
        ]}
        exact
        render={(props) => {
          if (!canRequestTransportation) {
            return (
              <Redirect
                to={
                  isFindReservations
                    ? findReservationsPath.to({
                        locale,
                        hotelCode,
                        reservationId,
                      })
                    : unauthenticatedBookingPath.to({
                        locale,
                        hotelCode,
                        reservationId,
                      })
                }
              />
            );
          }
          const isOnItineraryPage = getOr(false, [
            "location",
            "state",
            "isOnItineraryPage",
          ])(props);
          return (
            <ArrivalTransportation
              goBack={isOnItineraryPage ? goToItinerary : goBackHandler}
              booking={upcomingTrip}
              itineraryPath={itineraryPath}
              availableArrivalTransportations={availableArrivalTransportations}
              isOnItineraryPage={isOnItineraryPage}
            />
          );
        }}
      />

      <Route
        path={[
          unauthenticatedBookingWelcomeAmenitiesPath.path,
          findReservationsWelcomeAmenitiesPath.path,
        ]}
        exact
        render={(props) => {
          if (!canRequestWelcomeAmenities) {
            return (
              <Redirect
                to={
                  isFindReservations
                    ? findReservationsPath.to({
                        locale,
                        hotelCode,
                        reservationId,
                      })
                    : unauthenticatedBookingPath.to({
                        locale,
                        hotelCode,
                        reservationId,
                      })
                }
              />
            );
          }
          const isOnItineraryPage = getOr(false, [
            "location",
            "state",
            "isOnItineraryPage",
          ])(props);
          return (
            <ArrivalAmenities
              goBack={isOnItineraryPage ? goToItinerary : goBackHandler}
              booking={upcomingTrip}
              itineraryPath={itineraryPath}
              availableWelcomeAmenities={availableWelcomeAmenities}
              goBackButtonLabel={
                isOnItineraryPage ? t("Back to itinerary") : t("Back to trip")
              }
              isOnItineraryPage={isOnItineraryPage}
            />
          );
        }}
      />

      <Route
        path={[
          unauthenticatedBookingEstimatedTotalPath.path,
          findReservationsEstimatedTotalPath.path,
        ]}
        exact
        render={() => (
          <EstimatedTotal
            goBackHandler={goBackHandler}
            highlights={highlightsMap}
            rates={rates}
            stayResortFees={stayResortFees}
            estimatedTotalDisclaimer={propertyContent?.estimatedTotalDisclaimer}
            bookingMessages={bookingMessages}
          />
        )}
      />

      <Route
        path={[unauthenticatedBookingPath.path, findReservationsPath.path]}
        exact
        render={() => (
          <UpcomingTrip
            phoneNumberPrimary={phoneNumberPrimary}
            bookingConfig={bookingConfig}
            allowCancellation={allowCancellation}
            canChangeBooking={canChangeBooking}
            changeArrivalTime={changeArrivalTime}
            comment={comment}
            employeeRate={employeeRate}
            goToEstimatedTotal={priceViewable && goToEstimatedTotal}
            hasErrors={hasErrors}
            hotelCode={hotelCode}
            phoneNumber={phoneNumber}
            propertyContent={propertyContent}
            reservationHighlights={reservationHighlights}
            reservationId={reservationId}
            upcomingTrip={upcomingTrip}
            updateAdditionalRequests={updateAdditionalRequests}
            updateMostImportantThing={updateMostImportantThing}
            updateRoomGuests={updateRoomGuests}
            updateTripPurpose={updateTripPurpose}
            youCanBookMessage={youCanBookMessage}
            showSavingSpinner={showSavingSpinner}
            isTrimmed
            //
            // WebChat
            isWebChatEnabled={isWebChatEnabled}
            openWebChat={openWebChat}
            //
            // Welcome Amentities
            canRequestWelcomeAmenities={canRequestWelcomeAmenities}
            goToAmenityRequests={goToAmenityRequests}
            //
            // Arrival Transportation
            canRequestTransportation={canRequestTransportation}
            goToArrivalTransportationView={goToArrivalTransportationView}
            arrivalTransportation={arrivalTransportation}
            hasTransportation={hasTransportation}
            isUnauthenticated
          />
        )}
      />

      <Route>
        <Redirect
          to={
            isFindReservations
              ? findReservationsPath.to({
                  locale,
                  hotelCode,
                  reservationId,
                })
              : unauthenticatedBookingPath.to({
                  locale,
                  hotelCode,
                  reservationId,
                })
          }
        />
      </Route>
    </Switch>
  );
}
