import React from "react";

export default function AddIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 29.25C22.8701 29.25 29.25 22.8701 29.25 15C29.25 7.12994 22.8701 0.75 15 0.75C7.12994 0.75 0.749999 7.12994 0.749999 15C0.749999 22.8701 7.12994 29.25 15 29.25ZM15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30ZM15.7597 14.4452H19.0297V15.4652H15.7597V18.7502H14.7397V15.4652H11.4697V14.4452H14.7397V11.1602H15.7597V14.4452Z"
        fill="black"
      />
      <mask
        id="mask0_3905_2055"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="30"
        height="30"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 29.25C22.8701 29.25 29.25 22.8701 29.25 15C29.25 7.12994 22.8701 0.75 15 0.75C7.12994 0.75 0.749999 7.12994 0.749999 15C0.749999 22.8701 7.12994 29.25 15 29.25ZM15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30ZM15.7597 14.4452H19.0297V15.4652H15.7597V18.7502H14.7397V15.4652H11.4697V14.4452H14.7397V11.1602H15.7597V14.4452Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3905_2055)" />
    </svg>
  );
}
