import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { useTranslation } from "hooks";

function LinkCard({ icon, title, description }) {
  return (
    <>
      <div className="quick-link__icon">
        <img src={icon} alt={title} />
      </div>
      <div className="quick-link__content">
        <div className="quick-link__content__title ty-c3">{title}</div>
        <div className="quick-link__content__description">{description}</div>
      </div>
    </>
  );
}

function QuickLinks({ links = [], className }) {
  const { t } = useTranslation();
  return (
    <div className={classNames("quick-links", className)}>
      <h3 className="quick-links__title ty-h3">
        {t("For your peace of mind")}
      </h3>
      <div className="quick-links__links">
        {links.map(
          ({ to, href, icon, title, description, onClick = () => {} }) => {
            if (href) {
              return (
                <a
                  key={href}
                  href={href}
                  className="quick-link"
                  onClick={onClick}
                >
                  <LinkCard
                    icon={icon}
                    title={title}
                    description={description}
                  />
                </a>
              );
            }

            return (
              <Link key={href} to={to} className="quick-link" onClick={onClick}>
                <LinkCard icon={icon} title={title} description={description} />
              </Link>
            );
          }
        )}
      </div>
    </div>
  );
}

export default QuickLinks;
