import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import get from "lodash/get";

import { useTranslation, useDispatchWithLocale } from "hooks";
import useWebChat from "hooks/useWebChat";
import { MediaContext } from "contexts/MediaContext";
import * as profileRoutes from "Profile/profileRoutes";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import ThemeContextProvider, { THEMES } from "contexts/ThemeContext";
import { selectProfile } from "store/profile";
import {
  fetchPropertyContent,
  fetchPropertyContentCancel,
  selectPropertyContentByOrs,
} from "store/propertyContent";
import { isWebChatEnabled } from "utils/utils";
import { selectMemberDetails } from "store/memberDetails";
import MembershipViewHeader from "./MembershipViewHeader";
import MembershipBenefits from "./MembershipBenefits";
import DownloadAppCTA from "./DownloadAppCTA";

export default function MembershipView({ hotelCode, membershipId, tierId }) {
  const { t, locale, i18n } = useTranslation();
  const media = useContext(MediaContext);
  const dispatchWithLocale = useDispatchWithLocale();

  const memberDetails = useSelector(selectMemberDetails);

  const selectedMembership = memberDetails.Memberships.find(
    (membership) =>
      membership.hotelCode === hotelCode &&
      membership.membershipId === membershipId &&
      membership.membershipTierId === tierId
  );

  const propertyContent = useSelector(selectPropertyContentByOrs(hotelCode));
  const hotelOwsCode = propertyContent?.owsCode;

  const profile = useSelector(selectProfile);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!hotelOwsCode) {
      dispatchWithLocale(fetchPropertyContent({ propertyCode: hotelCode }));
    }

    return () => {
      if (!hotelOwsCode) {
        dispatchWithLocale(fetchPropertyContentCancel());
      }
    };
  }, [hotelCode, hotelOwsCode, dispatchWithLocale]);

  const hasAllRequiredData = useSelector((state) => {
    return [
      get(state, ["propertyContent"]),
      get(state, ["memberDetails"]),
    ].every(Boolean);
  });

  const webChatEnabled =
    isWebChatEnabled(propertyContent) && !media.isMobileApp;

  const { isInitialised, openWebChat } = useWebChat({
    hotelOwsCode,
  });

  const membershipBenefitsTitle = t("{0} Membership Benefits", [
    selectedMembership.Content.name,
  ]);

  if (!hasAllRequiredData) {
    return <BookingFlowLoadingIndicator opaque />;
  }

  return (
    <ThemeContextProvider theme={THEMES.LIGHT}>
      <div
        className={`layout--profile ${i18n.language} ${i18n.dir(
          i18n.language
        )}`}
      >
        <div className="layout--profile-fullscreen layout--profile-fullscreen--light view--profile-membership">
          <div className="layout--profile-fullscreen__top-bar">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <Link
                    className="btn-back"
                    to={profileRoutes.profilePath.to({
                      locale,
                    })}
                  >
                    <span>{t("Back")}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="layout--profile-fullscreen__body">
            <MembershipViewHeader
              membership={selectedMembership}
              profile={profile}
              propertyContent={propertyContent}
              isWebChatEnabled={webChatEnabled}
              isInitialised={isInitialised}
              startSmooch={openWebChat}
            />
            <div className="fs-membership-benefits">
              <div className="container">
                <div className="row">
                  <div className="fs-membership-benefits-heading">
                    <div className="col-lg-9 offset-lg-1">
                      {membershipBenefitsTitle}
                    </div>
                  </div>
                </div>
              </div>

              <MembershipBenefits
                label="Discounts"
                icon="discounts"
                initialIsOpen
                items={selectedMembership.Content.discounts}
              />

              <MembershipBenefits
                label="Vouchers"
                icon="vouchers"
                items={selectedMembership.Content.vouchers}
              />
            </div>

            <DownloadAppCTA />
          </div>
        </div>
      </div>
    </ThemeContextProvider>
  );
}
