import React from "react";
import classNames from "classnames";
import { Controller } from "react-hook-form";

import { useTranslation } from "hooks";
import { DictionaryKeyContent } from "BookingFlow/components";
import * as regexPatterns from "utils/regexPatterns";
import { getPPReservationServicesFormLink } from "utils/externalLinks";
import ArrivalTimeInput from "./ArrivalTimeInput";

export default function YourArrival({
  dictionaryKeys,
  register,
  control,
  errors = {},
  isBookingCancelled,
  isPreferredPartners,
}) {
  const { t } = useTranslation();

  return (
    <fieldset>
      <legend>
        <DictionaryKeyContent dict={dictionaryKeys.yourArrival} />
      </legend>

      <Controller
        name="arrivalTime"
        control={control}
        as={ArrivalTimeInput}
        disabled={isBookingCancelled}
      />

      <div
        className={classNames("form-row textarea", {
          "has-errors": errors.tripPurpose,
        })}
        style={{ overflow: "visible" }}
      >
        <label htmlFor="reason-for-stay">{t("Reason for Stay")}</label>
        <textarea
          id="reason-for-stay"
          name="tripPurpose"
          placeholder={isPreferredPartners ? "" : t("Reason for Stay")}
          ref={register({
            pattern: regexPatterns.nonDoubleByte,
            maxLength: 200,
          })}
          disabled={isBookingCancelled}
          style={{ float: "none", display: "block" }}
        />

        {errors.tripPurpose && (
          <div className="inline-error">
            <span className="message">
              {t("Please enter a valid response.")}
            </span>
            <span className="icon">&#33;</span>
          </div>
        )}
      </div>

      {isPreferredPartners && (
        <div
          className={classNames(
            "form-row textarea addition-requests-textbox-small-label",
            {
              "has-errors": errors.additionalRequests,
              pp: isPreferredPartners,
            }
          )}
        >
          <span
            htmlFor="additional-requests"
            dangerouslySetInnerHTML={{
              __html: t(
                'Please indicate any additional requests or complete the <a href="{0}" target="_blank">reservations services form</a> to provide us with more specific details about your client’s visit.',
                [getPPReservationServicesFormLink()]
              ),
            }}
          />
        </div>
      )}
      <div
        className={classNames("form-row textarea addition-requests-textbox", {
          "has-errors": errors.additionalRequests,
          pp: isPreferredPartners,
        })}
      >
        {isPreferredPartners ? (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label
            htmlFor="additional-requests"
            dangerouslySetInnerHTML={{
              __html: t(
                'Please indicate any additional requests or complete the <a href="{0}" target="_blank">reservations services form</a> to provide us with more specific details about your client’s visit.',
                [getPPReservationServicesFormLink()]
              ),
            }}
          />
        ) : (
          <label htmlFor="additional-requests">
            {t("Any additional requests?")}
          </label>
        )}
        <textarea
          id="additional-requests"
          name="additionalRequests"
          placeholder={isPreferredPartners ? "" : t("Additional Requests")}
          ref={register({
            pattern: regexPatterns.nonDoubleByte,
            maxLength: 200,
          })}
          disabled={isBookingCancelled}
        />

        {errors.additionalRequests && (
          <div className="inline-error">
            <span className="message">
              {t("Please enter a valid response.")}
            </span>
            <span className="icon">&#33;</span>
          </div>
        )}
      </div>
    </fieldset>
  );
}
