import {
  updateEmailSubscriptions,
  updateEmailSubscriptionsFulfilled,
} from "store/profile/profile.slice";
import { prepareMailSubscriptionList } from "../profile.helpers";
import { updateProfileTemplate } from "./updateProfile";

export default updateProfileTemplate({
  actionType: updateEmailSubscriptions.type,
  fulfillAction: updateEmailSubscriptionsFulfilled,
  updateFn: ({ extension = {} } = {}, newValue) => ({
    extension: {
      ...extension,
      mailSubscriptionList: prepareMailSubscriptionList(
        extension?.mailSubscriptionList,
        newValue
      ),
    },
  }),
});
