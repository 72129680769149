import React, { useState } from "react";

import { useTranslation, useDispatchWithLocale } from "hooks";
import { Button } from "Profile/components";
import ThemeContextProvider, { THEMES } from "contexts/ThemeContext";
import { requestInvoiceClear } from "store/bookings";
import RequestInvoiceModal from "./RequestInvoiceForm";
import Modal from "./Modal";

function InvoiceRequest() {
  const { t } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const close = () => {
    dispatchWithLocale(requestInvoiceClear());
    setModalIsOpen(false);
  };

  return (
    <ThemeContextProvider theme={THEMES.DARK}>
      <div id="request-an-invoice" className="view--profile__invoice-request">
        <div className="container">
          <div className="fs-invoice-request">
            <div className="fs-invoice-request__holder">
              <div className="fs-invoice-request__copy">
                <div className="fs-invoice-request__title">
                  <h3>{t("Request an Invoice")}</h3>
                </div>
                <div className="fs-invoice-request__body">
                  <p>
                    {t(
                      "We are happy to assist you with instructions for receiving your hotel bill."
                    )}
                  </p>
                </div>
              </div>
              <Button onClick={() => setModalIsOpen(true)}>
                {t("Request an Invoice")}
              </Button>
              {modalIsOpen && (
                <Modal close={close} theme={THEMES.LIGHT}>
                  <RequestInvoiceModal close={close} />
                </Modal>
              )}
            </div>
          </div>
        </div>
      </div>
    </ThemeContextProvider>
  );
}

export default InvoiceRequest;
