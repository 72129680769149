import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "unorm";
import React from "react";
import ReactDOM from "react-dom";
import smoothscroll from "smoothscroll-polyfill";

import { isSafari, isIOS } from "utils";
import Providers from "components/Providers";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// kick off the polyfill!
if (isSafari() || isIOS()) {
  // eslint-disable-next-line no-underscore-dangle
  window.__forceSmoothScrollPolyfill__ = true;
}
smoothscroll.polyfill();

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
