import React from "react";
import classNames from "classnames";
import partition from "lodash/partition";

import { useTranslation } from "hooks";
import { ToggleBlock } from "Profile/components";
import { SignInModal } from "Authentication";
import GuestForm from "./GuestForm";

const guestIsAdult = ({ type }) => type === 1;

function Guests({
  allowCancellation,
  guests: { bookingHolder, others },
  onSubmit,
  showSavingSpinner,
  isUnauthenticated,
}) {
  const { t } = useTranslation();

  const othersWithGuestIndexes = partition(others, guestIsAdult).flatMap((a) =>
    a.map((b, index) => ({
      ...b,
      guestIndex: guestIsAdult(b) ? index + 2 : index + 1,
    }))
  );

  return (
    <div className="fs-trip__guests">
      <h2>{t("Guests")}</h2>
      <h3 className="fs-trip__guests__reservation-holder">
        {t("Reservation Holder")}
      </h3>
      <p className="fs-trip__guests__reservation-holder-name">
        {bookingHolder?.name}
      </p>
      <p className="fs-trip__guests__reservation-holder-email">
        {bookingHolder?.email}
      </p>
      <p className="fs-trip__guests__reservation-message">
        {t(
          "We’re sorry but at this time you can’t change the reservation holder’s information."
        )}
        <br />
        <span
          dangerouslySetInnerHTML={{
            __html: t(
              `Please call us via <a href='https://www.fourseasons.com/global_toll_free_telephone_numbers/'>your local toll-free number</a> to complete this request.`
            ),
          }}
        />
      </p>
      <div>
        {othersWithGuestIndexes.map(({ name, type, guestIndex }, index) => (
          <div key={`${name}:${index}`} className="fs-trip__guests__guest">
            {allowCancellation ? (
              <ToggleBlock
                label={({ toggle, isOpen, isOpening }) => (
                  <div className="fs-toggle-block__label">
                    <div className="fs-trip__guests__guest__label">
                      {`${
                        guestIsAdult({ type }) ? t("Adult") : t("Child")
                      } ${guestIndex}`}
                    </div>

                    {isUnauthenticated ? (
                      <button
                        className={classNames(
                          "fs-toggle-button--light fs-toggle-button-light--edit",
                          {
                            "fs-toggle-button--light-active":
                              isOpen || isOpening,
                          }
                        )}
                        onClick={toggle}
                        type="button"
                        aria-label="Edit guests name"
                      >
                        {name?.length > 0 ? t("Edit") : t("Add")}
                      </button>
                    ) : (
                      <SignInModal
                        id="editGuests"
                        onClick={toggle}
                        className={classNames(
                          "fs-toggle-button--light fs-toggle-button-light--edit",
                          {
                            "fs-toggle-button--light-active":
                              isOpen || isOpening,
                          }
                        )}
                        type="CustomButton"
                        buttonLabel={name?.length > 0 ? t("Edit") : t("Add")}
                        ariaLabel={t("Edit guests name")}
                      />
                    )}
                  </div>
                )}
              >
                {({ close }) => (
                  <GuestForm
                    name={name}
                    index={index}
                    roomGuests={others}
                    onSubmit={onSubmit}
                    showSavingSpinner={showSavingSpinner}
                    close={close}
                  />
                )}
              </ToggleBlock>
            ) : (
              <div className="fs-toggle-block__label">
                <div className="fs-trip__guests__guest__label">
                  {`${
                    guestIsAdult({ type }) ? t("Adult") : t("Child")
                  } ${guestIndex}`}
                </div>
              </div>
            )}

            {name?.length > 0 && (
              <div className="fs-trip__guests__guest__guest-name">
                <p className="fs-trip__guests__guest__guest-name__label">
                  {t("Guest Name")}
                </p>
                <p className="fs-trip__guests__guest__guest-name__value">
                  {name}
                </p>
              </div>
            )}

            {!allowCancellation && (
              <p className="fs-trip__guests__reservation-message">
                {t("We are unable to modify this reservation.")}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Guests;
