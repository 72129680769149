import React, { useContext, useState } from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";
import { MediaContext } from "contexts/MediaContext";
import FloorplanModal from "./FloorplanModal";

export default function RoomItemFeatures({
  bedOptions,
  bookableOnline,
  floorPlanImagePath,
  occupancy,
  roomName,
  size: roomSize,
  view,
}) {
  const { t } = useTranslation();
  const media = useContext(MediaContext);

  const [showFloorplanModal, setShowFloorplanModal] = useState(false);

  const openFloorplanModal = () => {
    setShowFloorplanModal(true);
    // sendAnalytics('floorplan_view', room, index)
  };

  return (
    <>
      {showFloorplanModal && (
        <FloorplanModal
          floorPlanImagePath={floorPlanImagePath}
          closeModal={() => setShowFloorplanModal(false)}
        />
      )}
      <div
        className={classNames("room-item-features", {
          "no-price": !bookableOnline,
        })}
      >
        {media.isMobileDevice ? (
          <div className="room-feature-list">
            <div className="col">
              <ul>
                {roomSize && (
                  <li>
                    <span className="label">{t("Room Size")}</span>
                    <span className="details">
                      <div className="shrink">{roomSize}</div>
                      {floorPlanImagePath && (
                        <button
                          type="button"
                          className="terms-button"
                          onClick={openFloorplanModal}
                          aria-label={`${t("View floorplan")} (${roomName})`}
                          aria-haspopup="dialog"
                          style={{
                            width: "auto",
                            minWidth: "auto",
                            paddingTop: 0,
                          }}
                        >
                          <span>{t("View floorplan")}</span>
                        </button>
                      )}
                    </span>
                  </li>
                )}

                {view && (
                  <li>
                    <span className="label">{t("View")}</span>
                    <span className="details">{view}</span>
                  </li>
                )}

                {occupancy && (
                  <li className="visible-showmore">
                    <span className="label">{t("Occupancy")}</span>
                    <span className="details">
                      <div className="occupancy">{occupancy}</div>
                    </span>
                  </li>
                )}

                {bedOptions && (
                  <li className="visible-showmore">
                    <span className="label">{t("Bed Options")}</span>
                    <span className="details">{bedOptions}</span>
                  </li>
                )}
              </ul>
            </div>
          </div>
        ) : (
          <div className="room-feature-list">
            <div className="col">
              <ul>
                {view && (
                  <li>
                    <span className="label">{t("View")}</span>
                    <div className="shrink">{view}</div>
                  </li>
                )}

                {bedOptions && (
                  <li>
                    <span className="label">{t("Bed Options")}</span>
                    <div className="shrink">{bedOptions}</div>
                  </li>
                )}
              </ul>
            </div>
            <div className="col">
              <ul>
                {roomSize && (
                  <li>
                    <span className="label">{t("Room Size")}</span>
                    <div className="shrink">{roomSize}</div>
                    {floorPlanImagePath && (
                      <button
                        type="button"
                        className="terms-button"
                        onClick={openFloorplanModal}
                        aria-label={`${t("View floorplan")} (${roomName})`}
                        aria-haspopup="dialog"
                        style={{
                          width: "auto",
                          minWidth: "auto",
                          paddingTop: 0,
                        }}
                      >
                        <span>{t("View floorplan")}</span>
                      </button>
                    )}
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
