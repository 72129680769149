import {
  FOOD_AND_DRINK,
  HEALTH_AND_WELLNESS,
  MATTRESS,
  PILLOW,
  ROOM,
  TRAVEL_AND_LIFESTYLE,
} from "fixtures/constants";

export const mattressPreferences = {
  title: MATTRESS,
  identifier: "field-preference-mattress",
  description:
    "The Four Seasons standard of luxury, designed for optimum comfort. May be customised to your preferred level of firmness.",
  options: [
    { label: "Signature mattress  (medium firmness)", value: "" },
    { label: "Pillowtop mattress - Firm", value: "FRM" },
    { label: "Pillowtop mattress - Plush", value: "SFT" },
  ],
};

export const pillowPreferences = {
  title: PILLOW,
  identifier: "field-preference-pillow",
  description:
    "Our standard of luxurious comfort, made with hypo-allergenic down.",
  options: [
    { label: "Pillows - Feather", value: "" },
    { label: "Pillows - Buckwheat", value: "PBW" },
    { label: "Pillows - Down Alternative", value: "PDA" },
    { label: "Pillows - Extra Large", value: "PXL" },
    { label: "Pillows - Foam", value: "FP" },
    { label: "Pillows - Hard", value: "PHA" },
    { label: "Pillows - Horsehair", value: "PHO" },
    { label: "Pillows - Memory Foam", value: "PMF" },
    { label: "Pillows - Orthopedic", value: "POR" },
    { label: "Pillows - Soft", value: "PSO" },
  ],
};

export const sleepPreferences = [mattressPreferences, pillowPreferences];

export const roomPreferences = {
  title: ROOM,
  options: [
    { value: "", label: "bk42.smokingroom.no" },
    { value: "S", label: "bk42.smokingroom.yes" },
  ],
};

export const foodAndDrinkPreferences = {
  title: FOOD_AND_DRINK,
  options: [
    { value: "RNB", label: "Restaurants & Bars" },
    { value: "WIE", label: "Wine" },
    { value: "MIX", label: "Mixology" },
    { value: "BRU", label: "Brunch" },
    { value: "CCS", label: "Cooking Classes" },
    { value: "FTT", label: "Farm to Table" },
    { value: "LSP", label: "Local Specialities" },
    { value: "BSC", label: "Behind the Scenes with Chef" },
  ],
};

export const healthAndWellnessPreferences = {
  title: HEALTH_AND_WELLNESS,
  options: [
    { value: "FIS", label: "Fitness" },
    { value: "SP1", label: "Spa" },
    { value: "YOG", label: "Yoga" },
    { value: "NEX", label: "Nature Excursions" },
    { value: "SKG", label: "Skiing" },
    { value: "GFG", label: "Golfing" },
    { value: "DVG", label: "Diving" },
    { value: "SFG", label: "Surfing" },
    { value: "OTS", label: "Other Water Sports" },
    { value: "HBR", label: "Horseback Riding" },
    { value: "SPD", label: "Spiritual Discovery" },
    { value: "MAM", label: "Meditation and Mindfulness" },
  ],
};

export const travelAndLifestylePreferences = {
  title: TRAVEL_AND_LIFESTYLE,
  options: [
    { value: "CGY", label: "Couples Getaway" },
    { value: "FGY", label: "Family Getaway" },
    { value: "FRG", label: "Friends Getaway" },
    { value: "SOT", label: "Solo Travel" },
    { value: "BVA", label: "Beach Vacation" },
    { value: "VEL", label: "Vacation Rental" },
    { value: "CYE", label: "City Escape" },
    { value: "ADT", label: "Adventure Travel" },
    { value: "NFS", label: "New Four Seasons Hotels and Resorts" },
    { value: "PJT", label: "Private Jet Travel" },
    { value: "YHT", label: "Luxury Yachting" },
    { value: "DRV", label: "Driving Experiences" },
    { value: "ART", label: "Art and Culture" },
    { value: "SHO", label: "Shopping" },
    { value: "SPS", label: "Sporting Events" },
    { value: "CUT", label: "Culinary Travel" },
  ],
};

const flattenToOptionCodes = (preferencesList) =>
  preferencesList
    .flatMap(({ options }) => options)
    .map(({ value }) => value)
    .filter(Boolean);

export const yourInterestsOptions = flattenToOptionCodes([
  foodAndDrinkPreferences,
  healthAndWellnessPreferences,
  travelAndLifestylePreferences,
]);

export const otherPreferencesOptions = flattenToOptionCodes([
  mattressPreferences,
  pillowPreferences,
  roomPreferences,
]);
