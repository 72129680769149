/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { stringifyObject } from "utils/utils";

const initialState = {
  data: {},
  error: "",
};

const offersTermsAndConditionsSlice = createSlice({
  name: "offersTermsAndConditionsContent",
  initialState,
  reducers: {
    fetchOffersTermsAndConditionsContent(state) {
      state.error = "";
    },

    fetchOffersTermsAndConditionsContentFulfilled(state, { payload }) {
      state.error = "";
      state.data = payload.reduce((acc, curr) => {
        const { offersTermsAndConditionsContent, ...options } = curr;
        return {
          ...acc,
          [stringifyObject(options)]: offersTermsAndConditionsContent,
        };
      }, state.data);
    },

    fetchOffersTermsAndConditionsContentFailed(state, { payload }) {
      state.data = {};
      state.error = payload.error;
    },

    fetchOffersTermsAndConditionsContentCancel() {},

    clearOffersTermsAndConditionsContent() {
      return initialState;
    },
  },
});

export const {
  fetchOffersTermsAndConditionsContent,
  fetchOffersTermsAndConditionsContentCancel,
  fetchOffersTermsAndConditionsContentFailed,
  fetchOffersTermsAndConditionsContentFulfilled,
  clearOffersTermsAndConditionsContent,
} = offersTermsAndConditionsSlice.actions;

export default offersTermsAndConditionsSlice.reducer;
