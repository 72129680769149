import {
  updateUserAddress,
  updateUserAddressFulfilled,
} from "store/profile/profile.slice";
import { prepareAddresses } from "../profile.helpers";
import { updateProfileTemplate } from "./updateProfile";

export default updateProfileTemplate({
  actionType: updateUserAddress.type,
  fulfillAction: updateUserAddressFulfilled,
  updateFn: ({ address = [] } = {}, newValue) => ({
    address: prepareAddresses(address, newValue),
  }),
});
