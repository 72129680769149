import React, { useContext } from "react";

import GuestDetails from "./GuestDetails";
import PaymentMethod from "./PaymentMethod";
import TermsAndConditions from "./TermsAndConditions";
import EmployeeTermsAndConditions from "./EmployeeTermsAndConditions";
import MappingLayerContext from "../../mappingLayerContext";

export default function BookingForYourself({
  register,
  control,
  errors,
  allowAlipay,
  paymentMethod,
  arePaymentOptionsLoading,
  readOnlyFields,
  hasExplicitOptIn,
  showEmployeeTerms,
  persistentValues,
}) {
  const { suppressPaymentOptions } = useContext(MappingLayerContext);

  return (
    <>
      <GuestDetails
        register={register}
        control={control}
        errors={errors.guestDetails || {}}
        readOnlyFields={readOnlyFields.guestDetails || []}
        persistentValues={persistentValues}
      />
      {!suppressPaymentOptions && !arePaymentOptionsLoading && (
        <PaymentMethod
          register={register}
          control={control}
          errors={errors}
          allowAlipay={allowAlipay}
          paymentMethod={paymentMethod}
          readOnlyFields={readOnlyFields}
        />
      )}
      {showEmployeeTerms ? (
        <EmployeeTermsAndConditions
          register={register}
          errors={errors.termsAndConditions || {}}
          readOnlyFields={readOnlyFields.termsAndConditions || []}
        />
      ) : (
        <TermsAndConditions
          register={register}
          errors={errors.termsAndConditions || {}}
          readOnlyFields={readOnlyFields.termsAndConditions || []}
          hasExplicitOptIn={hasExplicitOptIn}
          showOptInAgreement
        />
      )}
    </>
  );
}
