import React from "react";

import env from "config/env";
import { useTranslation } from "hooks";
import ThemeContextProvider, { THEMES } from "contexts/ThemeContext";
import { SignInModal } from "Authentication";

const { RESIDENTIAL_APP_URL } = env;

function YourResidence() {
  const { t } = useTranslation();

  return (
    <ThemeContextProvider theme={THEMES.DARK}>
      <div id="your-residence" className="view--profile__your-residences">
        <div className="view--profile__your-residences__inner">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h2>{t("Your Residence")}</h2>
                <p>
                  {t(
                    "Enter your residence dashboard to easily request service, book facilities and manage your home."
                  )}
                </p>
              </div>
              <div className="col-md-4">
                <SignInModal
                  id="residences"
                  afterSignInRedirectTo={RESIDENTIAL_APP_URL}
                  showToastOnNextView
                  href={RESIDENTIAL_APP_URL}
                  type="Link"
                  kind="secondary"
                  buttonLabel={t("Go to your residence")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeContextProvider>
  );
}

export default YourResidence;
