import { getSearchFormValuesFromQueryString } from "BookingFlow/utils";
import { convertBookingCodesToArrays } from "utils/utils";

export default function canDeepLinkToConfirmYourStayByResultSet(search) {
  const {
    hotelCode,
    dates: { checkIn, checkOut },
    rooms,
    resultIds,
    resultSetId,
  } = convertBookingCodesToArrays(
    getSearchFormValuesFromQueryString(search, new Date())
  );

  return (
    [hotelCode, checkIn, checkOut, rooms, resultIds, resultSetId].every(
      Boolean
    ) &&
    resultIds.length > 0 &&
    resultIds.length === rooms.length
  );
}
