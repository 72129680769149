import { concatMap, map, switchMap } from "rxjs/operators";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { createHotelSearch$, getHotelSearchResults$ } from "api/tretail/hotel";
import getNearestAvailableResultSetId from "./getNearestAvailableResultSetId";

export default function getSearchResults$({
  isAllInPricing = false,
  searchParams,
  locale,
}) {
  return ajaxWithHealthCheck$({
    locale,
    propertyCode: searchParams.hotelCode,
    searchParams,
  }).pipe(
    switchMap(() => {
      return createHotelSearch$({
        isAllInPricing,
        searchParams,
        locale,
      }).pipe(
        concatMap((createHotelSearchResponse) => {
          const {
            hotelSearchResultSets = [],
            supplierErrors,
            apiErrors,
          } = createHotelSearchResponse;

          if (supplierErrors || apiErrors) {
            // eslint-disable-next-line no-throw-literal
            throw { response: { apiErrors, supplierErrors } };
          }

          const resultSetId = getNearestAvailableResultSetId(
            searchParams.dates.checkIn
          )(hotelSearchResultSets);

          if (!resultSetId) {
            // eslint-disable-next-line no-throw-literal
            throw { response: { supplierErrors: [{ errorCode: "E0301" }] } };
          }

          return getHotelSearchResults$({
            resultSetId,
            locale,
          }).pipe(
            map((getHotelSearchResultsResponse) => {
              return {
                createHotelSearchResponse,
                getHotelSearchResultsResponse,
              };
            })
          );
        })
      );
    })
  );
}
