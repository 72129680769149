import { ofType } from "redux-observable";
import { catchError, mergeMap, switchMap, takeUntil } from "rxjs/operators";
import { of } from "rxjs";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { verifyEmailCode$ } from "api/tretail/profile";
import {
  fetchProfile,
  verifyEmailCode,
  verifyEmailCodeCancel,
  verifyEmailCodeFailed,
  verifyEmailCodeFulfilled,
} from "store/profile/profile.slice";
import catchInternalServerError from "store/catchInternalServerError";

export default (action$) =>
  action$.pipe(
    ofType(verifyEmailCode.type),

    switchMap(({ payload: { locale, ...body } }) => {
      return ajaxWithHealthCheck$({
        locale,
      }).pipe(
        switchMap(() => {
          return verifyEmailCode$({ body, locale }).pipe(
            mergeMap(() => [
              fetchProfile({ locale }),
              verifyEmailCodeFulfilled(),
            ]),

            catchInternalServerError(),

            catchError(({ response = {} }) =>
              of(
                verifyEmailCodeFailed({
                  apiErrors: response?.apiErrors || [],
                  supplierErrors: response?.supplierErrors || [],
                })
              )
            ),

            takeUntil(action$.pipe(ofType(verifyEmailCodeCancel.type)))
          );
        })
      );
    })
  );
