import { useContext } from "react";
import { get } from "lodash/fp";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "hooks";
import { MediaContext } from "contexts/MediaContext";

import { requestCtaClicked } from "store/analyticEvents";

import {
  selectTravelArrangements,
  selectWelcomeAmentities,
} from "store/transportationAmenitiesContent";
import {
  selectCartExperiencesRecommendations,
  selectCartDiningRecommendations,
  selectCartSpaRecommendations,
} from "store/cartRecommendations";
import { selectGuestRequests } from "store/guestRequests";

import { SignInModal } from "Authentication";
import getAncillaryImages from "./getAncillaryImages";
import defaultCardImages from "./defaultCardImages";

export default function useAncillaryCardsData({
  hotelCode,
  reservationId,
  upcomingTrip,
  transportationEnabled,
  goToArrivalTransportationView,
  amenitiesEnable,
  goToAmenityRequests,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currencyCode = get(["price", "total", "cash", "currencyCode"])(
    upcomingTrip
  );

  const availableArrivalTransportations = useSelector(
    selectTravelArrangements({ reservationId, currencyCode })
  );
  const availableWelcomeAmenities = useSelector(
    selectWelcomeAmentities({ reservationId, currencyCode })
  );
  const cartSpaRecommendations = useSelector(selectCartSpaRecommendations);
  const cartExperiencesRecommendations = useSelector(
    selectCartExperiencesRecommendations
  );
  const cartDiningRecommendations = useSelector(
    selectCartDiningRecommendations
  );

  const guestRequests = useSelector(selectGuestRequests({ reservationId }));

  const hasAmenity = guestRequests.some(
    ({ masterRequestType }) => masterRequestType === "Amenity"
  );

  const media = useContext(MediaContext);
  const isMobile = media.isLessThan.sm;

  const ancillaryCardsData = [
    transportationEnabled && {
      id: "arrival-transportation",
      as: SignInModal,
      type: "CustomButton",
      ariaLabel: t("Arrival Transportation Request"),
      title: t("Arrival Transportation"),
      subtitle: t("Request"),
      img: (() => {
        const { portrait, landscape } =
          getAncillaryImages(availableArrivalTransportations) ||
          defaultCardImages.transportation;
        return !amenitiesEnable && isMobile ? portrait : landscape;
      })(),
      onClick: () => {
        dispatch(
          requestCtaClicked({
            kind: "arrival transportation",
            hotelCode,
          })
        );
        goToArrivalTransportationView();
      },
    },

    amenitiesEnable && {
      id: "arrival-amenities",
      as: SignInModal,
      type: "CustomButton",
      ariaLabel: t("Arrival Amenities Request"),
      title: t("Arrival Amenities"),
      subtitle: hasAmenity ? t("Add more items") : t("Request"),
      img: (() => {
        const { portrait, landscape } =
          getAncillaryImages(availableWelcomeAmenities) ||
          defaultCardImages.amenities;
        return isMobile ? portrait : landscape;
      })(),
      onClick: () => {
        dispatch(
          requestCtaClicked({
            kind: "arrival amenities",
            hotelCode,
          })
        );
        goToAmenityRequests();
      },
    },

    {
      id: "experiences",
      title: t("Experiences"),
      subtitle: t("Explore"),
      href: cartExperiencesRecommendations.landingPageUrl,
      img: (() => {
        const { portrait, landscape } =
          getAncillaryImages(cartExperiencesRecommendations) ||
          defaultCardImages.experiences;
        if (isMobile) {
          return !transportationEnabled && !amenitiesEnable
            ? landscape
            : portrait;
        }
        return (!transportationEnabled ? amenitiesEnable : !amenitiesEnable)
          ? landscape
          : portrait;
      })(),
    },

    {
      id: "dining",
      title: t("Dining"),
      subtitle: t("Explore"),
      href: cartDiningRecommendations.landingPageUrl,
      img: (() => {
        const { portrait, landscape } =
          getAncillaryImages(cartDiningRecommendations) ||
          defaultCardImages.dining;
        return (!transportationEnabled ? amenitiesEnable : !amenitiesEnable) &&
          !isMobile
          ? landscape
          : portrait;
      })(),
    },

    {
      id: "spa",
      title: t("Spa"),
      subtitle: t("Explore"),
      href: cartSpaRecommendations.landingPageUrl,
      img: (() => {
        const { portrait, landscape } =
          getAncillaryImages(cartSpaRecommendations) || defaultCardImages.spa;
        return (!transportationEnabled ? amenitiesEnable : !amenitiesEnable) &&
          !isMobile
          ? landscape
          : portrait;
      })(),
    },
  ].filter(Boolean);

  return { ancillaryCardsData };
}
