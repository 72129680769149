/* eslint-disable camelcase */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import useProperties from "hooks/useProperties";
import { selectProperty } from "store/properties";
import {
  fetchPropertyContent,
  selectPropertyContent,
} from "store/propertyContent";
import { selectAreSomeRequestsLoading } from "store/apiRequestStates";
import {
  fetchBooking,
  selectBooking,
  selectHasFetchBookingErrors,
  selectReservationIsPast,
  clearErrors as clearWebCheckInErrors,
  selectValidTimeToCheckIn,
  confirmCheckIn,
  selectConfirmCheckInError,
  selectConfirmedCheckIn,
  clearFetchBooking,
  selectPropertyCheckInTimeUTC,
  webCheckInStartEvt,
  webCheckInErrorEvt,
  webCheckInRegistrationEvt,
} from "store/webCheckIn";
import { useDispatchWithLocale, useTranslation } from "hooks";
import { scrollToTop } from "utils/utils";
import env from "config/env";
import {
  fetchPropertyWebCheckin,
  selectPropertyWebCheckin,
} from "store/propertyWebCheckin";
import { selectIsUserPersistent } from "store/profile";

const { STATIC_SITE_URL } = env;

export default function useWebCheckIn() {
  const location = useLocation();
  const dispatchWithLocale = useDispatchWithLocale();
  const { locale } = useTranslation();
  const isUserPersistent = useSelector(selectIsUserPersistent);

  const [reservationDetails, setReservationDetails] = useState({
    hotelCode: "",
    confirmation: "",
    lastName: "",
  });
  const { hotelCode, confirmation, lastName, email } = reservationDetails;

  const { confirmationNumber: qsConfirmationNumber, hotelCode: qsHotelCode } =
    queryString.parse(location.search || "");

  // content
  const { properties, isLoading: propertiesLoading } = useProperties();
  const validQsProperty = !!useSelector(selectProperty(qsHotelCode));
  const propertyContent = useSelector(selectProperty(hotelCode));
  const detailedPropertyContent = useSelector(selectPropertyContent(hotelCode));
  const propertyWebCheckin =
    useSelector(selectPropertyWebCheckin({ propertyCode: hotelCode })) || {};

  const loadingData = useSelector(
    selectAreSomeRequestsLoading([
      fetchBooking.type,
      confirmCheckIn.type,
      fetchPropertyWebCheckin.type,
      fetchPropertyContent.type,
    ])
  );

  // booking / confirmation
  const hasFetchBookingError = useSelector(selectHasFetchBookingErrors);
  const pastReservation = useSelector(selectReservationIsPast);
  const checkInError = useSelector(selectConfirmCheckInError);
  const confirmedCheckIn = useSelector(selectConfirmedCheckIn);
  const canCheckIn = useSelector(selectValidTimeToCheckIn);
  const booking = useSelector(selectBooking);
  const propertyCheckInTimeUTC = useSelector(selectPropertyCheckInTimeUTC);

  const [partialDataProvided, setPartialDataProvided] = useState(false);

  useEffect(() => {
    if (properties.length) {
      // if we've got a confirmation number and valid hotel code, use them
      if (qsConfirmationNumber && validQsProperty) {
        setReservationDetails({
          hotelCode: qsHotelCode,
          confirmation: qsConfirmationNumber,
        });
        setPartialDataProvided(true);
      }
    }
  }, [qsConfirmationNumber, qsHotelCode, properties.length]);

  useEffect(() => {
    // need to fetch property data
    if (hotelCode) {
      dispatchWithLocale(
        fetchPropertyContent({ locale, propertyCode: hotelCode })
      );
      dispatchWithLocale(
        fetchPropertyWebCheckin({ locale, propertyCode: hotelCode })
      );
    }
  }, [hotelCode]);

  useEffect(() => {
    // need to fetch booking
    if (hotelCode && confirmation && lastName) {
      dispatchWithLocale(
        fetchBooking({
          reservationId: confirmation,
          surname: lastName,
          hotelCode,
        })
      );
    }
  }, [hotelCode, confirmation, lastName, isUserPersistent]); // need to refetch booking if persistent state changes

  useEffect(() => {
    // ensure we scroll to the top of the page if there are any errors
    if (
      checkInError ||
      hasFetchBookingError ||
      pastReservation ||
      (booking && !canCheckIn)
    ) {
      // the check in error is on that later steps. all other are on the authentication view
      scrollToTop(!checkInError ? "#root main" : undefined);
    }
  }, [
    checkInError,
    hasFetchBookingError,
    pastReservation,
    booking && !canCheckIn,
  ]);

  // track start event
  useEffect(() => {
    // don't track anything until we've at least got the regions lists
    if (properties.length) {
      // in order to track the "property" version we need to have valid query string parameters, and the detailPropertyContent must have been fetched
      if (qsConfirmationNumber && validQsProperty) {
        // this needs to be on a separate line because it loads later.
        if (detailedPropertyContent) {
          dispatchWithLocale(webCheckInStartEvt({ hotelCode }));
        }
      } else if (qsHotelCode && !validQsProperty) {
        // track invalid hotel code used
        dispatchWithLocale(webCheckInErrorEvt({ error: "invalid property" }));
      } else if (!hotelCode) {
        // nothing provided, kick off generic start event, but not if there's a hotel code (otherwise it'll fire again once a hotel is selected)
        dispatchWithLocale(webCheckInStartEvt());
      }
    }
  }, [propertiesLoading, detailedPropertyContent]);

  // track reservation is past
  useEffect(() => {
    if (booking && pastReservation) {
      dispatchWithLocale(
        webCheckInErrorEvt({ error: "past reservation", hotelCode })
      );
    }
  }, [booking]);

  const showFullReservationDetailsForm =
    !propertiesLoading &&
    !partialDataProvided &&
    (!booking || pastReservation || (booking && !canCheckIn));

  const showPartialReservationDetailsForm =
    !propertiesLoading &&
    detailedPropertyContent &&
    partialDataProvided &&
    (!booking || pastReservation || (booking && !canCheckIn));

  const showCheckinForm =
    !propertiesLoading &&
    properties.length &&
    booking &&
    canCheckIn &&
    !hasFetchBookingError &&
    !pastReservation &&
    !confirmedCheckIn;

  const showConfirmation = confirmedCheckIn;

  const verticalImage =
    detailedPropertyContent?.upcomingTrip?.mobileImage?.croppedPath;
  const horizontalImage =
    detailedPropertyContent?.upcomingTrip?.desktopImage?.croppedPath;

  const onSubmitReservationDetails = (providedReservationDetails) => {
    // only update if there isn't already a booking in play
    if (!booking) {
      setReservationDetails({
        ...reservationDetails,
        ...providedReservationDetails,
      });
      scrollToTop("#root main");
    }
  };

  const onConfirmReservationDetails = ({ isEarlyCheckIn }) => {
    dispatchWithLocale(
      webCheckInRegistrationEvt({ isEarlyCheckIn, hotelCode })
    );
  };

  const onConfirmCheckInRequest = (formValues) => {
    setReservationDetails({
      ...reservationDetails,
      email: formValues.email,
    });
    dispatchWithLocale(
      confirmCheckIn({
        ...formValues,
        email: isUserPersistent ? undefined : formValues.email,
        registrationCardText: propertyWebCheckin.registrationCard.text,
      })
    );
    scrollToTop();
  };

  const clearErrors = () => {
    dispatchWithLocale(clearWebCheckInErrors());
  };

  const resetWebCheckIn = () => {
    dispatchWithLocale(clearFetchBooking());
  };

  return {
    properties,
    confirmation,
    email,
    booking,
    showLoadingIndicator: propertiesLoading || loadingData,
    showReservationDetailsForm:
      showFullReservationDetailsForm || showPartialReservationDetailsForm,
    showFullReservationDetailsForm,
    showPartialReservationDetailsForm,
    showCheckinForm,
    showConfirmation,
    onSubmitReservationDetails,
    onConfirmCheckInRequest,
    propertyData: propertyContent
      ? {
          owsCode: propertyContent.owsCode,
          name: propertyContent.propertyOfficialName || propertyContent.name,
          verticalImage: verticalImage
            ? STATIC_SITE_URL + verticalImage
            : undefined,
          horizontalImage: horizontalImage
            ? STATIC_SITE_URL + horizontalImage
            : undefined,
          timeZone: propertyContent.timeZone,
          enabled: !!(
            detailedPropertyContent?.hotel_regcard_email ||
            detailedPropertyContent?.web_check_in_email
          ),
          phone: detailedPropertyContent?.propertyPhone,
          checkInTimeUTC: propertyCheckInTimeUTC,
        }
      : undefined,
    reservationError: hasFetchBookingError
      ? "We were unable to confirm your stay information. The reservation details provided do not appear to be valid."
      : pastReservation
      ? "The check in date on this reservation is in the past."
      : undefined,
    clearErrors,
    canCheckIn: !booking || canCheckIn,
    checkInError,
    resetWebCheckIn,
    propertyWebCheckin,
    onConfirmReservationDetails,
  };
}
