import Cookies from "js-cookies";
import isAfter from "date-fns/fp/isAfter";

export function getDecodedFSSecurityMXValue() {
  const cookieValue = Cookies.getItem("FSSecurityMX");
  if (!cookieValue) {
    return {};
  }
  const decoded = atob(cookieValue);
  return JSON.parse(decoded || "{}") || {};
}

export function getPersistAt() {
  return getDecodedFSSecurityMXValue().persistAt || null;
}

export function getGoldenIdString() {
  return getDecodedFSSecurityMXValue().goldenIdString || null;
}

export function isSignInExpired() {
  const { persistAt, valid, authorized } = getDecodedFSSecurityMXValue();
  if (persistAt) {
    return isAfter(new Date(persistAt))(new Date());
  }
  return !valid && !authorized;
}

export function isSignInPersistentMode() {
  const { persisted, persistAt } = getDecodedFSSecurityMXValue();
  return Boolean(persisted || persistAt);
}

export function isSignInPersistent() {
  const { persisted } = getDecodedFSSecurityMXValue();
  return Boolean(persisted);
}

export function isPartialAuth() {
  return Boolean(getDecodedFSSecurityMXValue().authorized);
}

export function FSSecurityMXExists() {
  return Boolean(Cookies.getItem("FSSecurityMX"));
}
