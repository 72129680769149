import env from "config/env";

const { EMPLOYEE_OVERFLOW_PATH, PUBLIC_OVERFLOW_PATH, STATIC_SITE_URL } = env;

const getThrottleRedirect = ({ throttleStatus = {}, state = {} }) => {
  if (
    throttleStatus.throttle === false ||
    !PUBLIC_OVERFLOW_PATH ||
    !EMPLOYEE_OVERFLOW_PATH ||
    !STATIC_SITE_URL
  ) {
    return false;
  }
  if (state.router?.location?.pathname?.includes("/employee")) {
    return STATIC_SITE_URL + EMPLOYEE_OVERFLOW_PATH;
  }
  return STATIC_SITE_URL + PUBLIC_OVERFLOW_PATH;
};

export default getThrottleRedirect;
