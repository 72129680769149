import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";
import { iif, of, EMPTY } from "rxjs";

import {
  checkUpdateEmailValidated,
  updateUserEmail,
} from "store/profile/profile.slice";
import { getNotValidatedSignInEmail } from "../profile.helpers";

export default (action$) =>
  action$.pipe(
    ofType(checkUpdateEmailValidated.type),
    switchMap(({ payload: { profile = {}, signInEmail } = {} }) => {
      const notValidatedEmail = getNotValidatedSignInEmail({
        ...profile,
        signInEmail,
      });
      return iif(
        () => !!notValidatedEmail,
        of(
          updateUserEmail({
            newValue: {
              ...notValidatedEmail,
              validatedEmail: true,
            },
          })
        ),
        EMPTY
      );
    })
  );
