import React from "react";
import classNames from "classnames";

export default function Request({
  title,
  description,
  className = "",
  actions,
  children,
}) {
  const hasActions = Boolean(actions);

  return (
    <div className={classNames(`fs-trip__transport`, className)}>
      <div className="row">
        <div
          className={classNames("fs-trip__transport__copy", {
            "col-md-9": hasActions,
            "col-md-12": !hasActions,
          })}
        >
          <h2>{title}</h2>

          {description && typeof description === "string" && (
            <p>{description}</p>
          )}

          {description && description?.html && (
            <p dangerouslySetInnerHTML={{ __html: description.html }} />
          )}

          {children}
        </div>

        {hasActions && (
          <div className="col-md-3 fs-trip__transport__actions">{actions}</div>
        )}
      </div>
    </div>
  );
}
