import React, { useContext } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { useTranslation } from "hooks";
import { MediaContext } from "contexts/MediaContext";
import * as profileRoutes from "Profile/profileRoutes";
import useNavigation from "../hooks/useNavigation";

const navigations = [
  {
    hash: "email",
    label: "Email",
  },
  {
    hash: "phone-numbers",
    label: "Phone Numbers",
  },
  {
    hash: "address",
    label: "Address",
  },
  {
    hash: "country-and-language",
    label: "Country & Language",
  },
  {
    hash: "email-subscriptions",
    label: "Email Subscriptions",
  },
];

function Navigations() {
  const { t, locale } = useTranslation();
  const media = useContext(MediaContext);

  const { scrolledToSection, scrollToSection } = useNavigation({
    navigations,
    topAdjust: media.isLessThan.sm ? 110 : media.isLessThan.md ? 210 : 0,
  });

  return (
    <ul>
      {navigations.map(({ hash, label }) => (
        <li key={hash}>
          <Link
            to={{
              pathname: profileRoutes.editProfilePath.to({
                locale,
              }),
              hash,
            }}
            onClick={(evt) => {
              evt.preventDefault();
              scrollToSection(hash);
            }}
            className={classNames({
              "is-selected":
                hash === scrolledToSection ||
                (!scrolledToSection && hash === "email"),
            })}
          >
            {t(label)}
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default Navigations;
