import { forkJoin } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { getBookingById$ } from "api/tretail/booking";
import {
  retrieveAllHotelProductComments$,
  retrieveAllHotelProductPostBookingComments$,
} from "api/tretail/bookingProductCust";
import catchInternalServerError from "store/catchInternalServerError";
import { BOOKING_STATUSES } from "../constants";

export default function fetchBookingWithCommentsById$({ bookingId, locale }) {
  return getBookingById$({ bookingId, locale }).pipe(
    switchMap((booking) => {
      const parts = {
        preBookingComments: retrieveAllHotelProductComments$({
          bookingId,
          locale,
        }),
      };
      if (booking.bookingStatus === BOOKING_STATUSES.BOOKED) {
        parts.postBookingComments = retrieveAllHotelProductPostBookingComments$(
          {
            bookingId,
            locale,
          }
        );
      }
      return forkJoin(parts).pipe(
        map(({ preBookingComments = {}, postBookingComments = {} }) => {
          return {
            booking,
            preBookingComments,
            postBookingComments,
          };
        }),

        catchInternalServerError()
      );
    })
  );
}
