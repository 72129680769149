import { combineEpics } from "redux-observable";

import changeSearchEpic from "./epics/changeSearch";
import fetchSearchResultsEpic from "./epics/fetchSearchResults";
import fetchSearchResultsForResultSetIdEpic from "./epics/fetchSearchResultsForResultSetId";
import submitSearchEpic from "./epics/submitSearch";

export default combineEpics(
  changeSearchEpic,
  fetchSearchResultsEpic,
  fetchSearchResultsForResultSetIdEpic,
  submitSearchEpic
);
