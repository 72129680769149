import React from "react";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import {
  ReservationDetails,
  CheckInForm,
  CheckInConfirmation,
} from "./components";
import useWebCheckIn from "./hooks/useWebCheckIn";

function WebCheckIn() {
  const webCheckInData = useWebCheckIn();

  const {
    properties,
    confirmation,
    email,
    booking,
    showLoadingIndicator,
    showReservationDetailsForm,
    showFullReservationDetailsForm,
    showPartialReservationDetailsForm,
    showCheckinForm,
    onSubmitReservationDetails,
    propertyData,
    reservationError,
    clearErrors,
    canCheckIn,
    onConfirmReservationDetails,
    onConfirmCheckInRequest,
    checkInError,
    showConfirmation,
    resetWebCheckIn,
    propertyWebCheckin,
  } = webCheckInData;

  return (
    <>
      {showLoadingIndicator && <BookingFlowLoadingIndicator />}
      {showReservationDetailsForm && (
        <ReservationDetails
          properties={properties}
          confirmation={confirmation}
          propertyData={propertyData}
          onSubmitReservationDetails={onSubmitReservationDetails}
          reservationError={reservationError}
          resetWebCheckIn={resetWebCheckIn}
          canCheckIn={canCheckIn}
          showFullReservationDetailsForm={showFullReservationDetailsForm}
          showPartialReservationDetailsForm={showPartialReservationDetailsForm}
        />
      )}
      {showCheckinForm && (
        <CheckInForm
          booking={booking}
          propertyData={propertyData}
          onConfirmReservationDetails={onConfirmReservationDetails}
          onConfirmCheckInRequest={onConfirmCheckInRequest}
          clearErrors={clearErrors}
          checkInError={checkInError}
          propertyWebCheckin={propertyWebCheckin}
        />
      )}

      {showConfirmation && (
        <CheckInConfirmation email={email} propertyData={propertyData} />
      )}
    </>
  );
}

export default WebCheckIn;
