import React from "react";

function Tooltip({ message }) {
  return (
    <span className="profiles--tooltip__container">
      <a className="profiles--tooltip__trigger" href="#a">
        i
      </a>
      <div aria-hidden="true" className="profiles--tooltip" role="tooltip">
        <p>{message}</p>
      </div>
    </span>
  );
}

export default Tooltip;
