import React from "react";

import { useTranslation } from "hooks";

export default function MultiRoomOptions({ register }) {
  const { t } = useTranslation();

  return (
    <fieldset className="multi-room-options">
      <legend>{t("Multi-room Options")}</legend>
      <div className="form-row checkbox">
        <span className="disclaimer">
          {t(
            "We will do our best to accommodate adjoining room requests, but cannot make guarantees."
          )}
        </span>
        <label htmlFor="adjoiningrooms">
          <input
            id="adjoiningrooms"
            type="checkbox"
            name="adjoiningRooms"
            ref={register}
          />
          {t("Adjoining Rooms Request")}
        </label>
        <label htmlFor="roomsclosetogether">
          <input
            id="roomsclosetogether"
            type="checkbox"
            name="keepRoomsClose"
            ref={register}
          />
          {t("Keep Rooms Close Together")}
        </label>
      </div>
    </fieldset>
  );
}
