import React from "react";

import { useTranslation } from "hooks";

const propertyDetails = {
  street: "London Rd",
  city: "Southampton",
  zipcode: "SO14",
  state: "Hampshire",
  country: "UK",
  name: "Southampton FS",
  url: "",
  type: "Four Seasons Resort",
  reservationPhone: "023333333",
  propertyPhone: "02344444444",
  globalSupportPhone: "0235555555",
};

export function ErrorContactDetails() {
  const { t } = useTranslation();

  return (
    <div className="col-md-5 error-contact-details">
      <h2>{t("Contact Reservation")}</h2>
      <div className="row">
        <div className="col-xs-12 col-md-12">
          <p>
            {(propertyDetails.reservationPhone ||
              propertyDetails.propertyPhone) && (
              <span>{`${propertyDetails.name} ${t("Office")}: +${
                propertyDetails.reservationPhone ||
                propertyDetails.propertyPhone
              }`}</span>
            )}
            {propertyDetails.globalSupportPhone && (
              <>
                <span>{`${t("Worldwide")}: +${
                  propertyDetails.globalSupportPhone
                }`}</span>
                <span>
                  {t("Worldwide")}
                  <a
                    className="global-contact-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={propertyDetails.url}
                  >
                    {t("Toll Free Numbers By Country")}
                  </a>
                </span>
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
