import React from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "hooks";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import ThemeContextProvider, { THEMES } from "contexts/ThemeContext";
import { selectProfile } from "store/profile";
import YourPreferences from "../ProfileView/YourPreferences";

export default function YourPreferencesView() {
  const { i18n } = useTranslation();

  // REQUIRED DATA FOR VIEW
  // profile
  const hasProfile = Boolean(useSelector(selectProfile));

  if (!hasProfile) {
    return <BookingFlowLoadingIndicator opaque />;
  }

  return (
    <ThemeContextProvider theme={THEMES.DARK}>
      <div className={`layout--profile ${i18n.dir(i18n.language)}`}>
        <div className="layout--profile-fullscreen profile-stay-preferences-view">
          <div className="view--profile">
            <div className="view--profile__your-preferences">
              <YourPreferences />
            </div>
          </div>
        </div>
      </div>
    </ThemeContextProvider>
  );
}
