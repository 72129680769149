export const SEARCH_BY_OPTIONS = {
  DESTINATION: "destination",
  DATES: "dates",
};

export const FORM_OPTIONS = {
  DAILY_DISCOVERY: "EMPCMP",
  EXPERIENCE_RATE: "EMPECE",
  FRIENDS_FAMILY: "SPREQ",
};
