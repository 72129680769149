import React, { useContext, useState } from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";
import { getPrivacyLink } from "utils/externalLinks";
import MappingLayerContext from "../../mappingLayerContext";

export default function TermsAndConditions({
  register,
  errors = {},
  readOnlyFields = [],
  hasExplicitOptIn = false,
  showOptInAgreement = false,
}) {
  const { t, locale } = useTranslation();
  const { termsAndConditions } = useContext(MappingLayerContext);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const toggleShowTermsAndConditions = () =>
    setShowTermsAndConditions(!showTermsAndConditions);

  const hasError = Boolean(errors.termsAgreement);

  return (
    <fieldset
      name="termsAndConditions"
      className={classNames("terms-fieldset has-break", {
        "has-errors": Object.keys(errors).length > 0,
      })}
    >
      <legend>{t("Terms & Conditions")}</legend>

      <div
        className={classNames("form-row checkbox terms-agreement-row", {
          "has-errors": hasError,
        })}
      >
        <label htmlFor="termsAgreement">
          <input
            id="termsAgreement"
            type="checkbox"
            name="termsAndConditions.termsAgreement"
            aria-describedby="ttTermsAndConditions"
            required
            ref={register()}
            readOnly={readOnlyFields.includes("termsAgreement")}
          />
          {t("I have read & accepted the")}
        </label>{" "}
        <button
          type="button"
          id="termsAndConditions"
          className="appear-as-link"
          onClick={toggleShowTermsAndConditions}
          aria-controls="termsAndConditionsText"
          aria-expanded={showTermsAndConditions}
        >
          {t("Terms & Conditions")}
        </button>
        {hasError && (
          <div className="inline-error">
            <span id="ttTermsAndConditions" className="message">
              {t("To proceed, please accept terms & conditions.")}
            </span>
            <span aria-hidden="true" className="icon">
              !
            </span>
          </div>
        )}
        {showTermsAndConditions &&
          termsAndConditions.map(({ roomOffer, text }, index) => (
            <React.Fragment key={index}>
              {roomOffer && roomOffer}
              <div
                id="termsAndConditionsText"
                className="termsAndConditionsText"
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            </React.Fragment>
          ))}
        {!hasExplicitOptIn && showOptInAgreement && (
          <label
            className="original-opt-in terms-label"
            htmlFor="optInAgreement"
          >
            <input
              id="optInAgreement"
              type="checkbox"
              name="termsAndConditions.optInAgreement"
              aria-describedby="ttOptIn"
              ref={register}
            />
            {t(
              "I consent to Four Seasons sending me electronic communications so that Four Seasons can keep me informed of upcoming promotions and exclusive offers and to conduct marketing research."
            )}{" "}
            {t(
              "You may withdraw this consent at any time by emailing guest.historian@fourseasons.com"
            )}
          </label>
        )}
        {showOptInAgreement && (
          <div className="privacy-wrapper">
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  'By selecting “Book” I acknowledge that Four Seasons will process my personal information as set out in the <a href="{0}" target="_blank">Four Seasons Privacy Notice</a>.',
                  [getPrivacyLink(locale)]
                ),
              }}
            />
          </div>
        )}
        {!showOptInAgreement && (
          <div className="privacy-wrapper">
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  'By selecting “Book” I declare that I am authorized to complete this booking on behalf of the guest, and acknowledge that Four Seasons will process the personal information provided above as set out in the <a href="{0}" target="_blank">Four Seasons Privacy Notice</a>.',
                  [getPrivacyLink(locale)]
                ),
              }}
            />
          </div>
        )}
      </div>
    </fieldset>
  );
}
