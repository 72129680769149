/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import getMergedPropertyCodes from "store/getMergedPropertyCodes";
import {
  setRequestStateRequesting,
  setRequestStateFulfilled,
  setRequestStateFailed,
  setRequestStateCancelled,
} from "store/apiRequestStates/setRequestState";

const BOOKING_MESSAGES_TYPES = ["availability", "soldout", "confirmation"];

const initialState = {
  data: {
    availability: {},
    soldout: {},
    confirmation: {},
  },
  error: {
    availability: {},
    soldout: {},
    confirmation: {},
  },
  requestStates: {
    availability: {},
    soldout: {},
    confirmation: {},
  },
};

const bookingMessagesSlice = createSlice({
  name: "bookingMessages",
  initialState,
  reducers: {
    fetchBookingMessages(state, { payload }) {
      const { bookingSteps } = payload;
      const propertyCodes = getMergedPropertyCodes(payload);
      bookingSteps.forEach((bookingStep) => {
        propertyCodes.forEach((propertyCode) => {
          delete state.error[bookingStep][propertyCode];
          state.requestStates[bookingStep][propertyCode] =
            setRequestStateRequesting(
              state.requestStates[bookingStep][propertyCode]
            );
        });
      });
    },

    fetchBookingMessagesFulfilled(
      state,
      { payload: { propertyCode, bookingStep, bookingMessages } }
    ) {
      state.data[bookingStep][propertyCode] = bookingMessages;
      state.requestStates[bookingStep][propertyCode] = setRequestStateFulfilled(
        state.requestStates[bookingStep][propertyCode]
      );
    },

    fetchBookingMessagesFailed(
      state,
      { payload: { propertyCode, bookingStep, error } }
    ) {
      state.data[bookingStep][propertyCode] = null;
      state.error[bookingStep][propertyCode] = error;
      state.requestStates[bookingStep][propertyCode] = setRequestStateFailed(
        state.requestStates[bookingStep][propertyCode]
      );
    },

    fetchBookingMessagesCancel(state, { payload }) {
      const { bookingSteps = BOOKING_MESSAGES_TYPES } = payload;
      const propertyCodes = getMergedPropertyCodes(payload);
      bookingSteps.forEach((bookingStep) => {
        propertyCodes.forEach((propertyCode) => {
          state.requestStates[bookingStep][propertyCode] =
            setRequestStateCancelled(
              state.requestStates[bookingStep][propertyCode]
            );
        });
      });
    },

    resetBookingMessages() {
      return initialState;
    },
  },
});

export const {
  fetchBookingMessages,
  fetchBookingMessagesFulfilled,
  fetchBookingMessagesFailed,
  fetchBookingMessagesCancel,
  resetBookingMessages,
} = bookingMessagesSlice.actions;

export default bookingMessagesSlice.reducer;
