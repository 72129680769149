import get from "lodash/get";

export const selectViewState = ({ registration }) => registration.viewState;

export const selectRegistrationFormValues = ({ registration }) =>
  registration.registrationFormValues;

export const selectFoundBooking = ({
  registration: { foundReservationId },
  bookings,
}) => {
  return foundReservationId && bookings.byReservationId[foundReservationId];
};

export const selectFoundBookingCustomer = (state) => {
  return get(selectFoundBooking(state), ["customers", 0]);
};

export const selectFoundBookingCustomerForRegistration = (state) => {
  const foundBooking = selectFoundBooking(state);
  if (!foundBooking) {
    return undefined;
  }

  const bookingCustomer = selectFoundBookingCustomer(state);

  const title =
    get(bookingCustomer, ["name", "title"]) ||
    get(foundBooking, ["contactDetails", "name", "title"]) ||
    "";
  const firstName =
    get(bookingCustomer, ["name", "firstName"]) ||
    get(foundBooking, ["contactDetails", "name", "firstName"]) ||
    "";
  const surname =
    get(bookingCustomer, ["name", "surname"]) ||
    get(foundBooking, ["contactDetails", "name", "surname"]) ||
    "";

  const [address] = get(bookingCustomer, ["address"], []).sort((a) =>
    a.defaultAddress && a.activeAddress ? -1 : 1
  );
  const countryCode =
    get(address, ["country", "code"]) ||
    get(foundBooking, ["contactDetails", "address", "country", "code"]) ||
    "";

  const [defaultEmail] = get(bookingCustomer, ["emailsFS"], []).sort((e) =>
    e.defaultEmail && e.activeEmail ? -1 : 1
  );
  const email =
    get(defaultEmail, ["email"]) ||
    get(foundBooking, ["contactDetails", "email"]) ||
    "";

  const [defaultPhoneNumber] = get(bookingCustomer, ["phones"], []).sort((p) =>
    p.defaultPhone && p.activePhone ? -1 : 1
  );
  const phoneNumber =
    get(defaultPhoneNumber, ["number"]) ||
    get(foundBooking, ["contactDetails", "phones", 0, "number"]) ||
    "";

  return {
    title,
    firstName,
    surname,
    countryCode,
    email,
    phoneNumber,
  };
};
