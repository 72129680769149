import React from "react";

import { useTranslation } from "hooks";
import redirectImage from "./images/redirect.png";

export default function AlipayDetails() {
  const { t } = useTranslation();

  return (
    <li className="choice-item alipayChina-content">
      <img alt={t("Redirect")} title={t("Redirect")} src={redirectImage} />
      <p>
        {t(
          "You will be re-directed to Alipay to complete your purchase securely & pre-pay for your reservation. If you wish to pay at the hotel, please select the Credit Card payment option."
        )}
      </p>
    </li>
  );
}
