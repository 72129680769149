import React from "react";

import { useTranslation } from "hooks";
import Modal from "BookingFlow/components/Modal";
import env from "config/env";

const { IMAGE_BASE_URL } = env;

export default function FloorplanModal({
  floorPlanImagePath,
  closeModal = () => {},
}) {
  const { t } = useTranslation();

  return (
    <Modal
      title={t("Floorplans")}
      className="new-modal--floorplan"
      closeModal={closeModal}
    >
      <div className="floorplan">
        <img src={IMAGE_BASE_URL + floorPlanImagePath} alt={t("Floor Plan")} />
      </div>
    </Modal>
  );
}
