import { useEffect } from "react";
import { useSelector } from "react-redux";

import env from "config/env";

import { selectPropertyContent } from "store/propertyContent";

const { TRIPTEASE_STATIC_URL, TRIPTEASE_CLIENT_API_KEY } = env;

const useTripTeaseConfirmation = ({ completedBooking }) => {
  const propertyContent = useSelector(
    selectPropertyContent(completedBooking?.hotelProducts?.[0]?.hotelCode)
  );

  useEffect(() => {
    let script;
    const hotelKey = propertyContent?.tripteaseAPIKey;
    const bookingValue = completedBooking?.price?.total?.cash?.amount;
    const bookingCurrency = completedBooking?.price?.total?.cash?.currencyCode;
    const bookingReference =
      completedBooking?.hotelProducts?.[0]?.reservationId;
    if (hotelKey && bookingReference) {
      script = document.createElement("script");
      script.src = `${TRIPTEASE_STATIC_URL}${TRIPTEASE_CLIENT_API_KEY}.js?hotelKey=${hotelKey}&bookingValue=${bookingValue}&bookingCurrency=${bookingCurrency}&bookingReference=${bookingReference}`;
      script.async = true;
      document.body.appendChild(script);
    }

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  });
};

export default useTripTeaseConfirmation;
