import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";

import env from "config/env";
import { baseGetRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

export const getBookingById$ = ({ bookingId, locale }) =>
  ajax({
    ...baseGetRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/bookingsCust/${bookingId}`,
  }).pipe(pluck("response"));

export default getBookingById$;
