import React from "react";
import classNames from "classnames";

const NavButton = React.forwardRef(
  ({ direction = "left", onClick, isEnabled, handleOnKeyDown }, ref) => {
    const travel = direction === "left" ? "prev" : "next";

    return (
      <button
        type="button"
        className={classNames(`ui-datepicker-${travel} icon-button`, {
          "ui-state-disabled": !isEnabled,
        })}
        onClick={onClick}
        onKeyDown={handleOnKeyDown}
        ref={ref}
      >
        <i aria-hidden="true" className={`icon icon-arrow-${direction}`} />
      </button>
    );
  }
);

export default NavButton;
