import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

function ButtonSelectOptions({
  label,
  ariaLabel,
  options,
  roomOptionsId,
  onSelectOption,
  close,
}) {
  const el = useRef();

  useEffect(() => {
    function handleDocumentClick({ target }) {
      if (!el?.current?.contains(target)) {
        close(target);
      }
    }
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <div className="options" ref={el}>
      <fieldset id={roomOptionsId}>
        <legend>{label}</legend>
        {options.map((option) => (
          <button
            type="button"
            key={option.value}
            className="btn btn--primary"
            aria-label={`${ariaLabel} ${option.label}`}
            onClick={() => onSelectOption(option)}
          >
            {option.label}
          </button>
        ))}
      </fieldset>
    </div>
  );
}

export default function ButtonSelect({
  roomName,
  options = [],
  ariaName,
  btnLabel,
  label,
  onSelectOption,
}) {
  const buttonRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => setIsOpen(!isOpen);

  const roomOptionsId = `roomOptions-${roomName}`;
  const ariaLabel = `${btnLabel}${ariaName ? ` (${ariaName})` : ""}`;

  return (
    <div className="fs-btn-select">
      {options.length > 1 && (
        <div
          className={classNames("fs-btn-select-container", {
            "is-open": isOpen,
          })}
        >
          <button
            ref={buttonRef}
            type="button"
            aria-label={ariaLabel}
            aria-expanded={isOpen}
            aria-controls={roomOptionsId}
            className="btn btn--primary label toggle"
            onClick={toggleIsOpen}
          >
            <span>{btnLabel}</span>
            <span
              aria-hidden="true"
              className={classNames("icon", {
                "icon-arrow-up": isOpen,
                "icon-arrow-down": !isOpen,
              })}
            />
          </button>

          {isOpen && (
            <ButtonSelectOptions
              label={label}
              ariaLabel={ariaLabel}
              roomOptionsId={roomOptionsId}
              options={options}
              onSelectOption={onSelectOption}
              close={(target) =>
                !buttonRef?.current?.contains(target) && setIsOpen(false)
              }
            />
          )}
        </div>
      )}

      {options.length === 1 && (
        <div className="fs-btn-select-container">
          <button
            type="button"
            aria-label={ariaLabel}
            className="btn btn--primary label"
            onClick={() => onSelectOption(options[0])}
          >
            <span>{btnLabel}</span>
          </button>
        </div>
      )}
    </div>
  );
}
