import React, { useContext, useEffect } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { MediaContext } from "contexts/MediaContext";
import { useDispatchWithLocale, useTranslation } from "hooks";
import {
  fetchCalendarAvailability,
  fetchCalendarAvailabilityCancel,
} from "store/calendarAvailability/calendarAvailability.slice";
import { selectCalendarAvailability } from "store/calendarAvailability/calendarAvailability.selectors";
import useCalendar from "../../BookingFlow/PlanYourStay/hooks/useCalendar";
import Month from "../../BookingFlow/PlanYourStay/DateRangePicker/Month";
import DatepickerContext from "../../BookingFlow/PlanYourStay/DateRangePicker/datepickerContext";
import {
  extractRestrictedDates,
  extractUnavailableDates,
  formatDateWithLocale,
} from "../../BookingFlow/PlanYourStay/DateRangePicker/calendarHelpers";

export default function Calendar({
  nextControlOutsideWidget,
  checkIn,
  checkOut,
  setCheckIn,
  setCheckOut,
  checkInStart,
  hotelCode,
}) {
  const { i18n, t, locale } = useTranslation();
  const media = useContext(MediaContext);
  const dispatchWithLocale = useDispatchWithLocale();

  const availabilityDates =
    useSelector(selectCalendarAvailability(hotelCode)) || {};

  const {
    activeMonths,
    canNavigateToNextMonth,
    canNavigateToPreviousMonth,
    checkType,
    componentOnBlur,
    componentRef,
    firstDayOfWeek,
    focusedDate,
    formatedEndDate,
    formatedStartDate,
    goToNextMonths,
    goToPreviousMonths,
    handleEndDateOnClick,
    handleNavigatingAwayFromCalendar,
    handleNavigatingAwayFromEndDateInput,
    handleNavigatingAwayFromNextMonth,
    handleNavigatingAwayFromPrevMonth,
    handleNavigatingAwayFromStartDateInput,
    handleOnMouseDown,
    handleStartDateOnClick,
    handleStartDateOnFocus,
    includeDaysFromOtherMonths,
    isCalendarVisible,
    isDateAvailable,
    isDateBlocked,
    isDateFocused,
    isDateHovered,
    isDateSelected,
    isEndDate,
    isFirstOrLastSelectedDate,
    isOutsideCheckInRange,
    isRestricted,
    isStartDate,
    isUnselectable,
    nextMonthRef,
    onDateFocus,
    onDateSelect,
    prevMonthRef,
    weekdayLabelFormatter,
  } = useCalendar({
    areInputsFocusable: false,
    checkIn,
    checkInStart,
    checkOut,
    disableEffectfullyUpdatingCheckInDate: true,
    includeDaysFromOtherMonths: false,
    language: i18n.language,
    locale,
    nextControlOutsideWidget,
    numberOfMonths: media.isLessThan.sm ? 1 : 2,
    restrictedDates: extractRestrictedDates(availabilityDates),
    unavailableDates: extractUnavailableDates(availabilityDates),
    setCheckIn,
    setCheckOut,
    weekdayLabelFormatter: formatDateWithLocale("eee", locale),
  });

  useEffect(() => {
    dispatchWithLocale(fetchCalendarAvailability());

    return () => dispatchWithLocale(fetchCalendarAvailabilityCancel());
  }, []);

  return (
    <section
      className="fs-datepicker-wrapper"
      ref={componentRef}
      onBlur={componentOnBlur}
    >
      <div className="TextInput">
        <input
          id="checkin"
          className="fs-select-date check-in fs-date-picker-check-in-input"
          type="text"
          aria-label="Check In"
          value={formatedStartDate}
          onClick={handleStartDateOnClick}
          onFocus={handleStartDateOnFocus}
          onKeyDown={handleNavigatingAwayFromStartDateInput}
          aria-describedby=""
          readOnly
        />
        <label className="formElement-label formElement--focusAlways ty-c4">
          {t("Check-in date")}
        </label>
      </div>
      <div className="TextInput">
        <input
          id="checkout"
          className="fs-select-date check-in fs-date-picker-check-out-input"
          type="text"
          aria-label="Check Out"
          value={formatedEndDate}
          onClick={handleEndDateOnClick}
          onKeyDown={handleNavigatingAwayFromEndDateInput}
          aria-describedby=""
          readOnly
        />
        <label className="formElement-label formElement--focusAlways ty-c4">
          {t("Check-out date")}
        </label>
      </div>
      {isCalendarVisible && (
        <DatepickerContext.Provider
          value={{
            focusedDate,
            handleNavigatingAwayFromCalendar,
            handleNavigatingAwayFromNextMonth,
            isDateAvailable,
            isDateBlocked,
            isDateFocused,
            isDateHovered,
            isDateSelected,
            isEndDate,
            isFirstOrLastSelectedDate,
            isRestricted,
            isStartDate,
            onDateFocus,
            onDateSelect,
            isUnselectable,
            isOutsideCheckInRange,
          }}
        >
          <div
            className={`fs-calendar-wrapper ${checkType}`}
            onMouseDown={handleOnMouseDown}
            role="presentation"
          >
            <div className="fs-calendar" role="application">
              <div className="datepicker hasDatepicker">
                <div className="ui-datepicker-inline ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all ui-datepicker-multi ui-datepicker-multi-2">
                  {activeMonths.map((activeMonth, index) => {
                    const isFirst = index === 0;
                    const isLast = index + 1 === activeMonths.length;

                    return (
                      <div
                        key={activeMonth.month}
                        className={classNames("ui-datepicker-group", {
                          "ui-datepicker-group-first": isFirst,
                          "ui-datepicker-group-last": isLast,
                        })}
                      >
                        <div
                          className={classNames(
                            "ui-datepicker-header ui-widget-header ui-helper-clearfix",
                            {
                              "ui-corner-left": isFirst,
                              "ui-corner-right": isLast,
                            }
                          )}
                        >
                          {/* Change using NavButton */}
                          {isFirst && (
                            <button
                              type="button"
                              className={classNames(
                                "ui-datepicker-prev ui-corner-all",
                                {
                                  "ui-state-disabled":
                                    !canNavigateToPreviousMonth,
                                }
                              )}
                              ref={prevMonthRef}
                              onClick={goToPreviousMonths}
                              onKeyDown={handleNavigatingAwayFromPrevMonth}
                            >
                              <span className="ui-icon ui-icon-circle-triangle-w">
                                <i
                                  className="icon icon-arrow-left"
                                  aria-hidden="true"
                                />
                              </span>
                            </button>
                          )}

                          {isLast && (
                            <button
                              type="button"
                              className={classNames(
                                "ui-datepicker-next ui-corner-all",
                                {
                                  "ui-state-disabled": !canNavigateToNextMonth,
                                }
                              )}
                              ref={nextMonthRef}
                              onClick={goToNextMonths}
                              onKeyDown={handleNavigatingAwayFromNextMonth}
                            >
                              <span className="ui-icon ui-icon-circle-triangle-w">
                                <i
                                  className="icon icon-arrow-right"
                                  aria-hidden="true"
                                />
                              </span>
                            </button>
                          )}

                          <div className="ui-datepicker-title">
                            {formatDateWithLocale(
                              "MMMM yyyy",
                              locale
                            )(new Date(activeMonth.year, activeMonth.month))}
                          </div>
                        </div>

                        <Month
                          firstDayOfWeek={firstDayOfWeek}
                          month={activeMonth}
                          includeDaysFromOtherMonths={
                            includeDaysFromOtherMonths
                          }
                          weekdayLabelFormatter={weekdayLabelFormatter}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <footer className="datepicker-footer">
                {/* if show legend */}
                <ul className="legend">
                  <li title="No Availability">
                    {t("Unavailable")}
                    <span className="is-unavailable">&nbsp;</span>
                  </li>
                  <li title="Restrictions may apply">
                    {t("Restricted")}
                    <span className="is-restricted">&nbsp;</span>
                  </li>
                </ul>
              </footer>
            </div>
          </div>
        </DatepickerContext.Provider>
      )}
    </section>
  );
}
