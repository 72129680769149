import React from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";

function FlexDatesPromoCodeWarning({ isError = false }) {
  const { t } = useTranslation();
  return (
    <div
      className={classNames("flex-dates-promo-code-warning", {
        "flex-dates-promo-code-warning--is-error": isError,
      })}
    >
      {t("A promo code cannot be applied with a flexible date search.")}
    </div>
  );
}

export default FlexDatesPromoCodeWarning;
