import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { getFindAHotelLink } from "utils/externalLinks";
import { selectProperties } from "store/properties";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import { useTranslation, useDispatchWithLocale } from "hooks";
import { Button, Checkbox, TextInput } from "Profile/components";
import { THEMES } from "contexts/ThemeContext";
import { addReservation, addReservationCancel } from "store/bookings";
import { errorCodes, getErrorCodeForTranslations } from "utils/errorCodes";
import {
  setIsOpen,
  setIsSubmitting,
  selectIsSubmitting,
  selectIsSuccess,
  selectAddReservationError,
} from "store/addReservationModal";
import ErrorMessage from "../components/ErrorMessage";
import Modal from "./Modal";
import Typeahead from "./Typeahead";

export default function AddReservationModal() {
  const { t, locale } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();

  const properties = useSelector(selectProperties);
  const error = useSelector(selectAddReservationError);
  let errorParams = [];

  if (error?.errorCode === errorCodes.COULD_NOT_RETRIEVE) {
    errorParams = [getFindAHotelLink(locale)];
  }

  const { register, control, handleSubmit, formState, formErrors } = useForm({
    mode: "onChange",
    defaultValues: {
      surname: "",
      hotelCode: "",
      reservationId: "",
      confirmation: false,
    },
  });

  const errors = {
    ...formErrors,
    ...(error?.field
      ? { [error.field]: error.userMessage || error.errorCode }
      : {}),
  };

  const handleClose = () => {
    dispatchWithLocale(setIsOpen(false));
  };

  const showLoadingIndicator = useSelector(selectIsSubmitting);
  const showSuccessMessage = useSelector(selectIsSuccess);

  const onSubmit = ({ surname, hotelCode, reservationId }) => {
    dispatchWithLocale(setIsSubmitting(true));
    dispatchWithLocale(
      addReservation({
        surname,
        hotelCode,
        reservationId,
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatchWithLocale(setIsSubmitting(false));
      dispatchWithLocale(addReservationCancel);
    };
  }, []);

  if (showSuccessMessage) {
    return (
      <Modal close={handleClose} theme={THEMES.LIGHT}>
        <div className="view--profile-folio">
          <div className="container">
            <div className="view--profile-folio__header">
              <h1>{t("Add Your Reservation")}</h1>
              <p className="intro ty-b1">
                {t(
                  "Your request to add this reservation has been submitted. Please check back within 24 to 48 hours."
                )}
              </p>
            </div>
            <div className="view--profile-folio__actions">
              <Button theme="light" onClick={handleClose}>
                {t("Close")}
              </Button>
            </div>
          </div>
          <button className="btn-close" type="button" onClick={handleClose}>
            <span>{t("Back")}</span>
          </button>
        </div>
      </Modal>
    );
  }

  return (
    <Modal close={handleClose} theme={THEMES.LIGHT}>
      {showLoadingIndicator && <BookingFlowLoadingIndicator />}
      <div className="view--profile-folio">
        <div className="container">
          <div className="view--profile-folio__header">
            <h1>{t("Add Your Reservation")}</h1>
            <p className="intro">
              {t(
                "Please provide your booking details and we'll add this trip to your profile."
              )}
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {error && (
              <ErrorMessage>
                {t(
                  getErrorCodeForTranslations({ errorCode: error.errorCode }),
                  errorParams
                )}
              </ErrorMessage>
            )}
            <div>
              <TextInput
                identifier="input-last-name"
                name="surname"
                label={t("Last Name")}
                register={register({ required: true })}
                required
                error={
                  Boolean(errors?.surname) && t("Please enter a last name")
                }
              />

              <Controller
                control={control}
                name="hotelCode"
                rules={{ required: true }}
                required
                render={({ ref: _ignoredRef, ...renderProps }) => {
                  return (
                    <Typeahead
                      options={properties}
                      {...renderProps}
                      identifier="input-hotelCode"
                      label={t("Hotel Name")}
                      placeholder={t("Hotel Name")}
                      error={
                        Boolean(errors?.hotelCode) && "Please select a hotel"
                      }
                      required
                    />
                  );
                }}
              />

              <TextInput
                identifier="input-confirmation-number"
                name="reservationId"
                label={t("Confirmation Number")}
                placeholder="12345678"
                register={register({ required: true })}
                required
                error={
                  Boolean(errors?.reservationId) &&
                  t("Please enter your reservation number")
                }
              />
            </div>
            <Checkbox
              identifier="confirmation"
              name="confirmation"
              register={register({ required: true })}
            >
              {t(
                "I confirm that I am the guest, another individual authorized and responsible for the requested reservation and payment details, or I have otherwise been authorized to update this reservation."
              )}
            </Checkbox>
            <div className="view--profile-folio__actions">
              <Button type="submit" disabled={!formState.isValid}>
                {t("Continue")}
              </Button>
            </div>
          </form>
        </div>

        <button className="btn-close" type="button" onClick={handleClose}>
          <span>{t("Back")}</span>
        </button>
      </div>
    </Modal>
  );
}
