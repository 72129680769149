import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Joi from "joi";
import parsePhoneNumber from "libphonenumber-js";
import { useSelector } from "react-redux";

import { isMobileApp } from "utils";
import { useTranslation } from "hooks";
import env from "config/env";
import * as regex from "utils/regexPatterns";
import getBookerNames from "utils/getBookerNames";
import { selectBookingInProgressFormValues } from "store/bookings";
import { CancellationDate } from "BookingFlow/components";
import { selectIsUserPersistent } from "store/profile";
import { useGetDefaultFormValues } from "../hooks";
import BookingForSomeoneElse from "./BookingForSomeoneElse";
import TravelAgentBooking from "./TravelAgentBooking";
import BookingForYourself from "./BookingForYourself";
import { PAYMENT_METHODS, SECTIONS } from "../hooks/constants";
import FormWithRecaptcha from "./FormWithRecaptcha";
import MappingLayerContext from "../../mappingLayerContext";

const { GOOGLE_RECAPTCHA_ENABLED } = env;

const isPhoneNumberValid = (value, helpers) => {
  const phoneNumber = parsePhoneNumber(`+${value}`);

  if (!phoneNumber?.isValid()) {
    return helpers.error("any.invalid");
  }

  return value;
};

const JOI_EMAIL_RULE = Joi.string().regex(regex.email).required();
const JOI_RULES = {
  email: JOI_EMAIL_RULE,
  confirmEmail: Joi.when("email", {
    is: JOI_EMAIL_RULE,
    then: Joi.string().valid(Joi.ref("email")),
    otherwise: Joi.string().required(),
  }),
  name: Joi.string().regex(regex.noDigits).required(),
  optionalString: Joi.string().allow("").optional(),
};

const validationSchema = ({
  bookingType,
  employeeTermFields,
  isPreferredPartners,
  isUserPersistent,
}) =>
  Joi.object({
    creditCard: Joi.object({
      cardHolderName: JOI_RULES.name,
      expiryDateMonth: Joi.string().required(),
      expiryDateYear: Joi.string().required(),
      number: Joi.string().creditCard().required(),
    }),
    guestDetails: Joi.object({
      confirmEmail:
        bookingType === SECTIONS.AGENT || isUserPersistent
          ? JOI_RULES.optionalString
          : JOI_RULES.confirmEmail,
      countryCode: Joi.string().required(),
      email: isUserPersistent ? JOI_RULES.optionalString : JOI_RULES.email,
      firstName: isUserPersistent ? JOI_RULES.optionalString : JOI_RULES.name,
      phoneNumber: isUserPersistent
        ? JOI_RULES.optionalString
        : Joi.string().custom(isPhoneNumberValid).required(),
      surname: isUserPersistent ? JOI_RULES.optionalString : JOI_RULES.name,
      sendGuestConfirmation:
        bookingType === SECTIONS.SOMEONE_ELSE
          ? Joi.boolean().required()
          : Joi.boolean().optional(),
    }),
    bookerDetails: Joi.object({
      confirmEmail: isUserPersistent
        ? JOI_RULES.optionalString
        : JOI_RULES.confirmEmail,
      email: isUserPersistent ? JOI_RULES.optionalString : JOI_RULES.email,
      firstName: isUserPersistent ? JOI_RULES.optionalString : JOI_RULES.name,
      phoneNumber: isUserPersistent
        ? JOI_RULES.optionalString
        : Joi.string().custom(isPhoneNumberValid).required(),
      surname: isUserPersistent ? JOI_RULES.optionalString : JOI_RULES.name,
    }),
    travelAgentDetails: Joi.object({
      name: JOI_RULES.name,
      travelAgencyName: JOI_RULES.optionalString,
      travelAgentId: Joi.string().required(),
      travelAgentIdConfirmation: isPreferredPartners
        ? Joi.string().optional()
        : Joi.string().required(),
      fsAdvisorId: isPreferredPartners
        ? Joi.string().required()
        : Joi.string().optional(),
      agentEmail: isUserPersistent ? JOI_RULES.optionalString : JOI_RULES.email,
      phoneNumber: isPreferredPartners
        ? Joi.string().required()
        : Joi.string().custom(isPhoneNumberValid).required(),
    }),
    termsAndConditions: Joi.object(
      employeeTermFields
        ? {
            ...employeeTermFields.reduce((termFields, termField) => {
              return {
                ...termFields,
                [termField]: Joi.boolean().invalid(false),
              };
            }, {}),
          }
        : {
            optInAgreement: Joi.boolean(),
            termsAgreement: Joi.boolean().invalid(false),
          }
    ),
    paymentMethod: Joi.string().optional(),
  });

function InnerForm({
  isBookingForMyselfSelected,
  isBookingForSomeoneElseSelected,
  isAgentBookingSelected,
  register,
  control,
  errors,
  readOnlyFields,
  allowAlipay,
  paymentMethod,
  arePaymentOptionsLoading,
  isPreferredPartners,
  hasExplicitOptIn,
  showEmployeeTerms,
  persistentValues,
}) {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div
        id="tab-panel-booking-type"
        role="tabpanel"
        aria-labelledby="booking-for-myself-button"
      >
        {isBookingForMyselfSelected && (
          <BookingForYourself
            register={register}
            control={control}
            errors={errors}
            allowAlipay={allowAlipay}
            paymentMethod={paymentMethod}
            arePaymentOptionsLoading={arePaymentOptionsLoading}
            readOnlyFields={readOnlyFields}
            hasExplicitOptIn={hasExplicitOptIn}
            showEmployeeTerms={showEmployeeTerms}
            persistentValues={persistentValues}
          />
        )}

        {isBookingForSomeoneElseSelected && (
          <BookingForSomeoneElse
            register={register}
            control={control}
            errors={errors}
            readOnlyFields={readOnlyFields}
            persistentValues={persistentValues}
          />
        )}

        {isAgentBookingSelected && (
          <TravelAgentBooking
            register={register}
            control={control}
            errors={errors}
            readOnlyFields={readOnlyFields}
            isPreferredPartners={isPreferredPartners}
          />
        )}
      </div>

      <div className="form-actions">
        <button
          className="btn btn-primary"
          type="submit"
          disabled={allowAlipay && paymentMethod === ""}
        >
          {paymentMethod !== PAYMENT_METHODS.ALIPAY ? t("Book") : t("Pay")}
        </button>
      </div>

      {isMobileApp() && (
        <button
          type="button"
          className="appear-as-link return-to-top"
          onClick={scrollToTop}
        >
          {t("Return To Top")}
        </button>
      )}
    </>
  );
}

export default function Form({
  onSubmit,
  bookingType,
  isAgentBookingSelected,
  isBookingForMyselfSelected,
  isBookingForSomeoneElseSelected,
  allowAlipay,
  arePaymentOptionsLoading,
  isPreferredPartners,
  employeeMode,
  cancelBy,
  displayCancelByMessage,
  setPaymentMethod,
  hasExplicitOptIn,
}) {
  const formValues = useSelector(selectBookingInProgressFormValues);
  const isUserPersistent = useSelector(selectIsUserPersistent);

  const { defaultValues, readOnlyFields } = useGetDefaultFormValues({
    bookingType,
    isPreferredPartners,
  });

  const { termsAndConditions } = useContext(MappingLayerContext);

  const showEmployeeTerms =
    employeeMode &&
    termsAndConditions?.length > 1 &&
    termsAndConditions.some((term) => term.checkbox);
  const employeeTermFields = showEmployeeTerms
    ? termsAndConditions.reduce((termFields, term, index) => {
        if (term.checkbox) {
          termFields.push(`termsAgreement-${index}`);
        }
        return termFields;
      }, [])
    : undefined;

  const resolver = async (data) => {
    const { error, value: values } = validationSchema({
      bookingType,
      employeeTermFields,
      isPreferredPartners,
      isUserPersistent,
    }).validate(data, {
      abortEarly: false,
    });

    const formErrors = !error
      ? {}
      : error.details.reduce(
          (acc, { path: [level1, level2] }) => ({
            ...acc,
            ...(Object.keys(acc).some((a) => level1 === a)
              ? { [level1]: { ...acc[level1], [level2]: true } }
              : { [level1]: { [level2]: true } }),
          }),
          {}
        );

    return {
      values,
      errors: { ...formErrors },
    };
  };

  const { control, register, handleSubmit, errors, watch, setValue } = useForm({
    defaultValues: formValues || defaultValues,
    resolver,
  });

  const [
    hasCardHolderNameBeenModifiedManually,
    setHasCardHolderNameBeenModifiedManually,
  ] = useState(false);

  const watchedCardHolderName = watch("creditCard.cardHolderName");
  const { watchedFirstName, watchedSurname } = getBookerNames({
    watch,
    isAgentBookingSelected,
    isBookingForSomeoneElseSelected,
  });

  const fullName = `${watchedFirstName} ${watchedSurname}`;

  useEffect(() => {
    if (watchedCardHolderName && fullName !== watchedCardHolderName) {
      setHasCardHolderNameBeenModifiedManually(true);
    }
  }, [watchedCardHolderName]);

  useEffect(() => {
    if (
      !hasCardHolderNameBeenModifiedManually &&
      [watchedFirstName, watchedSurname].some(Boolean)
    ) {
      setValue("creditCard.cardHolderName", fullName);
    }
  }, [fullName]);

  const paymentMethod = watch("paymentMethod");

  useEffect(() => {
    setPaymentMethod(paymentMethod);
  }, [paymentMethod]);

  const innerForm = (
    <>
      <InnerForm
        isBookingForMyselfSelected={isBookingForMyselfSelected}
        isBookingForSomeoneElseSelected={isBookingForSomeoneElseSelected}
        isAgentBookingSelected={isAgentBookingSelected}
        register={register}
        control={control}
        errors={errors}
        readOnlyFields={readOnlyFields}
        allowAlipay={allowAlipay}
        paymentMethod={paymentMethod}
        arePaymentOptionsLoading={arePaymentOptionsLoading}
        isPreferredPartners={isPreferredPartners}
        hasExplicitOptIn={hasExplicitOptIn}
        showEmployeeTerms={showEmployeeTerms}
        persistentValues={isUserPersistent ? defaultValues.guestDetails : {}}
      />
      {displayCancelByMessage && (
        <div className="cancellation-date-container">
          <CancellationDate cancellationBy={cancelBy} />
        </div>
      )}
    </>
  );

  if (GOOGLE_RECAPTCHA_ENABLED) {
    return (
      <FormWithRecaptcha
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        noValidate
      >
        {innerForm}
      </FormWithRecaptcha>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {innerForm}
    </form>
  );
}
