import React from "react";

import { getErrorCodeForTranslations } from "utils/errorCodes";
import { LOGIN_TYPES } from "api/tretail/authentication/generateOTP";
import { useTranslation } from "hooks";

export default function RequestOtpError({
  errorCode,
  sendCodeTo,
  sendCodeVia,
  onReset = () => {},
}) {
  const { t } = useTranslation();

  const handleKeyDown = (evt) => {
    if (evt.key === "Enter" || evt.key === " ") {
      onReset();
    }
  };

  return (
    <div role="alert">
      <div
        className="form-error try-another"
        onClick={onReset}
        role="button"
        onKeyDown={handleKeyDown}
        tabIndex="0"
      >
        <span>
          {sendCodeVia === LOGIN_TYPES.EMAIL_ADDRESS
            ? t("Sign in with another email address")
            : t("Sign in with another phone number")}
        </span>
      </div>

      <div
        className="form-error not-found"
        dangerouslySetInnerHTML={{
          __html: t(getErrorCodeForTranslations({ errorCode }), [sendCodeTo]),
        }}
      />
    </div>
  );
}
