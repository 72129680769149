import React from "react";

export function AlertIconAsHtmlString() {
  return `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M16 1a15 15 0 1 0 15 15A15 15 0 0 0 16 1Zm0 29a14 14 0 1 1 14-14 14 14 0 0 1-14 14Zm0-8.442a1.221 1.221 0 0 0-1.221 1.221A1.221 1.221 0 0 0 16 24a1.221 1.221 0 0 0 1.221-1.221A1.221 1.221 0 0 0 16 21.558Zm1.045-8.426v-4.84h-2.09v4.84l.55 5.842h.99Z" fill-rule="evenodd" fill="#EA0000"></path></svg>`;
}

export default function AlertIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        d="M16 1a15 15 0 1 0 15 15A15 15 0 0 0 16 1Zm0 29a14 14 0 1 1 14-14 14 14 0 0 1-14 14Zm0-8.442a1.221 1.221 0 0 0-1.221 1.221A1.221 1.221 0 0 0 16 24a1.221 1.221 0 0 0 1.221-1.221A1.221 1.221 0 0 0 16 21.558Zm1.045-8.426v-4.84h-2.09v4.84l.55 5.842h.99Z"
        fillRule="evenodd"
        fill="#EA0000"
      />
    </svg>
  );
}
