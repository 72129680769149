import React from "react";

import { selectPhone, deleteUserPhone, updateUserPhone } from "store/profile";
import PhoneNumberForm from "./PhoneNumberForm";
import useFormSubmit from "../../../hooks/useFormSubmit";
import useDelete from "../../../hooks/useDelete";

function EditPhoneNumber({ identifier, phoneNumber, closeForm, canBeDeleted }) {
  const {
    onSubmit,
    register,
    showLoadingIndicator,
    formState,
    watched,
    value,
    errors,
    baseError,
    control,
  } = useFormSubmit({
    closeForm,
    action: updateUserPhone,
    selector: selectPhone(phoneNumber.salesForceId),
    fieldName: "phoneData",
  });

  const { onDelete, showLoadingIndicator: showLoadingIndicatorForDeleting } =
    useDelete({
      action: deleteUserPhone,
      salesForceId: value.salesForceId,
    });

  return (
    <PhoneNumberForm
      canBeDeleted={canBeDeleted}
      closeForm={closeForm}
      identifier={identifier}
      watched={watched}
      phoneNumber={value}
      onSubmit={onSubmit}
      formState={formState}
      register={register}
      onDelete={onDelete}
      errors={errors}
      baseError={baseError}
      control={control}
      showLoadingIndicator={
        showLoadingIndicator || showLoadingIndicatorForDeleting
      }
    />
  );
}

export default EditPhoneNumber;
