// An opportunity to use Transducers: https://jrsinclair.com/articles/2019/magical-mystical-js-transducers/
// We can even use RxJs: https://dev.to/rasmusvhansen/rxjs-transducer---harness-the-power-of-rxjs-operators-1ai8
import get from "lodash/get";
import getFP from "lodash/fp/get";
import update from "lodash/fp/update";
import add from "lodash/fp/add";
import { transducer } from "rxjs-transducer";
import { from } from "rxjs";
import { map, reduce, groupBy, mergeMap } from "rxjs/operators";
import parse from "date-fns/parse";

import { addOne } from "utils/utils";
import { DATA } from "utils/dateFormats";

const getAmount = ({ cash: { amount } }) => amount;
const addAmounts = ({ total, count }, amount) => ({
  total: total + amount,
  count: addOne(count),
});
const matchTaxes =
  (code) =>
  ({ taxID }) =>
    taxID === code;

export const getTaxes = ({ price, contentTaxes = [] }) => {
  const sortOrderTaxIds = contentTaxes.map((contentTax) => contentTax.taxID);
  return transducer(from(get(price, ["prepay", "taxesFeesCharges"], [])))(
    groupBy(getFP(["code"])),
    mergeMap((group$) =>
      group$.pipe(
        reduce((acc, tax) =>
          update(["cash", "amount"], add(getFP(["cash", "amount"], tax)), acc)
        )
      )
    ),
    map(({ code, description, cash: { amount } }) => ({
      description: contentTaxes.some(matchTaxes(code))
        ? contentTaxes.find(matchTaxes(code)).description
        : description,
      amount,
      code,
    }))
  ).sort(
    (a, b) => sortOrderTaxIds.indexOf(a.code) - sortOrderTaxIds.indexOf(b.code)
  );
};

export const getAverageRate = (product) => {
  const [{ total, count }] = transducer(
    from(getFP(["roomRate", "nightlyRates"], product))
  )(map(getAmount), reduce(addAmounts, { total: 0, count: 0 }));

  return total / count;
};

export default ({
  upcomingTrip: {
    hotelProducts: [product],
  },
  taxes: contentTaxes = [],
}) => {
  const nightlyAverage =
    product?.roomRate?.averageNightlyPrice?.cash?.amount ||
    getAverageRate(product);
  const price = getFP(["price"], product);
  const nightlyRates = getFP(["roomRate", "nightlyRates"], product);
  const feesDisclaimerCheckout = product?.roomRate?.feesDisclaimerCheckout;

  return {
    base: get(price, ["prepay", "base", "cash", "amount"], ""),
    currency: get(price, ["total", "cash", "currencyCode"], ""),
    feesDisclaimerCheckout,
    nightlyAverage,
    nightlyRates: nightlyRates.map((nightlyRate) => ({
      ...nightlyRate,
      amount: nightlyRate.cash.amount,
      date: parse(nightlyRate.date, DATA, new Date()),
    })),
    taxes: getTaxes({ price, contentTaxes }),
    total: get(price, ["total", "cash", "amount"], ""),
  };
};
