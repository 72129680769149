import React from "react";
import classNames from "classnames";
import { Controller } from "react-hook-form";

import { CleaveInput } from "components";

export default function FormRowCreditCardNumber({
  id: initialId,
  name,
  className,
  label,
  errorMessage,
  ariaDescribedBy,
  control,
  ...inputProps
}) {
  const hasError = Boolean(errorMessage);
  const id = initialId || `input-${name.replace(/\./g, "_")}`;

  return (
    <div
      className={classNames("form-row string", className, {
        "has-errors": hasError,
      })}
    >
      <label htmlFor={id}>{label}</label>

      <Controller
        control={control}
        name={name}
        rules={{
          required: true,
        }}
        render={({ ...renderProps }) => (
          <CleaveInput
            asRawValue
            type="text"
            id={id}
            placeholder={label}
            aria-describedby={ariaDescribedBy}
            aria-invalid={hasError}
            required
            cleaveOptions={{ creditCard: true }}
            {...inputProps}
            {...renderProps}
          />
        )}
      />

      {hasError && (
        <div className="inline-error">
          <span id={ariaDescribedBy} className="message" role="tooltip">
            {errorMessage}
          </span>
          <span aria-hidden="true" className="icon">
            !
          </span>
        </div>
      )}
    </div>
  );
}
