import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { useTranslation, usePrevious } from "hooks";
import Button from "../components/Button";

export default function TripReasonForm({
  close,
  defaultValues,
  onSubmit,
  showSavingSpinner,
}) {
  const { t } = useTranslation();
  const prevShowSavingSpinner = usePrevious(showSavingSpinner);

  const { register, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    if (prevShowSavingSpinner && !showSavingSpinner) {
      close();
    }
  }, [prevShowSavingSpinner, showSavingSpinner]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-md-12">
          <textarea
            className="fs-trip__text_area"
            placeholder={t("Reason")}
            name="tripPurpose"
            ref={register()}
          />
        </div>
        <div className="col-sm-12">
          <div className="fs-your-preferences__preference__actions">
            <Button type="submit" aria-label={t("Update")}>
              {t("Update")}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
