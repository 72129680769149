import React from "react";
import classNames from "classnames";
import kebabCase from "lodash/fp/kebabCase";
import { useForm } from "react-hook-form";
import get from "lodash/fp/get";

import { useTranslation } from "hooks";
import { Button, Checkbox } from "Profile/components";
import TransportationFormFieldSet from "./TransportationFormFieldSet";
import ArrivalTransportationTerms from "./ArrivalTransportationTerms";

export default function TransportationForm({
  initialValues = {},
  fieldSets,
  selectedArrivalDetailsOption,
  footnote,
  isSubmitting,
  onSubmit,
  cancel,
}) {
  const { t } = useTranslation();

  const cancellationTerms = get(["cancellationPolicy", "text"])(
    selectedArrivalDetailsOption
  );

  const { currencyCode, amount } = selectedArrivalDetailsOption.priceInCurrency;

  const {
    register,
    control,
    handleSubmit,
    formState: { submitCount, errors },
  } = useForm({
    defaultValues: initialValues,
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      className="arrival-transportation__form"
    >
      {fieldSets.map((fieldSet) => (
        <div
          key={fieldSet.label}
          className={[
            "arrival-transportation__more-details__item",
            `arrival-transportation__more-details__item--${kebabCase(
              fieldSet.label
            )}`,
          ].join(" ")}
        >
          <TransportationFormFieldSet
            legend={fieldSet.label}
            fields={fieldSet.fields}
            register={register}
            control={control}
            errors={submitCount > 0 ? errors : {}}
          />
        </div>
      ))}

      <div className="arrival-transportation__estimated-total">
        <p className="ty-b1">{t("Est. Total")}*</p>
        <p className="ty-h3">
          {currencyCode} {amount}
        </p>
      </div>

      <div className="arrival-transportation__terms-list">
        {footnote && (
          <ArrivalTransportationTerms>{footnote}</ArrivalTransportationTerms>
        )}

        {cancellationTerms && (
          <ArrivalTransportationTerms title={t("Cancellation Terms")}>
            {cancellationTerms}
          </ArrivalTransportationTerms>
        )}

        <div
          className={classNames("arrival-transportation__checkbox", {
            "arrival-transportation__checkbox--has-error": Boolean(
              errors?.consent
            ),
          })}
        >
          <Checkbox name="consent" register={register({ required: true })}>
            {t("I have read and accepted the above Terms & Conditions")}
            {errors?.consent && (
              <div
                className="arrival-transportation__checkbox__error"
                role="alert"
              >
                {t("To proceed, please accept terms & conditions.")}
              </div>
            )}
          </Checkbox>
        </div>
      </div>

      <div className="arrival-transportation__actions">
        <Button type="submit" disabled={isSubmitting}>
          {t("Continue")}
        </Button>

        <Button disabled={isSubmitting} onClick={cancel} kind="secondary">
          {t("Cancel")}
        </Button>
      </div>
    </form>
  );
}
