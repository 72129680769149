import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";

import env from "config/env";
import { generateNumbersList } from "utils/utils";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

const TRETAIL_MINIMUM_CHILD_AGE = 3;

function getDiscountCode(searchParams) {
  if (searchParams.ppMode) {
    return "PREFERRED";
  }
  if (searchParams.promoCode) {
    return searchParams.promoCode.toUpperCase();
  }
  return undefined;
}

export default function createHotelSearch$({
  isAllInPricing = false,
  searchParams,
  locale,
}) {
  const discountCode = getDiscountCode(searchParams);

  return ajax({
    ...basePostRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/hotelCust/resultSets`,
    body: {
      allInPricing: isAllInPricing,
      checkinDate: searchParams.dates.checkIn,
      checkoutDate: searchParams.dates.checkOut,
      currencyCode: "USD",
      destination: {
        locationByCode: {
          code: searchParams.hotelCode,
          context: "OJ_HOTEL_CODE",
        },
      },
      rooms: searchParams.rooms.map(({ children, ...room }) => ({
        ...room,
        children:
          children > 0
            ? generateNumbersList(children).map(() => ({
                age: TRETAIL_MINIMUM_CHILD_AGE,
              }))
            : undefined,
      })),
      discountCode,
      flexDatesWindow: searchParams.flexDatesWindow
        ? parseInt(searchParams.flexDatesWindow, 10)
        : undefined,
    },
  }).pipe(pluck("response"));
}
