import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "hooks";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";

import env from "config/env";
import { isMobileApp, isIOS } from "utils";
import * as regexPatterns from "utils/regexPatterns";
import validPhoneNumber from "utils/validPhoneNumber";
import trimStringValues from "utils/trimStringValues";
import { LOGIN_TYPES } from "api/tretail/authentication/generateOTP";
import { Button, TextInput, PhoneNumber } from "Profile/components";
import useCountries from "hooks/useCountries";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import DisplayOnlyInput from "Profile/components/DisplayOnlyInput";
import useRecaptcha from "../../../BookingFlow/ConfirmYourStay/hooks/useRecaptcha";

const { GOOGLE_RECAPTCHA_PROFILE_ENABLED } = env;

export default function RequestOtpForm({
  title,
  introContent,
  emailOrPhoneNotFound,
  registerLinkTo,
  initialValues = {
    sendCodeVia: LOGIN_TYPES.EMAIL_ADDRESS,
    sendCodeTo: {
      [LOGIN_TYPES.PHONE_NUMBER]: "",
      [LOGIN_TYPES.EMAIL_ADDRESS]: "",
    },
  },
  errorComponent,
  footerComponent,
  onSubmit,
  hasError,
  isYachtsMode,
  isUserPersistent,
}) {
  const { t, locale } = useTranslation();

  const {
    recaptchaElementId,
    recaptchaCallbackName,
    isReady,
    recaptchaExecute,
  } = useRecaptcha({ locale });

  const {
    register,
    handleSubmit,
    watch,
    reset: resetForm,
    control,
    errors,
    formState,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ...initialValues,
      sendCodeTo: {
        [LOGIN_TYPES.PHONE_NUMBER]:
          initialValues.sendCodeTo[LOGIN_TYPES.PHONE_NUMBER] || "",
        [LOGIN_TYPES.EMAIL_ADDRESS]:
          initialValues.sendCodeTo[LOGIN_TYPES.EMAIL_ADDRESS] || "",
      },
    },
  });

  const { countries, isLoading: isLoadingCountries } = useCountries();

  const watchSendCodeVia = watch("sendCodeVia");

  const onSubmitAmendFormValues = ({
    sendCodeTo: sendCodeToValue,
    sendCodeVia: sendCodeViaFromValue,
    ...remainingValues
  }) => {
    const sendCodeVia = sendCodeViaFromValue || watchSendCodeVia;
    const sendCodeTo =
      sendCodeVia === LOGIN_TYPES.PHONE_NUMBER
        ? `+${sendCodeToValue[sendCodeVia].replace(/^\+/, "")}`
        : sendCodeToValue[sendCodeVia];
    onSubmit({
      ...remainingValues,
      ...trimStringValues({
        sendCodeTo,
        sendCodeVia,
        isUserPersistent,
      }),
    });
  };

  const onSubmitWithRecaptcha = (formValues) => {
    recaptchaExecute().then((gRecaptchaResponse) => {
      onSubmitAmendFormValues({ ...formValues, gRecaptchaResponse });
    });
  };

  return (
    <div className="fs-otp-sign-in__request-otp__container">
      <div className="fs-otp-sign-in__request-otp">
        {title && <h1>{title}</h1>}

        {introContent && <p className="intro">{introContent}</p>}

        <form
          onSubmit={handleSubmit(
            GOOGLE_RECAPTCHA_PROFILE_ENABLED
              ? onSubmitWithRecaptcha
              : onSubmitAmendFormValues
          )}
          noValidate
          autoComplete="off"
        >
          {!isUserPersistent &&
            errorComponent({
              resetForm: () =>
                resetForm({
                  sendCodeTo: "",
                  sendCodeVia: LOGIN_TYPES.EMAIL_ADDRESS,
                }),
            })}

          {!emailOrPhoneNotFound && (
            <div className="fs-radio-inputs">
              <label className="fs-radio" aria-label={t("Sign in by email")}>
                <input
                  type="radio"
                  name="sendCodeVia"
                  value={LOGIN_TYPES.EMAIL_ADDRESS}
                  ref={register({ required: true })}
                />
                <span>{t("Email me")}</span>
              </label>

              <label
                className="fs-radio"
                aria-label={t("Sign in by phone number")}
              >
                <input
                  type="radio"
                  name="sendCodeVia"
                  value={LOGIN_TYPES.PHONE_NUMBER}
                  ref={register({ required: true })}
                />
                <span>{t("Text me")}</span>
              </label>
            </div>
          )}

          {watchSendCodeVia === LOGIN_TYPES.EMAIL_ADDRESS && (
            <>
              {isUserPersistent && (
                <DisplayOnlyInput
                  type="text"
                  name={`sendCodeTo.${LOGIN_TYPES.EMAIL_ADDRESS}`}
                  value={initialValues.sendCodeTo[LOGIN_TYPES.EMAIL_ADDRESS]}
                  displayValue={
                    initialValues.sendCodeTo[LOGIN_TYPES.EMAIL_ADDRESS]
                  }
                  includeHiddenInput
                  register={register}
                />
              )}

              {!isUserPersistent && (
                <TextInput
                  type="email"
                  label={t("Email")}
                  placeholder={t("Email")}
                  name={`sendCodeTo.${LOGIN_TYPES.EMAIL_ADDRESS}`}
                  register={register({
                    required: true,
                    pattern: regexPatterns.email,
                  })}
                  required
                  error={Boolean(errors.sendCodeTo) || hasError}
                />
              )}
            </>
          )}

          {watchSendCodeVia === LOGIN_TYPES.PHONE_NUMBER && (
            <>
              {isUserPersistent && (
                <DisplayOnlyInput
                  type="phone"
                  name={`sendCodeTo.${LOGIN_TYPES.PHONE_NUMBER}`}
                  value={initialValues.sendCodeTo[LOGIN_TYPES.PHONE_NUMBER]}
                  displayValue={
                    initialValues.sendCodeTo[LOGIN_TYPES.PHONE_NUMBER]
                  }
                  includeHiddenInput
                  register={register}
                />
              )}

              {!isUserPersistent && (
                <Controller
                  name={`sendCodeTo.${LOGIN_TYPES.PHONE_NUMBER}`}
                  required
                  control={control}
                  countries={countries}
                  isLoadingCountries={isLoadingCountries}
                  as={<PhoneNumber />}
                  rules={{
                    required: true,
                    pattern: regexPatterns.telephoneNumber,
                    validate: { validPhoneNumber },
                  }}
                  error={Boolean(errors.sendCodeTo) || hasError}
                />
              )}
            </>
          )}

          {watchSendCodeVia === LOGIN_TYPES.PHONE_NUMBER && (
            <p
              className={classNames("rates-may-apply", {
                "persistent-login ty-d2": isUserPersistent,
              })}
            >
              {t(
                "If you choose to receive your password via mobile, we will send one text message per login attempt. Message and data rates may apply."
              )}
            </p>
          )}

          {!isUserPersistent && registerLinkTo && (
            <p className="dont-have-account">
              {t("Don’t have an account?")}{" "}
              <Link to={registerLinkTo}>{t("Register")}</Link>
            </p>
          )}

          {isUserPersistent &&
            errorComponent({
              resetForm: () =>
                resetForm({
                  sendCodeTo: "",
                  sendCodeVia: watchSendCodeVia,
                }),
            })}

          {footerComponent}

          <div
            className={classNames("view__actions", {
              "small-center": isYachtsMode,
              "persistent-login-next": isUserPersistent,
            })}
          >
            <Button
              type="submit"
              disabled={!isUserPersistent && !formState.isValid}
            >
              {isUserPersistent && hasError ? t("Get new code") : t("Next")}
            </Button>
          </div>
          {GOOGLE_RECAPTCHA_PROFILE_ENABLED && isReady && (
            <div
              id={recaptchaElementId}
              className={classNames("g-recaptcha", {
                iosBottom: isIOS() || isMobileApp(),
              })}
              data-callback={recaptchaCallbackName}
            />
          )}
          {GOOGLE_RECAPTCHA_PROFILE_ENABLED && !isReady && (
            <BookingFlowLoadingIndicator />
          )}
        </form>
      </div>
    </div>
  );
}
