import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "hooks";
import classNames from "classnames";
import { Button } from "Profile/components";
import Modal from "Profile/ProfileView/Modal";
import { selectSendFFEmailStatus } from "store/profile";

function FriendsAndFamilyConfirmationModal({ close }) {
  const { t } = useTranslation();
  const status = useSelector(selectSendFFEmailStatus);

  let msg = "";
  let failed = false;
  if (status === "success") {
    msg = "Friends and Family rate request has been sent to the Hotel/Resort.";
  } else if (status === "failure") {
    failed = true;
    msg = "Error Sending Friends and Family rate request";
  }

  return (
    <Modal close={close} className="employ-portal-modal-container">
      <div className="modal--friends-and-family view--profile-folio">
        <div className="container">
          <h3 className="ty-h3">{t("Friends and Family Rate Request")}</h3>
          <p
            className={classNames({
              "email-error": failed,
            })}
          >
            {t(msg)}
          </p>
          <Button kind="secondary" theme="light" onClick={close}>
            {t("Close")}
          </Button>
        </div>

        <button className="btn-close" type="button" onClick={close}>
          <span>{t("Back")}</span>
        </button>
      </div>
    </Modal>
  );
}

export default FriendsAndFamilyConfirmationModal;
