import React from "react";

import { useTranslation } from "hooks";

function Footer({
  canAddRooms,
  maxNumberOfBookableRooms,
  propertyPhone,
  globalTollFreeLink,
  handleAddRoomClick,
}) {
  const { t } = useTranslation();

  const message =
    propertyPhone || globalTollFreeLink ? (
      <div className="guests-dropdown-call-option">
        <span
          className="phone-number"
          dangerouslySetInnerHTML={{
            __html: propertyPhone
              ? t("bk16.callmorerooms", [
                  maxNumberOfBookableRooms,
                  propertyPhone,
                ])
              : t("bk16.callmorerooms", [
                  maxNumberOfBookableRooms,
                  `<a href="${globalTollFreeLink}" target="_blank">${t(
                    "Toll Free Telephone Numbers By Country"
                  )}</a>`,
                ]),
          }}
        />
      </div>
    ) : null;

  return canAddRooms ? (
    <button
      type="button"
      className="guests-dropdown-more-options"
      onClick={handleAddRoomClick}
    >
      {t("Add another room")}
    </button>
  ) : (
    message
  );
}

export default Footer;
