import React from "react";

export default function CheckmarkIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#32817C" />
      <path
        d="M6.75 11.2841V13.2727L10.7273 17.25L17.6875 8.30114V6.3125L10.7273 15.2614L6.75 11.2841Z"
        fill="white"
      />
    </svg>
  );
}
