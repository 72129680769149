import { ofType } from "redux-observable";
import { of } from "rxjs";
import { mergeMap, switchMap, catchError, takeUntil } from "rxjs/operators";
import get from "lodash/get";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import {
  fetchHistoricBookingForRegistration,
  fetchHistoricBookingForRegistrationCancel,
  fetchHistoricBookingForRegistrationFailed,
  fetchHistoricBookingForRegistrationFulfilled,
} from "store/bookings";
import {
  setViewState,
  setFoundReservationId,
  REGISTER_VIEW_STATES,
} from "store/registration/registration.slice";
import catchInternalServerError from "store/catchInternalServerError";
import { retrieveBooking } from "./fetchHistoricBooking";

export default function fetchHistoricBookingForRegistrationEpic(action$) {
  return action$.pipe(
    ofType(fetchHistoricBookingForRegistration.type),
    switchMap(
      ({
        payload: {
          reservationId,
          hotelCode,
          surname,
          creditCardExpiryDate,
          creditCardNumber,
          firstName,
          reservationStartDate,
          locale,
        },
      }) => {
        return ajaxWithHealthCheck$({
          locale,
        }).pipe(
          switchMap(() => {
            return retrieveBooking({
              reservationId,
              hotelCode,
              surname,
              creditCardExpiryDate,
              creditCardNumber,
              firstName,
              reservationStartDate,
              locale,
            }).pipe(
              mergeMap((booking) => {
                const foundReservationId = get(booking, [
                  "hotelProducts",
                  0,
                  "reservationId",
                ]);
                return [
                  fetchHistoricBookingForRegistrationFulfilled(booking),
                  setFoundReservationId(foundReservationId),
                  setViewState(REGISTER_VIEW_STATES.WE_FOUND_YOUR_RESERVATION),
                ];
              }),

              catchInternalServerError(),

              catchError(({ response = {} }) =>
                of(
                  fetchHistoricBookingForRegistrationFailed({
                    apiErrors: response?.apiErrors || [],
                    supplierErrors: response?.supplierErrors || [],
                  })
                )
              ),

              takeUntil(
                action$.pipe(
                  ofType(fetchHistoricBookingForRegistrationCancel.type)
                )
              )
            );
          })
        );
      }
    )
  );
}
