import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import filter from "lodash/fp/filter";
import flow from "lodash/fp/flow";
import flatMap from "lodash/fp/flatMap";
import find from "lodash/fp/find";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import map from "lodash/fp/map";
import negate from "lodash/fp/negate";
import reduce from "lodash/fp/reduce";
import set from "lodash/fp/set";
import uniqueId from "lodash/fp/uniqueId";
import merge from "lodash/merge";
import sortBy from "lodash/fp/sortBy";

import { useTranslation } from "hooks";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import {
  Button,
  ButtonOptions,
  Dropdown,
  TextInput,
  Link,
} from "Profile/components";
import formatPrice from "utils/formatPrice";
import { addRequestEvent, addRequestEventCancel } from "store/guestRequests";
import { openTransportationRequestForm } from "store/analyticEvents";
import TransportationAmenitiesLayout from "./TransportationAmenitiesLayout";
import TransportationForm from "./TransportationForm";
import ErrorBlock from "./ErrorBlock";
import ExitTransportationFormModal from "./ExitTransportationFormModal";

const getVehicleOptions = ({ pickUpLocation, currencyCode }) =>
  flow(
    find(({ name }) => name === pickUpLocation),
    getOr([], ["products"]),
    map((product) => {
      const price = flow(
        getOr([], ["prices"]),
        find({ currencyCode })
      )(product);
      return {
        title: getOr(product.name, ["name"])(price),
        subtitle: get(["subtitle"])(price),
        price: formatPrice({ currency: currencyCode, value: price.amount }),
        value: product.name,
        amount: price.amount,
      };
    }),
    sortBy("amount")
  );

export default function ArrivalTransportation({
  goBack,
  booking,
  itineraryPath,
  availableArrivalTransportations,
  isOnItineraryPage = false,
}) {
  const { t, localeIsoCode } = useTranslation();
  const dispatch = useDispatch();

  const scrollContent = useRef();

  const surname = get(["contactDetails", "name", "surname"])(booking);
  const propertyCode = get(["hotelProducts", 0, "hotelCode"])(booking);
  const reservationId = get(["hotelProducts", 0, "reservationId"])(booking);
  const currencyCode = get(["price", "total", "cash", "currencyCode"])(booking);
  const dropOffLocation = getOr("", ["hotelProducts", 0, "name"])(booking);

  const categories = getOr([], ["categories"])(availableArrivalTransportations);

  const [showExitModal, setShowExitModal] = useState(false);

  const [requestId, setRequestId] = useState(null);

  const requestState = useSelector(
    get(["guestRequests", "addingRequestStates", requestId])
  );

  const isSubmitting = requestState === "SUBMITTING";
  const requestSuccessful = requestState === "COMPLETE";
  const requestFailed = requestState === "FAILED";

  useEffect(() => {
    if (requestFailed && scrollContent?.current) {
      scrollContent.current.scrollTop = 0;
    }
  }, [requestFailed]);

  const [values, setValues] = useState({
    pickUpLocation: "",
    dropOffLocation,
    vehicleType: "",
  });

  const handleClose = () => setShowExitModal(true);

  const handleContinue = () => setShowExitModal(false);

  const handleCancel = () => {
    setValues({
      pickUpLocation: "",
      dropOffLocation,
      vehicleType: "",
    });
    goBack();
  };

  const handleChange = ({ target }) => {
    setValues((currentValues) => ({
      ...currentValues,
      vehicleType:
        target.name === "pickUpLocation" ? "" : currentValues.vehicleType,
      [target.name]: target.value,
    }));
  };

  const priceInCurrency = flow(
    find({ name: values.pickUpLocation }),
    getOr([], ["products"]),
    find({ name: values.vehicleType }),
    getOr({}, ["priceInCurrency"])
  )(categories);

  const fieldSets = priceInCurrency?.fieldSets;

  const fieldSetsToRender = filter(negate(get("hidden")))(fieldSets);

  const showTransportationForm = fieldSetsToRender.length > 0;

  const vehicleOptions = getVehicleOptions({
    pickUpLocation: values.pickUpLocation,
    currencyCode,
  })(categories);

  const selectedArrivalDetailsOption = flow(
    flatMap(get("products")),
    find({ name: values.vehicleType })
  )(categories);

  const initialValues = flow(
    flatMap(getOr([], "fields")),
    reduce((acc, { name, value }) => set(name, value)(acc), {})
  )(fieldSets);

  const handleSubmit = (formData) => {
    const nextRequestId = uniqueId("request_");
    setRequestId(nextRequestId);

    dispatch(
      addRequestEvent({
        requestId: nextRequestId,
        bookingId: booking.bookingId,
        surname,
        propertyCode,
        reservationId,
        formData: merge(initialValues, formData),
        localeIsoCode,
        isOnItineraryPage,
      })
    );
  };

  useEffect(() => {
    return () => {
      if (requestId) {
        dispatch(addRequestEventCancel({ requestId }));
        setRequestId(null);
      }
    };
  }, [requestId]);

  useEffect(() => {
    dispatch(
      openTransportationRequestForm({ hotelCode: propertyCode, booking })
    );
  }, []);

  if (requestSuccessful) {
    return (
      <TransportationAmenitiesLayout goBack={goBack}>
        <div className="arrival-transportation--success">
          <div className="arrival-transportation--success__content">
            <h3 className="ty-h3">{t("Request Submitted")}</h3>
            <p className="ty-b2">
              {t("Your arrival transportation request has been submitted.")}
              <br />
              {t("Lets us get back to you with confirmation shortly.")}
            </p>
          </div>

          <div className="arrival-transportation--success__actions">
            <Button kind="secondary" onClick={goBack}>
              {t("Close")}
            </Button>

            {itineraryPath && (
              <Link to={itineraryPath} kind="primary" onClick={goBack}>
                {t("View itinerary")}
              </Link>
            )}
          </div>
        </div>
      </TransportationAmenitiesLayout>
    );
  }

  return (
    <TransportationAmenitiesLayout
      goBack={showTransportationForm ? handleClose : goBack}
      title={availableArrivalTransportations.name}
      description={availableArrivalTransportations.description}
      imageUrl={
        availableArrivalTransportations?.image?.url ??
        "/content/dam/fourseasons/images/web/FSH/FSH_no_image_light_grey_tree_aspect16x9.jpg"
      }
      ref={scrollContent}
    >
      <div className="arrival-transportation">
        {isSubmitting && <BookingFlowLoadingIndicator />}

        {requestFailed && <ErrorBlock>{t("Something went wrong")}</ErrorBlock>}

        <fieldset className="arrival-transportation__fieldset">
          <div className="arrival-transportation__fieldset__legend ty-c3">
            {t("Arrival Pick up")}
          </div>
          <div className="arrival-transportation__fieldset__fields">
            <Dropdown
              label={t("Pick Up Location")}
              name="pickUpLocation"
              options={map(get("name"))(categories)}
              value={values.pickUpLocation}
              onChange={handleChange}
              includeBlank
              required
            />

            <TextInput
              label={t("Drop OFf Location")}
              name="dropOffLocation"
              value={values.dropOffLocation}
              onChange={handleChange}
              disabled
              required
            />

            <ButtonOptions
              label={t("Choose Vehicle Type")}
              selectedLabel={t("Vehicle Type")}
              name="vehicleType"
              options={vehicleOptions}
              value={values.vehicleType}
              onChange={handleChange}
              layout="grid"
              includeBlank
              required
            />
          </div>
        </fieldset>

        {showTransportationForm && (
          <>
            <p className="arrival-transportation__info ty-b2">
              {t(
                "Please fill in your flight information and passenger details."
              )}
            </p>

            <TransportationForm
              initialValues={initialValues}
              fieldSets={fieldSetsToRender}
              selectedArrivalDetailsOption={selectedArrivalDetailsOption}
              footnote={availableArrivalTransportations?.footnote}
              isSubmitting={isSubmitting}
              onSubmit={handleSubmit}
              cancel={handleClose}
            />
          </>
        )}
      </div>

      {showExitModal && (
        <ExitTransportationFormModal
          onCancel={handleCancel}
          onContinue={handleContinue}
        />
      )}
    </TransportationAmenitiesLayout>
  );
}
