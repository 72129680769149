import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import get from "lodash/fp/get";
import { useTranslation } from "hooks";
import { selectPropertyContent } from "store/propertyContent";
import { modifyHotelProductCust } from "store/bookings";
import { selectIsUserPersistent } from "store/profile";
import { formatArrivalTime } from "utils/utils";
import BookingFlowLoadingIndicator from "BookingFlow/components/BookingFlowLoadingIndicator";
import { selectIsRequestLoading } from "store/apiRequestStates";
import { getDefaultCheckInTime } from "utils";
import { SignInModal } from "Authentication";
import EditArrivalTimeForm from "./EditArrivalTimeForm";

export default function EditArrivalTime({ soonestBooking }) {
  const { t } = useTranslation();
  const {
    name,
    checkInTime: unformattedCheckInTime,
    hotelCode,
  } = get(["hotelProducts", "0"], soonestBooking) || {};
  const propertyContent = useSelector(selectPropertyContent(hotelCode)) || {};
  const showLoadingIndicator = useSelector(
    selectIsRequestLoading(modifyHotelProductCust.type)
  );
  const isUserPersistent = useSelector(selectIsUserPersistent);

  const checkInTime =
    formatArrivalTime(unformattedCheckInTime) ||
    getDefaultCheckInTime(propertyContent);

  const [showEditArrivalTimeForm, setShowEditArrivalTimeForm] = useState(false);

  useEffect(() => {
    if (!showLoadingIndicator || isUserPersistent) {
      setShowEditArrivalTimeForm(false);
    }
  }, [showLoadingIndicator, isUserPersistent]);

  return (
    <div className="fs-profile-edit-arrival-time">
      {showLoadingIndicator && <BookingFlowLoadingIndicator />}
      {!showEditArrivalTimeForm ? (
        <>
          <p className="fs-profile-edit-arrival-time-msg">
            {t("We look forward to welcoming you at {0} to the {1}.", [
              checkInTime,
              name,
            ])}
          </p>
          <SignInModal
            id="editArrivalTime"
            buttonLabel={t("Edit arrival time")}
            ariaLabel={t("Edit your arrival time at {0}", [checkInTime])}
            buttonType="CTA"
            onClick={() => setShowEditArrivalTimeForm(true)}
          />
        </>
      ) : (
        <EditArrivalTimeForm soonestBooking={soonestBooking} />
      )}
    </div>
  );
}
