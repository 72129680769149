/* eslint-disable react/require-default-props, jsx-a11y/control-has-associated-label */
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import formatBeverlyWilshireSubstr from "Profile/utils/formatBeverlyWilshireSubstr";

import { ThemeContext } from "contexts/ThemeContext";

function Dropdown({
  identifier,
  label,
  name,
  options: initialOptions,
  placeholder,
  includeBlank = false,
  error = "",
  disabled = false,
  readOnly = false,
  required = false,
  requiredIndicator = "*",
  value: initialValue = "",
  defaultValue,
  register,
  className = "",
  formElementFieldFont = "ty-b2",
  forceLabel = false,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  labelFn,
  ...props
}) {
  const { theme } = useContext(ThemeContext);

  const [value, setValue] = useState(initialValue);
  const [isFocused, setIsFocused] = useState(false);
  const id = identifier || `input-${name}`;
  const hasValue = value.length > 0;
  const hasError = typeof error === "boolean" ? error : error.length > 0;

  const options = initialOptions.map((option) =>
    typeof option === "string" || typeof option === "number"
      ? { value: option, label: option }
      : option
  );

  const selectedOption = options.find((o) => o?.value === value);

  let selectedOptionStr =
    selectedOption?.selectedLabel ||
    selectedOption?.label ||
    placeholder ||
    label;
  let propNameBEV = false;

  if (
    selectedOptionStr &&
    selectedOptionStr.indexOf("Beverly Wilshire, A Four Seasons Hotel") >= 0
  ) {
    propNameBEV = true;
    selectedOptionStr = formatBeverlyWilshireSubstr(selectedOptionStr);
  }

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (evt) => {
    setValue(evt.target.value);
    onChange(evt);
  };

  const handleFocus = (evt) => {
    setIsFocused(true);
    onFocus(evt);
  };

  const handleBlur = (evt) => {
    setIsFocused(false);
    onBlur(evt);
  };

  const blankOption = () => {
    const blankLabel = typeof includeBlank === "string" ? includeBlank : "";
    return (
      <option key="__blank__" value="" label={blankLabel}>
        {blankLabel}
      </option>
    );
  };

  const uncontrolledInputProps = {
    defaultValue,
  };

  const controlledInputProps = {
    value,
  };

  return (
    <div
      className={classNames("Dropdown", className, {
        "Dropdown--dark": theme === "dark",
        "Dropdown--error": hasError,
      })}
    >
      <select
        className={classNames("formElement-field", formElementFieldFont, {
          "formElement-field--invalid": hasError,
        })}
        id={id}
        name={name}
        disabled={disabled}
        readOnly={readOnly}
        ref={register}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...(register ? uncontrolledInputProps : controlledInputProps)}
        {...props}
      >
        {(includeBlank || value === "") && blankOption()}

        {options.map((option) => (
          <option
            key={option.value + option.label}
            label={option.label}
            value={option.value}
            disabled={option?.disabled}
          >
            {option.label}
          </option>
        ))}
      </select>

      {label && (
        <label
          htmlFor={id}
          className={classNames("formElement-label ty-c4", {
            "formElement--focusAlways":
              forceLabel ||
              (placeholder && placeholder !== label) ||
              hasValue ||
              isFocused,
          })}
        >
          {labelFn
            ? labelFn(
                `${label}${required ? requiredIndicator : ""}`,
                selectedOptionStr
              )
            : `${label}${required ? requiredIndicator : ""}`}
        </label>
      )}

      {hasError && typeof error === "string" && (
        <span className="formElement-message" role="alert">
          {error}
        </span>
      )}

      {propNameBEV && (
        <div
          className={classNames(
            "formElement-field",
            formElementFieldFont,
            "mask"
          )}
          dangerouslySetInnerHTML={{
            __html: selectedOptionStr,
          }}
        />
      )}
      {!propNameBEV && (
        <div
          className={classNames(
            "formElement-field",
            formElementFieldFont,
            "mask"
          )}
        >
          {selectedOption?.selectedLabel ||
            selectedOption?.label ||
            placeholder ||
            label}
          {!selectedOption &&
            (placeholder || label) &&
            required &&
            requiredIndicator}
        </div>
      )}
    </div>
  );
}

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        selectedLabel: PropTypes.string,
      })
    ),
  ]).isRequired,
  placeholder: PropTypes.string,
  includeBlank: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  requiredIndicator: PropTypes.string,
  value: PropTypes.string,
  register: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

Dropdown.defaultProps = {
  includeBlank: false,
  placeholder: undefined,
  error: "",
  disabled: false,
  readOnly: false,
  required: false,
  requiredIndicator: "*",
  value: "",
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
};

export default Dropdown;
