import flow from "lodash/fp/flow";
import filter from "lodash/fp/filter";
import head from "lodash/fp/head";

const upcomingTripRegex = /^(\/\S{2})?(\/profile)?\/upcoming-trip\/(\S{6})/;
const hotelCodeQueryParamRegex = /hotelCode=(\S{6})/;
const hotelCodeRegex = /^\S{6}$/;

const getHotelCodeFromPathname = flow(
  (pathname) =>
    pathname.match(upcomingTripRegex) ||
    pathname.match(hotelCodeQueryParamRegex),
  filter(Boolean),
  filter((str) => hotelCodeRegex.test(str)),
  head
);

export default getHotelCodeFromPathname;
