import contentAjax from "api/content/contentAjax";

export default function getPropertyWebCheckin$({
  locale = "en",
  propertyCode,
}) {
  return contentAjax({
    locale,
    url: `/properties/${propertyCode}/web-checkin`,
  });
}
