import React, { useState } from "react";

export default function MembershipBenefits({
  label,
  icon,
  initialIsOpen = false,
  items = [],
}) {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [buttonIcon, setButtonIcon] = useState(
    initialIsOpen ? "less_sign" : "plus_sign"
  );

  const toggleBenefits = () => {
    if (isOpen) {
      setIsOpen(false);
      setButtonIcon("plus_sign");
    } else {
      setIsOpen(true);
      setButtonIcon("less_sign");
    }
  };

  return (
    <div className="fs-membership-benefit-block">
      <div className={`fs-membership-benefit__row fs-member-benefits--${icon}`}>
        <div className="fs-membership-benefit__label">
          <div className="fs-membership-benefit-icon" />
          <div className="fs-membership-benefit__label-text ty-c3">{label}</div>
          <div className="fs-membership-benefit-toggle">
            <button
              type="button"
              aria-hidden="true"
              className={`fs-membership-benefit-toggle-button icon-${buttonIcon}`}
              onClick={toggleBenefits}
            />
          </div>
        </div>
      </div>
      <div className="fs-membership-benefit__row">
        {isOpen && (
          <ul className="fs-membership-benefit-items">
            {items.map((item, idx) => (
              <li key={`${label}:${idx}`}> {item} </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
