import { ofType } from "redux-observable";
import {
  catchError,
  mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
} from "rxjs/operators";
import { push } from "connected-react-router";
import get from "lodash/get";

import * as bookingFlowRoutes from "BookingFlow/bookingFlowRoutes";
import * as profileRoutes from "Profile/profileRoutes";
import {
  checkEmployeePropertyLimits,
  checkEmployeeRoomNights,
  getQueryStringFromSearchFormValues,
  hasEmployeeSameDayBooking,
} from "BookingFlow/utils";
import { EMPLOYEE_SEARCH_ERRORS } from "store/searchResults/checkEmployeeSearchParams";
import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { completeBooking$ } from "api/tretail/booking";
import { fetchBookingCommentsFulfilled } from "store/bookingComments";
import catchInternalServerError from "store/catchInternalServerError";
import { fetchStayHistory$ } from "store/stayHistory";
import { getDateForEmployeeRoomNights } from "utils/datesHelpers";
import {
  completeBooking,
  completeBookingFailed,
  completeBookingFulfilled,
  completeBookingCancel,
} from "../bookings.slice";
import fetchBookingWithCommentsById$ from "./fetchBookingWithCommentsById";
import { getBookingTypeAndEmailOptions } from "../emailHelpers";

function completeBookingAndFetchComments$({
  locale,
  bookingId,
  suppressPaymentOptions,
  contactType,
  bookerDetails,
  travelAgentDetails,
  guestDetails,
  creditCard,
  termsAndConditions,
  amount,
  gRecaptchaResponse,
  salesChannel,
  isUserPersistent,
}) {
  return completeBooking$({
    locale,
    bookingId,
    suppressPaymentOptions,
    contactType,
    bookerDetails,
    travelAgentDetails,
    guestDetails,
    creditCard,
    termsAndConditions,
    amount,
    gRecaptchaResponse,
    salesChannel,
    isUserPersistent,
  }).pipe(
    switchMap((booking) => {
      return fetchBookingWithCommentsById$({
        bookingId: booking.bookingId,
        locale,
      }).pipe(
        mergeMap(({ preBookingComments, postBookingComments }) => {
          const bookingTypeAndEmailOptions = getBookingTypeAndEmailOptions({
            contactType,
            guestDetails,
          });

          return [
            fetchBookingCommentsFulfilled({
              bookingId: booking.bookingId,
              reservationId: get(booking, [
                "hotelProducts",
                0,
                "reservationId",
              ]),
              preBookingComments,
              postBookingComments,
            }),
            completeBookingFulfilled({ booking }),
            push({
              pathname: bookingFlowRoutes.personalizeYourStay.to({
                locale,
              }),
              state: {
                ...bookingTypeAndEmailOptions,
              },
            }),
          ];
        })
      );
    })
  );
}

export default function completeBookingEpic(action$, state$) {
  return action$.pipe(
    ofType(completeBooking.type),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const {
        locale,
        employeeMode = false,
        suppressPaymentOptions = false,
        isUserPersistent = false,
      } = payload;

      const salesChannel = state.router?.location?.query?.channel;

      const {
        bookings: {
          bookingInProgressId,
          bookingsInProgress,
          bookingInProgressSearchParams: searchParams = {},
        },
      } = state;

      const bookingInProgress = bookingsInProgress[bookingInProgressId];

      const {
        price: {
          total: {
            cash: { amount },
          },
        },
      } = bookingInProgress;

      return ajaxWithHealthCheck$({
        locale,
        propertyCode: searchParams?.hotelCode,
        searchParams,
      }).pipe(
        switchMap(() => {
          if (!employeeMode) {
            return completeBookingAndFetchComments$({
              locale,
              bookingId: bookingInProgressId,
              suppressPaymentOptions,
              contactType: payload.contactType,
              bookerDetails: payload.bookerDetails,
              travelAgentDetails: payload.travelAgentDetails,
              guestDetails: payload.guestDetails,
              creditCard: payload.creditCard,
              termsAndConditions: payload.termsAndConditions,
              amount,
              gRecaptchaResponse: payload.gRecaptchaResponse,
              salesChannel,
              isUserPersistent,
            });
          }

          return fetchStayHistory$({
            locale,
            startDate: getDateForEmployeeRoomNights(searchParams?.dates),
          }).pipe(
            switchMap((employeeStayHistory) => {
              const globalSettings = state.globalSettings?.data;

              let employeeBookingError;
              if (
                !employeeStayHistory?.totalResults &&
                employeeStayHistory?.totalResults !== 0
              ) {
                employeeBookingError = EMPLOYEE_SEARCH_ERRORS.TECHNICAL;
              }

              if (!employeeBookingError) {
                employeeBookingError = hasEmployeeSameDayBooking({
                  searchParams,
                  bookings: employeeStayHistory?.bookingSummaries,
                });
              }

              if (!employeeBookingError) {
                employeeBookingError = checkEmployeePropertyLimits({
                  searchParams,
                  stayHistory: employeeStayHistory.bookingSummaries,
                  globalSettings,
                });
              }

              if (!employeeBookingError) {
                employeeBookingError = checkEmployeeRoomNights({
                  searchParams,
                  stayHistory: employeeStayHistory.bookingSummaries,
                  globalSettings,
                });
              }

              if (employeeBookingError) {
                return [
                  completeBookingFailed({
                    errors:
                      typeof employeeBookingError === "string"
                        ? [{ errorCode: employeeBookingError }]
                        : [employeeBookingError],
                  }),
                  push({
                    pathname: profileRoutes.employeeProfilePath.to({
                      locale,
                    }),
                    search: getQueryStringFromSearchFormValues(searchParams),
                  }),
                ];
              }

              return completeBookingAndFetchComments$({
                locale,
                bookingId: bookingInProgressId,
                suppressPaymentOptions,
                contactType: payload.contactType,
                bookerDetails: payload.bookerDetails,
                travelAgentDetails: payload.travelAgentDetails,
                guestDetails: payload.guestDetails,
                creditCard: payload.creditCard,
                termsAndConditions: payload.termsAndConditions,
                amount,
                gRecaptchaResponse: payload.gRecaptchaResponse,
                salesChannel,
              });
            })
          );
        }),

        catchInternalServerError(),

        catchError(({ response }) => {
          return [
            completeBookingFailed({
              apiErrors: response?.apiErrors || [],
              supplierErrors: response?.supplierErrors || [],
            }),
          ];
        }),

        takeUntil(action$.pipe(ofType(completeBookingCancel.type)))
      );
    })
  );
}
