import React from "react";

import BookingMessage from "./BookingMessage";

function BookingMessages({ bookingMessages }) {
  return (
    <div className="booking-messages booking-messages--style-2">
      <ul className="booking-messages__list">
        {bookingMessages.map(({ title, body }, index) => (
          <BookingMessage key={index} title={title} body={body} />
        ))}
      </ul>
    </div>
  );
}

export default BookingMessages;
