import React from "react";

import { useTranslation } from "hooks";
import { getTollFreeNumbersLink } from "utils/externalLinks";
import { Link } from "Profile/components";

export default function GetInTouch() {
  const { t, locale } = useTranslation();
  return (
    <div className="find-reservations__get-in-touch">
      <h2 className="find-reservations__get-in-touch__title ty-h3">
        {t("Get in Touch")}
      </h2>
      <div className="find-reservations__get-in-touch__border">
        <div className="find-reservations__get-in-touch__row">
          <div className="find-reservations__get-in-touch__column">
            <p className="find-reservations__get-in-touch__copy ty-q2">
              {t("For further reservations assistance")}
            </p>
          </div>
          <div className="find-reservations__get-in-touch__actions">
            <Link kind="link" href={getTollFreeNumbersLink(locale)}>
              {t("Toll Free Numbers by Country")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
