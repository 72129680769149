import queryString from "query-string";

import { languagesRouteParam, getUrlLocale } from "config/languages";

export const planYourStay = {
  path: `${languagesRouteParam}/plan-your-stay`,
  to: ({
    locale = "en",
    plainSearchParams,
    queryParams: initialQueryParams = {},
  } = {}) => {
    const queryParams =
      plainSearchParams || queryString.stringify(initialQueryParams);
    return [`/${getUrlLocale(locale)}plan-your-stay`, queryParams]
      .filter(Boolean)
      .join("?");
  },
  getPageTitle: ({ isPreferredPartners = false } = {}) =>
    isPreferredPartners ? "Plan Stay" : "Plan Your Stay",
  htmlBodyClassName: "view-booking-flow__plan-your-stay",
};

export const chooseYourRoom = {
  path: [`${languagesRouteParam}/choose-your-room`, `${languagesRouteParam}/?`],
  to: ({ locale = "en" } = {}) => `/${getUrlLocale(locale)}choose-your-room`,
  getPageTitle: ({ isPreferredPartners = false } = {}) =>
    isPreferredPartners ? "Choose Room" : "Choose Your Room",
  htmlBodyClassName: "view-booking-flow__choose-rooms",
};

export const confirmYourStay = {
  path: `${languagesRouteParam}/confirm-your-stay`,
  to: ({ locale = "en" } = {}) => `/${getUrlLocale(locale)}confirm-your-stay`,
  getPageTitle: ({ isPreferredPartners = false } = {}) =>
    isPreferredPartners ? "Confirm" : "Confirm Your Stay",
  htmlBodyClassName: "view-booking-flow__confirm-your-stay",
};

export const personalizeYourStay = {
  path: `${languagesRouteParam}/personalize-your-stay`,
  to: ({ locale = "en" } = {}) =>
    `/${getUrlLocale(locale)}personalize-your-stay`,
  getPageTitle: ({ isPreferredPartners = false } = {}) =>
    isPreferredPartners ? "Personalize" : "Personalize Your Stay",
  htmlBodyClassName: "view-booking-flow__personalize-your-stay",
};

export const embeddedCheckAvailabilityWidget = {
  path: `${languagesRouteParam}/caw`,
  to: ({ locale = "en" } = {}) => `/${getUrlLocale(locale)}caw`,
  getPageTitle: () => "Plan Your Stay",
  htmlBodyClassName: "view-booking-flow__plan-your-stay",
};
