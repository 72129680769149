import queryString from "query-string";
import { parse, format, sub } from "date-fns";
import { identity } from "lodash/fp";

import { generateNumbersList, stringToInt } from "utils/utils";

const splitTargetPropertyCodes = (targetPropertyCodes) => {
  if (!targetPropertyCodes) return undefined;

  return targetPropertyCodes.replace(/\[|\]|"|\s/g, "").split(",");
};

const reformatDate = (date) => {
  if (!date) return undefined;

  try {
    const dateFormat = date.includes("/") ? "MM/dd/yyyy" : "yyyy-MM-dd";
    const parsedDate = parse(date, dateFormat, new Date());

    return format(parsedDate, "yyyy-MM-dd");
  } catch (e) {
    return format(new Date(), "yyyy-MM-dd");
  }
};

const calculateCheckInEnd = (date, params) => {
  if (!date) return undefined;

  try {
    const parsedDate = parse(date, "yyyy-MM-dd", new Date());
    const offset = stringToInt(params.minStay) || 1;

    return format(sub(parsedDate, { days: offset }), "yyyy-MM-dd");
  } catch (e) {
    return e;
  }
};

const queryParamsMap = new Map([
  ["cawConfig.caEnabled", { key: "calendarAvailabilityEnabled" }],
  ["cawConfig.closingDate", { key: "checkOutEnd" }],
  ["cawConfig.cta", { key: "ctaText" }],
  ["cawConfig.hidePromoCode", { key: "hidePromoCode" }],
  ["cawConfig.maxNumberOfRooms", { key: "maxRooms" }],
  ["cawConfig.minNumberOfNights", { key: "minStay" }],
  ["cawConfig.promoByGuest", { key: "promoByGuest" }],
  ["cawConfig.startDate", { key: "checkInStart" }],
  [
    "cawConfig.targetPropertyCodes",
    { key: "propertyList", f: splitTargetPropertyCodes },
  ],
  ["generalReservationForm.checkInDate", { key: "checkIn", f: reformatDate }],
  ["generalReservationForm.checkOutDate", { key: "checkOut", f: reformatDate }],
  ["generalReservationForm.locationId", { key: "hotelCode" }],
  ["generalReservationForm.promoCode", { key: "promoCode" }],
  ["generalReservationForm.ratePlanCode", { key: "ratePlanCode" }],
  ["generalReservationForm.roomOwsCodes", { key: "roomOwsCodes" }],
  ["hotel-code", { key: "hotelCode" }],
  ["promo-code", { key: "promoCode" }],
  ["ta.ppId", { key: "ppId" }],
  ["ta.ppIdType", { key: "pullTAInfo" }],
]);

const blacklist = [
  "cawConfig.guests",
  "generalReservationForm.showAllAvailablePackages",
  "generalReservationForm.locationName",
  "locale",
  "actionStep",
  "startBookingFlow",
  "cawConfig.customBooker",
  "cawConfig.fixedAD",
  "cawConfig.showAllPkgs",
  "ta.ppQualifier",
];
const ppParams = ["ppMode", "ppId", "pullTAInfo", "ppUrl"];
const ppBlackList = [
  "maxRooms",
  "checkInStart",
  "checkOutEnd",
  "minStay",
  "hidePromoCode",
  "propertyList",
  "promoByGuest",
];

const guestCountPerRoomRegex =
  /generalReservationForm.guestCountPerRoom\[\d+\]./g;

const shouldHandleEmployeeCode = (promoCode) =>
  promoCode && ["EMPCMP##", "EMPECE##"].some((a) => a === promoCode);

const handleEmployeeMode = (params) =>
  shouldHandleEmployeeCode(params.promoCode)
    ? {
        ...params,
        employeeMode: true,
      }
    : params;

const handlePreferredPartnerMode = (params) =>
  Boolean(params.ppMode) && Boolean(params.ppId) && Boolean(params.pullTAInfo)
    ? Object.keys(params).reduce(
        (acc, param) =>
          ppBlackList.includes(param)
            ? acc
            : { ...acc, [param]: params[param] },
        {}
      )
    : Object.keys(params).reduce(
        (acc, param) =>
          ppParams.includes(param) ? acc : { ...acc, [param]: params[param] },
        {}
      );

const didAdjustModeParams = (params, completeParams) =>
  Boolean(params.ppMode) !== Boolean(completeParams.ppMode) ||
  Boolean(params.employeeMode) !== Boolean(completeParams.employeeMode);

const getCompleteQueryParamsMaps = (maxNoOfRooms) =>
  maxNoOfRooms > 0
    ? generateNumbersList(maxNoOfRooms, 0).reduce((acc, roomCount) => {
        acc.set(
          `generalReservationForm.guestCountPerRoom[${roomCount}].adultCount`,
          { key: "adults" }
        );
        acc.set(
          `generalReservationForm.guestCountPerRoom[${roomCount}].childCount`,
          { key: "children" }
        );

        return acc;
      }, queryParamsMap)
    : queryParamsMap;

const handlePromoCode = (params) =>
  !params.promoByGuest && params.promoCode
    ? {
        ...params,
        [params.hidePromoCode ? "promoHide" : "promoDisabled"]: true,
      }
    : {
        ...params,
        ...(params?.ppMode === true ? { promoHide: true } : {}),
      };

const handleCheckInEndDate = (params) =>
  params.checkOutEnd
    ? {
        ...params,
        checkInEnd: calculateCheckInEnd(params.checkOutEnd, params),
      }
    : params;

const removeBlacklisted = (params) =>
  Object.keys(params).reduce(
    (acc, param) =>
      blacklist.includes(param) ? acc : { ...acc, [param]: params[param] },
    {}
  );

const transformer = (params, completeQueryParamsMaps) => (acc, paramKey) => {
  const { key = paramKey, f = identity } =
    completeQueryParamsMaps.get(paramKey) || {};

  return {
    ...acc,
    [key]: Object.prototype.hasOwnProperty.call(acc, key)
      ? [
          ...(Array.isArray(acc[key]) ? acc[key] : [acc[key]]),
          f(params[paramKey], params),
        ]
      : f(params[paramKey], params),
  };
};

const map = (params, completeQueryParamsMaps) =>
  Object.keys(params).reduce(transformer(params, completeQueryParamsMaps), {});

const mapQueryParams = (location) => {
  const params = queryString.parse(location.search || "", {
    sort: false,
    arrayFormat: "index",
  });

  const maxNoOfRoomsArray = Object.keys(params)
    .filter((key) => key.match(guestCountPerRoomRegex))
    .map((matched) => stringToInt(matched.split(/[[\]]/)[1]) + 1);

  const completeQueryParamsMaps = getCompleteQueryParamsMaps(
    Math.max(...[0, ...maxNoOfRoomsArray])
  );

  const completeParams = removeBlacklisted(
    handleEmployeeMode(
      handlePreferredPartnerMode(
        handleCheckInEndDate(
          handlePromoCode(map(params, completeQueryParamsMaps))
        )
      )
    )
  );

  const shouldRedirect = Boolean(
    Array.from([...completeQueryParamsMaps.keys(), ...blacklist]).some((key) =>
      Object.prototype.hasOwnProperty.call(params, key)
    ) || didAdjustModeParams(params, completeParams)
  );

  return shouldRedirect
    ? {
        shouldRedirect,
        reformatedLocation: `${location.pathname}?${queryString.stringify(
          completeParams,
          {
            sort: false,
          }
        )}`,
        completeParams,
      }
    : {
        shouldRedirect,
        reformatedLocation: location,
        completeParams,
      };
};

export default mapQueryParams;
