import selectRoomsData from "store/mlSelectors";
import * as bookingFlowRoutes from "BookingFlow/bookingFlowRoutes";
import { getQueryStringFromSearchFormValues } from "BookingFlow/utils";

const fetchedSearchResultsRouter =
  ({ geoIpCountryCode, locale, searchParams }) =>
  (state) => {
    const { bookingError } = selectRoomsData({
      ...searchParams,
      locale,
      geoIpCountryCode,
    })(state);

    return bookingError
      ? {
          pathname: bookingFlowRoutes.planYourStay.to({ locale }),
          search: getQueryStringFromSearchFormValues(searchParams),
          state: { bookingError },
        }
      : {
          pathname: bookingFlowRoutes.chooseYourRoom.to({ locale }),
          search: getQueryStringFromSearchFormValues(searchParams),
        };
  };

export default fetchedSearchResultsRouter;
