import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useDispatchWithLocale } from "hooks";
import {
  selectCountriesContent,
  selectIsLoadingCountriesContent,
  selectHasRequestedCountriesContent,
  fetchCountriesContent,
  fetchCountriesContentCancel,
} from "store/countriesContent";

export default function useCountries() {
  const dispatchWithLocale = useDispatchWithLocale();

  const countries = useSelector(selectCountriesContent);
  const isLoading = useSelector(selectIsLoadingCountriesContent);
  const hasRequested = useSelector(selectHasRequestedCountriesContent);

  useEffect(() => {
    if (!hasRequested && !isLoading && countries.length === 0) {
      dispatchWithLocale(fetchCountriesContent());
    }
    return () => {
      if (isLoading) {
        dispatchWithLocale(fetchCountriesContentCancel());
      }
    };
  }, [isLoading, hasRequested, countries.length]);

  return {
    countries,
    isLoading,
  };
}
