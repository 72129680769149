import { useSelector } from "react-redux";
import get from "lodash/get";

import {
  selectProfile,
  selectIsUserLoggedIn,
  selectIsUserPersistent,
} from "store/profile";
import { selectPreferredPartnerDetails } from "store/preferredPartnerDetails";
import * as regexPatterns from "utils/regexPatterns";
import { SECTIONS } from "./constants";

function getUserValuesFromProfile({ userProfile }) {
  const firstName = get(userProfile, ["name", "firstName"], "");
  const surname = get(userProfile, ["name", "surname"], "");
  const addresses = (userProfile?.address || [])
    .filter((a) => userProfile?.dataRedacted || a.activeAddress)
    .sort((a) => (a.defaultAddress ? -1 : 1));
  const emails = (userProfile?.emails || [])
    .filter((e) => userProfile?.dataRedacted || e.activeEmail)
    .sort((e) => (e.defaultEmail ? -1 : 1));
  const phoneNumbers = (userProfile?.phones || [])
    .filter((p) => userProfile?.dataRedacted || p.activePhone)
    .sort((p) => (p.defaultPhone ? -1 : 1));
  const email = get(emails, [0, "email"], "");
  const phoneNumber = get(phoneNumbers, [0, "number"], "");
  const countryCode = get(addresses, [0, "country", "code"], "");
  const cardHolderName = [firstName, surname].filter(Boolean).join(" ");

  return {
    firstName,
    surname,
    email,
    countryCode,
    phoneNumber,
    cardHolderName,
  };
}

function getDefaultFormValuesForMyself({
  userProfile,
  isSignedIn,
  isUserPersistent,
}) {
  const {
    firstName,
    surname,
    email,
    countryCode,
    phoneNumber,
    cardHolderName,
  } = getUserValuesFromProfile({ userProfile });

  return {
    defaultValues: {
      guestDetails: {
        firstName,
        surname,
        phoneNumber,
        email,
        confirmEmail: email,
        countryCode,
      },
      creditCard: {
        cardHolderName,
        number: "",
        expiryDateMonth: "",
        expiryDateYear: "",
      },
      termsAndConditions: {
        termsAgreement: false,
        optInAgreement: false,
      },
    },
    readOnlyFields: isSignedIn
      ? {
          guestDetails: [
            "firstName",
            "surname",
            "phoneNumber",
            "email",
            "confirmEmail",
            ...(isUserPersistent ? ["countryCode"] : []),
          ],
        }
      : {},
  };
}

function getDefaultFormValuesForSomeoneElse({
  userProfile,
  isSignedIn,
  isUserPersistent,
}) {
  const { firstName, surname, email, phoneNumber, cardHolderName } =
    getUserValuesFromProfile({ userProfile });

  return {
    defaultValues: {
      bookerDetails: {
        firstName,
        surname,
        phoneNumber,
        email,
        confirmEmail: email,
      },
      guestDetails: {
        firstName: "",
        surname: "",
        phoneNumber: "",
        email: "",
        confirmEmail: "",
        sendGuestConfirmation: true,
        countryCode: "",
      },
      creditCard: {
        cardHolderName,
        number: "",
        expiryDateMonth: "",
        expiryDateYear: "",
      },
      termsAndConditions: {
        termsAgreement: false,
      },
    },
    readOnlyFields: isSignedIn
      ? {
          bookerDetails: [
            "firstName",
            "surname",
            "phoneNumber",
            "email",
            "confirmEmail",
            ...(isUserPersistent ? ["countryCode"] : []),
          ],
        }
      : {},
  };
}

function getDefaultFormValuesForTravelAgent({ userProfile, isSignedIn }) {
  const { title, firstName, surname, email } = getUserValuesFromProfile({
    userProfile,
  });

  return {
    defaultValues: {
      travelAgentDetails: {
        name: [title, firstName, surname].filter(Boolean).join(" "),
        travelAgencyName: "",
        travelAgentId: "",
        travelAgentIdConfirmation: "",
        agentEmail: email,
        phoneNumber: "",
      },
      guestDetails: {
        firstName: "",
        surname: "",
        phoneNumber: "",
        email: "",
        confirmEmail: "",
        sendGuestConfirmation: true,
        countryCode: "",
      },
      creditCard: {
        cardHolderName: "",
        number: "",
        expiryDateMonth: "",
        expiryDateYear: "",
      },
      termsAndConditions: {
        termsAgreement: false,
      },
    },
    readOnlyFields: isSignedIn ? { travelAgentDetails: ["agentEmail"] } : {},
  };
}

function getDefaultFormValuesForPreferredPartner(ppDetails) {
  const { agentName, agencyName, agentId, taid, agentEmail, agentPhone } =
    ppDetails;

  return {
    defaultValues: {
      travelAgentDetails: {
        name: agentName || "",
        travelAgencyName: agencyName || "",
        travelAgentId: agentId || "",
        fsAdvisorId: taid || "",
        agentEmail: agentEmail || "",
        phoneNumber: agentPhone || "",
      },
      guestDetails: {
        firstName: "",
        surname: "",
        phoneNumber: "",
        email: "",
        confirmEmail: "",
        sendGuestConfirmation: false,
        countryCode: "",
      },
      creditCard: {
        cardHolderName: "",
        number: "",
        expiryDateMonth: "",
        expiryDateYear: "",
      },
      termsAndConditions: {
        termsAgreement: false,
      },
    },
    readOnlyFields: {
      travelAgentDetails:
        agentEmail && regexPatterns.email.test(agentEmail)
          ? ["fsAdvisorId", "agentEmail"]
          : ["fsAdvisorId"],
    },
  };
}

export default function useGetDefaultFormValues({
  bookingType,
  isPreferredPartners,
}) {
  const userProfile = useSelector(selectProfile);
  const isSignedIn = useSelector(selectIsUserLoggedIn);
  const isUserPersistent = useSelector(selectIsUserPersistent);
  const ppDetails = useSelector(selectPreferredPartnerDetails) || {};

  switch (bookingType) {
    case SECTIONS.SOMEONE_ELSE:
      return getDefaultFormValuesForSomeoneElse({
        userProfile,
        isSignedIn,
        isUserPersistent,
      });

    case SECTIONS.AGENT:
      if (isPreferredPartners) {
        return getDefaultFormValuesForPreferredPartner(ppDetails);
      }
      return getDefaultFormValuesForTravelAgent({ userProfile, isSignedIn });

    default:
      return getDefaultFormValuesForMyself({
        userProfile,
        isSignedIn,
        isUserPersistent,
      });
  }
}
