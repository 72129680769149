import React from "react";

export default function ChatIcon() {
  return (
    <svg
      width="23px"
      height="23px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M 14.9287 14.7194 L 15.5968 14.2718 C 17.8038 12.7932 19 10.8386 19 8.5734 C 19 4.428 15.0032 1.0023 10 1 L 10 1 C 4.8782 1.1026 1 4.4878 1 8.5711 C 1 12.7166 4.9968 16.1423 10 16.1423 C 10.1175 16.1423 10.2438 16.1339 10.3816 16.1172 C 10.6485 16.0848 10.9012 16.0329 11.3829 15.9184 C 11.8118 15.8164 11.9113 15.7935 12.1113 15.7536 L 12.4759 15.681 L 16.1146 17.7408 L 14.9287 14.7194 Z M 20 8.5734 C 20 11.3183 18.4951 13.5338 16.1534 15.1026 L 18.0756 20 L 12.3067 16.7344 C 11.5769 16.8797 10.7792 17.1423 10 17.1423 C 4.4758 17.1423 0 13.306 0 8.5711 C 0 3.8365 4.4757 0 10 0 C 15.5242 0.0023 20 3.8386 20 8.5734 Z" />
    </svg>
  );
}
