import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { useTranslation, useDispatchWithLocale } from "hooks";
import { ErrorMessage, Checkbox, Button } from "Profile/components";
import {
  modifyHotelProductCust,
  modifyHotelProductCustClear,
  selectModifyDatesError,
} from "store/bookings";
import { selectIsRequestFulfilled } from "store/apiRequestStates";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import BookingSummary from "./BookingSummary";

export default function ConfirmDateChange({
  endDate,
  goBackHandler,
  handleUpdateTravelDates,
  highlights,
  rates,
  showSavingSpinner,
  startDate,
  termsForDateChange,
}) {
  const { t } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();

  const cancelButtonRef = useRef();
  const { handleSubmit, register, formState } = useForm({ mode: "onChange" });

  const onSubmit = () => handleUpdateTravelDates({ startDate, endDate });

  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const toggleShowTermsAndConditions = () =>
    setShowTermsAndConditions(!showTermsAndConditions);

  const error = useSelector(selectModifyDatesError);
  const isFulfilled = useSelector(
    selectIsRequestFulfilled(modifyHotelProductCust.type)
  );

  useEffect(() => {
    if (isFulfilled) {
      goBackHandler();
      dispatchWithLocale(modifyHotelProductCustClear());
    }
  }, [isFulfilled]);

  if (showSavingSpinner) {
    return <BookingFlowLoadingIndicator />;
  }

  return (
    <>
      <h2>{t("Confirm Date Change")}</h2>
      {error && (
        <div className="container">
          <ErrorMessage>{t(`Error: ${error}`)}</ErrorMessage>{" "}
        </div>
      )}
      <BookingSummary highlights={highlights} rates={rates} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Checkbox name="confirmation" register={register({ required: true })}>
            {t("I have read & accepted the")}
          </Checkbox>
          <button
            type="button"
            id="termsAndConditions"
            className="tandc--button appear-as-link"
            onClick={toggleShowTermsAndConditions}
            aria-controls="termsAndConditionsText"
            aria-expanded={showTermsAndConditions}
          >
            {t("Terms & Conditions")}
          </button>
          {showTermsAndConditions &&
            termsForDateChange.map(({ cancellationPolicy }, index) => (
              <React.Fragment key={index}>
                <div
                  id="termsAndConditionsText"
                  className="termsAndConditionsText"
                  dangerouslySetInnerHTML={{
                    __html: cancellationPolicy,
                  }}
                />
              </React.Fragment>
            ))}
          <p className="view--upcoming-trip__update-trip-dates__terms-and-conditions">
            {t(
              "All modifications are subject to availability at the time of modification and are only valid upon receipt of a confirmation number. Offers are not valid in conjunction with any other offer or contract and do not apply to groups. Rates displayed are per room, per night, and do not include taxes, service charges, administrative fees and gratuities unless otherwise noted."
            )}
          </p>
        </div>
        <div className="form-actions">
          <Button
            kind="secondary"
            onClick={goBackHandler}
            ref={cancelButtonRef}
          >
            {t("Cancel")}
          </Button>
          <Button
            type="submit"
            aria-label={t("Continue")}
            disabled={!formState.isValid}
          >
            {t("Continue")}
          </Button>
        </div>
      </form>
    </>
  );
}
