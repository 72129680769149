import React, { useState } from "react";

export const THEMES = {
  DARK: "dark",
  LIGHT: "light",
};

export const ThemeContext = React.createContext(THEMES.LIGHT);

function ThemeContextProvider({
  theme: initialTheme = THEMES.LIGHT,
  children,
}) {
  const [theme, setTheme] = useState(initialTheme);

  const setToValidTheme = (nextTheme) => {
    setTheme(
      [THEMES.LIGHT, THEMES.DARK].includes(nextTheme) ? nextTheme : THEMES.LIGHT
    );
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme: setToValidTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export const flipTheme = (theme) =>
  theme === THEMES.DARK ? THEMES.LIGHT : THEMES.DARK;

export default ThemeContextProvider;
