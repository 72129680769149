import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import env from "config/env";
import { selectIsUserLoggedIn } from "store/profile";
import { MediaContext } from "contexts/MediaContext";
import { useTranslation } from "hooks";
import { DictionaryKeyContent } from "BookingFlow/components";
import { profileRoutes } from "Profile";
import DownloadApp from "./DownloadApp";
import CancellationMessage from "./CancellationMessage";

const { STATIC_SITE_URL, PREFERRED_PARTNER_DOMAIN } = env;

export default function Aside({
  isBookingCancelled,
  cancellation,
  property,
  dictionaryKeys,
  isPreferredPartners,
  tollFreeNumbers,
  contactUs,
  showAppDownload = false,
  openCancellationModal,
  submitConfirmationTool,
}) {
  const { t, locale } = useTranslation();
  const { isMobileApp } = useContext(MediaContext);
  const isSignedIn = useSelector(selectIsUserLoggedIn);

  const showAllReservationsLink =
    !isMobileApp && !isPreferredPartners && isSignedIn;

  return (
    <div className="col-sm-3">
      <ul className="confirmation-actions no-bullets">
        {showAllReservationsLink && (
          <li>
            <span id="skip" name="skip" tabIndex="-1" />
            <Link
              className="see-all-reservations-link"
              to={profileRoutes.profilePath.to({ locale })}
            >
              <i aria-hidden="true" className="icon icon-hamburger" />
              {t("See All Your Reservations")}
            </Link>
          </li>
        )}

        {cancellation.allowed && !isBookingCancelled && (
          <li>
            <button
              type="button"
              className="btn-link cancel"
              onClick={openCancellationModal}
              aria-haspopup="dialog"
            >
              <i aria-hidden="true" className="icon icon-cancel" />
              {t("Cancel this reservation")}
            </button>
          </li>
        )}
      </ul>

      <CancellationMessage
        cancellation={cancellation}
        dictionaryKeys={dictionaryKeys}
        isBookingCancelled={isBookingCancelled}
      />

      {isPreferredPartners && !isBookingCancelled && (
        <p>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="confirmation-email-link"
            href="#"
            onClick={submitConfirmationTool}
          >
            {t("Create a Confirmation Email for your guest")}
          </a>
        </p>
      )}

      <p
        className="hotel-address"
        dangerouslySetInnerHTML={{
          __html: [
            `<a href="${
              (isPreferredPartners
                ? PREFERRED_PARTNER_DOMAIN
                : STATIC_SITE_URL) + property.name.url
            }">${property.typeName} ${property.name.text}</a>`,
            property.street,
            property.country,
          ]
            .filter(Boolean)
            .join("<br />"),
        }}
      />

      <p>
        {property?.phone && (
          <span>
            {`${t("Tel")}: `}
            <a className="tel-number" href={`tel:+${property.phone.number}`}>
              {property.phone.number}
            </a>
            <br />
          </span>
        )}
        {tollFreeNumbers && (
          <a
            className="global-contact-link"
            href={STATIC_SITE_URL + tollFreeNumbers.url}
            target="_blank"
            rel="noreferrer"
          >
            <DictionaryKeyContent dict={tollFreeNumbers.textDictKey} />
          </a>
        )}
        {contactUs && (
          <a
            className="global-contact-link"
            href={
              (isPreferredPartners
                ? PREFERRED_PARTNER_DOMAIN
                : STATIC_SITE_URL) + contactUs.url
            }
            target="_blank"
            rel="noreferrer"
          >
            <DictionaryKeyContent dict={contactUs.textDictKey} />
          </a>
        )}
      </p>

      {showAppDownload && <DownloadApp />}
    </div>
  );
}
