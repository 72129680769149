import compose from "lodash/fp/compose";
import get from "lodash/fp/get";
import find from "lodash/fp/find";
import map from "lodash/fp/map";
import flatten from "lodash/fp/flatten";
import matchesProperty from "lodash/fp/matchesProperty";

import { API_REQUEST_STATES } from "store/apiRequestStates/constants";
import { fetchProperties } from "./properties.slice";

export const selectHasProperties = (state) => state.properties.data.length > 0;

export const selectIsLoadingProperties = ({ apiRequestStates }) => {
  const apiRequestState = apiRequestStates[fetchProperties.type];
  return (
    (apiRequestState && apiRequestState === API_REQUEST_STATES.REQUEST) || false
  );
};

export const selectHasRequestedProperties = ({ apiRequestStates }) => {
  const apiRequestState = apiRequestStates[fetchProperties.type];
  return (
    (apiRequestState && apiRequestState === API_REQUEST_STATES.FULFILLED) ||
    false
  );
};

export const selectProperties = (state) => {
  return (state.properties.data || []).reduce(
    (acc, { properties, title }) => [
      ...acc,
      ...properties.map((a) => ({ ...a, regionTitle: title })),
    ],
    []
  );
};

export const selectProperty = (propertyCode) => (state) => {
  return selectProperties(state).find(
    ({ owsCode }) => owsCode === propertyCode
  );
};

export const selectPropertyIsBookableInLocale =
  ({ propertyCode, locale }) =>
  (state) => {
    return (
      locale === "en" || selectProperty(propertyCode)(state)?.bookable === true
    );
  };

export const selectIsPropertyBookableInLocale =
  ({ locale, propertyCode }) =>
  (state) =>
    locale === "en" ||
    !(selectProperty(propertyCode)(state)?.bookable === false);

export const selectPropertyIsSmokeFree = (propertyCode) => (state) => {
  // default to 'true' if property isn't found in the state
  const smokeFree = selectProperty(propertyCode)(state)?.smokeFree;
  return smokeFree === undefined ? true : smokeFree;
};

export const selectPropertyContent = (hotelCode) =>
  compose(
    find(matchesProperty(["owsCode"], hotelCode)),
    flatten,
    map(get("properties")),
    get(["properties", "data"])
  );
