/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import getMergedPropertyCodes from "store/getMergedPropertyCodes";
import {
  setRequestStateRequesting,
  setRequestStateFulfilled,
  setRequestStateFailed,
  setRequestStateCancelled,
} from "store/apiRequestStates/setRequestState";

const initialState = {
  data: {},
  error: {},
  requestStates: {},
};

const accommodationsContentSlice = createSlice({
  name: "accommodationsContent",
  initialState,
  reducers: {
    fetchAccommodationsContent(state, { payload }) {
      const propertyCodes = getMergedPropertyCodes(payload);
      propertyCodes.forEach((propertyCode) => {
        delete state.error[propertyCode];
        state.requestStates[propertyCode] = setRequestStateRequesting(
          state.requestStates[propertyCode]
        );
      });
    },

    fetchAccommodationsContentFulfilled(state, { payload }) {
      const { propertyCode, accommodationsContent } = payload;
      state.data[propertyCode] = accommodationsContent;
      state.requestStates[propertyCode] = setRequestStateFulfilled(
        state.requestStates[propertyCode]
      );
    },

    fetchAccommodationsContentFailed(state, { payload }) {
      const { propertyCode, error } = payload;
      state.error[propertyCode] = error;
      state.requestStates[propertyCode] = setRequestStateFailed(
        state.requestStates[propertyCode]
      );
    },

    fetchAccommodationsContentCancel(state, { payload }) {
      const propertyCodes = getMergedPropertyCodes(payload);
      propertyCodes.forEach((code) => {
        state.requestStates[code] = setRequestStateCancelled(
          state.requestStates[code]
        );
      });
    },

    accommodationsContentReset() {
      return initialState;
    },
  },
});

export const {
  fetchAccommodationsContent,
  fetchAccommodationsContentFulfilled,
  fetchAccommodationsContentFailed,
  fetchAccommodationsContentCancel,
  accommodationsContentReset,
} = accommodationsContentSlice.actions;

export default accommodationsContentSlice.reducer;
