import React, { useState, useContext, useEffect } from "react";

import { useDispatchWithLocale } from "hooks";
import { MediaContext } from "contexts/MediaContext";
import { singleRoomUpsellEvt, upgradeRoomsCancel } from "store/bookings";
import DesktopSingleRoomUpsells from "./DesktopSingleRoomUpsells";
import MobileSingleRoomUpsells from "./MobileSingleRoomUpsells";

export default function SingleRoomUpsells({
  upsells: [{ roomIndex, roomName, description, image, price }],
}) {
  const media = useContext(MediaContext);
  const dispatchWithLocale = useDispatchWithLocale();

  const [showUpgrade, setShowUpgrade] = useState(true);

  const hideUpgrade = () => setShowUpgrade(false);

  useEffect(
    () => () => {
      dispatchWithLocale(upgradeRoomsCancel());
    },
    []
  );

  useEffect(() => {
    if (showUpgrade) {
      dispatchWithLocale(singleRoomUpsellEvt());
    }
  }, [showUpgrade]);

  if (!showUpgrade) return null;

  return media.isGreaterThan.sm ? (
    <DesktopSingleRoomUpsells
      roomIndex={roomIndex}
      roomName={roomName}
      image={image}
      price={price}
      description={description}
      hideUpgrade={hideUpgrade}
      dispatchWithLocale={dispatchWithLocale}
    />
  ) : (
    <MobileSingleRoomUpsells
      roomIndex={roomIndex}
      roomName={roomName}
      image={image}
      price={price}
      description={description}
      hideUpgrade={hideUpgrade}
      dispatchWithLocale={dispatchWithLocale}
    />
  );
}
