import React from "react";

import { useTranslation } from "hooks";
import { pluralize } from "utils/utils";

const getRoomAndCount = ({ t, roomIndex }) =>
  t("bk31.multioffertitle", [roomIndex + 1]);

const getAriaLabelForButton =
  (isRemove = false) =>
  ({ t, isAdult, roomIndex }) =>
    `${t(
      isRemove
        ? isAdult
          ? "Remove adult"
          : "Remove child"
        : isAdult
        ? "Add adult"
        : "Add child"
    )} (${getRoomAndCount({ t, roomIndex })})`;

const getAriaLabelForRemoveButton = getAriaLabelForButton(true);
const getAriaLabelForAddButton = getAriaLabelForButton();

const inputValue = ({ t, isAdult, count, minChildAge, maxChildAge }) =>
  `${
    isAdult
      ? pluralize(t, count, "Adult")
      : pluralize(t, count, "Child", "Children")
  } ${
    !isAdult
      ? t("label.p1-10-footer-booker.018yrs", [minChildAge, maxChildAge])
      : ""
  }`;

const inputAriaLabel = ({ t, roomIndex, isAdult, count }) =>
  `${getRoomAndCount({ t, roomIndex })} (${inputValue({ t, isAdult, count })})`;

function Occupancy({
  label,
  value,
  minimum = 1,
  maximum = 4,
  count,
  roomIndex,
  isAdult = false,
  handleIncrement,
  handleDecrement,
  minChildAge = 0,
  maxChildAge = 18,
}) {
  const { t } = useTranslation();

  return (
    <div className="form-row">
      <fieldset className="fs-occupancy-input">
        <legend className="visuallyhidden">{t(label)}</legend>
        <button
          type="button"
          className="btn-decrement btn"
          onClick={handleDecrement}
          disabled={value <= minimum}
          aria-label={getAriaLabelForRemoveButton({ t, isAdult, roomIndex })}
        >
          <i aria-hidden="true" className="icon icon-minus" />
        </button>
        <input
          className="occupancy-input"
          type="text"
          value={inputValue({ t, count, isAdult, minChildAge, maxChildAge })}
          readOnly
          aria-label={inputAriaLabel({ t, count, roomIndex, isAdult })}
        />
        <button
          type="button"
          className="btn-increment btn"
          onClick={handleIncrement}
          disabled={value >= maximum}
          aria-label={getAriaLabelForAddButton({ t, isAdult, roomIndex })}
        >
          <i aria-hidden="true" className="icon icon-plus" />
        </button>
      </fieldset>
    </div>
  );
}

export default Occupancy;
