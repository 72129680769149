import React from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";

function getFontAdjustmentStyles(length) {
  if (length > 14) {
    return {
      fontSize: "10px",
      letterSpacing: "2.5px",
    };
  }
  if (length > 12) {
    return {
      fontSize: "11px",
      letterSpacing: "2.5px",
    };
  }
  return {};
}

const FlexDate = React.forwardRef(
  (
    {
      dateRange,
      bestAvailableRateCurrencyCode,
      bestAvailableRatePerNight,
      isBestRate = false,
      isSelected = false,
      isDisabled = false,
      onClick = () => {},
    },
    ref
  ) => {
    const { t, formatPrice } = useTranslation();

    const displayedPrice = bestAvailableRatePerNight
      ? `${formatPrice({
          currency: bestAvailableRateCurrencyCode,
          value: bestAvailableRatePerNight,
          decimalPlaces: 0,
        })}*`
      : t("Not Available");

    return (
      <button
        type="button"
        onClick={onClick}
        className={classNames("flex-dates-date-selection__flex-date", {
          "flex-dates-date-selection__flex-date--is-selected": isSelected,
          "flex-dates-date-selection__flex-date--is-disabled": isDisabled,
        })}
        disabled={isDisabled}
        ref={ref}
      >
        {isBestRate && (
          <div className="flex-dates-date-selection__flex-date__best-rate">
            {t("Best Rate")}
          </div>
        )}
        <div className="flex-dates-date-selection__flex-date__date">
          {dateRange}
        </div>
        <div
          className="flex-dates-date-selection__flex-date__cost"
          style={getFontAdjustmentStyles(displayedPrice.length)}
        >
          {displayedPrice}
        </div>
      </button>
    );
  }
);

export default FlexDate;
