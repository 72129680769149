import get from "lodash/fp/get";

const getSourceName = (booking) => {
  const hotelProduct = get(["hotelProducts", 0])(booking);

  if (!hotelProduct) {
    return "Web";
  }

  if (hotelProduct.fsReservationStatus === "INHOUSE") {
    return "Web - Stay";
  }

  return "Web - Pre-Arrival";
};

export default getSourceName;
