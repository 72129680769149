/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import getMergedPropertyCodes from "store/getMergedPropertyCodes";
import {
  setRequestStateRequesting,
  setRequestStateFulfilled,
  setRequestStateFailed,
  setRequestStateCancelled,
} from "store/apiRequestStates/setRequestState";

const initialState = {
  data: {},
  error: {},
  requestStates: {},
};

const propertyContentSlice = createSlice({
  name: "propertyContent",
  initialState,
  reducers: {
    fetchPropertyContent(state, { payload }) {
      const propertyCodes = getMergedPropertyCodes(payload);
      propertyCodes.forEach((propertyCode) => {
        delete state.error[propertyCode];
        state.requestStates[propertyCode] = setRequestStateRequesting(
          state.requestStates[propertyCode]
        );
      });
    },

    fetchPropertyContentFulfilled(state, { payload }) {
      const { propertyCode, propertyContent } = payload;
      delete state.error[propertyCode];
      state.data[propertyCode] = propertyContent;
      state.requestStates[propertyCode] = setRequestStateFulfilled(
        state.requestStates[propertyCode]
      );
    },

    fetchPropertyContentFailed(state, { payload }) {
      const { propertyCode, error } = payload;
      state.error[propertyCode] = error;
      delete state.data[propertyCode];
      state.requestStates[propertyCode] = setRequestStateFailed(
        state.requestStates[propertyCode]
      );
    },

    fetchPropertyContentCancel(state, { payload }) {
      const propertyCodes = getMergedPropertyCodes(payload);
      propertyCodes.forEach((code) => {
        state.requestStates[code] = setRequestStateCancelled(
          state.requestStates[code]
        );
      });
    },

    propertyContentReset() {
      return initialState;
    },
  },
});

export const {
  fetchPropertyContent,
  fetchPropertyContentCancel,
  fetchPropertyContentFailed,
  fetchPropertyContentFulfilled,
  propertyContentReset,
} = propertyContentSlice.actions;

export default propertyContentSlice.reducer;
