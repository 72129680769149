import React from "react";

import { useTranslation } from "hooks";
import ThemeContextProvider, { THEMES } from "contexts/ThemeContext";
import { Link } from "Profile/components";
import env from "config/env";

const { STATIC_SITE_URL } = env;

function ExclusiveOffers() {
  const { t } = useTranslation();

  return (
    <ThemeContextProvider theme={THEMES.DARK}>
      <div className="view--profile__exclusive-offers">
        <div className="container">
          <div className="fs-exclusive-offers">
            <div className="fs-exclusive-offers__copy">
              <div className="fs-exclusive-offers__title">
                <h2>{t("You can now see exclusive offers")}</h2>
              </div>
              <div className="fs-exclusive-offers__body">
                <p>
                  {t(
                    "Complimentary perks, best rate guaranteeed and personalized service when you book with us"
                  )}
                </p>
              </div>
            </div>

            <div className="fs-exclusive-offers__icon" />

            <div className="fs-exclusive-offers__link">
              <Link
                kind="secondary"
                href={`${STATIC_SITE_URL}/find_a_hotel_or_resort/`}
              >
                {t("Find a hotel or Resort")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ThemeContextProvider>
  );
}

export default ExclusiveOffers;
