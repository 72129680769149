import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import env from "config/env";
import { MediaContext } from "contexts/MediaContext";
import { bookingFlowRoutes } from "BookingFlow";
import {
  ErrorMessage,
  LanguageSupportModal,
  BookingFlowLoadingIndicator,
} from "BookingFlow/components";
import { TECHNICAL_ERROR_MESSAGE } from "fixtures/constants";
import { useTranslation } from "hooks";
import {
  leadWithCareTravelAdvisoryButtonClicked,
  leadWithCareCheckHotelStatusLinkClicked,
} from "store/analyticEvents";
import { selectGlobalSettings } from "store/globalSettings";
import BookingFlowLayout from "../BookingFlowLayout";
import SearchForm from "./SearchForm";
import BookingMessages from "./BookingMessages";
import SisterProperties from "./SisterProperties";
import OccupancyAlert from "./OccupancyAlert";
import NotSureWhereToTravel from "./NotSureWhereToTravel";
import FlexDatesPromoCodeWarning from "./FlexDatesPromoCodeWarning";
import usePlanYourStay from "./hooks/usePlanYourStay";

const { PREFERRED_PARTNER_DOMAIN } = env;

function PlanYourStayView({ hasReactError }) {
  const media = useContext(MediaContext);
  const searchData = usePlanYourStay();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { globalTravelAdvisoryLink, hotelStatusLink } =
    useSelector(selectGlobalSettings);

  const {
    bookingError,
    bookingMessages,
    checkIn,
    checkOut,
    contactUs,
    employeeMode,
    employeeSearchError,
    dispatchSubmitSearch,
    isPreferredPartners,
    isLoadingSearchResults,
    ppReturnUrl = PREFERRED_PARTNER_DOMAIN,
    promoCode,
    rooms,
    selectedProperty,
    sisterProperties,
    missingLanguageError,
    hasUserSelectedCheckIn,
    setHasUserSelectedCheckIn,
    dismissMissingLanguageError,
    searchFormKey,
    error,
    occupancyError,
    dismissOccupancyError,
    additionalWebChatInfo,
    initialFocus,
  } = searchData;

  const hasBookingError = Boolean(bookingError);

  useEffect(() => {
    if (hasBookingError) {
      window.scrollTo(0, 0);
    }
  }, [hasBookingError]);

  const handleSisterPropertySelect = (sisterPropertyCode) => {
    dispatchSubmitSearch({
      dates: {
        checkIn,
        checkOut,
      },
      rooms,
      promoCode,
      hotelCode: sisterPropertyCode,
    });
  };

  if (isLoadingSearchResults) {
    return <BookingFlowLoadingIndicator />;
  }

  return (
    <BookingFlowLayout
      pageTitle={bookingFlowRoutes.planYourStay.getPageTitle({
        isPreferredPartners,
      })}
      htmlBodyClassName={bookingFlowRoutes.planYourStay.htmlBodyClassName}
      isPreferredPartners={isPreferredPartners}
      ppReturnUrl={ppReturnUrl}
      contactUs={contactUs}
      employeeMode={employeeMode}
      additionalWebChatInfo={{
        ...additionalWebChatInfo,
        bookingError: bookingError?.messageDictKey?.key,
      }}
    >
      <div className="main-inner">
        {bookingError && (
          <ErrorMessage
            contactReservations={bookingError.contactReservations}
            descriptionDictKey={bookingError.descriptionDictKey}
            labelDictKey={
              typeof bookingError === "boolean"
                ? "Error"
                : bookingError.labelDictKey
            }
            messageDictKey={bookingError.messageDictKey}
          />
        )}

        {employeeSearchError && (
          <ErrorMessage
            labelDictKey="Error"
            messageDictKey={{
              key: employeeSearchError.errorCode,
              ...employeeSearchError,
            }}
          />
        )}

        {!bookingError &&
          !employeeSearchError &&
          ((error && !error.errorCode) || hasReactError) && (
            <ErrorMessage messageDictKey={TECHNICAL_ERROR_MESSAGE} />
          )}

        {!bookingError && !employeeSearchError && error?.errorCode && (
          <ErrorMessage
            messageDictKey={{
              html: false,
              key: error.errorCode,
              params: [],
            }}
          />
        )}

        <div>
          {bookingMessages.length > 0 && (
            <div className="container">
              <BookingMessages bookingMessages={bookingMessages} />
            </div>
          )}

          {selectedProperty && occupancyError && (
            <div className="container">
              <OccupancyAlert
                title="Select Additional Rooms"
                messageKey={occupancyError}
                propertyType={selectedProperty.type}
                phone={selectedProperty.propertyDirectPhone}
                email={selectedProperty.reservation_email}
                propertyUrlName={selectedProperty.urlName}
                close={dismissOccupancyError}
              />
            </div>
          )}

          {sisterProperties.length > 0 && (
            <div className="container">
              <SisterProperties
                sisterProperties={sisterProperties}
                onSisterPropertySelect={handleSisterPropertySelect}
              />
            </div>
          )}

          {searchData.flexDatesWindow.length > 0 &&
            media.isLessThan.sm &&
            !bookingError && (
              <FlexDatesPromoCodeWarning
                isError={searchData.promoCode.length > 0}
              />
            )}

          {searchData.flexDatesWindow.length > 0 &&
            !media.isLessThan.sm &&
            !bookingError && (
              <div className="container">
                <FlexDatesPromoCodeWarning
                  isError={searchData.promoCode.length > 0}
                />
              </div>
            )}

          <SearchForm
            key={searchFormKey}
            isEmployeeRate={false}
            searchData={searchData}
            occupancyError={occupancyError}
            hasUserSelectedCheckIn={hasUserSelectedCheckIn}
            setHasUserSelectedCheckIn={setHasUserSelectedCheckIn}
            initialFocus={initialFocus}
            selectedProperty={selectedProperty}
          />

          <div className="container">
            {!selectedProperty &&
              !isPreferredPartners &&
              (globalTravelAdvisoryLink || hotelStatusLink) && (
                <NotSureWhereToTravel
                  icon="/images/profiles_2018/Travel_Advisory.svg"
                  links={[
                    globalTravelAdvisoryLink && {
                      href: globalTravelAdvisoryLink,
                      children: t("Travel advisory"),
                      kind: "secondary",
                      onClick: () => {
                        dispatch(leadWithCareTravelAdvisoryButtonClicked());
                      },
                    },
                    hotelStatusLink && {
                      href: hotelStatusLink,
                      children: t("Check hotel status"),
                      onClick: () => {
                        dispatch(leadWithCareCheckHotelStatusLinkClicked());
                      },
                    },
                  ].filter(Boolean)}
                />
              )}
          </div>
        </div>
      </div>

      {missingLanguageError && bookingMessages.length === 0 && (
        <LanguageSupportModal
          continuePath={missingLanguageError.continuePath}
          closeModal={dismissMissingLanguageError}
        />
      )}
    </BookingFlowLayout>
  );
}

export default PlanYourStayView;
