import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { getErrorCodeForTranslations } from "utils/errorCodes";
import { LOGIN_TYPES } from "api/tretail/authentication/generateOTP";
import { useTranslation } from "hooks";
import env from "config/env";
import { isMobileApp, isIOS } from "utils";
import { Button, ErrorMessage } from "Profile/components";
import { selectProfileError } from "store/profile";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import useRecaptcha from "../../BookingFlow/ConfirmYourStay/hooks/useRecaptcha";

const { GOOGLE_RECAPTCHA_PROFILE_ENABLED } = env;

export default function SendCode({ onSubmit = () => {}, onCancel = () => {} }) {
  const { t, locale } = useTranslation();

  const {
    recaptchaElementId,
    recaptchaCallbackName,
    isReady,
    recaptchaExecute,
  } = useRecaptcha({ locale });

  const { register, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
  });
  const error = useSelector(selectProfileError);

  const sendCodeVia = watch("sendCodeVia");

  const onSubmitWithRecaptcha = (formValues, evt) => {
    recaptchaExecute().then((gRecaptchaResponse) => {
      onSubmit({ ...formValues, gRecaptchaResponse }, evt);
    });
  };

  return (
    <div className="view view--register-send-code">
      <h1>{t("Send Code")}</h1>

      <p className="intro">{t("Where would you like us to send your code?")}</p>

      <form
        onSubmit={handleSubmit(
          GOOGLE_RECAPTCHA_PROFILE_ENABLED ? onSubmitWithRecaptcha : onSubmit
        )}
        noValidate
      >
        {error && (
          <ErrorMessage>
            {t(getErrorCodeForTranslations({ errorCode: error.errorCode }))}
          </ErrorMessage>
        )}

        <div className="fs-radio-inputs">
          <label className="fs-radio">
            <input
              type="radio"
              name="sendCodeVia"
              value={LOGIN_TYPES.PHONE_NUMBER}
              ref={register({ required: true })}
            />
            <span>{t("Send to phone")}</span>
          </label>

          <label className="fs-radio">
            <input
              type="radio"
              name="sendCodeVia"
              value={LOGIN_TYPES.EMAIL_ADDRESS}
              ref={register({ required: true })}
            />
            <span>{t("Send to email")}</span>
          </label>
        </div>

        {sendCodeVia === LOGIN_TYPES.PHONE_NUMBER && (
          <p className="rates-may-apply">
            {t(
              "If you choose to receive your password via mobile, we will send one text message per login attempt. Message and data rates may apply."
            )}
          </p>
        )}

        <div className="view__actions">
          <div className="view__action">
            <Button kind="secondary" onClick={onCancel}>
              {t("Back")}
            </Button>
          </div>
          <div className="view__action">
            <Button type="submit" disabled={!formState.isValid}>
              {t("Next")}
            </Button>
          </div>
        </div>
        {GOOGLE_RECAPTCHA_PROFILE_ENABLED && isReady && (
          <div
            id={recaptchaElementId}
            className={classNames("g-recaptcha", {
              iosBottom: isIOS() || isMobileApp(),
            })}
            data-callback={recaptchaCallbackName}
          />
        )}
        {GOOGLE_RECAPTCHA_PROFILE_ENABLED && !isReady && (
          <BookingFlowLoadingIndicator />
        )}
      </form>
    </div>
  );
}
