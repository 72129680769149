import sortUpcomingTrips from "utils/sortUpcomingTrips";

export const selectUpcomingTripsOnly = (state) =>
  sortUpcomingTrips(state?.bookingHistory?.data?.bookingSummaries || []);

export const selectBookingFromBookingHistory =
  (hotelCode, reservationId) => (state) => {
    // Search for the specified booking
    if (state.bookingHistory?.data?.bookingSummaries) {
      const { bookingSummaries } = state.bookingHistory.data;
      for (let a = 0; a < bookingSummaries.length; a += 1) {
        const currentSummary = bookingSummaries[a];
        if (currentSummary?.hotelProducts) {
          for (let b = 0; currentSummary.hotelProducts.length; b += 1) {
            const currentProduct = currentSummary.hotelProducts[b];
            if (
              currentProduct.hotelCode === hotelCode &&
              currentProduct.reservationId === reservationId
            ) {
              return currentSummary;
            }
          }
        }
      }
    }

    // Booking not found so return false
    return false;
  };

export const selectRoomsForBooking = (targetReservationId) => (state) => {
  const {
    hotelProducts: [{ rooms }],
  } = selectUpcomingTripsOnly(state).find(
    ({ hotelProducts: [{ reservationId } = {}] = [] } = {}) =>
      targetReservationId === reservationId
  );

  return rooms;
};
