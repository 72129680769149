import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useCombobox } from "downshift";
import { match, surround } from "fuzzyjs";
import omit from "lodash/omit";

import { useTranslation } from "hooks";
import extractTextContent from "utils/extractTextContent";
import matchSortProperties from "utils/matchSortProperties";

export default function PropertySearch({
  label = "",
  properties: initialProperties,
  placeholder = "",
  value,
  hasError,
  onChange,
  closeBtnClassName,
}) {
  const inputRef = useRef();
  const { t } = useTranslation();

  const properties = initialProperties.map((p) => ({
    ...p,
    displayName: p.name,
    normalizedName:
      p.normalizedName ||
      p.name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, ""),
  }));

  const propertiesCodeMap = properties.reduce((acc, property) => {
    return { ...acc, [property.name]: property.code };
  }, {});

  const [inputItems, setInputItems] = useState(properties);

  const formatBeverleyWilshire = (propertyDisplayName) => {
    if (
      extractTextContent(propertyDisplayName).startsWith("Beverly Wilshire")
    ) {
      const nameAry = propertyDisplayName.split(",");
      nameAry.pop();
      nameAry.push(' <span class="font-20">A Four Seasons Hotel</span>');
      return nameAry.join(",");
    }
    return propertyDisplayName;
  };

  const setInputCaretToStart = () => {
    if (inputRef?.current) {
      inputRef.current.setSelectionRange(0, 0);
    }
  };

  const {
    openMenu,
    isOpen,
    reset,
    closeMenu,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    inputValue: inputTerm,
  } = useCombobox({
    initialSelectedItem: value && properties.find(({ code }) => code === value),
    items: inputItems,
    defaultHighlightedIndex: 0,
    initialHighlightedIndex: null,

    itemToString: (item) => (item ? item.name : ""),

    onSelectedItemChange: (changes) => {
      onChange(changes.selectedItem?.code || "");
      setInputCaretToStart();
      closeMenu();
    },

    onInputValueChange: ({ inputValue: initialInputValue }) => {
      const inputValue = initialInputValue;

      if (!propertiesCodeMap[inputValue]) {
        onChange("");
      }

      const fuzzyMatchedProperties = properties
        .map((property) => {
          return {
            ...property,
            result: match(inputValue, extractTextContent(property.name), {
              caseSensitive: false,
              withRanges: true,
            }),
          };
        })
        .filter(({ result }) => result.match);

      if (
        fuzzyMatchedProperties.length === 0 &&
        propertiesCodeMap[inputValue]
      ) {
        const props = properties.filter((property) => {
          return property.code === propertiesCodeMap[inputValue];
        });
        setInputItems(
          props.map((property) => ({
            ...property,
            displayName: extractTextContent(property.name),
          }))
        );
      } else {
        setInputItems(
          matchSortProperties(inputValue, fuzzyMatchedProperties).map(
            ({ result, ...property }) => ({
              ...property,
              displayName:
                inputValue.length > 0
                  ? surround(extractTextContent(property.name), {
                      result,
                      prefix: "<strong>",
                      suffix: "</strong>",
                    })
                  : extractTextContent(property.name),
            })
          )
        );
      }
    },
  });

  let lastRegionTitle = null;

  const inputProps = getInputProps({ ref: inputRef });

  useEffect(() => {
    if (hasError && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [hasError]);

  return (
    <div className="destination fs-autocomplete emulated-flex-gap-component">
      <div className="autocomplete-input-wrapper" {...getComboboxProps()}>
        {placeholder.length === 0 && (
          <span aria-hidden="true" className="icon icon-search" />
        )}
        <input
          name=""
          aria-label={label}
          className={classNames("autocomplete-input", {
            "search-panel-error": hasError,
            "no-search-icon": placeholder.length > 0,
          })}
          onFocus={openMenu}
          placeholder={placeholder || t("Find a Destination")}
          type="text"
          {...omit(inputProps, "aria-labelledby")}
          value={inputProps.value.replace(/(<([^>]+)>)/gi, "")}
        />
        {inputProps.value && (
          <button
            type="button"
            aria-label="Clear Property Search"
            className={closeBtnClassName || "icon-button icon icon-close"}
            onClick={reset}
          />
        )}
      </div>
      <div
        className={classNames({
          "autocomplete-list": isOpen,
          "d-none": !isOpen,
        })}
      >
        <ul {...getMenuProps()}>
          {isOpen &&
            inputItems.map((property, index) => {
              const renderRegionTitle =
                lastRegionTitle !== property.regionTitle && !inputTerm;
              if (renderRegionTitle) {
                lastRegionTitle = property.regionTitle;
              }

              const itemProps = getItemProps({ item: property, index });

              return (
                <React.Fragment key={property.code}>
                  {renderRegionTitle && (
                    <li className="region-title">{t(property.regionTitle)}</li>
                  )}
                  <li
                    className={classNames("property", {
                      "is-selected": itemProps["aria-selected"] === "true",
                    })}
                    {...itemProps}
                    dangerouslySetInnerHTML={{
                      __html: formatBeverleyWilshire(property.displayName),
                    }}
                  />
                </React.Fragment>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
