import React from "react";

import { useTranslation } from "hooks";
import { SignInModal } from "Authentication";
import Button from "../components/Button";

export default function TripCancellation({
  onClick,
  cancelByDate: [time, date],
  isUnauthenticated,
}) {
  const { t } = useTranslation();

  return (
    <div className="fs-trip__cancellation">
      <div className="row">
        <div className="col-md-8">
          <h3>{t("Cancellation")}</h3>
          <p>
            {t("Cancellation is required by {0}, {1}", [
              time.replace("00", "12"),
              date,
            ])}
          </p>
        </div>
        <div className="col-md-4">
          <div className="fs-trip__cancellation__actions">
            {isUnauthenticated ? (
              <Button
                kind="link"
                aria-label={t("Cancel Reservation")}
                onClick={onClick}
              >
                <span>{t("Cancel Reservation")}</span>
              </Button>
            ) : (
              <SignInModal
                id="cancelReservation"
                onClick={onClick}
                type="CTA"
                kind="link"
                ariaLabel={t("Cancel Reservation")}
                showToastOnNextView
              >
                <span>{t("Cancel Reservation")}</span>
              </SignInModal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
