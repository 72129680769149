import React, { useContext } from "react";
import { Link } from "react-router-dom";
import trim from "lodash/fp/trim";

import { useTranslation } from "hooks";
import { MediaContext } from "contexts/MediaContext";
import formatBeverlyWilshire from "Profile/utils/formatBeverlyWilshire";
import env from "../../config/env";

export default function UpcomingTripHeader({
  propertyContent,
  startSmooch,
  isWebChatEnabled,
  itineraryPath,
}) {
  const { t } = useTranslation();
  const media = useContext(MediaContext);
  const { IMAGE_BASE_URL } = env;

  const getPropertyImage = () => {
    const imageKey = media.isMobileDevice ? "mobileImage" : "desktopImage";
    return (
      IMAGE_BASE_URL +
      (propertyContent.upcomingTrip[imageKey]?.croppedPath || "")
    );
  };

  const address = [propertyContent.street, propertyContent.city]
    .map(trim)
    .filter(Boolean)
    .join(", ");

  return (
    <div className="fs-trip-header">
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <div className="fs-trip-header__container">
              <div className="fs-trip-header__contact-buttons">
                {itineraryPath && (
                  <Link to={itineraryPath} className="contact-button">
                    <div className="contact-button__icon contact-button__icon--itinerary" />
                    <span>{t("Itinerary")}</span>
                  </Link>
                )}

                <a
                  className="contact-button"
                  href={`tel:${propertyContent.reservationPhone}`}
                >
                  <div className="contact-button__icon contact-button__icon--call" />
                  <span>{t("call us")}</span>
                </a>

                {isWebChatEnabled && (
                  <button
                    type="button"
                    className="contact-button"
                    onClick={startSmooch}
                  >
                    <div className="contact-button__icon contact-button__icon--chat" />
                    <span>{t("chat")}</span>
                  </button>
                )}
              </div>
              <div className="fs-trip-header__property-container">
                <h1
                  className="fs-trip-header__property-name"
                  dangerouslySetInnerHTML={{
                    __html: t(formatBeverlyWilshire(propertyContent.shortName)),
                  }}
                />
                <p className="fs-trip-header__property-address">{address}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div
              className="fs-trip-header__image"
              style={{
                backgroundImage: `url("${getPropertyImage()}")`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
