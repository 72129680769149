import get from "lodash/get";
import {
  add,
  isAfter,
  isBefore,
  parse,
  differenceInDays,
  isSameYear,
} from "date-fns";

import { EMPLOYEE_SEARCH_ERRORS } from "store/searchResults/checkEmployeeSearchParams";
import isEmployeeRate from "./isEmployeeRate";

function checkEmployeeRoomNights({
  searchParams = {},
  stayHistory = [],
  globalSettings = {},
} = {}) {
  if (
    !get(searchParams, ["dates", "checkIn"]) ||
    !get(searchParams, ["dates", "checkOut"]) ||
    !Array.isArray(stayHistory) ||
    !stayHistory.length
  ) {
    return false;
  }

  const maxRoomNights30Days = globalSettings.maxRoomNights30Days || 0;
  const maxRoomNightsCalendarYear =
    globalSettings.maxRoomNightsCalendarYear || 0;
  if (!maxRoomNights30Days && !maxRoomNightsCalendarYear) {
    return false;
  }

  const checkInDate = parse(
    searchParams.dates.checkIn,
    "yyyy-MM-dd",
    new Date()
  );
  const checkOutDate = parse(
    searchParams.dates.checkOut,
    "yyyy-MM-dd",
    new Date()
  );

  const roomNightsThisStay = differenceInDays(checkOutDate, checkInDate);
  if (roomNightsThisStay < 1) {
    return false;
  }

  const checkOut30Before = add(checkOutDate, { days: -31 });
  const checkIn30After = add(checkInDate, { days: 30 });

  if (
    maxRoomNightsCalendarYear &&
    roomNightsThisStay > maxRoomNightsCalendarYear
  ) {
    return {
      errorCode: EMPLOYEE_SEARCH_ERRORS.MAX_ROOM_NIGHTS_YEAR,
      params: [maxRoomNightsCalendarYear, 365],
    };
  }
  if (maxRoomNights30Days && roomNightsThisStay > maxRoomNights30Days) {
    return {
      errorCode: EMPLOYEE_SEARCH_ERRORS.MAX_ROOM_NIGHTS_30,
      params: [maxRoomNights30Days],
    };
  }

  let roomNightsYear = roomNightsThisStay;
  const roomNights30Dates = [];

  if (Array.isArray(stayHistory)) {
    stayHistory.forEach((booking) => {
      const bookingRateCode = get(
        booking,
        ["hotelProducts", 0, "rooms", "room", 0, "ratePlanCode"],
        ""
      );
      if (
        !!booking.startDate &&
        !!booking.endDate &&
        !get(booking, ["hotelProducts", 0, "fsCachedExistingReservation"]) &&
        isEmployeeRate({ promoCode: bookingRateCode })
      ) {
        let stayDate = parse(booking.startDate, "yyyy-MM-dd", new Date());
        const stayEndDate = parse(booking.endDate, "yyyy-MM-dd", new Date());
        while (isBefore(stayDate, stayEndDate)) {
          if (isSameYear(checkInDate, stayDate)) {
            roomNightsYear += 1;
          }
          if (
            isAfter(stayDate, checkOut30Before) &&
            isBefore(stayDate, checkIn30After)
          ) {
            roomNights30Dates.push(stayDate);
          }
          stayDate = add(stayDate, { days: 1 });
        }
      }
    });
  }

  if (maxRoomNightsCalendarYear && roomNightsYear > maxRoomNightsCalendarYear) {
    return {
      errorCode: EMPLOYEE_SEARCH_ERRORS.MAX_ROOM_NIGHTS_YEAR,
      params: [maxRoomNightsCalendarYear, 365],
    };
  }
  if (
    maxRoomNights30Days &&
    roomNightsThisStay + roomNights30Dates.length > maxRoomNights30Days
  ) {
    let stayRange30Date = checkOut30Before;
    let exceededmaxRoomNights30Days = false;
    while (
      isBefore(stayRange30Date, checkInDate) &&
      !exceededmaxRoomNights30Days
    ) {
      const stayRange30Start = stayRange30Date;
      const stayRange30End = add(stayRange30Date, { days: 31 });
      const roomNights = roomNights30Dates.reduce((nightCounter, stayDate) => {
        if (
          isAfter(stayDate, stayRange30Start) &&
          isBefore(stayDate, stayRange30End)
        ) {
          return nightCounter + 1;
        }
        return nightCounter;
      }, 0);

      if (roomNightsThisStay + roomNights > maxRoomNights30Days) {
        exceededmaxRoomNights30Days = true;
      }
      stayRange30Date = add(stayRange30Date, { days: 1 });
    }

    if (exceededmaxRoomNights30Days) {
      return {
        errorCode: EMPLOYEE_SEARCH_ERRORS.MAX_ROOM_NIGHTS_30,
        params: [maxRoomNights30Days],
      };
    }
  }

  return false;
}

export default checkEmployeeRoomNights;
