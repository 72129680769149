import React from "react";
import { useTranslation } from "hooks";

import Layout from "./Layout";
import BookingSummary from "./BookingSummary";

export default function EstimatedTotal({
  bookingMessages = [],
  estimatedTotalDisclaimer,
  goBackHandler,
  highlights,
  isWebChatEnabled,
  openWebChat,
  rates,
  stayResortFees,
}) {
  const { t } = useTranslation();

  return (
    <Layout
      goBackHandler={goBackHandler}
      openWebChat={openWebChat}
      isWebChatEnabled={isWebChatEnabled}
    >
      <div className="col-sm-12 col-md-10 offset-md-1">
        <h2 className="estimated-total-title">{t("Estimated Total")}</h2>
        <BookingSummary
          highlights={highlights}
          rates={rates}
          stayResortFees={stayResortFees}
          estimatedTotalDisclaimer={estimatedTotalDisclaimer}
        />
        {!!estimatedTotalDisclaimer && (
          <div
            className="upcoming-trip__estimated-total-disclaimer"
            dangerouslySetInnerHTML={{ __html: estimatedTotalDisclaimer }}
          />
        )}
        {bookingMessages.map(({ title, body }, index) => (
          <div className="upcoming-trip__booking-message" key={index}>
            <p>
              <strong dangerouslySetInnerHTML={{ __html: title }} />
            </p>
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </div>
        ))}
      </div>
    </Layout>
  );
}
