import React from "react";
import { useTranslation } from "hooks";
import env from "config/env";

const { IMAGE_BASE_URL } = env;

function DownloadApp() {
  const { t } = useTranslation();
  const IMAGES = {
    appleStoreApp: `${IMAGE_BASE_URL}/etc/designs/fourseasons/img/app/app-store-button.png`,
    androidStoreApp: `${IMAGE_BASE_URL}/etc/designs/fourseasons/img/app/google-play-button.png`,
  };

  const URLS = {
    appleStore: "https://itunes.apple.com/app/id980069965",
    androidStore:
      "https://play.google.com/store/apps/details?id=com.fourseasons.mobileapp",
  };

  return (
    <div className="mobile-downloads">
      <div>
        <a
          href={URLS.appleStore}
          target="_blank"
          rel="noopener noreferrer"
          title={t("Download on the App Store")}
        >
          <img
            className="app-button"
            src={IMAGES.appleStoreApp}
            alt={t("Download on the App Store")}
          />
        </a>
      </div>
      <div>
        <a
          href={URLS.androidStore}
          target="_blank"
          rel="noopener noreferrer"
          title={t("Get it on Google play")}
        >
          <img
            className="app-button"
            src={IMAGES.androidStoreApp}
            alt={t("Get it on Google play")}
          />
        </a>
      </div>
    </div>
  );
}

export default DownloadApp;
