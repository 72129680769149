import { useEffect } from "react";

import env from "config/env";

const { STATIC_SITE_URL } = env;

export default function useLoadQualtrics() {
  useEffect(() => {
    let timeout;

    if (window?.FS?.Qualtrics) {
      return () => {};
    }

    function checkForGDPR() {
      return new Promise((resolve) => {
        function doCheckForGDPR() {
          if (window?.FS?.GDPR) {
            resolve();
          } else {
            timeout = setTimeout(doCheckForGDPR, 100);
          }
        }
        doCheckForGDPR();
      });
    }

    // add qualtrics _after_ GDPR
    checkForGDPR().then(() => {
      const script = document.createElement("script");
      script.src = `${STATIC_SITE_URL}/alt/apps/fshr/shared/qualtrics.js`;
      document.head.appendChild(script);
    });

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);
}
