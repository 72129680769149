/* eslint-disable react/require-default-props */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { ThemeContext } from "contexts/ThemeContext";

function Checkbox({
  identifier,
  name,
  value = true,
  disabled = false,
  children,
  register,

  checked,
  onChange = () => {},

  ...props
}) {
  const { theme } = useContext(ThemeContext);
  const id = identifier || `input-${name}`;

  const inputProps = { ...props };
  if (typeof value === "string") {
    inputProps.value = value;
  }

  if (!register) {
    inputProps.checked = checked;
  }

  inputProps.onChange = onChange;

  return (
    <div
      className={classNames("fsp-input fsp-input--checkbox", {
        "fsp-input--dark": theme === "dark",
      })}
    >
      <label className="fsp-input__checkbox" htmlFor={id} disabled={disabled}>
        <input
          id={id}
          className="checkbox"
          type="checkbox"
          name={name}
          disabled={disabled}
          ref={register}
          {...inputProps}
        />

        {children && <div>{children}</div>}
      </label>
    </div>
  );
}

Checkbox.propTypes = {
  identifier: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  register: PropTypes.func,
};

Checkbox.defaultProps = {
  value: true,
};

export default Checkbox;
