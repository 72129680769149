import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs";
import { switchMap, takeUntil, mergeMap, catchError } from "rxjs/operators";

// eslint-disable-next-line import/no-cycle
import getCountriesContent$ from "api/content/getCountries/getCountries";
import {
  fetchCountriesContent,
  fetchCountriesContentCancel,
  fetchCountriesContentFailed,
  fetchCountriesContentFulfilled,
} from "./countriesContent.slice";

const fetchCountriesContentEpic = (action$) =>
  action$.pipe(
    ofType(fetchCountriesContent.type),
    switchMap(({ payload: { locale } }) =>
      getCountriesContent$({ locale }).pipe(
        mergeMap(({ countries = [], error }) =>
          error
            ? of(fetchCountriesContentFailed(error))
            : of(fetchCountriesContentFulfilled({ countries }))
        ),
        catchError((error) => of(fetchCountriesContentFailed(error))),
        takeUntil(action$.pipe(ofType(fetchCountriesContentCancel.type)))
      )
    )
  );

export default combineEpics(fetchCountriesContentEpic);
