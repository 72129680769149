import {
  updateUserTitle,
  updateUserTitleFulfilled,
} from "store/profile/profile.slice";
import { prepareName } from "../profile.helpers";
import { updateProfileTemplate } from "./updateProfile";

export default updateProfileTemplate({
  actionType: updateUserTitle.type,
  fulfillAction: updateUserTitleFulfilled,
  updateFn: ({ name = "" } = {}, newValue) => ({
    name: prepareName(name, newValue),
  }),
});
