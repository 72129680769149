import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";

import env from "config/env";
import { basePostRequest } from "../../tretail/baseRequest";

const { WORKDAY_API_BASE_URL } = env;

export default function validateEmployeeDetails$({
  workdayId = "",
  workPlace = "",
  surname: lastName = "",
  locale,
}) {
  return ajax({
    ...basePostRequest({ locale }),
    url: `${WORKDAY_API_BASE_URL}/employee`,
    body: {
      workdayId,
      workPlace,
      lastName,
      type: "register",
    },
  }).pipe(pluck("response"));
}
