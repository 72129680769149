import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import capitalize from "lodash/capitalize";

import { useTranslation } from "hooks";
import Modal from "Profile/ProfileView/Modal";
import { Button } from "Profile/components";
import { itineraryActivityDetailsModal } from "store/analyticEvents";
import { pluralize } from "utils/utils";

export default function ActivityDetailsModal({
  hotelCode,
  itineraryItem,
  status,
  numberOfGuests = 0,
  numberOfAdults = 0,
  numberOfChildren = 0,
  displayedCost,
  displayedDuration,
  formattedTime,
  close,
  isWebChatEnabled,
  openWebChat,
}) {
  const {
    requestDate,
    productName,
    requestSubtype,
    vendorNameOnItinerary,
    preferredTimeOfDay,
    guestVisibleNotes,
    cancellationPolicy,
    confirmationNumber,
    departureLocation,
    departureTime,
    vehicleSelected,
    pickupLocation,
    dropoffLocation,
    numberOfItems,
    description,
    quantity,
    genderPreference,
  } = itineraryItem;

  const dispatch = useDispatch();
  const { t, formatDateInLocale } = useTranslation();

  useEffect(() => {
    dispatch(
      itineraryActivityDetailsModal({
        hotelCode,
      })
    );
  }, []);

  const formattedDate =
    requestDate && formatDateInLocale(requestDate, "MMMM d");

  const formattedDepartureTime =
    departureTime && formatDateInLocale(departureTime, "h:mma", "HH:mm:ss.SSS");

  const guestsContent = [
    numberOfAdults > 0 && pluralize(t, numberOfAdults, "Adult"),
    numberOfChildren > 0 && pluralize(t, numberOfChildren, "Child", "Children"),
    numberOfAdults === 0 &&
      numberOfChildren === 0 &&
      numberOfGuests > 0 &&
      pluralize(t, numberOfGuests, "Guest"),
  ]
    .filter(Boolean)
    .join(", ");

  const dataList = [
    status && {
      dt: t("Status"),
      dd: status,
    },

    formattedDate && {
      dt: t("Selected Date"),
      dd: formattedDate,
    },

    formattedTime && {
      dt: t("Time"),
      dd: formattedTime,
    },

    preferredTimeOfDay &&
      !formattedTime && {
        dt: t("Preferred Time"),
        dd: capitalize(preferredTimeOfDay),
      },

    confirmationNumber && {
      dt: t("Confirmation Number"),
      dd: confirmationNumber,
    },

    displayedCost && {
      dt: t("Total Cost"),
      dd: displayedCost,
    },

    vehicleSelected && {
      dt: t("Vehicle Selected"),
      dd: vehicleSelected,
    },

    pickupLocation && {
      dt: t("Pickup Location"),
      dd: pickupLocation,
    },

    dropoffLocation && {
      dt: t("Dropoff Location"),
      dd: dropoffLocation,
    },

    displayedDuration && {
      dt: t("Duration"),
      dd: displayedDuration,
    },

    guestsContent && {
      dt: t("Guests"),
      dd: guestsContent,
    },

    numberOfItems && {
      dt: t("Number Of Luggages"),
      dd: numberOfItems,
    },

    departureLocation && {
      dt: t("Departure Location"),
      dd: departureLocation,
    },

    false &&
      formattedDepartureTime && {
        dt: t("Departure Time"),
        dd: formattedDepartureTime,
      },

    quantity && {
      dt: t("Quantity"),
      dd: quantity,
    },

    genderPreference && {
      dt: t("Gender Preference"),
      dd: genderPreference,
    },

    guestVisibleNotes && {
      dt: t("Notes"),
      ddHTML: guestVisibleNotes,
      gridColumn: "1 / 3",
    },

    description && {
      dt: t("Description"),
      dd: description,
      gridColumn: guestVisibleNotes && "1 / 2",
    },

    cancellationPolicy && {
      dt: t("Cancellation Policy"),
      ddHTML: cancellationPolicy,
      gridColumn: guestVisibleNotes && (description ? "2 / 3" : "1 / 2"),
    },
  ].filter(Boolean);

  const title = productName || requestSubtype;

  return (
    <Modal close={close}>
      <div className="fs-itineray-modal">
        <button
          type="button"
          onClick={close}
          aria-label={t("Close")}
          className="fs-itineray-modal__close-btn"
        >
          <span className="icon icon-close" />
        </button>

        <div className="fs-itineray-modal__header">
          <h1 className="fs-itineray-modal__header__title">{title}</h1>
          {vendorNameOnItinerary && (
            <p className="fs-itineray-modal__header__vendor-name">
              {vendorNameOnItinerary}
            </p>
          )}
        </div>

        <div className="fs-itineray-modal__content">
          <dl>
            {dataList.map(({ dt, dd, ddHTML, gridColumn }, index) => {
              const gridRowStart = Math.floor(index / 2) * 2 + 1;
              const gridRowMid = gridRowStart + 1;
              const gridRowEnd = gridRowMid + 1;

              return (
                <div key={dt} style={gridColumn ? { gridColumn } : {}}>
                  <dt
                    className="dt "
                    style={
                      !gridColumn
                        ? { gridRowStart, gridRowEnd: gridRowMid }
                        : {}
                    }
                  >
                    {dt}
                  </dt>
                  {ddHTML ? (
                    <dd
                      className="dd "
                      dangerouslySetInnerHTML={{ __html: ddHTML }}
                      style={
                        !gridColumn
                          ? { gridRowStart: gridRowMid, gridRowEnd }
                          : {}
                      }
                    />
                  ) : (
                    <dd
                      className="dd "
                      style={
                        !gridColumn
                          ? { gridRowStart: gridRowMid, gridRowEnd }
                          : {}
                      }
                    >
                      {dd}
                    </dd>
                  )}
                </div>
              );
            })}
          </dl>
        </div>

        {isWebChatEnabled && (
          <div className="fs-itineray-modal__chat-container">
            <div className="fs-itineray-modal__chat">
              <p className="ty-b1">
                {t("Need to update your request? Please chat with us.")}
              </p>
              <Button kind="link" onClick={openWebChat}>
                {t("Chat with us")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
