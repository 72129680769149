/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  apiErrors: [],
  supplierErrors: [],
};

const stayHistorySlice = createSlice({
  name: "stayHistory",
  initialState,
  reducers: {
    fetchStayHistory() {},
    fetchStayHistoryFulfilled(state, { payload }) {
      state.data = payload;
    },
    fetchStayHistoryFailed(state, { payload }) {
      state.data = {};
      state.apiErrors = payload.apiErrors || [];
      state.supplierErrors = payload.supplierErrors || [];
    },
    fetchStayHistoryCancel() {},

    clearStayHistory(state) {
      state.data = {};
      state.apiErrors = [];
      state.supplierErrors = [];
    },
  },
});

export const {
  clearStayHistory,
  fetchStayHistory,
  fetchStayHistoryCancel,
  fetchStayHistoryFailed,
  fetchStayHistoryFulfilled,
} = stayHistorySlice.actions;

export default stayHistorySlice.reducer;
