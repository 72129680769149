/* eslint-disable import/prefer-default-export */

import { stringifyObject } from "utils/utils";

export function selectOffersTermsAndConditions({
  checkinDate,
  checkoutDate,
  hotelCode,
  promoCode,
  roomTypeId,
}) {
  const options = stringifyObject({
    checkinDate,
    checkoutDate,
    hotelCode,
    promoCode,
    roomTypeId,
  });
  return (state) => state?.offersTermsAndConditionsContent?.data[options];
}

export function selectOfferTermsAndConditions({
  checkinDate,
  checkoutDate,
  hotelCode,
  promoCode,
  roomTypeId,
  offerCode,
}) {
  return (state) =>
    (selectOffersTermsAndConditions({
      checkinDate,
      checkoutDate,
      hotelCode,
      promoCode,
      roomTypeId,
    })(state) || {})[offerCode];
}
