import React, { useContext, useRef } from "react";
import { useForm } from "react-hook-form";

import { MediaContext } from "contexts/MediaContext";
import { useTranslation } from "hooks";
import { Button, ErrorMessage } from "Profile/components";
import { getErrorCodeForTranslations } from "utils/errorCodes";
import Calendar from "./Calendar";

export default function ChangeTravelDates({
  checkInStart,
  endDate,
  errorCode,
  goBackHandler,
  hotelCode,
  onSubmit,
  setEndDate,
  setStartDate,
  startDate,
}) {
  const { t } = useTranslation();
  const media = useContext(MediaContext);

  const cancelButtonRef = useRef();
  const { handleSubmit } = useForm({ mode: "onChange" });

  return (
    <>
      <h2>{t("Change your travel dates")}</h2>
      <p className="view--upcoming-trip__update-trip-dates__intro">
        <em>{t("Please note:")}</em>
        <br />
        <span>
          {t(
            "If the dates of your stay change, all requested services will be cancelled. They will need to be re-booked."
          )}
        </span>
      </p>
      {errorCode && (
        <div>
          <br />
          <ErrorMessage>
            {t(getErrorCodeForTranslations({ errorCode }))}
          </ErrorMessage>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-12">
            <div className="fs-profile-trip-date-picker">
              <div className="row">
                <fieldset className="fs-profile-trip-date-picker-fieldset">
                  <Calendar
                    key={media.isLessThan.sm}
                    checkIn={startDate}
                    checkInStart={checkInStart}
                    checkOut={endDate}
                    hotelCode={hotelCode}
                    nextControlOutsideWidget={cancelButtonRef}
                    setCheckIn={setStartDate}
                    setCheckOut={setEndDate}
                  />
                </fieldset>
              </div>
            </div>
          </div>
        </div>
        <div className="form-actions">
          <Button
            kind="secondary"
            onClick={goBackHandler}
            ref={cancelButtonRef}
          >
            {t("Cancel")}
          </Button>
          <Button type="submit" aria-label={t("Continue")}>
            {t("Continue")}
          </Button>
        </div>
      </form>
    </>
  );
}
