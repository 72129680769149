import { ajax } from "rxjs/ajax";

import env from "config/env";
import { baseDeleteRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;
const TRETAIL_API_BOOKINGS_URL = `${TRETAIL_API_BASE_URL}/bookings`;

export const removeAllPayments$ = ({ bookingId, locale }) => {
  return ajax({
    ...baseDeleteRequest({ locale }),
    url: `${TRETAIL_API_BOOKINGS_URL}/${bookingId}/payments`,
  });
};

export default removeAllPayments$;
