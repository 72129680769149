import React from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";

function ToggleBlockLabel({
  label,
  secondaryLabel,
  ariaLabel,
  selectedValue,
  toggleForm,
  isActive = false,
}) {
  const { t } = useTranslation();

  return (
    <div className="fs-toggle-block__label">
      <div className="fs-toggle-block__label-html">
        <div className="fs-toggle-block__label-html__form-label">
          <span>{t(label)}</span>
          {secondaryLabel && (
            <span className="primary-label">{t(secondaryLabel)}</span>
          )}
        </div>
        <div className="fs-toggle-block__label-html__form-value">
          {selectedValue}
        </div>
      </div>
      <div>
        <button
          className={classNames("fs-toggle-button fs-toggle-button--edit", {
            "fs-toggle-button--active": isActive,
          })}
          onClick={toggleForm}
          type="button"
          aria-label={ariaLabel}
        >
          {t("Edit")}
        </button>
      </div>
    </div>
  );
}

export default ToggleBlockLabel;
