import React from "react";
import { useTranslation } from "hooks";
import { Link, Button } from "Profile/components";
import { getTollFreeNumbersLink } from "utils/externalLinks";

function ErrorBanner({ message, onClose }) {
  const { t, locale } = useTranslation();
  return (
    <div
      className="banner error-banner"
      aria-label={t("Error Banner")}
      role="dialog"
    >
      <p>
        {message}&nbsp;
        {t("Please contact the Worldwide Reservations Office")}&nbsp;
        <Link theme="dark" href={getTollFreeNumbersLink(locale)}>
          {t("Toll Free Numbers By Country")}
        </Link>
      </p>

      <Button onClick={onClose}>{t("Close")}</Button>
    </div>
  );
}

export default ErrorBanner;
