/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  params: {},
};

const webChatSlice = createSlice({
  name: "webChat",
  initialState,
  reducers: {
    setParams(state, { payload }) {
      state.params = payload;
    },

    reset() {
      return { params: {} };
    },
  },
});

export const { setParams, reset } = webChatSlice.actions;

export default webChatSlice.reducer;
