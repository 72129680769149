import get from "lodash/fp/get";

const roundNumber = (n) => Math.round(n * 100) / 100;

export const convertEmployeeDiscoveryRates = ({
  upcomingTripDetails: upcomingTrip,
  taxes = [],
} = {}) => {
  if (!upcomingTrip?.hotelProducts?.length) {
    return upcomingTrip;
  }

  const {
    hotelProducts: [
      {
        price: {
          prepay: { taxesFeesCharges = [], stayResortFees = [] } = {},
        } = {},
        roomRate: { nightlyRates = [] },
      },
    ],
  } = upcomingTrip;

  const nightlyResortFees = nightlyRates
    .flatMap(get("resortFees"))
    .filter(Boolean);

  const filteredStayResortFees = stayResortFees.filter(
    (bookingTax) =>
      !taxes.find((tax) => tax.taxID === bookingTax.code && tax.isPercentage)
  );

  const filteredTaxesFeesCharges = taxesFeesCharges.filter(
    (bookingTax) =>
      !taxes.find((tax) => tax.taxID === bookingTax.code && tax.isPercentage)
  );

  const totalRoomRate = roundNumber(
    [...nightlyResortFees, ...filteredStayResortFees].reduce((sum, tax) => {
      return sum + tax.cash.amount;
    }, 0)
  );

  const updatedEstimatedTotal =
    roundNumber(
      filteredTaxesFeesCharges.reduce((sum, tax) => {
        return sum + tax.cash.amount;
      }, 0)
    ) + totalRoomRate;

  const currencyCode = upcomingTrip.price?.total?.cash?.currencyCode || "";

  return {
    ...upcomingTrip,
    hotelProducts: upcomingTrip.hotelProducts.map((product) => {
      return {
        ...product,
        roomRate: {
          ...product.roomRate,
          ...(product.roomRate.averageNightlyPrice
            ? {
                averageNightlyPrice: {
                  ...product.roomRate.averageNightlyPrice,
                  cash: {
                    ...product.roomRate.averageNightlyPrice.cash,
                    amount: (totalRoomRate / product.duration).toFixed(2),
                  },
                },
              }
            : {}),
          nightlyRates: (product.roomRate?.nightlyRates || []).map(
            (nightlyRate) => {
              return {
                ...nightlyRate,
                cash: {
                  amount: 0,
                  currencyCode: nightlyRate.cash?.currencyCode || currencyCode,
                },
              };
            }
          ),
        },
        price: {
          ...product.price,
          total: {
            cash: {
              amount: updatedEstimatedTotal,
              currencyCode:
                product.price?.total?.cash?.currencyCode || currencyCode,
            },
          },
          prepay: {
            ...product.price?.prepay,
            base: {
              cash: {
                amount: totalRoomRate,
                currencyCode:
                  product.price?.prepay?.base?.cash?.currencyCode ||
                  currencyCode,
              },
            },
            ...(filteredStayResortFees.length > 0
              ? { stayResortFees: filteredStayResortFees }
              : {}),
            taxesFeesCharges: filteredTaxesFeesCharges,
            taxesFeesChargesTotal: product.price?.prepay?.taxesFeesChargesTotal
              ? {
                  cash: {
                    amount: filteredTaxesFeesCharges.reduce((sum, tax) => {
                      return sum + tax.cash.amount;
                    }, 0),
                    currencyCode:
                      product.price.prepay.taxesFeesChargesTotal.cash
                        ?.currencyCode || currencyCode,
                  },
                }
              : undefined,
            subtotal: {
              cash: {
                amount: updatedEstimatedTotal,
                currencyCode:
                  product.price?.prepay?.subtotal?.cash?.currencyCode ||
                  currencyCode,
              },
            },
            total: {
              cash: {
                amount: updatedEstimatedTotal,
                currencyCode:
                  product.price?.prepay?.total?.cash?.currencyCode ||
                  currencyCode,
              },
            },
          },
        },
      };
    }),
    price: {
      ...upcomingTrip.price,
      total: {
        cash: {
          amount: updatedEstimatedTotal,
          currencyCode:
            upcomingTrip.price?.total?.cash?.currencyCode || currencyCode,
        },
      },
      prepay: {
        cash: {
          amount: updatedEstimatedTotal,
          currencyCode:
            upcomingTrip.price?.prepay?.cash?.currencyCode || currencyCode,
        },
      },
    },
  };
};

export default convertEmployeeDiscoveryRates;
