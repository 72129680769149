import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";
import get from "lodash/get";

import { useTranslation } from "hooks";
import {
  setIsOpen as setAddReservationModalIsOpen,
  selectIsOpen as selectAddReservationModalIsOpen,
} from "store/addReservationModal";
import ThemeContextProvider, { THEMES } from "contexts/ThemeContext";
import * as profileRoutes from "Profile/profileRoutes";
import * as bookingFlowRoutes from "BookingFlow/bookingFlowRoutes";
import { formatDateRange } from "utils/utils";
import { MediaContext } from "contexts/MediaContext";
import { Button } from "Profile/components";
import env from "config/env";
import { selectIsEmployeeProfile } from "store/employeeProfile";
import formatBeverlyWilshire from "Profile/utils/formatBeverlyWilshire";
import { selectPlainSearchParams } from "store/searchResults";
import AddReservationModal from "./AddReservationModal";

function UpcomingTrips({ upcomingTrips, allPropertyContent }) {
  const { t, locale } = useTranslation();
  const dispatch = useDispatch();
  const media = useContext(MediaContext);
  const { IMAGE_BASE_URL } = env;

  const isEmployee = useSelector(selectIsEmployeeProfile);

  const addReservationModalIsOpen = useSelector(
    selectAddReservationModalIsOpen
  );
  const plainSearchParams = useSelector(selectPlainSearchParams);

  const getPropertyImage = (hotelCode) => {
    const imageKey = media.isMobileDevice ? "mobileImage" : "desktopImage";
    return (
      IMAGE_BASE_URL +
      allPropertyContent[hotelCode]?.upcomingTrip[imageKey]?.croppedPath
    );
  };

  // Define some variables to control how the trips are displayed, and keep track of whether we should display them all
  const TRIPS_DISPLAY_INITIAL_LIMIT = 3;
  const TRIPS_DISPLAY_UNCONDENSED_LIMIT = 2;
  const [showAllTrips, setShowAllTrips] = useState(false);

  // Determine which trips to display
  const displayedTrips = showAllTrips
    ? upcomingTrips
    : upcomingTrips.slice(0, TRIPS_DISPLAY_INITIAL_LIMIT);

  return (
    <ThemeContextProvider theme={THEMES.DARK}>
      <div id="upcoming-trips" className="view--profile__upcoming-trips">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h2>{t("Upcoming Trips")}</h2>
            </div>
          </div>
          <div
            className={classNames("fs-upcoming-trips", {
              "fs-upcoming-trips--single-reservation":
                upcomingTrips.length === 1,
              "fs-upcoming-trips--two-reservations": upcomingTrips.length === 2,
              "fs-upcoming-trips--many-reservations": upcomingTrips.length > 2,
              "fs-upcoming-trips--employee-travel": isEmployee,
            })}
          >
            <div className="fs-upcoming-trips__trip-cards-list">
              {displayedTrips
                .map((booking) => {
                  const hotelCode = get(booking, [
                    "hotelProducts",
                    0,
                    "hotelCode",
                  ]);
                  const reservationId = get(booking, [
                    "hotelProducts",
                    0,
                    "reservationId",
                  ]);
                  const propertyContent = allPropertyContent[hotelCode];
                  return {
                    startDate: booking.startDate,
                    endDate: booking.endDate,
                    hotelCode,
                    reservationId,
                    propertyName:
                      propertyContent?.landingPagePropertyName ||
                      [propertyContent?.typeName, propertyContent?.shortName]
                        .filter(Boolean)
                        .join(" "),
                  };
                })
                .map(
                  (
                    {
                      startDate,
                      endDate,
                      hotelCode,
                      reservationId,
                      propertyName,
                    },
                    index
                  ) => {
                    return (
                      <Link
                        key={index}
                        to={profileRoutes.profileBookingPath.to({
                          hotelCode,
                          reservationId,
                          locale,
                        })}
                        className={classNames("fs-trip-card", {
                          "fs-trip-card--condensed":
                            upcomingTrips.length >
                              TRIPS_DISPLAY_UNCONDENSED_LIMIT && index > 0,
                        })}
                      >
                        <div
                          className="fs-trip-card__image"
                          style={{
                            backgroundImage: `url(${getPropertyImage(
                              hotelCode
                            )})`,
                          }}
                        />
                        <div className="fs-trip-card__details">
                          <p className="fs-trip-card__details__date">
                            {formatDateRange(startDate, endDate, locale)}
                          </p>
                          <p
                            className="fs-trip-card__details__destination"
                            dangerouslySetInnerHTML={{
                              __html: t(formatBeverlyWilshire(propertyName)),
                            }}
                          />
                        </div>
                      </Link>
                    );
                  }
                )}
              {upcomingTrips.length > TRIPS_DISPLAY_INITIAL_LIMIT &&
                !showAllTrips && (
                  <div className="fs-upcoming-trips__see-all-trips-container">
                    <Button
                      kind="link"
                      onClick={() => setShowAllTrips(true)}
                      aria-label={t("See all trips")}
                    >
                      + {t("See all trips")}
                    </Button>
                  </div>
                )}
            </div>

            {!media.isMobileApp && (
              <div className="fs-upcoming-trips__actions">
                <>
                  <button
                    type="button"
                    className="fs-upcoming-trips__action fs-upcoming-trips__action--add-reservation"
                    onClick={() => dispatch(setAddReservationModalIsOpen(true))}
                    aria-label={t("Add existing reservation")}
                  >
                    {t("Add existing reservation")}
                  </button>
                  {addReservationModalIsOpen && <AddReservationModal />}
                </>
                <Link
                  to={bookingFlowRoutes.planYourStay.to({
                    locale,
                    plainSearchParams,
                  })}
                  className="fs-upcoming-trips__action fs-upcoming-trips__action--book-trip"
                >
                  {t("Book a new trip")}
                </Link>
                {isEmployee && (
                  <Link
                    to={profileRoutes.employeeProfilePath.to({ locale })}
                    className="fs-upcoming-trips__action fs-upcoming-trips__action--book-trip book-employee-travel"
                  >
                    {t("Book Employee Travel")}
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </ThemeContextProvider>
  );
}

export default UpcomingTrips;
