import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";

const bodyHiddenClassName = "fs-shopping-cart__body__hidden";

function CloseIcon({ className, ...props }) {
  return (
    <span className={classNames("icon icon-close", className)} {...props} />
  );
}

function CollapseIcon({ className, ...props }) {
  return (
    <span className={classNames("icon icon-caret", className)} {...props} />
  );
}

export default function BottomDrawer({ title, totalPrice, children }) {
  const { t } = useTranslation();
  const [state, setState] = useState("closed");

  const isOpen = state === "open";
  const isOpening = state === "opening";
  const isClosing = state === "closing";
  const isClosed = state === "closed";

  const bottomDrawerClasses = classNames("bottom-drawer", {
    "bottom-drawer--open": isOpen,
    "bottom-drawer--opening": isOpening,
    "bottom-drawer--closed": isClosed,
    "bottom-drawer--closing": isClosing,
  });

  useEffect(() => {
    if (isClosed) {
      document.body.classList.remove(bodyHiddenClassName);
    } else {
      document.body.classList.add(bodyHiddenClassName);
    }

    return () => {
      document.body.classList.remove(bodyHiddenClassName);
    };
  }, [isClosed]);

  const ref = useRef();

  useEffect(() => {
    const handleTransitionEnd = (e) => {
      if (e.srcElement.isSameNode(ref.current)) {
        if (e.target.offsetHeight === 90) {
          setState("closed");
        } else {
          setState("open");
        }
      }
    };

    const el = ref.current;
    el.addEventListener("transitionend", handleTransitionEnd);

    return () => {
      el.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, []);

  return (
    <div className={bottomDrawerClasses} ref={ref}>
      <div className="bottom-drawer__closed-content">
        <button
          type="button"
          onClick={() => {
            setState("opening");
          }}
          className="bottom-drawer__expand-button bottom-drawer__collapse-icon"
        >
          {title && <div className="ty-c4 ">{title}</div>}
          <CollapseIcon />
        </button>
        <div className="bottom-drawer__details">
          <div className="ty-c1 bottom-drawer__detail">{t("Est. Total")}*</div>
          <div className="ty-h3 bottom-drawer__detail">{totalPrice}</div>
        </div>
      </div>

      <div className="bottom-drawer__open-content">
        <div className="bottom-drawer__children">
          <button
            type="button"
            aria-label="close"
            onClick={() => {
              setState("closing");
            }}
            className="bottom-drawer__close bottom-drawer__close-icon"
          >
            <CloseIcon />
          </button>
          {children}
        </div>
      </div>
    </div>
  );
}
