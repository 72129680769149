import { errorCodes } from "utils/errorCodes";
import { SIGN_IN_VIEW_STATES } from "store/signIn/signIn.slice";

export const selectOtpSentTo = ({
  signIn: {
    requestOTP: { sendCodeVia, sendCodeTo },
  },
}) => sendCodeTo[sendCodeVia];

export const selectOtpSentToParams = ({
  signIn: {
    requestOTP: { sendCodeTo },
  },
}) => sendCodeTo;

export const selectOtpSentVia = ({
  signIn: {
    requestOTP: { sendCodeVia },
  },
}) => sendCodeVia;

export const selectOtpSubmittedTo = ({
  signIn: {
    submitOTP: { sendCodeVia, sendCodeTo },
  },
}) => sendCodeTo[sendCodeVia];

export const selectOtpSubmittedVia = ({
  signIn: {
    submitOTP: { sendCodeVia },
  },
}) => sendCodeVia;

export const selectErrors = ({ signIn }) => {
  return [
    ...signIn.supplierErrors,
    ...signIn.apiErrors,
    ...signIn.errors,
  ].filter((error) => !error.field);
};

export const selectError = (state) => (selectErrors(state) || [])[0];

export const selectViewState = (state) => {
  const { errorCode } = selectError(state) || {};
  const { viewState } = state.signIn;

  if (
    (viewState === SIGN_IN_VIEW_STATES.CODE_INVALID &&
      errorCode !== errorCodes.INVALID_LOGIN_OTP_CODE) ||
    (viewState === SIGN_IN_VIEW_STATES.CODE_EXPIRED &&
      errorCode !== errorCodes.INVALID_LOGIN_OTP_EXPIRED)
  ) {
    return SIGN_IN_VIEW_STATES.REQUEST_OTP;
  }

  return viewState;
};

export const selectActiveSignInModal = ({ signIn }) => {
  return signIn.activeSignInModal;
};
