import { ajax } from "rxjs/ajax";
import { catchError, pluck } from "rxjs/operators";
import { of } from "rxjs";

import env from "config/env";
import { basePostRequest } from "../../tretail/baseRequest";

const { API_BASE_URL } = env;

export default function getThrottle$({ route = "" } = {}) {
  return ajax({
    ...basePostRequest(),
    url: `${API_BASE_URL}/throttle`,
    body: {
      route,
    },
  }).pipe(
    pluck("response"),
    catchError(() => {
      return of({});
    })
  );
}
