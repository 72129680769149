import get from "lodash/fp/get";

export default ({ booking, startDate, endDate }) => {
  const hotelProducts = get(["hotelProducts", "0"], booking) || {};

  return {
    searchParams: {
      dates: {
        checkIn: startDate,
        checkOut: endDate,
      },
      hotelCode: hotelProducts?.hotelCode,
      rooms: [
        {
          adults: get(["guestCount", "numberAdults"], hotelProducts),
          children: get(["guestCount", "numberChildren"], hotelProducts),
        },
      ],
    },
  };
};
