import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import isEmpty from "lodash/fp/isEmpty";

import { useTranslation } from "hooks";
import * as bookingFlowRoutes from "BookingFlow/bookingFlowRoutes";
import * as profileRoutes from "Profile/profileRoutes";
import { Button, Link } from "Profile/components";
import {
  selectProfileName,
  selectHasOwnedProperties,
  selectCompletePercentage,
} from "store/profile";
import { selectIsEmployeeProfile } from "store/employeeProfile";
import ThemeContextProvider, { THEMES } from "contexts/ThemeContext";
import {
  setIsOpen as setAddReservationModalIsOpen,
  selectIsOpen as selectAddReservationModalIsOpen,
} from "store/addReservationModal";
import { MediaContext } from "contexts/MediaContext";
import { selectPlainSearchParams } from "store/searchResults";
import { SignInModal } from "Authentication";
import AddReservationModal from "./AddReservationModal";
import EditArrivalTime from "./EditArrivalTime";

function TopBar({ soonestBooking, reservationId }) {
  const { isMobileApp } = useContext(MediaContext);
  const { t, locale } = useTranslation();
  const dispatch = useDispatch();

  const addReservationModalIsOpen = useSelector(
    selectAddReservationModalIsOpen
  );
  const profileName = useSelector(selectProfileName(t));
  const hasOwnedProperties = useSelector(selectHasOwnedProperties);
  const completePercentage = useSelector(selectCompletePercentage);
  const isProfileComplete = completePercentage === 100;
  const isEmployee = useSelector(selectIsEmployeeProfile);
  const plainSearchParams = useSelector(selectPlainSearchParams);

  useEffect(() => {
    window.utag_data = window.utag_data || {};
    window.utag_data.profileCompletionPercentage = completePercentage;
  }, [completePercentage]);

  return (
    <ThemeContextProvider theme={THEMES.LIGHT}>
      <div className="view--profile__top-bar">
        <div className="user-details-row">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-7 col-lg-6">
                <div className="user-details">
                  {hasOwnedProperties && (
                    <p className="user-details__private-residence-owner-label">
                      {t("Private Residence Owner")}
                    </p>
                  )}
                  <h1 className="user-details__name">{profileName}</h1>
                  <SignInModal
                    id="editProfileTopBar"
                    buttonLabel={
                      isProfileComplete
                        ? t("Edit your profile")
                        : t("Complete your profile")
                    }
                    type="Link"
                    kind="link"
                    afterSignInRedirectTo={profileRoutes.editProfilePath.to({
                      locale,
                    })}
                  />
                  {isEmployee && (
                    <div className="employee-stay-history-link">
                      <Link
                        to={profileRoutes.employeeStayHistoryPath.to({
                          locale,
                        })}
                      >
                        {t("Employee Stay History")}
                      </Link>
                    </div>
                  )}
                </div>
              </div>

              {!isMobileApp && (!reservationId || !isEmpty(soonestBooking)) && (
                <div className="col-sm-12 col-md-5 col-lg-6 col-user-contextual">
                  {!isEmpty(soonestBooking) ? (
                    <EditArrivalTime soonestBooking={soonestBooking} />
                  ) : (
                    <div className="contextual">
                      <p className="contextual__question">
                        {t(
                          "You don't seem to have any upcoming trips. Would you like to add one?"
                        )}
                      </p>
                      <div
                        className={classNames("contextual__actions", {
                          employee: isEmployee,
                        })}
                      >
                        {/* className value need to be customised */}
                        <div className="contextual__action">
                          <Button
                            onClick={() =>
                              dispatch(setAddReservationModalIsOpen(true))
                            }
                            aria-label={t("Add existing reservation")}
                          >
                            {t("Add existing reservation")}
                          </Button>
                          {addReservationModalIsOpen && <AddReservationModal />}
                        </div>

                        <div className="contextual__action">
                          <Link
                            kind="secondary"
                            to={bookingFlowRoutes.planYourStay.to({
                              locale,
                              plainSearchParams,
                            })}
                          >
                            {t("Book Now")}
                          </Link>
                        </div>

                        {isEmployee && (
                          <div className="contextual__action">
                            <Link
                              kind="secondary"
                              to={profileRoutes.employeeProfilePath.to({
                                locale,
                              })}
                            >
                              {t("Book Employee Travel")}
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ThemeContextProvider>
  );
}

export default TopBar;
