import { ofType } from "redux-observable";
import {
  catchError,
  map,
  switchMap,
  takeUntil,
  mergeMap,
} from "rxjs/operators";
import { of } from "rxjs";

import { getMemberDetails$, hasMembership$ } from "api/memberDetails";
import {
  fetchMemberDetails,
  fetchMemberDetailsFulfilled,
  fetchMemberDetailsFailed,
  fetchMemberDetailsCancel,
} from "../memberDetails.slice";

export function fetchMemberDetails$() {
  return hasMembership$().pipe(
    switchMap((hasMembership) => {
      if (
        hasMembership?.HasMembership === true ||
        hasMembership?.HasMembership === "True"
      ) {
        return getMemberDetails$().pipe(
          mergeMap((response = {}) => of(response)),

          catchError(({ response = {} } = {}) => {
            return of(response);
          })
        );
      }

      return null;
    }),

    catchError(({ response = {} } = {}) => {
      return of(response);
    })
  );
}

export default function fetchMemberDetailsEpic(action$) {
  return action$.pipe(
    ofType(fetchMemberDetails.type),
    switchMap(() =>
      fetchMemberDetails$().pipe(
        map(fetchMemberDetailsFulfilled),

        catchError(({ response = {} }) =>
          of(
            fetchMemberDetailsFailed({
              apiErrors: response?.apiErrors || [],
              supplierErrors: response?.supplierErrors || [],
            })
          )
        ),

        takeUntil(action$.pipe(ofType(fetchMemberDetailsCancel.type)))
      )
    )
  );
}
