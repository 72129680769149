import React from "react";

export default function ErrorBlock({ children }) {
  return (
    <div className="error-message-block">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 0C4.47709 0 0 4.47709 0 10C0 15.5229 4.47709 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47709 15.5229 0 10 0ZM10 18.9858C5.03724 18.9858 1.01423 14.9628 1.01423 10C1.01423 5.03724 5.03724 1.01423 10 1.01423C14.9628 1.01423 18.9858 5.03724 18.9858 10C18.9858 14.9628 14.9628 18.9858 10 18.9858Z"
          fill="#FF0000"
        />
        <path
          d="M10.0825 14.045C9.62272 14.045 9.25 14.4177 9.25 14.8775C9.25 15.3373 9.62272 15.71 10.0825 15.71C10.5423 15.71 10.915 15.3373 10.915 14.8775C10.915 14.4177 10.5423 14.045 10.0825 14.045ZM10.795 8.3V5H9.37V8.3L9.745 12.71H10.42L10.795 8.3Z"
          fill="#FF0000"
        />
      </svg>
      <div className="error-message-block__main-text ty-c1">{children}</div>
      {/* <div className="error-message-block__sub-text ty-b3">
        There was issue with booking your stay. Please try again.
      </div> */}
    </div>
  );
}
