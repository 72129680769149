import React, { useState, useEffect } from "react";
import { useTranslation } from "hooks";
import { pluralize, formatDateRange, webCheckinMapOptions } from "utils/utils";
import { convert12HourTo24hour } from "utils";
import { DATA, ETA } from "utils/dateFormats";
import { useForm, Controller } from "react-hook-form";
import {
  TextInput,
  Button,
  Checkbox,
  Link,
  Dropdown,
} from "Profile/components";
import { getEmployeeRate } from "Profile/utils/getEmployeeRate";
import * as regexPatterns from "utils/regexPatterns";
import ArrivalTimeSelects from "Profile/components/ArrivalTimeSelects";
import getInitialArrivalTime from "utils/getInitialArrivalTime";
import { parse, differenceInMinutes, isBefore } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import env from "config/env";
import { last as _last } from "lodash";
import { useSelector } from "react-redux";
import { selectIsUserPersistent } from "store/profile";
import DisplayOnlyInput from "Profile/components/DisplayOnlyInput";
import classNames from "classnames";
import PastCheckInTimeModal from "./PastCheckInTimeModal";
import FullScreenLayout from "../../FullScreenLayout";
import { ErrorBanner } from "../Banners";
import FormSignature from "../FormSignature";
import CustomField from "./CustomField";

const { STATIC_SITE_URL } = env;

function CheckInForm({
  booking,
  propertyData: {
    name,
    horizontalImage,
    owsCode: hotelCode,
    enabled,
    phone,
    timeZone,
  },
  onConfirmReservationDetails,
  onConfirmCheckInRequest,
  checkInError,
  clearErrors,
  propertyWebCheckin,
}) {
  const { t, locale } = useTranslation();
  const isUserPersistent = useSelector(selectIsUserPersistent);

  const {
    contactDetails: {
      email: bookingEmail,
      name: { firstName, surname: lastName },
    },
    hotelProducts: [
      {
        reservationId,
        checkOutDate,
        checkInDate,
        guestCount: { numberAdults, numberChildren },
        roomRate: { priceViewable = true } = {},
      },
    ],
    price: {
      total: {
        cash: { amount, currencyCode },
      },
    },
  } = booking;

  const employeeRate = getEmployeeRate({ upcomingTripDetails: booking });
  const employeeCompRate = !!employeeRate && employeeRate.compRate;

  const { register, handleSubmit, control, errors, formState, watch, trigger } =
    useForm({
      mode: "onChange",
      defaultValues: {
        arrivalTime:
          propertyWebCheckin &&
          getInitialArrivalTime({
            checkInTime: propertyWebCheckin.checkInTime,
          })(booking),
        flightNumber: "",
        firstName,
        lastName,
        noMarketing: "",
      },
    });

  const propertyCheckInTime =
    propertyWebCheckin &&
    parse(
      convert12HourTo24hour(propertyWebCheckin.checkInTime),
      ETA,
      new Date()
    );

  const watchedEta = watch("arrivalTime");
  const watchedNationality = watch("nationality");
  const [showPastCheckInTime, setShowPastCheckInTime] = useState(false);
  const [isEarlyCheckIn, setIsEarlyCheckIn] = useState(false);
  const [checkInSubmission, setCheckInSubmission] = useState({});
  const [showRegCard, setShowRegCard] = useState(false);
  const [domestic, setDomestic] = useState(false);

  useEffect(() => {
    // need to check if it's past the property check in time
    const parsedEta = parse(watchedEta, ETA, new Date());
    if (differenceInMinutes(propertyCheckInTime, parsedEta) > 0) {
      setShowPastCheckInTime(true);
      setIsEarlyCheckIn(true);
    } else {
      setIsEarlyCheckIn(false);
    }
  }, [watchedEta]);

  useEffect(() => {
    setDomestic(watchedNationality === propertyWebCheckin.countryCode);
  }, [watchedNationality]);

  useEffect(() => {
    if (checkInError) {
      setShowRegCard(false);
      window.scrollTo(0, 0);
    }
  }, [checkInError]);

  useEffect(() => {
    trigger("arrivalTime");
  }, []);

  const isCheckInTimeValid = (etaValue) =>
    !(
      !!timeZone &&
      !!booking?.startDate &&
      !!etaValue &&
      isBefore(
        zonedTimeToUtc(
          parse(`${booking.startDate}${etaValue}`, DATA + ETA, new Date()),
          timeZone
        ),
        new Date()
      )
    );

  const onConfirmDetails = ({
    email,
    arrivalTime,
    flightNumber,
    nationality,
    additionalInformation,
  }) => {
    onConfirmReservationDetails({ isEarlyCheckIn });
    setCheckInSubmission({
      email,
      arrivalTime,
      flightNumber,
      nationality,
      additionalInformation,
    });
    setShowRegCard(true);
    window.scrollTo(0, 0);
  };

  const onSubmitCheckInRequest = (formPayload) => {
    const { noMarketing, signature, termsAndConditions } = formPayload;
    onConfirmCheckInRequest({
      ...checkInSubmission,
      noMarketing,
      firstName,
      lastName,
      reservationId,
      hotelCode,
      signature,
      // convert nationality back to full string
      nationality: (
        propertyWebCheckin.nationalityOptions.find(
          ({ value }) => value === checkInSubmission.nationality
        ) || { title: checkInSubmission.nationality }
      ).title,
      termsAndConditions:
        termsAndConditions && propertyWebCheckin.termsAndConditions,
      termsAndConditionsPrompt:
        termsAndConditions && propertyWebCheckin.termsAndConditionsPrompt,
    });
  };

  const showAdditionalDetails =
    propertyWebCheckin?.registrationCard?.customFields?.length > 0;
  const additionalDetailsFields = [];

  if (showAdditionalDetails) {
    // map from obj to element
    const customFields = propertyWebCheckin?.registrationCard?.customFields.map(
      (field, idx) => (
        <CustomField
          field={field}
          register={register}
          errors={errors}
          key={field.label}
          domestic={domestic}
          idx={idx}
        />
      )
    );
    // first push on the nationality dropdown and the first field item
    additionalDetailsFields.push([
      <Dropdown
        name="nationality"
        label={t("Nationality")}
        options={webCheckinMapOptions(propertyWebCheckin?.nationalityOptions)}
        required
        register={register({
          required: true,
        })}
        error={errors.nationality && t("This field is required.")}
      />,
      customFields[0],
    ]);

    // now add in any addition fields
    for (let i = 1; i < customFields.length; i += 1) {
      const pushNew = i % 2 === 1;

      if (pushNew) {
        // add a new field group
        additionalDetailsFields.push([customFields[i]]);
      } else {
        // add to the last field group
        _last(additionalDetailsFields).push(customFields[i]);
      }
    }
  }

  const showLanguageSelector =
    propertyWebCheckin.supportedLanguages?.length > 1 && !showRegCard;

  return (
    <FullScreenLayout
      backgroundImageSrc={horizontalImage}
      hideLanguageSelector={!showLanguageSelector}
      languagesFilter={({ locale: localeOption }) =>
        propertyWebCheckin.supportedLanguages.includes(localeOption)
      }
    >
      {showPastCheckInTime && (
        <PastCheckInTimeModal close={() => setShowPastCheckInTime(false)} />
      )}

      {checkInError && (
        <ErrorBanner message={t(checkInError)} onClose={clearErrors} />
      )}

      <div className="step-2">
        <div className="step-2__inner">
          <form
            className="form-gray"
            onSubmit={handleSubmit(
              showRegCard ? onSubmitCheckInRequest : onConfirmDetails
            )}
          >
            <h1 className="ty-h2">{t("Check In")}</h1>
            <div className="booking-info">
              <p>{name}</p>
              <p>{t("Reservation #: {0}", [reservationId])}</p>
              <p>{`${formatDateRange(checkInDate, checkOutDate, locale)}`}</p>
              <p>
                {pluralize(t, numberAdults, "Adult")}
                {numberChildren > 0
                  ? `, ${pluralize(t, numberChildren, "Child", "Children")}`
                  : ""}
              </p>
            </div>

            {!enabled && (
              <div className="property-disabled">
                <h2 className="ty-c1">
                  {t(
                    "Online check-in requests are temporarily unavailable for this property."
                  )}
                </h2>
                <p>
                  {t(
                    "For assistance, please contact the property directly at {0}.",
                    [phone]
                  )}
                </p>
              </div>
            )}

            {!showRegCard && enabled && (
              <>
                <h1 className="ty-h2">{t("Confirm My Details")}</h1>

                <div className="row">
                  <div className="col-sm-12 col-md-6 display-field">
                    <label className="ty-c4">{t("First Name")}</label>
                    <span>{firstName}</span>
                  </div>
                  <div className="col-sm-12 col-md-6 display-field">
                    <label className="ty-c4">{t("Last Name")}</label>
                    <span>{lastName}</span>
                  </div>

                  <div className="col-sm-12">
                    <Controller
                      name="arrivalTime"
                      control={control}
                      as={ArrivalTimeSelects}
                      disabled={false}
                      rules={{
                        validate: { isCheckInTimeValid },
                      }}
                      label={t("Expected arrival time")}
                      error={
                        errors.arrivalTime &&
                        t("The time you have selected is in the past.")
                      }
                    />
                  </div>
                  <div className="col-sm-12">
                    <TextInput
                      name="flightNumber"
                      label={t("Flight Number (Optional)")}
                      register={register()}
                    />
                  </div>

                  <div
                    className={classNames("col-sm-12", {
                      "persistent-email": isUserPersistent && bookingEmail,
                    })}
                  >
                    <p className="email-notification-label">
                      {isUserPersistent && bookingEmail
                        ? t("We will send the room ready notification to:")
                        : t("Send room ready notification to")}
                    </p>
                  </div>
                  <div
                    className={classNames("col-sm-12", {
                      "persistent-email": isUserPersistent && bookingEmail,
                    })}
                  >
                    {isUserPersistent && bookingEmail ? (
                      <DisplayOnlyInput
                        type="text"
                        name="email"
                        value={bookingEmail}
                        displayValue={bookingEmail}
                        includeHiddenInput
                        register={register}
                      />
                    ) : (
                      <TextInput
                        type="email"
                        label={t("Email")}
                        defaultValue={bookingEmail}
                        hasValue
                        name="email"
                        register={register({
                          required: true,
                          pattern: regexPatterns.email,
                        })}
                        required
                        error={errors.email && t("Please enter your email.")}
                      />
                    )}
                  </div>

                  {priceViewable && !employeeCompRate && (
                    <div className="col-sm-12 col-md-6 display-field">
                      <label className="ty-c4">{t("Estimated Total")}</label>
                      <span>{`${currencyCode} ${amount}`}</span>
                    </div>
                  )}
                </div>

                {showAdditionalDetails && (
                  <div className="additional-details">
                    <div className="row">
                      <div className="col-sm-12">
                        <h1 className="ty-h2">{t("Additional Details")}</h1>
                        <p>
                          {t(
                            "Please complete the following required fields to help us expedite your arrival process"
                          )}
                        </p>
                      </div>
                    </div>

                    {additionalDetailsFields.map((fieldGroup, gIdx) => (
                      <div className="row" key={`field-group-${gIdx}`}>
                        {fieldGroup.map((el, fIdx) => (
                          <div
                            className="col-sm-12 col-md-6"
                            key={`field-${gIdx}-${fIdx}`}
                          >
                            {el}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}

            {showRegCard && enabled && (
              <>
                <h1 className="ty-h2">{t("REGISTRATION CARD")}</h1>

                <div className="reg-card-paragraphs">
                  {propertyWebCheckin.registrationCard.text.map((paragraph) => (
                    <p key={paragraph}>{paragraph}</p>
                  ))}
                </div>

                {propertyWebCheckin?.signatureEnabled && (
                  <div className="reg-card-signature">
                    <p className="prompt">
                      {t(
                        "Please add your E-Signature before proceeding your web check in."
                      )}
                    </p>
                    <FormSignature
                      name="signature"
                      required
                      label={t("Add Signature")}
                      clearLabel={t("Clear")}
                      register={register({ required: true })}
                      trigger={trigger}
                    />
                  </div>
                )}

                {propertyWebCheckin.termsAndConditions && (
                  <div className="webcheckin-terms-and-conditions">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: propertyWebCheckin.termsAndConditions,
                      }}
                    />
                    <Checkbox
                      name="termsAndConditions"
                      register={register({ required: true })}
                      required
                    >
                      {propertyWebCheckin.termsAndConditionsPrompt}
                    </Checkbox>
                  </div>
                )}
              </>
            )}

            <div className="controls">
              {!enabled && (
                <Link kind="primary" href={STATIC_SITE_URL}>
                  {t("Back to Four Seasons Home")}
                </Link>
              )}
              {enabled && (
                <Button type="submit" disabled={!formState.isValid}>
                  {t(
                    showRegCard
                      ? "I AGREE & PROCEED WITH CHECK-IN"
                      : "PROCEED WITH CHECK-IN"
                  )}
                </Button>
              )}
            </div>

            {showRegCard && propertyWebCheckin.optOut.enabled && (
              <div className="subscription-disclaimer">
                <p>{propertyWebCheckin.optOut.intro}</p>
                <Checkbox name="noMarketing" register={register}>
                  {propertyWebCheckin.optOut.prompt}
                </Checkbox>
              </div>
            )}
          </form>
        </div>
      </div>
    </FullScreenLayout>
  );
}

export default CheckInForm;
