import React from "react";
import { useTranslation } from "hooks";

function SmokingOptions({ name, formControls, defaultValue = false }) {
  const { t } = useTranslation();

  const { register } = formControls;

  const defaultSmoking =
    typeof defaultValue === "string" ? defaultValue === "true" : defaultValue;

  return (
    <div className="smoking-options">
      <ul className="radio-buttons">
        <li>
          <label className="fs-radio">
            <input
              type="radio"
              name={name}
              value="true"
              ref={register({ required: true })}
              defaultChecked={defaultSmoking}
            />
            <span>{t("Smoking")}</span>
          </label>
        </li>

        <li>
          <label className="fs-radio">
            <input
              type="radio"
              name={name}
              value="false"
              ref={register({ required: true })}
              defaultChecked={!defaultSmoking}
            />
            <span>{t("Non-Smoking")}</span>
          </label>
        </li>
      </ul>
    </div>
  );
}

export default SmokingOptions;
