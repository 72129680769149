export const SECTIONS = {
  MYSELF: "guest",
  SOMEONE_ELSE: "booker",
  AGENT: "agent",
};

export const PAYMENT_METHODS = {
  CREDITCARD: "creditCard",
  ALIPAY: "alipay",
};
