import { useEffect } from "react";

import env from "config/env";
import { isMobileApp } from "utils";

const { STATIC_SITE_URL } = env;

export default function useGdprBanner() {
  useEffect(() => {
    if (!window?.FS?.GDPR) {
      const script = document.createElement("script");
      script.src = `${STATIC_SITE_URL}/alt/fshr/shared/js/gdpr-banner.js`;
      document.head.appendChild(script);

      if (isMobileApp()) {
        const style = document.createElement("style");
        style.innerHTML =
          "#onetrust-consent-sdk #onetrust-banner-sdk { padding-bottom: 100px; }";
        document.head.appendChild(style);
      }
    }
  }, []);
}
