import React from "react";

import BookingSummary from "Profile/UpcomingTripView/BookingSummary";
import { useTranslation } from "hooks";
import Modal from "Profile/ProfileView/Modal";
import { Button } from "Profile/components";

export default function ArrivalDetailsModal({
  highlightsMap,
  bookingMessages,
  rates,
  estimatedTotalDisclaimer,
  isWebChatEnabled,
  openWebChat,
  close,
}) {
  const { t } = useTranslation();

  return (
    <Modal close={close}>
      <div className="fs-itineray-modal view--upcoming-trip__modal">
        <button
          type="button"
          onClick={close}
          aria-label={t("Close")}
          className="fs-itineray-modal__close-btn"
        >
          <span className="icon icon-close" />
        </button>
        <div className="fs-itineray-modal__header arrival">
          <h1 className="fs-itineray-modal__header__title">
            {t("Estimated Total")}
          </h1>
        </div>

        <div className="arrival">
          <BookingSummary
            highlights={highlightsMap}
            rates={rates}
            estimatedTotalDisclaimer={estimatedTotalDisclaimer}
          />
          {!!estimatedTotalDisclaimer && (
            <div
              className="upcoming-trip__estimated-total-disclaimer"
              dangerouslySetInnerHTML={{ __html: estimatedTotalDisclaimer }}
            />
          )}
          {bookingMessages.map(({ title, body }, index) => (
            <div className="upcoming-trip__booking-message" key={index}>
              <p>
                <strong dangerouslySetInnerHTML={{ __html: title }} />
              </p>
              <div dangerouslySetInnerHTML={{ __html: body }} />
            </div>
          ))}
        </div>

        {isWebChatEnabled && (
          <div className="fs-itineray-modal__chat-container">
            <div className="fs-itineray-modal__chat">
              <p className="ty-b1">
                {t("Need to update your request? Please chat with us.")}
              </p>
              <Button kind="link" onClick={openWebChat}>
                {t("Chat with us")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
