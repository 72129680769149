import zip from "lodash/zip";

const isSoughtRoomRateId = ({ roomRateId, roomCode, offerCode }) =>
  [roomCode, offerCode].every((s) => roomRateId.includes(s));

export const canConstructBooking = ({
  roomCodes,
  offerCodes,
  searchResults: { hotelOptions: [{ roomOptions, roomRates }] = [] },
}) =>
  zip(roomCodes, offerCodes).every(
    ([roomCode, offerCode], index) =>
      roomOptions[index].roomTypeIds.some(({ roomRateIds }) =>
        roomRateIds.some(({ roomRateId }) =>
          isSoughtRoomRateId({ roomRateId, roomCode, offerCode })
        )
      ) &&
      roomRates.some(
        ({ roomRateId, prices: [{ id }] }) =>
          isSoughtRoomRateId({ roomRateId, roomCode, offerCode }) && id
      )
  );

const getResultId = ({ roomTypeIds, roomCode, offerCode }) =>
  roomTypeIds.reduce(
    (acc, { roomRateIds }) =>
      roomRateIds.some(
        ({ roomRateId, id }) =>
          isSoughtRoomRateId({ roomRateId, roomCode, offerCode }) && id
      )
        ? roomRateIds.find(({ roomRateId }) =>
            isSoughtRoomRateId({ roomRateId, roomCode, offerCode })
          ).id
        : acc,
    undefined
  );

const getPriceId = ({ roomRates, roomCode, offerCode }) =>
  roomRates.reduce(
    (acc, { roomRateId, prices: [{ id }] }) =>
      isSoughtRoomRateId({ roomRateId, roomCode, offerCode }) ? id : acc,
    undefined
  );

export const selectRoomsWithOffers = ({
  roomCodes,
  offerCodes,
  searchResults: { hotelOptions: [{ roomOptions, roomRates }] = [] },
}) =>
  zip(roomCodes, offerCodes).map(([roomCode, offerCode], index) => ({
    resultId: getResultId({
      roomTypeIds: roomOptions[index].roomTypeIds,
      roomCode,
      offerCode,
    }),
    priceId: getPriceId({
      roomRates,
      roomCode,
      offerCode,
    }),
  }));

export const indexHotelProducts = (hotelProducts) =>
  [...hotelProducts]
    .sort((a, b) => a.productId - b.productId)
    .map((a, index) => ({ ...a, roomIndex: index + 1 }));

export const getUpsells = ({
  roomIndices,
  hotelProducts,
  hotelOptions: { roomRates, roomTypes, roomOptions },
}) =>
  indexHotelProducts(hotelProducts).map(
    ({
      roomRate: { ratePlanCode },
      roomTypes: [{ roomTypeId }],
      roomIndex,
    }) => {
      const [{ upsell: upsellRoomPlanCode }] = roomTypes.filter(
        ({ roomTypeId: a }) => roomTypeId === a
      );

      const roomCode = roomIndices.includes(roomIndex)
        ? upsellRoomPlanCode
        : roomTypeId;

      return {
        resultId: getResultId({
          roomTypeIds: roomOptions.find(({ roomIndex: a }) => a === roomIndex)
            .roomTypeIds,
          roomCode,
          offerCode: ratePlanCode,
        }),
        priceId: getPriceId({
          roomRates,
          roomCode,
          offerCode: ratePlanCode,
        }),
      };
    }
  );
