import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import find from "lodash/fp/find";
import flatMap from "lodash/fp/flatMap";
import flow from "lodash/fp/flow";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import omit from "lodash/fp/omit";
import pick from "lodash/fp/pick";
import pickBy from "lodash/fp/pickBy";
import set from "lodash/fp/set";
import toInteger from "lodash/fp/toInteger";
import uniqueId from "lodash/fp/uniqueId";

import { useTranslation } from "hooks";
import { MediaContext } from "contexts/MediaContext";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import { addRequestEvent, addRequestEventCancel } from "store/guestRequests";
import {
  welcomeAmentitiesAdded,
  welcomeAmentitiesRemoved,
} from "store/analyticEvents";
import { Button } from "Profile/components";
import TransportationAmenitiesLayout from "./TransportationAmenitiesLayout";
import ToggleBlock from "./ToggleBlock";
import AmenityItem from "./AmenityItem";
import CheckmarkIcon from "./CheckmarkIcon";
import ShoppingCartSummary from "./ShoppingCartSummary";
import ErrorBlock from "./ErrorBlock";

export default function ArrivalAmenities({
  booking,
  goBack,
  availableWelcomeAmenities,
  goBackButtonLabel,
  isOnItineraryPage = false,
}) {
  const media = useContext(MediaContext);
  const { t, localeIsoCode } = useTranslation();
  const dispatch = useDispatch();

  const scrollContent = useRef();

  const [requestId, setRequestId] = useState(null);
  const requestState = useSelector(
    get(["guestRequests", "addingRequestStates", requestId])
  );

  const isSubmitting = requestState === "SUBMITTING";
  const requestSuccessful = requestState === "COMPLETE";
  const requestFailed = requestState === "FAILED";

  useEffect(() => {
    if (requestFailed && scrollContent?.current) {
      scrollContent.current.scrollTop = 0;
    }
  }, [requestFailed]);

  const hotelCode = get(["hotelProducts", 0, "hotelCode"])(booking);
  const currencyCode = get(["price", "total", "cash", "currencyCode"])(booking);
  const welcomeAmenities = getOr([], ["categories"])(availableWelcomeAmenities);

  const [toggleBlockState, setToggleBlockState] = useState(null);
  const [_requestedAmenities, setRequestedAmenities] = useState({});
  const requestedAmenities = Object.values(_requestedAmenities);

  const removeItem = (code) => {
    const amenityToRemove = flow(
      flatMap(getOr([], "products")),
      find({ sku: code })
    )(welcomeAmenities);

    dispatch(
      welcomeAmentitiesRemoved({
        booking,
        hotelCode,
        isOnItineraryPage,
        amenityItem: amenityToRemove,
      })
    );

    setRequestedAmenities(omit(code));
  };

  const addRequestedAmenities = (amenityToAdd) => {
    if (amenityToAdd.numQuantity === 0) {
      removeItem(amenityToAdd.code);
    } else {
      const amenityItem = flow(
        flatMap(getOr([], "products")),
        find({ sku: amenityToAdd.code })
      )(welcomeAmenities);

      dispatch(
        welcomeAmentitiesAdded({
          booking,
          hotelCode,
          isOnItineraryPage,
          amenityToAdd,
          amenityItem,
        })
      );

      setRequestedAmenities(
        flow(
          set([amenityToAdd.code], amenityToAdd),
          pickBy((a) => parseInt(a.numQuantity, 10) > 0)
        )
      );
    }
  };

  const submitRequests = () => {
    const surname = get(["contactDetails", "name", "surname"])(booking);
    const reservationId = get(["hotelProducts", 0, "reservationId"])(booking);
    const nextRequestId = uniqueId("request_");
    setRequestId(nextRequestId);
    dispatch(
      addRequestEvent({
        requestId: nextRequestId,
        bookingId: booking.bookingId,
        surname,
        propertyCode: hotelCode,
        reservationId,
        formData: requestedAmenities,
        localeIsoCode,
        isOnItineraryPage,
      })
    );
  };

  useEffect(() => {
    return () => {
      if (requestId) {
        dispatch(addRequestEventCancel({ requestId }));
        setRequestId(null);
      }
    };
  }, [requestId]);

  const totalPrice = requestedAmenities.reduce(
    (acc, curr) => {
      return {
        value: acc.value + curr.price.amount * curr.quantity.units,
        currency: curr.price.currencyCode,
      };
    },
    { value: 0, currency: currencyCode || "USD" }
  );

  if (requestSuccessful) {
    return (
      <TransportationAmenitiesLayout goBack={goBack}>
        <div className="arrival-transportation--success">
          <div className="arrival-transportation--success__content">
            <h3 className="ty-h3">{t("Request Submitted")}</h3>
            <p className="ty-b2">
              {t(
                "Your amenity request has been submitted. If you have any inquiries or would like to make further updates, please chat with us."
              )}
            </p>
          </div>

          <div className="arrival-transportation--success__actions">
            <Button onClick={goBack}>{goBackButtonLabel}</Button>
          </div>
        </div>
      </TransportationAmenitiesLayout>
    );
  }

  return (
    <TransportationAmenitiesLayout
      goBack={goBack}
      title={availableWelcomeAmenities.name}
      image={availableWelcomeAmenities?.image?.url}
      description={t("Would you like to add welcome amenities?")}
      sidebar={
        <div className="arrival-amenities__sidebar">
          <ShoppingCartSummary
            requestedAmenities={requestedAmenities}
            totalPrice={totalPrice}
            removeItem={removeItem}
          />

          {media.isLessThan.md && availableWelcomeAmenities.footnote && (
            <div className="arrival-amenities__sidebar__footnote">
              {availableWelcomeAmenities.footnote}
            </div>
          )}
        </div>
      }
      totalPrice={totalPrice}
      ref={scrollContent}
    >
      {isSubmitting && <BookingFlowLoadingIndicator />}

      {requestFailed && <ErrorBlock>{t("Something went wrong")}</ErrorBlock>}

      {welcomeAmenities.map(({ name, products }) => {
        const isProductInBasket = products.some(
          (product) =>
            flow(
              getOr("0", [product.sku, "numQuantity"]),
              toInteger
            )(_requestedAmenities) > 0
        );

        const amenityItems = products.map((product) => {
          const requestedAmenity = _requestedAmenities[product.sku];
          return (
            <AmenityItem
              key={product.sku + JSON.stringify(requestedAmenity || {})}
              name={product.name}
              description={product.description}
              portraitImage={product.portraitImage?.url}
              price={pick(["amount", "currencyCode"])(product.priceInCurrency)}
              cancellationPolicy={product.cancellationPolicy}
              fieldSets={product.priceInCurrency.fieldSets}
              requestedAmenity={requestedAmenity}
              addRequestedAmenities={addRequestedAmenities}
            />
          );
        });

        if (!name) {
          return (
            <div className="arrival-amenities__items arrival-amenities__items--uncategorised">
              {amenityItems}
            </div>
          );
        }

        return (
          <ToggleBlock
            key={name}
            title={
              <div className="arrival-amenities__category-title">
                <h4 className="ty-c1">{name}</h4>
                {isProductInBasket && <CheckmarkIcon />}
              </div>
            }
            groupControlledToggle={toggleBlockState === name}
            groupController={(value) =>
              setToggleBlockState(value ? name : null)
            }
          >
            <div className="arrival-amenities__items">{amenityItems}</div>
          </ToggleBlock>
        );
      })}

      {availableWelcomeAmenities.footnote && (
        <div className="arrival-amenities__footnote">
          {availableWelcomeAmenities.footnote}
        </div>
      )}

      <div className="arrival-amenities__actions">
        <Button onClick={goBack} kind="secondary">
          {t("Back")}
        </Button>

        <Button
          onClick={submitRequests}
          disabled={requestedAmenities.length === 0 || isSubmitting}
        >
          {t("Submit Request")}
        </Button>
      </div>
    </TransportationAmenitiesLayout>
  );
}
