/* eslint-disable camelcase */
import { sub, add, isAfter, getYear } from "date-fns";
import { push } from "connected-react-router";

import * as bookingFlowRoutes from "BookingFlow/bookingFlowRoutes";
import { selectLocale } from "store/app";
import {
  alipayPaymentFailed,
  bookingInProgressPhaseSet,
  completeBooking,
  selectBookingInProgressHotelCode,
} from "store/bookings";
import { ALI_PAY_SUCCESS_ERROR, NEXT_BOOKING_PHASES } from "fixtures/constants";

export default function alipayCheck(reformattedLocation, state, actions) {
  const getFailureActions = (failureMessage) => [
    ...actions,
    push({
      pathname: bookingFlowRoutes.confirmYourStay.to({
        locale: state?.bookings?.bookingInProgressPhase?.data?.locale,
      }),
      search: reformattedLocation.search,
    }),
    alipayPaymentFailed(failureMessage),
    bookingInProgressPhaseSet({
      bookingInProgressPhase: {
        phase: NEXT_BOOKING_PHASES.ALIPAY,
        data: state?.bookings?.bookingInProgressPhase?.data,
      },
    }),
  ];

  // Check the path is as expected
  if (reformattedLocation?.pathname === "/confirm-your-stay") {
    // Check the query string
    if (
      reformattedLocation?.query?.trade_no &&
      state.bookings.bookingInProgressPhase.data
    ) {
      // Successful payment

      // Check we have the details we need to make the reservation
      const initTime = state.bookings.alipayPaymentInitialised;
      if (initTime) {
        // Check the stored details are from a valid timeframe
        const now = new Date();
        const threshold = sub(now, { minutes: 10 }); // AJD: TODO - Need to determine this threshold
        const paymentStarted = new Date(initTime);
        if (isAfter(paymentStarted, threshold)) {
          // Build the form values
          const expiry = add(now, { years: 1 });
          const { formValues: oldFormValues, locale = selectLocale(state) } =
            state.bookings.bookingInProgressPhase.data;
          const newFormValues = {
            ...oldFormValues,
            creditCard: {
              number: reformattedLocation.query.trade_no,
              cardHolderName: "Alipay Payment",
              expiryDateMonth: "12",
              expiryDateYear: `${getYear(expiry)}`,
            },
            locale,
          };
          // AJD: TODO - Should we actually update bookingInProgressPhase here?

          // Resume the reservation
          return [...actions, completeBooking(newFormValues)];
          // AJD: TODO - Error Case 1 - successful payment but completeBooking fails (won't be handled here, but needs to be handled somewhere)
        }
        // Alipay payment has taken too long - there is a risk the reservation has expired
        // AJD: TODO - Error Case 2 - successful payment but has taken too long so reservation may have expired
        console.log(
          "Payment has taken too long so reservation may have expired"
        );
      }
      // AJD: TODO - Should we clear anything from state so we can't try again for the same request?
    } else if (reformattedLocation?.query?.trade_no) {
      // Successful payment but no bookingInProgress data
      // AJD: TODO - Error Case 3 - successful payment but no bookingInProgress data (local state could have been cleared, etc)
      console.log("Successful payment but no bookingInProgress data");
      return getFailureActions(
        ALI_PAY_SUCCESS_ERROR(selectBookingInProgressHotelCode(state))
      );
    } else if (reformattedLocation?.query?.abort) {
      // Failed payment
      console.log("Failed payment");
      return getFailureActions();
      // AJD: TODO - Error Case 4 - the Alipay payment failed
    } else if (state.bookings.alipayPaymentInitialised) {
      return getFailureActions();
    }
  }

  // Not an Alipay case if we reach this point
  return false;
}
