import { useSelector } from "react-redux";

import { useDispatchWithLocale } from "hooks";
import { selectIsRequestLoading } from "store/apiRequestStates";

export default function useDelete({ action, salesForceId }) {
  const dispatchWithLocale = useDispatchWithLocale();

  const onDelete = () =>
    dispatchWithLocale(action({ newValue: { salesForceId } }));

  const showLoadingIndicator = useSelector(selectIsRequestLoading(action.type));

  return {
    onDelete,
    showLoadingIndicator,
  };
}
