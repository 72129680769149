import React from "react";

import { useTranslation } from "hooks";
import { Link } from "components";

function NotSureWhereToTravel({ icon, links = [] }) {
  const { t } = useTranslation();

  return (
    <div className="not-sure-where-to-travel">
      {icon && (
        <div className="not-sure-where-to-travel__icon">
          <img src={icon} alt={t("Not sure where to travel?")} />
        </div>
      )}

      <div className="not-sure-where-to-travel__content_actions_container">
        <div className="not-sure-where-to-travel__content">
          <h3 className="not-sure-where-to-travel__content__title ty-c1">
            {t("Not sure where to travel?")}
          </h3>
          <p className="not-sure-where-to-travel__content__description">
            {t("Let us help you plan your trip with confidence")}
          </p>
        </div>
        <div className="not-sure-where-to-travel__actions">
          {links.map((linkProps) => (
            <Link key={linkProps.href} {...linkProps} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default NotSureWhereToTravel;
