import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { useTranslation } from "hooks";
import validateEmailList from "utils/validateEmailList";

export default function CancellationForm({ onSubmit }) {
  const { t } = useTranslation();

  const { register, handleSubmit, errors, watch, setValue } = useForm({
    defaultValues: {
      cancelSendAdditional: false,
      emailList: "",
    },
  });

  const watchedCancelSendAdditional = watch("cancelSendAdditional");

  useEffect(() => {
    setValue("emailList", "");
  }, [watchedCancelSendAdditional]);

  return (
    <div className="modal-content-body-padding">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="receive-additional">
          <p>
            {t(
              "Would you like to receive an additional email confirmation of your cancellation?"
            )}
          </p>
        </div>

        <div className="cancel-checkbox" ng-hide="data.cancelFailed">
          <label htmlFor="cancelSendAdditional">
            <input
              id="cancelSendAdditional"
              type="checkbox"
              name="cancelSendAdditional"
              ref={register}
            />{" "}
            {t(
              "Send additional cancellation confirmation emails to these email addresses"
            )}
          </label>
        </div>

        <br />

        <label htmlFor="emailList" className="EmailListLabel">
          {t("Separate email addresses with commas.")}
        </label>

        {watchedCancelSendAdditional ? (
          <input
            name="emailList"
            id="additionalRecipients"
            type="text"
            placeholder={t("Separate email addresses with commas.")}
            ref={register({ validate: validateEmailList })}
          />
        ) : (
          <input
            name="emailList"
            id="additionalRecipients"
            type="text"
            placeholder={t("Separate email addresses with commas.")}
            ref={register({ validate: false })}
            disabled
          />
        )}

        {watchedCancelSendAdditional && errors.emailList && (
          <div className="input-error">
            {t(
              "Please Enter A List Of Valid Email Addresses Separated By Commas."
            )}
          </div>
        )}

        <br />
        <br />

        <button type="submit" className="btn btn--primary">
          {t("Cancel Reservation")}
        </button>
      </form>
    </div>
  );
}
