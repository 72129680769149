import flow from "lodash/fp/flow";
import get from "lodash/fp/get";
import map from "lodash/fp/map";

const getOfferCodesFromHotelResultsResponse = flow(
  get(["hotelOptions", 0, "roomOptions", 0, "roomTypeIds"]),
  map(({ roomTypeId, roomRateIds }) => ({
    roomTypeId,
    offerCodes: roomRateIds.map(get("roomRateId")).map((a) => a.split(".")[1]),
  }))
);

export default getOfferCodesFromHotelResultsResponse;
