import React from "react";

import { useTranslation } from "hooks";
import ThemeContextProvider, { THEMES } from "contexts/ThemeContext";
import { getCardDisplayStatus } from "Profile/utils/membershipCardStatus";
import MembershipCard from "../components/MembershipCard";

function MemberDetails({ memberDetails }) {
  const { t } = useTranslation();
  const cardsToDisplay = memberDetails.Memberships.filter((membership) => {
    return getCardDisplayStatus(membership);
  });
  if (cardsToDisplay.length <= 0) {
    return null;
  }

  return (
    <ThemeContextProvider theme={THEMES.DARK}>
      <div id="your-memberships" className="view--profile__member-details">
        <div className="container">
          <div className="fs-member-details">
            <div className="fs-member-details__copy">
              <div className="fs-member-details__title">
                <h2>{t("Your Memberships")}</h2>
              </div>
              <div className="fs-member-details__body">
                {cardsToDisplay.map((membership) => (
                  <MembershipCard
                    membership={membership}
                    key={`${membership?.membershipId}/${membership?.membershipTierId}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeContextProvider>
  );
}

export default MemberDetails;
