import React from "react";

import { useTranslation } from "hooks";
import { Button } from "Profile/components";
import Modal from "BookingFlow/components/Modal";

function SelectNewDatesWarningModal({ selectNewDates, closeModal }) {
  const { t } = useTranslation();

  return (
    <Modal className="select-new-dates-warning-modal" closeModal={closeModal}>
      <div className="select-new-dates-warning-modal__content">
        <div className="warning-icon" />

        <p
          dangerouslySetInnerHTML={{
            __html: t(
              "We only allow multi-room bookings for the same dates.<br> Your choices will be refreshed and will take you back to “Choose your Room 1” if you change the dates at this point."
            ),
          }}
        />

        <div className="select-new-dates-warning-modal__actions">
          <Button onClick={selectNewDates}>{t("Select new dates")}</Button>
          <Button onClick={closeModal} kind="secondary">
            {t("Cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default SelectNewDatesWarningModal;
