import { ajax } from "rxjs/ajax";

import env from "config/env";
import { baseDeleteRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

const removeAllItems$ = ({ bookingId, locale }) =>
  ajax({
    ...baseDeleteRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/bookings/${bookingId}`,
  });

export default removeAllItems$;
