import React from "react";
import classNames from "classnames";
import { parse } from "date-fns";

import { useTranslation } from "hooks";
import { formatDate } from "utils/utils";
import { STEP_2 as DATE_FORMATS } from "utils/dateFormats";

function CancellationDate({ className, cancellationBy }) {
  const { t, locale } = useTranslation();

  const cancellationByDate =
    typeof cancellationBy === "string"
      ? parse(cancellationBy.split("T")[0], "yyyy-MM-dd", new Date())
      : cancellationBy;

  return (
    <div className={classNames("cancellation-date", className)}>
      {t("Free Cancellation until {0}", [
        formatDate(cancellationByDate, DATE_FORMATS.OFFER_CANCELLATION, locale),
      ])}
    </div>
  );
}

export default CancellationDate;
