import get from "lodash/get";

import { API_REQUEST_STATES } from "store/apiRequestStates";

export const completedRequestStates = [
  API_REQUEST_STATES.FULFILLED,
  API_REQUEST_STATES.FAILED,
];

export function selectIsRequestComplete(requestStatePath) {
  return (state) =>
    completedRequestStates.includes(get(state, requestStatePath));
}

export function selectAreAllRequestsComplete(requestStatePaths = []) {
  return (state) =>
    requestStatePaths.every((requestStatePath) =>
      selectIsRequestComplete(requestStatePath)(state)
    );
}

export function selectIsRequestLoading(requestStatePath) {
  return (state) => get(state, requestStatePath) === API_REQUEST_STATES.REQUEST;
}

export function selectAreAllRequestsLoading(requestStatePaths = []) {
  return (state) =>
    requestStatePaths.every((requestStatePath) =>
      selectIsRequestLoading(requestStatePath)(state)
    );
}
