import React from "react";

import { updateUserPhone, selectPhone } from "store/profile";
import { NEW_PHONE_NUMBER_ID } from "fixtures/constants";
import PhoneNumberForm from "./PhoneNumberForm";
import useFormSubmit from "../../../hooks/useFormSubmit";

export default function AddEmail({ closeForm }) {
  const {
    onSubmit,
    register,
    showLoadingIndicator,
    value,
    formState,
    watched,
    errors,
    baseError,
    control,
  } = useFormSubmit({
    closeForm,
    action: updateUserPhone,
    selector: selectPhone(NEW_PHONE_NUMBER_ID),
    fieldName: "phoneData",
  });

  return (
    <PhoneNumberForm
      identifier="new-phone-address"
      phoneNumber={value}
      watched={watched}
      isSave
      onSubmit={onSubmit}
      formState={formState}
      register={register}
      errors={errors}
      baseError={baseError}
      control={control}
      showLoadingIndicator={showLoadingIndicator}
    />
  );
}
