import React from "react";
import get from "lodash/get";
import { format } from "date-fns";

import { useTranslation } from "hooks";
import YourBookingRoomCard from "./YourBookingRoomCard";
import CancellationTerms from "../CancellationMessage/CancellationTerms";

export default function YourBooking({
  completedBooking,
  dictionaryKeys,
  cancellation,
  control,
  property,
  register,
  errors,
  rooms,
  isBookingCancelled,
  openCancellationModal,
  isPreferredPartners,
}) {
  const { t, getDateTimeFormat } = useTranslation();

  const isMultiRoomBooking = rooms.length > 1;

  return (
    <div className="your-booking container">
      <h2>{isPreferredPartners ? t("Reservation") : t("Your Booking")}</h2>
      {isMultiRoomBooking && !isBookingCancelled && (
        <div className="mobile-multiple-cancel">
          {cancellation.allowed && (
            <div className="cancel-reservation">
              <button
                type="button"
                className="btn-link cancel"
                onClick={openCancellationModal}
                aria-haspopup="dialog"
              >
                {t("Cancel this reservation")}
              </button>
              {cancellation.by && (
                <p>
                  {t("Cancellation required by")}{" "}
                  {format(cancellation.by, getDateTimeFormat())}
                </p>
              )}
            </div>
          )}

          {!cancellation.allowed && (
            <CancellationTerms dictionaryKeys={dictionaryKeys} />
          )}
        </div>
      )}

      {completedBooking.hotelProducts.map((hotelProduct, roomIndex) => (
        <YourBookingRoomCard
          key={roomIndex}
          roomIndex={roomIndex}
          reservationId={hotelProduct.reservationId}
          cancelRef={hotelProduct.cancelRef}
          dictionaryKeys={dictionaryKeys}
          register={register}
          control={control}
          errors={get(errors, ["comments", roomIndex, "roomGuests"], [])}
          isMultiRoomBooking={rooms.length > 1}
          isBookingCancelled={isBookingCancelled}
          cancellation={cancellation}
          openCancellationModal={openCancellationModal}
          property={property}
          {...rooms[roomIndex]}
        />
      ))}
    </div>
  );
}
