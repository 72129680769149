import React from "react";

import { selectAddress, updateUserAddress } from "store/profile";
import { NEW_ADDRESS_ID } from "fixtures/constants";
import AddressForm from "./AddressForm";
import useFormSubmit from "../../../hooks/useFormSubmit";

export default function AddAddress({ closeForm }) {
  const {
    onSubmit,
    register,
    showLoadingIndicator,
    value,
    formState,
    watched,
    errors,
    baseError,
  } = useFormSubmit({
    closeForm,
    action: updateUserAddress,
    selector: selectAddress(NEW_ADDRESS_ID),
    fieldName: "addressData",
  });

  return (
    <AddressForm
      identifier="new-address"
      address={value}
      isSave
      watched={watched}
      onSubmit={onSubmit}
      formState={formState}
      register={register}
      errors={errors}
      baseError={baseError}
      showLoadingIndicator={showLoadingIndicator}
    />
  );
}
