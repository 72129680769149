import { languagesRouteParam, getUrlLocale } from "config/languages";

export const profilePath = {
  path: `${languagesRouteParam}/profile`,
  to: ({ locale = "en" } = {}) => `/${getUrlLocale(locale)}profile`,
  getPageTitle: () => "Your Profile",
};

export const editProfilePath = {
  path: `${languagesRouteParam}/profile/edit`,
  to: ({ locale = "en" } = {}) => `/${getUrlLocale(locale)}profile/edit`,
  getPageTitle: () => "Your Profile",
};

export const employeeProfilePath = {
  path: `${languagesRouteParam}/profile/employee`,
  to: ({ locale = "en" } = {}) => `/${getUrlLocale(locale)}profile/employee`,
  getPageTitle: () => "Your Profile",
};

export const employeeStayHistoryPath = {
  path: `${languagesRouteParam}/profile/employee-stay-history`,
  to: ({ locale = "en" } = {}) =>
    `/${getUrlLocale(locale)}profile/employee-stay-history`,
  getPageTitle: () => "Stay History",
};

export const profileBookingPath = {
  path: `${languagesRouteParam}/profile/upcoming-trip/:hotelCode/:reservationId`,
  to: ({ hotelCode, reservationId, locale = "en" }) =>
    `/${getUrlLocale(
      locale
    )}profile/upcoming-trip/${hotelCode}/${reservationId}`,
  getPageTitle: () => "Your Upcoming Trip",
};

export const profileBookingItineraryPath = {
  path: `${languagesRouteParam}/profile/upcoming-trip/:hotelCode/:reservationId/itinerary`,
  to: ({ hotelCode, reservationId, locale = "en" }) =>
    `/${getUrlLocale(
      locale
    )}profile/upcoming-trip/${hotelCode}/${reservationId}/itinerary`,
  getPageTitle: () => "Your Itinerary",
};

export const profileStayPreferencesPath = {
  path: `${languagesRouteParam}/profile/stay-preferences`,
  to: ({ locale = "en" } = {}) =>
    `/${getUrlLocale(locale)}profile/stay-preferences`,
  getPageTitle: () => "Stay Preferences",
};

export const profileYourPreferencesPath = {
  path: `${languagesRouteParam}/profile/your-preferences`,
  to: ({ locale = "en" } = {}) =>
    `/${getUrlLocale(locale)}profile/your-preferences`,
  getPageTitle: () => "Your Preferences",
};

export const profileYourInterestsPath = {
  path: `${languagesRouteParam}/profile/your-interests`,
  to: ({ locale = "en" } = {}) =>
    `/${getUrlLocale(locale)}profile/your-interests`,
  getPageTitle: () => "Your Interests",
};

export const profileEmailSubscriptionsPath = {
  path: `${languagesRouteParam}/profile/edit/email-subscriptions`,
  to: ({ locale = "en" } = {}) =>
    `/${getUrlLocale(locale)}profile/edit/email-subscriptions`,
  getPageTitle: () => "Your Profile",
};

export const profileMembershipPath = {
  path: `${languagesRouteParam}/profile/memberships/:hotelCode/:membershipId/:tierId`,
  to: ({ hotelCode, membershipId, tierId, locale = "en" }) =>
    `/${getUrlLocale(
      locale
    )}profile/memberships/${hotelCode}/${membershipId}/${tierId}`,
  getPageTitle: () => "Your Membership",
};
