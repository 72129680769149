import { combineEpics, ofType } from "redux-observable";
import { switchMap, takeUntil, catchError, mergeMap } from "rxjs/operators";
import { of } from "rxjs";

import { getPreferredPartnerDetails$ } from "api/preferredpartner";
import {
  fetchPreferredPartnerDetails,
  fetchPreferredPartnerDetailsCancel,
} from "./preferredPartnerDetails.slice";

export const fetchPreferredPartnerDetails$ = ({ searchParams }) => {
  return getPreferredPartnerDetails$({ searchParams }).pipe(
    mergeMap((response = {}) => of(response)),

    catchError(() => {
      return of({});
    })
  );
};

const preferredPartnerDetailsEpic = (action$) =>
  action$.pipe(
    ofType(fetchPreferredPartnerDetails.type),
    switchMap(({ payload: { searchParams } }) =>
      fetchPreferredPartnerDetails$({ searchParams }).pipe(
        takeUntil(action$.pipe(ofType(fetchPreferredPartnerDetailsCancel.type)))
      )
    )
  );

export default combineEpics(preferredPartnerDetailsEpic);
