/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  error: "",
};

const propertiesSlice = createSlice({
  name: "properties",
  initialState,
  reducers: {
    fetchProperties(state) {
      state.error = "";
    },

    fetchPropertiesFulfilled(state, { payload }) {
      state.data = payload;
      state.error = "";
    },

    fetchPropertiesFailed(state) {
      state.data = [];
      state.error = "error";
    },

    fetchPropertiesCancel() {},

    propertiesReset() {
      return initialState;
    },
  },
});

export const {
  fetchProperties,
  fetchPropertiesFulfilled,
  fetchPropertiesFailed,
  fetchPropertiesCancel,
  propertiesReset,
} = propertiesSlice.actions;

export default propertiesSlice.reducer;
