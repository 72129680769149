// NOTE:
// use `locale` for i18next.changeLanguage()
const languages = [
  {
    locale: "en",
    name: "English",
    isoCode: "en-US",
    ojCode: "E",
    dateFnsLocale: "enUS",
    recaptcha: "en",
  },
  {
    locale: "fr",
    name: "French",
    isoCode: "fr-FR",
    ojCode: "F",
    dateFnsLocale: "fr",
    recaptcha: "fr",
  },
  {
    locale: "de",
    name: "German",
    isoCode: "de-DE",
    ojCode: "D",
    dateFnsLocale: "de",
    recaptcha: "de",
  },
  {
    locale: "es",
    name: "Spanish (Latin American)",
    isoCode: "es-ES",
    ojCode: "S",
    dateFnsLocale: "es",
    recaptcha: "es-419",
  },
  {
    locale: "es-es",
    name: "Spanish (Castilian)",
    isoCode: "es-ES",
    ojCode: "S",
    dateFnsLocale: "es",
    recaptcha: "es",
  },
  {
    locale: "zh",
    name: "Chinese",
    isoCode: "zh-CN",
    ojCode: "CH",
    dateFnsLocale: "zhCN",
    recaptcha: "zh-CN",
  },
  {
    locale: "ar",
    name: "Arabic",
    isoCode: "ar-SA",
    ojCode: "AR",
    dateFnsLocale: "arSA",
    recaptcha: "ar",
  },
  {
    locale: "jp",
    name: "Japanese",
    isoCode: "ja-JP",
    ojCode: "J",
    dateFnsLocale: "ja",
    recaptcha: "ja",
  },
  {
    locale: "ru",
    name: "Russian",
    isoCode: "ru-RU",
    ojCode: "R",
    dateFnsLocale: "ru",
    recaptcha: "ru",
  },
  {
    locale: "zh_hant",
    name: "Traditional Chinese",
    isoCode: "zh-TW",
    ojCode: "TW",
    dateFnsLocale: "zhTW",
    recaptcha: "zh-TW",
  },
  {
    locale: "br",
    name: "Brazillian Portuguese",
    isoCode: "pt-BR",
    ojCode: "BR",
    dateFnsLocale: "ptBR",
    recaptcha: "pt-BR",
  },
  {
    locale: "pt",
    name: "Portuguese",
    isoCode: "pt-PT",
    ojCode: "P",
    dateFnsLocale: "pt",
    recaptcha: "pt-PT",
  },
  {
    locale: "it",
    name: "Italian",
    isoCode: "it-IT",
    ojCode: "I",
    dateFnsLocale: "it",
    recaptcha: "it",
  },
  {
    locale: "kr",
    name: "Korean",
    isoCode: "ko-KR",
    ojCode: "KO",
    dateFnsLocale: "ko",
    recaptcha: "ko",
  },
  {
    locale: "tr",
    name: "Turkish",
    isoCode: "tr-TR",
    ojCode: "TR",
    dateFnsLocale: "tr",
    recaptcha: "tr",
  },
  {
    locale: "gr",
    name: "Greek",
    isoCode: "el-GR",
    ojCode: "GK",
    dateFnsLocale: "el",
    recaptcha: "el",
  },
];

export const languagesForRegex = languages
  .map((l) => l.locale)
  .sort((a, b) => b.length - a.length)
  .join("|");
export const languagesRegex = new RegExp(`^/(${languagesForRegex})/`);
export const languagesRouteParam = `/:locale(${languagesForRegex})?`;

export default languages;

export function getIsoCodeForLocale(locale) {
  return languages.find((l) => l.locale === locale)?.isoCode;
}

export function getDateFnsLocale(locale) {
  return languages.find((l) => l.locale === locale)?.dateFnsLocale;
}

export function getRecaptchaLocale(locale) {
  return languages.find((l) => l.locale === locale)?.recaptcha;
}

export function getUrlLocale(locale) {
  return locale === "en" ? "" : `${locale}/`;
}

export function getPathnameWithoutLocale(pathname) {
  const replaceRexEx = new RegExp(`^/(${languagesForRegex})`);
  return pathname.replace(replaceRexEx, "");
}

export function getLocaleFromPathname(pathname) {
  const matches = pathname.match(languagesRegex) || [];
  return matches[1] || "en";
}
