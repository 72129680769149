import queryString from "query-string";

function getQueryStringFromSearchFormValues(searchFormValues) {
  const { hotelCode, rooms, dates, promoCode, flexDatesWindow, ...formValues } =
    searchFormValues;

  const order = [
    "hotelCode",
    "checkIn",
    "checkOut",
    "adults",
    "children",
    "promoCode",
    "flexDatesWindow",
  ];

  return queryString.stringify(
    {
      ...formValues,
      hotelCode,
      checkIn: dates.checkIn,
      checkOut: dates.checkOut,
      adults: rooms.map((room) => room.adults),
      children: rooms.map((room) => room.children),
      promoCode,
      flexDatesWindow: flexDatesWindow || undefined,
    },
    {
      sort: (a, b) => order.indexOf(a) - order.indexOf(b),
    }
  );
}

export default getQueryStringFromSearchFormValues;
