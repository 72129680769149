import { ajax } from "rxjs/ajax";

import env from "config/env";
import { basePostRequest } from "../tretail/baseRequest";

const { PREFERENCE_REQUEST_URL } = env;

export default function subscribeToAllEmails$({ bookingId }) {
  return ajax({
    ...basePostRequest(),
    url: `${PREFERENCE_REQUEST_URL}/updateGlobalSubscribe`,
    body: {
      all: true,
      bookingId,
    },
  });
}
