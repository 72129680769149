import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { useTranslation, usePrevious } from "hooks";
import getInitialArrivalTime from "utils/getInitialArrivalTime";
import Button from "../components/Button";
import ArrivalTimeSelects from "../components/ArrivalTimeSelects";

export default function TripDetailsForm({
  close,
  upcomingTrip,
  propertyContent,
  onSubmit,
  showSavingSpinner,
}) {
  const { t } = useTranslation();

  const prevShowSavingSpinner = usePrevious(showSavingSpinner);

  const { handleSubmit, control } = useForm({
    mode: "onChange",
    defaultValues: {
      arrivalTime: getInitialArrivalTime(propertyContent)(upcomingTrip),
    },
  });

  useEffect(() => {
    if (prevShowSavingSpinner && !showSavingSpinner) {
      close();
    }
  }, [showSavingSpinner]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-md-6">
          <p className="fs-trip__details__checkin">
            {t(
              "For a perfectly-timed welcome at the hotel, let us know when to expect you."
            )}
          </p>
        </div>
        <div className="col-md-6">
          <Controller
            name="arrivalTime"
            control={control}
            as={ArrivalTimeSelects}
            disabled={false}
            rules={{ required: true }}
          />
          <div className="fs-trip__details__checkin-action">
            <Button type="submit">{t("Update")}</Button>
          </div>
        </div>
      </div>
    </form>
  );
}
