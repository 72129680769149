import React from "react";
import { Controller } from "react-hook-form";
import { parseISO, isValid } from "date-fns";

import {
  Dropdown,
  TextArea,
  TextInput,
  QuantityInput,
  RadioButtons,
} from "Profile/components";

export const INPUT_TYPES = {
  CARD_EXPIRY_DATE: "CARD_EXPIRY_DATE",
  CARD_NUMBER: "CARD_NUMBER",
  DATE: "DATE",
  DROPDOWN: "DROPDOWN",
  EMAIL: "EMAIL",
  NUMBER: "NUMBER",
  PHONE: "PHONE",
  QUANTITY: "QUANTITY",
  RADIO: "RADIO",
  TEXT: "TEXT",
  TEXTAREA: "TEXTAREA",
  TIME: "TIME",
};

function getValidations({ type, required = false, min }) {
  const validations = {
    required: required
      ? {
          value: true,
          message: "Please enter a value for this field",
        }
      : false,
  };

  if (min !== undefined) {
    validations.min = {
      value: min,
      message: `Please enter a value greater ${min}`,
    };
  }

  if (type === INPUT_TYPES.NUMBER || type === INPUT_TYPES.QUANTITY) {
    validations.validate = validations.validate || {};
    validations.validate.isNumber = (value) => {
      return (
        (value.length === 0 && !required) ||
        parseInt(value, 10) >= 0 ||
        "Please enter a number"
      );
    };
  }

  if (type === INPUT_TYPES.DATE) {
    validations.validate = validations.validate || {};
    validations.validate.isValid = (value) => {
      return (
        (value.length === 0 && !required) ||
        isValid(parseISO(value)) ||
        "Please enter a valid date"
      );
    };
  }

  if (type === INPUT_TYPES.TIME) {
    validations.validate = validations.validate || {};
    validations.validate.isValid = (value) => {
      return (
        (value.replace(/:00.000Z$/, "").length === 0 && !required) ||
        isValid(parseISO(`2050-01-01T${value}`)) ||
        "Please enter a valid time"
      );
    };
  }

  return validations;
}

export default function FieldSelector({
  type = INPUT_TYPES.TEXT,
  register,
  control,
  additionalNames: _ignoredAdditionalNames,
  ...props
}) {
  const validations = getValidations({ type, ...props });

  switch (type) {
    case INPUT_TYPES.DROPDOWN:
      return (
        <Dropdown includeBlank {...props} register={register(validations)} />
      );

    case INPUT_TYPES.TEXTAREA:
      return <TextArea {...props} register={register(validations)} />;

    case INPUT_TYPES.PHONE:
      return (
        <TextInput type="tel" {...props} register={register(validations)} />
      );

    case INPUT_TYPES.QUANTITY:
      return (
        <Controller
          control={control}
          name={props.name}
          rules={validations}
          render={({ ref: _ignoredRef, name, value, onChange, onBlur }) => {
            return (
              <QuantityInput
                name={name}
                value={parseInt(value, 10)}
                onChange={(evt) => {
                  onChange(evt.target.value);
                }}
                onBlur={onBlur}
              />
            );
          }}
        />
      );

    case INPUT_TYPES.TIME:
      return (
        <Controller
          control={control}
          name={props.name}
          rules={validations}
          defaultValue=""
          render={({
            ref: _ignoredRef,
            name,
            value = "",
            onChange,
            onBlur,
          }) => {
            return (
              <TextInput
                name={name}
                type={type.toLowerCase()}
                value={value.replace(/:00.000Z$/, "")}
                onChange={(evt) => {
                  onChange(`${evt.target.value}:00.000Z`);
                }}
                onBlur={onBlur}
                {...props}
                forceLabel
              />
            );
          }}
        />
      );

    case INPUT_TYPES.DATE:
      return (
        <TextInput
          type={type.toLowerCase()}
          {...props}
          register={register(validations)}
          forceLabel
        />
      );

    case INPUT_TYPES.RADIO:
      return (
        <Controller
          control={control}
          name={props.name}
          rules={validations}
          render={({ ref: _ignoredRef, name, value, onChange, onBlur }) => {
            return (
              <RadioButtons
                flexDirection="row"
                radioIsButton
                type={type.toLowerCase()}
                register={register(validations)}
                name={name}
                value={value}
                onChange={(evt) => {
                  onChange(evt.target.value);
                }}
                onBlur={onBlur}
                {...props}
              />
            );
          }}
        />
      );

    default:
      return (
        <TextInput
          type={type.toLowerCase()}
          {...props}
          register={register(validations)}
        />
      );
  }
}
