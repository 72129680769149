import { ajax } from "rxjs/ajax";

import env from "config/env";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

export default function login$({ email, password, locale }) {
  return ajax({
    ...basePostRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/authentication`,
    body: {
      loginType: "EMAIL_ADDRESS",
      password,
      user: email,
    },
  });
}
