import { stringifyObject } from "utils/utils";

export const selectTermsAndConditionsForCurrentBooking =
  ({ currentBooking: { hotelProducts = [] } = {}, locale }) =>
  (state) =>
    hotelProducts.map(
      ({
        hotelCode,
        checkInDate,
        checkOutDate,
        roomTypes: [{ roomTypeId }],
        roomRate: { ratePlanCode },
      }) => {
        return state.termsAndConditions?.data[hotelCode]
          ? state.termsAndConditions?.data[hotelCode][
              stringifyObject({
                checkinDate: checkInDate,
                checkoutDate: checkOutDate,
                locale,
                offerCode: ratePlanCode,
                roomCode: roomTypeId,
              })
            ]
          : [];
      }
    );

export const selectTermsAndConditionsContent =
  ({ hotelCode, options = {} }) =>
  (state) =>
    state.termsAndConditions?.data[hotelCode] &&
    state.termsAndConditions?.data[hotelCode][stringifyObject(options)];
