const onlyUnique = (value, index, self) =>
  self.map(JSON.stringify).indexOf(JSON.stringify(value)) === index;

export default function getTermsAndConditionsOptionsFromBooking({
  booking: { hotelProducts },
}) {
  return hotelProducts
    .map(
      ({
        checkInDate,
        checkOutDate,
        roomTypes: [{ roomTypeId }],
        roomRate: { ratePlanCode },
      }) => ({
        checkinDate: checkInDate,
        checkoutDate: checkOutDate,
        offerCode: ratePlanCode,
        roomCode: roomTypeId,
      })
    )
    .filter(onlyUnique);
}
