import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    const {
      state: { hasError },
      props: { fallbackUI, children },
    } = this;

    if (hasError) {
      // You can render any custom fallback UI
      return fallbackUI(hasError);
    }

    return children;
  }
}
