import React, { useState } from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";

function BookingMessage({ title, body, initiallyOpen = true }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(initiallyOpen);

  return (
    <li className="booking-message">
      <button
        type="button"
        className="message-title"
        onClick={() => setIsOpen(!isOpen)}
      >
        <strong>{title}</strong>
        <span
          className={classNames("icon", {
            "icon-arrow-up": isOpen,
            "icon-arrow-down": !isOpen,
          })}
        />

        <span
          className={classNames("booking-message__icon", {
            "booking-message__icon--arrow-up": isOpen,
            "booking-message__icon--arrow-down": !isOpen,
          })}
        >
          {isOpen ? t("hide") : t("show")}
        </span>
      </button>
      {isOpen && (
        <div
          className="message-body"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      )}
    </li>
  );
}

export default BookingMessage;
