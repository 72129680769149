import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs";
import { map, switchMap, takeUntil, catchError } from "rxjs/operators";

import { getGlobalSettings$ } from "api/content";
import {
  fetchGlobalSettings,
  fetchGlobalSettingsFulfilled,
  fetchGlobalSettingsFailed,
  fetchGlobalSettingsCancel,
} from "./globalSettings.slice";

const fetchGlobalSettingsEpic = (action$) =>
  action$.pipe(
    ofType(fetchGlobalSettings.type),
    switchMap(({ payload: { locale } }) =>
      getGlobalSettings$({ locale }).pipe(
        map(fetchGlobalSettingsFulfilled),
        catchError((error) => of(fetchGlobalSettingsFailed(error))),
        takeUntil(action$.pipe(ofType(fetchGlobalSettingsCancel.type)))
      )
    )
  );

export default combineEpics(fetchGlobalSettingsEpic);
