import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setView } from "store/app";

export default function useView(viewName) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setView(viewName));
    return () => {
      dispatch(setView(""));
    };
  }, []);
}
