import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useDispatchWithLocale } from "hooks";
import {
  selectProperties,
  selectIsLoadingProperties,
  selectHasRequestedProperties,
  fetchProperties,
  fetchPropertiesCancel,
} from "store/properties";

export default function useProperties() {
  const dispatchWithLocale = useDispatchWithLocale();

  const properties = useSelector(selectProperties);
  const isLoading = useSelector(selectIsLoadingProperties);
  const hasRequested = useSelector(selectHasRequestedProperties);

  useEffect(() => {
    if (!hasRequested && !isLoading && properties.length === 0) {
      dispatchWithLocale(fetchProperties());
    }
    return () => {
      if (isLoading) {
        dispatchWithLocale(fetchPropertiesCancel());
      }
    };
  }, [isLoading, hasRequested, properties.length]);

  return {
    properties,
    isLoading,
  };
}
