import { ofType } from "redux-observable";
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
} from "rxjs/operators";
import { iif, of } from "rxjs";
import { replace } from "connected-react-router";
import { matchPath } from "react-router";

import * as bookingFlowRoutes from "BookingFlow/bookingFlowRoutes";
import { getAppStatus$, getGlobalSettings$ } from "api/content";
import { getThrottle$ } from "api/throttle";
import { fetchProfileWithBookingHistory$ } from "store/profile/epics/fetchProfile";
import { fetchProfileFulfilled } from "store/profile/profile.slice";
import {
  fetchBookingHistoryFulfilled,
  clearBookingHistory,
} from "store/bookingHistory";
import { fetchAppStatusFulfilled } from "store/appStatus/appStatus.slice";
import mapQueryParams from "utils/mapQueryParams";
import getShouldThrottle from "utils/getShouldThrottle";
import getThrottleRedirect from "utils/getThrottleRedirect";
import {
  hasSearchQueryParams,
  getSearchFormValuesFromQueryString,
} from "BookingFlow/utils";
import catchInternalServerError from "store/catchInternalServerError";
import canDeepLinkToConfirmYourStay from "BookingFlow/ConfirmYourStay/canDeepLinkToConfirmYourStay";
import canDeepLinkToConfirmYourStayByBookingId from "BookingFlow/ConfirmYourStay/canDeepLinkToConfirmYourStayByBookingId";
import canDeepLinkToConfirmYourStayByResultSet from "BookingFlow/ConfirmYourStay/canDeepLinkToConfirmYourStayByResultSet";
import {
  resetBookings,
  clearDeepLinkErrors,
  constructBooking,
  constructBookingByBookingId,
  constructBookingByResultSet,
} from "store/bookings";
import { convertBookingCodesToArrays } from "utils/utils";
import { fetchGlobalSettingsFulfilled } from "store/globalSettings";
import { clearEmployeeProfile } from "store/employeeProfile/employeeProfile.slice";
import { memberDetailsReset } from "store/memberDetails/memberDetails.slice";
import { fetchMemberDetailsFulfilled } from "store/memberDetails";
import { resetRegistration } from "store/registration/registration.slice";
import { fetchAllPropertyContent } from "store/allPropertyContent";
import alipayCheck from "./alipayCheck";
import {
  appStart,
  appStartCancel,
  appStartFailed,
  appStartFulfilled,
  setAvailCheckTemplate,
  setRoomFindingMethod,
} from "../app.slice";

export default function appStartEpic(action$, state$) {
  return action$.pipe(
    ofType(appStart.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { locale },
        },
        state,
      ]) => {
        return getGlobalSettings$({ locale }).pipe(
          switchMap((globalSettingsResponse) => {
            const shouldThrottle = getShouldThrottle({
              globalSettings: globalSettingsResponse,
              state,
            });
            return iif(
              () => shouldThrottle,
              getThrottle$({ route: state.router.location.pathname }),
              of(undefined)
            ).pipe(
              switchMap((throttleStatus) => {
                const throttleRedirect = shouldThrottle
                  ? getThrottleRedirect({
                      throttleStatus,
                      state,
                    })
                  : false;
                if (throttleRedirect) {
                  window.location.href = throttleRedirect;
                  return [appStartFailed()];
                }
                return getAppStatus$({ locale }).pipe(
                  switchMap((appStatus) => {
                    const {
                      shouldRedirect,
                      reformatedLocation,
                      completeParams,
                    } = mapQueryParams(state.router.location);

                    return fetchProfileWithBookingHistory$({ locale }).pipe(
                      catchError((error) => {
                        if (
                          error?.response?.message === "Internal Server Error"
                        ) {
                          throw error;
                        }
                        return of({
                          logoutActions: [
                            clearBookingHistory(),
                            clearEmployeeProfile(),
                            memberDetailsReset(),
                            resetRegistration(),
                          ],
                        });
                      }),
                      map(
                        ({
                          profile,
                          bookingHistory,
                          memberDetails,
                          logoutActions = [],
                        }) => {
                          return [
                            ...logoutActions,
                            fetchAppStatusFulfilled(appStatus),
                            fetchGlobalSettingsFulfilled(
                              globalSettingsResponse
                            ),
                            memberDetails &&
                              fetchMemberDetailsFulfilled(memberDetails),
                            bookingHistory &&
                              fetchBookingHistoryFulfilled(bookingHistory),
                            profile && fetchProfileFulfilled(profile),
                            setAvailCheckTemplate({
                              availCheckTemplate:
                                completeParams.availCheckTemplate,
                            }),
                            setRoomFindingMethod({
                              roomFindingMethod:
                                completeParams.roomFindingMethod,
                            }),
                            appStartFulfilled(),
                          ].filter(Boolean);
                        }
                      ),

                      mergeMap((actions) => {
                        const areAllNecessarySearchParamsAvailable =
                          hasSearchQueryParams(reformatedLocation.search);

                        const canConstructBooking =
                          canDeepLinkToConfirmYourStay(
                            reformatedLocation.search
                          );

                        if (canConstructBooking) {
                          return [
                            resetBookings(),
                            clearDeepLinkErrors(),
                            setAvailCheckTemplate({
                              availCheckTemplate:
                                completeParams.availCheckTemplate,
                            }),
                            setRoomFindingMethod({
                              roomFindingMethod:
                                completeParams.roomFindingMethod,
                            }),
                            constructBooking({
                              searchParams: convertBookingCodesToArrays(
                                getSearchFormValuesFromQueryString(
                                  reformatedLocation.search,
                                  new Date()
                                )
                              ),
                              actions,
                              location: reformatedLocation,
                              locale,
                            }),
                          ];
                        }

                        const canConstructBookingByResultSet =
                          canDeepLinkToConfirmYourStayByResultSet(
                            reformatedLocation.search
                          );

                        if (canConstructBookingByResultSet) {
                          const searchParams = convertBookingCodesToArrays(
                            getSearchFormValuesFromQueryString(
                              reformatedLocation.search,
                              new Date()
                            )
                          );
                          return [
                            resetBookings(),
                            clearDeepLinkErrors(),
                            setAvailCheckTemplate({
                              availCheckTemplate:
                                completeParams.availCheckTemplate,
                            }),
                            setRoomFindingMethod({
                              roomFindingMethod:
                                completeParams.roomFindingMethod,
                            }),
                            fetchAllPropertyContent({
                              locale,
                              propertyCode: searchParams.hotelCode,
                            }),
                            constructBookingByResultSet({
                              searchParams,
                              actions,
                              location: reformatedLocation,
                              locale,
                            }),
                          ];
                        }

                        const canConstructBookingByBookingId =
                          canDeepLinkToConfirmYourStayByBookingId(
                            reformatedLocation.search
                          );

                        if (canConstructBookingByBookingId) {
                          const searchParams = convertBookingCodesToArrays(
                            getSearchFormValuesFromQueryString(
                              reformatedLocation.search,
                              new Date()
                            )
                          );
                          return [
                            resetBookings(),
                            clearDeepLinkErrors(),
                            setAvailCheckTemplate({
                              availCheckTemplate:
                                completeParams.availCheckTemplate,
                            }),
                            setRoomFindingMethod({
                              roomFindingMethod:
                                completeParams.roomFindingMethod,
                            }),
                            fetchAllPropertyContent({
                              locale,
                              propertyCode: searchParams.hotelCode,
                            }),
                            constructBookingByBookingId({
                              searchParams,
                              actions,
                              location: reformatedLocation,
                              locale,
                            }),
                          ];
                        }

                        // Check for an Alipay payment
                        const isAlipay = alipayCheck(
                          reformatedLocation,
                          state,
                          actions
                        );
                        if (isAlipay) {
                          return isAlipay;
                        }

                        if (
                          areAllNecessarySearchParamsAvailable &&
                          !matchPath(
                            state.router.location.pathname,
                            bookingFlowRoutes.chooseYourRoom.path
                          )
                        ) {
                          return [
                            ...actions,
                            replace({
                              ...reformatedLocation,
                              pathname: bookingFlowRoutes.chooseYourRoom.to({
                                locale,
                              }),
                              analyticsData: {
                                availCheckTemplate:
                                  completeParams.availCheckTemplate,
                                roomFindingMethod:
                                  completeParams.roomFindingMethod,
                              },
                            }),
                          ];
                        }

                        return [
                          ...actions,
                          shouldRedirect && replace(reformatedLocation),
                        ].filter(Boolean);
                      })
                    );
                  })
                );
              })
            );
          }),

          catchInternalServerError(),

          catchError((error) => {
            return [
              fetchAppStatusFulfilled(
                error?.response || { message: "Internal Server Error" }
              ),
              appStartFailed(),
            ];
          }),

          takeUntil(action$.pipe(ofType(appStartCancel.type)))
        );
      }
    )
  );
}
