import { combineEpics } from "redux-observable";

import activateEmployeeProfileEpic from "./epics/activateEmployeeProfile";
import createProfileEpic from "./epics/createProfile";
import fetchProfileEpic from "./epics/fetchProfile";
import logoutEpic from "./epics/logout";
import toggleInterestEpic from "./epics/toggleInterest";
import updateRoomPreferenceEpic from "./epics/updateRoomPreference";
import updateSleepPreferencesEpic from "./epics/updateSleepPreferences";
import updateUserProfileEpic from "./epics/updateProfile";
import updateUserTitleEpic from "./epics/updateUserTitle";
import updateUserEmailEpic from "./epics/updateUserEmail";
import deleteUserEmailEpic from "./epics/deleteUserEmail";
import createEmailVerifyCodeEpic from "./epics/createEmailVerifyCode";
import verifyEmailCode from "./epics/verifyEmailCode";
import employeeSpecialRateRequestEpic from "./epics/employeeSpecialRateRequest";
import updateUserPhoneEpic from "./epics/updateUserPhone";
import deleteUserPhoneEpic from "./epics/deleteUserPhone";
import updateUserAddressEpic from "./epics/updateUserAddress";
import deleteUserAddressEpic from "./epics/deleteUserAddress";
import updateCountryAndLanguageEpic from "./epics/updateCountryAndLanguage";
import updateEmailSubscriptionsEpic from "./epics/updateEmailSubscriptions";
import checkUpdateEmailValidatedEpic from "./epics/checkUpdateEmailValidated";

export default combineEpics(
  activateEmployeeProfileEpic,
  createEmailVerifyCodeEpic,
  createProfileEpic,
  deleteUserAddressEpic,
  deleteUserEmailEpic,
  deleteUserPhoneEpic,
  employeeSpecialRateRequestEpic,
  fetchProfileEpic,
  checkUpdateEmailValidatedEpic,
  logoutEpic,
  toggleInterestEpic,
  updateCountryAndLanguageEpic,
  updateEmailSubscriptionsEpic,
  updateRoomPreferenceEpic,
  updateSleepPreferencesEpic,
  updateUserAddressEpic,
  updateUserEmailEpic,
  updateUserPhoneEpic,
  updateUserProfileEpic,
  updateUserTitleEpic,
  verifyEmailCode
);
