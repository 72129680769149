import React from "react";
import { format, parseISO } from "date-fns";
import * as dateFnLocales from "date-fns/locale";

import { useTranslation } from "hooks";
import { getDateFnsLocale } from "config/languages";

const formatDate = (locale) => (date) => {
  const f = (formatString) => {
    return format(parseISO(date), formatString, {
      locale: dateFnLocales[getDateFnsLocale(locale)],
    });
  };

  switch (locale) {
    case "zh":
    case "zh_hant":
    case "jp":
      return f("yyyy'年'MM'月'dd'日'");

    case "kr":
      return f("yyyy'년'MM'월'dd'일'");

    default:
      return f("MM/dd/yyyy");
  }
};

function SelectedItinerary({ checkIn, checkOut, onSelectNewDatesClick }) {
  const { t, locale } = useTranslation();

  return (
    <div className="selected-itinerary">
      <div className="ty-h4 selected-itinerary__title">
        {t("Selected Itinerary")}:
      </div>
      <div className="selected-itinerary__dates-container">
        <div className="ty-h4 selected-itinerary__dates">
          {[checkIn, checkOut].map(formatDate(locale)).join(" - ")}
        </div>
        <button
          type="button"
          className="selected-itinerary__new-dates-button"
          onClick={onSelectNewDatesClick}
        >
          {t("Select new dates")}
        </button>
      </div>
    </div>
  );
}

export default SelectedItinerary;
