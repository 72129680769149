import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useCombobox } from "downshift";
import { match, surround } from "fuzzyjs";
import get from "lodash/get";
import partition from "lodash/partition";

import { useTranslation } from "hooks";
import extractTextContent from "utils/extractTextContent";
import formatBeverlyWilshire from "Profile/utils/formatBeverlyWilshire";

export default function Typeahead({
  options: initialOptions = [],
  value,
  onChange,
  identifier = "input-hotel-name",
  required = false,
  requiredIndicator = "*",
  label = "Hotel Name",
  maxOptions = 4,
  error = "",
  className = "",
  uniqueFieldName = "owsCode",
  shortNameField = "shortName",
}) {
  const { t } = useTranslation();
  const validOptions = initialOptions.map((o) => o[shortNameField]);

  const hasError =
    (typeof error === "boolean" && error === true) || error.length > 0;

  const options = initialOptions.map((option) => ({
    ...option,
    displayName: option.name,
    normalizedName:
      option.normalizedName ||
      option[shortNameField]
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, ""),
  }));

  const [inputItems, setInputItems] = useState(options);

  const {
    closeMenu,
    getComboboxProps,
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    isOpen,
    openMenu,
    selectedItem,
  } = useCombobox({
    initialSelectedItem: options.find(
      (option) => option[uniqueFieldName] === value
    ),
    items: inputItems,

    itemToString: (item) => (item ? item[shortNameField] : ""),

    onSelectedItemChange: (changes) => {
      onChange(get(changes, ["selectedItem", uniqueFieldName], ""));
      closeMenu();
    },

    onInputValueChange: ({ inputValue }) => {
      setInputItems(
        options
          .map((option) => {
            return {
              ...option,
              result: match(
                inputValue,
                extractTextContent(option[shortNameField]),
                {
                  caseSensitive: false,
                  withRanges: true,
                  withScore: true,
                }
              ),
            };
          })
          .filter(({ result }) => result.match)
          .sort((a, b) => b.result.score - a.result.score)
          .map(({ result, ...option }) => ({
            ...option,
            displayName:
              inputValue.length > 0
                ? surround(extractTextContent(option[shortNameField]), {
                    result,
                    prefix: "<strong>",
                    suffix: "</strong>",
                  })
                : extractTextContent(option[shortNameField]),
          }))
      );
      if (inputValue === "" || !validOptions.includes(inputValue)) {
        onChange("");
      }
    },
  });

  const inputPropsPre = getInputProps();
  const [ariaProps, inputProps] = partition(Object.keys(inputPropsPre), (key) =>
    key.startsWith("aria-")
  ).map((propKeys) => {
    return propKeys.reduce((acc, propKey) => {
      return {
        ...acc,
        [propKey]: inputPropsPre[propKey],
      };
    }, {});
  });

  const menuProps = getMenuProps();
  const [optionsMaxHeight, setOptionsMaxHeight] = useState(48 * maxOptions);
  useEffect(() => {
    try {
      if (menuProps.ref) {
        let newHeight = parseInt(
          getComputedStyle(
            menuProps.ref.querySelector(".fsp-input--typeahead__option")
          ).height,
          10
        );
        if (newHeight && newHeight > 0) {
          newHeight *= maxOptions;
          if (newHeight !== optionsMaxHeight) {
            setOptionsMaxHeight(newHeight);
          }
        }
      }
    } catch (e) {
      // empty by design
    }
  }, [menuProps.ref, optionsMaxHeight, maxOptions]);

  const showList =
    isOpen &&
    (!(inputItems.length === 1 && !!selectedItem) || !inputProps?.value);

  return (
    <div
      className={classNames("TextInput", className, {
        "TextInput--error": hasError,
      })}
      {...getComboboxProps()}
    >
      <input
        aria-label={label}
        autoComplete="off"
        className="TextInput-field formElement-field"
        id={identifier}
        onFocus={openMenu}
        placeholder={[t(label), required && requiredIndicator]
          .filter(Boolean)
          .join("")}
        type="text"
        {...inputProps}
        {...(showList ? ariaProps : {})}
      />

      <label
        className={classNames("formElement-label ty-c4", {
          "formElement--focusAlways": !!selectedItem,
        })}
        {...getLabelProps()}
      >
        {t(label)}
        {required && requiredIndicator}
      </label>
      <div className="fsp-input--typeahead__options-wrapper">
        <div
          className="fsp-input--typeahead__options"
          ref={menuProps.ref}
          style={{
            maxHeight: `${optionsMaxHeight}px`,
          }}
          {...(showList ? menuProps : {})}
        >
          {showList &&
            inputItems.map((option, index) => {
              const itemProps = getItemProps({ item: option, index });
              return (
                <div
                  key={option[uniqueFieldName]}
                  className={classNames("fsp-input--typeahead__option", {
                    "fsp-input--typeahead__option--selected":
                      itemProps["aria-selected"] === "true",
                  })}
                  {...itemProps}
                  dangerouslySetInnerHTML={{
                    __html: formatBeverlyWilshire(
                      option[shortNameField] || option.displayName
                    ),
                  }}
                />
              );
            })}
        </div>
      </div>

      {hasError && typeof error === "string" && (
        <span className="formElement-message" role="alert">
          {error}
        </span>
      )}
    </div>
  );
}
