import React, { useState } from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";
import ViewMoreDetailsLink from "./ViewMoreDetailsLink";
import SelectRoomButton from "./SelectRoomButton";
import BookDirectContent from "./BookDirectContent";
import RoomImages from "./RoomImages";
import MobileRoomDetails from "../../components/Room/MobileRoomDetails";
import RoomItemFeatures from "../../components/Room/RoomItemFeatures";
import Price from "./Price";

export default function RoomMobile({
  accessibleFeaturesList,
  bedOptions,
  bedTypes,
  isBookableOnline,
  bookDirectData = { telephones: [] },
  propertyUseGlobalNumber,
  tollFreeNumbers,
  feesDisclaimer, // from property content
  floorPlanImagePath,
  images,
  lowestRate: { amount, currency } = {},
  occupancy,
  onSelectRoom,
  openFloorplanModal,
  priceLabel,
  roomName,
  servicesAndAmenityGroups,
  size,
  view,
  isBestRate = false,
}) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <section
      className={classNames("room-item", {
        "room-item-expanded": isExpanded,
        "room-item--is-best-rate": isBestRate,
      })}
    >
      {isBestRate && (
        <div className="room-item__best-rate">{t("Best Rate")}</div>
      )}

      <div className="room-item-preview">
        {isBookableOnline && (
          <Price
            currency={currency}
            amount={amount}
            feesDisclaimer={feesDisclaimer}
            priceLabel={priceLabel}
          />
        )}

        <div className="room-item-image">
          {images.length > 0 && (
            <RoomImages
              roomName={roomName}
              images={images}
              showThumbnails={false}
            />
          )}
        </div>

        <div
          className={classNames("room-item-info", {
            "has-book-direct": !isBookableOnline,
          })}
        >
          <h3 className="room-item-title">{roomName}</h3>

          {!isBookableOnline && (
            <BookDirectContent
              hotelReservations={bookDirectData.hotelReservations}
              title="To book this room please call "
              telephones={bookDirectData.telephones}
              propertyUseGlobalNumber={propertyUseGlobalNumber}
              tollFreeNumbers={tollFreeNumbers}
              className="hidden-xxs"
            />
          )}

          <RoomItemFeatures
            bedOptions={bedOptions}
            isBookableOnline
            floorPlanImagePath={floorPlanImagePath}
            openFloorplanModal={openFloorplanModal}
            occupancy={occupancy}
            size={size}
            roomName={roomName}
            view={view}
          />

          {!isBookableOnline && (
            <BookDirectContent
              hotelReservations={bookDirectData.hotelReservations}
              title="To book this room please call "
              telephones={bookDirectData.telephones}
              propertyUseGlobalNumber={propertyUseGlobalNumber}
              tollFreeNumbers={tollFreeNumbers}
              className="visible-xxs"
            />
          )}

          <ViewMoreDetailsLink
            isExpanded={isExpanded}
            toggleExpand={toggleExpand}
            roomName={roomName}
            uniqueName={roomName}
          />

          {isBookableOnline && (
            <SelectRoomButton
              bedTypes={bedTypes}
              roomName={roomName}
              onSelectRoom={onSelectRoom}
            />
          )}
        </div>
      </div>
      {isExpanded && (
        <MobileRoomDetails
          accessibleFeaturesList={accessibleFeaturesList}
          isBookableOnline={isBookableOnline}
          roomName={roomName}
          servicesAndAmenityGroups={servicesAndAmenityGroups}
        />
      )}
    </section>
  );
}
