/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import useTextInput from "../../hooks/useTextInput";

const TextArea = React.forwardRef((props, ref) => {
  const {
    inputProps,
    fieldClassNames,
    labelClassNames,
    label,
    error,
    errorClassNames,
  } = useTextInput({ ...props, type: "textarea" }, ref);

  return (
    <div className={fieldClassNames}>
      <textarea {...inputProps} />

      {label && (
        <label htmlFor={inputProps.id} className={labelClassNames}>
          {label}
        </label>
      )}

      {error && (
        <span className={errorClassNames} role="alert">
          {error}
        </span>
      )}
    </div>
  );
});

TextArea.propTypes = {
  identifier: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  requiredIndicator: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  register: PropTypes.func,
  onChange: PropTypes.func,
};

TextArea.defaultProps = {
  value: "",
  disabled: false,
  readOnly: false,
  required: false,
  requiredIndicator: "*",
  error: "",
  onChange: () => {},
};

TextArea.displayName = "TextArea";

export default TextArea;
