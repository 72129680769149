import { transform, isEmpty, flow, pick, get } from "lodash/fp";

import env from "config/env";

const { IMAGE_BASE_URL } = env;

const getAncillaryImages = (ancillary) => {
  const ancillaryImages = flow(
    get(["categories", 0, "products", 0]),
    pick(["landscapeImage", "portraitImage"]),
    transform.convert({ cap: false })((acc, v, k) => {
      if (!v || !v.url) return acc;
      acc[k.replace("Image", "")] = `${IMAGE_BASE_URL}${v.url}`;
      return acc;
    }, {})
  )(ancillary);

  return isEmpty(ancillaryImages) ? null : ancillaryImages;
};

export default getAncillaryImages;
