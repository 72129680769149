import React from "react";

import { useTranslation } from "hooks";
import Button from "Profile/components/Button";

export default function DoYouHaveAReservationWithUs({ onSubmit }) {
  const { t } = useTranslation();

  return (
    <div className="view view--centered view--register-have-reservation">
      <h1>{t("Welcome")}</h1>

      <p className="intro">{t("Do you have a reservation with us?")}</p>

      <div className="view__actions">
        <div className="view__action">
          <Button
            kind="secondary"
            onClick={() => onSubmit({ hasReservation: false })}
          >
            {t("No")}
          </Button>
        </div>
        <div className="view__action">
          <Button onClick={() => onSubmit({ hasReservation: true })}>
            {t("Yes")}
          </Button>
        </div>
      </div>
    </div>
  );
}
