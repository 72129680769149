import { STEPS, WEB_CHAT_STATE as STATE } from "fixtures/constants";

const getWebChatStatus = ({ isEnabled, currentState = STATE.HIDDEN, step }) => {
  if (!isEnabled) return STATE.HIDDEN;

  const showWhenHidden = () =>
    currentState === STATE.HIDDEN ? STATE.SHOWN : currentState;

  switch (step) {
    case STEPS.ONE:
    case STEPS.TWO.TWO_A:
    case STEPS.TWO.TWO_B:
    case STEPS.THREE:
    case STEPS.FOUR:
      return showWhenHidden();
    case STEPS.UPCOMING_TRIP:
    case STEPS.ITINERARY:
      return currentState === STATE.HIDDEN ? STATE.SHOWN : currentState;

    default:
      return STATE.HIDDEN;
  }
};

export default getWebChatStatus;
