import React from "react";
import pick from "lodash/fp/pick";

import { useTranslation } from "hooks";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import {
  selectMailSubscriptionList,
  updateEmailSubscriptions,
} from "store/profile";
import { Button } from "Profile/components";
import {
  separateGlobalAndLocalSubscriptions,
  groupLocalSubscriptionsByProperty,
} from "store/profile/profile.helpers";
import useFormSubmit from "../../../hooks/useFormSubmit";

const subscribedToAll = (watchedSubscriptions, globalSubscriptionsLength) =>
  Object.keys(watchedSubscriptions)
    .map((key) => watchedSubscriptions[key].optInOption)
    .filter(Boolean).length === globalSubscriptionsLength;

const handleSubscribedToAllChanged =
  (setValue, watchedSubscriptions, globalSubscriptionsLength) => () => {
    const optInOption = !subscribedToAll(
      watchedSubscriptions,
      globalSubscriptionsLength
    );

    setValue(
      "mailSubscriptionList",
      Object.keys(watchedSubscriptions).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            ...watchedSubscriptions[key],
            optInOption,
          },
        }),
        {}
      )
    );
  };

const deselectAll = (setValue, watchedSubscriptions) => {
  setValue(
    "mailSubscriptionList",
    Object.keys(watchedSubscriptions).reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          ...watchedSubscriptions[key],
          optInOption: false,
        },
      }),
      {}
    )
  );
};

function EmailSubscriptions() {
  const { t } = useTranslation();

  const {
    onSubmit,
    register,
    showLoadingIndicator,
    value: subscriptions,
    formState,
    setValue,
    watched,
  } = useFormSubmit({
    action: updateEmailSubscriptions,
    selector: selectMailSubscriptionList,
    fieldName: "mailSubscriptionList",
  });

  const { global, local } = separateGlobalAndLocalSubscriptions(subscriptions);

  const localSubscriptionsByProperty = groupLocalSubscriptionsByProperty(local);

  const globalSubscriptionsLength = Object.keys(global).length;
  const watchedGlobal = pick(Object.keys(global), watched);

  return (
    <div id="email-subscriptions" className="view--profile-edit__block">
      {showLoadingIndicator && <BookingFlowLoadingIndicator />}
      <div className="fs-profile-subscriptions">
        <h3 className="fs-profile-subscriptions__title">
          {t("Email Subscriptions")}
        </h3>
        <p>
          {t(
            global["Four Seasons Yachts"]
              ? "Please select the communications you would like to receive. We’re mindful of your inbox and we’ll make sure you only receive content based on your interests."
              : "Please select the communications you would like to receive from Four Seasons. We’re mindful of your inbox and we’ll make sure you only receive content based on your interests."
          )}
        </p>
        <form onSubmit={onSubmit}>
          <div className="fs-profile-subscriptions__checkboxes">
            <div className="fsp-input fsp-input--dark">
              <label className="fsp-input__checkbox">
                <input
                  className="checkbox"
                  type="checkbox"
                  name="all"
                  checked={subscribedToAll(
                    watchedGlobal,
                    globalSubscriptionsLength
                  )}
                  onChange={handleSubscribedToAllChanged(
                    setValue,
                    watchedGlobal,
                    globalSubscriptionsLength
                  )}
                />
                {t(
                  global["Four Seasons Yachts"]
                    ? "Subscribe to ALL Four Seasons Hotels and Resorts and Four Seasons Yachts communications"
                    : "Subscribe to ALL Four Seasons Hotels and Resorts communications"
                )}
              </label>
            </div>

            {Object.keys(global).map((key) => (
              <div key={key} className="fsp-input fsp-input--dark">
                <label className="fsp-input__checkbox">
                  <input
                    className="checkbox"
                    type="checkbox"
                    name={`mailSubscriptionList.${key}.optInOption`}
                    ref={register()}
                  />
                  {t(global[key].text)}
                </label>
              </div>
            ))}
          </div>
          {global["Four Seasons Yachts"] && (
            <div className="fs-profile-subscriptions__yacht">
              <p>
                {t(
                  'Please note that by selecting "Four Seasons Yachts" you consent to Marc-Henry Cruise Holdings LTD ("Marc-Henry)", in its capacity as the joint owner/operator of Four Seasons Yachts, sending you electronic communications to keep you informed of updates regarding the Four Seasons Yachts, including exclusive offers and upcoming itineraries.'
                )}
              </p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(
                    'If I provided my consent to receive electronic communications from Marc-Henry related to the Four Seasons Yachts, by selecting "Update" I acknowledge that Marc-Henry will process my personal information as set out in its <a href="https://www.fourseasonsyachts.com/privacy/">Privacy Notice</a>. I also acknowledge that Four Seasons will share my contact information with Marc-Henry, as well as certain information about me and my relationship with Four Seasons, including preference and stay information, so that Marc-Henry can send me more relevant and personalized communications.'
                  ),
                }}
              />
            </div>
          )}
          {localSubscriptionsByProperty.length > 0 && (
            <div className="fs-profile-subscriptions__checkboxes fs-profile-subscriptions__checkboxes--local">
              <h4>{t("Local Mailing List")}</h4>
              {localSubscriptionsByProperty.map(
                ({
                  propertyCode,
                  propertyName,
                  subscriptions: localSubscriptions,
                }) => (
                  <div
                    key={propertyName}
                    className="fs-profile-subscriptions__checkboxes--local__block"
                  >
                    <p>{propertyName}</p>
                    {localSubscriptions.map(({ mailingList, text }) => (
                      <div
                        key={mailingList}
                        className="fsp-input fsp-input--dark"
                      >
                        <label className="fsp-input__checkbox">
                          <input
                            className="checkbox"
                            type="checkbox"
                            name={`mailSubscriptionList.${mailingList}:${propertyCode}.optInOption`}
                            ref={register()}
                          />
                          {t(text)}
                        </label>
                      </div>
                    ))}
                  </div>
                )
              )}
              <Button
                kind="link"
                onClick={() => deselectAll(setValue, watched)}
              >
                {t("Deselect all")}
              </Button>
            </div>
          )}
          <div className="fs-profile-subscriptions__actions">
            <Button
              type="submit"
              kind="secondary"
              aria-label={t("Add a new email address")}
              disabled={!formState.isValid}
              rounded
            >
              {t("Update")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EmailSubscriptions;
