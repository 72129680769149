import { useTranslation } from "hooks";
import { useLocation } from "react-router-dom";
import { LOCALE_CONTACT_NUMBERS } from "fixtures/constants";

export default function useLanguageSupport({
  useLocale,
  continuePath,
  continueMsg = "Continue and view rooms and rates in English",
}) {
  const { t, locale } = useTranslation();
  const location = useLocation();

  const alreadyOnContinuePath = [location, continuePath]
    .filter(Boolean)
    .map(({ pathname, search }) => pathname + search)
    .every((val, index, self) => val === self[0]);

  const heading = t("English content is unavailable for this property", {
    lng: useLocale || locale,
  });
  const callMsg = t("Call for assistance in English", {
    lng: useLocale || locale,
  });

  const continueMessage = t(continueMsg, { lng: useLocale || locale });

  const phoneContacts = (LOCALE_CONTACT_NUMBERS[locale] || []).map(
    ({ name, number }) => ({ text: name || callMsg, number })
  );

  return {
    alreadyOnContinuePath,
    heading,
    continueMessage,
    phoneContacts,
  };
}
