import {
  updateCountryAndLanguage,
  updateCountryAndLanguageFulfilled,
} from "store/profile/profile.slice";
import { prepareCountryAndLanguage } from "../profile.helpers";
import { updateProfileTemplate } from "./updateProfile";

export default updateProfileTemplate({
  actionType: updateCountryAndLanguage.type,
  fulfillAction: updateCountryAndLanguageFulfilled,
  updateFn: ({ extension = {} } = {}, newValue) => ({
    extension: {
      ...extension,
      ...prepareCountryAndLanguage(
        extension.countryOfResidence,
        extension.preferredLanguage,
        newValue
      ),
    },
  }),
});
