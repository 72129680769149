import { flow, find, merge, getOr } from "lodash/fp";

const SOURCE_NAME = "RBF";

const STEPS = [
  { url: "/plan-your-stay", index: 1 },
  { url: "/choose-your-room", index: 2 },
  { url: "/confirm-your-stay", index: 3 },
  { url: "/personalize-your-stay", index: 4 },
  { url: "/upcoming-trip", index: "upcoming-trip" },
  { url: "/profile", index: "profile" },
];

const handleStringOrArray = (value = "") =>
  Array.isArray(value) ? value : [value];

function generateSearchMetadata({
  searchParams = {},
  pathname = "",
  profile,
  bookingError,
}) {
  const step = STEPS.find(({ url }) => pathname.includes(url))?.index;

  const firstName = getOr("", ["name", "firstName"])(profile);
  const surname = getOr("", ["name", "surname"])(profile);
  const email = flow(
    getOr([], "emails"),
    find(({ activeEmail, defaultEmail }) => activeEmail || defaultEmail),
    getOr("", "email")
  )(profile);

  return {
    source: SOURCE_NAME,
    url: pathname,
    "guest.userGivenName": firstName,
    "guest.userSurname": surname,
    "guest.userEmail": email,
    ...(bookingError ? { "search.error": bookingError } : {}),
    ...(step ? { step } : {}),
    ...Object.keys(searchParams).reduce(
      (acc, key) =>
        ["adults", "children"].includes(key)
          ? {
              ...acc,
              ...handleStringOrArray(searchParams[key])
                .map((number, index) => ({
                  [`search.rooms[${index}].${key}`]: parseInt(number, 10),
                }))
                .reduce(merge),
            }
          : {
              ...acc,
              [`search.${key}`]: searchParams[key],
            },
      {}
    ),
  };
}

export default generateSearchMetadata;
