import sortBy from "lodash/fp/sortBy";
import get from "lodash/fp/get";

export default sortBy(
  (bookingSummary) =>
    `${get(["startDate"], bookingSummary)}-${get(
      ["endDate"],
      bookingSummary
    )}-${get(["hotelProducts", "0", "reservationId"], bookingSummary)}`
);
