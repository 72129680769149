import React from "react";
import classNames from "classnames";
import { AlertIcon, AlertIconAsHtmlString } from "components";

export default function ErrorMessage({
  newAlertIcon = false,
  className,
  children,
}) {
  return newAlertIcon ? (
    <div className={classNames("form-error-new", className)}>
      {typeof children === "string" ? (
        <span
          className="ty-d2"
          dangerouslySetInnerHTML={{
            __html: `<span class="form-error-new__alert">${AlertIconAsHtmlString()}</span>${children}`,
          }}
        />
      ) : (
        <span className="ty-d2">
          <span className="form-error-new__alert">
            <AlertIcon />
          </span>
          {children}
        </span>
      )}
    </div>
  ) : (
    <div className={classNames("form-error", className)}>
      {typeof children === "string" ? (
        <span
          className="ty-b2"
          dangerouslySetInnerHTML={{
            __html: children,
          }}
        />
      ) : (
        <span className="ty-b2">{children}</span>
      )}
    </div>
  );
}
