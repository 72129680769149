import React from "react";

import * as bookingFlowRoutes from "BookingFlow/bookingFlowRoutes";
import { useTranslation } from "hooks";
import { SignInModal } from "Authentication";

export default function SignInOrRegisterBlock({
  contactName,
  email,
  phoneNumber,
  goToRegisterWithReservation,
  enableSignInLink = false,
}) {
  const { t, locale } = useTranslation();

  return (
    <div className="sign-in-or-register-block">
      <p className="sign-in-or-register-block__greeting">
        {`${t("Hi")} ${contactName}`}
      </p>
      <p>{t("Plan the perfect stay with us.")}</p>
      <div className="sign-in-or-register-block__actions">
        {enableSignInLink && (
          <div>
            <SignInModal
              id="signInBlock"
              email={email}
              phoneNumber={phoneNumber}
              afterSignInRedirectTo={bookingFlowRoutes.personalizeYourStay.to({
                locale,
              })}
              fromAnonymous
            />
          </div>
        )}

        <div>
          <button
            type="button"
            onClick={goToRegisterWithReservation}
            className="CTA--underlined--light"
          >
            {t("Sign Up")}
          </button>
        </div>
      </div>
    </div>
  );
}
