import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/fp/isEmpty";
import get from "lodash/fp/get";

import { useTranslation, useDispatchWithLocale } from "hooks";
import getRates from "Profile/utils/getRates";
import {
  getRatesForDateChange,
  getRatesForDateChangeClear,
  selectBookingForDateChange,
  selectBookingForDateChangeError,
} from "store/bookings";
import {
  selectTermsAndConditionsForCurrentBooking,
  fetchTermsAndConditionsContent,
} from "store/termsAndConditionsContent";
import BookingFlowLoadingIndicator from "BookingFlow/components/BookingFlowLoadingIndicator";
import { earliestCheckInDate } from "utils/utils";
import Layout from "./Layout";
import ChangeTravelDates from "./ChangeTravelDates";
import ConfirmDateChange from "./ConfirmDateChange";

export default function ChangeDate({
  dates,
  goBackHandler,
  handleUpdateTravelDates,
  highlights,
  hotelCode,
  isWebChatEnabled,
  openWebChat,
  propertyContent,
  showSavingSpinner,
  taxes,
  upcomingTrip: booking,
}) {
  const { locale } = useTranslation();

  const dispatchWithLocale = useDispatchWithLocale();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [startDate, setStartDate] = useState(dates.startDate);
  const [endDate, setEndDate] = useState(dates.endDate);

  const checkInStart = earliestCheckInDate(propertyContent);

  const bookingForDateChange = useSelector(selectBookingForDateChange);
  const hasBookingForDateChange = !isEmpty(bookingForDateChange);
  const termsForDateChange = useSelector(
    selectTermsAndConditionsForCurrentBooking({
      locale,
      currentBooking: bookingForDateChange,
    })
  );

  const rates =
    hasBookingForDateChange &&
    getRates({ upcomingTrip: bookingForDateChange, taxes });

  const handleContinue = () => {
    dispatchWithLocale(getRatesForDateChangeClear());
    dispatchWithLocale(getRatesForDateChange({ booking, startDate, endDate }));

    const termsAndConditionsOptions = {
      checkinDate: startDate,
      checkoutDate: endDate,
      offerCode: get(
        ["hotelProducts", "0", "roomRate", "ratePlanCode"],
        booking
      ),
      roomCode:
        get(
          ["hotelProducts", "0", "roomTypes", "0", "fsRoomToCharge"],
          booking
        ) ||
        get(["hotelProducts", "0", "roomTypes", "0", "roomTypeId"], booking),
    };

    dispatchWithLocale(
      fetchTermsAndConditionsContent({
        locale,
        hotelCode,
        optionsList: [termsAndConditionsOptions],
      })
    );
    setShowConfirmation(true);
  };

  const errorCode = useSelector(selectBookingForDateChangeError);

  useEffect(() => {
    dispatchWithLocale(getRatesForDateChangeClear());
  }, []);

  return (
    <Layout
      goBackHandler={goBackHandler}
      className="view--upcoming-trip__modal--change-date"
      openWebChat={openWebChat}
      isWebChatEnabled={isWebChatEnabled}
    >
      <div className="col-sm-12 col-md-10 offset-md-1">
        {showConfirmation && !hasBookingForDateChange && !errorCode && (
          <BookingFlowLoadingIndicator />
        )}
        {showConfirmation && hasBookingForDateChange && !errorCode ? (
          <ConfirmDateChange
            endDate={endDate}
            goBackHandler={goBackHandler}
            handleUpdateTravelDates={handleUpdateTravelDates}
            highlights={highlights}
            rates={rates}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            showSavingSpinner={showSavingSpinner}
            startDate={startDate}
            termsForDateChange={termsForDateChange}
          />
        ) : (
          <ChangeTravelDates
            checkInStart={checkInStart}
            endDate={endDate}
            errorCode={errorCode}
            goBackHandler={goBackHandler}
            hotelCode={hotelCode}
            onSubmit={handleContinue}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            startDate={startDate}
          />
        )}
      </div>
    </Layout>
  );
}
