import React from "react";

import { useTranslation } from "hooks";
import { getPrivacyLink } from "utils/externalLinks";
import { Button, Checkbox } from "Profile/components";
import Modal from "Profile/ProfileView/Modal";

export default function UpdateSubscriptionsModal({
  close,
  updateSubscriptions,
  setUpdateSubscriptions,
}) {
  const { t, locale } = useTranslation();

  return (
    <Modal
      close={close}
      onClick={(evt) => {
        evt.stopPropagation();
      }}
    >
      <div className="fs-profile-modal">
        <h1>{t("Update subscriptions")}</h1>

        <p>
          {t(
            "You have active subscriptions under your current primary email. If you would like to update your existing subscriptions to the new email please consent below."
          )}
        </p>

        <div className="fs-profile-modal__content">
          <Checkbox
            name="updateSubscriptions"
            onChange={({ target: { checked } }) => {
              setUpdateSubscriptions(checked);
            }}
            checked={updateSubscriptions}
          >
            {t(
              "I consent to Four Seasons sending me electronic communications so that Four Seasons can keep me informed of upcoming promotions and exclusive offers and to conduct marketing research."
            )}
          </Checkbox>
        </div>

        <p
          dangerouslySetInnerHTML={{
            __html: t(
              'I acknowledge that Four Seasons will handle my personal information as set out in the <a href="{0}" target="_blank">Four Seasons Privacy Notice</a>. I understand that if I provided my consent to receive certain electronic communications above, I can withdraw that consent at any time by emailing <a href="mailto:guest.historian@fourseasons.com">guest.historian@fourseasons.com</a>.',
              [getPrivacyLink(locale)]
            ),
          }}
        />

        <div className="fs-profile-modal__form-actions">
          <Button onClick={close}>{t("Continue")}</Button>
        </div>
      </div>
    </Modal>
  );
}
