import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import FocusLock from "react-focus-lock";
import { RemoveScroll } from "react-remove-scroll";
import { CloseIcon } from "components";
import { useTranslation } from "hooks";

export default function Modal({
  title,
  className,
  children,
  closeModal = () => {},
  renderCloseButton = true,
}) {
  const { t } = useTranslation();

  function handleKeyDown(evt) {
    if (evt.key === "Escape") {
      closeModal();
    }
  }

  function handleOverlayClicked(evt) {
    if (evt.target === evt.currentTarget) {
      closeModal();
    }
  }

  function catchSubmit(evt) {
    // any forms submitted inside a <Modal> should not propagate outside
    evt.stopPropagation();
  }

  return ReactDOM.createPortal(
    <RemoveScroll allowPinchZoom>
      <FocusLock returnFocus>
        <div
          className={classNames("new-modal", className)}
          role="button"
          onKeyDown={handleKeyDown}
          onClick={handleOverlayClicked}
          tabIndex="0"
          onSubmit={catchSubmit}
        >
          <div className="modal-dialog" role="dialog">
            <div className="modal-dialog__content">
              {title && (
                <div className="modal-dialog__title">
                  <button
                    type="button"
                    aria-label={t("Back")}
                    className="back-btn icon"
                    onClick={closeModal}
                  />
                  <h1>{t(title)}</h1>
                </div>
              )}
              <div className="modal-dialog__body">{children}</div>
              {renderCloseButton && (
                <div
                  className="modal-dialog__close icon-button modal-icon icon"
                  onClick={closeModal}
                  onKeyDown={closeModal}
                  role="button"
                  tabIndex={0}
                  aria-label={t("Close")}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
          </div>
        </div>
      </FocusLock>
    </RemoveScroll>,
    document.body
  );
}
