import React, { Children, useEffect, useRef, useState } from "react";
import debounce from "lodash/debounce";
import flow from "lodash/fp/flow";
import get from "lodash/fp/get";

import { useTranslation } from "hooks";

export default function HorizontalScrollView({ children }) {
  const { t } = useTranslation();
  const ref = useRef(null);

  const SCROLL_AMOUNT = 200;
  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScrollPosition, setMaxScrollPosition] = useState(0);

  const handleScroll = flow(get(["target", "scrollLeft"]), setScrollPosition);

  const handleLeftButtonClick = () => {
    ref.current.scrollTo({
      top: 0,
      left: Math.max(0, scrollPosition - SCROLL_AMOUNT),
      behavior: "smooth",
    });
  };

  const handleRightButtonClick = () => {
    ref.current.scrollTo({
      top: 0,
      left: Math.min(maxScrollPosition, scrollPosition + SCROLL_AMOUNT),
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const doSetMaxScrollPosition = () =>
      setMaxScrollPosition(ref.current.scrollWidth - ref.current.clientWidth);

    window.addEventListener("resize", doSetMaxScrollPosition);

    const timeout = setTimeout(doSetMaxScrollPosition, 100);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("resize", doSetMaxScrollPosition);
    };
  }, []);

  return (
    <div className="horizontal-scrollview__container">
      <div
        className="horizontal-scrollview"
        ref={ref}
        onScroll={debounce(handleScroll, 300)}
      >
        {scrollPosition > 0 && (
          <button
            type="button"
            aria-label={t("scroll left")}
            onClick={handleLeftButtonClick}
            className="horizontal-scrollview__side-button horizontal-scrollview__side-button--left"
          >
            <span className="icon-chevron-left" />
          </button>
        )}

        {Children.map(children, (child, index) => {
          if (!child) {
            return null;
          }
          return (
            <div key={index} className="horizontal-scrollview__item">
              {child}
            </div>
          );
        })}

        {scrollPosition < maxScrollPosition && (
          <button
            type="button"
            aria-label={t("scroll right")}
            onClick={handleRightButtonClick}
            className="horizontal-scrollview__side-button horizontal-scrollview__side-button--right"
          >
            <span className="icon-chevron-right" />
          </button>
        )}
      </div>
    </div>
  );
}
