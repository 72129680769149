/* eslint-disable import/prefer-default-export */

export function selectWebChatParams(state) {
  const { params } = state.webChat;
  const hotelCode =
    params?.propertyCode ||
    state.bookings?.bookingInProgressSearchParams?.hotelCode ||
    state.router.location?.query?.hotelCode;

  return {
    hotelCode,
    ...params,
  };
}
