import React from "react";

import env from "config/env";
import { useTranslation } from "hooks";
import DictionaryKeyContent from "BookingFlow/components/DictionaryKeyContent";
import formatBeverlyWilshire from "Profile/utils/formatBeverlyWilshire";

const { STATIC_SITE_URL } = env;

function ReservationsContactDetails({
  propertyPhone,
  globalSupportPhone,
  globalTollFreeLink,
  propertyName,
}) {
  const { t } = useTranslation();

  return (
    <div className="col-md-5 error-contact-details">
      <h2>{t("Contact Reservations")}</h2>

      <div className="row">
        <div className="col-xs-12 col-md-12">
          <p>
            {propertyPhone && (
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(formatBeverlyWilshire(propertyName)),
                  }}
                />
                <br />
                {t("Office")}:{" "}
                <a href={`tel:${propertyPhone}`}>{propertyPhone}</a>
                <br />
              </span>
            )}

            <span>
              {t("Worldwide")}:{" "}
              {globalTollFreeLink && (
                <a
                  className="global-contact-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={STATIC_SITE_URL + globalTollFreeLink.url}
                >
                  <DictionaryKeyContent dict={globalTollFreeLink.textDictKey} />
                </a>
              )}
              {globalSupportPhone && !globalTollFreeLink && (
                <a href={`tel:${globalSupportPhone}`}>{globalSupportPhone}</a>
              )}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ReservationsContactDetails;
