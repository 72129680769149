import React, { useState, useRef, useContext, useEffect } from "react";
import { MediaContext } from "contexts/MediaContext";
import classNames from "classnames";

import { useTranslation } from "hooks";
import { addOne, subtractOne, buildSelectedOptionsLabel } from "utils/utils";
import { defaultRoom, operationType } from "./constants";
import RoomOptions from "./RoomOptions";

const GuestDropdown = React.forwardRef(
  (
    {
      hasError,
      maxAdults = 4,
      maxBodies = 4,
      maxChildAge,
      maxChildren = 3,
      maxNumberOfRooms,
      minAdults,
      minChildAge,
      minChildren,
      onChange,
      value: rooms,

      propertyPhone,
      globalTollFreeLink,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const media = useContext(MediaContext);

    const [isFirstRender, setIsFirstRender] = useState(true);
    const [isOpen, setIsOpen] = useState(hasError);

    const hide = () => setIsOpen(false);

    const defaultOptionsRef = useRef();
    const roomsRef = useRef();

    const handleAddRoomClick = () => {
      if (rooms.length < maxNumberOfRooms) {
        onChange([...rooms, defaultRoom]);
      }
    };
    const handleRemoveRoomClick = (index) => {
      if (rooms.length > 1) {
        onChange(rooms.filter((_a, i) => i !== index));
      }
    };

    useEffect(() => {
      function handleClose({ target }) {
        if (!ref?.current?.contains(target)) {
          if (media.isLessThan.sm) {
            return;
          }
          hide();
        }
      }

      document.addEventListener("click", handleClose);
      return () => {
        document.removeEventListener("click", handleClose);
      };
    });
    const defaultOptionsOnBlur = ({ relatedTarget }) => {
      if (!relatedTarget) {
        return;
      }
      if (
        media.isGreaterThan.sm &&
        !defaultOptionsRef.current.contains(relatedTarget)
      ) {
        hide();
      }
    };

    const roomsOnBlur = ({ relatedTarget }) => {
      if (!relatedTarget) {
        return;
      }
      if (media.isGreaterThan.sm && !roomsRef.current.contains(relatedTarget)) {
        hide();
      }
    };

    const componentOnBlur = ({ relatedTarget }) => {
      if (!relatedTarget) {
        return;
      }
      if (
        isOpen &&
        [defaultOptionsRef, roomsRef].every(
          (a) => !a.current?.contains(relatedTarget)
        )
      ) {
        hide();
      }
    };

    const handleOnKeyDown = (e) => {
      switch (e.key) {
        case "Enter":
        case " ":
          setIsOpen(true);
          break;
        case "Escape":
          hide();
          break;
        default:
          setIsOpen(isOpen);
      }
    };

    const update = (index, field, operation) =>
      onChange(
        rooms.map((a, i) =>
          i === index
            ? {
                ...a,
                [field]:
                  operation === operationType.INCREMENT
                    ? addOne(a[field])
                    : subtractOne(a[field]),
              }
            : a
        )
      );

    const handleIncrementAdult = (index) =>
      update(index, "adults", operationType.INCREMENT);
    const handleIncrementChild = (index) =>
      update(index, "children", operationType.INCREMENT);
    const handleDecrementAdult = (index) =>
      update(index, "adults", operationType.DECREMENT);
    const handleDecrementChild = (index) =>
      update(index, "children", operationType.DECREMENT);

    useEffect(() => {
      const getNewNumbers = ({ adults, children }) => {
        const newAdultNo = Math.min(adults, maxAdults);
        return {
          adults: newAdultNo,
          children: Math.min(children, maxBodies - newAdultNo),
        };
      };
      if (!isFirstRender) {
        onChange(rooms.map(getNewNumbers));
      }
    }, [maxAdults, maxChildren]);

    useEffect(() => {
      setIsFirstRender(false);
    }, []);

    return (
      <div id="NumberOfGuests">
        <fieldset>
          <div
            className={classNames(
              "guests-dropdown emulated-flex-gap-component show-rooms",
              {
                "shows-options": isOpen,
              }
            )}
            ref={ref}
          >
            <div
              className="guests-dropdown-selected"
              onClick={() => setIsOpen(!isOpen)}
              role="button"
              onKeyDown={handleOnKeyDown}
              onBlur={componentOnBlur}
              tabIndex="0"
            >
              {buildSelectedOptionsLabel(t, rooms).value}
              <button
                type="button"
                className={`icon-button icon icon-arrow-${
                  isOpen ? "up" : "down"
                }`}
                aria-label="show-options"
                aria-hidden="true"
                tabIndex="-1"
              />
            </div>

            {(isOpen || media.isLessThan.sm) && (
              <div
                className="guests-dropdown-options"
                role="presentation"
                ref={defaultOptionsRef}
                onBlur={defaultOptionsOnBlur}
                onMouseDown={(e) => e.preventDefault()}
              >
                <RoomOptions
                  roomsRef={roomsRef}
                  roomsOnBlur={roomsOnBlur}
                  rooms={rooms}
                  handleRemoveRoomClick={handleRemoveRoomClick}
                  maxBodies={maxBodies}
                  minAdults={minAdults}
                  maxAdults={maxAdults}
                  minChildren={minChildren}
                  maxChildren={maxChildren}
                  minChildAge={minChildAge}
                  maxChildAge={maxChildAge}
                  handleIncrementAdult={handleIncrementAdult}
                  handleDecrementAdult={handleDecrementAdult}
                  handleIncrementChild={handleIncrementChild}
                  handleDecrementChild={handleDecrementChild}
                  maxNumberOfRooms={maxNumberOfRooms}
                  handleAddRoomClick={handleAddRoomClick}
                  propertyPhone={propertyPhone}
                  globalTollFreeLink={globalTollFreeLink}
                />
              </div>
            )}
          </div>
        </fieldset>
      </div>
    );
  }
);

export default GuestDropdown;
