import React from "react";

import "../../stylesheets/sass/profile_2018.scss";
import { Helmet } from "react-helmet-async";
import { getIsoCodeForLocale } from "config/languages";
import { useTranslation, useView } from "hooks";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import { getHMTLTagClassNames } from "utils";
import ProfileLayout from "../ProfileLayout";
import {
  BookingOptionsForm,
  LanguageSupportModal,
  ErrorBanner,
} from "./components";
import useEmployeePortal from "./hooks/useEmployeePortal";

function EmployeePortalView() {
  const { i18n, locale, t } = useTranslation();
  const { searchData, showLoadingIndicator } = useEmployeePortal();
  const {
    bookingError,
    employeeError,
    error,
    missingLanguageError,
    dismissMissingLanguageError,
  } = searchData;

  useView("EmployeePortalView");

  return (
    <>
      <Helmet>
        <html
          lang={getIsoCodeForLocale(i18n.language)}
          className={getHMTLTagClassNames({
            locale,
            dir: i18n.dir(i18n.language),
            kind: "fs-profile-2018",
          })}
        />
      </Helmet>
      <ProfileLayout>
        {showLoadingIndicator && <BookingFlowLoadingIndicator />}
        <div className="employee-portal">
          {(bookingError || employeeError || error) && (
            <ErrorBanner
              bookingError={bookingError}
              employeeError={employeeError}
              error={error}
            />
          )}

          <div className="form-container">
            <h1 className="ty-h3">{t("Employee Travel")}</h1>

            <BookingOptionsForm searchData={searchData} />
          </div>
        </div>

        {missingLanguageError && (
          <LanguageSupportModal
            continuePath={missingLanguageError.continuePath}
            closeModal={dismissMissingLanguageError}
          />
        )}
      </ProfileLayout>
    </>
  );
}

export default EmployeePortalView;
