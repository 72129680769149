import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { mergeMap } from "rxjs/operators";

import env from "config/env";

const { CONTENT_API_BASE_URL } = env;

export default function contentAjax({ locale, url, retryInEN = true }) {
  return ajax
    .getJSON(`${CONTENT_API_BASE_URL}/${locale}/${url.replace(/^\//, "")}`)
    .pipe(
      mergeMap((response) => {
        if (
          retryInEN &&
          locale !== "en" &&
          response?.error === "invalid property"
        ) {
          return ajax.getJSON(
            `${CONTENT_API_BASE_URL}/en/${url.replace(/^\//, "")}`
          );
        }
        return of(response);
      })
    );
}
