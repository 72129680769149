import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs";
import { switchMap, takeUntil, mergeMap, catchError } from "rxjs/operators";

// eslint-disable-next-line import/no-cycle
import getTaxesContent$ from "api/content/getTaxes/getTaxes";
import {
  fetchTaxesContent,
  fetchTaxesContentCancel,
  fetchTaxesContentFailed,
  fetchTaxesContentFulfilled,
} from "./taxesContent.slice";

const fetchTaxesContentEpic = (action$) =>
  action$.pipe(
    ofType(fetchTaxesContent.type),
    switchMap(({ payload: { locale, propertyCode, propertyCodes } }) =>
      getTaxesContent$({ locale, propertyCode, propertyCodes }).pipe(
        mergeMap((responses) => {
          return responses.map(({ ajaxResponse, hotelCode }) => {
            if (ajaxResponse.error) {
              return fetchTaxesContentFailed({
                error: ajaxResponse.error,
                propertyCode: hotelCode,
              });
            }

            return fetchTaxesContentFulfilled({
              taxes: ajaxResponse.taxes,
              propertyCode: hotelCode,
            });
          });
        }),

        catchError((error) =>
          of(fetchTaxesContentFailed({ propertyCode, error }))
        ),

        takeUntil(action$.pipe(ofType(fetchTaxesContentCancel.type)))
      )
    )
  );

export default combineEpics(fetchTaxesContentEpic);
