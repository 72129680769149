import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  updateUserEmail,
  selectEmail,
  deleteUserEmail,
  selectMailSubscriptionList,
} from "store/profile";
import EmailForm from "./EmailForm";
import useFormSubmit from "../../../hooks/useFormSubmit";
import useDelete from "../../../hooks/useDelete";
import UpdateSubscriptionsModal from "../UpdateSubscriptionsModal";

function EditEmail({ identifier, email, canBeDeleted, closeForm }) {
  const [showUpdateSubscriptionsModal, setShowUpdateSubscriptionsModal] =
    useState(false);

  const [updateSubscriptions, setUpdateSubscriptions] = useState(false);

  const {
    onSubmit,
    register,
    showLoadingIndicator,
    formState,
    watched,
    value,
    errors,
    baseError,
    setValue,
  } = useFormSubmit({
    closeForm,
    action: updateUserEmail,
    selector: selectEmail(email.salesForceId),
    fieldName: "emailData",
  });

  const { onDelete, showLoadingIndicator: showLoadingIndicatorForDeleting } =
    useDelete({
      action: deleteUserEmail,
      salesForceId: value.salesForceId,
    });

  const mailSubscriptions = useSelector(selectMailSubscriptionList);
  const hasMailSubscriptions = Object.values(mailSubscriptions).some(
    ({ optInOption }) => optInOption
  );
  const { defaultEmail } = email;
  const watchDefaultEmail = watched.defaultEmail;

  if (!defaultEmail && hasMailSubscriptions) {
    register("emailData.updateSubscriptions");
  }

  useEffect(() => {
    if (!defaultEmail && hasMailSubscriptions && watchDefaultEmail) {
      setShowUpdateSubscriptionsModal(true);
    }
  }, [hasMailSubscriptions, defaultEmail, watchDefaultEmail]);

  useEffect(() => {
    setValue("emailData.updateSubscriptions", updateSubscriptions);
  }, [updateSubscriptions]);

  return (
    <>
      <EmailForm
        canBeDeleted={canBeDeleted}
        closeForm={closeForm}
        watched={watched}
        email={email}
        register={register}
        identifier={identifier}
        isVerified={email.validatedEmail}
        onSubmit={onSubmit}
        formState={formState}
        onDelete={onDelete}
        errors={errors}
        baseError={baseError}
        showLoadingIndicator={
          showLoadingIndicator || showLoadingIndicatorForDeleting
        }
      />

      {showUpdateSubscriptionsModal && (
        <UpdateSubscriptionsModal
          close={() => setShowUpdateSubscriptionsModal(false)}
          updateSubscriptions={updateSubscriptions}
          setUpdateSubscriptions={setUpdateSubscriptions}
        />
      )}
    </>
  );
}

export default EditEmail;
