import React, { useState } from "react";
import { useTranslation } from "hooks";
import classNames from "classnames";
import { parse, differenceInDays } from "date-fns";
import { getFormattedDateRange } from "BookingFlow/utils";
import formatBeverlyWilshire from "Profile/utils/formatBeverlyWilshire";
import env from "config/env";

const { EMPLOYEE_RATES } = env;

function YearDisplay({ displayYear, properties, stays = [] }) {
  const { t, locale } = useTranslation();

  const [showYearStays, setshowYearStays] = useState(
    new Date().getFullYear() === displayYear
  );
  const toggleshowYearStays = () => setshowYearStays(!showYearStays);

  const getEmployeeRateName = (stay) =>
    EMPLOYEE_RATES.rateDetails.find((rateCodeDetails) =>
      rateCodeDetails.rateCodes.includes(stay.ratePlanCode || "")
    )?.title || "";

  const getPropertyName = (stay) =>
    properties.find((property) => property.owsCode === stay.hotelCode)
      ?.shortName || "";

  const getNumberOfNights = (stay) =>
    differenceInDays(
      parse(stay.checkOut, "yyyy-MM-dd", new Date()),
      parse(stay.checkIn, "yyyy-MM-dd", new Date())
    );

  return (
    <div>
      <div className="year-heading">
        <div className="year-wrapper">
          <div className="year-row">{displayYear}</div>
          <button
            type="button"
            className={classNames({
              expand: !showYearStays,
              collapse: showYearStays,
            })}
            onClick={toggleshowYearStays}
          >
            {showYearStays ? t("Hide") : t("Show")}
          </button>
        </div>
      </div>
      {showYearStays && (
        <table>
          <thead>
            <tr>
              <th scope="col">{t("Rate")}</th>
              <th scope="col">{t("Hotel/Resort")}</th>
              <th scope="col">{t("Dates")}</th>
              <th scope="col">{t("Nights")}</th>
            </tr>
          </thead>
          <tbody>
            {stays.map((stay) => (
              <tr key={stay.reservationId}>
                <td data-label={t("Rate")}>{t(getEmployeeRateName(stay))}</td>
                <td
                  data-label={t("Hotel/Resort")}
                  dangerouslySetInnerHTML={{
                    __html: formatBeverlyWilshire(getPropertyName(stay)),
                  }}
                />
                <td data-label={t("Dates")}>
                  {getFormattedDateRange(
                    stay.checkIn,
                    stay.checkOut,
                    locale,
                    "short"
                  )}
                </td>
                <td data-label={t("Nights")}>{getNumberOfNights(stay)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default YearDisplay;
