import React from "react";
import classNames from "classnames";

export default function Select({
  register,
  value,
  onChange,
  children,
  includeBlank = false,
  className,
  ...props
}) {
  const inputProps = register
    ? {
        ...props,
        ref: register,
      }
    : {
        ...props,
        value,
        onChange,
      };

  return (
    <div className={classNames("fs-select-mask", className)}>
      <select {...inputProps}>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        {includeBlank && (
          <option value="">
            {typeof includeBlank === "string" ? includeBlank : ""}
          </option>
        )}
        {children}
      </select>
      <span aria-hidden="true" className="icon icon-arrow-down" />
    </div>
  );
}
