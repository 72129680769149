import React, { forwardRef, useContext } from "react";
import classNames from "classnames";
import FocusLock from "react-focus-lock";
import { RemoveScroll } from "react-remove-scroll";

import { useTranslation } from "hooks";
import { MediaContext } from "contexts/MediaContext";
import formatPrice from "utils/formatPrice";
import BottomDrawer from "./BottomDrawer";
import env from "../../../config/env";

const { IMAGE_BASE_URL } = env;

const TransportationAmenitiesLayout = forwardRef(
  (
    {
      goBack,
      title,
      description,
      totalPrice,
      imageUrl: initialImageUrl,
      className,
      sidebar,
      children,
    },
    ref
  ) => {
    const media = useContext(MediaContext);
    const { t } = useTranslation();

    const imageUrl = initialImageUrl && IMAGE_BASE_URL + initialImageUrl;

    return (
      <RemoveScroll>
        <FocusLock returnFocus noFocusGuards>
          <div
            className={classNames("transportation-amenities-layout", className)}
            ref={ref}
          >
            <div className="transportation-amenities-layout__container">
              <div className="transportation-amenities-layout__back-bar">
                <button className="btn-back" type="button" onClick={goBack}>
                  <span>{t("Back")}</span>
                </button>
              </div>

              <div className="transportation-amenities-layout__row">
                <div className="transportation-amenities-layout__main">
                  {(title || description || imageUrl) && (
                    <div className="transportation-amenities-layout__header">
                      <div className="transportation-amenities-layout__header__content">
                        {title && <h2 className="ty-h2">{title}</h2>}
                        {description && <p className="ty-b2">{description}</p>}
                      </div>

                      {imageUrl && (
                        <div className="transportation-amenities-layout__header__image">
                          <img src={imageUrl} alt={title} />
                        </div>
                      )}
                    </div>
                  )}

                  <div className="transportation-amenities-layout__content">
                    {children}
                  </div>
                </div>

                {!media.isLessThan.md && sidebar && (
                  <div className="transportation-amenities-layout__sidebar">
                    {sidebar}
                  </div>
                )}
              </div>
            </div>
          </div>

          {media.isLessThan.md && sidebar && (
            <BottomDrawer
              title={t("View Your Order")}
              totalPrice={formatPrice(totalPrice)}
            >
              {sidebar}
            </BottomDrawer>
          )}
        </FocusLock>
      </RemoveScroll>
    );
  }
);

export default TransportationAmenitiesLayout;
