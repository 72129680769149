import { combineEpics } from "redux-observable";

import {
  addPackageEpic,
  addReservation,
  cancelBookingEpic,
  completeBookingEpic,
  cancelBookingFulfilledEpic,
  constructBookingEpic,
  constructBookingByBookingIdEpic,
  constructBookingByResultSetEpic,
  fetchBookingInProgressEpic,
  fetchHistoricBookingAndContentEpic,
  fetchHistoricBookingEpic,
  fetchHistoricBookingForRegistrationEpic,
  getRatesForDateChangeEpic,
  modifyHotelProductCustEpic,
  removeContactCustomerPaymentEpic,
  requestInvoice,
  retrieveBookingEpic,
  submitBookingPreferencesEpic,
  subscribeToAllEmailsEpic,
  upgradeRoomEpic,
  getUpcomingTripDataEpic,
} from "./epics";

export default combineEpics(
  addPackageEpic,
  addReservation,
  cancelBookingEpic,
  cancelBookingFulfilledEpic,
  completeBookingEpic,
  constructBookingEpic,
  constructBookingByBookingIdEpic,
  constructBookingByResultSetEpic,
  fetchBookingInProgressEpic,
  fetchHistoricBookingAndContentEpic,
  fetchHistoricBookingEpic,
  fetchHistoricBookingForRegistrationEpic,
  getRatesForDateChangeEpic,
  modifyHotelProductCustEpic,
  removeContactCustomerPaymentEpic,
  requestInvoice,
  retrieveBookingEpic,
  submitBookingPreferencesEpic,
  subscribeToAllEmailsEpic,
  upgradeRoomEpic,
  getUpcomingTripDataEpic
);
