export default {
  US: {
    name: "United States",
    iso2: "US",
    dialCode: "1",
    priority: 0,
    areaCodes: null,
    shortName: "U.S.A",
    states_provinces: {
      title: "States",
      statesProvinces: [
        { code: "AL", title: "Alabama" },
        { code: "AK", title: "Alaska" },
        { code: "AZ", title: "Arizona" },
        { code: "AR", title: "Arkansas" },
        { code: "CA", title: "California" },
        { code: "CO", title: "Colorado" },
        { code: "CT", title: "Connecticut" },
        { code: "DE", title: "Delaware" },
        { code: "DC", title: "District of Columbia" },
        { code: "FL", title: "Florida" },
        { code: "GA", title: "Georgia" },
        { code: "HI", title: "Hawaii" },
        { code: "ID", title: "Idaho" },
        { code: "IL", title: "Illinois" },
        { code: "IN", title: "Indiana" },
        { code: "IA", title: "Iowa" },
        { code: "KS", title: "Kansas" },
        { code: "KY", title: "Kentucky" },
        { code: "LA", title: "Louisiana" },
        { code: "ME", title: "Maine" },
        { code: "MD", title: "Maryland" },
        { code: "MA", title: "Massachusetts" },
        { code: "MI", title: "Michigan" },
        { code: "MN", title: "Minnesota" },
        { code: "MS", title: "Mississippi" },
        { code: "MO", title: "Missouri" },
        { code: "MT", title: "Montana" },
        { code: "NE", title: "Nebraska" },
        { code: "NV", title: "Nevada" },
        { code: "NH", title: "New Hampshire" },
        { code: "NJ", title: "New Jersey" },
        { code: "NM", title: "New Mexico" },
        { code: "NY", title: "New York" },
        { code: "NC", title: "North Carolina" },
        { code: "ND", title: "North Dakota" },
        { code: "OH", title: "Ohio" },
        { code: "OK", title: "Oklahoma" },
        { code: "OR", title: "Oregon" },
        { code: "PA", title: "Pennsylvania" },
        { code: "RI", title: "Rhode Island" },
        { code: "SC", title: "South Carolina" },
        { code: "SD", title: "South Dakota" },
        { code: "TN", title: "Tennessee" },
        { code: "TX", title: "Texas" },
        { code: "UT", title: "Utah" },
        { code: "VT", title: "Vermont" },
        { code: "VA", title: "Virginia" },
        { code: "WA", title: "Washington" },
        { code: "WV", title: "West Virginia" },
        { code: "WI", title: "Wisconsin" },
        { code: "WY", title: "Wyoming" },
      ],
    },
  },
  CA: {
    name: "Canada",
    iso2: "CA",
    dialCode: "1",
    priority: 1,
    areaCodes: [
      "204",
      "226",
      "236",
      "249",
      "250",
      "289",
      "306",
      "343",
      "365",
      "387",
      "403",
      "416",
      "418",
      "431",
      "437",
      "438",
      "450",
      "506",
      "514",
      "519",
      "548",
      "579",
      "581",
      "587",
      "604",
      "613",
      "639",
      "647",
      "672",
      "705",
      "709",
      "742",
      "778",
      "780",
      "782",
      "807",
      "819",
      "825",
      "867",
      "873",
      "902",
      "905",
    ],
    shortName: "Canada",
    states_provinces: {
      title: "Provinces",
      statesProvinces: [
        { code: "AB", title: "Alberta" },
        { code: "BC", title: "British Columbia" },
        { code: "MB", title: "Manitoba" },
        { code: "NB", title: "New Brunswick" },
        { code: "NF", title: "Newfoundland and Labrador" },
        { code: "NT", title: "Northwest Territories" },
        { code: "NS", title: "Nova Scotia" },
        { code: "NU", title: "Nunavut" },
        { code: "ON", title: "Ontario" },
        { code: "PE", title: "Prince Edward Island" },
        { code: "QC", title: "Quebec" },
        { code: "SK", title: "Saskatchewan" },
        { code: "YT", title: "Yukon" },
      ],
    },
  },
  AF: {
    name: "Afghanistan (‫افغانستان‬‎)",
    iso2: "AF",
    dialCode: "93",
    priority: 0,
    areaCodes: null,
    shortName: "Afghanistan",
  },
  AL: {
    name: "Albania (Shqipëri)",
    iso2: "AL",
    dialCode: "355",
    priority: 0,
    areaCodes: null,
    shortName: "Albania",
  },
  DZ: {
    name: "Algeria (‫الجزائر‬‎)",
    iso2: "DZ",
    dialCode: "213",
    priority: 0,
    areaCodes: null,
    shortName: "Algeria",
  },
  AS: {
    name: "American Samoa",
    iso2: "AS",
    dialCode: "1684",
    priority: 0,
    areaCodes: null,
    shortName: "American Samoa",
  },
  AD: {
    name: "Andorra",
    iso2: "AD",
    dialCode: "376",
    priority: 0,
    areaCodes: null,
    shortName: "Andorra",
  },
  AO: {
    name: "Angola",
    iso2: "AO",
    dialCode: "244",
    priority: 0,
    areaCodes: null,
    shortName: "Angola",
  },
  AI: {
    name: "Anguilla",
    iso2: "AI",
    dialCode: "1264",
    priority: 0,
    areaCodes: null,
    shortName: "Anguilla",
  },
  AG: {
    name: "Antigua and Barbuda",
    iso2: "AG",
    dialCode: "1268",
    priority: 0,
    areaCodes: null,
    shortName: "Antigua and Barbuda",
  },
  AR: {
    name: "Argentina",
    iso2: "AR",
    dialCode: "54",
    priority: 0,
    areaCodes: null,
    shortName: "Argentina",
  },
  AM: {
    name: "Armenia (Հայաստան)",
    iso2: "AM",
    dialCode: "374",
    priority: 0,
    areaCodes: null,
    shortName: "Armenia",
  },
  AW: {
    name: "Aruba",
    iso2: "AW",
    dialCode: "297",
    priority: 0,
    areaCodes: null,
    shortName: "Aruba",
  },
  AU: {
    name: "Australia",
    iso2: "AU",
    dialCode: "61",
    priority: 0,
    areaCodes: null,
    shortName: "Australia",
    states_provinces: {
      title: "States",
      statesProvinces: [
        { code: "ACT", title: "Australian Capital Territory" },
        { code: "NSW", title: "New South Wales" },
        { code: "NT", title: "Northern Territory" },
        { code: "QLD", title: "Queensland" },
        { code: "SA", title: "South Australia" },
        { code: "TAS", title: "Tasmania" },
        { code: "VIC", title: "Victoria" },
        { code: "WA", title: "Western Australia" },
      ],
    },
  },
  AT: {
    name: "Austria (Österreich)",
    iso2: "AT",
    dialCode: "43",
    priority: 0,
    areaCodes: null,
    shortName: "Austria",
  },
  AZ: {
    name: "Azerbaijan (Azərbaycan)",
    iso2: "AZ",
    dialCode: "994",
    priority: 0,
    areaCodes: null,
    shortName: "Azerbaijan",
  },
  BS: {
    name: "Bahamas",
    iso2: "BS",
    dialCode: "1242",
    priority: 0,
    areaCodes: null,
    shortName: "Bahamas",
  },
  BH: {
    name: "Bahrain (‫البحرين‬‎)",
    iso2: "BH",
    dialCode: "973",
    priority: 0,
    areaCodes: null,
    shortName: "Bahrain",
  },
  BD: {
    name: "Bangladesh (বাংলাদেশ)",
    iso2: "BD",
    dialCode: "880",
    priority: 0,
    areaCodes: null,
    shortName: "Bangladesh",
  },
  BB: {
    name: "Barbados",
    iso2: "BB",
    dialCode: "1246",
    priority: 0,
    areaCodes: null,
    shortName: "Barbados",
  },
  BY: {
    name: "Belarus (Беларусь)",
    iso2: "BY",
    dialCode: "375",
    priority: 0,
    areaCodes: null,
    shortName: "Belarus",
  },
  BE: {
    name: "Belgium (België)",
    iso2: "BE",
    dialCode: "32",
    priority: 0,
    areaCodes: null,
    shortName: "Belgium",
  },
  BZ: {
    name: "Belize",
    iso2: "BZ",
    dialCode: "501",
    priority: 0,
    areaCodes: null,
    shortName: "Belize",
  },
  BJ: {
    name: "Benin (Bénin)",
    iso2: "BJ",
    dialCode: "229",
    priority: 0,
    areaCodes: null,
    shortName: "Benin",
  },
  BM: {
    name: "Bermuda",
    iso2: "BM",
    dialCode: "1441",
    priority: 0,
    areaCodes: null,
    shortName: "Bermuda",
  },
  BT: {
    name: "Bhutan (འབྲུག)",
    iso2: "BT",
    dialCode: "975",
    priority: 0,
    areaCodes: null,
    shortName: "Bhutan",
  },
  BO: {
    name: "Bolivia",
    iso2: "BO",
    dialCode: "591",
    priority: 0,
    areaCodes: null,
    shortName: "Bolivia",
  },
  BA: {
    name: "Bosnia and Herzegovina (Босна и Херцеговина)",
    iso2: "BA",
    dialCode: "387",
    priority: 0,
    areaCodes: null,
    shortName: "Bosnia and Herzegovina ",
  },
  BW: {
    name: "Botswana",
    iso2: "BW",
    dialCode: "267",
    priority: 0,
    areaCodes: null,
    shortName: "Botswana",
  },
  BR: {
    name: "Brazil (Brasil)",
    iso2: "BR",
    dialCode: "55",
    priority: 0,
    areaCodes: null,
    shortName: "Brazil",
  },
  BV: {
    name: "Bouvet Island",
    iso2: "BV",
    dialCode: "55",
    priority: 1,
    areaCodes: null,
    shortName: "Bouvet Island",
  },
  IO: {
    name: "British Indian Ocean Territory",
    iso2: "IO",
    dialCode: "246",
    priority: 0,
    areaCodes: null,
    shortName: "British Indian Ocean Territory",
  },
  UM: {
    name: "US Minor Outlying Islands",
    iso2: "UM",
    dialCode: "246",
    priority: 1,
    areaCodes: null,
    shortName: "US Minor Outlying Islands",
  },
  BWI: {
    name: "West Indies, Caribbean",
    iso2: "UM",
    dialCode: "246",
    priority: 2,
    areaCodes: null,
    shortName: "West Indies, Caribbean",
  },
  VG: {
    name: "British Virgin Islands",
    iso2: "VG",
    dialCode: "1284",
    priority: 0,
    areaCodes: null,
    shortName: "British Virgin Islands",
  },
  BN: {
    name: "Brunei",
    iso2: "BN",
    dialCode: "673",
    priority: 0,
    areaCodes: null,
    shortName: "Brunei",
  },
  BG: {
    name: "Bulgaria (България)",
    iso2: "BG",
    dialCode: "359",
    priority: 0,
    areaCodes: null,
    shortName: "Bulgaria",
  },
  BF: {
    name: "Burkina Faso",
    iso2: "BF",
    dialCode: "226",
    priority: 0,
    areaCodes: null,
    shortName: "Burkina Faso",
  },
  BI: {
    name: "Burundi (Uburundi)",
    iso2: "BI",
    dialCode: "257",
    priority: 0,
    areaCodes: null,
    shortName: "Burundi",
  },
  KH: {
    name: "Cambodia (កម្ពុជា)",
    iso2: "KH",
    dialCode: "855",
    priority: 0,
    areaCodes: null,
    shortName: "Cambodia",
  },
  CM: {
    name: "Cameroon (Cameroun)",
    iso2: "CM",
    dialCode: "237",
    priority: 0,
    areaCodes: null,
    shortName: "Cameroon",
  },
  CV: {
    name: "Cape Verde (Kabu Verdi)",
    iso2: "CV",
    dialCode: "238",
    priority: 0,
    areaCodes: null,
    shortName: "Cape Verde",
  },
  BQ: {
    name: "Caribbean Netherlands",
    iso2: "BQ",
    dialCode: "599",
    priority: 1,
    areaCodes: null,
    shortName: "Caribbean Netherlands",
  },
  KY: {
    name: "Cayman Islands",
    iso2: "KY",
    dialCode: "1345",
    priority: 0,
    areaCodes: null,
    shortName: "Cayman Islands",
  },
  CF: {
    name: "Central African Republic (République centrafricaine)",
    iso2: "CF",
    dialCode: "236",
    priority: 0,
    areaCodes: null,
    shortName: "Central African Republic",
  },
  TD: {
    name: "Chad (Tchad)",
    iso2: "TD",
    dialCode: "235",
    priority: 0,
    areaCodes: null,
    shortName: "Chad",
  },
  CL: {
    name: "Chile",
    iso2: "CL",
    dialCode: "56",
    priority: 0,
    areaCodes: null,
    shortName: "Chile",
  },
  CN: {
    name: "China (中国)",
    iso2: "CN",
    dialCode: "86",
    priority: 0,
    areaCodes: null,
    shortName: "China",
  },
  CX: {
    name: "Christmas Island",
    iso2: "CX",
    dialCode: "61",
    priority: 2,
    areaCodes: null,
    shortName: "Christmas Island",
  },
  CC: {
    name: "Cocos (Keeling) Islands",
    iso2: "CC",
    dialCode: "61",
    priority: 1,
    areaCodes: null,
    shortName: "Cocos (Keeling) Islands",
  },
  CO: {
    name: "Colombia",
    iso2: "CO",
    dialCode: "57",
    priority: 0,
    areaCodes: null,
    shortName: "Colombia",
  },
  KM: {
    name: "Comoros (‫جزر القمر‬‎)",
    iso2: "KM",
    dialCode: "269",
    priority: 0,
    areaCodes: null,
    shortName: "Comoros",
  },
  CD: {
    name: "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
    iso2: "CD",
    dialCode: "243",
    priority: 0,
    areaCodes: null,
    shortName: "Congo (DRC)",
  },
  CG: {
    name: "Congo (Republic) (Congo-Brazzaville)",
    iso2: "CG",
    dialCode: "242",
    priority: 0,
    areaCodes: null,
    shortName: "Congo (Republic)",
  },
  CK: {
    name: "Cook Islands",
    iso2: "CK",
    dialCode: "682",
    priority: 0,
    areaCodes: null,
    shortName: "Cook Islands",
  },
  CR: {
    name: "Costa Rica",
    iso2: "CR",
    dialCode: "506",
    priority: 0,
    areaCodes: null,
    shortName: "Costa Rica",
  },
  CI: {
    name: "Côte d’Ivoire",
    iso2: "CI",
    dialCode: "225",
    priority: 0,
    areaCodes: null,
    shortName: "Côte d’Ivoire",
  },
  HR: {
    name: "Croatia (Hrvatska)",
    iso2: "HR",
    dialCode: "385",
    priority: 0,
    areaCodes: null,
    shortName: "Croatia",
  },
  CU: {
    name: "Cuba",
    iso2: "CU",
    dialCode: "53",
    priority: 0,
    areaCodes: null,
    shortName: "Cuba",
  },
  CW: {
    name: "Curaçao",
    iso2: "CW",
    dialCode: "599",
    priority: 0,
    areaCodes: null,
    shortName: "Curaçao",
  },
  AN: {
    name: "Netherlands Antilles",
    iso2: "AN",
    dialCode: "599",
    priority: 2,
    areaCodes: null,
    shortName: "Netherlands Antilles",
  },
  CY: {
    name: "Cyprus (Κύπρος)",
    iso2: "CY",
    dialCode: "357",
    priority: 0,
    areaCodes: null,
    shortName: "Cyprus",
  },
  CZ: {
    name: "Czech Republic (Česká republika)",
    iso2: "CZ",
    dialCode: "420",
    priority: 0,
    areaCodes: null,
    shortName: "Czech Republic",
  },
  DK: {
    name: "Denmark (Danmark)",
    iso2: "DK",
    dialCode: "45",
    priority: 0,
    areaCodes: null,
    shortName: "Denmark",
  },
  DJ: {
    name: "Djibouti",
    iso2: "DJ",
    dialCode: "253",
    priority: 0,
    areaCodes: null,
    shortName: "Djibouti",
  },
  DM: {
    name: "Dominica",
    iso2: "DM",
    dialCode: "1767",
    priority: 0,
    areaCodes: null,
    shortName: "Dominica",
  },
  DO: {
    name: "Dominican Republic (República Dominicana)",
    iso2: "DO",
    dialCode: "1",
    priority: 2,
    areaCodes: ["809", "829", "849"],
    shortName: "Dominican Republic",
  },
  EC: {
    name: "Ecuador",
    iso2: "EC",
    dialCode: "593",
    priority: 0,
    areaCodes: null,
    shortName: "Ecuador",
  },
  EG: {
    name: "Egypt (‫مصر‬‎)",
    iso2: "EG",
    dialCode: "20",
    priority: 0,
    areaCodes: null,
    shortName: "Egypt",
  },
  SV: {
    name: "El Salvador",
    iso2: "SV",
    dialCode: "503",
    priority: 0,
    areaCodes: null,
    shortName: "El Salvador",
  },
  GQ: {
    name: "Equatorial Guinea (Guinea Ecuatorial)",
    iso2: "GQ",
    dialCode: "240",
    priority: 0,
    areaCodes: null,
    shortName: "Equatorial Guinea",
  },
  ER: {
    name: "Eritrea",
    iso2: "ER",
    dialCode: "291",
    priority: 0,
    areaCodes: null,
    shortName: "Eritrea",
  },
  EE: {
    name: "Estonia (Eesti)",
    iso2: "EE",
    dialCode: "372",
    priority: 0,
    areaCodes: null,
    shortName: "Estonia",
  },
  ET: {
    name: "Ethiopia",
    iso2: "ET",
    dialCode: "251",
    priority: 0,
    areaCodes: null,
    shortName: "Ethiopia",
  },
  FK: {
    name: "Falkland Islands (Islas Malvinas)",
    iso2: "FK",
    dialCode: "500",
    priority: 0,
    areaCodes: null,
    shortName: "Falkland Islands",
  },
  GS: {
    name: "South Georgia and the South Sandwich Islands",
    iso2: "GS",
    dialCode: "500",
    priority: 0,
    areaCodes: null,
    shortName: "South Georgia and the South Sandwich Islands",
  },
  FO: {
    name: "Faroe Islands (Føroyar)",
    iso2: "FO",
    dialCode: "298",
    priority: 0,
    areaCodes: null,
    shortName: "Faroe Islands",
  },
  FJ: {
    name: "Fiji",
    iso2: "FJ",
    dialCode: "679",
    priority: 0,
    areaCodes: null,
    shortName: "Fiji",
  },
  FI: {
    name: "Finland (Suomi)",
    iso2: "FI",
    dialCode: "358",
    priority: 0,
    areaCodes: null,
    shortName: "Finland",
  },
  FR: {
    name: "France",
    iso2: "FR",
    dialCode: "33",
    priority: 0,
    areaCodes: null,
    shortName: "France",
  },
  GF: {
    name: "French Guiana (Guyane française)",
    iso2: "GF",
    dialCode: "594",
    priority: 0,
    areaCodes: null,
    shortName: "French Guiana",
  },
  PF: {
    name: "French Polynesia (Polynésie française)",
    iso2: "PF",
    dialCode: "689",
    priority: 0,
    areaCodes: null,
    shortName: "French Polynesia",
  },
  GA: {
    name: "Gabon",
    iso2: "GA",
    dialCode: "241",
    priority: 0,
    areaCodes: null,
    shortName: "Gabon",
  },
  GM: {
    name: "Gambia",
    iso2: "GM",
    dialCode: "220",
    priority: 0,
    areaCodes: null,
    shortName: "Gambia",
  },
  GE: {
    name: "Georgia (საქართველო)",
    iso2: "GE",
    dialCode: "995",
    priority: 0,
    areaCodes: null,
    shortName: "Georgia",
  },
  DE: {
    name: "Germany (Deutschland)",
    iso2: "DE",
    dialCode: "49",
    priority: 0,
    areaCodes: null,
    shortName: "Germany",
  },
  GH: {
    name: "Ghana (Gaana)",
    iso2: "GH",
    dialCode: "233",
    priority: 0,
    areaCodes: null,
    shortName: "Ghana",
  },
  GI: {
    name: "Gibraltar",
    iso2: "GI",
    dialCode: "350",
    priority: 0,
    areaCodes: null,
    shortName: "Gibraltar",
  },
  GR: {
    name: "Greece (Ελλάδα)",
    iso2: "GR",
    dialCode: "30",
    priority: 0,
    areaCodes: null,
    shortName: "Greece",
  },
  GL: {
    name: "Greenland (Kalaallit Nunaat)",
    iso2: "GL",
    dialCode: "299",
    priority: 0,
    areaCodes: null,
    shortName: "Greenland",
  },
  GD: {
    name: "Grenada",
    iso2: "GD",
    dialCode: "1473",
    priority: 0,
    areaCodes: null,
    shortName: "Grenada",
  },
  GP: {
    name: "Guadeloupe",
    iso2: "GP",
    dialCode: "590",
    priority: 0,
    areaCodes: null,
    shortName: "Guadeloupe",
  },
  GU: {
    name: "Guam",
    iso2: "GU",
    dialCode: "1671",
    priority: 0,
    areaCodes: null,
    shortName: "Guam",
  },
  GT: {
    name: "Guatemala",
    iso2: "GT",
    dialCode: "502",
    priority: 0,
    areaCodes: null,
    shortName: "Guatemala",
  },
  GG: {
    name: "Guernsey",
    iso2: "GG",
    dialCode: "44",
    priority: 2,
    areaCodes: null,
    shortName: "Guernsey",
  },
  GN: {
    name: "Guinea (Guinée)",
    iso2: "GN",
    dialCode: "224",
    priority: 0,
    areaCodes: null,
    shortName: "Guinea",
  },
  GW: {
    name: "Guinea-Bissau (Guiné Bissau)",
    iso2: "GW",
    dialCode: "245",
    priority: 0,
    areaCodes: null,
    shortName: "Guinea-Bissau",
  },
  GY: {
    name: "Guyana",
    iso2: "GY",
    dialCode: "592",
    priority: 0,
    areaCodes: null,
    shortName: "Guyana",
  },
  HT: {
    name: "Haiti",
    iso2: "HT",
    dialCode: "509",
    priority: 0,
    areaCodes: null,
    shortName: "Haiti",
  },
  HN: {
    name: "Honduras",
    iso2: "HN",
    dialCode: "504",
    priority: 0,
    areaCodes: null,
    shortName: "Honduras",
  },
  HK: {
    name: "Hong Kong (香港)",
    iso2: "HK",
    dialCode: "852",
    priority: 0,
    areaCodes: null,
    shortName: "Hong Kong",
  },
  HU: {
    name: "Hungary (Magyarország)",
    iso2: "HU",
    dialCode: "36",
    priority: 0,
    areaCodes: null,
    shortName: "Hungary",
  },
  IS: {
    name: "Iceland (Ísland)",
    iso2: "IS",
    dialCode: "354",
    priority: 0,
    areaCodes: null,
    shortName: "Iceland",
  },
  IN: {
    name: "India (भारत)",
    iso2: "IN",
    dialCode: "91",
    priority: 0,
    areaCodes: null,
    shortName: "India",
  },
  ID: {
    name: "Indonesia",
    iso2: "ID",
    dialCode: "62",
    priority: 0,
    areaCodes: null,
    shortName: "Indonesia",
  },
  IR: {
    name: "Iran (‫ایران‬‎)",
    iso2: "IR",
    dialCode: "98",
    priority: 0,
    areaCodes: null,
    shortName: "Iran",
  },
  IQ: {
    name: "Iraq (‫العراق‬‎)",
    iso2: "IQ",
    dialCode: "964",
    priority: 0,
    areaCodes: null,
    shortName: "Iraq",
  },
  IE: {
    name: "Ireland",
    iso2: "IE",
    dialCode: "353",
    priority: 0,
    areaCodes: null,
    shortName: "Ireland",
  },
  IM: {
    name: "Isle of Man",
    iso2: "IM",
    dialCode: "44",
    priority: 3,
    areaCodes: null,
    shortName: "Isle of Man",
  },
  IL: {
    name: "Israel (‫ישראל‬‎)",
    iso2: "IL",
    dialCode: "972",
    priority: 0,
    areaCodes: null,
    shortName: "Israel",
  },
  IT: {
    name: "Italy (Italia)",
    iso2: "IT",
    dialCode: "39",
    priority: 0,
    areaCodes: null,
    shortName: "Italy",
  },
  JM: {
    name: "Jamaica",
    iso2: "JM",
    dialCode: "1",
    priority: 4,
    areaCodes: ["876", "658"],
    shortName: "Jamaica",
  },
  JP: {
    name: "Japan (日本)",
    iso2: "JP",
    dialCode: "81",
    priority: 0,
    areaCodes: null,
    shortName: "Japan",
  },
  JE: {
    name: "Jersey",
    iso2: "JE",
    dialCode: "44",
    priority: 4,
    areaCodes: null,
    shortName: "Jersey",
  },
  JO: {
    name: "Jordan (‫الأردن‬‎)",
    iso2: "JO",
    dialCode: "962",
    priority: 0,
    areaCodes: null,
    shortName: "Jordan",
  },
  KZ: {
    name: "Kazakhstan (Казахстан)",
    iso2: "KZ",
    dialCode: "7",
    priority: 1,
    areaCodes: null,
    shortName: "Kazakhstan",
  },
  KE: {
    name: "Kenya",
    iso2: "KE",
    dialCode: "254",
    priority: 0,
    areaCodes: null,
    shortName: "Kenya",
  },
  KI: {
    name: "Kiribati",
    iso2: "KI",
    dialCode: "686",
    priority: 0,
    areaCodes: null,
    shortName: "Kiribati",
  },
  XK: {
    name: "Kosovo",
    iso2: "XK",
    dialCode: "383",
    priority: 0,
    areaCodes: null,
    shortName: "Kosovo",
  },
  KW: {
    name: "Kuwait (‫الكويت‬‎)",
    iso2: "KW",
    dialCode: "965",
    priority: 0,
    areaCodes: null,
    shortName: "Kuwait",
  },
  KG: {
    name: "Kyrgyzstan (Кыргызстан)",
    iso2: "KG",
    dialCode: "996",
    priority: 0,
    areaCodes: null,
    shortName: "Kyrgyzstan",
  },
  LA: {
    name: "Laos (ລາວ)",
    iso2: "LA",
    dialCode: "856",
    priority: 0,
    areaCodes: null,
    shortName: "Laos",
  },
  LV: {
    name: "Latvia (Latvija)",
    iso2: "LV",
    dialCode: "371",
    priority: 0,
    areaCodes: null,
    shortName: "Latvia",
  },
  LB: {
    name: "Lebanon (‫لبنان‬‎)",
    iso2: "LB",
    dialCode: "961",
    priority: 0,
    areaCodes: null,
    shortName: "Lebanon",
  },
  LS: {
    name: "Lesotho",
    iso2: "LS",
    dialCode: "266",
    priority: 0,
    areaCodes: null,
    shortName: "Lesotho",
  },
  LR: {
    name: "Liberia",
    iso2: "LR",
    dialCode: "231",
    priority: 0,
    areaCodes: null,
    shortName: "Liberia",
  },
  LY: {
    name: "Libya (‫ليبيا‬‎)",
    iso2: "LY",
    dialCode: "218",
    priority: 0,
    areaCodes: null,
    shortName: "Libya",
  },
  LI: {
    name: "Liechtenstein",
    iso2: "LI",
    dialCode: "423",
    priority: 0,
    areaCodes: null,
    shortName: "Liechtenstein",
  },
  LT: {
    name: "Lithuania (Lietuva)",
    iso2: "LT",
    dialCode: "370",
    priority: 0,
    areaCodes: null,
    shortName: "Lithuania",
  },
  LU: {
    name: "Luxembourg",
    iso2: "LU",
    dialCode: "352",
    priority: 0,
    areaCodes: null,
    shortName: "Luxembourg",
  },
  MO: {
    name: "Macau (澳門)",
    iso2: "MO",
    dialCode: "853",
    priority: 0,
    areaCodes: null,
    shortName: "Macau",
  },
  MK: {
    name: "Macedonia (FYROM) (Македонија)",
    iso2: "MK",
    dialCode: "389",
    priority: 0,
    areaCodes: null,
    shortName: "Macedonia (FYROM)",
  },
  MG: {
    name: "Madagascar (Madagasikara)",
    iso2: "MG",
    dialCode: "261",
    priority: 0,
    areaCodes: null,
    shortName: "Madagascar",
  },
  MW: {
    name: "Malawi",
    iso2: "MW",
    dialCode: "265",
    priority: 0,
    areaCodes: null,
    shortName: "Malawi",
  },
  MY: {
    name: "Malaysia",
    iso2: "MY",
    dialCode: "60",
    priority: 0,
    areaCodes: null,
    shortName: "Malaysia",
  },
  MV: {
    name: "Maldives",
    iso2: "MV",
    dialCode: "960",
    priority: 0,
    areaCodes: null,
    shortName: "Maldives",
  },
  ML: {
    name: "Mali",
    iso2: "ML",
    dialCode: "223",
    priority: 0,
    areaCodes: null,
    shortName: "Mali",
  },
  MT: {
    name: "Malta",
    iso2: "MT",
    dialCode: "356",
    priority: 0,
    areaCodes: null,
    shortName: "Malta",
  },
  MH: {
    name: "Marshall Islands",
    iso2: "MH",
    dialCode: "692",
    priority: 0,
    areaCodes: null,
    shortName: "Marshall Islands",
  },
  MQ: {
    name: "Martinique",
    iso2: "MQ",
    dialCode: "596",
    priority: 0,
    areaCodes: null,
    shortName: "Martinique",
  },
  MR: {
    name: "Mauritania (‫موريتانيا‬‎)",
    iso2: "MR",
    dialCode: "222",
    priority: 0,
    areaCodes: null,
    shortName: "Mauritania",
  },
  MU: {
    name: "Mauritius (Moris)",
    iso2: "MU",
    dialCode: "230",
    priority: 0,
    areaCodes: null,
    shortName: "Mauritius",
  },
  YT: {
    name: "Mayotte",
    iso2: "YT",
    dialCode: "262",
    priority: 1,
    areaCodes: null,
    shortName: "Mayotte",
  },
  TF: {
    name: "French Southern Territories",
    iso2: "TF",
    dialCode: "262",
    priority: 2,
    areaCodes: null,
    shortName: "French Southern Territories",
  },
  MX: {
    name: "Mexico (México)",
    iso2: "MX",
    dialCode: "52",
    priority: 0,
    areaCodes: null,
    shortName: "Mexico",
  },
  FM: {
    name: "Micronesia",
    iso2: "FM",
    dialCode: "691",
    priority: 0,
    areaCodes: null,
    shortName: "Micronesia",
  },
  MD: {
    name: "Moldova (Republica Moldova)",
    iso2: "MD",
    dialCode: "373",
    priority: 0,
    areaCodes: null,
    shortName: "Moldova",
  },
  MC: {
    name: "Monaco",
    iso2: "MC",
    dialCode: "377",
    priority: 0,
    areaCodes: null,
    shortName: "Monaco",
  },
  MN: {
    name: "Mongolia (Монгол)",
    iso2: "MN",
    dialCode: "976",
    priority: 0,
    areaCodes: null,
    shortName: "Mongolia",
  },
  ME: {
    name: "Montenegro (Crna Gora)",
    iso2: "ME",
    dialCode: "382",
    priority: 0,
    areaCodes: null,
    shortName: "Montenegro",
  },
  MS: {
    name: "Montserrat",
    iso2: "MS",
    dialCode: "1664",
    priority: 0,
    areaCodes: null,
    shortName: "Montserrat",
  },
  MA: {
    name: "Morocco (‫المغرب‬‎)",
    iso2: "MA",
    dialCode: "212",
    priority: 0,
    areaCodes: null,
    shortName: "Morocco",
  },
  MZ: {
    name: "Mozambique (Moçambique)",
    iso2: "MZ",
    dialCode: "258",
    priority: 0,
    areaCodes: null,
    shortName: "Mozambique",
  },
  MM: {
    name: "Myanmar (Burma) (မြန်မာ)",
    iso2: "MM",
    dialCode: "95",
    priority: 0,
    areaCodes: null,
    shortName: "Myanmar (Burma)",
  },
  NA: {
    name: "Namibia (Namibië)",
    iso2: "NA",
    dialCode: "264",
    priority: 0,
    areaCodes: null,
    shortName: "Namibia",
  },
  NR: {
    name: "Nauru",
    iso2: "NR",
    dialCode: "674",
    priority: 0,
    areaCodes: null,
    shortName: "Nauru",
  },
  NP: {
    name: "Nepal (नेपाल)",
    iso2: "NP",
    dialCode: "977",
    priority: 0,
    areaCodes: null,
    shortName: "Nepal",
  },
  NL: {
    name: "Netherlands (Nederland)",
    iso2: "NL",
    dialCode: "31",
    priority: 0,
    areaCodes: null,
    shortName: "Netherlands",
  },
  NC: {
    name: "New Caledonia (Nouvelle-Calédonie)",
    iso2: "NC",
    dialCode: "687",
    priority: 0,
    areaCodes: null,
    shortName: "New Caledonia",
  },
  NZ: {
    name: "New Zealand",
    iso2: "NZ",
    dialCode: "64",
    priority: 0,
    areaCodes: null,
    shortName: "New",
  },
  PN: {
    name: "Pitcairn",
    iso2: "PN",
    dialCode: "64",
    priority: 1,
    areaCodes: null,
    shortName: "Pitcairn",
  },
  NI: {
    name: "Nicaragua",
    iso2: "NI",
    dialCode: "505",
    priority: 0,
    areaCodes: null,
    shortName: "Nicaragua",
  },
  NE: {
    name: "Niger (Nijar)",
    iso2: "NE",
    dialCode: "227",
    priority: 0,
    areaCodes: null,
    shortName: "Niger",
  },
  NG: {
    name: "Nigeria",
    iso2: "NG",
    dialCode: "234",
    priority: 0,
    areaCodes: null,
    shortName: "Nigeria",
  },
  NU: {
    name: "Niue",
    iso2: "NU",
    dialCode: "683",
    priority: 0,
    areaCodes: null,
    shortName: "Niue",
  },
  NF: {
    name: "Norfolk Island",
    iso2: "NF",
    dialCode: "672",
    priority: 0,
    areaCodes: null,
    shortName: "Norfolk Island",
  },
  HM: {
    name: "Heard and McDonald Islands",
    iso2: "HM",
    dialCode: "672",
    priority: 1,
    areaCodes: null,
    shortName: "Heard and McDonald Islands",
  },
  KP: {
    name: "North Korea (조선 민주주의 인민 공화국)",
    iso2: "KP",
    dialCode: "850",
    priority: 0,
    areaCodes: null,
    shortName: "North Korea",
  },
  MP: {
    name: "Northern Mariana Islands",
    iso2: "MP",
    dialCode: "1670",
    priority: 0,
    areaCodes: null,
    shortName: "Northern Mariana Islands",
  },
  NO: {
    name: "Norway (Norge)",
    iso2: "NO",
    dialCode: "47",
    priority: 0,
    areaCodes: null,
    shortName: "Norway",
  },
  OM: {
    name: "Oman (‫عُمان‬‎)",
    iso2: "OM",
    dialCode: "968",
    priority: 0,
    areaCodes: null,
    shortName: "Oman",
  },
  PK: {
    name: "Pakistan (‫پاکستان‬‎)",
    iso2: "PK",
    dialCode: "92",
    priority: 0,
    areaCodes: null,
    shortName: "Pakistan",
  },
  PW: {
    name: "Palau",
    iso2: "PW",
    dialCode: "680",
    priority: 0,
    areaCodes: null,
    shortName: "Palau",
  },
  PS: {
    name: "Palestine (‫فلسطين‬‎)",
    iso2: "PS",
    dialCode: "970",
    priority: 0,
    areaCodes: null,
    shortName: "Palestine",
  },
  PA: {
    name: "Panama (Panamá)",
    iso2: "PA",
    dialCode: "507",
    priority: 0,
    areaCodes: null,
    shortName: "Panama",
  },
  PG: {
    name: "Papua New Guinea",
    iso2: "PG",
    dialCode: "675",
    priority: 0,
    areaCodes: null,
    shortName: "Papua",
  },
  PY: {
    name: "Paraguay",
    iso2: "PY",
    dialCode: "595",
    priority: 0,
    areaCodes: null,
    shortName: "Paraguay",
  },
  PE: {
    name: "Peru (Perú)",
    iso2: "PE",
    dialCode: "51",
    priority: 0,
    areaCodes: null,
    shortName: "Peru",
  },
  PH: {
    name: "Philippines",
    iso2: "PH",
    dialCode: "63",
    priority: 0,
    areaCodes: null,
    shortName: "Philippines",
  },
  PL: {
    name: "Poland (Polska)",
    iso2: "PL",
    dialCode: "48",
    priority: 0,
    areaCodes: null,
    shortName: "Poland",
  },
  PT: {
    name: "Portugal",
    iso2: "PT",
    dialCode: "351",
    priority: 0,
    areaCodes: null,
    shortName: "Portugal",
  },
  PR: {
    name: "Puerto Rico",
    iso2: "PR",
    dialCode: "1",
    priority: 3,
    areaCodes: ["787", "939"],
    shortName: "Puerto Rico",
  },
  QA: {
    name: "Qatar (‫قطر‬‎)",
    iso2: "QA",
    dialCode: "974",
    priority: 0,
    areaCodes: null,
    shortName: "Qatar",
  },
  RE: {
    name: "Réunion (La Réunion)",
    iso2: "RE",
    dialCode: "262",
    priority: 0,
    areaCodes: null,
    shortName: "Réunion",
  },
  RO: {
    name: "Romania (România)",
    iso2: "RO",
    dialCode: "40",
    priority: 0,
    areaCodes: null,
    shortName: "Romania",
  },
  RU: {
    name: "Russia (Россия)",
    iso2: "RU",
    dialCode: "7",
    priority: 0,
    areaCodes: null,
    shortName: "Russia",
  },
  RW: {
    name: "Rwanda",
    iso2: "RW",
    dialCode: "250",
    priority: 0,
    areaCodes: null,
    shortName: "Rwanda",
  },
  BL: {
    name: "Saint Barthélemy",
    iso2: "BL",
    dialCode: "590",
    priority: 1,
    areaCodes: null,
    shortName: "Saint Barthélemy",
  },
  SH: {
    name: "Saint Helena",
    iso2: "SH",
    dialCode: "290",
    priority: 0,
    areaCodes: null,
    shortName: "Saint Helena",
  },
  KN: {
    name: "Saint Kitts and Nevis",
    iso2: "KN",
    dialCode: "1869",
    priority: 0,
    areaCodes: null,
    shortName: "Saint Kitts and Nevis",
  },
  LC: {
    name: "Saint Lucia",
    iso2: "LC",
    dialCode: "1758",
    priority: 0,
    areaCodes: null,
    shortName: "Saint Lucia",
  },
  MF: {
    name: "Saint Martin (Saint-Martin (partie française))",
    iso2: "MF",
    dialCode: "590",
    priority: 2,
    areaCodes: null,
    shortName: "Saint Martin",
  },
  PM: {
    name: "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
    iso2: "PM",
    dialCode: "508",
    priority: 0,
    areaCodes: null,
    shortName: "Saint Pierre and Miquelon",
  },
  VC: {
    name: "Saint Vincent and the Grenadines",
    iso2: "VC",
    dialCode: "1784",
    priority: 0,
    areaCodes: null,
    shortName: "Saint Vincent and the Grenadines",
  },
  WS: {
    name: "Samoa",
    iso2: "WS",
    dialCode: "685",
    priority: 0,
    areaCodes: null,
    shortName: "Samoa",
  },
  SM: {
    name: "San Marino",
    iso2: "SM",
    dialCode: "378",
    priority: 0,
    areaCodes: null,
    shortName: "San Marino",
  },
  ST: {
    name: "São Tomé and Príncipe (São Tomé e Príncipe)",
    iso2: "ST",
    dialCode: "239",
    priority: 0,
    areaCodes: null,
    shortName: "São Tomé and Príncipe",
  },
  SA: {
    name: "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
    iso2: "SA",
    dialCode: "966",
    priority: 0,
    areaCodes: null,
    shortName: "Saudi Arabia",
  },
  SN: {
    name: "Senegal (Sénégal)",
    iso2: "SN",
    dialCode: "221",
    priority: 0,
    areaCodes: null,
    shortName: "Senegal",
  },
  RS: {
    name: "Serbia (Србија)",
    iso2: "RS",
    dialCode: "381",
    priority: 0,
    areaCodes: null,
    shortName: "Serbia",
  },
  CS: {
    name: "Serbia and Montenegro",
    iso2: "CS",
    dialCode: "381",
    priority: 1,
    areaCodes: null,
    shortName: "Serbia and Montenegro",
  },
  SC: {
    name: "Seychelles",
    iso2: "SC",
    dialCode: "248",
    priority: 0,
    areaCodes: null,
    shortName: "Seychelles",
  },
  SL: {
    name: "Sierra Leone",
    iso2: "SL",
    dialCode: "232",
    priority: 0,
    areaCodes: null,
    shortName: "Sierra Leone",
  },
  SG: {
    name: "Singapore",
    iso2: "SG",
    dialCode: "65",
    priority: 0,
    areaCodes: null,
    shortName: "Singapore",
  },
  SX: {
    name: "Sint Maarten",
    iso2: "SX",
    dialCode: "1721",
    priority: 0,
    areaCodes: null,
    shortName: "Sint Maarten",
  },
  SK: {
    name: "Slovakia (Slovensko)",
    iso2: "SK",
    dialCode: "421",
    priority: 0,
    areaCodes: null,
    shortName: "Slovakia",
  },
  SI: {
    name: "Slovenia (Slovenija)",
    iso2: "SI",
    dialCode: "386",
    priority: 0,
    areaCodes: null,
    shortName: "Slovenia",
  },
  SB: {
    name: "Solomon Islands",
    iso2: "SB",
    dialCode: "677",
    priority: 0,
    areaCodes: null,
    shortName: "Solomon Islands",
  },
  SO: {
    name: "Somalia (Soomaaliya)",
    iso2: "SO",
    dialCode: "252",
    priority: 0,
    areaCodes: null,
    shortName: "Somalia",
  },
  ZA: {
    name: "South Africa",
    iso2: "ZA",
    dialCode: "27",
    priority: 0,
    areaCodes: null,
    shortName: "South Africa",
  },
  KR: {
    name: "South Korea (대한민국)",
    iso2: "KR",
    dialCode: "82",
    priority: 0,
    areaCodes: null,
    shortName: "South Korea",
  },
  SS: {
    name: "South Sudan (‫جنوب السودان‬‎)",
    iso2: "SS",
    dialCode: "211",
    priority: 0,
    areaCodes: null,
    shortName: "South Sudan",
  },
  ES: {
    name: "Spain (España)",
    iso2: "ES",
    dialCode: "34",
    priority: 0,
    areaCodes: null,
    shortName: "Spain",
  },
  LK: {
    name: "Sri Lanka (ශ්‍රී ලංකාව)",
    iso2: "LK",
    dialCode: "94",
    priority: 0,
    areaCodes: null,
    shortName: "Sri Lanka",
  },
  SD: {
    name: "Sudan (‫السودان‬‎)",
    iso2: "SD",
    dialCode: "249",
    priority: 0,
    areaCodes: null,
    shortName: "Sudan",
  },
  SR: {
    name: "Suriname",
    iso2: "SR",
    dialCode: "597",
    priority: 0,
    areaCodes: null,
    shortName: "Suriname",
  },
  SJ: {
    name: "Svalbard and Jan Mayen",
    iso2: "SJ",
    dialCode: "47",
    priority: 1,
    areaCodes: null,
    shortName: "Svalbard and Jan Mayen",
  },
  SZ: {
    name: "Swaziland",
    iso2: "SZ",
    dialCode: "268",
    priority: 0,
    areaCodes: null,
    shortName: "Swaziland",
  },
  SE: {
    name: "Sweden (Sverige)",
    iso2: "SE",
    dialCode: "46",
    priority: 0,
    areaCodes: null,
    shortName: "Sweden",
  },
  CH: {
    name: "Switzerland (Schweiz)",
    iso2: "CH",
    dialCode: "41",
    priority: 0,
    areaCodes: null,
    shortName: "Switzerland",
  },
  SY: {
    name: "Syria (‫سوريا‬‎)",
    iso2: "SY",
    dialCode: "963",
    priority: 0,
    areaCodes: null,
    shortName: "Syria",
  },
  TW: {
    name: "Taiwan (台灣)",
    iso2: "TW",
    dialCode: "886",
    priority: 0,
    areaCodes: null,
    shortName: "Taiwan",
  },
  TJ: {
    name: "Tajikistan",
    iso2: "TJ",
    dialCode: "992",
    priority: 0,
    areaCodes: null,
    shortName: "Tajikistan",
  },
  TZ: {
    name: "Tanzania",
    iso2: "TZ",
    dialCode: "255",
    priority: 0,
    areaCodes: null,
    shortName: "Tanzania",
  },
  TH: {
    name: "Thailand (ไทย)",
    iso2: "TH",
    dialCode: "66",
    priority: 0,
    areaCodes: null,
    shortName: "Thailand",
  },
  TL: {
    name: "Timor-Leste",
    iso2: "TL",
    dialCode: "670",
    priority: 0,
    areaCodes: null,
    shortName: "Timor-Leste",
  },
  TG: {
    name: "Togo",
    iso2: "TG",
    dialCode: "228",
    priority: 0,
    areaCodes: null,
    shortName: "Togo",
  },
  TK: {
    name: "Tokelau",
    iso2: "TK",
    dialCode: "690",
    priority: 0,
    areaCodes: null,
    shortName: "Tokelau",
  },
  TO: {
    name: "Tonga",
    iso2: "TO",
    dialCode: "676",
    priority: 0,
    areaCodes: null,
    shortName: "Tonga",
  },
  TT: {
    name: "Trinidad and Tobago",
    iso2: "TT",
    dialCode: "1868",
    priority: 0,
    areaCodes: null,
    shortName: "Trinidad and Tobago",
  },
  TN: {
    name: "Tunisia (‫تونس‬‎)",
    iso2: "TN",
    dialCode: "216",
    priority: 0,
    areaCodes: null,
    shortName: "Tunisia",
  },
  TR: {
    name: "Turkey (Türkiye)",
    iso2: "TR",
    dialCode: "90",
    priority: 0,
    areaCodes: null,
    shortName: "Turkey",
  },
  TM: {
    name: "Turkmenistan",
    iso2: "TM",
    dialCode: "993",
    priority: 0,
    areaCodes: null,
    shortName: "Turkmenistan",
  },
  TC: {
    name: "Turks and Caicos Islands",
    iso2: "TC",
    dialCode: "1649",
    priority: 0,
    areaCodes: null,
    shortName: "Turks and Caicos Islands",
  },
  TV: {
    name: "Tuvalu",
    iso2: "TV",
    dialCode: "688",
    priority: 0,
    areaCodes: null,
    shortName: "Tuvalu",
  },
  VI: {
    name: "U.S. Virgin Islands",
    iso2: "VI",
    dialCode: "1340",
    priority: 0,
    areaCodes: null,
    shortName: "U.S. Virgin Islands",
  },
  UG: {
    name: "Uganda",
    iso2: "UG",
    dialCode: "256",
    priority: 0,
    areaCodes: null,
    shortName: "Uganda",
  },
  UA: {
    name: "Ukraine (Україна)",
    iso2: "UA",
    dialCode: "380",
    priority: 0,
    areaCodes: null,
    shortName: "Ukraine",
  },
  AE: {
    name: "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
    iso2: "AE",
    dialCode: "971",
    priority: 0,
    areaCodes: null,
    shortName: "United Arab Emirates",
  },
  GB: {
    name: "United Kingdom",
    iso2: "GB",
    dialCode: "44",
    priority: 0,
    areaCodes: null,
    shortName: "United Kingdom",
  },
  ENG: {
    name: "England",
    iso2: "ENG",
    dialCode: "44",
    priority: 1,
    areaCodes: null,
    shortName: "England",
  },
  UY: {
    name: "Uruguay",
    iso2: "UY",
    dialCode: "598",
    priority: 0,
    areaCodes: null,
    shortName: "Uruguay",
  },
  UZ: {
    name: "Uzbekistan (Oʻzbekiston)",
    iso2: "UZ",
    dialCode: "998",
    priority: 0,
    areaCodes: null,
    shortName: "Uzbekistan",
  },
  VU: {
    name: "Vanuatu",
    iso2: "VU",
    dialCode: "678",
    priority: 0,
    areaCodes: null,
    shortName: "Vanuatu",
  },
  VA: {
    name: "Vatican City (Città del Vaticano)",
    iso2: "VA",
    dialCode: "39",
    priority: 1,
    areaCodes: null,
    shortName: "Vatican City",
  },
  VE: {
    name: "Venezuela",
    iso2: "VE",
    dialCode: "58",
    priority: 0,
    areaCodes: null,
    shortName: "Venezuela",
  },
  VN: {
    name: "Vietnam (Việt Nam)",
    iso2: "VN",
    dialCode: "84",
    priority: 0,
    areaCodes: null,
    shortName: "Vietnam",
  },
  WF: {
    name: "Wallis and Futuna (Wallis-et-Futuna)",
    iso2: "WF",
    dialCode: "681",
    priority: 0,
    areaCodes: null,
    shortName: "Wallis and Futuna",
  },
  EH: {
    name: "Western Sahara (‫الصحراء الغربية‬‎)",
    iso2: "EH",
    dialCode: "212",
    priority: 1,
    areaCodes: null,
    shortName: "Western Sahara",
  },
  YE: {
    name: "Yemen (‫اليمن‬‎)",
    iso2: "YE",
    dialCode: "967",
    priority: 0,
    areaCodes: null,
    shortName: "Yemen",
  },
  ZM: {
    name: "Zambia",
    iso2: "ZM",
    dialCode: "260",
    priority: 0,
    areaCodes: null,
    shortName: "Zambia",
  },
  ZW: {
    name: "Zimbabwe",
    iso2: "ZW",
    dialCode: "263",
    priority: 0,
    areaCodes: null,
    shortName: "Zimbabwe",
  },
  AX: {
    name: "Åland Islands",
    iso2: "AX",
    dialCode: "358",
    priority: 1,
    areaCodes: null,
    shortName: "Åland Islands",
  },
};
