import { sub, parse, isValid } from "date-fns";

import { formatDateWithLocale } from "BookingFlow/PlanYourStay/DateRangePicker/calendarHelpers";
import { DATA, DATE_WITH_TIME } from "./dateFormats";

export default ({
  checkInDate,
  termsAndConditions: { cancellationBy, cancellationDeadlineHours = 0 } = {},
  locale,
}) => {
  const deadline = cancellationBy
    ? parse(
        cancellationBy.replace(/T/i, " ").substring(0, 19),
        DATE_WITH_TIME,
        new Date()
      )
    : sub(parse(checkInDate, DATA, new Date()), {
        hours: cancellationDeadlineHours,
      });

  return isValid(deadline)
    ? [
        formatDateWithLocale("hh aaa", locale),
        formatDateWithLocale("MMMM dd, yyyy", locale),
      ].map((f) => f(deadline))
    : [];
};
