import { map, mergeMap, catchError } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import queryString from "query-string";

import contentAjax from "api/content/contentAjax";
import {
  fetchBookingMessagesFulfilled,
  fetchBookingMessagesFailed,
} from "store/bookingMessages/bookingMessages.slice";

export default function getBookingMessages$({
  locale = "en",
  propertyCode,
  bookingSteps: initialBookingSteps, // ['availability', 'soldout', 'confirmation']
  checkinDate,
  checkoutDate,
  ratePlanCode,
  retryInEN,
}) {
  const bookingSteps =
    typeof initialBookingSteps === "string"
      ? [initialBookingSteps]
      : initialBookingSteps;

  return forkJoin(
    bookingSteps.map((bookingStep) => {
      const stringified = queryString.stringify({
        bookingStep,
        checkinDate,
        checkoutDate,
        ratePlanCode,
      });

      return contentAjax({
        locale,
        url: `/properties/${propertyCode}/bookingmessages?${stringified}`,
        retryInEN,
      }).pipe(
        map((response) => {
          if (response.error) {
            return fetchBookingMessagesFailed({
              propertyCode,
              bookingStep,
              error: response.error,
            });
          }
          return fetchBookingMessagesFulfilled({
            propertyCode,
            bookingStep,
            bookingMessages: response,
          });
        }),
        catchError((error) =>
          of(
            fetchBookingMessagesFailed({
              propertyCode,
              bookingStep,
              error,
            })
          )
        )
      );
    })
  ).pipe(mergeMap((actions) => actions));
}
