import React from "react";

export const PersonIconHTMLString = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 3C12.4101 3 9.5 5.91015 9.5 9.5C9.5 13.0899 12.4101 16 16 16C19.5899 16 22.5 13.0899 22.5 9.5C22.5 5.91015 19.5899 3 16 3ZM16 4C19.0376 4 21.5 6.46243 21.5 9.5C21.5 12.5376 19.0376 15 16 15C12.9624 15 10.5 12.5376 10.5 9.5C10.5 6.46243 12.9624 4 16 4Z" fill="black"></path><path d="M26.9962 28.22C26.8407 22.5452 21.9719 18 16 18C10.0707 18 5.2289 22.4805 5.00788 28.0987C4.99918 28.3199 5.17945 28.5 5.40081 28.5C5.46595 28.5 5.53012 28.4841 5.58774 28.4537L6.00384 28.2344C6.15189 23.1158 10.5655 19 16 19C21.3525 19 25.7148 22.9926 25.9866 28.0036C26.0014 28.2774 26.2223 28.5 26.4966 28.5H26.72C26.874 28.5 26.9983 28.374 26.9962 28.22Z" fill="black"></path></svg>`;

export default function PersonIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3C12.4101 3 9.5 5.91015 9.5 9.5C9.5 13.0899 12.4101 16 16 16C19.5899 16 22.5 13.0899 22.5 9.5C22.5 5.91015 19.5899 3 16 3ZM16 4C19.0376 4 21.5 6.46243 21.5 9.5C21.5 12.5376 19.0376 15 16 15C12.9624 15 10.5 12.5376 10.5 9.5C10.5 6.46243 12.9624 4 16 4Z"
        fill="black"
      />
      <path
        d="M26.9962 28.22C26.8407 22.5452 21.9719 18 16 18C10.0707 18 5.2289 22.4805 5.00788 28.0987C4.99918 28.3199 5.17945 28.5 5.40081 28.5C5.46595 28.5 5.53012 28.4841 5.58774 28.4537L6.00384 28.2344C6.15189 23.1158 10.5655 19 16 19C21.3525 19 25.7148 22.9926 25.9866 28.0036C26.0014 28.2774 26.2223 28.5 26.4966 28.5H26.72C26.874 28.5 26.9983 28.374 26.9962 28.22Z"
        fill="black"
      />
    </svg>
  );
}
