import { ofType } from "redux-observable";
import { switchMap, takeUntil, mergeMap } from "rxjs/operators";

import { getPropertiesContent$ } from "api/content";
import {
  fetchPropertyContentFulfilled,
  fetchPropertyContentFailed,
  fetchPropertyContent,
  fetchPropertyContentCancel,
} from "store/propertyContent/propertyContent.slice";

const fetchPropertyContentEpic = (action$) =>
  action$.pipe(
    ofType(fetchPropertyContent.type),
    switchMap(({ payload: { locale = "en", propertyCode, propertyCodes } }) =>
      getPropertiesContent$({ locale, propertyCode, propertyCodes }).pipe(
        mergeMap((responses) => {
          return responses.map(({ ajaxResponse, hotelCode }) => {
            if (ajaxResponse.error) {
              return fetchPropertyContentFailed({
                error: ajaxResponse.error,
                propertyCode: hotelCode,
              });
            }
            return fetchPropertyContentFulfilled({
              propertyContent: ajaxResponse,
              propertyCode: hotelCode,
            });
          });
        }),
        takeUntil(action$.pipe(ofType(fetchPropertyContentCancel.type)))
      )
    )
  );

export default fetchPropertyContentEpic;
