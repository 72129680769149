import React from "react";

import {
  updateUserAddress,
  selectAddress,
  deleteUserAddress,
} from "store/profile";
import AddressForm from "./AddressForm";
import useFormSubmit from "../../../hooks/useFormSubmit";
import useDelete from "../../../hooks/useDelete";

export default function EditAddress({
  identifier,
  address,
  closeForm,
  canBeDeleted,
  isNotLast,
}) {
  const {
    onSubmit,
    register,
    showLoadingIndicator,
    formState,
    watched,
    value,
    errors,
    baseError,
  } = useFormSubmit({
    closeForm,
    action: updateUserAddress,
    selector: selectAddress(address.salesForceId),
    fieldName: "addressData",
  });

  const { onDelete, showLoadingIndicator: showLoadingIndicatorForDeleting } =
    useDelete({
      action: deleteUserAddress,
      salesForceId: value.salesForceId,
    });

  return (
    <AddressForm
      address={address}
      canBeDeleted={canBeDeleted}
      watched={watched}
      closeForm={closeForm}
      register={register}
      identifier={identifier}
      onSubmit={onSubmit}
      formState={formState}
      onDelete={onDelete}
      errors={errors}
      baseError={baseError}
      isNotLast={isNotLast}
      showLoadingIndicator={
        showLoadingIndicator || showLoadingIndicatorForDeleting
      }
    />
  );
}
