/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { stringifyObject } from "utils/utils";

const initialState = {
  data: {},
  error: "",
};

const termsAndConditionsSlice = createSlice({
  name: "termsAndConditions",
  initialState,
  reducers: {
    fetchTermsAndConditionsContent: (state) => {
      state.error = "";
    },
    fetchTermsAndConditionsContentFulfilled: (
      state,
      { payload: { hotelCode, termsAndConditions } }
    ) => {
      state.data[hotelCode] = termsAndConditions.reduce(
        (acc, [terms, options]) => ({
          ...acc,
          [stringifyObject(options)]: terms,
        }),
        state.data[hotelCode]
      );
      state.error = "";
    },
    fetchTermsAndConditionsContentFailed: (state, { payload }) => {
      state.data = {};
      state.error = payload.error;
    },
    fetchTermsAndConditionsContentCancel: () => {},
    clearTermsAndConditionsContent: () => initialState,
  },
});

export const {
  fetchTermsAndConditionsContent,
  fetchTermsAndConditionsContentCancel,
  fetchTermsAndConditionsContentFailed,
  fetchTermsAndConditionsContentFulfilled,
  clearTermsAndConditionsContent,
} = termsAndConditionsSlice.actions;

export default termsAndConditionsSlice.reducer;
