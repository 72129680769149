import React, { useState } from "react";
import { useTranslation } from "hooks";
import { useForm, Controller } from "react-hook-form";

import * as regexPatterns from "utils/regexPatterns";
import { LOGIN_TYPES } from "api/tretail/authentication/generateOTP";
import { Button, Checkbox } from "Profile/components";
import classNames from "classnames";
import OtpInput from "../OtpInput";
import IntroContent from "./IntroContent";

export default function SubmitOtpForm({
  sendCodeVia,
  sendCodeTo,
  messageType,
  onSubmit,
  reset,
  employeeMode,
  isYachtsMode,
  isUserPersistent,
  disablePersistentLoginOption = false,
}) {
  const { t } = useTranslation();
  const { register, control, handleSubmit, formState } = useForm({
    mode: "onChange",
    defaultValues: {
      otp: "",
      persist: false,
    },
  });
  const { isValid } = formState;
  const [showDetails, setShowDetails] = useState(false);
  const disablePersistentLogin =
    disablePersistentLoginOption || isYachtsMode || employeeMode;
  const showPersistentOption = !disablePersistentLogin && !isUserPersistent;

  const handleClick = () => {
    setShowDetails(!showDetails);
  };

  return (
    <div className="fs-otp-sign-in__submit-otp">
      <div className="fs-otp-sign-in__submit-otp__content">
        <h1>{t("Verification Code")}</h1>

        <p className="intro">
          <IntroContent
            messageType={
              messageType === "short"
                ? messageType
                : sendCodeVia === LOGIN_TYPES.EMAIL_ADDRESS
                ? "email"
                : "SMS"
            }
            sendCodeTo={sendCodeTo}
            employeeMode={employeeMode}
            isYachtsMode={isYachtsMode}
            isUserPersistent={isUserPersistent}
          />
        </p>

        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
          <Controller
            control={control}
            name="otp"
            rules={{
              required: true,
              minLength: 6,
              maxLength: 6,
            }}
            render={({ ref: _ignoredRef, ...renderProps }) => (
              <OtpInput
                pattern={regexPatterns.onlyDigits}
                {...renderProps}
                showPersistentOption={showPersistentOption}
              />
            )}
          />

          {showPersistentOption && (
            <>
              <div
                className={classNames("view__actions persistent-login", {
                  showDetails,
                })}
              >
                <Checkbox name="persist" register={register}>
                  {t("Keep me signed in")}
                </Checkbox>
                <div className="persistent-login-details-button">
                  <Button kind="link" onClick={handleClick}>
                    {t("Details")}
                  </Button>
                </div>
              </div>
              {showDetails && (
                <div className="persistent-login-details-text-container">
                  <div className="persistent-login-details-text">
                    <p>
                      {t(
                        "This option will reduce the frequency of being asked to sign-in on this device. Use this option only on your trusted devices to keep your account secure."
                      )}
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
          {!disablePersistentLogin && isUserPersistent && (
            <input type="hidden" name="persist" ref={register()} value />
          )}

          <div
            className={classNames("view__actions", {
              "persistent-login-next": isUserPersistent,
            })}
          >
            <Button type="submit" disabled={!isValid}>
              {t("Next")}
            </Button>
          </div>
        </form>
      </div>

      <footer
        className={classNames("view__footer", {
          "persistent-login-footer": showPersistentOption,
        })}
      >
        <Button kind="link" onClick={reset}>
          {t("I didn’t receive my code")}
        </Button>
      </footer>
    </div>
  );
}
