import env from "config/env";

const { EMPLOYEE_RATES } = env;

function isEmployeeRate({ promoCode } = {}) {
  if (!promoCode || !EMPLOYEE_RATES) {
    return false;
  }

  return EMPLOYEE_RATES.rateDetails.some((rateCodeDetails) =>
    rateCodeDetails.rateCodes.includes(promoCode)
  );
}

export default isEmployeeRate;
