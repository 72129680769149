import React, { useState } from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";
import { pluralize } from "utils/utils";
import { PENDING_STATES, CONFIRMED_STATES } from "../getItineraryItems";
import ActivityDetailsModal from "./ActivityDetailsModal";
import ChevronRightIcon from "./ChevronRightIcon";

export default function ActivityCard({
  hotelCode,
  formattedDate,
  formattedTime,
  itineraryItem,
  isWebChatEnabled,
  openWebChat,
}) {
  const { t, formatPrice } = useTranslation();

  const {
    id,
    productName,
    requestSubtype,
    numberOfGuests = 0,
    numberOfAdults = 0,
    numberOfChildren = 0,
    status,
    duration,
    currencyCode,
    totalCost,
    vendorNameOnItinerary,
  } = itineraryItem;

  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const isTransportation = [
    "Arrival Transportation",
    "Car Rental",
    "Departure Transportation",
    "Golf Cart",
    "House Car",
    "Shuttle",
    "As Directed Transportation",
    "Helicopter & Private Jet Charter",
  ].includes(requestSubtype);

  const isCarRental = requestSubtype === "Car Rental";

  const guestsContent =
    numberOfGuests > 0 && pluralize(t, numberOfGuests, "Guest");

  function getDisplayCost() {
    if (totalCost === 0) {
      return t("Complimentary");
    }
    if (totalCost) {
      return formatPrice({
        currency: currencyCode || "USD",
        value: totalCost,
      });
    }
    return null;
  }

  const displayedCost = getDisplayCost();

  const displayedDuration = !isCarRental && duration;

  const guestsAndDurationContent = [
    guestsContent,
    displayedCost,
    displayedDuration,
  ]
    .filter(Boolean)
    .join(" / ");

  const isPending = PENDING_STATES.includes(status);
  const isConfirmed = CONFIRMED_STATES.includes(status);

  const title = [
    (!isTransportation && productName) || requestSubtype,
    !isTransportation && vendorNameOnItinerary,
  ]
    .filter(Boolean)
    .join(" - ");

  const itineraryItemContent = (
    <>
      <div className="itinerary__item__header">
        <p className="itinerary__item__title">{title}</p>

        {guestsAndDurationContent && (
          <div className="itinerary__item__status-row">
            <span className="itinerary__item__guests">
              {guestsAndDurationContent}
            </span>
          </div>
        )}

        {(isPending || isConfirmed) && (
          <div
            className={classNames("itinerary__item__status", {
              "itinerary__item__status--is-pending": isPending,
              "itinerary__item__status--is-confirmed": isConfirmed,
            })}
          >
            {isPending && t("Pending")}
            {isConfirmed && t("Confirmed")}
          </div>
        )}
      </div>
    </>
  );

  return (
    <>
      {showDetailsModal && (
        <ActivityDetailsModal
          hotelCode={hotelCode}
          itineraryItem={itineraryItem}
          status={
            (isPending && t("Pending")) || (isConfirmed && t("Confirmed"))
          }
          numberOfGuests={numberOfGuests}
          numberOfAdults={numberOfAdults}
          numberOfChildren={numberOfChildren}
          displayedCost={displayedCost}
          displayedDuration={displayedDuration}
          formattedDate={formattedDate}
          formattedTime={formattedTime}
          close={() => setShowDetailsModal(false)}
          isWebChatEnabled={isWebChatEnabled}
          openWebChat={openWebChat}
        />
      )}

      <button
        type="button"
        onClick={() => setShowDetailsModal(true)}
        className="itinerary__item"
        id={id}
      >
        <div className="itinerary__item">{itineraryItemContent}</div>
        <div className="icon-container">
          <ChevronRightIcon />
        </div>
      </button>
    </>
  );
}
