import React, { useContext } from "react";

import { useTranslation } from "hooks";
import { MediaContext } from "contexts/MediaContext";
import Modal from "BookingFlow/components/Modal";

export default function AlipayModal({
  close,
  alipayInfo,
  alipaySubmitHandler,
}) {
  const { t } = useTranslation();
  const media = useContext(MediaContext);

  return (
    <Modal
      closeModal={close}
      title={alipayInfo.confirmation.title}
      className="new-modal--alipay"
    >
      <div className="alipay-modal-content-wrapper">
        <div
          className="aliay-modal-content"
          dangerouslySetInnerHTML={{
            __html: t(alipayInfo.confirmation.message),
          }}
        />
        <div className="center-text">
          <button
            type="button"
            onClick={close}
            className="btn btn-secondary alipay-modal-button"
          >
            {t(alipayInfo.confirmation.cancelText)}
          </button>

          <button
            type="button"
            onClick={() => alipaySubmitHandler(media.isGreaterThan.sm)}
            className="btn btn-secondary alipay-modal-button"
          >
            {t(alipayInfo.confirmation.confirmText)}
          </button>
        </div>
      </div>
    </Modal>
  );
}
