import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import { retrieveBooking as retrieveBooking$ } from "store/bookings/epics/fetchHistoricBooking";
import catchInternalServerError from "store/catchInternalServerError";
import {
  retrieveBooking,
  fetchHistoricBookingFailed,
  fetchHistoricBookingFulfilled,
} from "../bookings.slice";

export default function retrieveBookingEpic(action$) {
  return action$.pipe(
    ofType(retrieveBooking.type),
    switchMap(({ payload }) => {
      return retrieveBooking$(payload).pipe(
        map((response = {}) =>
          response?.errorCode
            ? fetchHistoricBookingFailed(response.message)
            : fetchHistoricBookingFulfilled(response)
        ),

        catchInternalServerError(),

        catchError(({ response = {} }) => {
          console.log("Error retrieving booking", response);
          return of(
            fetchHistoricBookingFailed({
              reservationId: payload.reservationId,
              apiErrors: response?.apiErrors || [],
              supplierErrors: response?.supplierErrors || [],
            })
          );
        })
      );
    })
  );
}
