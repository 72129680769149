import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useDispatchWithLocale, useTranslation } from "hooks";
import { getGeoIPCode } from "utils/geoIP";
import { MediaContext } from "contexts/MediaContext";
import { selectIsRequestLoading } from "store/apiRequestStates";
import { setCurrencyCode } from "store/exchangeRates";
import { selectGlobalSettings } from "store/globalSettings";
import {
  addPackage,
  addPackageCancel,
  changeRoomEvt,
  clearDeepLinkErrors,
  selectDeepLinkError,
  viewPackagesEvt,
  viewNextRoomEvt,
} from "store/bookings";
import {
  dismissMissingLanguage,
  fetchSearchResults,
  fetchSearchResultsCancel,
  selectHasSearchResults,
  selectIsAllInPricingResults,
  selectMissingLanguageError,
  fetchSearchResultsForResultSetId,
  selectResultSetId,
  selectFlexDates,
  changeSearch,
} from "store/searchResults";
import {
  selectPropertyContent,
  selectPropertyCurrency,
} from "store/propertyContent";
import { getInitialAllInPricing } from "utils";
import env from "config/env";
import { isEmployeeRate } from "BookingFlow/utils";
import { STEP_2_SUB_STEPS as SUB_STEPS } from "fixtures/constants";
import { setParams } from "store/webChat";
import getInitialRoomsSorting from "utils/getInitialRoomsSorting";
import { selectAccommodationByRoomTypeIdMap } from "store/accommodationsContent";
import { selectIsUserLoggedIn } from "store/profile";
import { selectRoomsData } from "./selectors";
import {
  allInPricingToggleInteraction,
  flexDateSelected,
} from "./chooseYourRoom.actions";

const { TRIPTEASE_ENABLED } = env;

const removeHeadFromList = (list) => {
  const [_, ...remainingElements] = list;
  return remainingElements;
};

export default function useChooseYourRoom({ searchParams }) {
  const { locale } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();
  const media = useContext(MediaContext);

  const {
    hotelCode,
    promoCode,
    ppMode: isPreferredPartners = false,
    ppUrl: ppReturnUrl,
    rooms,
    dates,
  } = searchParams;
  const propertyCode = hotelCode;

  const propertyCurrencyCode = useSelector(selectPropertyCurrency(hotelCode));
  const [currencyCode, setCurrencyCode_] = useState(
    propertyCurrencyCode || "USD"
  );

  const setCurrencyCodeAndDispatchAction = (value) => {
    setCurrencyCode_(value);
    dispatchWithLocale(setCurrencyCode({ currencyCode: value }));
  };

  const employeeMode = isEmployeeRate(searchParams);

  const propertyContent = useSelector(selectPropertyContent(hotelCode));

  const [
    hasInteractedWithAccessiblityOption,
    setHasInteractedWithAccessiblityOption,
  ] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [roomSelections, setRoomSelections] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const selectedPackageOrsCodes = selectedPackages.map(
    (selectedPackage) => selectedPackage.orsCode
  );
  const [subStep, setSubStep] = useState(SUB_STEPS.TWO_A);
  const [isAccessible, setIsAccessible] = useState(false);
  const [sortByHighestToLowestRate, setSortByHighestToLowestRate] = useState(
    getInitialRoomsSorting(propertyContent)
  );

  const isFlexDates = Boolean(searchParams?.flexDatesWindow);
  const flexDates = useSelector(
    selectFlexDates({
      currencyCode,
      isAccessible,
      enCountry: propertyContent?.enCountry || "",
      optInUsAccessibleRooms: propertyContent?.optInUsAccessibleRooms,
    })
  );

  const accommodationByRoomTypeIdMap = useSelector(
    selectAccommodationByRoomTypeIdMap(hotelCode)
  );

  const setSubStepAndScrollToTop = (step) => {
    setSubStep(step);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  const isLoadingSearchResults = useSelector(
    selectIsRequestLoading(fetchSearchResults.type)
  );
  const isLoadingFlexDateSearchResults = useSelector(
    (state) => state.searchResults.isLoading
  );
  const isLoadingAddPackage = useSelector(
    selectIsRequestLoading(addPackage.type)
  );
  const hasSearchResults = useSelector(selectHasSearchResults);
  const resultSetId = useSelector(selectResultSetId);
  const isAllInPricingResults = useSelector(selectIsAllInPricingResults);
  const missingLanguageError = useSelector(selectMissingLanguageError);
  const isSignedIn = useSelector(selectIsUserLoggedIn);

  const { allInPricingCountries = [] } =
    useSelector(selectGlobalSettings) || {};

  const geoIpCountryCode = getGeoIPCode();
  const willForceAllInPricing = searchParams.allInP === true;
  const willRenderAllInPricing =
    willForceAllInPricing || (!employeeMode && !isPreferredPartners);
  const [isAllInPricing, setIsAllInPricingState] = useState(
    willRenderAllInPricing &&
      getInitialAllInPricing({
        propertyOptIn: propertyContent?.allInPricingOptIn,
        optedInCountries: allInPricingCountries,
        countryCodeFromGeoIPCookie: geoIpCountryCode,
        willForceAllInPricing,
      })
  );

  const setIsAllInPricing = (nextIsAllInPricing) => {
    dispatchWithLocale(
      allInPricingToggleInteraction({ isAllInPricing: nextIsAllInPricing })
    );
    setIsAllInPricingState(nextIsAllInPricing);
  };
  // Expose "all-in pricing" flag to front end
  window.FS = window.FS || {};
  window.FS.propertyInfo = window.FS.propertyInfo || {};
  window.FS.propertyInfo.allInPricing = propertyContent?.allInPricingOptIn;

  const selectedFlexDate =
    flexDates.find((flexDate) => flexDate.resultSetId === resultSetId) || {};

  const propertyUseGlobalNumber = !!propertyContent?.useGlobalNumber;

  const {
    bookDirectOffers,
    availabilityErrors,
    bookingMessages = [],
    searchResults = [],
    contactUs,
    disclaimer,
    error,
    propertyName,
    tollFreeNumbers,
    webChat,
  } = useSelector(
    selectRoomsData({
      ...searchParams,
      dates: {
        checkIn: selectedFlexDate?.checkinDate || searchParams.dates.checkIn,
        checkOut: selectedFlexDate?.checkoutDate || searchParams.dates.checkOut,
      },
      currencyCode,
      locale,
      geoIpCountryCode,
      sortByHighestToLowestRate,
      roomSelections,
      selectedPackages,
      isAccessible,
      hasInteractedWithAccessiblityOption,
    })
  );

  const deepLinkError = useSelector(selectDeepLinkError);

  useEffect(() => {
    dispatchWithLocale(setParams(webChat));
  }, [JSON.stringify(webChat)]);

  const handleAddPackage = (packageToAdd, asMember = false) => {
    const updatedSelectedPackages = [
      ...selectedPackages,
      { ...packageToAdd, isAccessible },
    ];

    // Package ORS codes for analytics
    const updatedSelectedPackageOrsCodes = [
      ...selectedPackageOrsCodes,
      packageToAdd?.orsCode || "",
    ];

    if (rooms.length === updatedSelectedPackages.length) {
      dispatchWithLocale(
        addPackage({
          resultSetId,
          selectedPackages: updatedSelectedPackages,
          searchParams,
          asMember,
          isSignedIn,
        })
      );
    } else {
      setSelectedPackages(updatedSelectedPackages);
      setSubStepAndScrollToTop(SUB_STEPS.TWO_A);
      dispatchWithLocale(
        viewNextRoomEvt({
          searchResults,
          roomSelections,
          updatedSelectedPackages,
          selectedPackageOrsCodes: updatedSelectedPackageOrsCodes,
        })
      );
    }
  };

  const addRoom = ({ owsCode, offers }) => {
    const roomCodes = [owsCode, ...roomSelections];
    setRoomSelections(roomCodes);
    if (employeeMode && offers?.length === 1) {
      handleAddPackage({
        ...offers[0],
        isAccessible,
      });
    } else {
      setSubStepAndScrollToTop(SUB_STEPS.TWO_B);
    }
    dispatchWithLocale(
      viewPackagesEvt({
        searchResults,
        roomCodes,
        selectedPackages,
        selectedPackageOrsCodes,
        searchParams,
        selectedFlexDate,
        isAccessible,
      })
    );
    dispatchWithLocale(clearDeepLinkErrors());
  };

  const removeRoom = () => {
    setSubStepAndScrollToTop(SUB_STEPS.TWO_A);
    setRoomSelections(removeHeadFromList(roomSelections));
    dispatchWithLocale(changeRoomEvt());
  };

  const removeAllRooms = () => {
    setSubStepAndScrollToTop(SUB_STEPS.TWO_A);
    setRoomSelections([]);
    setSelectedPackages([]);
  };

  const isSelectedRoom = ({ owsCode = "" }) => owsCode === roomSelections[0];

  const lastSelectedRoom = searchResults
    .flatMap(({ rooms: a = [] }) => a)
    .reduce(
      (acc, { bedTypes = [], name: roomName, ...rest }) =>
        bedTypes.some(isSelectedRoom)
          ? { roomName, bedType: bedTypes.find(isSelectedRoom), ...rest }
          : acc,
      { offers: [] }
    );

  const propertyTripTeaseHotelKey = propertyContent?.tripteaseAPIKey;

  const willRenderTripTease =
    TRIPTEASE_ENABLED &&
    locale === "en" &&
    !isFlexDates &&
    !promoCode &&
    propertyContent?.tripteaseEnabled &&
    propertyTripTeaseHotelKey &&
    Boolean(currencyCode) &&
    !media.isLessThan.sm &&
    subStep === SUB_STEPS.TWO_A &&
    rooms.length === 1 &&
    !isAllInPricing &&
    !isPreferredPartners &&
    !employeeMode;

  const plainSearchParams = useLocation().search;

  useEffect(() => {
    dispatchWithLocale(
      fetchSearchResults({
        plainSearchParams,
        searchParams,
        isAllInPricing,
        refetch: !isFirstRender && hasSearchResults,
      })
    );

    return () => {
      dispatchWithLocale(fetchSearchResultsCancel());
    };
  }, [JSON.stringify(searchParams), isAllInPricing, locale]);

  useEffect(() => () => dispatchWithLocale(addPackageCancel()), []);

  const dispatchChangeSearch = () => {
    dispatchWithLocale(changeSearch({ employeeMode }));
  };

  const dispatchFetchSearchResultsForResultSetId = ({
    resultSetId: nextResultSetId,
    checkIn,
    checkOut,
  }) => {
    if (
      checkIn !== searchParams.dates.checkIn ||
      checkOut !== searchParams.dates.checkOut
    ) {
      dispatchWithLocale(flexDateSelected());
    }
    dispatchWithLocale(
      fetchSearchResultsForResultSetId({
        resultSetId: nextResultSetId,
        plainSearchParams,
        searchParams: {
          ...searchParams,
          dates: {
            checkIn,
            checkOut,
          },
        },
        isAllInPricing,
        refetch: false,
      })
    );
  };

  const currentRoomIndex = selectedPackages.length;

  const removeRoomsFromIndex = (index) => {
    setSubStepAndScrollToTop(SUB_STEPS.TWO_A);
    setRoomSelections(roomSelections.slice(0, index));
    setSelectedPackages(selectedPackages.slice(0, index));
  };

  const removePackagesFromIndex = (index) => {
    setSubStepAndScrollToTop(SUB_STEPS.TWO_B);
    setRoomSelections(roomSelections.slice(0, index + 1));
    setSelectedPackages(selectedPackages.slice(0, index));
  };

  const roomsForRoomsSelection = rooms.map((room, index) => {
    const roomTypeId = roomSelections[index];
    const selectedPackage = selectedPackages[index];

    return {
      index,
      roomNumber: index + 1,
      adults: room.adults,
      children: room.children,
      roomTypeId,
      roomName:
        roomTypeId && (accommodationByRoomTypeIdMap[roomTypeId] || {})?.title,
      packageName: selectedPackage?.title || selectedPackage?.owsCode || "",
      isSelected: index === currentRoomIndex,
      selectedIndex: currentRoomIndex,
      onEditRoomClick: () => removeRoomsFromIndex(index),
      onEditPackageClick: () => removePackagesFromIndex(index),
    };
  });

  return {
    availabilityErrors,
    deepLinkError,

    hasSearchResults,
    isLoadingSearchResults: !hasSearchResults,
    isReLoadingSearchResults: isLoadingSearchResults && hasSearchResults,
    isLoadingFlexDateSearchResults:
      hasSearchResults && isLoadingFlexDateSearchResults,

    isAccessible,
    isAllInPricingResults, // retrieved search results are "all in pricing" or not, used to label beneath price
    isAllInPricing, // that Boolean value for the UI toggle
    setIsAccessible: (newValue) => {
      if (!hasInteractedWithAccessiblityOption) {
        setHasInteractedWithAccessiblityOption(true);
      }
      setIsAccessible(newValue);
    },
    setIsAllInPricing,
    setSortByHighestToLowestRate,
    sortByHighestToLowestRate,

    isLoadingAddPackage,
    handleAddPackage,

    bookDirectOffers,
    propertyUseGlobalNumber,
    bookingMessages,
    contactUs,
    currencyCode,
    setCurrencyCode: setCurrencyCodeAndDispatchAction,
    disclaimer,
    propertyName,
    searchResults,
    tollFreeNumbers,

    isPreferredPartners,
    ppReturnUrl,
    employeeMode,

    addRoom,
    currentRoomIndex,
    lastSelectedRoom,
    promoCode,
    removeRoom,
    removeAllRooms,
    willRenderAllInPricing,
    willRenderPackages: subStep === SUB_STEPS.TWO_B,
    willRenderSearchResults: subStep === SUB_STEPS.TWO_A,
    willRenderTripTease,

    locale,
    error,
    hotelCode,
    propertyCode,
    propertyTripTeaseHotelKey,
    dates,
    missingLanguageError,
    additionalWebChatInfo: webChat,
    dismissMissingLanguageError: () =>
      dispatchWithLocale(dismissMissingLanguage),

    isFlexDates,
    flexDates,
    resultSetId,
    dispatchChangeSearch,
    dispatchFetchSearchResultsForResultSetId,
    roomsForRoomsSelection,
    selectedPackages,
  };
}
