import React, { useState } from "react";

function ChevronDownIcon() {
  return (
    <svg
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.502197L8 8.5022L16 0.502197H14.4L8 6.9022L1.6 0.502197H0Z"
        fill="black"
      />
    </svg>
  );
}

function ChevronUpIcon() {
  return (
    <svg
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8.50214L8 0.502136L0 8.50214H1.6L8 2.10214L14.4 8.50214H16Z"
        fill="black"
      />
    </svg>
  );
}

export default function ToggleBlock({
  title,
  showDetailsInitially = false,
  fieldSetIsOptionalText,
  isFieldsetRequired,

  groupControlledToggle: externalIsOpen,
  groupController: externalToggle,

  children,
}) {
  const [internalIsOpen, setInternalIsOpen] = useState(showDetailsInitially);

  const isOpen =
    typeof externalIsOpen === "boolean" ? externalIsOpen : internalIsOpen;

  const toggle = () => (externalToggle || setInternalIsOpen)(!isOpen);

  return (
    <div className="transportation-amenities-toggle-block">
      <button
        type="button"
        onClick={toggle}
        className="transportation-amenities-toggle-block__container"
      >
        <div className="transportation-amenities-toggle-block__condensed-item">
          <div>
            {typeof title === "string" ? (
              <h4 className="ty-c1">
                {title}
                {fieldSetIsOptionalText && (
                  <span
                    className="ty-c4
                      transportation-amenities-toggle-block__optional-text"
                  >
                    {fieldSetIsOptionalText}
                  </span>
                )}
                {isFieldsetRequired && <span>*</span>}
              </h4>
            ) : (
              title
            )}
          </div>

          {isOpen ? (
            <div className="transportation-amenities-toggle-block__chevron-icon">
              <ChevronUpIcon />
            </div>
          ) : (
            <div className="transportation-amenities-toggle-block__chevron-icon">
              <ChevronDownIcon />
            </div>
          )}
        </div>
      </button>

      {isOpen && <div>{children}</div>}
    </div>
  );
}
