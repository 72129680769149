import React, { useEffect, useState } from "react";
import noop from "lodash/fp/noop";

import env from "config/env";
import { bookingFlowRoutes } from "BookingFlow";
import {
  ErrorMessage,
  BookingFlowLoadingIndicator,
} from "BookingFlow/components";
import BookingFlowLayout from "../BookingFlowLayout";
import CancellationModal from "./components/CancellationModal";
import PreferencesForm from "./components/PreferencesForm";
import PreferredPartnerEmailForm from "./components/PreferredPartnerEmailForm";
import usePersonaliseYourStay from "./hooks/usePersonaliseYourStay";
import useTripTeaseConfirmation from "./hooks/useTripTeaseConfirmation";

const {
  PREFERRED_PARTNER_DOMAIN,
  BOOKING_SURVEY_INTERCEPT_ID,
  BOOKING_SURVEY_LINK,
} = env;

function PersonalizeYourStayView() {
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const openCancellationModal = () => setShowCancellationModal(true);
  const closeCancellationModal = () => setShowCancellationModal(false);

  const [qualtricsIntercept, setQualtricsIntercept] = useState({
    passed: false,
    createSurveyLink: noop,
  });
  const [showBookingSurvey, setShowBookingSurvey] = useState(false);

  const amountInCAD = (completedBooking) => {
    try {
      const usdAmount = completedBooking?.price?.total?.cash?.usdAmount;
      const exgRate = completedBooking?.exchangeRates?.find(
        (rate) => rate.code === "CAD"
      )?.rate;
      return parseFloat((usdAmount * exgRate).toFixed(2));
    } catch {
      return false;
    }
  };

  useEffect(() => {
    if (!BOOKING_SURVEY_INTERCEPT_ID) {
      return;
    }

    localStorage.removeItem("starRating");

    window?.FS?.Qualtrics?.hasInterceptPassed(BOOKING_SURVEY_INTERCEPT_ID)
      .then((intercept) => {
        if (intercept.passed) {
          setShowBookingSurvey(intercept.passed);
          setQualtricsIntercept(intercept);
          window?.FS?.DECISION_ENGINE?.getState();
        }
      })
      .catch((err) => {
        console.log("Failed to get booking survey", err);
      });
  }, []);

  const {
    bookingError,
    bookingType,
    cancellation,
    completedBooking,
    confirmationSummary,
    contactName,
    dictionaryKeys,
    email,
    employeeMode,
    goToRegisterWithReservation,
    initialArrivalTime,
    isBookingCancelled,
    isBookingCancelledFailed,
    isCancellingBooking,
    isPreferredPartners,
    isUserLoggedIn,
    goldenId,
    phoneNumber,
    postBookingComments,
    ppDetails,
    ppReturnUrl = PREFERRED_PARTNER_DOMAIN,
    property,
    propertyStatusLink,
    propertyTravelAdvisoryLink,
    rooms,
    showAppDownload,
    submitConfirmationTool,
    tollFreeNumbers,
    contactUs,
    downloadMobileAppUrl,
    reservationId,
    sendGuestEmail,
  } = usePersonaliseYourStay();
  const hasBookingError = Boolean(bookingError);

  useTripTeaseConfirmation({
    completedBooking,
  });

  useEffect(() => {
    if (hasBookingError) {
      window.scrollTo(0, 0);
    }
  }, [hasBookingError]);

  return (
    <BookingFlowLayout
      pageTitle={bookingFlowRoutes.personalizeYourStay.getPageTitle({
        isPreferredPartners,
      })}
      htmlBodyClassName={
        bookingFlowRoutes.personalizeYourStay.htmlBodyClassName
      }
      isPreferredPartners={isPreferredPartners}
      ppReturnUrl={ppReturnUrl}
      contactUs={contactUs}
      employeeMode={employeeMode}
    >
      <div className="main-inner">
        {bookingError && (
          <ErrorMessage
            contactReservations={bookingError.contactReservations}
            descriptionDictKey={bookingError.descriptionDictKey}
            field={bookingError.field}
            labelDictKey={bookingError.labelDictKey}
            messageDictKey={bookingError.messageDictKey}
            propertyInfo={bookingError.propertyInfo}
          />
        )}

        <div className="confirmation-personalise">
          <PreferencesForm
            completedBooking={completedBooking}
            bookingType={bookingType}
            postBookingComments={postBookingComments}
            initialArrivalTime={initialArrivalTime}
            phoneNumber={phoneNumber}
            hasBookingError={hasBookingError}
            openCancellationModal={openCancellationModal}
            dictionaryKeys={dictionaryKeys}
            rooms={rooms}
            showAppDownload={showAppDownload}
            tollFreeNumbers={tollFreeNumbers}
            contactUs={contactUs}
            property={property}
            isPreferredPartners={isPreferredPartners}
            submitConfirmationTool={submitConfirmationTool}
            isUserLoggedIn={isUserLoggedIn}
            email={email}
            goToRegisterWithReservation={goToRegisterWithReservation}
            confirmationSummary={confirmationSummary}
            contactName={contactName}
            cancellation={cancellation}
            isBookingCancelled={isBookingCancelled}
            downloadMobileAppUrl={downloadMobileAppUrl}
            reservationId={reservationId}
            propertyStatusLink={propertyStatusLink}
            propertyTravelAdvisoryLink={propertyTravelAdvisoryLink}
            sendGuestEmail={sendGuestEmail}
            showBookingSurvey={showBookingSurvey}
            setShowBookingSurvey={setShowBookingSurvey}
            createSurveyLink={(additionalData) =>
              qualtricsIntercept.createSurveyLink(BOOKING_SURVEY_LINK, {
                ...additionalData,
                "OWS Code": property.owsCode,
                "Check-In Date": completedBooking.startDate,
                "Check-Out Date": completedBooking.endDate,
                "Room Name": rooms[0].name,
                "Booking Process": "rbf",
                multiroom: Boolean(rooms.length > 1).toString(),
                "Ancillary Product Categories": "Empty - rbf",
                "Golden profile id":
                  goldenId ||
                  completedBooking?.customers?.[0]?.goldenId ||
                  "na",
                "Estimated Total": amountInCAD(completedBooking) || "",
              })
            }
          />
        </div>
      </div>

      {isPreferredPartners && (
        <PreferredPartnerEmailForm
          completedBooking={completedBooking}
          ppDetails={ppDetails}
          ppReturnUrl={ppReturnUrl}
        />
      )}

      {isCancellingBooking && <BookingFlowLoadingIndicator />}

      {showCancellationModal && (
        <CancellationModal
          completedBooking={completedBooking}
          isBookingCancelled={isBookingCancelled}
          isBookingCancelledFailed={isBookingCancelledFailed}
          isPreferredPartners={isPreferredPartners}
          sendGuestEmail={sendGuestEmail}
          closeModal={closeCancellationModal}
        />
      )}
    </BookingFlowLayout>
  );
}

export default PersonalizeYourStayView;
