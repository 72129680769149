import { API_REQUEST_STATES } from "store/apiRequestStates/constants";
import { fetchPropertyWebCheckin } from "./propertyWebCheckin.slice";

export const selectPropertyWebCheckin =
  ({ propertyCode }) =>
  ({ propertyWebCheckin }) =>
    propertyWebCheckin.data[propertyCode];

export const selectIsLoadingPropertyWebCheckin = ({ apiRequestStates }) => {
  const apiRequestState = apiRequestStates[fetchPropertyWebCheckin.type];
  return (
    (apiRequestState && apiRequestState === API_REQUEST_STATES.REQUEST) || false
  );
};

export const selectIsPropertyWebCheckinFulfilled = ({ apiRequestStates }) => {
  const apiRequestState = apiRequestStates[fetchPropertyWebCheckin.type];
  return (
    (apiRequestState && apiRequestState === API_REQUEST_STATES.FULFILLED) ||
    false
  );
};
