import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import env from "config/env";
import { useTranslation } from "hooks";
import { getSearchFormValuesFromQueryString } from "BookingFlow/utils";
import * as bookingFlowRoutes from "BookingFlow/bookingFlowRoutes";
import {
  BookingFlowLoadingIndicator,
  DictionaryKeyContent,
  ErrorMessage,
  LanguageSupportModal,
} from "BookingFlow/components";
import { TECHNICAL_ERROR_MESSAGE } from "fixtures/constants";
import BookingFlowLayout from "../BookingFlowLayout";
import BookingMessages from "./BookingMessages";
import PreferredPartnersBookingMessage from "./PreferredPartnersBookingMessage";
import SearchSummary from "./SearchSummary";
import SearchFilters from "./SearchFilters";
import AvailableRooms from "./AvailableRooms";
import AvailablePackages from "./AvailablePackages";
import TripTease from "./TripTease";
import useChooseYourRoom from "./useChooseYourRoom";
import MappingLayerContext from "../mappingLayerContext";
import FlexDatesDateSelection from "./FlexDatesDateSelection";
import RoomsSelection from "./RoomsSelection";
import SelectedItinerary from "./SelectedItinerary";
import SelectNewDatesWarningModal from "./SelectNewDatesWarningModal";

const { STATIC_SITE_URL, PREFERRED_PARTNER_DOMAIN } = env;

export default function ChooseYourRoomView() {
  const { t } = useTranslation();
  const [showSelectNewDatesWarningModal, setShowSelectNewDatesWarningModal] =
    useState(false);

  const searchParams = getSearchFormValuesFromQueryString(
    useLocation().search,
    new Date()
  );
  const {
    dates: { checkIn, checkOut },
    rooms,
    promoCode,
    promoHide,
    flexDatesWindow,
  } = searchParams;

  const roomsData = useChooseYourRoom({
    searchParams,
  });

  const {
    isPreferredPartners,
    ppReturnUrl = PREFERRED_PARTNER_DOMAIN,
    addRoom,
    availabilityErrors,
    bookingMessages,
    contactUs,
    currencyCode,
    currentRoomIndex,
    setCurrencyCode,
    deepLinkError,
    disclaimer,
    dismissMissingLanguageError,
    employeeMode,
    handleAddPackage,
    isAccessible,
    isAllInPricing,
    isLoadingAddPackage,
    isLoadingSearchResults,
    isReLoadingSearchResults,
    isLoadingFlexDateSearchResults,
    lastSelectedRoom,
    missingLanguageError,
    propertyName,
    propertyTripTeaseHotelKey,
    propertyUseGlobalNumber,
    removeAllRooms,
    searchResults,
    setIsAccessible,
    setIsAllInPricing,
    setSortByHighestToLowestRate,
    sortByHighestToLowestRate,
    tollFreeNumbers,
    willRenderAllInPricing,
    willRenderTripTease,
    willRenderPackages,
    willRenderSearchResults,
    error,

    isFlexDates,
    flexDates,
    resultSetId,
    dispatchChangeSearch,
    dispatchFetchSearchResultsForResultSetId,
    roomsForRoomsSelection,
    selectedPackages,
  } = roomsData;

  const isMultiRoom = roomsForRoomsSelection.length > 1;

  const selectNewDates = () => {
    removeAllRooms();
    setShowSelectNewDatesWarningModal(false);
  };

  const handleSelectNewDatesClick = () => {
    if (isMultiRoom) {
      setShowSelectNewDatesWarningModal(true);
    } else {
      selectNewDates();
    }
  };

  const selectedFlexDate = flexDates.find(
    (flexDate) => flexDate.resultSetId === resultSetId
  );

  if (isLoadingSearchResults) {
    return <BookingFlowLoadingIndicator />;
  }

  return (
    <BookingFlowLayout
      pageTitle={bookingFlowRoutes.chooseYourRoom.getPageTitle({
        isPreferredPartners,
      })}
      htmlBodyClassName={bookingFlowRoutes.chooseYourRoom.htmlBodyClassName}
      isPreferredPartners={isPreferredPartners}
      ppReturnUrl={ppReturnUrl}
      contactUs={contactUs}
      employeeMode={employeeMode}
    >
      {showSelectNewDatesWarningModal && (
        <SelectNewDatesWarningModal
          selectNewDates={selectNewDates}
          closeModal={() => setShowSelectNewDatesWarningModal(false)}
        />
      )}

      {(isLoadingFlexDateSearchResults ||
        isLoadingAddPackage ||
        isReLoadingSearchResults) && <BookingFlowLoadingIndicator />}

      <MappingLayerContext.Provider value={roomsData}>
        <div className="main-inner">
          <SearchSummary
            propertyName={propertyName}
            checkIn={checkIn}
            checkOut={checkOut}
            rooms={rooms}
            promoCode={promoCode}
            flexDatesWindow={flexDatesWindow}
            hidePromoCodeField={
              promoHide || employeeMode || isPreferredPartners
            }
            changeSearch={dispatchChangeSearch}
          />

          <div className="search-results-title-block">
            <div className="container room-list-container">
              <h1 className="ty-h2">
                {willRenderSearchResults &&
                  !isMultiRoom &&
                  t("Choose Your Room")}
                {willRenderSearchResults &&
                  isMultiRoom &&
                  t("CHOOSE YOUR ROOM {0}", [currentRoomIndex + 1])}
                {willRenderPackages && !isMultiRoom && t("Choose a Package")}
                {willRenderPackages &&
                  isMultiRoom &&
                  t("CHOOSE A PACKAGE FOR ROOM {0}", [currentRoomIndex + 1])}
              </h1>

              {availabilityErrors &&
                availabilityErrors.map((bookingError, i) => (
                  <ErrorMessage
                    key={i}
                    contactReservations={bookingError.contactReservations}
                    descriptionDictKey={bookingError.descriptionDictKey}
                    labelDictKey={bookingError.labelDictKey}
                    messageDictKey={bookingError.messageDictKey}
                  />
                ))}

              {!availabilityErrors && deepLinkError && (
                <ErrorMessage
                  labelDictKey="Error"
                  messageDictKey={deepLinkError}
                />
              )}

              {!availabilityErrors && !deepLinkError && error && (
                <ErrorMessage messageDictKey={TECHNICAL_ERROR_MESSAGE} />
              )}

              {isPreferredPartners && willRenderPackages && (
                <PreferredPartnersBookingMessage />
              )}

              {bookingMessages?.length > 0 && (
                <BookingMessages bookingMessages={bookingMessages} />
              )}
            </div>
          </div>

          <div className="container room-list-container">
            {willRenderSearchResults &&
              isFlexDates &&
              currentRoomIndex === 0 && (
                <FlexDatesDateSelection
                  flexDates={flexDates}
                  selectedResultSetId={resultSetId}
                  setSelectedResultSetId={
                    dispatchFetchSearchResultsForResultSetId
                  }
                  isAllInPricing={isAllInPricing}
                />
              )}

            {isFlexDates && (willRenderPackages || currentRoomIndex > 0) && (
              <SelectedItinerary
                checkIn={selectedFlexDate?.checkinDate || checkIn}
                checkOut={selectedFlexDate?.checkoutDate || checkOut}
                onSelectNewDatesClick={handleSelectNewDatesClick}
              />
            )}

            {!employeeMode && (
              <RoomsSelection
                onEditClick={() => setShowSelectNewDatesWarningModal(true)}
                rooms={roomsForRoomsSelection}
                isMultiRoom={isMultiRoom}
              />
            )}
            {willRenderSearchResults && (
              <SearchFilters
                rooms={rooms}
                isAllInPricing={isAllInPricing}
                onIsAllInPricingChange={setIsAllInPricing}
                isAccessible={isAccessible}
                onIsAccessibleChange={setIsAccessible}
                isAllInPricingDisabled={currentRoomIndex > 0}
                sortByHighestToLowestRate={sortByHighestToLowestRate}
                onSortByHighestToLowestRateChange={setSortByHighestToLowestRate}
                willRenderAllInPricing={willRenderAllInPricing}
                currencyCode={currencyCode}
                setCurrencyCode={setCurrencyCode}
              />
            )}

            {willRenderSearchResults && (
              <AvailableRooms
                searchResults={searchResults}
                onSelectRoom={addRoom}
                propertyUseGlobalNumber={propertyUseGlobalNumber}
                tollFreeNumbers={tollFreeNumbers}
              />
            )}

            {willRenderPackages && (
              <AvailablePackages
                offers={lastSelectedRoom.bedType.offers}
                bookableOnline={lastSelectedRoom.bookableOnline}
                onSelectPackage={handleAddPackage}
                employeeMode={employeeMode}
                isPreferredPartners={isPreferredPartners}
                lastPackageToSelect={
                  selectedPackages.length === roomsForRoomsSelection.length - 1
                }
              />
            )}

            <div className="info-row">
              {tollFreeNumbers && (
                <a href={STATIC_SITE_URL + tollFreeNumbers.url}>
                  <DictionaryKeyContent dict={tollFreeNumbers.textDictKey} />
                </a>
              )}
              <br />

              {contactUs && (
                <a
                  href={
                    (isPreferredPartners
                      ? PREFERRED_PARTNER_DOMAIN
                      : STATIC_SITE_URL) + contactUs.url
                  }
                >
                  <DictionaryKeyContent dict={contactUs.textDictKey} />
                </a>
              )}

              <div
                dangerouslySetInnerHTML={{
                  __html: disclaimer,
                }}
              />
            </div>
          </div>

          {willRenderTripTease && (
            <TripTease
              searchResults={searchResults}
              searchParams={searchParams}
              propertyTripTeaseHotelKey={propertyTripTeaseHotelKey}
              currencyCode={currencyCode}
            />
          )}
        </div>
      </MappingLayerContext.Provider>

      {missingLanguageError && (
        <LanguageSupportModal
          useLocale={missingLanguageError.locale}
          continuePath={missingLanguageError.continuePath}
          closeModal={dismissMissingLanguageError}
        />
      )}
    </BookingFlowLayout>
  );
}
