import React from "react";

import { useTranslation } from "hooks";

export default function SisterProperties({
  sisterProperties,
  onSisterPropertySelect,
}) {
  const { t } = useTranslation();

  return (
    <div className="fs-sister-properties">
      <h2 className="title">
        <span>{t("Alternative options")}</span>
      </h2>
      <p>{t("Check for availability at")}</p>
      <div className="fs-sister-properties-buttons">
        {sisterProperties.map(({ owsCode, name }) => (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <button
            type="button"
            key={owsCode}
            className="btn"
            onClick={() => onSisterPropertySelect(owsCode)}
            dangerouslySetInnerHTML={{ __html: name }}
          />
        ))}
      </div>
    </div>
  );
}
