import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { useTranslation } from "hooks";
import { selectLastView } from "store/app";
import { DictionaryKeyContent } from "BookingFlow/components";
import LanguageSelect from "Profile/components/LanguageSelect";
import env from "config/env";
import ProfileFlyout from "./ProfileFlyout";
import ProfileStatus from "../ProfileStatus/ProfileStatus";

const { STATIC_SITE_URL, PREFERRED_PARTNER_DOMAIN } = env;

const views = {
  SIGN_IN_VIEW: "SignInView",
  REGISTER_VIEW: "RegisterView",
  PROFILE_VIEW: "ProfileView",
};

export default function FsHeader({
  children,
  contactUs,
  enableProfileLinkFlyout = true,
  hideLanguageSelector = false,
  hideProfileFlyout = false,
  languagesFilter,
  isMenuOpen,
  isMobile = false,
  isPreferredPartners,
  pageTitle,
  ppReturnUrl,
  setIsMenuOpen,
  showBestRateGuaranteed = false,
}) {
  const lastView = useSelector(selectLastView);
  const isSignInView = lastView === views.SIGN_IN_VIEW;
  const isRegisterView = lastView === views.REGISTER_VIEW;
  const isProfileView = lastView === views.PROFILE_VIEW;
  const { t, locale } = useTranslation();

  const handleMyProfileClick = (evt) => {
    evt.preventDefault();
    setIsMenuOpen(!isMenuOpen);
  };

  const fsWebsite =
    isPreferredPartners && ppReturnUrl
      ? ppReturnUrl
      : `${STATIC_SITE_URL}/${locale === "en" ? "" : locale}`;
  const fsWebSiteLabel =
    isPreferredPartners && ppReturnUrl
      ? "The Four Seasons Preferred Partner Home Page"
      : "Go to the Four Seasons home page";

  return (
    <header className="fs-header">
      <div className="fs-header__top-bar">
        <div className="fs-header__logo-container">
          <a
            href={fsWebsite}
            className={classNames("fs-header__logo", {
              "preferred-partner": isPreferredPartners,
            })}
          >
            <span className="visuallyhidden">{t(fsWebSiteLabel)}</span>
          </a>

          {!isMobile && showBestRateGuaranteed && (
            <div className="fs-header__best-rate-guaranteed">
              <p className="fs-header__best-rate-guaranteed__title">
                {t("BEST RATE GUARANTEED")}
              </p>
              <p className="fs-header__best-rate-guaranteed__text">
                {t(
                  "Book direct and get the best rate and most favourable cancellation terms, guaranteed."
                )}
              </p>
            </div>
          )}
        </div>

        {isMobile && pageTitle && !isMenuOpen && (
          <h1 className="fs-header__title">{t(pageTitle)}</h1>
        )}

        <div className="fs-header__top-bar__nav">
          {isPreferredPartners && contactUs && !isMobile && (
            <div className="fs-header__top-bar__nav__inner">
              <a
                href={PREFERRED_PARTNER_DOMAIN + contactUs.url}
                rel="noreferrer"
                target="_blank"
              >
                <DictionaryKeyContent dict={contactUs.textDictKey} />
              </a>
            </div>
          )}

          {!isPreferredPartners && !isMobile && (
            <div className="fs-header__top-bar__nav__inner">
              {!isRegisterView && !isSignInView && (
                <ProfileStatus
                  locale={locale}
                  enableProfileLinkFlyout={enableProfileLinkFlyout}
                  onClick={handleMyProfileClick}
                />
              )}

              {!hideLanguageSelector && (
                <LanguageSelect filter={languagesFilter} />
              )}
            </div>
          )}

          {!isSignInView &&
            !isRegisterView &&
            !isPreferredPartners &&
            isMobile && (
              <div className="fs-header__menu-button-container">
                <button
                  type="button"
                  className="fs-header__menu-button"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  data-testid="menu-trigger"
                >
                  <span className="visuallyhidden">{t("Menu")}</span>
                </button>
              </div>
            )}

          {(isSignInView || isRegisterView) &&
            isMobile &&
            !hideLanguageSelector && (
              <div className="fs-header__top-bar__nav__inner">
                <LanguageSelect filter={languagesFilter} />
              </div>
            )}
        </div>
      </div>

      {children}

      {!hideProfileFlyout && (
        <ProfileFlyout
          isSignInView={isSignInView}
          isRegisterView={isRegisterView}
          isProfileView={isProfileView}
          isPreferredPartners={isPreferredPartners}
          hideLanguageSelector={hideLanguageSelector}
          languagesFilter={languagesFilter}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        />
      )}
    </header>
  );
}
