/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import hardcodedCountries from "countries";

const addExtraInfo = (serverCountries) =>
  serverCountries.map(({ code, title, ...rest }) => {
    const {
      name = title,
      dialCode = "",
      priority = 0,
    } = hardcodedCountries[code] || {};

    return {
      name,
      iso2: code,
      dialCode,
      priority,
      shortName: title,
      ...rest,
    };
  });

const initialState = {
  data: [],
  error: "",
};

const countriesSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    fetchCountriesContent: (state) => {
      state.error = "";
    },
    fetchCountriesContentFulfilled: (state, { payload: { countries } }) => {
      state.data = addExtraInfo(countries);
      state.error = "";
    },
    fetchCountriesContentFailed: (state, { payload }) => {
      state.data = [];
      state.error = payload.error;
    },
    fetchCountriesContentCancel: () => {},
  },
});

export const {
  fetchCountriesContent,
  fetchCountriesContentCancel,
  fetchCountriesContentFailed,
  fetchCountriesContentFulfilled,
} = countriesSlice.actions;

export default countriesSlice.reducer;
