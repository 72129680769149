import { useState, useContext } from "react";
import { ThemeContext } from "contexts/ThemeContext";
import classNames from "classnames";

export default function useTextInput(
  {
    identifier,
    className = "",
    defaultValue,
    disabled = false,
    error = "",
    label,
    name,
    onChange = () => {},
    pattern,
    placeholder,
    readOnly = false,
    register,
    required = false,
    requiredIndicator = "*",
    type = "text",
    value,
    hasValue: hasInitialValue,
    forceLabel = false,
    ...props
  },
  ref
) {
  const { theme } = useContext(ThemeContext);
  const [hasValue, setHasValue] = useState(
    (value && value.length > 0) || hasInitialValue
  );

  const isTextArea = type === "textarea";

  const id = identifier || `input-${name}`;
  const hasError =
    (typeof error === "boolean" && error === true) || error.length > 0;

  if (placeholder === undefined && label) {
    // eslint-disable-next-line no-param-reassign
    placeholder = label;
  }

  const uncontrolledInputProps = {
    defaultValue,
    onChange: (evt) => {
      setHasValue(evt.target.value.length > 0);
      onChange(evt);
    },
  };

  const controlledInputProps = {
    value,
    onChange: (evt) => {
      setHasValue(evt.target.value.length > 0);
      onChange(evt);
    },
  };

  const addRequiredIndicator = (text) =>
    [text, text && required && requiredIndicator].filter(Boolean).join("");

  const inputProps = {
    id,
    type,
    name,
    className: classNames("TextInput-field formElement-field", {
      "formElement-field--invalid": hasError,
    }),
    pattern,
    placeholder: addRequiredIndicator(placeholder),
    disabled,
    readOnly,
    ref: (e) => {
      if (register) {
        register(e);
      }
      if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = e;
      }
    },
    ...(register ? uncontrolledInputProps : controlledInputProps),
    ...props,
  };

  const fieldClassNames = classNames("TextInput", className, {
    "TextInput--dark": theme === "dark",
    "TextInput--error": hasError,
    TextArea: isTextArea,
  });

  const labelClassNames = classNames("formElement-label ty-c4", {
    "formElement--focusAlways": forceLabel || hasValue || placeholder !== label,
  });

  return {
    inputProps,
    fieldClassNames,
    label: addRequiredIndicator(label),
    labelClassNames,
    error: hasError && typeof error === "string" ? error : undefined,
    errorClassNames: "formElement-message",
  };
}
