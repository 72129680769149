import React from "react";
import { format as formatDate } from "date-fns";

import { useTranslation } from "hooks";
import CancellationTerms from "./CancellationTerms";

export default function CancellationMessage({
  cancellation,
  dictionaryKeys,
  isBookingCancelled,
}) {
  const { t, getDateTimeFormat } = useTranslation();

  return (
    <>
      {cancellation.allowed && !isBookingCancelled && cancellation.by && (
        <div className="cancellation-terms">
          <span>
            {t("Cancellation required by")}{" "}
            {formatDate(cancellation.by, getDateTimeFormat())}
          </span>
        </div>
      )}

      {!cancellation.allowed && (
        <CancellationTerms dictionaryKeys={dictionaryKeys} />
      )}
    </>
  );
}
