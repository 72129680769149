import sum from "lodash/sum";

import { getMaxRooms } from "BookingFlow/utils/checkOccupancy";

export default function getAdjustedOccupancy({
  rooms,
  maxAdultOccupancy,
  maxChildOccupancy,
  maxNumberOfBodies,
  maxRooms: initialMaxRooms,
  maxRoomsPP,
  isPreferredPartners,
}) {
  const adults = [];
  const children = [];

  const maxRooms = getMaxRooms({
    maxRooms: initialMaxRooms,
    maxRoomsPP,
    isPreferredPartners,
  });

  const { totalAdults, totalChildren } = rooms.reduce(
    (acc, room) => {
      return {
        totalAdults: acc.totalAdults + room.adults,
        totalChildren: acc.totalChildren + room.children,
      };
    },
    {
      totalAdults: 0,
      totalChildren: 0,
    }
  );

  const roomsForAdults = Math.ceil(totalAdults / maxAdultOccupancy);

  const roomsForChildren =
    maxChildOccupancy > 0 ? Math.ceil(totalChildren / maxChildOccupancy) : 0;

  const roomsForTotalOccupancy = Math.ceil(
    (totalAdults + totalChildren) / maxNumberOfBodies
  );

  const roomsNeeded = Math.max(
    roomsForTotalOccupancy,
    roomsForAdults,
    roomsForChildren
  );

  for (let r = 0; r < roomsNeeded; r += 1) {
    const splitAdults = Math.ceil(
      (totalAdults - sum(adults)) / (roomsNeeded - r)
    );
    const splitChildren = Math.min(
      Math.ceil((totalChildren - sum(children)) / (roomsNeeded - r)),
      maxNumberOfBodies - splitAdults
    );
    adults.push(splitAdults);
    children.push(splitChildren);
  }

  const adjustedRooms = adults.map((adult, index) => ({
    adults: adult,
    children: children[index] || 0,
  }));

  if (roomsNeeded > maxRooms || totalAdults < roomsNeeded) {
    return rooms.map(({ adults: adults_, children: children_ }) => {
      const newAdultsCount =
        adults_ > maxAdultOccupancy ? maxAdultOccupancy : adults_;
      const remainingPlaces = maxNumberOfBodies - newAdultsCount;
      return {
        adults: newAdultsCount,
        children:
          remainingPlaces >= children_
            ? children_ > maxChildOccupancy
              ? maxChildOccupancy
              : children_
            : maxChildOccupancy >= remainingPlaces
            ? remainingPlaces
            : maxChildOccupancy,
      };
    });
  }

  return adjustedRooms;
}
