import { combineEpics, ofType } from "redux-observable";
import { switchMap, takeUntil } from "rxjs/operators";

import { getBookingMessages$ } from "api/content";
import {
  fetchBookingMessages,
  fetchBookingMessagesCancel,
} from "./bookingMessages.slice";

const fetchBookingMessagesEpic = (action$) =>
  action$.pipe(
    ofType(fetchBookingMessages.type),

    switchMap(
      ({
        payload: {
          locale = "en",
          propertyCode,
          bookingSteps,
          checkinDate,
          checkoutDate,
          ratePlanCode,
        },
      }) =>
        getBookingMessages$({
          locale,
          propertyCode,
          bookingSteps,
          checkinDate,
          checkoutDate,
          ratePlanCode,
        }).pipe(
          takeUntil(action$.pipe(ofType(fetchBookingMessagesCancel.type)))
        )
    )
  );

export default combineEpics(fetchBookingMessagesEpic);
