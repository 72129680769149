import React from "react";
import { useSelector } from "react-redux";
import { selectChatStatus } from "store/appStatus";

import { useTranslation } from "hooks";
import { WEB_CHAT_STATE as STATE } from "fixtures/constants";
import ChatIcon from "../../components/ChatIcon";

function WebChatButton({ className = "web-chat-button", onClick }) {
  const webChatStatus = useSelector(selectChatStatus);
  const isWebChatOpened = webChatStatus === STATE.OPEN;

  const { t } = useTranslation();

  const style = isWebChatOpened ? { zIndex: 1000 } : {};
  return (
    <div
      style={style}
      className={className}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
      aria-label={t("Chat With Us")}
    >
      <ChatIcon />
    </div>
  );
}
export default WebChatButton;
