import React from "react";

import { useTranslation } from "hooks";
import { upgradeRooms } from "store/bookings";
import Modal from "BookingFlow/components/Modal";
import CommonSingleRoomUpsells from "./CommonSingleRoomUpsells";

export default function MobileSingleRoomUpsells({
  roomIndex,
  roomName,
  image,
  price,
  description,
  hideUpgrade,
  dispatchWithLocale,
}) {
  const { t } = useTranslation();

  return (
    <Modal title=" " className="new-modal--upsells" closeModal={hideUpgrade}>
      <ul className="block-list upgrade-option-block">
        <CommonSingleRoomUpsells
          roomIndex={roomIndex}
          roomName={roomName}
          image={image}
          price={price}
          description={description}
        >
          <div className="upgrade-modal-select">
            <button
              className="btn btn-primary btn-upgrade-option"
              type="button"
              onClick={() =>
                dispatchWithLocale(
                  upgradeRooms({ roomIndices: [roomIndex + 1] })
                )
              }
            >
              {t("Upgrade")}
            </button>
            <button
              className="upgrade-option-decline button-as-link"
              type="button"
              onClick={hideUpgrade}
            >
              {t("No thanks, continue")}
            </button>
          </div>
        </CommonSingleRoomUpsells>
      </ul>
    </Modal>
  );
}
