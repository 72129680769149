import React, { useState } from "react";
import classNames from "classnames";

import useCountries from "hooks/useCountries";
import { useTranslation } from "hooks";
import { Select } from "BookingFlow/components";

export default function FormRowCountrySelect({
  id: initialId,
  name,
  className,
  label,
  errorMessage,
  ariaDescribedBy,
  register,
  onFocus = () => {},
  onBlur = () => {},
  ...inputProps
}) {
  const { t } = useTranslation();
  const [inFocus, setInFocus] = useState(false);
  const hasError = Boolean(errorMessage);
  const id = initialId || `input-${name}`;

  const { isLoading: isLoadingCountries, countries } = useCountries();

  const handleFocus = (evt) => {
    setInFocus(true);
    onFocus(evt);
  };

  const handleBlur = (evt) => {
    setInFocus(false);
    onBlur(evt);
  };

  return (
    <div
      className={classNames("form-row country-row", className, {
        "has-errors": hasError,
        "in-focus": inFocus,
      })}
    >
      <label htmlFor={id}>{label}</label>

      <Select
        key={isLoadingCountries}
        id={id}
        name={name}
        aria-describedby={ariaDescribedBy}
        aria-invalid={hasError}
        required="required"
        register={register}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={isLoadingCountries}
        {...inputProps}
      >
        {[{ iso2: "", shortName: "" }, ...countries]
          .filter(
            (v, index, self) =>
              self.findIndex(({ iso2 }) => iso2 === v.iso2) === index
          )
          .map(({ iso2, shortName }) => (
            <option key={iso2} value={iso2}>
              {t(shortName)}
            </option>
          ))}
      </Select>

      {hasError && (
        <div className="inline-error">
          <span id={ariaDescribedBy} className="message" role="tooltip">
            {errorMessage}
          </span>
          <span aria-hidden="true" className="icon">
            !
          </span>
        </div>
      )}
    </div>
  );
}
