import {
  deleteUserEmail,
  deleteUserEmailFulfilled,
} from "store/profile/profile.slice";
import { deleteEmail } from "../profile.helpers";
import { updateProfileTemplate } from "./updateProfile";

export default updateProfileTemplate({
  actionType: deleteUserEmail.type,
  fulfillAction: deleteUserEmailFulfilled,
  updateFn: ({ emails = [] } = {}, newValue) => ({
    emails: deleteEmail(emails, newValue),
  }),
});
