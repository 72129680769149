import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SignInModal } from "Authentication";

import { editProfilePath } from "Profile/profileRoutes";
import { useTranslation } from "hooks";
import {
  selectYourInterests,
  toggleInterest,
  updateInterests,
} from "store/profile";
import { isMobileApp } from "utils";
import Interest from "../components/Interest";

function YourInterests() {
  const { t, locale } = useTranslation();
  const dispatch = useDispatch();

  const storeInterests = useSelector(selectYourInterests);

  const [interests, setInterests] = useState(storeInterests);

  const onToggle = (value) => {
    dispatch(toggleInterest({ newValue: value }));
    setInterests(updateInterests(interests, value));
  };

  return (
    <div id="your-interests" className="view--profile__your-interests">
      <div className="container">
        <div className="fs-your-interests">
          <div className="row">
            <div className="col-sm-12">
              <h2>{t("Your Interests")}</h2>
              {!isMobileApp() && (
                <p>
                  <span>
                    {t(
                      "We'll use your choices to make the content you see more personal. You can also"
                    )}
                  </span>{" "}
                  <SignInModal
                    id="editProfileEmailSubscriptions"
                    buttonLabel={t("edit your subscriptions")}
                    type="Link"
                    kind="link"
                    className="plain-link"
                    afterSignInRedirectTo={`${editProfilePath.to({
                      locale,
                    })}#email-subscriptions`}
                  />
                  .
                </p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              {interests.map(({ title, options }) => (
                <Interest
                  title={t(title)}
                  classTitle={title}
                  key={title}
                  options={options}
                  onToggle={onToggle}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YourInterests;
