import React from "react";
import { useTranslation } from "hooks";
import { useSelector } from "react-redux";
import { selectProfile } from "store/profile";
import { selectEmployeeProfile } from "store/employeeProfile";
import { TextInput, TextArea, PhoneNumber } from "Profile/components";
import * as regexPatterns from "utils/regexPatterns";
import validPhoneNumber from "utils/validPhoneNumber";
import startCase from "lodash/startCase";
import { Controller } from "react-hook-form";
import useCountries from "hooks/useCountries";
import get from "lodash/fp/get";
import LocationAndDates from "../BookingFields/LocationAndDates";
import RoomDetails from "./RoomDetails";

function FriendsAndFamilyForm({ searchData, formControls, smokeFree }) {
  const { t } = useTranslation();
  const userProfile = useSelector(selectProfile);
  const employeeProfile = useSelector(selectEmployeeProfile);

  const {
    name: { firstName, surname },
    phones,
  } = userProfile;

  const { workPlace } = employeeProfile;

  const { register, errors, control, setValue } = formControls;

  const fullName = `${firstName} ${surname}`;
  const email = get(["emails", 0, "email"], userProfile) || "";
  const phone = ([0], phones.map(({ number }) => `+${number}`)) || "";
  const normalizedWorkplace = startCase(workPlace.toLowerCase());

  const { countries, isLoading: isLoadingCountries } = useCountries();

  return (
    <div>
      <div>
        <h3 className="ty-c1 section-header">{t("Employee Details")}</h3>

        <div className="row">
          <div className="col-sm-12 col-md-6 display-field">
            <label className="ty-c4">{t("Employee Name")}</label>
            <span>{fullName}</span>
            <input
              name="employeeName"
              value={fullName}
              type="hidden"
              ref={register()}
            />
          </div>
          <div className="col-sm-12 col-md-6 display-field">
            <label className="ty-c4">{t("Workplace")}</label>
            <span>{normalizedWorkplace}</span>
            <input
              name="employeeWorkplace"
              value={normalizedWorkplace}
              type="hidden"
              ref={register()}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 display-field">
            <label className="ty-c4">{t("Employee Email")}</label>
            <span>{email}</span>
            <input
              name="employeeEmail"
              type="hidden"
              value={email}
              ref={register()}
            />
          </div>
          <div className="col-sm-12 col-md-6 display-field">
            <label className="ty-c4">{t("Employee Phone")}</label>
            <span>{phone}</span>
            <input
              name="employeePhone"
              value={phone}
              type="hidden"
              ref={register()}
            />
          </div>
        </div>
      </div>
      <div>
        <h3 className="ty-c1 section-header">{t("Guest Details")}</h3>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <TextInput
              label={t("Guest Full Name")}
              placeholder={t("Guest Full Name")}
              name="guestFullName"
              register={register({ required: true })}
              required
              error={
                errors.guestFullName &&
                t("Please enter the guest first and last names.")
              }
              onChange={(evt) =>
                setValue("rooms[0].adults[0].name", evt.target.value || "", {
                  shouldValidate: true,
                })
              }
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <TextInput
              type="email"
              label={t("Email Address")}
              placeholder={t("Email Address")}
              name="guestEmail"
              register={register({
                required: true,
                pattern: regexPatterns.email,
              })}
              error={
                errors.guestEmail && t("Please enter the guest email address.")
              }
              required
            />
          </div>
        </div>
        <div>
          <Controller
            name="guestMobilePhone"
            required
            control={control}
            as={<PhoneNumber />}
            countries={countries}
            isLoadingCountries={isLoadingCountries}
            countryCodeClassName="col-sm-12 col-md-6"
            phoneNumberClassName="col-sm-12 col-md-6"
            rules={{
              required: true,
              pattern: regexPatterns.telephoneNumber,
              validate: { validPhoneNumber },
            }}
            error={
              errors.guestMobilePhone &&
              t("Please enter the guest mobile phone number.")
            }
          />
        </div>
        <div className="row">
          <div className="col-sm-12 relation-to-guest">
            <TextInput
              label={t("Please describe how you are related to the guest")}
              placeholder={t(
                "Please describe how you are related to the guest"
              )}
              name="relationshipToGuest"
              register={register()}
            />
          </div>
        </div>
      </div>

      <div>
        <h3 className="ty-c1 section-header">{t("Stay Details")}</h3>
        <hr />
        <LocationAndDates searchData={searchData} formControls={formControls} />
      </div>
      <RoomDetails
        searchData={searchData}
        formControls={formControls}
        smokeFree={smokeFree}
      />
      <div className="special-requests">
        <TextArea
          label={t("Special Requests")}
          placeholder={t("Special Requests")}
          name="specialRequests"
          register={register()}
        />
      </div>
    </div>
  );
}

export default FriendsAndFamilyForm;
