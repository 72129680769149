import { configureStore } from "@reduxjs/toolkit";
import { createEpicMiddleware } from "redux-observable";
import { persistStore } from "redux-persist";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

import env from "config/env";
import rootReducer, { rootEpic } from "./rootReducer";
import beaconMiddleware from "./beaconMiddleware";
import apmBeacon from "./apmBeacon";
import decisionEngineBeacon from "./decisionEngineBeacon";
import profileRefreshBeacon from "./profileRefreshBeacon";
// eslint-disable-next-line import/no-cycle

export const history = createBrowserHistory();

const { ENABLE_ANALYTICS } = env;

const epicMiddleware = createEpicMiddleware();

const store = configureStore({
  reducer: rootReducer(history),
  middleware: (getDefaultMiddleware) =>
    [
      ...getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
      routerMiddleware(history),
      ENABLE_ANALYTICS && beaconMiddleware,
      apmBeacon,
      decisionEngineBeacon,
      profileRefreshBeacon,
      epicMiddleware,
    ].filter(Boolean),
});

epicMiddleware.run(rootEpic);

export const persistor = persistStore(store);

export default store;
