import React from "react";

import { useTranslation } from "hooks";
import { Button, Dropdown } from "Profile/components";
import { selectUserTitle } from "store/profile/profile.selectors";
import { updateUserTitle } from "store/profile";
import TITLES from "Profile/utils/userProfileTitleOptions";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import useFormSubmit from "../../hooks/useFormSubmit";

export default function UserDetailsForm({ closeForm }) {
  const { t } = useTranslation();

  const {
    onSubmit,
    register,
    showLoadingIndicator,
    value: userTitle,
  } = useFormSubmit({
    closeForm,
    action: updateUserTitle,
    selector: selectUserTitle,
    fieldName: "title",
  });

  return (
    <>
      {showLoadingIndicator && <BookingFlowLoadingIndicator />}
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <p>
              {t(
                "We’re sorry but at this time you can’t change your name without calling us. Please call toll free 1-800-819-5053 any time."
              )}
            </p>
          </div>
          <div className="col-lg-3 col-md-8">
            <Dropdown
              name="title"
              options={TITLES.map(({ label, ...rest }) => ({
                label: t(label),
                ...rest,
              }))}
              register={register({ required: true })}
              value={userTitle}
            />
          </div>
          <div className="col-md-3 offset-md-1 offset-lg-0">
            <Button type="submit">{t("Update")}</Button>
          </div>
        </div>
      </form>
    </>
  );
}
