/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import get from "lodash/get";

import smoochSignOut from "utils/smoochSignOut";
import { errorCodes } from "utils/errorCodes";
import { LOGIN_TYPES } from "api/tretail/authentication/generateOTP";

export const SIGN_IN_VIEW_STATES = {
  REQUEST_OTP: "REQUEST_OTP",
  SUBMIT_OTP: "SUBMIT_OTP",
  CODE_INVALID: "CODE_INVALID",
  CODE_EXPIRED: "CODE_EXPIRED",
};

export const initialState = {
  viewState: SIGN_IN_VIEW_STATES.REQUEST_OTP,

  requestOTP: {
    sendCodeTo: {
      [LOGIN_TYPES.PHONE_NUMBER]: "",
      [LOGIN_TYPES.EMAIL_ADDRESS]: "",
    },
    sendCodeVia: LOGIN_TYPES.EMAIL_ADDRESS,
  },

  submitOTP: {
    sendCodeTo: {
      [LOGIN_TYPES.PHONE_NUMBER]: "",
      [LOGIN_TYPES.EMAIL_ADDRESS]: "",
    },
    sendCodeVia: LOGIN_TYPES.EMAIL_ADDRESS,
    persist: false,
    isUserPersistent: false,
  },
  activeSignInModal: undefined,
  errors: [],
  apiErrors: [],
  supplierErrors: [],
};

const signInSlice = createSlice({
  name: "signIn",
  initialState,
  reducers: {
    setViewState(state, { payload }) {
      state.viewState = payload;
    },

    setSendCodeTo(
      state,
      {
        payload: {
          email: { email },
          phoneNumber: { number },
        },
      }
    ) {
      state.viewState = SIGN_IN_VIEW_STATES.REQUEST_OTP;
      state.requestOTP.sendCodeTo = {
        [LOGIN_TYPES.PHONE_NUMBER]: `+${number.replace(/^\+/, "")}`,
        [LOGIN_TYPES.EMAIL_ADDRESS]: email,
      };
      state.submitOTP = {
        sendCodeTo: {
          [LOGIN_TYPES.PHONE_NUMBER]: "",
          [LOGIN_TYPES.EMAIL_ADDRESS]: "",
        },
        sendCodeVia: LOGIN_TYPES.EMAIL_ADDRESS,
      };
    },

    reset() {
      return { ...initialState };
    },

    generateOtp(
      state,
      { payload: { sendCodeTo, sendCodeVia, isUserPersistent } }
    ) {
      if (sendCodeTo) {
        state.requestOTP.sendCodeTo = {
          [LOGIN_TYPES.PHONE_NUMBER]: "",
          [LOGIN_TYPES.EMAIL_ADDRESS]: "",
          [sendCodeVia]: sendCodeTo,
        };
      }
      state.submitOTP.sendCodeTo = state.requestOTP.sendCodeTo;
      state.requestOTP.sendCodeVia = sendCodeVia;
      state.submitOTP.sendCodeVia = sendCodeVia;
      state.submitOTP.isUserPersistent = isUserPersistent;
    },
    generateOtpFulfilled(state) {
      state.viewState = SIGN_IN_VIEW_STATES.SUBMIT_OTP;
      state.errors = [];
      state.apiErrors = [];
      state.supplierErrors = [];
    },
    generateOtpFailed(state, { payload }) {
      state.errors = payload.errors || [];
      state.apiErrors = payload.apiErrors || [];
      state.supplierErrors = payload.supplierErrors || [];
    },
    generateOtpCancel() {},
    generateOtpReset(state) {
      state.viewState = SIGN_IN_VIEW_STATES.REQUEST_OTP;
      state.requestOTP.sendCodeTo = {
        [LOGIN_TYPES.PHONE_NUMBER]: "",
        [LOGIN_TYPES.EMAIL_ADDRESS]: "",
      };
      state.errors = [];
      state.apiErrors = [];
      state.supplierErrors = [];
    },

    submitOtp(
      state,
      {
        payload: {
          formValues: { persist },
          isUserPersistent,
        },
      }
    ) {
      state.submitOTP.persist = persist;
      state.submitOTP.isUserPersistent = isUserPersistent;
      state.errors = [];
      state.apiErrors = [];
      state.supplierErrors = [];
    },
    submitOtpFulfilled({ activeSignInModal }) {
      smoochSignOut();
      return { ...initialState, activeSignInModal };
    },
    submitOtpFailed(state, { payload }) {
      const errorCode = get(payload, ["supplierErrors", 0, "errorCode"]);
      if (errorCode === errorCodes.INVALID_LOGIN_OTP_CODE) {
        state.viewState = SIGN_IN_VIEW_STATES.CODE_INVALID;
      } else if (errorCode === errorCodes.INVALID_LOGIN_OTP_EXPIRED) {
        state.viewState = SIGN_IN_VIEW_STATES.CODE_EXPIRED;
      } else {
        state.viewState = SIGN_IN_VIEW_STATES.REQUEST_OTP;
      }
      state.errors = payload.errors || [];
      state.apiErrors = payload.apiErrors || [];
      state.supplierErrors = payload.supplierErrors || [];
    },
    submitOtpCancel(state) {
      state.errors = [];
      state.apiErrors = [];
      state.supplierErrors = [];
    },

    redirectTo(state, { payload: { afterSignInRedirectTo } }) {
      window.location.replace(afterSignInRedirectTo);
    },

    setActiveSignInModal(state, { payload }) {
      state.activeSignInModal = payload;
    },
  },
});

export const {
  setViewState,
  setSendCodeTo,
  reset,
  generateOtp,
  generateOtpCancel,
  generateOtpFailed,
  generateOtpFulfilled,
  generateOtpReset,
  submitOtp,
  submitOtpCancel,
  submitOtpFailed,
  submitOtpFulfilled,
  redirectTo,
  setActiveSignInModal,
} = signInSlice.actions;

export default signInSlice.reducer;
