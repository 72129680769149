import { useDispatch } from "react-redux";
import isObject from "lodash/isObject";

import useTranslation from "./useTranslation";

export default function useDispatchWithLocale() {
  const { locale } = useTranslation();

  const dispatch = useDispatch();

  return ({ type, payload = {} }) => {
    const action = {
      type,
      payload,
    };
    if (isObject(action.payload) && !action.payload.locale) {
      action.payload.locale = locale;
    }
    return dispatch(action);
  };
}
