import React from "react";

import { useTranslation } from "hooks";
import {
  getBestRateGuaranteeLink,
  getPressLink,
  getMagazinesLink,
  getAboutLink,
  getCareersLink,
  getLegalLink,
  getPrivacyLink,
  getAccessibilityLink,
} from "utils/externalLinks";

import env from "config/env";

const { STATIC_SITE_URL } = env;

function BookingFlowFooter({ showBestRateGuaranteed }) {
  const { t, i18n } = useTranslation();

  const openCookiePreferencesModal = () => {
    if (window.OneTrust && window.OneTrust.ToggleInfoDisplay) {
      window.OneTrust.ToggleInfoDisplay();
    }

    return true;
  };

  return (
    <footer className="original-footer">
      {showBestRateGuaranteed && (
        <div className="footer-upper">
          <a
            className="best_rate_guarantee_link"
            target="_blank"
            rel="noopener noreferrer"
            href={getBestRateGuaranteeLink(i18n.language)}
          >
            <div className="best_rate_guaranteed_wrapper">
              <p className="best_rate_guaranteed_title ">
                {t("BEST RATE GUARANTEED")}
              </p>
            </div>
          </a>
        </div>
      )}
      <div className="footer-inner">
        <div className="container">
          <div className="row">
            <dl className="col-xs-4 col-md-3">
              <dt className="h3">{t("More Information")}</dt>
              <dd>
                <a href={getPressLink(i18n.language)}>{t("Press Room")}</a>
              </dd>
              <dd>
                <a href={getMagazinesLink(i18n.language)}>
                  {t("Four Seasons Magazine")}
                </a>
              </dd>
              <dd>
                <a href={getAboutLink(i18n.language)}>{t("About Us")}</a>
              </dd>
              <dd>
                <a href={getCareersLink(i18n.language)}>{t("Careers")}</a>
              </dd>
            </dl>

            <dl className="col-xs-4 col-md-3">
              <dt>{t("Disclaimer")}</dt>
              <dd>
                <a href={getLegalLink(i18n.language)}>{t("Legal Notice")}</a>
              </dd>
              <dd>
                <a href={getPrivacyLink(i18n.language)}>
                  {t("Privacy Notice")}
                </a>
              </dd>
              <dd>
                <button
                  type="button"
                  className="cookie-settings-button button-as-link"
                  href="#"
                  onClick={openCookiePreferencesModal}
                >
                  {t("Cookie Preferences")}
                </button>
              </dd>
              <dd>
                <a href={getAccessibilityLink(i18n.language)}>
                  {t("Accessibility")}
                </a>
              </dd>
            </dl>
          </div>
        </div>
        <div className="legal">
          <div className="container">
            <p>
              {t(
                "_Four Seasons Hotels Limited 1997-{0}. All Rights Reserved.",
                [new Date().getFullYear()]
              )}
              <a className="icon icon-fs-icon" href={STATIC_SITE_URL}>
                <span className="sr-only">Four Seasons</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default BookingFlowFooter;
