import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";

import { employeeSpecialRateRequest$ } from "api/email";
import catchInternalServerError from "store/catchInternalServerError";
import {
  employeeSpecialRateRequest,
  employeeSpecialRateRequestFulfilled,
  employeeSpecialRateRequestFailed,
  employeeSpecialRateRequestCancel,
} from "../profile.slice";

export default function employeeSpecialRateRequestEpic(action$) {
  return action$.pipe(
    ofType(employeeSpecialRateRequest.type),
    switchMap(
      ({
        payload: {
          rooms,
          specialRequests,
          totalNights,
          checkOutDate,
          checkInDate,
          guestMobilePhone,
          guestEmail,
          guestFullName,
          employeeWorkplace,
          employeeName,
          employeeEmail,
          employeePhone,
          relationshipToGuest,
          hotelCode,
        },
      }) => {
        return employeeSpecialRateRequest$({
          rooms,
          specialRequests,
          totalNights,
          checkOutDate,
          checkInDate,
          guestMobilePhone,
          guestEmail,
          guestFullName,
          employeeWorkplace,
          employeeName,
          employeeEmail,
          employeePhone,
          relationshipToGuest,
          hotelCode,
        }).pipe(
          map(() => employeeSpecialRateRequestFulfilled()),

          catchInternalServerError(),

          catchError(({ response }) => {
            console.log("Error Sending Friends and Family email", {
              response,
            });
            return of(employeeSpecialRateRequestFailed());
          }),

          takeUntil(action$.pipe(ofType(employeeSpecialRateRequestCancel.type)))
        );
      }
    )
  );
}
