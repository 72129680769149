import { ajax } from "rxjs/ajax";

import env from "config/env";

const { MEMBER_DETAILS_URL } = env;

export default function getMemberDetails$() {
  return ajax.getJSON(`${MEMBER_DETAILS_URL}`);
}
/*
// DMG: Mock the API call
import { Observable } from "rxjs";

export default function getMemberDetails$() {
  return new Observable((subscriber) => {
    setTimeout(() => {
      subscriber.next({
        Memberships: [
          {
            Content: {
              id: "KLP",
              name: "Platinum",
              cardImage: {
                url: "https://reservations.project1.stage.fsweb.ca/alt/img-opt/~60/author/content/dam/fourseasons/images/demo/card-platinum-large-original.jpg",
              },
              discounts: [
                "35% off food and 10% off beverages at The Lounge, Curate, and Pool Bar & Grill for parties of fewer than 10 (excluding alcoholic beverages)",
                "30% off food and beverages at Yun House for parties of fewer than 10 (excluding beverages)",
                "25% off food and beverages at Bar Trigona for parties of fewer than 10 (excluding alcoholic beverages)",
                "15% off bottled wine and spirits at The Lounge",
                "10% off Best Available Rate for guest rooms, 15% off Best Available Rate for suites (subject to availability, excluding packages)",
                "20% off laundry and dry cleaning services",
                "20% off facials, body treatments and salon services at The Spa",
                "10% off food for social events and weddings",
              ],
              vouchers: [
                "One accommodation voucher for a complimentary one-night stay in a Junior Suite with Executive Club Lounge access for two",
                "One accommodation voucher for 30% off Best Available Rate in a guest room or suite (for stays of up to three consecutive nights)",
                "Three accommodation vouchers for upgrade to the next category of guest room or suite (for stays of up to three consecutive nights)",
                "Two accommodation vouchers for extended stay with complimentary upgrade to the next category of guest room or suite, and complimentary third night (minimum stay of two consecutive nights)",
                "Two dining vouchers for complimentary buffet lunch or dinner for two guests at Curate (excluding Weekend Brunch)",
                "One dining voucher for complimentary afternoon tea for guests at The Lounge",
                "One dining voucher for a complimentary bottle of wine for a minimum of two dine-in guests",
                "One dining voucher for a complimentary birthday cake",
                "One dining voucher valued at MYR 200 for The Lounge, Curate, Yun House, or Pool Bar & Grill (minimum spend of MYR 500)",
                "One dining voucher for 50% off food and beverages at Curate for parties of 4 to 10 (excluding Weekend Brunch and alcoholic beverages)",
                "Two dining vouchers for 35% off food and beverages at Curate for parties of 4 to 10 (excluding Weekend Brunch and alcoholic beverages)",
                "Two dining vouchers for 25% off food and beverages at Yun House for parties of 4 to 10 (excluding alcoholic beverages)",
                "Two spa vouchers for 40% off spa treatments",
                " Two laundry vouchers for 40% off dry cleaning services (minimum of 4 and maximum of 10 pieces)",
              ],
              fee: {
                amount: 1588,
                currency: "MYR",
              },
              description:
                "Explore, discover, and enjoy the perks of your membership program all in one place.",
              disclaimer:
                "For any questions or assistance, please contact the hotel membership team: lifestyle.kualalumpur@fourseasons.com.",
              enrollmentEmailIntro:
                '<p>We would like to acknowledge receipt of your enrollment form. Thank you for taking the first step towards joining the program!</p>\r\n<p>Here&rsquo;s what you can expect next:</p>\r\n<ol>\r\n<li>Payment Process: You will receive a separate communication with detailed instructions on how to make your payment securely. Please keep an eye on your inbox for this important message. If you do not receive this email within 48 hours, please check your spam or promotions folder, and if it&rsquo;s not there, feel free to reach out to our Lifestyle team at <a href="mailto:lifestyle.kualalumpur@fourseasons.com">lifestyle.kualalumpur@fourseasons.com</a>.</li>\r\n<li>Membership Benefits: Once we have confirmed payment, you will receive another confirmation email notifying you that your benefits are accessible through our mobile app. Download and login to the app to access your benefits.</li>\r\n</ol>\r\n<p><span data-contrast="auto">Did you already purchase a membership? Then we are delighted to inform you that your membership has now been successfully integrated with your Four Seasons online profile. This integration enables you to access your membership details through your online profile. Additionally, we invite you to enhance your Four Seasons experience by downloading our exclusive app. With the app, you can effortlessly redeem your discounts and vouchers at the Four Seasons Kuala Lumpur hotel.</span><span data-ccp-props="{&quot;201341983&quot;:0,&quot;335559739&quot;:160,&quot;335559740&quot;:259}">&nbsp;</span></p>',
              activationEmailIntro:
                "<p>Congratulations, your Kuala Lumpur Platinum &ldquo;Lifestyle by Four Seasons&rdquo; membership is officially activated! Remember to download our mobile app and login to access your benefits.</p>",
              global: {
                overview:
                  "Explore, discover, and enjoy the perks of your membership program all in one place.",
                disclaimer:
                  "For any questions or assistance, please contact the hotel directly.",
              },
              membership: {
                id: "life-by-fs",
                image: {
                  url: "https://reservations.project1.stage.fsweb.ca/alt/img-opt/~60/author/content/dam/fourseasons/images/fsbh/home/Img_Promo_Knowledgebase.jpg",
                },
                greencloudHotelGroupCode: "LBFS",
                membershipManagerEmail: "caleb@entropy.cc",
                termsAndConditions: "These are some terms and conditions",
              },
              property: {
                officialName: "Four Seasons Hotel Kuala Lumpur",
              },
            },
            Expired: "False",
            cardId: "00002023102494083284",
            cardMaster: "",
            cardMasterNo: "",
            cardNo: "KUA30067",
            endDate: "2024-10-23 19:42:08",
            hotelCode: "KUA",
            memberID: "00002023102477806059",
            membershipId: "life-by-fs",
            membershipTierId: "KLP",
            sta: "I",
          },
          {
            Content: {
              id: "KLG",
              name: "Gold",
              cardImage: {
                url: "https://reservations.project1.stage.fsweb.ca/alt/img-opt/~60/author/content/dam/fourseasons/images/demo/card-gold-large-original.jpg",
              },
              discounts: [
                "35% off food and 10% off beverages at The Lounge, Curate, and Pool Bar & Grill for parties of fewer than 10 (excluding alcoholic beverages)",
              ],
              vouchers: [
                "One accommodation voucher for a complimentary one-night stay in a Junior Suite with Executive Club Lounge access for two",
              ],
              fee: {
                amount: 1388,
                currency: "MYR",
              },
              description:
                "Explore, discover, and enjoy the perks of your membership program all in one place.",
              disclaimer:
                "For any questions or assistance, please contact the hotel membership team: lifestyle.kualalumpur@fourseasons.com.",
              enrollmentEmailIntro:
                '<p>We would like to acknowledge receipt of your enrollment form. Thank you for taking the first step towards joining the program!</p>\r\n<p>Here&rsquo;s what you can expect next:</p>\r\n<ol>\r\n<li>Payment Process: You will receive a separate communication with detailed instructions on how to make your payment securely. Please keep an eye on your inbox for this important message. If you do not receive this email within 48 hours, please check your spam or promotions folder, and if it&rsquo;s not there, feel free to reach out to our Lifestyle team at <a href="mailto:lifestyle.kualalumpur@fourseasons.com">lifestyle.kualalumpur@fourseasons.com</a>.</li>\r\n<li>Membership Benefits: Once we have confirmed payment, you will receive another confirmation email notifying you that your benefits are accessible through our mobile app. Download and login to the app to access your benefits.</li>\r\n</ol>\r\n<p><span data-contrast="auto">Did you already purchase a membership? Then we are delighted to inform you that your membership has now been successfully integrated with your Four Seasons online profile. This integration enables you to access your membership details through your online profile. Additionally, we invite you to enhance your Four Seasons experience by downloading our exclusive app. With the app, you can effortlessly redeem your discounts and vouchers at the Four Seasons Kuala Lumpur hotel.</span><span data-ccp-props="{&quot;201341983&quot;:0,&quot;335559739&quot;:160,&quot;335559740&quot;:259}">&nbsp;</span></p>',
              activationEmailIntro:
                "<p>Congratulations, your Kuala Lumpur Gold &ldquo;Lifestyle by Four Seasons&rdquo; membership is officially activated! Remember to download our mobile app and login to access your benefits.</p>",
              global: {
                overview:
                  "Explore, discover, and enjoy the perks of your membership program all in one place.",
                disclaimer:
                  "For any questions or assistance, please contact the hotel directly.",
              },
              membership: {
                id: "life-by-fs",
                image: {
                  url: "https://reservations.project1.stage.fsweb.ca/alt/img-opt/~60/author/content/dam/fourseasons/images/fsbh/home/Img_Promo_Knowledgebase.jpg",
                },
                greencloudHotelGroupCode: "LBFS",
                membershipManagerEmail: "caleb@entropy.cc",
                termsAndConditions: "These are some terms and conditions",
              },
              property: {
                officialName: "Four Seasons Hotel Kuala Lumpur",
              },
            },
            Expired: "False",
            cardId: "00002023102494083285",
            cardMaster: "",
            cardMasterNo: "",
            cardNo: "KUA10119",
            endDate: "2024-10-23 19:42:08",
            hotelCode: "KUA",
            memberID: "00002023102477806059",
            membershipId: "life-by-fs",
            membershipTierId: "KLG",
            sta: "I",
          },
          {
            Content: {
              id: "KLS",
              name: "Spa & Fitness",
              cardImage: {
                url: "https://reservations.project1.stage.fsweb.ca/alt/img-opt/~60/author/content/dam/fourseasons/images/demo/card-spa-large-original.jpg",
              },
              discounts: ["35% off spa"],
              vouchers: [
                "One accommodation voucher for a complimentary one-night stay in a Junior Suite with Executive Club Lounge access for two",
              ],
              fee: {
                amount: 1888,
                currency: "MYR",
              },
              description:
                "Explore, discover, and enjoy the perks of your membership program all in one place.",
              disclaimer:
                "For any questions or assistance, please contact the hotel membership team: lifestyle.kualalumpur@fourseasons.com.",
              enrollmentEmailIntro:
                '<p>We would like to acknowledge receipt of your enrollment form. Thank you for taking the first step towards joining the program!</p>\r\n<p>Here&rsquo;s what you can expect next:</p>\r\n<ol>\r\n<li>Payment Process: You will receive a separate communication with detailed instructions on how to make your payment securely. Please keep an eye on your inbox for this important message. If you do not receive this email within 48 hours, please check your spam or promotions folder, and if it&rsquo;s not there, feel free to reach out to our Lifestyle team at <a href="mailto:lifestyle.kualalumpur@fourseasons.com">lifestyle.kualalumpur@fourseasons.com</a>.</li>\r\n<li>Membership Benefits: Once we have confirmed payment, you will receive another confirmation email notifying you that your benefits are accessible through our mobile app. Download and login to the app to access your benefits.</li>\r\n</ol>\r\n<p><span data-contrast="auto">Did you already purchase a membership? Then we are delighted to inform you that your membership has now been successfully integrated with your Four Seasons online profile. This integration enables you to access your membership details through your online profile. Additionally, we invite you to enhance your Four Seasons experience by downloading our exclusive app. With the app, you can effortlessly redeem your discounts and vouchers at the Four Seasons Kuala Lumpur hotel.</span><span data-ccp-props="{&quot;201341983&quot;:0,&quot;335559739&quot;:160,&quot;335559740&quot;:259}">&nbsp;</span></p>',
              activationEmailIntro:
                "<p>Congratulations, your Kuala Lumpur Gold &ldquo;Lifestyle by Four Seasons&rdquo; membership is officially activated! Remember to download our mobile app and login to access your benefits.</p>",
              global: {
                overview:
                  "Explore, discover, and enjoy the perks of your membership program all in one place.",
                disclaimer:
                  "For any questions or assistance, please contact the hotel directly.",
              },
              membership: {
                id: "life-by-fs",
                image: {
                  url: "https://reservations.project1.stage.fsweb.ca/alt/img-opt/~60/author/content/dam/fourseasons/images/fsbh/home/Img_Promo_Knowledgebase.jpg",
                },
                greencloudHotelGroupCode: "LBFS",
                membershipManagerEmail: "caleb@entropy.cc",
                termsAndConditions: "These are some terms and conditions",
              },
              property: {
                officialName: "Four Seasons Hotel Kuala Lumpur",
              },
            },
            Expired: "True",
            cardId: "00002023102494083285",
            cardMaster: "",
            cardMasterNo: "",
            cardNo: "KUA50001",
            endDate: "2023-09-23 19:42:08",
            hotelCode: "KUA",
            memberID: "00002023102477806059",
            membershipId: "life-by-fs",
            membershipTierId: "KLS",
            sta: "O",
          },
          {
            Content: {
              id: "KLF",
              name: "Family Spa & Fitness",
              cardImage: {
                url: "https://reservations.project1.stage.fsweb.ca/alt/img-opt/~60/author/content/dam/fourseasons/images/demo/card-familyspa-large-original.jpg",
              },
              discounts: ["35% off spa for whole family"],
              vouchers: [
                "One accommodation voucher for a complimentary one-night stay in a Junior Suite with Executive Club Lounge access for two",
              ],
              fee: {
                amount: 2488,
                currency: "MYR",
              },
              description:
                "Explore, discover, and enjoy the perks of your membership program all in one place.",
              disclaimer:
                "For any questions or assistance, please contact the hotel membership team: lifestyle.kualalumpur@fourseasons.com.",
              enrollmentEmailIntro:
                '<p>We would like to acknowledge receipt of your enrollment form. Thank you for taking the first step towards joining the program!</p>\r\n<p>Here&rsquo;s what you can expect next:</p>\r\n<ol>\r\n<li>Payment Process: You will receive a separate communication with detailed instructions on how to make your payment securely. Please keep an eye on your inbox for this important message. If you do not receive this email within 48 hours, please check your spam or promotions folder, and if it&rsquo;s not there, feel free to reach out to our Lifestyle team at <a href="mailto:lifestyle.kualalumpur@fourseasons.com">lifestyle.kualalumpur@fourseasons.com</a>.</li>\r\n<li>Membership Benefits: Once we have confirmed payment, you will receive another confirmation email notifying you that your benefits are accessible through our mobile app. Download and login to the app to access your benefits.</li>\r\n</ol>\r\n<p><span data-contrast="auto">Did you already purchase a membership? Then we are delighted to inform you that your membership has now been successfully integrated with your Four Seasons online profile. This integration enables you to access your membership details through your online profile. Additionally, we invite you to enhance your Four Seasons experience by downloading our exclusive app. With the app, you can effortlessly redeem your discounts and vouchers at the Four Seasons Kuala Lumpur hotel.</span><span data-ccp-props="{&quot;201341983&quot;:0,&quot;335559739&quot;:160,&quot;335559740&quot;:259}">&nbsp;</span></p>',
              activationEmailIntro:
                "<p>Congratulations, your Kuala Lumpur Gold &ldquo;Lifestyle by Four Seasons&rdquo; membership is officially activated! Remember to download our mobile app and login to access your benefits.</p>",
              global: {
                overview:
                  "Explore, discover, and enjoy the perks of your membership program all in one place.",
                disclaimer:
                  "For any questions or assistance, please contact the hotel directly.",
              },
              membership: {
                id: "life-by-fs",
                image: {
                  url: "https://reservations.project1.stage.fsweb.ca/alt/img-opt/~60/author/content/dam/fourseasons/images/fsbh/home/Img_Promo_Knowledgebase.jpg",
                },
                greencloudHotelGroupCode: "LBFS",
                membershipManagerEmail: "caleb@entropy.cc",
                termsAndConditions: "These are some terms and conditions",
              },
              property: {
                officialName: "Four Seasons Hotel Kuala Lumpur",
              },
            },
            Expired: "True",
            cardId: "00002023102494083285",
            cardMaster: "",
            cardMasterNo: "",
            cardNo: "KUA60003",
            endDate: "2023-10-23 19:42:08",
            hotelCode: "KUA",
            memberID: "00002023102477806059",
            membershipId: "life-by-fs",
            membershipTierId: "KLF",
            sta: "O",
          },
          {
            Content: {
              id: "KLD",
              name: "Dining",
              cardImage: {
                url: "https://reservations.project1.stage.fsweb.ca/alt/img-opt/~60/author/content/dam/fourseasons/images/demo/card-dining-large-original.jpg",
              },
              discounts: ["35% off Dining at all restaurants"],
              vouchers: [
                "One accommodation voucher for a complimentary one-night stay in a Junior Suite with Executive Club Lounge access for two",
              ],
              fee: {
                amount: 1288,
                currency: "MYR",
              },
              description:
                "Explore, discover, and enjoy the perks of your membership program all in one place.",
              disclaimer:
                "For any questions or assistance, please contact the hotel membership team: lifestyle.kualalumpur@fourseasons.com.",
              enrollmentEmailIntro:
                '<p>We would like to acknowledge receipt of your enrollment form. Thank you for taking the first step towards joining the program!</p>\r\n<p>Here&rsquo;s what you can expect next:</p>\r\n<ol>\r\n<li>Payment Process: You will receive a separate communication with detailed instructions on how to make your payment securely. Please keep an eye on your inbox for this important message. If you do not receive this email within 48 hours, please check your spam or promotions folder, and if it&rsquo;s not there, feel free to reach out to our Lifestyle team at <a href="mailto:lifestyle.kualalumpur@fourseasons.com">lifestyle.kualalumpur@fourseasons.com</a>.</li>\r\n<li>Membership Benefits: Once we have confirmed payment, you will receive another confirmation email notifying you that your benefits are accessible through our mobile app. Download and login to the app to access your benefits.</li>\r\n</ol>\r\n<p><span data-contrast="auto">Did you already purchase a membership? Then we are delighted to inform you that your membership has now been successfully integrated with your Four Seasons online profile. This integration enables you to access your membership details through your online profile. Additionally, we invite you to enhance your Four Seasons experience by downloading our exclusive app. With the app, you can effortlessly redeem your discounts and vouchers at the Four Seasons Kuala Lumpur hotel.</span><span data-ccp-props="{&quot;201341983&quot;:0,&quot;335559739&quot;:160,&quot;335559740&quot;:259}">&nbsp;</span></p>',
              activationEmailIntro:
                "<p>Congratulations, your Kuala Lumpur Gold &ldquo;Lifestyle by Four Seasons&rdquo; membership is officially activated! Remember to download our mobile app and login to access your benefits.</p>",
              global: {
                overview:
                  "Explore, discover, and enjoy the perks of your membership program all in one place.",
                disclaimer:
                  "For any questions or assistance, please contact the hotel directly.",
              },
              membership: {
                id: "life-by-fs",
                image: {
                  url: "https://reservations.project1.stage.fsweb.ca/alt/img-opt/~60/author/content/dam/fourseasons/images/fsbh/home/Img_Promo_Knowledgebase.jpg",
                },
                greencloudHotelGroupCode: "LBFS",
                membershipManagerEmail: "caleb@entropy.cc",
                termsAndConditions: "These are some terms and conditions",
              },
              property: {
                officialName: "Four Seasons Hotel Kuala Lumpur",
              },
            },
            Expired: "False",
            cardId: "00002023102494083285",
            cardMaster: "",
            cardMasterNo: "",
            cardNo: "KUA00004",
            endDate: "2026-10-23 19:42:08",
            hotelCode: "KUA",
            memberID: "00002023102477806059",
            membershipId: "life-by-fs",
            membershipTierId: "KLD",
            sta: "L",
          },
          {
            Content: {
              id: "KLP",
              name: "Platinum",
              cardImage: {
                url: "https://reservations.project1.stage.fsweb.ca/alt/img-opt/~60/author/content/dam/fourseasons/images/demo/card-platinum-large-original.jpg",
              },
              discounts: [
                "35% off food and 10% off beverages at The Lounge, Curate, and Pool Bar & Grill for parties of fewer than 10 (excluding alcoholic beverages)",
              ],
              vouchers: [
                "One accommodation voucher for a complimentary one-night stay in a Junior Suite with Executive Club Lounge access for two",
              ],
              fee: {
                amount: 1588,
                currency: "MYR",
              },
              description:
                "Explore, discover, and enjoy the perks of your membership program all in one place.",
              disclaimer:
                "For any questions or assistance, please contact the hotel membership team: lifestyle.kualalumpur@fourseasons.com.",
              enrollmentEmailIntro:
                '<p>We would like to acknowledge receipt of your enrollment form. Thank you for taking the first step towards joining the program!</p>\r\n<p>Here&rsquo;s what you can expect next:</p>\r\n<ol>\r\n<li>Payment Process: You will receive a separate communication with detailed instructions on how to make your payment securely. Please keep an eye on your inbox for this important message. If you do not receive this email within 48 hours, please check your spam or promotions folder, and if it&rsquo;s not there, feel free to reach out to our Lifestyle team at <a href="mailto:lifestyle.kualalumpur@fourseasons.com">lifestyle.kualalumpur@fourseasons.com</a>.</li>\r\n<li>Membership Benefits: Once we have confirmed payment, you will receive another confirmation email notifying you that your benefits are accessible through our mobile app. Download and login to the app to access your benefits.</li>\r\n</ol>\r\n<p><span data-contrast="auto">Did you already purchase a membership? Then we are delighted to inform you that your membership has now been successfully integrated with your Four Seasons online profile. This integration enables you to access your membership details through your online profile. Additionally, we invite you to enhance your Four Seasons experience by downloading our exclusive app. With the app, you can effortlessly redeem your discounts and vouchers at the Four Seasons Kuala Lumpur hotel.</span><span data-ccp-props="{&quot;201341983&quot;:0,&quot;335559739&quot;:160,&quot;335559740&quot;:259}">&nbsp;</span></p>',
              activationEmailIntro:
                "<p>Congratulations, your Kuala Lumpur Gold &ldquo;Lifestyle by Four Seasons&rdquo; membership is officially activated! Remember to download our mobile app and login to access your benefits.</p>",
              global: {
                overview:
                  "Explore, discover, and enjoy the perks of your membership program all in one place.",
                disclaimer:
                  "For any questions or assistance, please contact the hotel directly.",
              },
              membership: {
                id: "life-by-fs",
                image: {
                  url: "https://reservations.project1.stage.fsweb.ca/alt/img-opt/~60/author/content/dam/fourseasons/images/fsbh/home/Img_Promo_Knowledgebase.jpg",
                },
                greencloudHotelGroupCode: "LBFS",
                membershipManagerEmail: "caleb@entropy.cc",
                termsAndConditions: "These are some terms and conditions",
              },
              property: {
                officialName: "Four Seasons Hotel Kuala Lumpur",
              },
            },
            Expired: "False",
            cardId: "00002023102494083285",
            cardMaster: "",
            cardMasterNo: "",
            cardNo: "KUA30069",
            endDate: "2026-10-23 19:42:08",
            hotelCode: "KUA",
            memberID: "00002023102477806059",
            membershipId: "life-by-fs",
            membershipTierId: "KLP",
            sta: "X",
          },
          {
            Content: {
              id: "KLG",
              name: "Gold",
              cardImage: {
                url: "https://reservations.project1.stage.fsweb.ca/alt/img-opt/~60/author/content/dam/fourseasons/images/demo/card-gold-large-original.jpg",
              },
              discounts: [
                "35% off food and 10% off beverages at The Lounge, Curate, and Pool Bar & Grill for parties of fewer than 10 (excluding alcoholic beverages)",
              ],
              vouchers: [
                "One accommodation voucher for a complimentary one-night stay in a Junior Suite with Executive Club Lounge access for two",
              ],
              fee: {
                amount: 1388,
                currency: "MYR",
              },
              description:
                "Explore, discover, and enjoy the perks of your membership program all in one place.",
              disclaimer:
                "For any questions or assistance, please contact the hotel membership team: lifestyle.kualalumpur@fourseasons.com.",
              enrollmentEmailIntro:
                '<p>We would like to acknowledge receipt of your enrollment form. Thank you for taking the first step towards joining the program!</p>\r\n<p>Here&rsquo;s what you can expect next:</p>\r\n<ol>\r\n<li>Payment Process: You will receive a separate communication with detailed instructions on how to make your payment securely. Please keep an eye on your inbox for this important message. If you do not receive this email within 48 hours, please check your spam or promotions folder, and if it&rsquo;s not there, feel free to reach out to our Lifestyle team at <a href="mailto:lifestyle.kualalumpur@fourseasons.com">lifestyle.kualalumpur@fourseasons.com</a>.</li>\r\n<li>Membership Benefits: Once we have confirmed payment, you will receive another confirmation email notifying you that your benefits are accessible through our mobile app. Download and login to the app to access your benefits.</li>\r\n</ol>\r\n<p><span data-contrast="auto">Did you already purchase a membership? Then we are delighted to inform you that your membership has now been successfully integrated with your Four Seasons online profile. This integration enables you to access your membership details through your online profile. Additionally, we invite you to enhance your Four Seasons experience by downloading our exclusive app. With the app, you can effortlessly redeem your discounts and vouchers at the Four Seasons Kuala Lumpur hotel.</span><span data-ccp-props="{&quot;201341983&quot;:0,&quot;335559739&quot;:160,&quot;335559740&quot;:259}">&nbsp;</span></p>',
              activationEmailIntro:
                "<p>Congratulations, your Kuala Lumpur Gold &ldquo;Lifestyle by Four Seasons&rdquo; membership is officially activated! Remember to download our mobile app and login to access your benefits.</p>",
              global: {
                overview:
                  "Explore, discover, and enjoy the perks of your membership program all in one place.",
                disclaimer:
                  "For any questions or assistance, please contact the hotel directly.",
              },
              membership: {
                id: "life-by-fs",
                image: {
                  url: "https://reservations.project1.stage.fsweb.ca/alt/img-opt/~60/author/content/dam/fourseasons/images/fsbh/home/Img_Promo_Knowledgebase.jpg",
                },
                greencloudHotelGroupCode: "LBFS",
                membershipManagerEmail: "caleb@entropy.cc",
                termsAndConditions: "These are some terms and conditions",
              },
              property: {
                officialName: "Four Seasons Hotel Kuala Lumpur",
              },
            },
            Expired: "False",
            cardId: "00002023102494083285",
            cardMaster: "",
            cardMasterNo: "",
            cardNo: "KUA10119",
            endDate: "2026-10-23 19:42:08",
            hotelCode: "KUA",
            memberID: "00002023102477806059",
            membershipId: "life-by-fs",
            membershipTierId: "KLG",
            sta: "S",
          },
          {
            Content: {
              id: "KLS",
              name: "Spa & Fitness",
              cardImage: {
                url: "https://reservations.project1.stage.fsweb.ca/alt/img-opt/~60/author/content/dam/fourseasons/images/demo/card-spa-large-original.jpg",
              },
              discounts: ["35% off spa"],
              vouchers: [
                "One accommodation voucher for a complimentary one-night stay in a Junior Suite with Executive Club Lounge access for two",
              ],
              fee: {
                amount: 1888,
                currency: "MYR",
              },
              description:
                "Explore, discover, and enjoy the perks of your membership program all in one place.",
              disclaimer:
                "For any questions or assistance, please contact the hotel membership team: lifestyle.kualalumpur@fourseasons.com.",
              enrollmentEmailIntro:
                '<p>We would like to acknowledge receipt of your enrollment form. Thank you for taking the first step towards joining the program!</p>\r\n<p>Here&rsquo;s what you can expect next:</p>\r\n<ol>\r\n<li>Payment Process: You will receive a separate communication with detailed instructions on how to make your payment securely. Please keep an eye on your inbox for this important message. If you do not receive this email within 48 hours, please check your spam or promotions folder, and if it&rsquo;s not there, feel free to reach out to our Lifestyle team at <a href="mailto:lifestyle.kualalumpur@fourseasons.com">lifestyle.kualalumpur@fourseasons.com</a>.</li>\r\n<li>Membership Benefits: Once we have confirmed payment, you will receive another confirmation email notifying you that your benefits are accessible through our mobile app. Download and login to the app to access your benefits.</li>\r\n</ol>\r\n<p><span data-contrast="auto">Did you already purchase a membership? Then we are delighted to inform you that your membership has now been successfully integrated with your Four Seasons online profile. This integration enables you to access your membership details through your online profile. Additionally, we invite you to enhance your Four Seasons experience by downloading our exclusive app. With the app, you can effortlessly redeem your discounts and vouchers at the Four Seasons Kuala Lumpur hotel.</span><span data-ccp-props="{&quot;201341983&quot;:0,&quot;335559739&quot;:160,&quot;335559740&quot;:259}">&nbsp;</span></p>',
              activationEmailIntro:
                "<p>Congratulations, your Kuala Lumpur Gold &ldquo;Lifestyle by Four Seasons&rdquo; membership is officially activated! Remember to download our mobile app and login to access your benefits.</p>",
              global: {
                overview:
                  "Explore, discover, and enjoy the perks of your membership program all in one place.",
                disclaimer:
                  "For any questions or assistance, please contact the hotel directly.",
              },
              membership: {
                id: "life-by-fs",
                image: {
                  url: "https://reservations.project1.stage.fsweb.ca/alt/img-opt/~60/author/content/dam/fourseasons/images/fsbh/home/Img_Promo_Knowledgebase.jpg",
                },
                greencloudHotelGroupCode: "LBFS",
                membershipManagerEmail: "caleb@entropy.cc",
                termsAndConditions: "These are some terms and conditions",
              },
              property: {
                officialName: "Four Seasons Hotel Kuala Lumpur",
              },
            },
            Expired: "True",
            cardId: "00002023102494083285",
            cardMaster: "",
            cardMasterNo: "",
            cardNo: "KUA50001",
            endDate: "2023-09-23 19:42:08",
            hotelCode: "KUA",
            memberID: "00002023102477806059",
            membershipId: "life-by-fs",
            membershipTierId: "KLS",
            sta: "G",
          },
          {
            Content: {
              id: "KLF",
              name: "Family Spa & Fitness",
              cardImage: {
                url: "https://reservations.project1.stage.fsweb.ca/alt/img-opt/~60/author/content/dam/fourseasons/images/demo/card-familyspa-large-original.jpg",
              },
              discounts: ["35% off spa for whole family"],
              vouchers: [
                "One accommodation voucher for a complimentary one-night stay in a Junior Suite with Executive Club Lounge access for two",
              ],
              fee: {
                amount: 2488,
                currency: "MYR",
              },
              description:
                "Explore, discover, and enjoy the perks of your membership program all in one place.",
              disclaimer:
                "For any questions or assistance, please contact the hotel membership team: lifestyle.kualalumpur@fourseasons.com.",
              enrollmentEmailIntro:
                '<p>We would like to acknowledge receipt of your enrollment form. Thank you for taking the first step towards joining the program!</p>\r\n<p>Here&rsquo;s what you can expect next:</p>\r\n<ol>\r\n<li>Payment Process: You will receive a separate communication with detailed instructions on how to make your payment securely. Please keep an eye on your inbox for this important message. If you do not receive this email within 48 hours, please check your spam or promotions folder, and if it&rsquo;s not there, feel free to reach out to our Lifestyle team at <a href="mailto:lifestyle.kualalumpur@fourseasons.com">lifestyle.kualalumpur@fourseasons.com</a>.</li>\r\n<li>Membership Benefits: Once we have confirmed payment, you will receive another confirmation email notifying you that your benefits are accessible through our mobile app. Download and login to the app to access your benefits.</li>\r\n</ol>\r\n<p><span data-contrast="auto">Did you already purchase a membership? Then we are delighted to inform you that your membership has now been successfully integrated with your Four Seasons online profile. This integration enables you to access your membership details through your online profile. Additionally, we invite you to enhance your Four Seasons experience by downloading our exclusive app. With the app, you can effortlessly redeem your discounts and vouchers at the Four Seasons Kuala Lumpur hotel.</span><span data-ccp-props="{&quot;201341983&quot;:0,&quot;335559739&quot;:160,&quot;335559740&quot;:259}">&nbsp;</span></p>',
              activationEmailIntro:
                "<p>Congratulations, your Kuala Lumpur Gold &ldquo;Lifestyle by Four Seasons&rdquo; membership is officially activated! Remember to download our mobile app and login to access your benefits.</p>",
              global: {
                overview:
                  "Explore, discover, and enjoy the perks of your membership program all in one place.",
                disclaimer:
                  "For any questions or assistance, please contact the hotel directly.",
              },
              membership: {
                id: "life-by-fs",
                image: {
                  url: "https://reservations.project1.stage.fsweb.ca/alt/img-opt/~60/author/content/dam/fourseasons/images/fsbh/home/Img_Promo_Knowledgebase.jpg",
                },
                greencloudHotelGroupCode: "LBFS",
                membershipManagerEmail: "caleb@entropy.cc",
                termsAndConditions: "These are some terms and conditions",
              },
              property: {
                officialName: "Four Seasons Hotel Kuala Lumpur",
              },
            },
            Expired: "True",
            cardId: "00002023102494083285",
            cardMaster: "",
            cardMasterNo: "",
            cardNo: "KUA60003",
            endDate: "2023-10-23 19:42:08",
            hotelCode: "KUA",
            memberID: "00002023102477806059",
            membershipId: "life-by-fs",
            membershipTierId: "KLF",
            sta: "T",
          },
        ],
        accountID: "001Aq00000HYu7dIAD",
      });
      subscriber.complete();
    }, 5000);
  });
}
*/
