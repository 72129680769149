export const STEP_2_SUB_STEPS = {
  TWO_A: "2a",
  TWO_B: "2b",
};
export const WEB_CHAT_STATE = {
  HIDDEN: "hidden",
  SHOWN: "shown",
  OPEN: "open",
  CLOSED: "closed", // the user has closed the Widget
};
export const STEPS = {
  ONE: "step-1",
  TWO: STEP_2_SUB_STEPS,
  THREE: "step-3",
  FOUR: "step-4",
  UPCOMING_TRIP: "upcoming-trip",
  ITINERARY: "itinerary",
};
export const PILLOW = "Pillow";
export const MATTRESS = "Mattress";
export const ROOM = "Room";
export const FOOD_AND_DRINK = "Food & Drink";
export const HEALTH_AND_WELLNESS = "Health & Wellness";
export const TRAVEL_AND_LIFESTYLE = "Travel & Lifestyle";
export const NEW_PREFERENCE = "NEWPREFERENCE";
export const NEW_SUBSCRIPTION = "NEWSUBSCRIPTION";
export const CONSENT_SOURCE = "Web";
export const NEW_EMAIL_ID = "dummySalesForceIdForNewEmail";
export const NEW_PHONE_NUMBER_ID = "dummySalesForceIdForNewPhoneNumber";
export const NEW_ADDRESS_ID = "dummySalesForceIdForNewAddress";
export const PERSONAL_INFO_TYPE = ({ isEmail = false } = {}) => ({
  HO: isEmail ? "Personal" : "Home",
  BU: "Business",
  TP: "Other",
  HOME: "HOME",
  WORK: "Business",
  MOBILE: "Mobile",
  BUSINESS: "Business",
});
export const NEW_EMAIL = {
  email: "",
  emailType: "HO",
  defaultEmail: false,
  isLogin: false,
  salesForceId: NEW_EMAIL_ID,
};
export const NEW_PHONE_NUMBER = {
  number: "",
  defaultPhone: false,
  isLogin: false,
  salesForceId: NEW_PHONE_NUMBER_ID,
};
export const NEW_ADDRESS = {
  addressLines: "",
  defaultAddress: false,
  city: "",
  country: { code: "" },
  postCode: "",
  state: "",
  salesForceId: NEW_ADDRESS_ID,
};
export const OPTING_OPTIONS = {
  IN: "Explicit Opt In",
  OUT: "Opted Out",
};
export const RANGE_TYPE = {
  LOCAL: "Local",
  GLOBAL: "Global",
};
export const subscriptions = [
  {
    mailingList: "Four Seasons Offers and Promotions",
    name: "offersAndPromotions",
    text: "Four Seasons Offers and Promotions",
    analyticsCode: "offers",
  },
  {
    mailingList: "Four Seasons Residence Club and Private Residence",
    name: "privateResidences",
    text: "Four Seasons Private Residences",
    analyticsCode: "pr",
  },
  {
    mailingList: "Survey Emails",
    name: "surveys",
    text: "Market Research Surveys",
    analyticsCode: "survey",
  },
];
export const deprecatedSubscriptions = ["Four Seasons Magazine Email"];
export const hiddenSubscriptions = [
  ...deprecatedSubscriptions,
  "Four Seasons Yachts",
];

// Defined here: http://developers.git.workbench.fourseasons.com/content-mapping/modules/common.html#bookingerror
export const SEARCH_FORM_FIELDS = {
  PROPERTY: "destination.locationByCode.code",
  CHECK_IN: "checkinDate",
  CHECK_OUT: "checkoutDate",
  PROMOCODE: "discountCode",
};

export const RUSSIA = {
  NAME: "Russian Federation",
  CODE: "RU",
};
export const TECHNICAL_ERROR_MESSAGE =
  "We are sorry but we are experiencing some technical difficulty";

export const LOCALE_CONTACT_NUMBERS = {
  zh: [{ number: "+864001487200" }],
  jp: [{ number: "+810120024754" }],
  es: [
    {
      name: "Argentina",
      number: "+5408002221009",
    },
    {
      name: "España",
      number: "+340080064886488",
    },
    {
      name: "México",
      number: "+520018883046755",
    },
  ],
};

export const NEXT_BOOKING_PHASES = {
  ADD_CONTACT: "add-contact",
  ADD_CUSTOMERS: "add-customers",
  ADD_ALL_PAYMENT: "add-all-payment",
  VALIDATE_BOOKING: "validate-booking",
  MAKE_RESERVATION: "make-reservation",
  ALIPAY: "alipay",
};

export const ALI_PAY_FAILED_ERROR = () =>
  "Your chosen payment method could not be completed. Please try again or select another payment method to complete your booking.";
export const ALI_PAY_SUCCESS_ERROR = ({ reservationPhone }) =>
  `br /> IMPORTANT - We are sorry but your booking cannot be completed at this time. However, your payment has been sent via Alipay as requested. <br /> Please call the hotel directly for assistance ${reservationPhone}`;

export const DATE_CHANGE_ERROR =
  "Dates could not be changed. Please try again later";

export const SORT_BY_RATE = {
  HIGH_TO_LOW: "HighToLow",
  LOW_TO_HIGH: "LowToHigh",
  BY_RATE: "DEFAULT",
};

export const SIGN_IN_STATES = {
  FULLY_SIGNED_IN: 2,
  PERSISTENT: 1,
  ANONYMOUS: 0,
};
