import React from "react";

import { useTranslation } from "hooks";
import ChatIcon from "components/ChatIcon";
import useWebChat from "hooks/useWebChat";

export function ChatButton({ className = "web-chat-button", onClick }) {
  const { t } = useTranslation();
  return (
    <div
      className={className}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
      aria-label={t("Chat With Us")}
    >
      <ChatIcon />
    </div>
  );
}

export default function WebChat({
  hotelCode,
  className,
  webChatParams = {},
  searchParams,
}) {
  const { isInitialised, isOpen, openWebChat } = useWebChat({
    hotelCode,
    searchParams,
    ...webChatParams,
  });

  if (!isInitialised || isOpen) {
    return null;
  }

  return <ChatButton className={className} onClick={openWebChat} />;
}
