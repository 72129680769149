import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTranslation } from "hooks";
import PropertyContactDetails from "./PropertyContactDetails";
import ReservationsContactDetails from "./ReservationsContactDetails";

const ErrorMessageContent = ({
  htmlTag = "span",
  dictKey,
  dictParams = [],
  isHtml = false,
  ...props
}) => {
  const { t } = useTranslation();
  const translated = t(dictKey, dictParams);
  if (isHtml) {
    return React.createElement(htmlTag, {
      ...props,
      dangerouslySetInnerHTML: { __html: translated },
    });
  }
  return React.createElement(htmlTag, props, translated);
};

export default function ErrorMessage({
  contactReservations,
  descriptionDictKey,
  labelDictKey,
  messageDictKey,
  propertyInfo,
  ...props
}) {
  const { t } = useTranslation();
  const [isOpenForMobile, setIsOpenForMobile] = useState(false);
  const toggleIsOpenForMobile = () => setIsOpenForMobile(!isOpenForMobile);

  const showContactDetails = Boolean(contactReservations);

  return (
    <div className="fs-errors-container" {...props}>
      <section className="container">
        <div
          className={classNames("row error", {
            "is-shown": isOpenForMobile,
          })}
        >
          <div
            id="error-message-container"
            className={classNames("error-contact-flush", {
              "col-md-12": !showContactDetails,
              "col-md-7": showContactDetails,
            })}
            tabIndex="-1"
          >
            <h2 id="errorMessage">
              {labelDictKey && (
                <>
                  <ErrorMessageContent
                    isHtml={labelDictKey.html}
                    dictKey={labelDictKey.key || labelDictKey}
                    dictParams={labelDictKey.params}
                    className="error-label"
                  />
                  :&nbsp;
                </>
              )}

              <ErrorMessageContent
                isHtml={messageDictKey.html}
                dictKey={messageDictKey.key || messageDictKey}
                dictParams={messageDictKey.params}
              />
            </h2>

            {descriptionDictKey && (
              <ErrorMessageContent
                htmlTag="p"
                isHtml={descriptionDictKey.html}
                dictKey={descriptionDictKey.key || descriptionDictKey}
                dictParams={descriptionDictKey.params}
              />
            )}

            {showContactDetails && (
              <button
                className="error-contact-details-toggle"
                type="button"
                onClick={toggleIsOpenForMobile}
              >
                {t("Contact Details")}
                <i className="icon-arrow-down icon-large" />
                <i className="icon-arrow-up icon-large" />
              </button>
            )}
          </div>

          {propertyInfo && <PropertyContactDetails {...propertyInfo} />}

          {contactReservations && (
            <ReservationsContactDetails {...contactReservations} />
          )}
        </div>
      </section>
    </div>
  );
}

const BookingError = PropTypes.shape({
  html: PropTypes.bool.isRequired,
  key: PropTypes.string.isRequired,
  params: PropTypes.arrayOf(PropTypes.string).isRequired,
});

ErrorMessage.propTypes = {
  contactReservations: PropTypes.shape({
    globalSupportPhone: PropTypes.string,
    globalTollFreeLink: PropTypes.shape({
      textDictKey: PropTypes.oneOfType([PropTypes.string, BookingError]),
      url: PropTypes.string,
    }),
    propertyName: PropTypes.string.isRequired,
    propertyPhone: PropTypes.string,
  }),
  descriptionDictKey: PropTypes.oneOfType([PropTypes.string, BookingError]),
  labelDictKey: PropTypes.oneOfType([PropTypes.string, BookingError]),
  messageDictKey: PropTypes.oneOfType([PropTypes.string, BookingError]),
  propertyInfo: PropTypes.shape({
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    link: PropTypes.shape({
      text: PropTypes.string,
      textDictKey: PropTypes.string,
      url: PropTypes.string.isRequired,
    }),
    phone: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
  }),
};

ErrorMessage.defaultProps = {
  contactReservations: undefined,
  descriptionDictKey: undefined,
  labelDictKey: undefined,
  messageDictKey:
    "We are sorry but we are experiencing some technical difficulty",
  propertyInfo: undefined,
};
