import React from "react";
import trim from "lodash/fp/trim";

import { useTranslation } from "hooks";
import { parse, format } from "date-fns";
import formatBeverlyWilshire from "Profile/utils/formatBeverlyWilshire";
import MembershipCard from "../components/MembershipCard";

export default function MembershipViewHeader({
  membership,
  profile,
  propertyContent,
  startSmooch,
  isWebChatEnabled,
  isInitialised,
}) {
  const { t } = useTranslation();
  const memberDetail = true;

  let status = "";
  if (membership?.endDate) {
    const expDate = parse(
      membership?.endDate,
      "yyyy-MM-dd HH:mm:ss",
      new Date()
    );
    status = format(expDate, "dd MMM, yyyy");
  }

  const address = [propertyContent?.street, propertyContent?.city]
    .map(trim)
    .filter(Boolean)
    .join(", ");

  return (
    <>
      <div className="fs-membership-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-10">
              <div className="fs-membership-header__container">
                <div className="fs-membership-header__contact-buttons">
                  <a
                    className="contact-button"
                    href={`tel:${propertyContent?.reservationPhone}`}
                  >
                    <div className="contact-button__icon contact-button__icon--call" />
                    <span>{t("call us")}</span>
                  </a>

                  {isWebChatEnabled && isInitialised && (
                    <button
                      type="button"
                      className="contact-button"
                      onClick={startSmooch}
                    >
                      <div className="contact-button__icon contact-button__icon--chat" />
                      <span>{t("chat")}</span>
                    </button>
                  )}
                </div>
                <div className="fs-membership-header__property-container">
                  <h1
                    className="fs-membership-header__property-name"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        formatBeverlyWilshire(propertyContent?.shortName)
                      ),
                    }}
                  />
                  <div className="fs-membership-header__property-address">
                    {address}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="fs-membership-header__image">
                <MembershipCard
                  membership={membership}
                  memberDetail={memberDetail}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fs-membership-details">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="fs-membership-details-heading__container">
                <div className="fs-membership-details-heading ty-c3">
                  {t("Membership Details")}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <div className="fs-membership-details-item-heading__container">
                <div className="fs-membership-details-item-heading ty-c3">
                  {t("Membership Holder")}
                </div>
                <div className="fs-membership-details-item fs-member-name">
                  {`${profile?.name.firstName} ${profile?.name.surname}`}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6">
              <div className="fs-membership-details-item-heading__container">
                <div className="fs-membership-details-item-heading ty-c3">
                  {t("Tier")}
                </div>
                <div className="fs-membership-details-item">
                  {membership?.Content.name}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <div className="fs-membership-details-item-heading__container">
                <div className="fs-membership-details-item-heading ty-c3">
                  {t("Card Number")}
                </div>
                <div className="fs-membership-details-item">
                  {membership?.cardNo}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6">
              <div className="fs-membership-details-item-heading__container">
                <div className="fs-membership-details-item-heading ty-c3">
                  {t("Valid Until")}
                </div>
                <div className="fs-membership-details-item">{status}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
