import { ajax } from "rxjs/ajax";
import { EMPTY, of } from "rxjs";

import env from "config/env";
import { basePostRequest } from "../../tretail/baseRequest";

const { EMAIL_API_BASE_URL, EMAIL_FRIENDS_AND_FAMILY_ENABLED } = env;

export default function employeeSpecialRateRequest$({
  rooms,
  specialRequests,
  totalNights,
  checkOutDate,
  checkInDate,
  guestMobilePhone,
  guestEmail,
  guestFullName,
  employeeWorkplace,
  employeeName,
  employeeEmail,
  employeePhone,
  relationshipToGuest,
  hotelCode,
}) {
  return EMAIL_FRIENDS_AND_FAMILY_ENABLED
    ? ajax({
        ...basePostRequest(),
        url: `${EMAIL_API_BASE_URL}/employeeSpecialRateRequest`,
        body: {
          rooms,
          specialRequests,
          totalNights,
          checkOutDate,
          checkInDate,
          guestMobilePhone,
          guestEmail,
          guestFullName,
          employeeWorkplace,
          employeeName,
          employeeEmail,
          employeePhone,
          relationshipToGuest,
          hotelCode,
        },
      })
    : of(EMPTY);
}
