// TO KEEP CLASSNAMES APPLIED TO THE HTML TAG GIVING THE WEBCHAT IFRAME CORRECT SIZES
function getHMTLTagClassNames({
  locale,
  dir,
  kind = "fs-booking-flow", // "fs-booking-flow" || "fs-profile-2018"
}) {
  return [
    kind,
    locale,
    dir,

    // existin classNames on HTML tga
    ...document
      .querySelector("html")
      .className.split(/\s+/)
      .filter(
        (s) => !/^(fs-booking-flow|fs-profile-2018|ltr|rtl|.{2})$/.test(s)
      ),
  ]
    .filter(Boolean)
    .join(" ");
}

export default getHMTLTagClassNames;
