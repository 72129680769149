import React, { useEffect, useRef, useState } from "react";

function OtpInput({
  pattern = /\d/,
  length = 6,
  onChange = () => {},
  onFocus = () => {},
  onKeyUp = () => {},
  showPersistentOption = false,
}) {
  const [values, setValues] = useState(
    new Map([...new Array(length)].map((_, index) => [index, ""]))
  );
  const valuesArray = Array.from(values.entries());
  const valueString = Array.from(values.values()).join("");
  const inputRefs = valuesArray.map(useRef);

  const focusInput = (index) => {
    if (inputRefs[index]) {
      inputRefs[index].current.focus();
    }
  };

  useEffect(() => {
    focusInput(0);
  }, []);

  useEffect(() => {
    onChange(valueString);
  }, [valueString]);

  const setValueAtIndex = (index, value) => {
    if (value.length === length) {
      setValues(new Map(value.split("").map((v, i) => [i, v])));
    } else {
      const newValues = new Map(values);
      newValues.set(index, value.substring(0, 1));
      setValues(newValues);
    }
  };

  const handleChange =
    (index) =>
    ({ target: { value } }) => {
      if (pattern.test(value) || value === "") {
        setValueAtIndex(index, value);
      }
    };

  const handleFocus = (evt) => {
    evt.target.select();
    onFocus(evt);
  };

  const handleKeyUp = (index) => (evt) => {
    if (pattern.test(evt.target.value)) {
      focusInput(index + 1);
    }
    onKeyUp(evt);
  };

  return (
    <div
      className={`verification-code${
        showPersistentOption ? " persistent-login-verification-code" : ""
      }`}
    >
      {valuesArray.map(([key, value]) => (
        <input
          ref={inputRefs[key]}
          key={key}
          name={`verification-code__input-${key}`}
          className="fsp-input fsp-input--verification-code"
          type="tel"
          autoComplete="new"
          minLength="1"
          maxLength={length}
          pattern={pattern}
          value={value}
          onChange={handleChange(key)}
          onFocus={handleFocus}
          onKeyUp={handleKeyUp(key)}
        />
      ))}
    </div>
  );
}

export default OtpInput;
