/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  error: "",
};

const globalSettingsSlice = createSlice({
  name: "globalSettings",
  initialState,
  reducers: {
    fetchGlobalSettings: (state) => {
      state.error = "";
    },

    fetchGlobalSettingsFulfilled: (state, { payload }) => {
      state.data = payload;
      state.error = "";
    },

    fetchGlobalSettingsFailed: (state, { payload }) => {
      state.data = {};
      state.error = payload.error;
    },

    fetchGlobalSettingsCancel: () => {},
  },
});

export const {
  fetchGlobalSettings,
  fetchGlobalSettingsFulfilled,
  fetchGlobalSettingsFailed,
  fetchGlobalSettingsCancel,
} = globalSettingsSlice.actions;

export default globalSettingsSlice.reducer;
