import {
  deleteUserAddress,
  deleteUserAddressFulfilled,
} from "store/profile/profile.slice";
import { deleteAddress } from "../profile.helpers";
import { updateProfileTemplate } from "./updateProfile";

export default updateProfileTemplate({
  actionType: deleteUserAddress.type,
  fulfillAction: deleteUserAddressFulfilled,
  updateFn: ({ address = [] } = {}, newValue) => ({
    address: deleteAddress(address, newValue),
  }),
});
