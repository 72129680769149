import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";

import env from "config/env";
import { baseGetRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;
const TRETAIL_API_RESULT_SETS_URL = `${TRETAIL_API_BASE_URL}/hotel/resultSets`;

export default function getHotelSearchResults$({ resultSetId, locale }) {
  return ajax({
    ...baseGetRequest({ locale }),
    url: `${TRETAIL_API_RESULT_SETS_URL}/${resultSetId}`,
  }).pipe(pluck("response"));
}
