import React from "react";
import { Button } from "Profile/components";
import { DictionaryKeyContent } from "BookingFlow/components";
import { EMPLOYEE_SEARCH_ERRORS } from "store/searchResults/checkEmployeeSearchParams";
import { useTranslation, useDispatchWithLocale } from "hooks";
import { clearErrors } from "store/searchResults";
import { clearBookingErrors } from "store/bookings";

function ErrorBanner({ bookingError, employeeError, error }) {
  const { t } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();
  return (
    <div className="error-banner">
      <div className="message">
        {bookingError && (
          <DictionaryKeyContent dict={bookingError.messageDictKey} />
        )}

        {!bookingError && employeeError && (
          <DictionaryKeyContent
            dict={{ key: employeeError.errorCode, ...employeeError }}
          />
        )}

        {!bookingError && !employeeError && error && (
          <DictionaryKeyContent dict={EMPLOYEE_SEARCH_ERRORS.TECHNICAL} />
        )}
      </div>
      <div className="actions">
        <Button
          type="button"
          onClick={() => {
            dispatchWithLocale(clearErrors());
            dispatchWithLocale(clearBookingErrors());
          }}
        >
          {t("Close")}
        </Button>
      </div>
    </div>
  );
}

export default ErrorBanner;
