import contentAjax from "api/content/contentAjax";

export default function getUpsells$({
  locale = "en",
  propertyCode,
  retryInEN,
}) {
  return contentAjax({
    locale,
    url: `/properties/${propertyCode}/upsells`,
    retryInEN,
  });
}
