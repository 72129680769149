import React from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";
import env from "config/env";

const { FOUR_SEASIONS_DOMAIN, MAPS_LINK } = env;

export default function ConfirmationContact({
  property,
  facebookMessengerPageId,
  facebookMessengerAppId,
}) {
  const { t } = useTranslation();

  const {
    name,
    latitude,
    longitude,
    street,
    city,
    state,
    postalCode,
    phone,
    propertyEmail,
  } = property;

  const propertyAddress = [
    street,
    city,
    [state, postalCode].filter(Boolean).join(" "),
  ]
    .filter(Boolean)
    .join("<br />");

  return (
    <div
      className={classNames("confirmation-contact", {
        "facebook-messenger": facebookMessengerAppId,
      })}
    >
      <div className="contact-icons">
        {latitude && longitude && (
          <a
            className="directions-link"
            href={`${MAPS_LINK}?q=${latitude},${longitude}`}
          >
            <i className="icon-location-thin" />
            {t("Directions")}
          </a>
        )}

        {phone?.number && (
          <a dir="ltr" href={`tel:+${phone.number}`}>
            <i className="icon-phone-thin" />
            {t("Phone")}
          </a>
        )}

        {propertyEmail && (
          <a href={`mailto:+${propertyEmail}`}>
            <i className="icon-email-thin" />
            {t("Email")}
          </a>
        )}

        {facebookMessengerAppId && (
          <div className="facebook-messenger">
            <div
              className="fb-messengermessageus"
              messenger_app_id={facebookMessengerAppId}
              page_id={facebookMessengerPageId}
              color="white"
              size="large"
            />
          </div>
        )}
      </div>

      <p className="hotel-details-property-title">
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <a
          href={FOUR_SEASIONS_DOMAIN + name.url}
          className="hotel-name"
          dangerouslySetInnerHTML={{ __html: name.text }}
        />
      </p>
      <p
        className="hotel-details-property-address"
        dangerouslySetInnerHTML={{ __html: propertyAddress }}
      />
    </div>
  );
}
