/* eslint-disable no-unused-vars */
import { createMiddleware } from "redux-beacon";
import { fetchSearchResultsFulfilled } from "store/searchResults";
import { addPackageFulfilled, completeBookingFulfilled } from "store/bookings";
import {
  recordSearch,
  decisionEngineTarget,
  recordReservation,
} from "../DecisionEngine/decision-engine";

const eventsMap = {
  [completeBookingFulfilled.type]: recordReservation(),
  [fetchSearchResultsFulfilled.type]: recordSearch("search"),
  [addPackageFulfilled.type]: recordSearch("confirm_stay"),
};

export default createMiddleware(eventsMap, decisionEngineTarget());
