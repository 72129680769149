import React from "react";

import {
  getCheckInDay,
  getCheckInDate,
  getCheckInMonthAndYear,
} from "./calendarHelpers";

export default function SmallScreenSelectDate({
  date,
  locale,
  onClick,
  isCheckIn = false,
  label,
}) {
  const id = isCheckIn ? "mobilecheckin" : "mobilecheckout";

  return (
    <div className={`fs-select-date ${isCheckIn ? "check-in" : "check-out"}`}>
      <label htmlFor={id} aria-label="Arrival Date">
        {label}
      </label>
      <button
        id={id}
        aria-label="Arrival Date"
        className="date-trigger"
        type="button"
        onClick={onClick}
      >
        <span className="date-trigger-day ">{getCheckInDay(date, locale)}</span>
        <div className="date-trigger__date-month">
          <span className="date-trigger-date">
            {getCheckInDate(date, locale)}
          </span>
          <span className="date-trigger-month">
            {getCheckInMonthAndYear(date, locale)}
          </span>
        </div>
      </button>
    </div>
  );
}
