function getPropertyAddress(propertyContent) {
  const { street, city, state, zipBeforeCountry, zipcode, country } =
    propertyContent;

  if (zipBeforeCountry) {
    return [street, city, [state, zipcode].filter(Boolean).join(" "), country]
      .filter(Boolean)
      .join("\n");
  }

  return [street, city, state, country, zipcode].filter(Boolean).join("\n");
}

export default getPropertyAddress;
