import { createAction } from "@reduxjs/toolkit";

export const fetchAllPropertyContent = createAction(
  "allPropertyContent/fetchAllPropertyContent"
);

export const fetchAllPropertyContentFulfilled = createAction(
  "allPropertyContent/fetchAllPropertyContentFulfilled"
);

export const fetchAllPropertyContentFailed = createAction(
  "allPropertyContent/fetchAllPropertyContentFailed"
);

export const fetchAllPropertyContentCancel = createAction(
  "allPropertyContent/fetchAllPropertyContentCancel"
);
