import React from "react";
import classNames from "classnames";

import * as regexPatterns from "utils/regexPatterns";
import { useTranslation } from "hooks";

export default function AdultGuestRow({
  roomIndex,
  index,
  register,
  errors = {},
  isMultiRoomBooking,
  ...props
}) {
  const { t } = useTranslation();
  const uniqueId = `guest-name-${roomIndex}-${index}`;
  const label = `${t("Guest")} ${index + 1} ${t("Name")}`;
  const hasError = errors.name;

  return (
    <div>
      <div
        className={classNames("form-row", {
          "has-errors": hasError,
        })}
      >
        <label htmlFor={uniqueId}>{label}</label>
        <input
          id={uniqueId}
          name={`comments[${roomIndex}].roomGuests[${index}].name`}
          type="text"
          readOnly={!isMultiRoomBooking && roomIndex === 0 && index === 0}
          placeholder={label}
          ref={register({
            pattern: regexPatterns.noDigits,
          })}
          {...props}
        />

        {hasError && (
          <div className="inline-error">
            <span className="message">
              {t("Please enter a valid response.")}
            </span>
            <span className="icon">&#33;</span>
          </div>
        )}
      </div>
    </div>
  );
}
