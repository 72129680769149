import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "hooks";
import { Helmet } from "react-helmet-async";
import classNames from "classnames";
import queryString from "query-string";

import { MediaContext } from "contexts/MediaContext";
import { getIsoCodeForLocale } from "config/languages";
import { FsHeader } from "Profile/components";
import getFullPageTitle from "utils/getFullPageTitle";
import { getHMTLTagClassNames } from "utils";

function AuthenticationLayout({
  pageTitle,
  htmlBodyClassName,
  backgroundImageSrc,
  hideLanguageSelector,
  fullWidthRender = () => {},
  children,
  enableProfileLinkFlyout,
}) {
  const { t, i18n, locale } = useTranslation();
  const media = useContext(MediaContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const queryParams = queryString.parse(useLocation()?.search || "");
  const isYachtsMode = queryParams?.yachts === "true";

  return (
    <>
      <Helmet>
        <html
          lang={getIsoCodeForLocale(i18n.language)}
          className={getHMTLTagClassNames({
            locale,
            dir: i18n.dir(i18n.language),
            kind: "fs-profile-2018",
          })}
        />
        <title>{getFullPageTitle(t, pageTitle)}</title>
        <body
          className={classNames(htmlBodyClassName, {
            "no-y-scroll": isMenuOpen,
          })}
        />
      </Helmet>

      <div className={`layout--profile-auth ${i18n.dir(i18n.language)}`}>
        <FsHeader
          enableProfileLinkFlyout={enableProfileLinkFlyout}
          hideLanguageSelector={hideLanguageSelector}
          isMenuOpen={isMenuOpen}
          isMobile={media.isLessThan.sm}
          setIsMenuOpen={setIsMenuOpen}
        />

        <main className="layout--profile-auth__body">
          {fullWidthRender()}
          <div className="layout--profile-auth__container">
            <div className="layout--profile-auth__content">{children}</div>
          </div>

          <div
            className={classNames("layout--profile-auth__background-image", {
              yachts: isYachtsMode,
            })}
            style={
              backgroundImageSrc && {
                backgroundImage: `url(${backgroundImageSrc})`,
              }
            }
          />
        </main>
      </div>
    </>
  );
}

export default AuthenticationLayout;
