import React, { useRef, useState } from "react";
import classNames from "classnames";
import debounce from "lodash/debounce";

import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from "Profile/ItineraryView/components";
import AncillaryCard from "./AncillaryCard";
import FeaturedAncillaryCard from "./FeaturedAncillaryCard";

export default function AncillaryCards({
  ancillaries = [],
  numberDisplayed = 3,
}) {
  const [cardIndex, _setCardIndex] = useState(0);
  const [dotIndex, setDotIndex] = useState(0);

  const ref = useRef(null);

  const isRTL = document.dir === "rtl";

  const min = 0;
  const max = ancillaries.length - numberDisplayed;

  function setCardIndex(n) {
    const newCardIndex = Math.min(Math.max(cardIndex + n, min), max);
    const { scrollWidth, clientWidth } = ref.current;
    const { offsetLeft } = ref.current.children[newCardIndex];

    const left = isRTL
      ? -1 *
        (scrollWidth -
          clientWidth -
          (clientWidth + offsetLeft - (numberDisplayed - 1) * 20))
      : offsetLeft;

    ref.current.scrollTo({
      left,
      // behavior: "smooth" - handled in CSS "scroll-behavior: smooth;"
    });

    _setCardIndex(newCardIndex);
  }

  function handleScroll(evt) {
    const { scrollLeft } = evt.target;
    const { scrollWidth, clientWidth } = ref.current;
    const newCardIndex = Array.from(ref.current.children)
      .map((el) => el.offsetLeft)
      .map((offsetLeft) => {
        return isRTL
          ? -1 * (scrollWidth - clientWidth - (clientWidth + offsetLeft))
          : offsetLeft;
      })
      .findIndex((offsetLeft) => {
        return isRTL ? scrollLeft > offsetLeft : scrollLeft < offsetLeft;
      });

    _setCardIndex(newCardIndex);
    setDotIndex(newCardIndex);
  }

  const handleScrollDebounced = debounce(handleScroll, 300);

  return (
    <div className="ancillary-cards">
      {ancillaries.length === 1 && (
        <div className="ancillary-cards__featured-ancillary-card">
          <FeaturedAncillaryCard
            title={ancillaries[0].title}
            subTitle={ancillaries[0].subTitle}
            image={ancillaries[0].image}
            description={ancillaries[0].description}
            cta={ancillaries[0].cta}
          />
        </div>
      )}

      {ancillaries.length > 3 && (
        <div className="ancillary-cards__btns">
          <button
            disabled={cardIndex === min}
            onClick={() => setCardIndex(-1)}
            className="ancillary-cards__btn"
            type="button"
          >
            {isRTL ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </button>

          <button
            disabled={cardIndex === max}
            onClick={() => setCardIndex(1)}
            className="ancillary-cards__btn"
            type="button"
          >
            {isRTL ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </button>
        </div>
      )}

      {ancillaries.length > 1 && (
        <div
          ref={ref}
          className={classNames("ancillary-cards__row", {
            "ancillary-cards__row--two": ancillaries.length === 2,
          })}
          onScroll={handleScrollDebounced}
        >
          {ancillaries.map(
            ({ title, subTitle, image, description, cta, id }) => {
              return (
                <div className="ancillary-cards__col" key={id}>
                  <AncillaryCard
                    title={title}
                    subTitle={subTitle}
                    image={image}
                    description={description}
                    cta={cta}
                    isWide={ancillaries.length === 2}
                  />
                </div>
              );
            }
          )}
        </div>
      )}
      {ancillaries.length > numberDisplayed && (
        <div className="ancillary-cards__dots">
          {ancillaries.slice(0, max + 1).map((ancillary, index) => {
            return (
              <div
                key={ancillary.id}
                className={classNames("ancillary-cards__dot", {
                  "ancillary-cards__dot--selected": dotIndex === index,
                })}
                role="presentation"
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
