import React from "react";

export default function RemoveIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30ZM15 29.25C22.8701 29.25 29.25 22.8701 29.25 15C29.25 7.12994 22.8701 0.75 15 0.75C7.12994 0.75 0.75 7.12994 0.75 15C0.75 22.8701 7.12994 29.25 15 29.25ZM18.55 14.73H11.95V15.93H18.55V14.73Z"
        fill="#D8D8D8"
      />
    </svg>
  );
}
