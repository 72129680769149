import React, { useContext } from "react";
import { format, parseISO } from "date-fns";
import * as dateFnLocales from "date-fns/locale";

import { useTranslation } from "hooks";
import { getDateFnsLocale } from "config/languages";
import { MediaContext } from "contexts/MediaContext";

const formatDate = (locale) => (date) => {
  const f = (formatString) => {
    return format(parseISO(date), formatString, {
      locale: dateFnLocales[getDateFnsLocale(locale)],
    });
  };

  switch (locale) {
    case "zh":
    case "zh_hant":
    case "jp":
      return f("yyyy'年'MM'月'dd'日'");

    case "kr":
      return f("yyyy'년'MM'월'dd'일'");

    default:
      return f("MM/dd/yyyy");
  }
};

function SearchSummary({
  propertyName,
  checkIn,
  checkOut,
  rooms,
  promoCode,
  flexDatesWindow,
  hidePromoCodeField = false,
  changeSearch = () => {},
}) {
  const { t, locale } = useTranslation();
  const media = useContext(MediaContext);
  const isMobile = media.isLessThan.sm;

  const numberOfRooms = rooms.length;

  const { adults: numberOfAdults, children: numberOfChildren } = rooms.reduce(
    (totals, room) => ({
      adults: totals.adults + parseInt(room.adults, 10),
      children: totals.children + parseInt(room.children, 10),
    }),
    {
      adults: 0,
      children: 0,
    }
  );

  const handleFormSubmit = (evt) => {
    evt.preventDefault();
    changeSearch();
  };

  const checkInCheckOutValue = [
    [checkIn, checkOut].map(formatDate(locale)).join(" - "),
    flexDatesWindow ? `| ±  ${flexDatesWindow} ${t("Days")}` : null,
  ]
    .filter(Boolean)
    .join(" ");

  const guestsValue = [
    `${numberOfRooms} ${t(numberOfRooms === 1 ? "Room" : "Rooms")}`,
    [
      numberOfAdults > 0 &&
        `${numberOfAdults} ${t(numberOfAdults === 1 ? "Adult" : "Adults")}`,
      numberOfChildren > 0 &&
        `${numberOfChildren} ${t(
          numberOfChildren === 1 ? "Child" : "Children"
        )}`,
    ]
      .filter(Boolean)
      .join(", "),
  ].join(" - ");

  return (
    <div className="search-summary">
      <div className="search-summary__container">
        {isMobile && (
          <>
            <div
              className="ty-c3 search-summary__property-name"
              dangerouslySetInnerHTML={{
                __html: propertyName,
              }}
            />
          </>
        )}

        {!isMobile && (
          <form onSubmit={handleFormSubmit} className="search-summary__form">
            <div className="search-summary__form-fields">
              <div className="search-summary__form-field search-summary__form-field--destination">
                <label className="ty-c3">{t("Find a Destination")}</label>
                <div
                  className="search-summary__form-field__value"
                  dangerouslySetInnerHTML={{
                    __html: propertyName,
                  }}
                />
              </div>
              <div className="search-summary__form-field search-summary__form-field--check-in-check-out">
                <label className="ty-c3">
                  {["Check In", "Check Out"].map(t).join(" - ")}
                </label>
                <div className="search-summary__form-field__value">
                  {checkInCheckOutValue}
                </div>
              </div>
              <div className="search-summary__form-field">
                <label className="ty-c3">{t("Guests")}</label>
                <div className="search-summary__form-field__value">
                  {guestsValue}
                </div>
              </div>
              {!hidePromoCodeField && (
                <div className="search-summary__form-field search-summary__form-field--promo">
                  <label className="ty-c3">{t("Promo Code")}</label>
                  <div className="search-summary__form-field__value">
                    {promoCode}
                  </div>
                </div>
              )}
            </div>
            <div className="search-summary__form-field search-summary__form-field--submit">
              <button type="submit" className="btn-primary">
                {t("Change Search")}
              </button>
            </div>
          </form>
        )}

        {isMobile && (
          <div className="search-summary__row">
            <div className="search-summary__details">
              <div className="search-summary__details__check-in-check-out">
                {checkInCheckOutValue}
              </div>
              <div className="search-summary__details__rooms-and-guests">
                {guestsValue}
              </div>
            </div>
            <div className="search-summary__actions">
              <button
                type="button"
                className="btn-plain"
                onClick={changeSearch}
              >
                {t("Edit")}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchSummary;
