import React, { useState, useEffect } from "react";
import { useTranslation } from "hooks";

function CounterInput({
  label,
  name,
  initialValue = 0,
  min = -Infinity,
  max = Infinity,
  forceCount,
  onChange = () => {},
  allowIncrement = () => true,
  allowDecrement = () => true,
}) {
  const { t } = useTranslation();
  const [count, setCount] = useState(initialValue);

  useEffect(() => onChange(count), [count]);

  useEffect(() => {
    setCount(Math.max(Math.min(count, max), min));
  }, [min, max]);

  useEffect(() => {
    if (forceCount || forceCount === 0) {
      setCount(forceCount);
    }
  }, [forceCount]);

  const canIncrement = () => count < max && allowIncrement();

  const canDecrement = () => count > min && allowDecrement();

  return (
    <div className="CounterInput">
      <label className="formElement-label ty-c4 formElement--focusAlways">
        {label}
      </label>

      <div className="controls">
        <button
          type="button"
          className="decrement"
          aria-label={t("decrement")}
          disabled={!canDecrement()}
          onClick={() => setCount(count - 1)}
        />
        <input name={name} value={count} className="ty-c1" readOnly />
        <button
          type="button"
          className="increment"
          aria-label={t("increment")}
          disabled={!canIncrement()}
          onClick={() => setCount(count + 1)}
        />
      </div>
    </div>
  );
}

export default CounterInput;
