import React from "react";
import { useSelector } from "react-redux";
import update from "lodash/fp/update";

import * as regexPatterns from "utils/regexPatterns";
import { useTranslation } from "hooks";
import {
  Button,
  Checkbox,
  Dropdown,
  ErrorMessage,
  TextInput,
} from "Profile/components";
import { NEW_ADDRESS_ID } from "fixtures/constants";
import { selectCountriesContent } from "store/countriesContent";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";

const addressTypes = [
  { label: "Business", value: "BU" },
  { label: "Home", value: "HO" },
];

function AddressForm({
  address: {
    addressType,
    city,
    country: { code: countryCode },
    postCode,
    addressLines,
    salesForceId,
    state,
  },
  formState,
  register,
  watched,
  onDelete = () => {},
  identifier,
  onSubmit,
  errors,
  baseError,
  isNotLast,
  showLoadingIndicator,
}) {
  const { t } = useTranslation();

  const countries = useSelector(selectCountriesContent);

  const countriesOptions = countries.map(({ shortName, iso2 }) => ({
    value: iso2,
    label: shortName,
  }));

  const isNotNew = salesForceId !== NEW_ADDRESS_ID;

  return (
    <>
      {showLoadingIndicator && <BookingFlowLoadingIndicator />}

      {baseError && <ErrorMessage>{t(baseError.errorCode)}</ErrorMessage>}

      <form onSubmit={onSubmit} noValidate>
        <div className="row">
          <div className="col-sm-12">
            <TextInput
              identifier={`${identifier}-street`}
              name="addressData.addressLines"
              value={addressLines}
              label={t("Street")}
              placeholder={t("Street")}
              error={
                errors.addressData?.addressLines &&
                t("Please enter your street address.")
              }
              register={register({
                required: true,
                pattern: regexPatterns.addressLine,
              })}
              required
            />
          </div>
          <div className="col-sm-12 col-lg-6">
            <Dropdown
              name="addressData.country.code"
              value={countryCode}
              label={t("Country / Region")}
              placeholder={t("Country / Region")}
              identifier={`${identifier}-country`}
              options={countriesOptions}
              register={register({
                required: true,
              })}
              required
              error={
                errors.addressData?.country?.code &&
                t("Please select your country.")
              }
            />
          </div>

          <div className="col-sm-12 col-lg-6">
            <TextInput
              identifier={`${identifier}-city`}
              name="addressData.city"
              label={t("City")}
              value={city}
              error={
                Boolean(errors.addressData?.city) &&
                t("Please enter your city.")
              }
              register={register({
                required: true,
                pattern: regexPatterns.city,
              })}
              required
            />
          </div>

          <div className="col-sm-12 col-lg-6">
            <TextInput
              identifier={`${identifier}-state`}
              name="addressData.state"
              label={t("State / Province")}
              value={state}
              register={register({
                required: true,
                pattern: regexPatterns.state(watched.country.code),
              })}
              required
              error={
                Boolean(errors.addressData?.state) &&
                t("Please enter your state / province.")
              }
            />
          </div>

          <div className="col-sm-12 col-lg-6">
            <TextInput
              identifier={`${identifier}-postCode`}
              name="addressData.postCode"
              label={t("Zip / Postal Code")}
              value={postCode}
              register={register({
                required: true,
                pattern: regexPatterns.postcode(watched.country.code),
              })}
              required
              error={
                Boolean(errors.addressData?.postCode) &&
                t("Please enter your zip code.")
              }
            />
          </div>

          <div className="col-sm-12 col-lg-6">
            <Dropdown
              name="addressData.addressType"
              value={addressType}
              identifier={`${identifier}-type`}
              options={addressTypes.map(update(["label"], t))}
              register={register({
                required: true,
              })}
              label={t("Type")}
              required
              error={
                Boolean(errors.addressData?.addressType) &&
                t("Please select the type of address.")
              }
            />
          </div>
          <div className="col-sm-12 col-md-6 col--primary-address">
            <Checkbox
              identifier={`${identifier}-primary`}
              name="addressData.defaultAddress"
              register={register}
            >
              {t("Primary address")}
            </Checkbox>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="fs-toggle-block__actions">
              {isNotLast && isNotNew && (
                <Button kind="link" onClick={onDelete}>
                  {t("Delete")}
                </Button>
              )}
              <Button
                type="submit"
                aria-label={!isNotNew ? t("Save address") : t("Update address")}
                disabled={!formState.isValid}
              >
                {!isNotNew ? t("Save") : t("Update")}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
export default AddressForm;
