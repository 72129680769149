/* eslint-disable import/prefer-default-export */
import { getSearchData, getCawData } from "rbf-content-mapping";

import { selectPropertyContent } from "store/propertyContent";
import { selectPropertyBookingMessagesContent } from "store/bookingMessages";
import { selectOffer } from "store/offersContent";
import { selectSearchResultTRetailErrors } from "store/searchResults";

export const getData =
  ({
    hotelCode,
    checkIn,
    promoCode,
    flexDatesWindow,
    searchParams,
    locale,
    isEmbedded,
    employee,
  }) =>
  (state) => {
    const {
      calendarAvailabilityEnabled,
      checkInEnd,
      checkInStart,
      checkOutEnd,
      ctaText,
      defaultAdults,
      defaultChildren,
      maxAdults,
      maxBodies,
      maxChildren,
      maxRooms,
      minAdults,
      minChildren,
      minStay,
      ppMode: isPreferredPartners = false,
      promoDisabled,
      promoHide,
      propertyList,
      ratePlanCode,
    } = searchParams;

    const propertyContent = selectPropertyContent(hotelCode)(state);
    const offer = selectOffer(ratePlanCode)(hotelCode);
    const tRetailErrors = selectSearchResultTRetailErrors(state);
    const availabilityBookingMessages = selectPropertyBookingMessagesContent({
      hotelCode,
      bookingStep: "availability",
    })(state);
    const soldOutBookingMessages = selectPropertyBookingMessagesContent({
      hotelCode,
      bookingStep: "soldout",
    })(state);

    const cawCustomizations = {
      calendarAvailabilityEnabled,
      checkInEnd,
      checkInStart,
      checkOutEnd,
      ctaText,
      defaultAdults,
      defaultChildren,
      maxAdults,
      maxBodies,
      maxChildren,
      maxRooms,
      minAdults,
      minChildren,
      minStay,
      promoDisabled,
      promoHide,
      propertyList,
    };

    const commonArguments = {
      cawState: {
        checkInDate: checkIn,
        hotelCode,
        promoCode: promoCode.toUpperCase(),
        flexDatesWindow: flexDatesWindow
          ? parseInt(flexDatesWindow, 10)
          : undefined,
      },
      content: {
        calendarAvailability: state.calendarAvailability.data,
        employeeCalendarAvailability: state.employeeCalendarAvailability.data,
        globalSettings: state.globalSettings.data,
        property: propertyContent
          ? {
              availabilityBookingMessages,
              metadata: propertyContent,
              soldOutBookingMessages,
              offer,
            }
          : undefined,
        regions: { regions: state.properties.data },
        status: state.appStatus,
      },
      employee: employee ? { checkInStart: employee.stayStart } : undefined,
      language: locale,
      pp: isPreferredPartners,
    };

    return !isEmbedded
      ? getSearchData({
          cawCustomizations,
          ...commonArguments,
          oj: tRetailErrors,
        })
      : getCawData({
          customizations: cawCustomizations,
          ...commonArguments,
        });
  };
