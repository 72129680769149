import { selectEmployeeProfile } from "store/employeeProfile";
import { useSelector } from "react-redux";
import { selectEmployeeBookingError } from "store/bookings";
import usePlanYourStay from "../../../BookingFlow/PlanYourStay/hooks/usePlanYourStay";
import useFetchRequiredData from "../../../BookingFlow/useFetchRequiredData";

export default function useEmployeePortal() {
  const searchData = usePlanYourStay({
    employee: useSelector(selectEmployeeProfile),
  });
  const { showLoadingIndicator } = useFetchRequiredData();
  const employeeBookingError = useSelector(selectEmployeeBookingError);

  const { employeeSearchError, ...remaingingData } = searchData;

  return {
    searchData: {
      ...remaingingData,
      employeeError: employeeSearchError || employeeBookingError,
    },
    showLoadingIndicator,
  };
}
