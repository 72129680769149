import { API_REQUEST_STATES } from "./constants";

export function setRequestState(requestState, { toState }) {
  if (
    !requestState ||
    requestState === API_REQUEST_STATES.REQUEST ||
    toState === API_REQUEST_STATES.REQUEST
  ) {
    // eslint-disable-next-line no-param-reassign
    return toState;
  }
  return requestState;
}

export function setRequestStateRequesting(requestState) {
  return setRequestState(requestState, { toState: API_REQUEST_STATES.REQUEST });
}

export function setRequestStateFulfilled(requestState) {
  return setRequestState(requestState, {
    toState: API_REQUEST_STATES.FULFILLED,
  });
}

export function setRequestStateFailed(requestState) {
  return setRequestState(requestState, { toState: API_REQUEST_STATES.FAILED });
}

export function setRequestStateCancelled(requestState) {
  return setRequestState(requestState, {
    toState: API_REQUEST_STATES.CANCELLED,
  });
}
