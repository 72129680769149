/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  error: "",
};

const cartRecommendationsSlice = createSlice({
  name: "cartRecommendations",
  initialState,
  reducers: {
    fetchCartRecommendations: (state) => {
      state.error = "";
    },

    fetchCartRecommendationsFulfilled: (state, { payload }) => {
      state.data = payload;
      state.error = "";
    },

    fetchCartRecommendationsFailed: (state, { payload: { error } }) => {
      state.data = {};
      state.error = error;
    },

    fetchCartRecommendationsCancel: () => {},
  },
});

export const {
  fetchCartRecommendations,
  fetchCartRecommendationsFulfilled,
  fetchCartRecommendationsFailed,
  fetchCartRecommendationsCancel,
} = cartRecommendationsSlice.actions;

export default cartRecommendationsSlice.reducer;
