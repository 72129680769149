import React from "react";
import env from "config/env";

const { CONFIRMATION_EMAIL_TOOL } = env;

export default function PreferredPartnerEmailForm({
  completedBooking,
  ppDetails = {},
  ppReturnUrl = "",
}) {
  const confirmationNumber = completedBooking.hotelProducts.length
    ? completedBooking.hotelProducts[0].reservationId || ""
    : "";

  return (
    <div className="divide-block email-sent-message">
      <form
        id="confirmationEmailForm"
        name="confirmationEmailForm"
        action={CONFIRMATION_EMAIL_TOOL}
        target="_blank"
        method="post"
      >
        <input type="hidden" name="iata" value={ppDetails.agentId} />
        <input type="hidden" name="agent_name" value={ppDetails.agentName} />
        <input type="hidden" name="agent_email" value={ppDetails.agentEmail} />
        <input type="hidden" name="agent_phone" value={ppDetails.agentPhone} />
        <input type="hidden" name="agency_name" value={ppDetails.agencyName} />
        <input type="hidden" name="sfid" value={ppDetails.sfid} />
        <input type="hidden" name="key" value={ppDetails.ppKey} />
        <input type="hidden" name="return_url" value={ppReturnUrl} />
        <input
          type="hidden"
          name="confirmation_number"
          value={confirmationNumber}
        />
      </form>
    </div>
  );
}
