import React from "react";

import { useTranslation } from "hooks";

function SystemOfflineMessage({
  title,
  phone,
  explanation,
  email,
  buttonText,
  buttonLink,
  buttonSuppress,
}) {
  const { t } = useTranslation();

  return (
    <div className="error outage-error">
      <p>
        <strong>{t(title)}</strong>
      </p>

      {explanation && <p>{t(explanation)}</p>}

      {(phone || email) && (
        <p className="outage-contact">
          {phone && (
            <span>
              {`${t("Phone")}: `}
              <a href={`tel:${phone}`} className="phone">
                {phone}
              </a>
            </span>
          )}

          {email && (
            <span>
              {`${t("Email")}: `}
              <a href={`mailto:${email}`}>{email}</a>
            </span>
          )}
        </p>
      )}

      {!buttonSuppress && (
        <div className="outage-contact">
          <a className="btn btn-primary" href={buttonLink}>
            {t(buttonText)}
          </a>
        </div>
      )}
    </div>
  );
}
export default SystemOfflineMessage;
