import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "hooks";
import FlexDate from "./FlexDate";

function FlexDatesDateSelection({
  flexDates = [],
  selectedResultSetId,
  setSelectedResultSetId,
  isAllInPricing = false,
}) {
  const { t } = useTranslation();
  const rowRef = useRef();
  const selectedRef = useRef();

  const [firstRender, setFirstRender] = useState(true);

  const [centeredFlexDateIndex, setCenteredFlexDateIndex] = useState(
    flexDates.findIndex(
      (flexDate) => flexDate.resultSetId === selectedResultSetId
    )
  );

  useEffect(() => {
    setCenteredFlexDateIndex(
      flexDates.findIndex(
        (flexDate) => flexDate.resultSetId === selectedResultSetId
      )
    );
  }, [selectedResultSetId]);

  const onFlexDateClick = (resultSetId) => {
    const index = flexDates.findIndex(
      (flexDate) => flexDate.resultSetId === resultSetId
    );
    setCenteredFlexDateIndex(index);
    setSelectedResultSetId({
      resultSetId,
      checkIn: flexDates[index].checkinDate,
      checkOut: flexDates[index].checkoutDate,
    });
  };

  const numberOfDatesThatAreBestRate = flexDates.filter(
    ({ isBestRate }) => isBestRate
  ).length;

  const scrollLeft = () => {
    setCenteredFlexDateIndex(Math.max(centeredFlexDateIndex - 1, 0));
  };

  const scrollRight = () => {
    setCenteredFlexDateIndex(
      Math.min(centeredFlexDateIndex + 1, flexDates.length)
    );
  };

  const showPreviousButton = centeredFlexDateIndex > 0;
  const showNextButton = centeredFlexDateIndex < flexDates.length;

  useEffect(() => {
    if (rowRef?.current && selectedRef?.current) {
      const isRTL = document.documentElement.dir === "rtl";
      const { width } = selectedRef.current.getBoundingClientRect();

      const marginLeft = parseFloat(
        window
          .getComputedStyle(selectedRef.current, null)
          .getPropertyValue("margin-left")
      );

      const left = centeredFlexDateIndex * (width + 2 * marginLeft);

      rowRef.current.scrollTo({
        top: 0,
        left: isRTL ? -1 * left : left,
        behavior: firstRender ? "instant" : "smooth",
      });

      if (firstRender) {
        setFirstRender(false);
      }
    }
  }, [firstRender, centeredFlexDateIndex]);

  return (
    <div className="flex-dates-date-selection">
      <div className="flex-dates-date-selection__title-container">
        <h3 className="ty-c1 flex-dates-date-selection__title">
          {t("Browse by date")}
        </h3>
        <p className="flex-dates-date-selection__lowest-rate-available">
          {isAllInPricing
            ? t("*Lowest rate available / All-in per night")
            : t("*Lowest rate available / Average per night")}
        </p>
      </div>
      <div className="flex-dates-date-selection__row-container">
        {showPreviousButton && (
          <div className="flex-dates-date-selection__button-container flex-dates-date-selection__button-container--previous">
            <button
              type="button"
              aria-label={t("previous dates")}
              className="flex-dates-date-selection__button flex-dates-date-selection__button--previous"
              onClick={scrollLeft}
            />
          </div>
        )}

        <div className="flex-dates-date-selection__row" ref={rowRef}>
          <div className="flex-dates-date-selection__row__row">
            {flexDates.map((flexDate) => (
              <FlexDate
                key={flexDate.checkinDate}
                dateRange={flexDate.dateRange}
                bestAvailableRatePerNight={flexDate.bestAvailableRatePerNight}
                bestAvailableRateCurrencyCode={
                  flexDate.bestAvailableRateCurrencyCode
                }
                isBestRate={
                  numberOfDatesThatAreBestRate === 1 && flexDate.isBestRate
                }
                isSelected={flexDate.resultSetId === selectedResultSetId}
                ref={
                  flexDate.resultSetId === selectedResultSetId
                    ? selectedRef
                    : undefined
                }
                isDisabled={flexDate.isDisabled}
                onClick={() => onFlexDateClick(flexDate.resultSetId)}
              />
            ))}
          </div>
        </div>

        {showNextButton && (
          <div className="flex-dates-date-selection__button-container flex-dates-date-selection__button-container--next">
            <button
              type="button"
              aria-label={t("next dates")}
              className="flex-dates-date-selection__button flex-dates-date-selection__button--next"
              onClick={scrollRight}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default FlexDatesDateSelection;
