import React from "react";
import { CounterInput } from "Profile/components";
import { useTranslation } from "hooks";
import { Controller } from "react-hook-form";

function Occupancy({
  searchData: {
    caw: {
      cawConfig: {
        fields: {
          maxAdults = 4,
          maxChildren = 4,
          minAdults = 1,
          minChildren = 0,
          maxBodies = 8,
        } = {},
      } = {},
    } = {},
  } = {},
  formNames: {
    adults: adultsName = "adults",
    children: childrenName = "children",
  } = {},
  formValues: { adults, children } = {},
  formControls,
}) {
  const { t } = useTranslation();
  const { control } = formControls;

  const allowIncrement = () => adults + children < maxBodies;

  const adjustedMaxChildren =
    adults <= maxAdults && adults >= minAdults
      ? Math.max(Math.min(maxBodies - adults, maxChildren), 0)
      : undefined;
  const forceChildrenCount =
    (adjustedMaxChildren || adjustedMaxChildren === 0) &&
    children > adjustedMaxChildren
      ? Math.max(adjustedMaxChildren, minChildren)
      : undefined;

  return (
    <div className="occupancy">
      <div className="alignment">
        <div className="adults">
          <Controller
            name={adultsName}
            control={control}
            render={({ onChange }) => (
              <CounterInput
                label={t("Adults")}
                min={minAdults}
                max={maxAdults}
                initialValue={adults}
                onChange={onChange}
                allowIncrement={allowIncrement}
              />
            )}
          />
        </div>
        <div className="children">
          <Controller
            name={childrenName}
            control={control}
            render={({ onChange }) => (
              <CounterInput
                label={t("Children")}
                min={minChildren}
                max={maxChildren}
                initialValue={children}
                forceCount={forceChildrenCount}
                onChange={onChange}
                allowIncrement={allowIncrement}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default Occupancy;
