import { ajax } from "rxjs/ajax";

import env from "config/env";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;
const TRETAIL_API_BOOKINGS_URL = `${TRETAIL_API_BASE_URL}/bookings`;

export const makeTwoPhaseReservation$ = ({
  bookingId,
  prePayment = true,
  locale,
}) =>
  ajax({
    ...basePostRequest({ locale }),
    url: `${TRETAIL_API_BOOKINGS_URL}/${bookingId}/reservation`,
    body: {
      prePayment,
    },
  });

export default makeTwoPhaseReservation$;
