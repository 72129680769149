import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";

import env from "config/env";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

export default function modifyCustAncillary$({ bookingId, locale }) {
  return ajax({
    ...basePostRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/bookings/${bookingId}/products/modification/cust/ancillary`,
  }).pipe(pluck("response"));
}
