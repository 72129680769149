import env from "config/env";
import { getCookieValue } from "utils/cookies";

const { STATIC_SITE_URL } = env;

export default function getFsInternal() {
  fetch(`${STATIC_SITE_URL}/etc/designs/fourseasons/sitecatalyst-blacklist`, {
    mode: "no-cors",
    credentials: "include",
  }).finally(() => {
    window.utag_data = {
      ...(window.utag_data || {}),
      is_internal: !!getCookieValue("sitecatalyst-blacklist"),
    };
  });
}
