import camelCase from "lodash/camelCase";
import isObject from "lodash/isObject";

// remove __r | __c from keys
// camelCase keys
// remove `Z` from timestamps
export default function processResponse(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];

    return {
      ...acc,
      [camelCase(key.replace(/__(c|r)$/, ""))]: isObject(value)
        ? processResponse(value)
        : typeof value === "string"
        ? value.replace(/Z$/, "")
        : value,
    };
  }, {});
}
