import React, { useState } from "react";
import classNames from "classnames";

import env from "config/env";

const { IMAGE_BASE_URL } = env;

export default function RoomImages({
  roomName,
  images,
  showThumbnails = true,
}) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const { path: selectedImagePath } = images[selectedImageIndex];

  return (
    <div className="flexslider">
      <ul className="slides">
        <li>
          <img
            loading="lazy"
            className="large"
            src={IMAGE_BASE_URL + selectedImagePath}
            alt={roomName}
          />
        </li>
      </ul>
      {showThumbnails && images.length > 1 && (
        <ol className="flex-control-nav flex-control-thumbs">
          {images.map(({ path }, index) => (
            <li key={`${path}:${index}`}>
              <button
                type="button"
                onClick={() => setSelectedImageIndex(index)}
              >
                <img
                  loading="lazy"
                  src={IMAGE_BASE_URL + path}
                  alt={roomName}
                  draggable="false"
                  className={classNames({
                    "flex-active": selectedImageIndex === index,
                  })}
                />
              </button>
            </li>
          ))}
        </ol>
      )}
    </div>
  );
}
