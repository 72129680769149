import React, { useState } from "react";

import { useTranslation } from "hooks";
import Modal from "BookingFlow/components/Modal";
import { getPrivacyLink } from "utils/externalLinks";

const CONSENT_STATE = {
  NEITHER: "neither",
  CONSENT: "consent",
  DISSENT: "dissent",
};

export default function RussiaConfirmationModal({ onSubmit, onClose }) {
  const { t, i18n } = useTranslation();

  const [consentStatus, setConsentStatus] = useState(CONSENT_STATE.NEITHER);

  const handleConsentChange = () =>
    [CONSENT_STATE.NEITHER, CONSENT_STATE.DISSENT].includes(consentStatus)
      ? setConsentStatus(CONSENT_STATE.CONSENT)
      : setConsentStatus(CONSENT_STATE.DISSENT);

  const name = "guestDetails.nationality";

  const handleSubmission = (isRussianCitizen) =>
    consentStatus === CONSENT_STATE.CONSENT
      ? onSubmit({ isRussianCitizen })
      : setConsentStatus(CONSENT_STATE.DISSENT);

  return (
    <Modal
      className="new-modal--russia-confirmation"
      closeModal={onClose}
      renderCloseButton={false}
    >
      <div className="russia-confirmation-view">
        <div className="russia-confirmation-body">
          <h1>
            {t(
              "We recognize you are currently located within the Russian Federation"
            )}
          </h1>
          <p>
            {t(
              "Before continuing we do require citizenship verification as per government regulations required within the Russian Federation. To proceed, we kindly ask you to confirm whether or not you are a Russian citizen by clicking the appropriate button below."
            )}
          </p>
        </div>
        <div className="russia-confirmation-consent">
          <input
            type="checkbox"
            id={name}
            name={name}
            required
            onChange={handleConsentChange}
          />
          <label htmlFor={name}>
            &nbsp;
            {t(
              "I consent to the processing of my personal data pursuant to Four Seasons Hotels and Resorts"
            )}
            &nbsp;
            <a href={getPrivacyLink(i18n.language)}>{t("Privacy Policy")}</a>
          </label>
        </div>
        <div className="russia-confirmation-actions">
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => handleSubmission(true)}
          >
            {t("Yes, I am a Russian citizen")}
          </button>
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => handleSubmission(false)}
          >
            {t("No, I am not a Russian citizen")}
          </button>
        </div>
        {consentStatus === CONSENT_STATE.DISSENT && (
          <div className="russia-confirmation-error">
            *&nbsp;
            {t(
              "Please consent to the processing of your personal data before proceeding further."
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}
