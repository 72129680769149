import React from "react";
import classNames from "classnames";

export default function StarIcon({ className }) {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("icon-star", className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1971 7.40224L14.4921 6.36724L11.4021 0.472241C11.3652 0.404965 11.311 0.348835 11.245 0.309733C11.179 0.270632 11.1038 0.25 11.0271 0.25C10.9504 0.25 10.8751 0.270632 10.8091 0.309733C10.7431 0.348835 10.6889 0.404965 10.6521 0.472241L7.56957 6.36724L0.857065 7.40224H0.789565C0.720296 7.42487 0.658107 7.46514 0.609109 7.51908C0.560112 7.57302 0.525993 7.63878 0.510104 7.7099C0.494216 7.78102 0.497105 7.85505 0.518488 7.92472C0.539872 7.99438 0.579013 8.05728 0.632065 8.10724L5.44707 12.6972L4.38207 19.2597V19.3272C4.38221 19.3987 4.40059 19.469 4.43548 19.5314C4.47038 19.5938 4.52062 19.6463 4.58145 19.6838C4.64229 19.7214 4.7117 19.7428 4.78312 19.746C4.85453 19.7492 4.92559 19.7342 4.98956 19.7022L10.9896 16.7022L16.9896 19.7022H17.0571C17.1251 19.7234 17.1975 19.727 17.2673 19.7125C17.337 19.698 17.402 19.666 17.456 19.6195C17.51 19.573 17.5513 19.5135 17.576 19.4466C17.6007 19.3797 17.6079 19.3077 17.5971 19.2372L16.6071 12.7122L21.4221 8.12224L21.4746 8.06974C21.5161 8.01074 21.542 7.94217 21.5499 7.87044C21.5578 7.79872 21.5475 7.72615 21.5198 7.65952C21.4921 7.59288 21.4479 7.53435 21.3915 7.48936C21.3351 7.44437 21.2682 7.4144 21.1971 7.40224ZM15.8721 12.2547L15.8121 12.3147C15.7806 12.3602 15.7585 12.4115 15.7469 12.4655C15.7353 12.5196 15.7345 12.5754 15.7446 12.6297L16.6971 18.5772L11.2146 15.8397H11.1471C11.0984 15.8193 11.0461 15.8088 10.9933 15.8088C10.9405 15.8088 10.8883 15.8193 10.8396 15.8397L5.35707 18.5772L6.31707 12.6297V12.5472C6.31764 12.4923 6.30657 12.4378 6.2846 12.3874C6.26262 12.3369 6.23024 12.2918 6.18957 12.2547L1.83207 8.09974L7.90707 7.11724C7.97116 7.10584 8.03196 7.08044 8.08511 7.04284C8.13826 7.00525 8.18247 6.95638 8.21457 6.89974L11.0271 1.54474L13.8396 6.89974L13.8846 6.95974C13.9455 7.04028 14.0333 7.0962 14.1321 7.11724L20.2146 8.06974L15.8721 12.2547Z"
        fill="black"
      />
    </svg>
  );
}
