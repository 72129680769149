import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs";
import { map, switchMap, takeUntil, catchError } from "rxjs/operators";

import { getExchangeRates$ } from "api/content";
import {
  fetchExchangeRates,
  fetchExchangeRatesFulfilled,
  fetchExchangeRatesFailed,
  fetchExchangeRatesCancel,
} from "./exchangeRates.slice";

const fetchExchangeRatesEpic = (action$) =>
  action$.pipe(
    ofType(fetchExchangeRates.type),
    switchMap(({ payload: { locale } }) =>
      getExchangeRates$({ locale }).pipe(
        map(fetchExchangeRatesFulfilled),
        catchError((error) => of(fetchExchangeRatesFailed(error))),
        takeUntil(action$.pipe(ofType(fetchExchangeRatesCancel.type)))
      )
    )
  );

export default combineEpics(fetchExchangeRatesEpic);
