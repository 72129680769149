import React from "react";

import { updateUserEmail, selectEmail } from "store/profile";
import { NEW_EMAIL_ID } from "fixtures/constants";
import EmailForm from "./EmailForm";
import useFormSubmit from "../../../hooks/useFormSubmit";

export default function AddEmail({ closeForm }) {
  const {
    onSubmit,
    register,
    showLoadingIndicator,
    value,
    formState,
    watched,
    errors,
    baseError,
  } = useFormSubmit({
    closeForm,
    action: updateUserEmail,
    selector: selectEmail(NEW_EMAIL_ID),
    fieldName: "emailData",
  });

  return (
    <EmailForm
      identifier="new-email-address"
      email={value}
      watched={watched}
      isSave
      onSubmit={onSubmit}
      formState={formState}
      register={register}
      errors={errors}
      baseError={baseError}
      showLoadingIndicator={showLoadingIndicator}
    />
  );
}
