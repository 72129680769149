import React from "react";
import { useTranslation } from "hooks";

function PreferredPartnersBookingMessage() {
  const { t } = useTranslation();

  return (
    <div className="booking-messages">
      <ul>
        <li>
          <p className="message-title pp">{t("Preferred Partner Benefits")}</p>
          <div className="message-body">
            {t(
              'Rate or package names preceded by "Preferred Partner" include Preferred Partner benefits, any other rates or packages do not.'
            )}
          </div>
        </li>
      </ul>
    </div>
  );
}

export default PreferredPartnersBookingMessage;
