import React from "react";
import { useTranslation } from "hooks";

import { errorCodes, getErrorCodeForTranslations } from "utils/errorCodes";
import { Button } from "Profile/components";

export default function OtpError({ errorCode, reset }) {
  const { t } = useTranslation();

  return (
    <div className="fs-otp-sign-in__invalid-otp">
      <div>
        <h1>
          {errorCode === errorCodes.INVALID_LOGIN_OTP_EXPIRED ||
          errorCode === errorCodes.VERIFY_EMAIL_CODE_EXPIRED
            ? t("Code Expired")
            : t("Code Invalid")}
        </h1>

        <p className="intro">{t(getErrorCodeForTranslations({ errorCode }))}</p>

        <div className="view__actions">
          <Button onClick={reset}>{t("Get new code")}</Button>
        </div>
      </div>
    </div>
  );
}
