import { from, interval } from "rxjs";
import { switchMap, filter, take } from "rxjs/operators";
import get from "lodash/get";

import env from "config/env";
import {
  getAvailCheckData,
  getBookingData,
  userIsPreferredPartner,
  userIsEmployee,
} from "../Tealium/tealium";

const CHECK_PERIOD = 100;
const { ENABLE_DECISION_ENGINE, STATIC_SITE_URL } = env;

function addScriptTag() {
  const scriptTag = document.createElement("script");
  scriptTag.src = `${STATIC_SITE_URL}/alt/apps/fshr/shared/decision-engine.js`;
  scriptTag.type = "text/javascript";
  scriptTag.async = true;
  document.body.appendChild(scriptTag);
  return scriptTag;
}

const parseNumber = (numberString, defaultValue) => {
  if (numberString) {
    try {
      return Number.parseInt(numberString, 10);
    } catch {
      return defaultValue;
    }
  } else {
    return defaultValue;
  }
};

// exclude EMP and PP from Joureny Awareness
const isValidUser = (nextState) => {
  const isEmployee = userIsEmployee(nextState);
  const isPp = userIsPreferredPartner(nextState);
  return !isEmployee && !isPp;
};

const getOwsCode = (payload, nextState) => {
  const { resultSetId } = nextState.searchResults;
  return (
    payload?.hotelCode ||
    nextState.router.location.query.hotelCode ||
    (nextState.searchResults.data[resultSetId] || {})?.hotelOptions?.[0]
      ?.hotelCode ||
    payload?.booking?.hotelProducts?.[0]?.hotelCode
  );
};

export const recordSearch =
  (which) =>
  ({ payload }, prevState, nextState) => {
    const {
      // eslint-disable-next-line camelcase
      booking_arrival_date,
      // eslint-disable-next-line camelcase
      booking_departure_date,
      booking_discount_code: promo,
      room_code_array: bedCodes = [],
      room_package_ows_code_array: offerCodes = [],
      room_adults_array: numOfAdults = [],
      room_children_array: numOfChildren = [],
      booking_arrival_array: checkinArray = [],
      booking_departure_array: checkoutArray = [],
    } = which === "search"
      ? getAvailCheckData(prevState, nextState, { ...payload })
      : getBookingData(
          { proceed: true, event_name: "confirm_stay" },
          prevState,
          nextState
        );

    const property = getOwsCode(payload, nextState);
    const checkin = get(booking_arrival_date, [0]);
    const checkout = get(booking_departure_date, [0]);

    return () =>
      property && isValidUser(nextState)
        ? window.FS?.DECISION_ENGINE?.recordSearch({
            property,
            checkin: checkinArray.length ? checkinArray[0] : checkin,
            checkout: checkoutArray.length ? checkoutArray[0] : checkout,
            promo,
            rooms: numOfAdults.map((adults = "", idx) => ({
              bed_code: bedCodes[idx] || "",
              offer_code: offerCodes[idx] || "",
              adults: parseNumber(adults, 0),
              children: parseNumber(numOfChildren[idx], 0),
            })),
          })
        : undefined;
  };
export const recordReservation =
  () =>
  ({ payload }, prevState, nextState) => {
    const property = getOwsCode(payload, nextState);
    return () =>
      property && isValidUser(nextState)
        ? window.FS?.DECISION_ENGINE?.recordReservation({ property })
        : undefined;
  };

const handleEvent = (event) => event();

export const decisionEngineTarget = () => (events) => {
  if (ENABLE_DECISION_ENGINE) {
    if (!window.FS?.DECISION_ENGINE) {
      // Wait for GDPR before loading Descision Engine
      return interval(CHECK_PERIOD)
        .pipe(
          filter(() => !!window.FS?.GDPR),
          take(1),
          switchMap(() => {
            addScriptTag();
            return interval(CHECK_PERIOD).pipe(
              filter(() => !!window.FS?.DECISION_ENGINE),
              take(1),
              switchMap(() => from(events))
            );
          })
        )
        .subscribe(handleEvent);
    }
    if (window.FS?.GDPR) {
      // Decision Engine and GDPR already loaded
      return from(events).subscribe(handleEvent);
    }
  }
  return null;
};
