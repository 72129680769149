import { map } from "rxjs/operators";
import { push } from "connected-react-router";
import queryString from "query-string";

import * as bookingFlowRoutes from "BookingFlow/bookingFlowRoutes";
import { fetchAppStatusFulfilled } from "store/appStatus/appStatus.slice";
import { getAppStatus$ } from "./content";

export default function ajaxWithHealthCheck$({
  locale = "en",
  propertyCode,
  searchParams,
}) {
  return getAppStatus$({ locale }).pipe(
    // MIGHT NOT BE NEEDED, WOULD ONLY BE USEFUL TO KNOW A DIFFERENT PROPERTY WAS OFFLINE
    // tap((appStatus) => store.dispatch(fetchAppStatusFulfilled(appStatus))),

    map((appStatus) => {
      if (appStatus.SYSTEM || (propertyCode && appStatus[propertyCode])) {
        const error = new Error("SYSTEM_OFFLINE");
        error.appStatus = appStatus;

        // must be picked up in rootReducer.js > rootEpic
        error.actions = [
          fetchAppStatusFulfilled(appStatus),
          push({
            pathname: bookingFlowRoutes.planYourStay.to({ locale }),
            search: queryString.stringify({
              ...searchParams,
              hotelCode: propertyCode || searchParams?.hotelCode,
            }),
          }),
        ];
        throw error;
      }
      return appStatus;
    })
  );
}
